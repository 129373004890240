import React, { Reducer, useReducer } from 'react';
import HomePresentational from '../components/Home/Home';

const initialState = {
	loading: false,
};

const reducer: Reducer<{ loading: boolean }, { type: string; value: any }> = (
	state,
	action,
) => {
	switch (action.type) {
		case 'loading':
			return { ...state, loading: action.value };
		default:
			throw new Error();
	}
};

// const LoginActions = (dispatch: Function) => {
//   return {
//     setToken(accessToken: string) {
//       setToken(accessToken);
//     },
//     setLoading(value: boolean) {
//       dispatch({ type: 'loading', value });
//     },
//     login: (username: string, password: string): Promise<LoginResponse> => {
//       return login({ username, password });
//     }
//   }
// };

const Home = (): JSX.Element => {
	const [state, dispatch] = useReducer(reducer, initialState);
	//   const actions = HomeActions(dispatch);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <HomePresentational />;
};

export default Home;
