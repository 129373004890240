import React, { useMemo, useState, useCallback } from 'react';
import {
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
	Tooltip,
	IconButton,
} from '@mui/material';
import { useFormikContext } from 'formik';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
	IAveragePaymentTermSalePriceFormationAnalysis,
	initialSaleOrderSimulationItem,
	initialSalePriceFormationAnalysis,
	ISaleOrderSimulation,
} from '../../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import { currencyBRLMask } from '../../../helpers/intl';
import {
	calculateUnitValueAverageTerm,
	calculateUnitValueAverageTermSimulation,
	getSalePriceFormation,
	getSelectedItem,
	getSelectedItemIndex,
} from '../../../helpers/ProfitabilityAnalysisCalculations';
import { ProfitabilityAnalysisStatus } from '../../../enums/ProfitabilityAnalysisStatus';

interface IAveragePaymentTermSimulationTableProps {
	updateSelectedAveragePaymentSimulation(
		saleOrderSimulationItemId: string,
		averagePaymentTermId: string,
	): void;
}

const AveragePaymentTermSimulationTable = ({
	updateSelectedAveragePaymentSimulation,
}: IAveragePaymentTermSimulationTableProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<ISaleOrderSimulation>();
	const [showExtraColumns, setShowExtraColumns] = useState(true);

	const selectedItem = useMemo(
		() => getSelectedItem(
			values.saleOrderSimulationItems,
			initialSaleOrderSimulationItem,
		),
		[values.saleOrderSimulationItems],
	);

	const selectedItemIndex = useMemo(
		() => getSelectedItemIndex(values.saleOrderSimulationItems),
		[values.saleOrderSimulationItems],
	);

	const salePriceFormation = useMemo(
		() => getSalePriceFormation(selectedItem, initialSalePriceFormationAnalysis),
		[selectedItem],
	);

	const isReadOnly = useMemo(
		() => [
			ProfitabilityAnalysisStatus.APPROVED,
			ProfitabilityAnalysisStatus.REJECTED,
		].includes(values.status),
		[values.status],
	);

	const options = useMemo(() => {
		if (!selectedItem || !salePriceFormation.averagePaymentTermSalePriceFormations) return [];

		return salePriceFormation.averagePaymentTermSalePriceFormations
			.map((term: IAveragePaymentTermSalePriceFormationAnalysis) => {
				const unitValueAverageTerm = calculateUnitValueAverageTerm(
					selectedItem,
					term,
				);

				const unitValueAverageTermSimulation = calculateUnitValueAverageTermSimulation(
					selectedItem,
					term,
				);

				return {
					id: term.averagePaymentTermId,
					value: term.averagePaymentTerm?.code.toString(),
					label: term.averagePaymentTerm?.description,
					interestRate: term.interestRate.toFixed(3),
					unitValueAverageTerm,
					unitValueAverageTermSimulation,
					averagePaymentTermId: term.averagePaymentTermId,
				};
			});
	}, [salePriceFormation.averagePaymentTermSalePriceFormations, selectedItem]);

	const handleRowClick = useCallback(
		(term: IAveragePaymentTermSalePriceFormationAnalysis): void => {
			if (isReadOnly) return;

			setFieldValue(
				`saleOrderSimulationItems[${selectedItemIndex}].selectedAveragePaymentTermId`,
				term.averagePaymentTermId,
			);

			if (salePriceFormation?.id) {
				updateSelectedAveragePaymentSimulation(
					selectedItem.id,
					term.averagePaymentTermId,
				);
			}
		},
		[
			isReadOnly,
			salePriceFormation,
			selectedItem,
			selectedItemIndex,
			setFieldValue,
			updateSelectedAveragePaymentSimulation,
		],
	);

	return (
		<Box sx={{ display: 'flex', position: 'relative' }}>
			<TableContainer component={Paper} sx={{ flexGrow: 1 }}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '10%' }}>
								<strong>Descrição</strong>
							</TableCell>
							<TableCell align="right" sx={{ width: '20%' }}>
								<strong>Taxa de Juros (%)</strong>
							</TableCell>
							<TableCell align="right" sx={{ width: '20%' }}>
								<strong>R$ Unitário</strong>
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell align="right" sx={{ width: '25%', backgroundColor: '#f0f0f0' }}>
										<strong>Descrição</strong>
									</TableCell>
									<TableCell align="right" sx={{ width: '25%', backgroundColor: '#f0f0f0' }}>
										<strong>R$ Unitário</strong>
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor: showExtraColumns ? 'primary.dark' : 'primary.main',
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip title={showExtraColumns ? 'Ocultar colunas de simulação' : 'Visualizar colunas de simulação'}>
									<IconButton
										sx={{ color: 'white' }}
										onClick={() => setShowExtraColumns(!showExtraColumns)}
									>
										{showExtraColumns ? <VisibilityOffIcon /> : <VisibilityIcon />}
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{options.map((option: any) => {
							const isSelected = option.label === values.averagePaymentTerm;

							const isSimulationSelected = option.averagePaymentTermId
							=== selectedItem.selectedAveragePaymentTermId
								? true
								: option.label === values.averagePaymentTerm
								&& selectedItem.selectedAveragePaymentTermId === null;

							const defaultCellStyle = {
								fontWeight: isSelected ? 'bold' : 'normal',
							};

							const simulationCellStyle = {
								fontWeight: isSimulationSelected ? 'bold' : 'normal',
								backgroundColor: isSimulationSelected ? '#e0e0e0' : '#f0f0f0',
								cursor: 'pointer',
							};

							return (
								<TableRow key={option.id}>
									<TableCell sx={defaultCellStyle}>{option.label}</TableCell>
									<TableCell align="right" sx={defaultCellStyle}>{option.interestRate}</TableCell>
									<TableCell align="right" sx={defaultCellStyle}>
										{currencyBRLMask(option.unitValueAverageTerm, { decimalPlaces: 3 })}
									</TableCell>
									{showExtraColumns && (
										<>
											<TableCell
												align="right"
												sx={{
													...simulationCellStyle,
													backgroundColor: '#f0f0f0',
												}}
												onClick={() => !isReadOnly && handleRowClick(option)}
											>
												{option.label}
											</TableCell>
											<TableCell
												align="right"
												sx={{
													...simulationCellStyle,
													backgroundColor: '#f0f0f0',
												}}
												onClick={() => !isReadOnly && handleRowClick(option)}
											>
												{currencyBRLMask(option
													.unitValueAverageTermSimulation, { decimalPlaces: 3 })}
											</TableCell>
										</>
									)}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				sx={{
					width: '40px',
					backgroundColor: showExtraColumns ? 'primary.dark' : 'primary.main',
					position: 'absolute',
					right: 0,
					top: '40px',
					bottom: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: 'calc(100% - 40px)',
				}}
			>
				<Typography
					sx={{
						writingMode: 'vertical-rl',
						transform: 'rotate(180deg)',
						color: 'white',
						fontWeight: 'bold',
						fontSize: '0.75rem',
						letterSpacing: '1px',
						textAlign: 'center',
						lineHeight: 'normal',
					}}
				>
					Simulação
				</Typography>
			</Box>
		</Box>
	);
};

export default AveragePaymentTermSimulationTable;
