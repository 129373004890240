import React, { useCallback, useEffect } from 'react';
import { Paper, Typography, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useParams } from 'react-router-dom';
import LocationTypeForm from './LocationTypeForm';
import { ILocationTypeActions } from '../../containers/LocationType/LocationTypeEdit';
import { ILocationType } from '../../containers/Location/LocationAssets';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';

interface LocationTypeEditProps extends ILocationTypeActions {
  locationType?: ILocationType;
  loading: boolean;
  companies: ICompanyWithoutDetails[];
  branches: IBranch[];
  getUserCompanies: () => void;
  getUserBranchesByCompany: (companyId: string) => void;
}

const paperSx: SxProps<Theme> = {
	mt: 3,
	p: 3,
};

const LocationTypeEdit = ({
	locationType,
	loading,
	companies,
	branches,
	handleEditLocationType,
	getLocationTypeById,
	getLocationsState,
	getUserCompanies,
	getUserBranchesByCompany,
}: LocationTypeEditProps): JSX.Element => {
	const { id } = useParams();

	const handleSubmit = useCallback(
		(values: ILocationType) => {
			handleEditLocationType({
				id: locationType?.id,
				data: {
					level: values.level,
					name: values.name,
					prefix: values.prefix.toUpperCase(),
					companyId: values.companyId,
					branchId: values.branchId,
				},
			});
		},
		[handleEditLocationType, locationType?.id],
	);

	useEffect(() => {
		getLocationsState();
		if (id) {
			getLocationTypeById(id);
		}
	}, [getLocationTypeById, getLocationsState, id]);

	return (
		<Paper sx={paperSx} elevation={3}>
			<Typography variant="h4" color="primary" gutterBottom>
				Cadastrar Tipo de Localização
			</Typography>
			<LocationTypeForm
				loading={loading}
				locationType={locationType}
				companies={companies}
				branches={branches}
				onSubmit={handleSubmit}
				getUserCompanies={getUserCompanies}
				getUserBranchesByCompany={getUserBranchesByCompany}
			/>
		</Paper>
	);
};

LocationTypeEdit.defaultProps = {
	locationType: undefined,
};

export default LocationTypeEdit;
