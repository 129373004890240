import '../../helpers/yupMethods';
import * as Yup from 'yup';
import { validationMessage } from '../../helpers/yupMessages';

export interface IBranch {
	address: string;
	cnpj: string;
	code: string;
	companyId: string;
	createdAt: string;
	email: string;
	id: string;
	logo: File | null;
	name: string;
	phone: string;
	companyName?: string;
	logoB64?: string;
	updatedAt?: string;
}

export interface IBranchResponse {
	data: IBranch[];
	count: number;
}

export interface EditBranch {
	companyId: string;
	name: string;
	code: string;
	cnpj: string;
	address: string;
	email: string;
	phone: string;
	logo: { file: File | null, preview: string | null } | null;
}

export const initialValues = {
	companyId: '',
	name: '',
	code: '',
	cnpj: '',
	address: '',
	email: '',
	phone: '',
	logo: null,
};

export const validationSchema: Yup.AnySchema = Yup.object().shape({
	name: Yup.string().required(validationMessage.required),
	companyId: Yup.string().required(validationMessage.required),
	code: Yup.string()
		.min(1, validationMessage.string.min(1))
		.required(validationMessage.required),
	address: Yup.string().required(validationMessage.required),
	email: Yup.string()
		.email(validationMessage.string.email)
		.required(validationMessage.required),
	phone: Yup.string()
		.min(10, validationMessage.string.min(10))
		.max(16, validationMessage.string.max(16))
		.required(validationMessage.required),
	cnpj: Yup.string()
		.cnpj()
		.required(validationMessage.required),
});
