export const filterInputs = [
	{
		name: 'accountingAccount',
		label: 'Cta. Contábil',
	},
	{
		name: 'costCenter',
		label: 'C. Custo',
	},
	{
		name: 'accountingItem',
		label: 'Filial',
	},
	{
		name: 'classValue',
		label: 'CliFor',
	},
];

export const filtersInitialValues = {
	accountingAccount: '',
	costCenter: '',
	accountingItem: '',
	classValue: '',
};
