import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SxProps } from '@mui/system';
import notFoundImage from '../../assets/not_found.svg';

const pageWrapper: SxProps<Theme> = {
	height: '100%',
	width: '100%',
};

const image: SxProps<Theme> = {
	height: '20rem',
};

const button: SxProps<Theme> = {
	width: 'fit-content',
};

const PageNotFound = (): JSX.Element => (
	<Stack spacing={10} direction="column" alignItems="center" justifyContent="center" sx={pageWrapper}>
		<Box>
			<Box
				component="img"
				src={notFoundImage}
				alt="Página não encontrada"
				sx={image}
			/>
		</Box>
		<Stack spacing={6}>
			<Stack spacing={3}>
				<Typography variant="h2">Página não encontrada</Typography>
				<Typography variant="body1">
					Parece que você está tentando acessar um endereço que foi removido ou não existe.
					<br />
					Certifique-se de que o endereço está correto.
				</Typography>
			</Stack>
			<Button
				variant="contained"
				component={Link}
				to="/"
				sx={button}
				startIcon={<ArrowBackIcon />}
			>
				Home
			</Button>
		</Stack>
	</Stack>
);

export default PageNotFound;
