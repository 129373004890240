import { Box, Checkbox } from '@mui/material';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { FilterList } from '@mui/icons-material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import InventoryTasksTable from './InventoryStartTable';
import InventoryStartFilter from './InventoryStartFilter';
import DrawerFilter from '../Common/DrawerFilter';
import { IProduct, IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { ILocationProduct, LocationProductQueryParams } from '../../interfaces/LocationProduct';
import { formatBarCode } from '../../helpers/masks';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import { ParamsStartInventoryPartial } from '../../containers/Inventory/InventoryAssets';
import { filterObject } from '../../helpers/Utils';

interface InventoryStartProps {
	loading: boolean;
	products: IProductWithoutDetails[];
	getProducts: (queryParams: ProductQueryParams) => void;
	getLocationsProducts: (queryParams: LocationProductQueryParams) => void;
	locationsProducts: ILocationProduct[];
	locationsProductsCount: number;
	locationsProductsPage: number;
	locationsProductsTake: number;
	startInventoryPartial: (params: ParamsStartInventoryPartial) => void;
}

const InventoryStart = ({
	loading,
	products,
	getLocationsProducts,
	getProducts,
	locationsProducts,
	locationsProductsCount,
	locationsProductsPage,
	locationsProductsTake,
	startInventoryPartial,
}: InventoryStartProps): JSX.Element => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(true);
	const [filterValues, setFilterValues] = useState<Partial<LocationProductQueryParams>>({});
	const [selectedLocationProducts, setSelectedLocationProducts] = useState<string[]>([]);
	const [allItemsSelected, setAllItemsSelected] = useState<boolean>(false);
	const [selectedProducts, setSelectedProducts] = useState<IProductWithoutDetails[]>([]);

	const locationState = useLocation();
	const { branchId, companyId } = locationState.state || {};

	const columns = useMemo<GridColDef[]>(() => [
		{
			field: 'id',
			headerName: 'Ações',
			width: 60,
			renderHeader: () => (
				<Checkbox
					checked={allItemsSelected}
					onChange={(event) => {
						const isChecked = event.target.checked;
						setAllItemsSelected(isChecked);
						setSelectedLocationProducts([]);
					}}
				/>
			),
			renderCell: (params: GridRenderCellParams) => {
				const isSelected = selectedLocationProducts.includes(params.row.id);
				return (
					<Checkbox
						checked={(allItemsSelected ? !isSelected : isSelected)}
						onChange={() => {
							setSelectedLocationProducts((prevState) => (prevState.includes(params.row.id)
								? prevState.filter((lp) => lp !== params.row.id)
								: [...prevState, params.row.id]));
						}}
					/>
				);
			},
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: 'location',
			headerName: 'Localização',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (formatBarCode(params.row.location.barCode)),
		},
		{
			field: 'product',
			headerName: 'Produto',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (`${params.row.product.barCode} - ${params.row.product.description}`),
		},
		{
			field: 'currentQuantity',
			headerName: 'Utilizado',
			flex: 1,
		},
		{
			field: 'valuedQuantity',
			headerName: 'Capacidade',
			flex: 1,
		},
	], [allItemsSelected, selectedLocationProducts]);

	useEffect(() => {
		getProducts({ skip: 0 });
	}, [getProducts]);

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<LocationProductQueryParams>({
		initialPageSize: locationsProductsTake,
		initialPage: locationsProductsPage,
		fetchData: getLocationsProducts,
	});

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen((prevState) => !prevState);
	}, []);

	const handleConfirmationInventoryPartial = useCallback(() => {
		const params = filterObject({
			branchId,
			companyId,
			locationProductIds: selectedLocationProducts,
			allItemsSelected,
			productIds: filterValues.productIds,
			locationValue: filterValues.locationValue,
		});

		startInventoryPartial(params as ParamsStartInventoryPartial);
	}, [
		allItemsSelected,
		branchId,
		companyId,
		filterValues,
		selectedLocationProducts,
		startInventoryPartial,
	]);

	const sendFilter = useCallback((values: Partial<LocationProductQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
		};
		setFilterValues(pageFilter);
		setFilter(pageFilter);
		setFilterDrawerOpen(false);
	}, [setFilter]);

	const filterMemo = useMemo(
		() => (
			<DrawerFilter open={isFilterDrawerOpen} onClose={() => setFilterDrawerOpen(false)}>
				<InventoryStartFilter
					products={products}
					initialValues={filterValues}
					sendFilter={sendFilter}
					loading={loading}
					getProducts={getProducts}
					setSelectedProducts={setSelectedProducts}
					selectedProducts={selectedProducts}
				/>
			</DrawerFilter>
		),
		[
			filterValues,
			getProducts,
			isFilterDrawerOpen,
			loading,
			products,
			selectedProducts,
			sendFilter,
		],
	);

	const headerButtonsProps = useMemo(
		(): PageHeaderButtonProps[] => [
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				onClick: () => handleConfirmationInventoryPartial(),
				text: 'Confirmar',
				disabled: selectedLocationProducts.length === 0 && !allItemsSelected,
			},
			{
				variant: 'contained',
				color: 'primary',
				onClick: toggleFilterDrawer,
				text: 'Filtro',
				startIcon: <FilterList />,
			},
		],
		[
			allItemsSelected,
			handleConfirmationInventoryPartial,
			selectedLocationProducts.length,
			toggleFilterDrawer,
		],
	);

	return (
		<Box className="content">
			<PageHeader title="Iniciar Inventário" buttons={headerButtonsProps} />
			{filterMemo}
			<InventoryTasksTable
				rows={locationsProducts}
				columns={columns}
				loading={loading && !isFilterDrawerOpen}
				locationsProductsCount={locationsProductsCount}
				locationsProductsTake={locationsProductsTake}
				locationsProductsPage={locationsProductsPage}
				sortModel={sortModel}
				onChangePageSize={onChangePageSize}
				onChangePage={onChangePage}
				onSortModelChange={onSortModelChange}
			/>
		</Box>
	);
};

export default InventoryStart;
