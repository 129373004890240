import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Input from '../Common/Form/Input';

interface IFilterProps {
    sendFilter: (values: IForm) => void
}

interface IForm {
    name: string;
}

const initialValues = {
	name: '',
};

const AccessGroupFilter = ({
	sendFilter,
}: IFilterProps): JSX.Element => {
	const onSubmit = useCallback((values: IForm) => {
		sendFilter(values);
	}, [sendFilter]);

	const onReset = useCallback((handleReset) => {
		sendFilter({ name: '' });
		handleReset();
	}, [sendFilter]);

	return (
		<Paper sx={{ p: 2, width: '100%', mb: 2 }}>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
			>
				{({ handleReset }) => (
					<Form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Input.InputField
									id="name"
									name="name"
									label="Nome"
									autoComplete="off"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
								<Button variant="outlined" onClick={() => onReset(handleReset)}>
									Restaurar
								</Button>
								<Button variant="contained" type="submit">
									Filtrar
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};

export default AccessGroupFilter;
