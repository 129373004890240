import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../Common/Loading';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import TransferLocations from './TransferLocations';
import TransferProductDetails from './TransferProductDetails';
import { SaveTaskType } from '../../../services/transfer';
import TransferFinish from './TransferFinish';
import { StepsTransfer } from '../../../containers/Mobile/Transfer/TransferAssets';

interface TransferEditProps {
	loading: boolean;
	setTransferTask(task: ITask): void;
	setActiveStep(step: StepsTransfer): void;
	activeStep: StepsTransfer;
	saveTaskCount(task: SaveTaskType): void;
    saveTakeTask(task: SaveTaskType): void;
	getTransfers: () => void;
	transferTask?: ITask;
	transferTasks: ITask[];
}

const Transfer = ({
	loading,
	setTransferTask,
	getTransfers,
	saveTaskCount,
	saveTakeTask,
	setActiveStep,
	activeStep,
	transferTask,
	transferTasks,
}: TransferEditProps): JSX.Element => {
	const [currentQuantity, setCurrentQuantity] = useState<number>();

	const navigate = useNavigate();

	useEffect(() => {
		getTransfers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSelectTask = useCallback(
		(task: ITask): void => {
			setTransferTask(task);
			saveTakeTask({ taskId: task.id, quantity: Number(task.quantity) });
			setActiveStep(StepsTransfer.PRODUCT_DETAILS);
		},
		[setTransferTask, saveTakeTask, setActiveStep],
	);

	const handleConfirmTask = useCallback(
		(task: ITask, quantity: number): void => {
			setCurrentQuantity(quantity);
			setTransferTask(task);
			setActiveStep(StepsTransfer.FINISH);
		},
		[setTransferTask, setActiveStep],
	);

	const handleFinishTask = useCallback(
		(task: ITask): void => {
			if (currentQuantity) {
				saveTaskCount({ taskId: task.id, quantity: currentQuantity });
				setActiveStep(StepsTransfer.LOCATIONS);
			}
		},
		[currentQuantity, saveTaskCount, setActiveStep],
	);

	const memoizedTransferTasks = useMemo(() => transferTasks, [transferTasks]);

	if (loading) {
		return <Loading />;
	}

	return (
		<>
			{activeStep === StepsTransfer.LOCATIONS && (
				<TransferLocations
					transferTasks={memoizedTransferTasks}
					loading={loading}
					handleSelectTask={handleSelectTask}
					handleBack={() => navigate('/app')}
				/>
			)}

			{activeStep === StepsTransfer.PRODUCT_DETAILS && transferTask && (
				<TransferProductDetails
					loading={loading}
					transferTask={transferTask}
					handleConfirmTask={handleConfirmTask}
				/>
			)}

			{activeStep === StepsTransfer.FINISH && transferTask && (
				<TransferFinish
					loading={loading}
					transferTask={transferTask}
					handleFinishTask={handleFinishTask}
				/>
			)}
		</>

	);
};

Transfer.defaultProps = {
	transferTask: undefined,
};

export default Transfer;
