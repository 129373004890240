import React, {
	MutableRefObject,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { Form, Formik, FormikProps } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BudgetOrderFourFieldsFilter from '../../Common/BudgetOrderFourFields/BudgetOrderFourFieldsFilter';
import { IFilterFieldsValues } from '../../../interfaces/BudgetOrderFourFields';
import { stopPropagation } from '../../../helpers/Utils';
import { getValidFilters } from '../../../helpers/BudgetDashboardFilter';
import { IBudgetApportionment, PurchaseOrderItem } from '../../../containers/Order/PurchaseOrderAssets';
import NoDataPage from '../../Common/NoDataPage';
import { Loading } from '../../Common/Loading';

interface IPurchaseOrderApportionmentDialogProps {
	loading: boolean;
	filterFieldsValues: IFilterFieldsValues | null;
	isOpen: boolean;
	budgets: any[];
	purchaseOrderItemEdit: PurchaseOrderItem;
	newBudget: any;
	getFilterFieldsValues: () => void;
	sendFilter: (values: any) => void
	handleClose: (budget?: string, apportionment?: any) => void;
	duplicateBudget: (budgetId: string) => void;
}

const initialValues = {
	accountingItems: [],
	costCenters: [],
	accountingAccounts: [],
	classValues: [],
};

const listItemStyle: SxProps<Theme> = {
	flex: '1 1',
};

const PurchaseOrderApportionmentDialog = ({
	loading,
	filterFieldsValues,
	isOpen,
	budgets,
	purchaseOrderItemEdit,
	newBudget,
	getFilterFieldsValues,
	sendFilter,
	handleClose,
	duplicateBudget,
}: IPurchaseOrderApportionmentDialogProps): JSX.Element => {
	const [budget, setBudget] = useState<any>();
	const [filterClicked, setFilterClicked] = useState(false);
	const formikRef: MutableRefObject<FormikProps<typeof initialValues> | null> = useRef(null);
	const codCalendar = useRef<number>(new Date().getFullYear());

	useEffect(() => {
		if (newBudget) {
			setBudget(newBudget);
			handleClose(newBudget);
		}
	}, [handleClose, newBudget]);

	useEffect(() => {
		if (
			budgets
			&& budgets.length === 0
			&& formikRef.current
			&& formikRef.current.submitCount === 1
		) {
			codCalendar.current = new Date().getFullYear() - 1;
			formikRef.current.submitForm();
		}
	}, [budgets]);

	useEffect(() => {
		if (!filterFieldsValues) {
			getFilterFieldsValues();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterFieldsValues]);

	const handleBudgetFromClick = useCallback((selectedBudget: any) => {
		setBudget(selectedBudget);
	}, []);

	const onReset = useCallback((e, handleReset) => {
		stopPropagation(e);
		handleReset();
		setFilterClicked(false);
	}, []);

	const onSubmit = useCallback((values: any) => {
		const budgetFilter = {
			accountingAccounts: values.accountingAccounts,
			accountingItems: values.accountingItems,
			classValues: values.classValues,
			costCenters: values.costCenters,
		};
		const validBudgetFilters = getValidFilters(budgetFilter, filterFieldsValues);

		const filter = {
			codCalendar: codCalendar.current,
			...validBudgetFilters,
		};

		sendFilter(filter);
		setFilterClicked(true);
	}, [filterFieldsValues, sendFilter]);

	const onClose = useCallback(() => {
		if (codCalendar.current !== new Date().getFullYear()) {
			duplicateBudget(budget.id);
		} else {
			handleClose(budget);
		}
	}, [budget, duplicateBudget, handleClose]);

	const notFoundBudgetsMessageMemo = useMemo(() => (
		codCalendar.current !== new Date().getFullYear() && budgets.length > 0 && (
			<Typography variant="caption" mt={1} color="warning.main">
				Os resultados apresentados são do ano anterior.
				Esteja ciente que ao salvar informações,
				o orçamento será criado para o ano atual com valores zerados.
			</Typography>
		)
	), [budgets.length]);

	const budgetsListMemo = useMemo(() => (
		<List>
			{budgets.map((currentBudget: IBudgetApportionment) => {
				const isDuplicate = purchaseOrderItemEdit?.purchaseOrderItemApportionments.some(
					(apportionment) => apportionment.budget.id === currentBudget.id,
				);

				return (
					<React.Fragment key={currentBudget.id}>
						{isDuplicate ? (
							<Typography variant="body1" color="error" style={{ marginLeft: '16px' }}>
								Orçamento repetido, selecione outro orçamento!
							</Typography>
						) : (
							<>
								<ListItem disablePadding>
									<ListItemButton
										selected={budget?.id === currentBudget.id}
										onClick={() => handleBudgetFromClick(currentBudget)}
									>
										<ListItemText sx={listItemStyle} primary={currentBudget.accountingAccount.name} secondary="Cta. Contábil" />
										<ListItemText sx={listItemStyle} primary={currentBudget.costCenter.name} secondary="C. Custo" />
										<ListItemText sx={listItemStyle} primary={currentBudget.accountingItem.name} secondary="Filial" />
										<ListItemText sx={listItemStyle} primary={currentBudget.classValue.name} secondary="CliFor" />
									</ListItemButton>
								</ListItem>
								<Divider />
							</>
						)}
					</React.Fragment>
				);
			})}
		</List>
	), [
		budgets,
		purchaseOrderItemEdit?.purchaseOrderItemApportionments,
		budget?.id,
		handleBudgetFromClick,
	]);

	return (
		<Dialog
			open={isOpen}
			maxWidth="lg"
			fullWidth
			onClose={() => handleClose()}
		>
			<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				Criar Rateio
				<IconButton
					color="inherit"
					onClick={() => handleClose()}
					aria-label="close"
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Formik
					initialValues={initialValues}
					innerRef={formikRef}
					onSubmit={onSubmit}
				>
					{({ values, handleReset }) => (
						<Form>
							<Grid container spacing={3}>
								{filterFieldsValues && (
									<BudgetOrderFourFieldsFilter
										accountingAccounts={filterFieldsValues.accountingAccounts}
										accountingItems={filterFieldsValues.accountingItems}
										classValues={filterFieldsValues.classValues}
										costCenters={filterFieldsValues.costCenters}
										breakpoints={{ xs: 6, xl: 3 }}
										limitTags={2}
									/>
								)}
								<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
									<Button variant="outlined" onClick={(e) => onReset(e, handleReset)}>RESTAURAR</Button>
									<Button
										variant="contained"
										type="submit"
										disabled={
											values.accountingAccounts.length === 0
											|| values.accountingItems.length === 0
											|| values.classValues.length === 0
											|| values.costCenters.length === 0
										}
									>
										FILTRAR
									</Button>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
				{notFoundBudgetsMessageMemo}
				{loading && <Loading />}
				{!loading
				&& filterClicked
				&& budgets.length === 0
				&& <NoDataPage />}
				{!loading && budgets.length > 0 && budgetsListMemo}
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="primary"
					disabled={!budget}
					onClick={onClose}
					sx={{ mr: 2 }}
				>
					Salvar informações
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PurchaseOrderApportionmentDialog;
