import React, {
	useState, useCallback, useMemo,
} from 'react';
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	IconButton,
	Tooltip,
	Box,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useFormikContext } from 'formik';
import {
	IIndirectCostSaleOrderSimulationItem,
	initialSaleOrderSimulationItem,
	ISaleOrderSimulation,
} from '../../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import { currencyBRLMask } from '../../../helpers/intl';
import Input from '../../Common/Form/Input';
import {
	calculateUnitValueIndirectCost,
	getSelectedItem,
	getSelectedItemIndex,
} from '../../../helpers/ProfitabilityAnalysisCalculations';
import { ProfitabilityAnalysisStatus } from '../../../enums/ProfitabilityAnalysisStatus';
import { formatPercentage } from '../../../helpers/Utils';

interface IIndirectCostSimulationProps {
	upsertIndirectCostSimulation(data: IIndirectCostSaleOrderSimulationItem): void;
}

const IndirectCostSimulationTable = ({
	upsertIndirectCostSimulation,
}: IIndirectCostSimulationProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<ISaleOrderSimulation>();
	const [showExtraColumns, setShowExtraColumns] = useState(true);

	const selectedItem = useMemo(
		() => getSelectedItem(
			values.saleOrderSimulationItems,
			initialSaleOrderSimulationItem,
		),
		[values.saleOrderSimulationItems],
	);

	const selectedItemIndex = useMemo(
		() => getSelectedItemIndex(values.saleOrderSimulationItems),
		[values.saleOrderSimulationItems],
	);

	const isReadOnly = useMemo(
		() => [
			ProfitabilityAnalysisStatus.APPROVED,
			ProfitabilityAnalysisStatus.REJECTED].includes(values.status),
		[values.status],
	);

	const handleInterestRateSimulationChange = useCallback(
		(index: number, value: string) => {
			if (isReadOnly || !selectedItem || !selectedItem.salePriceFormation) return;

			const formattedValue = parseFloat(value.replace(',', '.')) || 0;

			const interestRateSimulationField = `saleOrderSimulationItems[${selectedItemIndex}].indirectCostSimulations[${index}].interestRateSimulation`;
			setFieldValue(interestRateSimulationField, formattedValue);

			const indirectCosts = selectedItem.indirectCostSimulations || [];
			const updatedIndirectCosts = indirectCosts
				.map((cost, idx) => (idx === index
					? { ...cost, interestRateSimulation: formattedValue } : cost));

			const totalIndirectCostSimulation = updatedIndirectCosts.reduce(
				(acc, cost) => acc + (cost.interestRateSimulation || 0),
				0,
			);
			const totalIndirectCostSimulationField = `saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.totalIndirectCostSimulation`;
			setFieldValue(totalIndirectCostSimulationField, totalIndirectCostSimulation);

			const sppvUnitValueI = calculateUnitValueIndirectCost(
				selectedItem.salePriceFormation.totalDirectCost || 0,
				selectedItem.salePriceFormation.indexValueRangeCommissionSimulation || 0,
				formattedValue,
				totalIndirectCostSimulation,
				selectedItem.commission,
			);
			const sppvUnitValueIField = `saleOrderSimulationItems[${selectedItemIndex}].indirectCostSimulations[${index}].sppvUnitValueI`;
			setFieldValue(sppvUnitValueIField, sppvUnitValueI);

			const totalSPPVUnitValueI = updatedIndirectCosts.reduce(
				(acc, cost, idx) => acc + (idx === index ? sppvUnitValueI : cost.sppvUnitValueI || 0),
				0,
			);
			const totalUnitValueIndirectCostSimulationField = `saleOrderSimulationItems[${selectedItemIndex}].totalUnitValueIndirectCostSimulation`;
			setFieldValue(totalUnitValueIndirectCostSimulationField, totalSPPVUnitValueI);
		},
		[isReadOnly, selectedItem, selectedItemIndex, setFieldValue],
	);

	const handleBlur = useCallback(
		(event, indirectCost) => {
			const { value } = event.target;
			const formattedValue = parseFloat(value.replace(',', '.')) || 0;

			upsertIndirectCostSimulation({
				saleOrderSimulationItemId: selectedItem.id,
				indirectCostId: indirectCost.indirectCostId,
				interestRateSimulation: formattedValue || indirectCost.interestRate,
			});
		},
		[selectedItem, upsertIndirectCostSimulation],
	);

	const backgroundColor = useMemo(
		() => (showExtraColumns ? 'primary.dark' : 'primary.main'),
		[showExtraColumns],
	);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Box sx={{ display: 'flex', position: 'relative' }}>
					<TableContainer component={Paper} sx={{ flexGrow: 1 }}>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell sx={{ width: '20%' }}>
										<strong>Variáveis</strong>
									</TableCell>
									<TableCell align="right" sx={{ width: '10%' }}>
										<strong>Índice</strong>
									</TableCell>
									<TableCell align="right" sx={{ width: '20%' }}>
										<strong>R$ Unitário</strong>
									</TableCell>
									{showExtraColumns && (
										<>
											<TableCell align="right" sx={{ width: '25%', backgroundColor: '#f0f0f0' }}>
												<strong>Índice</strong>
											</TableCell>
											<TableCell align="right" sx={{ width: '25%', backgroundColor: '#f0f0f0' }}>
												<strong>R$ Unitário</strong>
											</TableCell>
										</>
									)}
									<TableCell
										sx={{
											width: '0.001%',
											backgroundColor,
											padding: 0,
											textAlign: 'center',
										}}
									>
										<Tooltip title={showExtraColumns ? 'Ocultar colunas de simulação' : 'Visualizar colunas de simulação'}>
											<IconButton sx={{ color: 'white' }} onClick={() => setShowExtraColumns(!showExtraColumns)}>
												{showExtraColumns ? <VisibilityOffIcon /> : <VisibilityIcon />}
											</IconButton>
										</Tooltip>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{selectedItem.indirectCostSimulations?.map((indirectCost, index) => (
									<TableRow key={indirectCost.indirectCostId || `indirect-cost-${index}`}>
										<TableCell sx={{ padding: '6px 16px' }}>
											{indirectCost.indirectCost?.description}
										</TableCell>
										<TableCell align="right" sx={{ padding: '6px 16px' }}>
											{indirectCost.interestRate.toFixed(2)}
											%
										</TableCell>
										<TableCell align="right" sx={{ padding: '6px 16px' }}>
											{currencyBRLMask((indirectCost.unitValueI || 0), { decimalPlaces: 3 })}
										</TableCell>
										{showExtraColumns && (
											<>
												<TableCell align="right" sx={{ padding: '0 8px', backgroundColor: '#f0f0f0' }}>
													<Input.FinancialInputField
														name={`saleOrderSimulationItems[${selectedItemIndex}].indirectCostSimulations[${index}].interestRateSimulation`}
														margin="none"
														onChange={(e) => handleInterestRateSimulationChange(
															index,
															e.target.value,
														)}
														InputProps={{
															style: {
																height: '25px',
															},
														}}
														onBlur={(event) => handleBlur(event, indirectCost)}
														decimalScale={3}
														isPercentage
														disabled={isReadOnly}
													/>
												</TableCell>
												<TableCell align="right" sx={{ padding: '6px 16px', backgroundColor: '#f0f0f0' }}>
													{
														currencyBRLMask(
															(indirectCost.sppvUnitValueI || 0),
															{ decimalPlaces: 3 },
														)
													}
												</TableCell>
											</>
										)}
										<TableCell sx={{ width: '40px', padding: 0 }} />
									</TableRow>
								))}
								<TableRow>
									<TableCell align="left" sx={{ fontWeight: 'bold' }}>Total</TableCell>
									<TableCell align="right" sx={{ fontWeight: 'bold' }}>
										{formatPercentage(selectedItem?.salePriceFormation?.totalIndirectCost || 0)}
									</TableCell>
									<TableCell align="right" sx={{ fontWeight: 'bold' }}>
										{
											currencyBRLMask(
												(selectedItem?.totalUnitValueIndirectCost || 0),
												{ decimalPlaces: 3 },
											)
										}
									</TableCell>
									{showExtraColumns && (
										<>
											<TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
												{formatPercentage(selectedItem?.totalIndirectCostSimulation || 0)}
											</TableCell>
											<TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
												{
													currencyBRLMask(
														(selectedItem
															?.totalUnitValueIndirectCostSimulation || 0),
														{ decimalPlaces: 3 },
													)
												}
											</TableCell>
										</>
									)}
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{
							width: '40px',
							backgroundColor,
							position: 'absolute',
							right: 0,
							top: '40px',
							bottom: 0,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: 'calc(100% - 40px)',
						}}
					>
						<Typography
							sx={{
								writingMode: 'vertical-rl',
								transform: 'rotate(180deg)',
								color: 'white',
								fontWeight: 'bold',
								fontSize: '0.75rem',
								letterSpacing: '1px',
								textAlign: 'center',
								lineHeight: 'normal',
							}}
						>
							Simulação
						</Typography>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default IndirectCostSimulationTable;
