import { AxiosResponse } from 'axios';
import { ITask } from '../containers/Mobile/TaskAssets';
import api from './api';
import { IMessageResponse } from './saleOrder';

type ResponseTasksOrigin = {
  data: ITask[];
  count: number;
};

export type SaveTaskType = {
  taskId: string;
  quantity?: number;
}

export const getLocationDestiny = (): Promise<AxiosResponse<ResponseTasksOrigin>> => api.get<ResponseTasksOrigin>('/inventory-task?action=2&status[]=1&status[]=2');

export const saveTakeTaskBatch = (data: SaveTaskType[]): Promise<AxiosResponse<IMessageResponse>> => api.post<SaveTaskType[], AxiosResponse<IMessageResponse>>('/inventory-task/takeTask/batch', data);

export const saveTaskCountBatch = (data: SaveTaskType[]): Promise<AxiosResponse<IMessageResponse>> => api.post<SaveTaskType, AxiosResponse<IMessageResponse>>('/inventory-task/taskCount/batch', data);
