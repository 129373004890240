import React from 'react';
import {
	Box,
	Button,
	Modal,
	Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import Input from '../../Common/Form/Input';
import { DatePicker } from '../../Common/Form/DatePicker';

interface ModalFormProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	fields: { name: string; label: string; type?: string; adornment?: string; }[];
	onConfirm: () => void;
}

const ModalForm = ({
	isOpen,
	onClose,
	title,
	fields,
	onConfirm,
}: ModalFormProps): JSX.Element => {
	const { values } = useFormikContext<any>();

	const allFieldsFilled = fields.every((field) => !!values[field.name]);

	return (
		<Modal open={isOpen} onClose={onClose}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					bgcolor: 'background.paper',
					border: '2px solid #000',
					boxShadow: 24,
					p: 4,
				}}
			>
				<Typography variant="h6" component="h2" gutterBottom>
					{title}
				</Typography>
				{fields.map((field) => (
					field.type === 'date' ? (
						<DatePicker
							key={field.name}
							name={field.name}
							label={field.label}
							minDate={new Date()}
							required
						/>
					) : (
						<Input.InputField
							key={field.name}
							name={field.name}
							label={field.label}
							variant="outlined"
							margin="dense"
							type={field.type || 'text'}
							fullWidth
							InputProps={field.adornment ? { endAdornment: field.adornment } : undefined}
							required
						/>
					)
				))}
				<Box mt={2} display="flex" justifyContent="space-between">
					<Button
						onClick={onClose}
						variant="outlined"
						color="primary"
					>
						Cancelar
					</Button>
					<Button
						onClick={onConfirm}
						variant="contained"
						color="primary"
						disabled={!allFieldsFilled}
					>
						Confirmar
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default ModalForm;
