import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import LocationTypeTable from './LocationTypeTable';
import { ILocationType } from '../../containers/Location/LocationAssets';

interface LocationTypeProps {
	loading: boolean;
	getLocationTypes: () => void;
	locationTypes: ILocationType[];
	handleDeleteLocationType: (id: string) => void;
}

const LocationType = ({
	loading,
	getLocationTypes,
	handleDeleteLocationType,
	locationTypes,
}: LocationTypeProps): JSX.Element => {
	const headerButtonsProps: PageHeaderButtonProps[] = useMemo(() => [
		{
			variant: 'contained',
			color: 'primary',
			type: 'submit',
			fullWidth: true,
			component: Link,
			state: { locations: locationTypes },
			to: 'edit',
			text: 'Adicionar',
		},
	], [locationTypes]);

	const pageHeaderMemo = useMemo(() => <PageHeader title="Tipo de Localização" buttons={headerButtonsProps} />, [headerButtonsProps]);

	return (
		<Box className="content">
			{pageHeaderMemo}
			<LocationTypeTable
				handleDeleteLocationType={handleDeleteLocationType}
				getLocationTypes={getLocationTypes}
				rows={locationTypes}
				loading={loading}
			/>
		</Box>
	);
};

export default LocationType;
