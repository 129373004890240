import React, { ReactElement, useMemo } from 'react';
import Box from '@mui/material/Box';
import TabsMui from '@mui/material/Tabs';
import TabMui from '@mui/material/Tab';

interface TabPanelProps {
	children: JSX.Element;
	id: string;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
	const {
		children, value, index, id, ...other
	} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`${id}panel-${index}`}
			aria-labelledby={`${id}-${index}`}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
};

const a11yProps = (id: string, index: number): {
    id: string;
    'aria-controls': string;
} => ({
	id: `${id}-${index}`,
	'aria-controls': `${id}panel-${index}`,
});

interface Tab {
	label: string;
	content: ReactElement;
}

interface TabsProps {
	id: string;
	tabs: Tab[];
}

const Tabs = ({ id, tabs }: TabsProps): JSX.Element => {
	const [value, setValue] = React.useState(0);

	const TabsHeader = useMemo(() => tabs.map((tab, index) => (
		<TabMui
			key={tab.label}
			label={tab.label}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...a11yProps(id, index)}
		/>
	)), [id, tabs]);

	const TabsPanel = useMemo(() => tabs.map((tab, index) => (
		<TabPanel key={tab.label} id={id} value={value} index={index}>
			{tab.content}
		</TabPanel>
	)), [id, tabs, value]);

	const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<TabsMui
					value={value}
					onChange={handleChange}
					aria-label={id}
				>
					{TabsHeader}
				</TabsMui>
			</Box>
			{TabsPanel}
		</Box>
	);
};

export default Tabs;
