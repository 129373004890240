import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { HourProjectQueryParams, initialValuesLoad } from '../../../interfaces/HourProject';
import Input from '../../Common/Form/Input';
import Autocomplete from '../../Common/Form/Autocomplete';
import RadioField from '../../Common/Form/Radio';
import { UserClock } from '../../../containers/HourProject/ClockHoursAssets';
import { DatePicker } from '../../Common/Form/DatePicker';

const statusOptions = [
	{ id: 'allTasks', value: '0', label: 'Todas' },
	{ id: 'hasQuantity', value: '1', label: 'Com Horas' },
	{ id: 'hasAppointment', value: '2', label: 'Com Horas Registradas' },
	{ id: 'hasOverflowTask', value: '3', label: 'Pendente de Aprovação' },
];

const getStatusFromInitialValues = (values: HourProjectQueryParams): string => {
	if (values.hasAppointment) return '2';
	if (values.hasQuantity) return '1';
	if (values.hasOverflowTask) return '3';
	return '0';
};

interface IFilterProps {
	sendFilter: (values: HourProjectQueryParams) => void;
	initialValues: HourProjectQueryParams;
	users: UserClock[];
}

const HourProjectFilter = ({ sendFilter, initialValues, users }: IFilterProps): JSX.Element => {
	const onSubmit = useCallback((values: HourProjectQueryParams) => {
		const filterValues: any = { ...values };
		delete filterValues.status;
		sendFilter(filterValues);
	}, [sendFilter]);

	const onReset = useCallback((resetForm) => {
		sendFilter(initialValuesLoad);
		resetForm();
	}, [sendFilter]);

	return (
		<Paper sx={{ p: 2, width: '100%', mb: 2 }}>
			<Formik
				initialValues={{
					...initialValues,
					status: getStatusFromInitialValues(initialValues),
				}}
				onSubmit={onSubmit}
			>
				{({ setFieldValue, resetForm }) => (
					<Form>
						<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
							<Grid item xs={12} sm={6}>
								<DatePicker name="startDate" label="Data Inicial" required />
							</Grid>
							<Grid item xs={12} sm={6}>
								<DatePicker name="endDate" label="Data Final" required />
							</Grid>
							<Grid item xs={12}>
								<Input.InputField
									id="name"
									name="name"
									label="Nome/Código"
									autoComplete="off"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<Autocomplete<{ name: string; id: string, login: string; }>
									multiple
									limitTags={1}
									label="Usuário"
									name="userId"
									options={users || []}
									labelKey="name"
									valueKey="id"
									valueLabel="login"
								/>
							</Grid>
							<Grid item xs={12}>
								<RadioField
									name="status"
									label="Status da Tarefa"
									options={statusOptions}
									orientation="column"
									onChange={(event) => {
										const { value } = event.target;
										setFieldValue('hasQuantity', false);
										setFieldValue('hasAppointment', false);
										setFieldValue('hasOverflowTask', false);

										switch (value) {
											case '1':
												setFieldValue('hasQuantity', true);
												break;
											case '2':
												setFieldValue('hasAppointment', true);
												break;
											case '3':
												setFieldValue('hasOverflowTask', true);
												break;
											default:

												break;
										}
									}}
								/>
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
								<Button variant="outlined" onClick={() => onReset(resetForm)}>
									Restaurar
								</Button>
								<Button variant="contained" type="submit">
									Filtrar
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};

export default HourProjectFilter;
