import Decimal from 'decimal.js';
import Yup from '../../helpers/yupMethods';
import { InventoryStatus } from '../../enums/inventory';

export interface IBranchInventory {
    id: string;
    name: string;
    company: {
        id: string;
        name: string;
    }
}

export enum InventoryType {
    PARTIAL,
    GERAL,
}

export interface BranchParams {
    companyId?: string;
}

export interface ParamsStartInventory {
    branchId?: string | null;
    companyId?: string| null;
    type?: InventoryType | null;
}

export interface ParamsStartInventoryPartial {
    branchId: string | null;
    companyId: string| null;
    allItemsSelected: boolean;
    locationProductIds: string[];
    productIds?: string[];
    locationValue?: string;
}

export const statusTask = ['Pendente', 'Em andamento', 'Concluído'];

export const drawerFilterInventory = Yup.object().shape({
	startDate: Yup.date().nullable(),
	endDate: Yup.date()
		.nullable()
		.when('startDate', (startDate, schema) => (startDate
			? schema
				.required('Informe uma data')
				.min(
					startDate,
					"A data do campo 'Data final', não pode ser menor que o campo 'Data inicial'",
				)
			: schema)),
});

export type InventoryTaskPostParams = {
    productId: string,
    inventoryId: string,
    locationId: string
}

type Product = {
    description: string,
    code: string,
}

type Location = {
    barCode: string,
    name: string,
}

export interface IInventoryItem {
    id: string,
    countNumber: number
    product: Product,
    location: Location,
    quantityCounted: number | Decimal
}

export interface InventoryOperator {
    name: string
}

export interface InventoryQueryParams {
    skip?: number;
    take?: number;
    startDate?: Date | string;
    endDate?: Date | string;
    companyId?: string;
    status?: InventoryStatus[];
    document?: string;
    orderBy?: 'asc' | 'desc';
}

export interface IInventory {
    id: string;
    date: string;
    status: InventoryStatus;
    document: string;
    branch: IBranchInventory;
}

export interface IInventorySummary {
    id: string;
    locationCode: string;
    productCode: string;
    productDescription: string;
    previousQuantity: number;
    countNumber1?: number;
    countNumber2?: number;
    countNumber3?: number;
    countNumber4?: number;
}

export interface IInventoryResponse {
    inventorys: IInventory[];
    count: number;
}

export interface InventoryTaskDetails {
	id: string;
	locationOrigin: {
		barCode: string;
		name: string;
	};
	product: {
		barCode: string;
		code: string;
		description: string;
	}
}

export interface InventoryByIdResponse {
    id: string;
	countNumber: number;
	inventoryTask: InventoryTaskDetails;
    startCount: boolean,
    info: string
}

export interface IInventoryItems {
    id: string,
    date: Date | string | null,
    inventoryItem: IInventoryItem,
    countNumber: number,
    operator: InventoryOperator | null
}

export interface IInventoryItemsResponse {
	inventory: IInventoryInfo;
    inventoryItems: IInventoryItems[];
    count: number;
}

export interface IInventorySummaryResponse {
    data: IInventorySummary[];
    inventory: IInventoryInfoReport;
    count: number;
}

export interface IInventoryDifferenceResponse {
    data: IInventoryDifferenceItems[];
    count: number;
    inventory: IInventoryInfoReport
}

export interface IInventoryDifferenceItems {
    productId: string,
    product: string,
    quantityBefore: number,
    valueBefore: number,
    quantityAfter: number,
    valueAfter: number,
    quantityDifference: number,
    valueDifference: number,
}

export interface IInventoryInfo {
    countNumber: number,
    startCount: boolean,
    haveItemsForNextCount: boolean,
    status: InventoryStatus,
    responsibleName: string,
    date: Date | string,
    document: string
}

export interface IInventoryInfoReport {
    responsibleName: string,
    date: Date | string,
    document: string
}
