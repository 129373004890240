import React, {
	useCallback,
	useState,
} from 'react';
import {
	Drawer,
	Box,
	Typography,
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DescriptionIcon from '@mui/icons-material/Description';
import SalePriceFormationSimulationTable from '../Forms/SalePriceFormationSimulationTable';
import AveragePaymentTermSimulationTable from '../Forms/AveragePaymentTermSimulationTable';
import IndirectCostSimulationTable from '../Forms/IndirectCostSimulationTable';
import RangePriceCommissionSimulationTable from '../Forms/RangePriceCommissionSimulationTable';
import { IIndirectCostSaleOrderSimulationItem } from '../../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';

interface SimulationDrawerProps {
	updateSelectedRangeSimulation(
		saleOrderSimulationItemId: string,
		rangeCommissionId: string,
	): void;
	updateSelectedAveragePaymentSimulation(
		saleOrderSimulationItemId: string,
		averagePaymentTermId: string,
	): void;
	upsertIndirectCostSimulation(data: IIndirectCostSaleOrderSimulationItem): void;
	updateSimulationPrice(
		saleOrderSimulationItemId: string,
		simulationPrice: number,
	): void;
}

const SimulationDrawer = ({
	updateSelectedRangeSimulation,
	updateSelectedAveragePaymentSimulation,
	upsertIndirectCostSimulation,
	updateSimulationPrice,
}: SimulationDrawerProps): JSX.Element | null => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const handleDrawerToggle = useCallback(() => {
		setIsDrawerOpen((prevOpen) => !prevOpen);
	}, []);

	return (
		<>
			<Box
				sx={{
					position: 'fixed',
					right: 0,
					top: 0,
					bottom: 0,
					width: '40px',
					backgroundColor: 'primary.main',
					color: '#fff',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'pointer',
					zIndex: 1100,
					'&:hover': {
						backgroundColor: 'primary.dark',
					},
				}}
				onClick={handleDrawerToggle}
			>
				<DescriptionIcon sx={{ marginBottom: 1 }} />
				<ChevronLeftIcon />
				<Typography variant="body2" sx={{ writingMode: 'vertical-rl', textOrientation: 'mixed' }}>
					Simulação
				</Typography>
			</Box>
			<Drawer
				variant="temporary"
				anchor="right"
				open={isDrawerOpen}
				onClose={handleDrawerToggle}
				sx={{
					'& .MuiDrawer-paper': {
						width: '1200px',
					},
				}}
			>
				<Box
					sx={{
						marginTop: '60px',
						width: '1200px',
						padding: 2,
						height: '100%',
						boxSizing: 'border-box',
					}}
					role="presentation"
				>
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">Formação de Preço</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<SalePriceFormationSimulationTable updateSimulationPrice={updateSimulationPrice} />
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">Prazo Médio de Pagamento</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<AveragePaymentTermSimulationTable
								updateSelectedAveragePaymentSimulation={
									updateSelectedAveragePaymentSimulation
								}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">Custos Indiretos</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<IndirectCostSimulationTable
								upsertIndirectCostSimulation={upsertIndirectCostSimulation}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">Faixa Comissão</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<RangePriceCommissionSimulationTable
								updateSelectedRangeSimulation={updateSelectedRangeSimulation}
							/>
						</AccordionDetails>
					</Accordion>
				</Box>
			</Drawer>
		</>
	);
};

export default SimulationDrawer;
