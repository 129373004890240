import React, { useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Input from '../../Common/Form/Input';
import CheckboxField from '../../Common/Form/Checkbox';
import FormObserver from '../../Common/Form/FormObserver';
import { PeriodPicker } from '../../Common/Form/PeriodPicker';
import { purchaseOrderApprovalStatusFilter } from '../../../constants/purchaseOrderApprovalStatus';
import { PurchaseOrderApprovalStatus } from '../../../enums/PurchaseOrderApprovalStatus';
import BudgetOrderFourFieldsFilter from '../../Common/BudgetOrderFourFields/BudgetOrderFourFieldsFilter';
import { IFilterFieldsValues } from '../../../interfaces/BudgetOrderFourFields';
import { filterObject } from '../../../helpers/Utils';

interface IManagePurchaseOrdersFilterProps {
	filterFieldsValues: IFilterFieldsValues | null;
	sendFilter: (values: any) => void
}

interface IForm {
	year: number;
	initialMonth: number;
	finalMonth: number;
	nrOrder: string;
	providerName: string;
	productDescription: string;
	active: boolean;
	rejected: boolean;
	approved: boolean;
	waitingapprovalcl: boolean;
	waitingapprovalc: boolean;
	selectAll: boolean;
	status?: PurchaseOrderApprovalStatus[];
}

const initialValues = {
	year: new Date().getFullYear(),
	initialMonth: new Date().getMonth(),
	finalMonth: new Date().getMonth(),
	nrOrder: '',
	providerName: '',
	productDescription: '',
	active: true,
	rejected: true,
	approved: true,
	waitingapprovalcl: true,
	waitingapprovalc: true,
	accountingItems: [],
	costCenters: [],
	accountingAccounts: [],
	classValues: [],
	selectAll: false,
};

const getOmittedFilterValues = (
	filterValues: IForm,
): Partial<IForm> => filterObject(
	filterValues,
	['active', 'rejected', 'approved', 'waitingapprovalcl', 'waitingapprovalc'],
);

const ManagePurchaseOrdersFilter = ({
	filterFieldsValues,
	sendFilter,
}: IManagePurchaseOrdersFilterProps): JSX.Element => {
	const onSubmit = useCallback((values: IForm) => {
		const filterValues = cloneDeep(values);
		filterValues.status = [];

		if (filterValues.active) { filterValues.status.push(0); }
		if (filterValues.rejected) { filterValues.status.push(1); }
		if (filterValues.approved) { filterValues.status.push(2); }
		if (filterValues.waitingapprovalcl) { filterValues.status.push(3); }
		if (filterValues.waitingapprovalc) { filterValues.status.push(4); }

		if (filterValues.status.length === 5) { filterValues.status = []; }

		sendFilter(getOmittedFilterValues(filterValues));
	}, [sendFilter]);

	const onReset = useCallback((handleReset) => {
		sendFilter(getOmittedFilterValues(initialValues));
		handleReset();
	}, [sendFilter]);

	return (
		<Paper sx={{ p: 2, width: '100%' }}>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
			>
				{({ values, handleReset }) => (
					<Form>
						<FormObserver />
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<PeriodPicker />
							</Grid>
							<Grid item xs={6}>
								<Input.InputField
									id="nrOrder"
									name="nrOrder"
									label="Nr do pedido"
								/>
							</Grid>
							<Grid item xs={6}>
								<Input.InputField
									id="providerName"
									name="providerName"
									label="Fornecedor"
								/>
							</Grid>
							<Grid item xs={6}>
								<Input.InputField
									id="productDescription"
									name="productDescription"
									label="Produto"
								/>
							</Grid>
							{filterFieldsValues && (
								<BudgetOrderFourFieldsFilter
									accountingAccounts={filterFieldsValues.accountingAccounts}
									accountingItems={filterFieldsValues.accountingItems}
									classValues={filterFieldsValues.classValues}
									costCenters={filterFieldsValues.costCenters}
									breakpoints={{ xs: 6, xl: 3 }}
									limitTags={2}
								/>
							)}
							<Grid item xs={12}>
								<CheckboxField.CheckboxField
									name="status"
									options={purchaseOrderApprovalStatusFilter.map((status) => ({
										id: status.id, checked: (values as any)[status.id], label: status.label,
									}))}
									orientation="row"
								/>
							</Grid>
							<Grid item xs={6}>
								<CheckboxField.CheckboxField
									name="selectAll"
									options={[
										{ id: 'selectAll', checked: values.selectAll, label: 'Selecionar Filtrados' },
									]}
									orientation="row"
								/>
							</Grid>
							<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
								<Button variant="outlined" onClick={() => onReset(handleReset)}>RESTAURAR</Button>
								<Button variant="contained" type="submit">FILTRAR</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};

export default ManagePurchaseOrdersFilter;
