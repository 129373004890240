export const defaultCardSpacing = 6;

export const budgetDashboardInitialFilter = {
	year: new Date().getFullYear(),
	initialMonth: 0,
	finalMonth: new Date().getMonth(),
	accountingItems: [],
	costCenters: [],
	accountingAccounts: [],
	classValues: [],
};

export const dataGridVisions = [
	{
		value: 'accountingItem',
		label: 'Filial',
	},
	{
		value: 'classValue',
		label: 'Cliente/Fornecedor',
	},
	{
		value: 'costCenter',
		label: 'Centro de Custo',
	},
	{
		value: 'accountingAccount',
		label: 'Conta Contábil',
	},
];
