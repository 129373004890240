import React, {
	SyntheticEvent, useCallback, useEffect, useMemo,
} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import AutocompleteMui, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import { CircularProgress, TextField } from '@mui/material';
import { ProductQueryParams } from '../../../interfaces/ProductQueryParams';
import {
	ILocation,
	initialValues,
	validationSchemaProduct,
} from '../../../containers/Location/LocationAssets';
import Input from '../../Common/Form/Input';
import { IProduct, IProductWithoutDetails } from '../../../containers/Product/ProductAssets';

interface AddProductModalProps {
  open: boolean;
  onClose: () => void;
  location: ILocation | null;
  loading: boolean;
  products: IProductWithoutDetails[];
  getProducts: (queryParams: ProductQueryParams) => void;
  onSubmitAdd(values: AddProductFormProps, location: ILocation | null): Promise<void>;
}

export interface AddProductFormProps {
  valuedQuantity: string;
  productId: string;
}

const AddProductModal = ({
	open,
	onClose,
	products,
	location,
	loading,
	getProducts,
	onSubmitAdd,
}: AddProductModalProps): JSX.Element => {
	const initialValuesLoad = useMemo(
		() => (location
			? {
				...initialValues,
				// product: products.find((product) => product.id === location.pro)
				locationId: location.id,
			}
			: initialValues),
		[location],
	);

	const handleAddLocationProduct = useCallback(
		async (values: AddProductFormProps) => {
			if (location) {
				await onSubmitAdd(values, location);
			}
		},
		[location, onSubmitAdd],
	);

	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<Formik
				initialValues={initialValuesLoad}
				validationSchema={validationSchemaProduct}
				onSubmit={handleAddLocationProduct}
			>
				{({ values, errors, setFieldValue }) => (
					<Form noValidate>
						<DialogTitle>Adicionar Produto</DialogTitle>

						<DialogContent>
							<AutocompleteMui
								value={values.product || null}
								onChange={(
									event: SyntheticEvent,
									newValue: IProductWithoutDetails | null,
								) => {
									if (newValue) {
										setFieldValue('product', newValue);
										setFieldValue('productId', newValue.id);
									}
								}}
								onInputChange={(
									event: SyntheticEvent,
									value: string,
									reason: AutocompleteInputChangeReason,
								) => {
									const defaultFilterProduct = { skip: 0 };

									if (value?.length > 2 && reason === 'input') {
										getProducts({ ...defaultFilterProduct, description: value });
									} else if (reason === 'clear') {
										setFieldValue('product', null);
										setFieldValue('productId', '');
										getProducts(defaultFilterProduct);
									}
								}}
								id="product"
								getOptionLabel={(product) => `${product.code} - ${product.description}`}
								options={products}
								sx={{ mt: 2 }}
								renderInput={(params) => (
									<TextField
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...params}
										fullWidth
										label="Produto"
										placeholder="Produto"
										error={Boolean(errors.productId)}
										helperText={errors.productId}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{loading ? (
														<CircularProgress color="inherit" size={26} />
													) : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
							/>

							<Input.InputField
								type="number"
								label="Capacidade"
								name="valuedQuantity"
								sx={{
									mt: 2,
									'& input[type=number]': {
										MozAppearance: 'textfield',
									},
									'& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
										WebkitAppearance: 'none',
										margin: 0,
									},
								}}
								fullWidth
								required
							/>
						</DialogContent>
						<DialogActions sx={{ gap: 1 }}>
							<Button onClick={onClose}>Cancelar</Button>
							<LoadingButton
								onClick={() => handleAddLocationProduct(values)}
								variant="contained"
								loading={loading}
							>
								Salvar
							</LoadingButton>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default AddProductModal;
