import { AxiosResponse } from 'axios';
import api from './api';
import { UserType } from '../containers/Order/PurchaseOrderApproval';
import { ForApprovalQueryParams } from '../interfaces/ForApprovalQueryParams';
import { ManagePurchaseOrders } from '../interfaces/ManagePurchaseOrders';
import {
	IPurchaseByBudget,
	IApprovePurchase,
	IPurchasesWithJustificative,
	ISendToOverrun,
} from '../interfaces/PurchaseOrderApproval';

export const getPurchasesCount = (type?: UserType): Promise<AxiosResponse<{ count: number; }>> => api.get<{ count: number; }>('/order/forApprovalCount', {
	params: { type },
});

export const getAllPurchasesByBudget = (params: ManagePurchaseOrders): Promise<AxiosResponse<IPurchaseByBudget>> => api.get<IPurchaseByBudget>('/order/findAllByBudget', {
	params,
});

export const getPurchases = (params: ForApprovalQueryParams): Promise<AxiosResponse<IPurchaseByBudget>> => api.get<IPurchaseByBudget>('/order/forApproval', {
	params,
});

export const getPurchaseById = (id: string): Promise<AxiosResponse<any>> => api.get<any[]>(`order/${id}`);

export const getApportionmentById = (id: string): Promise<AxiosResponse<any>> => api.get<any[]>(`order/apportionment/${id}`);

export const savePurchase = (data: any): Promise<AxiosResponse<any>> => api.post<any, AxiosResponse<any>>('/order', data);

export const updatePurchase = (id: string, data: any): Promise<AxiosResponse<any>> => api.post<any, AxiosResponse<any>>(`/order/${id}`, data);

export const approvePurchase = (
	budgetId: string,
	ids: string[],
	type?: UserType,
): Promise<AxiosResponse<IApprovePurchase>> => api.post<IApprovePurchase, AxiosResponse<any>>(
	`/order/approve${type !== undefined && type !== null ? (`?type=${type}`) : ''}`,
	{ budgetId, ids },
);

export const rejectPurchase = (
	data: IPurchasesWithJustificative,
): Promise<AxiosResponse<any>> => api.post<IPurchasesWithJustificative, AxiosResponse<any>>(
	'/order/reject',
	data,
);

export const sendToOverrun = (
	data: ISendToOverrun,
): Promise<AxiosResponse<any>> => {
	const { type, ...postData } = data;

	return api.post<ISendToOverrun, AxiosResponse<any>>(
		'/order/overrun',
		postData,
		{
			params: { type },
		},
	);
};

export const reactivatePurchase = (
	data: IPurchasesWithJustificative,
): Promise<AxiosResponse<any>> => api.post<IPurchasesWithJustificative, AxiosResponse<any>>('/order/reactivate', data);
