import { AxiosResponse } from 'axios';
import api from './api';
import {
	IInventoryDifferenceResponse,
	IInventoryItemsResponse,
	IInventoryResponse,
	IInventorySummaryResponse,
	InventoryByIdResponse,
	InventoryQueryParams,
	InventoryTaskPostParams,
	ParamsStartInventory,
	ParamsStartInventoryPartial,
} from '../containers/Inventory/InventoryAssets';
import {
	InventoryDifferenceParams,
	InventoryItemsByIdParams,
	InventorySummaryParams,
	ParamsFinishInventoryTask,
	ParamsStartInventoryTask,
	RecountParams,
} from '../interfaces/InventoryParams';
import { IMessageResponse } from '../interfaces/MessageResponse';

export const getInventorySummaryById = (id: string, params: InventorySummaryParams): Promise<
AxiosResponse<IInventorySummaryResponse>> => api.get<IInventorySummaryResponse, AxiosResponse<IInventorySummaryResponse>>(`inventory/summary/${id}`, { params });

export const getInventoryDifferenceById = (id: string, params: InventoryDifferenceParams): Promise<
AxiosResponse<IInventoryDifferenceResponse>> => api.get<IInventoryDifferenceResponse, AxiosResponse<IInventoryDifferenceResponse>>(`inventory/difference/${id}`, { params });

export const getInventories = (params: Partial<InventoryQueryParams>): Promise<
AxiosResponse<IInventoryResponse>> => api.get<IInventoryResponse, AxiosResponse<IInventoryResponse>>('inventory', { params });

export const getInventoryById = (id: string, params: any): Promise<
AxiosResponse<InventoryByIdResponse>> => api.get<InventoryByIdResponse, AxiosResponse<InventoryByIdResponse>>(`inventory/${id}`, { params });

export const getInventoryItemsById = (id: string, params: InventoryItemsByIdParams): Promise<AxiosResponse<IInventoryItemsResponse>> => api.get<IInventoryItemsResponse>(`inventory-items/${id}`, { params });

export const startInventoryAll = (data: ParamsStartInventory): Promise<AxiosResponse<IMessageResponse>> => api.post('/inventory/process/all', data);

export const startInventoryPartial = (data: ParamsStartInventoryPartial): Promise<AxiosResponse<IMessageResponse>> => api.post('/inventory/process/partial', data);

export const createInventoryTask = (
	data: InventoryTaskPostParams,
): Promise<AxiosResponse<IMessageResponse>> => api.post('/inventory-items', data);

export const finishInventoryTask = (data: ParamsFinishInventoryTask): Promise<AxiosResponse<IMessageResponse>> => api.post<ParamsFinishInventoryTask, AxiosResponse<IMessageResponse>>('/inventory-items/finish', data);

export const finishInventory = (id: string): Promise<AxiosResponse<IMessageResponse>> => api.patch(`/inventory/${id}`);

export const startInventoryTask = (data?: ParamsStartInventoryTask): Promise<AxiosResponse<IMessageResponse>> => api.post<ParamsStartInventoryTask, AxiosResponse<IMessageResponse>>('/inventory-items/start', data);

export const postRecount = (data: RecountParams): Promise<AxiosResponse<IMessageResponse>> => api.post<RecountParams, AxiosResponse<IMessageResponse>>('/inventory-items/recount', data);

export const reportDifferencePDF = (id: string): Promise<AxiosResponse> => api.get(`/inventory/exportDifference/${id}`, {
	responseType: 'blob',
});

export const reportSummaryPDF = (id: string): Promise<AxiosResponse> => api.get(`/inventory/exportSummary/${id}`, {
	responseType: 'blob',
});

export const reportByOperatorPDF = (id: string): Promise<AxiosResponse> => api.get(`/inventory-items/exportByOperator/${id}`, {
	responseType: 'blob',
});
