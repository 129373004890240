import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import {
	Grid,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	FormControl,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import {
	ICreateAveragePaymentTerm,
	IAveragePaymentTermSalePriceFormation,
	IExtendedSalePriceFormation,
	IAveragePaymentTerm,
} from '../../../containers/SalePriceFormation/SalePriceFormationAssets';
import Input from '../../Common/Form/Input';
import Autocomplete from '../../Common/Form/Autocomplete';
import SwitchField from '../../Common/Form/Switch';
import ModalForm from '../Modals/ModalForm';

interface AveragePaymentTermFormProps {
	isReadOnly: boolean;
	averagePaymentTerms?: IAveragePaymentTerm[];
	createAveragePaymentTerm: (data: ICreateAveragePaymentTerm) => void;
	upsertAveragePaymentTermToFormation(data: IAveragePaymentTermSalePriceFormation): void;
	removeAveragePaymentTermFromFormation(id: string, salePriceFormationId: string): void;
}

const AveragePaymentTermForm = ({
	isReadOnly,
	averagePaymentTerms,
	createAveragePaymentTerm,
	upsertAveragePaymentTermToFormation,
	removeAveragePaymentTermFromFormation,
}: AveragePaymentTermFormProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<IExtendedSalePriceFormation>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const handleAddPaymentTerm = useCallback((): void => {
		const selectedPaymentTerm = averagePaymentTerms?.find(
			(paymentTerm) => paymentTerm.id === values.currentAveragePaymentTermId,
		);

		if (selectedPaymentTerm && values.id && values.currentAveragePaymentTermRate) {
			const newPaymentTerm: IAveragePaymentTermSalePriceFormation = {
				averagePaymentTermId: selectedPaymentTerm.id,
				salePriceFormationId: values.id,
				interestRate: values.currentAveragePaymentTermRate,
				description: selectedPaymentTerm.description,
			};

			upsertAveragePaymentTermToFormation(newPaymentTerm);
		}
		setFieldValue('currentAveragePaymentTermRate', '');
	}, [
		averagePaymentTerms,
		values.id,
		values.currentAveragePaymentTermRate,
		values.currentAveragePaymentTermId,
		setFieldValue,
		upsertAveragePaymentTermToFormation,
	]);

	const handleOpenModal = useCallback((): void => {
		setIsModalOpen(true);
	}, []);

	const handleCloseModal = useCallback((): void => {
		setIsModalOpen(false);
		setFieldValue('newAveragePaymentTermDescription', '');
		setFieldValue('newAveragePaymentTermRate', '');
	}, [setFieldValue]);

	const handleCreateNewAveragePaymentTerm = useCallback(() => {
		if (values.newAveragePaymentTermDescription && values.newAveragePaymentTermRate) {
			createAveragePaymentTerm({
				description: values.newAveragePaymentTermDescription,
				interestRate: parseFloat(values.newAveragePaymentTermRate),
				initialTerm: Number(values.newAveragePaymentTerm),
				finalTerm: Number(values.newAveragePaymentTerm),
			});
			handleCloseModal();
		}
	}, [values, createAveragePaymentTerm, handleCloseModal]);

	const handleAverageTermInputChange = useCallback((
		event: any,
		value: string,
		reason: string,
	): void => {
		if (reason === 'reset') {
			const selectedPayment = averagePaymentTerms?.find((averagePaymentTerm) => `${averagePaymentTerm.code} - ${averagePaymentTerm.description}` === value);
			if (selectedPayment) {
				setFieldValue('currentAveragePaymentTermId', selectedPayment.id);
				setFieldValue('currentAveragePaymentTermRate', selectedPayment.interestRate);
				setFieldValue('currentAveragePaymentTermDescription', selectedPayment.description);
			}
		} else if (reason === 'clear') {
			setFieldValue('currentAveragePaymentTermId', '');
			setFieldValue('currentAveragePaymentTermRate', '');
			setFieldValue('currentAveragePaymentTermCode', '');
			setFieldValue('currentAveragePaymentTermDescription', '');
		}
	}, [averagePaymentTerms, setFieldValue]);

	const isPaymentAdded = useCallback((
		averagePaymentTermId: string,
	) => values.averagePaymentTermSalePriceFormations?.some(
		(averagePaymentTerm) => averagePaymentTerm.averagePaymentTermId === averagePaymentTermId,
	), [values.averagePaymentTermSalePriceFormations]);

	const handleBlur = useCallback((event, paymentTerm) => {
		const { value } = event.target;
		if (!value) return;

		const updatedPaymentTerms = values.averagePaymentTermSalePriceFormations?.map(
			(existingPaymentTerm) => (existingPaymentTerm.averagePaymentTermId
				=== paymentTerm.averagePaymentTermId
				? { ...existingPaymentTerm, interestRate: value }
				: existingPaymentTerm),
		);

		if (updatedPaymentTerms) {
			const updatedPaymentTerm = updatedPaymentTerms.find((term) => term.averagePaymentTermId
				=== paymentTerm.averagePaymentTermId);
			if (updatedPaymentTerm) {
				upsertAveragePaymentTermToFormation(updatedPaymentTerm);
			}
		}
	}, [values.averagePaymentTermSalePriceFormations, upsertAveragePaymentTermToFormation]);

	const modalMemo = useMemo(() => (
		<ModalForm
			isOpen={isModalOpen}
			onClose={handleCloseModal}
			title="Prazo Médio Padrão"
			fields={[
				{ name: 'newAveragePaymentTermDescription', label: 'Descrição' },
				{ name: 'newAveragePaymentTerm', label: 'Prazo Médio', type: 'number' },
				{
					name: 'newAveragePaymentTermRate',
					label: 'Indice',
					type: 'number',
				},
			]}
			onConfirm={handleCreateNewAveragePaymentTerm}
		/>
	), [isModalOpen, handleCloseModal, handleCreateNewAveragePaymentTerm]);

	const availableAveragePaymentTerms = useMemo(() => averagePaymentTerms?.filter(
		(cost) => !isPaymentAdded(cost.id),
	) || [], [averagePaymentTerms, isPaymentAdded]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TableContainer component={Paper} sx={{ maxHeight: '322px' }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell sx={{ width: '25%' }}>Descrição</TableCell>
								<TableCell align="right" sx={{ width: '25%' }}>Indice</TableCell>
								<TableCell sx={{ width: '25%' }}>Desconto</TableCell>
								<TableCell align="right" sx={{ width: '25%' }}>Ações</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{values.averagePaymentTermSalePriceFormations?.map((averagePaymentTerm, index) => (
								<TableRow key={averagePaymentTerm.averagePaymentTermId}>
									<TableCell sx={{ padding: '6px 16px' }}>{averagePaymentTerm.description}</TableCell>
									<TableCell align="right" sx={{ padding: '6px 16px', paddingRight: 0 }}>
										<Input.InputField
											name={`averagePaymentTermSalePriceFormations[${index}].interestRate`}
											type="number"
											fullWidth
											margin="dense"
											sx={{
												height: '30px',
												margin: 0,
												'& .MuiInputBase-input': {
													height: '30px',
													padding: '0 5px',
													marginRight: 0,
												},
											}}
											textAlign="right"
											onBlur={(event) => handleBlur(event, averagePaymentTerm)}
											disabled={isReadOnly}
										/>
									</TableCell>
									<TableCell sx={{ padding: '6px 16px' }}>
										<SwitchField
											name={`averagePaymentTermSalePriceFormations[${index}].withDiscount`}
											label="Não/Sim"
											onChange={(event) => {
												const updatedPaymentTerms = values.averagePaymentTermSalePriceFormations
													?.map(
														(existingPaymentTerm) => (existingPaymentTerm.averagePaymentTermId
															=== averagePaymentTerm.averagePaymentTermId
															? { ...existingPaymentTerm, withDiscount: event.target.checked }
															: existingPaymentTerm),
													);

												if (updatedPaymentTerms) {
													const updatedPaymentTerm = updatedPaymentTerms
														.find((term) => term.averagePaymentTermId
															=== averagePaymentTerm.averagePaymentTermId);
													if (updatedPaymentTerm) {
														upsertAveragePaymentTermToFormation(updatedPaymentTerm);
													}
												}
											}}
											disabled={isReadOnly}
										/>
									</TableCell>
									<TableCell align="right" sx={{ padding: '6px 16px' }}>
										<IconButton
											onClick={() => {
												if (averagePaymentTerm.id && averagePaymentTerm.salePriceFormationId) {
													removeAveragePaymentTermFromFormation(
														averagePaymentTerm.id,
														averagePaymentTerm.salePriceFormationId,
													);
												}
											}}
											color="error"
											disabled={isReadOnly}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={12} md={6}>
				<FormControl fullWidth margin="dense">
					<Autocomplete
						name="currentAveragePaymentTermCode"
						label="Prazo Médio de Pagamento"
						options={availableAveragePaymentTerms || []}
						labelKey="description"
						valueKey="code"
						onInputChange={handleAverageTermInputChange}
						closeOnSelect
						disabled={isReadOnly}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={6}>
				<Input.InputField
					fullWidth
					label="Indice"
					name="currentAveragePaymentTermRate"
					variant="outlined"
					margin="dense"
					type="number"
					InputLabelProps={{ shrink: true }}
					disabled={isReadOnly}
				/>
			</Grid>
			<Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Button variant="contained" onClick={handleAddPaymentTerm} color="primary" disabled={isReadOnly}>
					Adicionar
				</Button>
				<Button variant="outlined" onClick={handleOpenModal} startIcon={<AddIcon />} disabled={isReadOnly}>
					Prazo Médio Padrão
				</Button>
			</Grid>
			{modalMemo}
		</Grid>
	);
};

AveragePaymentTermForm.defaultProps = {
	averagePaymentTerms: undefined,
};

export default AveragePaymentTermForm;
