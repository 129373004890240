import Decimal from 'decimal.js';
import { aliasesSalePriceFormationSimulation } from '../constants/aliases';
import { IAveragePaymentTermSalePriceFormationAnalysis, ISaleOrderSimulationItem, ISalePriceFormationAnalysis } from '../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';

export const calculateUnitValueIndirectCost = (
	totalDirectCost: number,
	indexValue: number,
	interestRate: number,
	totalInterestRate: number,
	commissionRate: number,
): number => {
	const totalDirectCostDec = new Decimal(totalDirectCost);
	const indexValueDec = new Decimal(indexValue);
	const interestRateDec = new Decimal(interestRate).div(100);
	const totalInterestRateDec = new Decimal(totalInterestRate).div(100);
	const commissionRateDec = new Decimal(commissionRate).div(100);

	return totalDirectCostDec
		.times(indexValueDec)
		.minus(totalDirectCostDec)
		.times(interestRateDec)
		.div(totalInterestRateDec.plus(commissionRateDec))
		.toNumber();
};

export const calculateUnitValueRangePriceCommission = (
	totalDirectCost: number,
	indexValue: number,
	commissionRate: number,
	totalIndirectCost: number,
): number => {
	const totalDirectCostDec = new Decimal(totalDirectCost);
	const indexValueDec = new Decimal(indexValue);
	const commissionRateDec = new Decimal(commissionRate);
	const totalIndirectCostDec = new Decimal(totalIndirectCost);

	return totalDirectCostDec
		.times(indexValueDec)
		.minus(totalDirectCostDec)
		.times(commissionRateDec)
		.div(totalIndirectCostDec.plus(commissionRateDec))
		.toNumber();
};

export const calculateSPPVIndexRangePriceCommission = (
	commissionRate: number,
	totalInterestRateSimulation: number,
): number => {
	const commissionRateDec = new Decimal(commissionRate).div(100);
	const totalInterestRateSimulationDec = new Decimal(totalInterestRateSimulation).div(100);

	return new Decimal(1)
		.div(new Decimal(1).minus(commissionRateDec.plus(totalInterestRateSimulationDec)))
		.toNumber();
};

export const calculateUnitValueAverageTerm = (
	selectedItem: ISaleOrderSimulationItem,
	term: IAveragePaymentTermSalePriceFormationAnalysis,
): number => {
	const totalDirectCost = new Decimal(selectedItem.salePriceFormation?.totalDirectCost || 0);
	const totalUnitValueIndirectCost = new Decimal(selectedItem.totalUnitValueIndirectCost || 0);
	const unitValueRangeCommission = new Decimal(selectedItem
		.salePriceFormation?.unitValueRangeCommission || 0);
	const interestRate = new Decimal(term.interestRate).div(100);

	return totalDirectCost
		.plus(totalUnitValueIndirectCost)
		.plus(unitValueRangeCommission)
		.times(interestRate)
		.toNumber();
};

export const calculateUnitValueAverageTermSimulation = (
	selectedItem: ISaleOrderSimulationItem,
	term: IAveragePaymentTermSalePriceFormationAnalysis,
): number => {
	// const isVista = ['à vista', 'a vista', 'avista', 'vista']
	// .some((expected) => (term.averagePaymentTerm?.description ?? '')
	// 	.toLowerCase()
	// 	.trim()
	// 	.replace(/[^a-z\s]/g, '') === expected);
	const totalDirectCost = new Decimal(selectedItem.salePriceFormation?.totalDirectCost || 0);
	const totalUnitValueIndirectCostSimulation = new Decimal(selectedItem
		.totalUnitValueIndirectCostSimulation || 0);
	const unitValueRangeCommissionSimulation = new Decimal(
		selectedItem.salePriceFormation?.unitValueRangeCommissionSimulation || 0,
	);
	const interestRate = new Decimal(term.interestRate).div(100);

	// if (isVista) {
	// 	return totalDirectCost
	// 		.plus(totalUnitValueIndirectCostSimulation)
	// 		.plus(unitValueRangeCommissionSimulation)
	// 		.times(interestRate)
	// 		.toNumber();
	// }

	return totalDirectCost
		.plus(totalUnitValueIndirectCostSimulation)
		.plus(unitValueRangeCommissionSimulation)
		.times(interestRate)
		.toNumber();
};

export const replaceAliasesInFormula = (formula: string, salePriceFormation: any): string => Object
	.keys(aliasesSalePriceFormationSimulation).reduce((acc, key) => {
		const value = salePriceFormation[aliasesSalePriceFormationSimulation[key]] || 0;
		return acc.replace(new RegExp(key, 'g'), value.toString());
	}, formula);

export const getSelectedItem = (
	items: ISaleOrderSimulationItem[],
	defaultValue: ISaleOrderSimulationItem,
): ISaleOrderSimulationItem => items.find((item) => item.selected) || defaultValue;

export const getSelectedItemIndex = (
	items: ISaleOrderSimulationItem[],
): number => items.findIndex((item) => item.selected);

export const getSalePriceFormation = (
	selectedItem: ISaleOrderSimulationItem,
	defaultValue: ISalePriceFormationAnalysis,
): ISalePriceFormationAnalysis => selectedItem?.salePriceFormation
|| defaultValue;

export const calculateGrossMargin = (salePriceFormation: ISalePriceFormationAnalysis): number => {
	const salePrice = new Decimal(salePriceFormation?.salePrice || 0);
	const totalDirectCost = new Decimal(salePriceFormation?.totalDirectCost || 0);

	if (salePrice.isZero()) return 0;

	return salePrice.minus(totalDirectCost).div(salePrice).times(100).toNumber();
};

export const calculateGrossProfit = (selectedItem: ISaleOrderSimulationItem): number => {
	const salePrice = new Decimal(selectedItem?.salePriceFormation?.salePrice || 0);
	const totalDirectCost = new Decimal(selectedItem?.salePriceFormation?.totalDirectCost || 0);

	return salePrice.minus(totalDirectCost).toNumber();
};

export const calculateGrossProfitSaleOrder = (selectedItem: ISaleOrderSimulationItem): number => {
	const price = new Decimal(selectedItem?.price || 0);
	const totalDirectCost = new Decimal(selectedItem?.salePriceFormation?.totalDirectCost || 0);

	return price.minus(totalDirectCost).toNumber();
};

export const calculateGrossProfitSimulation = (
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const simulationPrice = new Decimal(selectedItem?.simulationPrice || 0);
	const totalDirectCost = new Decimal(selectedItem?.salePriceFormation?.totalDirectCost || 0);

	return simulationPrice.minus(totalDirectCost).toNumber();
};

export const getTotalUnitValueIndirectCost = (
	selectedItem: ISaleOrderSimulationItem,
): number => selectedItem?.totalUnitValueIndirectCost || 0;

export const getTotalUnitValueIndirectCostSimulation = (
	selectedItem: ISaleOrderSimulationItem,
): number => selectedItem?.totalUnitValueIndirectCostSimulation || 0;

// export const getUnitValueProfit = (selectedItem: ISaleOrderSimulationItem): number => {
// 	const costs = selectedItem?.indirectCostSimulations || [];
// 	const lucroCost = costs.find((cost) => cost.indirectCost.description.toLowerCase() === 'lucro');
// 	return lucroCost?.unitValueI || 0;
// };

// export const getUnitValueProfitSimulation = (selectedItem: ISaleOrderSimulationItem): number => {
// 	const costs = selectedItem?.indirectCostSimulations || [];
// 	const lucroCost = costs.find((cost) => cost.indirectCost.description.toLowerCase() === 'lucro');
// 	return lucroCost?.sppvUnitValueI || 0;
// };

export const calculateNetProfit = (
	grossProfit: number,
	totalUnitValueIndirectCost: number,
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const unitValueRangeCommission = new Decimal(selectedItem
		?.salePriceFormation?.unitValueRangeCommission || 0);
	const unitValueAverageTerm = new Decimal(selectedItem
		?.salePriceFormation?.unitValueAverageTerm || 0);
	const grossProfitDecimal = new Decimal(grossProfit);
	const totalIndirectCost = new Decimal(totalUnitValueIndirectCost);

	return grossProfitDecimal
		.minus(totalIndirectCost.plus(unitValueRangeCommission).plus(unitValueAverageTerm))
		.toNumber();
};

export const calculateNetProfitSaleOrder = (
	grossProfitSaleOrder: number,
	totalUnitValueIndirectCost: number,
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const unitValueRangeCommission = new Decimal(selectedItem
		?.salePriceFormation?.unitValueRangeCommission || 0);
	const unitValueAverageTerm = new Decimal(selectedItem
		?.salePriceFormation?.unitValueAverageTerm || 0);
	const grossProfit = new Decimal(grossProfitSaleOrder);
	const indirectCost = new Decimal(totalUnitValueIndirectCost);

	return grossProfit.minus(indirectCost.plus(unitValueRangeCommission)
		.plus(unitValueAverageTerm)).toNumber();
};

export const calculateNetProfitSimulation = (
	grossProfitSimulation: number,
	totalUnitValueIndirectCostSimulation: number,
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const unitValueRangeCommissionSimulation = new Decimal(selectedItem
		?.salePriceFormation?.unitValueRangeCommissionSimulation || 0);
	const unitValueAverageTermSimulation = new Decimal(selectedItem
		?.salePriceFormation?.unitValueAverageTermSimulation || 0);
	const grossProfit = new Decimal(grossProfitSimulation);
	const indirectCostSimulation = new Decimal(totalUnitValueIndirectCostSimulation);

	return grossProfit.minus(indirectCostSimulation.plus(unitValueRangeCommissionSimulation)
		.plus(unitValueAverageTermSimulation)).toNumber();
};

export const calculateNetProfitMargin = (
	netProfit: number,
	salePriceFormation: ISalePriceFormationAnalysis,
): number => {
	const salePrice = new Decimal(salePriceFormation?.salePrice || 0);

	if (salePrice.isZero()) return 0;

	return new Decimal(netProfit).div(salePrice).times(100).toNumber();
};

export const calculateNetProfitMarginSaleOrder = (
	netProfitSaleOrder: number,
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const price = new Decimal(selectedItem?.price || 0);

	if (price.isZero()) return 0;

	return new Decimal(netProfitSaleOrder).div(price).times(100).toNumber();
};

export const calculateNetProfitMarginSimulation = (
	netProfitSimulation: number,
	selectedItem: ISaleOrderSimulationItem,
): number => {
	const simulationPrice = new Decimal(selectedItem?.simulationPrice || 0);

	if (simulationPrice.isZero()) return 0;

	return new Decimal(netProfitSimulation).div(simulationPrice).times(100).toNumber();
};

export const getFinancialCost = (
	selectedItem: ISaleOrderSimulationItem,
): number => (selectedItem?.salePriceFormation?.unitValueAverageTerm || 0);

export const getFinancialCostSimulation = (
	selectedItem: ISaleOrderSimulationItem,
): number => (selectedItem?.salePriceFormation?.unitValueAverageTermSimulation || 0);

export const calculateTotalIndirectFinancialCommissionCost = (
	financialCost: number,
	totalUnitValueIndirectCost: number,
	commissionCost: number,
	quantity: number,
): number => {
	const totalUnitValueIndirectCostDecimal = new Decimal(totalUnitValueIndirectCost || 0);
	const financialCostDecimal = new Decimal(financialCost || 0);
	const commissionCostDecimal = new Decimal(commissionCost || 0);
	const quantityDecimal = new Decimal(quantity || 1);

	const totalCost = totalUnitValueIndirectCostDecimal
		.plus(financialCostDecimal)
		.plus(commissionCostDecimal)
		.times(quantityDecimal);

	return totalCost.toNumber();
};
