import React, {
	useCallback, useMemo, useState,
} from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import SaleOrderTable from './SaleOrderTable';
import SaleOrderSummaryCards from './SaleOrderSummaryCards';
import DrawerFilter from '../Common/DrawerFilter';
import SaleOrderFilter from './SaleOrderFilter';
import { ISaleOrder, initialValuesFilter } from '../../containers/SaleOrder/SaleOrderAssets';
import { SaleOrderQueryParams } from '../../interfaces/SaleOrderQueryParams';
import DetailsDrawer from './Drawers/DetailsDrawer';
import { normalizeDataGridOrderBy } from '../../helpers/Utils';
import useDataGridManagement from '../../hooks/useDataGridManagement';

interface SaleOrderProps {
	loading: boolean;
	saleOrders: ISaleOrder[];
	saleOrdersPages: number,
	saleOrdersPage: number,
	saleOrdersTake: number,
	draftCount: number;
	budgetCount: number;
	saleOrderCount: number;
	getSaleOrders: (params: SaleOrderQueryParams) => void;
	handleCancelSaleOrder: (id: string, actionJustificative: string) => void;
	handleEdit: (id: string) => void;
}

const initialSortModel: GridSortModel = [
	{ field: 'code', sort: 'asc' },
];

const SaleOrder = ({
	loading,
	saleOrders,
	saleOrdersPages,
	saleOrdersPage,
	saleOrdersTake,
	draftCount,
	budgetCount,
	saleOrderCount,
	getSaleOrders,
	handleCancelSaleOrder,
	handleEdit,
}: SaleOrderProps): JSX.Element => {
	const [openDetails, setOpenDetails] = useState<undefined | ISaleOrder>(undefined);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [
		filterValues,
		setFilterValues,
	] = useState<Partial<SaleOrderQueryParams>>(initialValuesFilter);

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<SaleOrderQueryParams>({
		initialSortModel,
		initialPageSize: saleOrdersTake,
		initialPage: saleOrdersPage,
		fetchData: getSaleOrders,
	});

	const sendFilter = useCallback((values: Partial<SaleOrderQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
			orderBy: normalizeDataGridOrderBy(sortModel) as {
				marketName: GridSortDirection,
				BPLegalEntity: GridSortDirection,
			},
		};
		setFilter(pageFilter);
	}, [setFilter, sortModel]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const handleFilterSubmit = useCallback((newFilterValues: Partial<SaleOrderQueryParams>) => {
		setFilterDrawerOpen(false);
		setFilterValues(newFilterValues);
		sendFilter(newFilterValues);
	}, [sendFilter]);

	const onDetailsClick = useCallback((id: string) => {
		const selectedSaleOrder = saleOrders
			.find((saleOrder) => saleOrder.id === id);
		setOpenDetails(selectedSaleOrder);
	}, [saleOrders]);

	const onCloseDetails = useCallback(() => {
		setOpenDetails(undefined);
	}, []);

	const headerButtonsProps: PageHeaderButtonProps[] = [
		{
			variant: 'contained',
			color: 'primary',
			type: 'submit',
			fullWidth: true,
			component: Link,
			to: 'edit',
			text: 'Novo',
		},
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
	];

	const saleOrderFilterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<SaleOrderFilter
				sendFilter={handleFilterSubmit}
				initialValues={filterValues}
			/>
		</DrawerFilter>
	), [filterValues, handleFilterSubmit, isFilterDrawerOpen, toggleFilterDrawer]);

	return (
		<Box className="content">
			{saleOrderFilterMemo}
			<PageHeader title="Ordens de Venda" buttons={headerButtonsProps} />
			<SaleOrderSummaryCards
				summaryData={[
					{ title: 'Ordens de Venda', count: saleOrderCount },
					{ title: 'Orçamentos', count: budgetCount },
					{ title: 'Rascunhos', count: draftCount },
				]}
			/>
			<SaleOrderTable
				loading={loading}
				rows={saleOrders}
				saleOrdersPages={saleOrdersPages}
				saleOrdersPage={saleOrdersPage}
				saleOrdersTake={saleOrdersTake}
				sortModel={sortModel}
				onChangePageSize={onChangePageSize}
				onChangePage={onChangePage}
				onSortModelChange={onSortModelChange}
				onDetailsClick={onDetailsClick}
				handleEdit={handleEdit}
				handleCancelSaleOrder={handleCancelSaleOrder}
			/>
			<DetailsDrawer
				openDetails={openDetails}
				onClose={onCloseDetails}
			/>
		</Box>
	);
};

export default SaleOrder;
