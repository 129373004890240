import Yup from '../../helpers/yupMethods';
import { validationMessage } from '../../helpers/yupMessages';
import { BranchPreferenceTypeLabels } from '../../constants/branchPreferenceTypeLabels';

export interface Branches {
  branchId: string;
  branchCompanyId: string;
  isDefault?: boolean;
}

export interface CompanyBranches {
  branchIds: string[];
  companyId: string;
}

export interface User {
  id: string;
  login: string;
  email: string;
  password?: string;
  name: string;
  phone: string;
  createdAt: string;
  changePasswordLogin: boolean;
  active: boolean;
  photo?: string;
  updatedAt?: string;
  cpf?: string;
  registry?: string;
  registerHour?: boolean;
  canWorkHoliday?: boolean;
  daysBackAppointment?: string;
  companyIds?: string[];
  branches?: Branches[];
  companyBranches?: CompanyBranches[];
  preferredBranch?: Branches;
  preferredBranchId?: string;
  preferredCompanyId?: string;
  branchPreference?: BranchPreference;
}

export interface AddUser {
  name: string;
  email: string;
  phone: string;
  login: string;
  changePasswordLogin: boolean;
  cpf?: string;
  registry?: string;
  registerHour?: boolean;
  canWorkHoliday?: boolean;
  daysBackAppointment?: string;
  companyIds?: string[];
  branches?: Branches[];
  companyBranches?: CompanyBranches[];
  preferredBranchId?: string;
  preferredCompanyId?: string;
  branchPreference?: BranchPreference;
}

export interface EditUser {
  name: string;
  email: string;
  phone: string;
  cpf?: string;
  registry?: string;
  registerHour?: boolean;
  canWorkHoliday?: boolean;
  daysBackAppointment?: string;
  companyIds?: string[];
  branches?: Branches[];
  companyBranches?: CompanyBranches[];
  preferredBranchId?: string;
  preferredCompanyId?: string;
  branchPreference?: BranchPreference;
}

export interface ProjectTaskUserStatus {
  status: boolean;
  projectTaskId: string;
  hourProjectId: string;
}

export interface UserWithProjectTaskStatus {
  id: string;
  name: string;
  login: string;
  projectTaskUsers: ProjectTaskUserStatus[];
}

export interface GetProjectUsers {
  data: UserWithProjectTaskStatus[];
  count: number;
}

export interface CurrentUserProps {
  id: string;
  name: string;
}

export enum BranchPreference {
  PREFERRED,
  LAST_USED,
}

export const initialValuesAddUser = {
	name: '',
	email: '',
	phone: '',
	login: '',
	password: '',
	changePasswordLogin: false,
	cpf: '',
	registry: '',
	registerHour: false,
	canWorkHoliday: false,
	daysBackAppointment: '',
	companyIds: [],
	branches: [],
	companyBranches: [],
	preferredBranchId: '',
	preferredCompanyId: '',
};

export const branchPreferenceOptions = Object.entries(
	BranchPreferenceTypeLabels,
).map(([value, label]) => ({
	id: value,
	value,
	label,
}));

export const addUserValidationSchema = Yup.object().shape({
	name: Yup.string().required(validationMessage.required),
	email: Yup.string()
		.email(validationMessage.string.email)
		.required(validationMessage.required),
	phone: Yup.string()
		.min(10, validationMessage.string.min(15))
		.max(16, validationMessage.string.max(16))
		.required(validationMessage.required),
	login: Yup.string().required(validationMessage.required),
	password: Yup.string().required(validationMessage.required),
	cpf: Yup.string().cpf().nullable(true),
	daysBackAppointment: Yup.string()
		.max(3, validationMessage.string.max(3))
		.matches(/^[0-9]*$/, validationMessage.number.typeError)
		.nullable(true),
	branchPreference: Yup.number().required(validationMessage.required),
	preferredCompanyId: Yup.string().when('branchPreference', {
		is: BranchPreference.PREFERRED,
		then: (schema) => schema.required(validationMessage.required).nullable(),
		otherwise: (schema) => schema.nullable(),
	}),
	preferredBranchId: Yup.string().when('branchPreference', {
		is: BranchPreference.PREFERRED,
		then: (schema) => schema.required(validationMessage.required),
		otherwise: (schema) => schema.nullable(),
	}),
	companyBranches: Yup.array().of(
		Yup.object().shape({
			companyId: Yup.string().required(validationMessage.required),
			branchIds: Yup.array()
				.of(Yup.string())
				.min(1, validationMessage.required)
				.required(validationMessage.required),
		}),
	).required(validationMessage.required)
		.min(1, validationMessage.required),
});

export const editUserValidationSchema = Yup.object().shape({
	name: Yup.string().required(validationMessage.required),
	email: Yup.string()
		.email(validationMessage.string.email)
		.required(validationMessage.required),
	phone: Yup.string()
		.min(10, validationMessage.string.min(15))
		.max(16, validationMessage.string.max(16))
		.required(validationMessage.required),
	cpf: Yup.string().cpf().nullable(true),
	daysBackAppointment: Yup.string()
		.max(3, validationMessage.string.max(3))
		.matches(/^[0-9]*$/, validationMessage.number.typeError)
		.nullable(true),
	branchPreference: Yup.number().required(validationMessage.required),
	preferredCompanyId: Yup.string().when('branchPreference', {
		is: BranchPreference.PREFERRED,
		then: (schema) => schema.required(validationMessage.required).nullable(),
		otherwise: (schema) => schema.nullable(),
	}),
	preferredBranchId: Yup.string().when('branchPreference', {
		is: BranchPreference.PREFERRED,
		then: (schema) => schema.required(validationMessage.required),
		otherwise: (schema) => schema.nullable(),
	}),
	companyBranches: Yup.array().of(
		Yup.object().shape({
			companyId: Yup.string().required(validationMessage.required),
			branchIds: Yup.array()
				.of(Yup.string())
				.min(1, validationMessage.required)
				.required(validationMessage.required),
		}),
	).required(validationMessage.required)
		.min(1, validationMessage.required),
});
