import React, { useMemo } from 'react';
import Grid, { RegularBreakpoints } from '@mui/material/Grid';
import Autocomplete from '../Form/Autocomplete';
import { IFilterFieldsValues } from '../../../interfaces/BudgetOrderFourFields';

interface BudgetOrderFourFieldsFilterProps {
	breakpoints: RegularBreakpoints;
	limitTags?: number;
	multiple?: boolean;
}

const BudgetOrderFourFieldsFilter = ({
	accountingAccounts,
	accountingItems,
	classValues,
	costCenters,
	breakpoints,
	limitTags,
	multiple,
}: IFilterFieldsValues & BudgetOrderFourFieldsFilterProps): JSX.Element => {
	const filterFields = useMemo(() => ([
		{
			name: 'accountingItems',
			label: 'Filial',
			options: accountingItems || [],
		},
		{
			name: 'costCenters',
			label: 'Centro de Custo',
			options: costCenters || [],
		},
		{
			name: 'classValues',
			label: 'Cliente/Fornecedor',
			options: classValues || [],
		},
		{
			name: 'accountingAccounts',
			label: 'Conta Contábil',
			options: accountingAccounts || [],
		},
	]), [accountingAccounts, accountingItems, classValues, costCenters]);

	return (
		<>
			{filterFields.map((field) => (
				<Grid
					key={field.label}
					item
					xs={breakpoints.xs}
					sm={breakpoints.sm}
					md={breakpoints.md}
					lg={breakpoints.lg}
					xl={breakpoints.xl}
				>
					<Autocomplete<{name: string; code: string;}>
						multiple={multiple}
						limitTags={limitTags}
						label={field.label}
						name={field.name}
						options={field.options}
						labelKey="name"
						valueKey="code"
					/>
				</Grid>
			))}
		</>
	);
};

BudgetOrderFourFieldsFilter.defaultProps = {
	limitTags: 1,
	multiple: true,
};

export default BudgetOrderFourFieldsFilter;
