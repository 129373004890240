import React from 'react';
import { RouteProps } from 'react-router-dom';
import { ProviderClient } from '../Common/ProviderClient/ProviderClientAssets';
import ProviderClientForm from '../Common/ProviderClient/ProviderClientForm';

interface ClientEditProps {
	loading: boolean;
	// client?: any;
	saveClient: (values: { id?: string; data: ProviderClient }) => void;
}

const ClientEdit = ({ loading, saveClient }: ClientEditProps): JSX.Element => (
	<ProviderClientForm
		loading={loading}
		handleSubmit={saveClient}
	/>
);

export default ClientEdit;
