/* eslint-disable no-param-reassign */
import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import {
	Location, NavigateFunction, useLocation, useNavigate,
} from 'react-router-dom';
import { AxiosError } from 'axios';
import {
	getLocationTypeById,
	saveLocationType,
	updateLocationType,
} from '../../services/locationType';
import LocationTypePresentational from '../../components/LocationType/LocationTypeEdit';
import { ILocationType } from '../Location/LocationAssets';
import { LocationDataParams } from './LocationType';
import { getUserBranchesByCompany, getUserCompanies } from '../../services/user';
import { ICompanyWithoutDetails } from '../Company/CompanyAssets';
import { IBranch } from '../Branch/BranchAssets';

enum ActionType {
  LOADING,
  LOCATION_TYPE,
  LOCATIONS_TYPES,
  COMPANY,
  BRANCH
}

export interface IState {
  loading: boolean;
  locationType?: ILocationType;
  locations: ILocationType[];
  companies: ICompanyWithoutDetails[];
  branches: IBranch[];
}

type TAction =
  | { type: ActionType.LOADING; payload: { loading: boolean } }
  | { type: ActionType.COMPANY; payload: { companies: ICompanyWithoutDetails[] } }
  | { type: ActionType.BRANCH; payload: { branches: IBranch[] } }
  | { type: ActionType.LOCATION_TYPE; payload: { locationType: ILocationType } }
  | { type: ActionType.LOCATIONS_TYPES; payload: { locations: ILocationType[] } };

export interface ILocationTypeActions {
  setLoading(loading: boolean): void;
  handleEditLocationType(values: { id?: string; data: Omit<ILocationType, 'canEditPrefix' | 'id'> }): void;
  getLocationTypeById(id: string): void;
  setLocationType(locationType: ILocationType): void;
  getLocationsState(): void;
  getUserCompanies(): void;
  getUserBranchesByCompany(companyId: string): void;
}

const initialState: IState = {
	loading: false,
	locationType: undefined,
	locations: [],
	companies: [],
	branches: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.COMPANY:
			return { ...state, companies: action.payload.companies };
		case ActionType.BRANCH:
			return { ...state, branches: action.payload.branches };
		case ActionType.LOCATION_TYPE:
			return { ...state, locationType: action.payload.locationType };
		case ActionType.LOCATIONS_TYPES:
			return { ...state, locations: action.payload.locations };
		default:
			throw new Error();
	}
};

const LocationTypeActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey,
	navigate: NavigateFunction,
	locationRouting: Location,
): ILocationTypeActions => {
	const actions: ILocationTypeActions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setLocationType(locationType: ILocationType) {
			dispatch({ type: ActionType.LOCATION_TYPE, payload: { locationType } });
		},
		handleEditLocationType(locationType: {
      id?: string;
      data: ILocationType;
    }): void {
			actions.setLoading(true);

			if (locationType.id) {
				updateLocationType(locationType.id, locationType.data)
					.then((response) => {
						navigate('/location-type');
						enqueueSnackbar(response.data.message, { variant: 'success' });
					})
					.catch((error: AxiosError) => {
						enqueueSnackbar(
							error.response?.data.message
                || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
							{
								variant: 'error',
							},
						);
						actions.setLoading(false);
					});
			} else {
				saveLocationType(locationType.data)
					.then((response) => {
						navigate('/location-type');
						enqueueSnackbar(response.data.message, { variant: 'success' });
					})
					.catch((error: AxiosError) => {
						enqueueSnackbar(
							error.response?.data.message
                || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
							{
								variant: 'error',
							},
						);
						actions.setLoading(false);
					});
			}
		},
		getLocationTypeById(id: string) {
			actions.setLoading(true);
			getLocationTypeById(id)
				.then((response) => {
					if (!response.data) {
						enqueueSnackbar('Tipo de localização não encontrada.', {
							variant: 'error',
						});
						navigate('/location-type');
					}
					actions.setLocationType(response.data);
					actions.setLoading(false);
				})
				.catch(() => {
					actions.setLoading(false);
				});
		},
		getLocationsState() {
			const { locations } = locationRouting.state as LocationDataParams;
			dispatch({ type: ActionType.LOCATIONS_TYPES, payload: { locations } });
		},
		getUserCompanies() {
			actions.setLoading(true);
			getUserCompanies().then((response) => {
				dispatch({
					type: ActionType.COMPANY,
					payload: {
						companies: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar empresas.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		getUserBranchesByCompany(companyId: string) {
			actions.setLoading(true);
			getUserBranchesByCompany(companyId).then((response) => {
				dispatch({
					type: ActionType.BRANCH,
					payload: {
						branches: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar filiais.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
	};
	return actions;
};

const LocationTypeEdit = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const locationRouting = useLocation();
	const actions = useMemo(
		() => LocationTypeActions(dispatch, enqueueSnackbar, navigate, locationRouting),
		[enqueueSnackbar, navigate, locationRouting],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <LocationTypePresentational {...state} {...actions} />;
};

export default LocationTypeEdit;
