/* eslint-disable react/jsx-props-no-spreading */
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import InventorySummaryByOperatorReportPresentational from '../../components/Inventory/Reports/InventorySummaryByOperatorReport';
import { getInventoryItemsById, reportByOperatorPDF } from '../../services/inventory';
import { InventoryItemsByIdParams } from '../../interfaces/InventoryParams';
import { IInventoryInfo, IInventoryItems } from './InventoryAssets';
import { InventoryStatus } from '../../enums/inventory';

enum ActionType {
	LOADING,
	INVENTORY_ITEM,
}

interface IState {
	loading: boolean;
	inventoryItems: IInventoryItems[];
	inventoryItemsCount: number;
	inventoryItemsPage: number;
	inventoryItemsTake: number;
	inventoryInfo: IInventoryInfo;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.INVENTORY_ITEM;
		payload: {
			inventoryItems: IInventoryItems[];
			inventoryItemsCount: number;
			inventoryItemsPage: number;
			inventoryItemsTake: number;
			inventoryInfo: IInventoryInfo;
		} }

interface IInventoryTaskActions {
	setLoading(loading: boolean): void;
	getInventoryItemsById(id: string, queryParams?: InventoryItemsByIdParams): void;
	reportByOperatorPDF(id: string): void;
}

const initialState: IState = {
	loading: false,
	inventoryItems: [],
	inventoryItemsCount: 0,
	inventoryItemsPage: 0,
	inventoryItemsTake: 10,
	inventoryInfo: {
		countNumber: 0,
		date: '',
		document: '',
		haveItemsForNextCount: false,
		responsibleName: '',
		status: InventoryStatus.PENDING,
		startCount: false,
	},
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.INVENTORY_ITEM:
			return {
				...state,
				inventoryItems: action.payload.inventoryItems,
				inventoryItemsTake: action.payload.inventoryItemsTake,
				inventoryItemsPage: action.payload.inventoryItemsPage,
				inventoryItemsCount: action.payload.inventoryItemsCount,
				inventoryInfo: action.payload.inventoryInfo,
			};
		default:
			throw new Error();
	}
};

const InventoryTaskActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IInventoryTaskActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getInventoryItemsById(inventoryId: string, queryParams: InventoryItemsByIdParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 10;
			const skip = queryParams.skip ?? 0;
			const params = {
				...queryParams,
				skip: skip * take,
				take: queryParams.take ?? 10,
			};
			getInventoryItemsById(inventoryId, params).then((response) => {
				dispatch({
					type: ActionType.INVENTORY_ITEM,
					payload: {
						inventoryItems: response.data.inventoryItems,
						inventoryItemsCount: response.data.count,
						inventoryItemsPage: queryParams.skip ?? 0,
						inventoryItemsTake: take,
						inventoryInfo: response.data.inventory,
					},
				});
				actions.setLoading(false);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			});
		},
		reportByOperatorPDF(id: string) {
			actions.setLoading(true);

			reportByOperatorPDF(id).then((response) => {
				const blob = new Blob([response.data], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				window.open(url);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
	};

	return actions;
};

const InventorySummaryByOperatorReport = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();

	const actions = useMemo(
		() => InventoryTaskActions(dispatch, enqueueSnackbar),
		[enqueueSnackbar],
	);

	return (
		<InventorySummaryByOperatorReportPresentational
			{...state}
			{...actions}
		/>
	);
};

export default InventorySummaryByOperatorReport;
