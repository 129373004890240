import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
	EditCompany,
	ICompany,
} from '../../containers/Company/CompanyAssets';
import CompanyForm from './CompanyForm';
import { Loading } from '../Common/Loading';

interface CompanyEditProps {
	loading: boolean;
	company?: ICompany;
	getCompanyById: (id: string) => void;
	handleEditCompany: (values: { id?: string; data: EditCompany }) => void;
}

const CompanyEdit = ({
	loading, handleEditCompany, getCompanyById, company,
}: CompanyEditProps): JSX.Element => {
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getCompanyById(id);
		}
	}, [id, getCompanyById]);

	const handleSubmit = useCallback((values: EditCompany) => {
		handleEditCompany({ id: company?.id, data: values });
	}, [handleEditCompany, company]);

	if (id && !company) {
		return <Loading />;
	}

	return (
		<div>
			<Typography variant="h4" gutterBottom>
				{`${company?.id ? 'Editar' : 'Cadastrar'} Empresa`}
			</Typography>
			<CompanyForm
				company={company}
				onSubmit={handleSubmit}
				loading={loading}
			/>
		</div>
	);
};

CompanyEdit.defaultProps = {
	company: undefined,
};

export default CompanyEdit;
