import {
	useState,
	useEffect,
	useCallback,
	useMemo,
} from 'react';

interface UseExpandAccordionsReturn {
    expandPanels: string[];
    togglePanel: (panelId: string) => void;
}

const useExpandAccordions = (rows: any[], expandAll: boolean): UseExpandAccordionsReturn => {
	const initialExpandedPanels = useMemo(
		() => (expandAll ? rows.map((row) => row.id) : []),
		[expandAll, rows],
	);

	const [expandPanels, setExpandPanels] = useState<string[]>(initialExpandedPanels);

	const togglePanel = useCallback(
		(panelId: string) => {
			setExpandPanels((current) => {
				if (current.includes(panelId)) {
					return current.filter((id) => id !== panelId);
				}
				return [...current, panelId];
			});
		},
		[],
	);

	useEffect(() => {
		setExpandPanels(initialExpandedPanels);
	}, [initialExpandedPanels]);

	return {
		expandPanels,
		togglePanel,
	};
};

export default useExpandAccordions;
