import React from 'react';
import { Stack } from '@mui/material';
import { CompanyBranchAccess, FormTitle, PersonalData } from './UserFormSections';
import { ICompanyWithoutDetails } from '../../../containers/Company/CompanyAssets';
import { IBranch } from '../../../containers/Branch/BranchAssets';
import { BranchParams } from '../../../containers/Inventory/InventoryAssets';

interface EditUserFormProps {
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	getBranches: (params: BranchParams) => void;
}

export const EditUserForm = ({
	companies,
	branches,
	getBranches,
}: EditUserFormProps): JSX.Element => (
	<Stack spacing={5}>
		<FormTitle>Editar usuário</FormTitle>
		<PersonalData />
		<CompanyBranchAccess
			companies={companies}
			branches={branches}
			getBranches={getBranches}
		/>
	</Stack>
);
