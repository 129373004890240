import React, { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { Link, Theme, useTheme } from '@mui/material';
import LoginForm from './LoginForm';
import { LoginData } from '../../services/auth';
import ChangePasswordLogin from '../ChangePassword/ChangePasswordLogin';
import { DEFAULT_NAME, VERSION } from '../../constants/general';

interface LoginProps {
  loading: boolean;
  isChangingPassword: boolean;
  errorMessage: string;
  dropSession: boolean;
  handleChangePassword: (password: string) => void;
  login: (loginData: LoginData, dropSession: boolean) => void;
}

const container: SxProps<Theme> = (theme) => ({
	width: '100%',
	height: '100vh', // Ajuste para altura total da tela
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	background: theme.palette.background.default,
});

const card: SxProps<Theme> = (theme) => ({
	width: '100%', // Ajuste para 100% da largura disponível
	maxWidth: 500,
	margin: theme.spacing(2), // Adiciona margem para dispositivos menores
	[theme.breakpoints.down('sm')]: {
		width: '90%', // Ajuste para 90% da largura em dispositivos pequenos
	},
});

const content: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	rowGap: '2rem',
};

const Login = ({
	isChangingPassword, loading, errorMessage, dropSession, handleChangePassword, login,
}: LoginProps): JSX.Element => {
	const theme = useTheme();

	const loginProxy = useCallback((loginData: LoginData) => {
		login(loginData, dropSession);
	}, [dropSession, login]);

	const cardContentComponent = useMemo(() => {
		if (isChangingPassword) {
			return (
				<ChangePasswordLogin
					loading={loading}
					handleChangePassword={handleChangePassword}
				/>
			);
		}

		return (
			<LoginForm
				loading={loading}
				errorMessage={errorMessage}
				alreadyLogged={dropSession}
				handleSubmit={loginProxy}
			/>
		);
	}, [isChangingPassword, loading, errorMessage, dropSession, loginProxy, handleChangePassword]);

	return (
		<Box sx={container(theme)}>
			<Card sx={card(theme)}>
				<CardContent sx={content}>
					<Box component="img" src="/api/configuration/loginLogo" alt="Logo Login" sx={{ maxWidth: '15rem', width: '100%', height: 'auto' }} />
					{cardContentComponent}
				</CardContent>
			</Card>
			<Typography
				variant="body2"
				color="text.secondary"
				align="center"
				sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}
			>
				<span>
					{'Copyright © '}
					<Link
						color="inherit"
						href="https://datasensetecnologia.com.br/"
					>
						{DEFAULT_NAME}
					</Link>
					{' '}
					{new Date().getFullYear()}
				</span>
				<span>{`Versão ${VERSION}`}</span>
			</Typography>
		</Box>
	);
};

export default Login;
