import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Box,
	Paper,
	Stack,
	Table,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../../interfaces/PageHeaderInterface';
import { IInventoryDifferenceItems, IInventoryInfoReport } from '../../../containers/Inventory/InventoryAssets';
import { InventoryDifferenceParams } from '../../../interfaces/InventoryParams';
import { Loading } from '../../Common/Loading';
import Pagination from '../../Common/Pagination';
import { currencyBRLMask } from '../../../helpers/intl';
import { formatNumberForLocale } from '../../../helpers/NumberUtils';
import useDataGridManagement from '../../../hooks/useDataGridManagement';
import { formatUTCDateToLocal } from '../../../helpers/DateUtils';

interface InventoryDifferenceReportProps {
	loading: boolean;
    inventoryDifferenceItems: IInventoryDifferenceItems[];
	getInventoryDifferenceById(id: string, queryParams: InventoryDifferenceParams): void
	reportDifferencePDF(id: string): void
	inventoryDifferenceCount: number
	inventoryDifferenceTake: number
	inventoryDifferencePage: number
	inventoryInfo: IInventoryInfoReport
}

const InventoryDifferencesReport = ({
	loading,
	inventoryDifferenceItems,
	inventoryDifferenceCount,
	getInventoryDifferenceById,
	reportDifferencePDF,
	inventoryDifferenceTake,
	inventoryDifferencePage,
	inventoryInfo,
}: InventoryDifferenceReportProps): JSX.Element => {
	const { id } = useParams();

	const headerButtonsProps = useMemo(
		(): PageHeaderButtonProps[] => [
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				onClick: () => {
					if (id) {
						reportDifferencePDF(id);
					}
				},
				text: 'Imprimir',
			},
		],
		[id, reportDifferencePDF],
	);

	const {
		filter, setFilter,
	} = useDataGridManagement<InventoryDifferenceParams>({
		fetchData: (params) => {
			if (id) {
				getInventoryDifferenceById(id, params);
			}
		},
	});

	const handlePaginationChange = useCallback(
		(page2: number, take2: number) => {
			setFilter({ ...filter, take: take2, skip: (page2 - 1) * take2 });
		},
		[filter, setFilter],
	);

	const onChangeItems = useCallback(
		(pageNumber: number, rowsPerPage: number) => {
			setFilter({ ...filter, take: rowsPerPage, skip: (pageNumber - 1) * rowsPerPage });
		},
		[setFilter, filter],
	);

	const paginationMemo = useMemo(() => (
		<Pagination
			loading={loading}
			count={Math.ceil(inventoryDifferenceCount / inventoryDifferenceTake)}
			page={inventoryDifferencePage}
			take={inventoryDifferenceTake}
			onChange={handlePaginationChange}
			onChangeItems={onChangeItems}
		/>
	), [
		loading,
		handlePaginationChange,
		inventoryDifferenceCount,
		inventoryDifferencePage,
		inventoryDifferenceTake,
		onChangeItems,
	]);

	if (loading) {
		return (
			<Box padding={1}>
				<Loading />
			</Box>
		);
	}

	return (
		<Box>
			<PageHeader
				title="Relatório de Diferenças do Inventário"
				buttons={headerButtonsProps}
			/>
			<Box component={Paper} p={2}>
				<Box mb={2}>
					<Stack direction="row" spacing={1}>
						<Typography fontWeight="bold">Número do Inventário:</Typography>
						<Typography>{inventoryInfo.document}</Typography>
					</Stack>
					<Stack direction="row" spacing={1}>
						<Typography fontWeight="bold">Data:</Typography>
						<Typography>{inventoryInfo.date ? formatUTCDateToLocal(inventoryInfo.date, 'dd/MM/yyyy') : ''}</Typography>
					</Stack>
					<Stack direction="row" spacing={1}>
						<Typography fontWeight="bold">Responsável:</Typography>
						<Typography>{inventoryInfo.responsibleName}</Typography>
					</Stack>
				</Box>
				<TableContainer component={Paper}>
					<Table
						sx={{
							minWidth: 650,
							borderCollapse: 'collapse',
							'& .MuiTableCell-root': {
								border: '1px solid rgba(224, 224, 224, 1)',
							},
						}}
						aria-label="table with visible borders"
					>
						<TableHead>
							<TableRow>
								<TableCell rowSpan={2}>
									<Typography fontWeight="bold">Produto</Typography>
								</TableCell>
								<TableCell colSpan={2}>
									<Typography fontWeight="bold" textAlign="center">Utilizado</Typography>
									<Typography fontWeight="bold" textAlign="center">(Antes do Inventário)</Typography>
								</TableCell>
								<TableCell colSpan={2}>
									<Typography fontWeight="bold" textAlign="center">Utilizado</Typography>
									<Typography fontWeight="bold" textAlign="center">(Depois do Inventário)</Typography>
								</TableCell>
								<TableCell colSpan={2}>
									<Typography fontWeight="bold" textAlign="center">Utilizado</Typography>
									<Typography fontWeight="bold" textAlign="center">(Diferença)</Typography>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Typography fontWeight="bold" width={80}>Quantidade</Typography>
								</TableCell>
								<TableCell>
									<Typography fontWeight="bold" width={80}>Valor</Typography>
								</TableCell>
								<TableCell>
									<Typography fontWeight="bold" width={80}>Quantidade</Typography>
								</TableCell>
								<TableCell>
									<Typography fontWeight="bold" width={80}>Valor</Typography>
								</TableCell>
								<TableCell>
									<Typography fontWeight="bold" width={80}>Quantidade</Typography>
								</TableCell>
								<TableCell>
									<Typography fontWeight="bold" width={80}>Valor</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{inventoryDifferenceItems.map((row) => (
								<TableRow key={row.productId}>
									<TableCell>
										<Typography>
											{row.product}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography>
											{formatNumberForLocale(
												Number(row.quantityBefore),
												2,
											)}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography>{currencyBRLMask(row.valueBefore)}</Typography>
									</TableCell>
									<TableCell>
										<Typography>
											{formatNumberForLocale(
												Number(row.quantityAfter),
												2,
											)}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography>{currencyBRLMask(row.valueAfter)}</Typography>
									</TableCell>
									<TableCell>
										<Typography>
											{formatNumberForLocale(
												Number(row.quantityDifference),
												2,
											)}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography>{currencyBRLMask(row.valueDifference)}</Typography>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						mt: 4,
					}}
				>

					{paginationMemo}
				</Box>
			</Box>
		</Box>
	);
};

export default InventoryDifferencesReport;
