/* eslint-disable import/no-duplicates */
import React, {
	Dispatch, memo, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PurchaseOrderPresentational from '../../components/Order/PurchaseOrderApportionment/PurchaseOrder';
import PurchaseOrderApportionment from '../../components/Order/PurchaseOrderApportionment/PurchaseOrderApportionment';
import {
	getPurchases,
	getPurchase,
	saveApportionments,
	savePurchaseOrderApproval,
	getBranches,
	onNFDownloadClick,
	exportPurchases,
	exportPurchasesApportionments,
} from '../../services/purchaseOrder';
import { getFilterFieldsValues, getBudgets, duplicateBudget } from '../../services/budget';
import { PurchaseOrderQueryParams } from '../../interfaces/PurchaseOrderQueryParams';
import { IFilterFieldsValues } from '../../interfaces/BudgetOrderFourFields';
import { BudgetFilter } from '../../interfaces/Budget';
import { convertXMLToHTML } from '../../helpers/ConvertXMLToHTML/convertXMLToHTML';
import { downloadFile } from '../../helpers/Utils';

enum ActionType {
	LOADING,
	PURCHASES,
	PURCHASE,
	FILTER_FIELDS_VALUES,
	BUDGETS,
	BRANCHES,
	NEW_BUDGET,
}

interface IState {
	loading: boolean;
	purchases: any[];
	purchase: any;
	purchasesPages: number;
	purchasesPage: number;
	purchasesTake: number;
	filterFieldsValues: IFilterFieldsValues | null;
	budgets: any[];
	branches: any[];
	newBudget: any;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| {
		type: ActionType.PURCHASES; payload: {
			purchases: any[],
			purchasesPages: number,
			purchasesPage: number,
			purchasesTake: number,
		}
	}
	| {
		type: ActionType.PURCHASE, payload: {
			purchase: any,
		}
	}
	| { type: ActionType.FILTER_FIELDS_VALUES; payload: { filterFieldsValues: IFilterFieldsValues } }
	| { type: ActionType.BUDGETS; payload: { budgets: any[] } }
	| { type: ActionType.BRANCHES; payload: { branches: any[] } }
	| { type: ActionType.NEW_BUDGET; payload: { newBudget: any[] } };

interface IPurchaseOrderActions {
	setLoading(loading: boolean): void;
	exportPurchases(queryParams: PurchaseOrderQueryParams): void;
	exportPurchasesApportionments(queryParams: PurchaseOrderQueryParams): void;
	getPurchases(queryParams: PurchaseOrderQueryParams): void;
	getPurchase(purchases: any[], purchase: any): void;
	getFilterFieldsValues: () => void;
	sendFilter(filter: BudgetFilter): void;
	clearBudgets(): void;
	saveApportionments: (apportionments: any[], purchaseOrderItemId: string) => void;
	savePurchaseOrderApproval: (id: string) => void;
	duplicateBudget: (budgetId: string) => void;
	getBranches: () => void;
	onNFDownloadClick(id: string, invoiceNumber?: string): void;
	onNFViewAsHTMLClick(id: string, invoiceNumber?: string): void;
}

const initialState: IState = {
	loading: false,
	purchases: [],
	purchase: null,
	purchasesPages: 0,
	purchasesPage: 0,
	purchasesTake: 10,
	filterFieldsValues: null,
	budgets: [],
	branches: [],
	newBudget: null,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.PURCHASES:
			return {
				...state,
				purchases: action.payload.purchases,
				purchasesPages: action.payload.purchasesPages,
				purchasesPage: action.payload.purchasesPage,
				purchasesTake: action.payload.purchasesTake,
			};
		case ActionType.PURCHASE:
			return { ...state, purchase: action.payload.purchase };
		case ActionType.FILTER_FIELDS_VALUES:
			return { ...state, filterFieldsValues: action.payload.filterFieldsValues };
		case ActionType.BUDGETS:
			return { ...state, budgets: action.payload.budgets, newBudget: null };
		case ActionType.BRANCHES:
			return { ...state, branches: action.payload.branches };
		case ActionType.NEW_BUDGET:
			return { ...state, newBudget: action.payload.newBudget };
		default:
			throw new Error();
	}
};

const PurchaseOrderActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IPurchaseOrderActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		exportPurchases(queryParams: PurchaseOrderQueryParams) {
			actions.setLoading(true);
			const params: any = { ...queryParams };

			if (queryParams.invoiceIssuanceFrom && queryParams.invoiceIssuanceTo) {
				params.invoiceIssuanceFrom = format(new Date(params.invoiceIssuanceFrom), 'yyyy-MM-dd', { locale: ptBR });
				params.invoiceIssuanceTo = format(new Date(params.invoiceIssuanceTo), 'yyyy-MM-dd', { locale: ptBR });
			}

			if (queryParams.orderIssuanceFrom && queryParams.orderIssuanceTo) {
				params.orderIssuanceFrom = format(new Date(params.orderIssuanceFrom), 'yyyy-MM-dd', { locale: ptBR });
				params.orderIssuanceTo = format(new Date(params.orderIssuanceTo), 'yyyy-MM-dd', { locale: ptBR });
			}

			exportPurchases(params).then((response) => {
				downloadFile(response);
				actions.setLoading(false);
			});
		},
		exportPurchasesApportionments(queryParams: PurchaseOrderQueryParams) {
			actions.setLoading(true);
			const params: any = { ...queryParams };

			if (queryParams.invoiceIssuanceFrom && queryParams.invoiceIssuanceTo) {
				params.invoiceIssuanceFrom = format(new Date(params.invoiceIssuanceFrom), 'yyyy-MM-dd', { locale: ptBR });
				params.invoiceIssuanceTo = format(new Date(params.invoiceIssuanceTo), 'yyyy-MM-dd', { locale: ptBR });
			}

			if (queryParams.orderIssuanceFrom && queryParams.orderIssuanceTo) {
				params.orderIssuanceFrom = format(new Date(params.orderIssuanceFrom), 'yyyy-MM-dd', { locale: ptBR });
				params.orderIssuanceTo = format(new Date(params.orderIssuanceTo), 'yyyy-MM-dd', { locale: ptBR });
			}

			exportPurchasesApportionments(params).then((response) => {
				downloadFile(response);
				actions.setLoading(false);
			});
		},
		getPurchases(queryParams: PurchaseOrderQueryParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 10;
			const params: any = { ...queryParams, skip: queryParams.skip * take };

			if (queryParams.invoiceIssuanceFrom && queryParams.invoiceIssuanceTo) {
				params.invoiceIssuanceFrom = format(new Date(params.invoiceIssuanceFrom), 'yyyy-MM-dd', { locale: ptBR });
				params.invoiceIssuanceTo = format(new Date(params.invoiceIssuanceTo), 'yyyy-MM-dd', { locale: ptBR });
			}

			if (queryParams.orderIssuanceFrom && queryParams.orderIssuanceTo) {
				params.orderIssuanceFrom = format(new Date(params.orderIssuanceFrom), 'yyyy-MM-dd', { locale: ptBR });
				params.orderIssuanceTo = format(new Date(params.orderIssuanceTo), 'yyyy-MM-dd', { locale: ptBR });
			}

			getPurchases(params).then((purchases) => {
				dispatch({
					type: ActionType.PURCHASES,
					payload: {
						purchases: purchases.data.data,
						purchasesPages: purchases.data.count,
						purchasesPage: queryParams.skip,
						purchasesTake: take,
					},
				});
				actions.setLoading(false);
			});
		},
		getPurchase(purchases: any[], purchaseId: string) {
			actions.setLoading(true);
			if (purchases.length > 0) {
				const purchase = purchases.find((purchaseF) => purchaseF.id === purchaseId);

				if (!purchase) {
					enqueueSnackbar('Ordem de compra não encontrada.', {
						variant: 'error',
					});
					navigate('/order/apportionment');
				}

				dispatch({
					type: ActionType.PURCHASE,
					payload: { purchase },
				});
				actions.setLoading(false);
			} else {
				actions.setLoading(true);
				getPurchase(purchaseId).then((purchase) => {
					if (!purchase.data) {
						enqueueSnackbar('Ordem de compra não encontrada.', {
							variant: 'error',
						});
						navigate('/user');
					}
					dispatch({
						type: ActionType.PURCHASE,
						payload: { purchase: purchase.data.data },
					});
					actions.setLoading(false);
				});
			}
		},
		getFilterFieldsValues() {
			getFilterFieldsValues(undefined, true).then((response: AxiosResponse) => {
				dispatch({
					type: ActionType.FILTER_FIELDS_VALUES,
					payload: { filterFieldsValues: response.data },
				});
			});
		},
		getBranches() {
			getBranches().then((response: AxiosResponse) => {
				dispatch({
					type: ActionType.BRANCHES,
					payload: { branches: response.data.data },
				});
			});
		},
		sendFilter(filter: BudgetFilter) {
			actions.setLoading(true);
			getBudgets(filter).then((response: AxiosResponse) => {
				dispatch({
					type: ActionType.BUDGETS,
					payload: { budgets: response.data },
				});
				actions.setLoading(false);
			});
		},
		clearBudgets() {
			dispatch({
				type: ActionType.BUDGETS,
				payload: { budgets: [] },
			});
		},
		saveApportionments(apportionments: any[], purchaseOrderItemId: string) {
			const data = {
				purchaseOrderItemId,
				apportionments: apportionments.map((apportionment) => ({
					budgetId: apportionment.budgetId,
					percentage: Number.parseFloat(apportionment.percentage),
				})),
			};

			saveApportionments(data)
				.then((response: AxiosResponse) => {
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
				})
				.catch((error: AxiosError<{ message: string }>) => {
					enqueueSnackbar(error.response?.data.message, {
						variant: 'error',
					});
				});
		},
		savePurchaseOrderApproval(id: string) {
			savePurchaseOrderApproval({ purchaseOrderId: id })
				.then((response: AxiosResponse) => {
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
					navigate('/order/apportionment/');
				})
				.catch((error: AxiosError<{ message: string }>) => {
					enqueueSnackbar(error.response?.data.message, {
						variant: 'error',
					});
				});
		},
		duplicateBudget(budgetId: string) {
			duplicateBudget(budgetId)
				.then((response: AxiosResponse) => {
					dispatch({
						type: ActionType.NEW_BUDGET,
						payload: { newBudget: response.data.data },
					});
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
				})
				.catch((error: AxiosError<{ message: string }>) => {
					enqueueSnackbar(error.response?.data.message, {
						variant: 'error',
					});
				});
		},
		onNFDownloadClick(id: string, invoiceNumber?: string) {
			onNFDownloadClick(id, { responseType: 'blob' })
				.then((response) => {
					if (response.data.size === 0) {
						enqueueSnackbar('Não há NF-e integrada para este pedido', {
							variant: 'info',
						});
						return;
					}

					downloadFile(response);
				})
				.catch((error) => {
					enqueueSnackbar(error.response?.data.message, {
						variant: 'error',
					});
				});
		},
		onNFViewAsHTMLClick(id: string, invoiceNumber?: string) {
			onNFDownloadClick(id)
				.then((response) => {
					if (response.data.size === 0) {
						enqueueSnackbar('Não há NF-e integrada para este pedido', {
							variant: 'info',
						});
						return;
					}

					const htmlString = convertXMLToHTML(response.data);
					const newWindow = window.open('', '_blank');

					if (newWindow) {
						newWindow.document.write(htmlString);
						newWindow.document.title = `NFe_${invoiceNumber}`;
					}
				})
				.catch(() => {
					enqueueSnackbar('Erro ao baixar XML', {
						variant: 'error',
					});
				});
		},
	};

	return actions;
};

const PurchaseOrder = ({ apportionment }: { apportionment?: boolean }): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => PurchaseOrderActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	if (apportionment) {
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <PurchaseOrderApportionment {...state} {...actions} />;
	}

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <PurchaseOrderPresentational {...state} {...actions} />;
};

PurchaseOrder.defaultProps = {
	apportionment: false,
};

export default memo(PurchaseOrder);
