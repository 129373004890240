import { AxiosResponse } from 'axios';
import { IModule } from '../interfaces/Module';
import api from './api';

export const TOKEN_KEY = 'token';
export const ID_KEY = 'id';
export const PERMISSIONS_KEY = 'permissions';
export const BRANCH_NAME_KEY = 'branchName';
export const COMPANY_ID_KEY = 'companyId';
export const BRANCH_ID_KEY = 'branchId';

interface Login {
	token: string;
	changePasswordLogin: boolean;
	name: string;
	email: string;
	id: string;
	branchId: string;
	branchName: string;
	companyId: string;
}

export interface LoginData {
	username: string;
	password: string;
}

interface ChangePasswordData {
	password: string;
}

export type LoginResponse = AxiosResponse<Login>;
export const isAuthenticated = (): boolean => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY);

export const login = (
	data: LoginData,
	dropSession?: boolean,
): Promise<LoginResponse> => api.post<LoginData, LoginResponse>('auth/login', data, { headers: { dropSession: dropSession ? 'true' : 'false' } });

export const changePassword = (data: ChangePasswordData): Promise<AxiosResponse<any>> => api.post<ChangePasswordData, any>('auth/changePassword', data);

export const changePasswordLogin = (data: ChangePasswordData): Promise<AxiosResponse<any>> => api.post<ChangePasswordData, any>('auth/changePassowordFirstLogin', data);

export type ChangeBranchPreference = {
	branchId: string,
}

export const setToken = (token: string): void => {
	localStorage.setItem(TOKEN_KEY, token);
};

export const setId = (id: string): void => {
	localStorage.setItem(ID_KEY, id);
};

export const setBranchName = (branchName: string): void => {
	localStorage.setItem(BRANCH_NAME_KEY, branchName || 'Usuário sem filial vinculada');
};

export const setCompanyId = (companyId: string): void => {
	localStorage.setItem(COMPANY_ID_KEY, companyId);
};

export const setBranchId = (branchId: string): void => {
	localStorage.setItem(BRANCH_ID_KEY, branchId || '');
};

export const setBranch = (data: ChangeBranchPreference): Promise<LoginResponse> => api.post('auth/setBranch', data);

export const setPermissions = (permissions: IModule): void => {
	localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
};

export const hasPermissions = (): boolean => {
	const permissions = localStorage.getItem(PERMISSIONS_KEY);

	return Boolean(permissions);
};

const doLogout = (): void => {
	localStorage.clear();
	const logoutEvent = new Event('logout');

	window.dispatchEvent(logoutEvent);
};

export const logout = (anotherSession?: boolean): void => {
	if (!anotherSession) {
		api.post('auth/logout').then(() => {
			doLogout();
		});
	} else {
		doLogout();
	}
};
