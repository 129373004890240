import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import CompanyEdit from '../../components/Company/CompanyEdit';
import { EditCompany, ICompany } from './CompanyAssets';
import {
	getCompanyById, updateCompany, saveCompany,
} from '../../services/company';

enum ActionType {
	LOADING,
	COMPANY,
}

interface IState {
	loading: boolean;
	company?: ICompany;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.COMPANY; payload: { company: ICompany } };

interface ICompanyActions {
	setLoading(loading: boolean): void;
	setCompany(company: ICompany): void;
	handleEditCompany(values: {
		id?: string;
		data: EditCompany;
	}): void;
	getCompanyById(id: string): void;
}

const initialState: IState = {
	loading: false,
	company: undefined,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.COMPANY:
			return { ...state, company: action.payload.company };
		default:
			throw new Error();
	}
};

const CompanyActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): ICompanyActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setCompany(company: ICompany) {
			dispatch({ type: ActionType.COMPANY, payload: { company } });
		},
		handleEditCompany(company: { id?: string, data: EditCompany }) {
			actions.setLoading(true);

			if (company.id) {
				updateCompany(company.id, company.data).then((response) => {
					navigate('/company');
					enqueueSnackbar(response.data.message, { variant: 'success' });
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
			} else {
				saveCompany(company.data).then((response) => {
					navigate('/company');
					enqueueSnackbar(response.data.message, { variant: 'success' });
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
			}
		},
		getCompanyById(id: string) {
			actions.setLoading(true);
			getCompanyById(id).then((response) => {
				if (!response.data) {
					enqueueSnackbar('Empresa não encontrada.', {
						variant: 'error',
					});
					navigate('/company');
				}
				actions.setCompany(response.data);
				actions.setLoading(false);
			}).catch(() => {
				actions.setLoading(false);
			});
		},
	};

	return actions;
};

const CompanyEditContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => CompanyActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <CompanyEdit {...state} {...actions} />;
};

export default CompanyEditContainer;
