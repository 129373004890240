import { validationMessage } from '../../../helpers/yupMessages';
import Yup from '../../../helpers/yupMethods';
import { ITask } from '../TaskAssets';

export const validationSchema: Yup.AnySchema = Yup.object().shape({
	barCodeOrigin: Yup.string().required(validationMessage.required),
});

export interface GroupTasks {
	barCode: string;
	tasks: ITask[];
}

export interface TaskStorageFormik {
	confirmedTasks: ITask[];
	[key: string]: any;
}
