import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import { AlertTypes } from '../../enums/ConfirmationDialogType';

interface ConfirmationDialogProps {
	open: boolean;
	title?: string;
	description?: string;
	content?: React.ReactNode;
	onClose: () => void;
	onConfirm: () => void;
	alertType?: AlertTypes;
}

const Transition = React.forwardRef((
	props: TransitionProps & { children: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
	// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
	open,
	title,
	description,
	content,
	onClose,
	onConfirm,
	alertType = AlertTypes.ERROR,
}) => {
	const { icon, color } = useMemo(() => {
		switch (alertType) {
			case AlertTypes.ERROR:
				return { icon: <WarningIcon sx={{ mr: 1 }} />, color: 'error' as const };
			case AlertTypes.INFO:
				return { icon: <InfoIcon sx={{ mr: 1 }} />, color: 'info' as const };
			case AlertTypes.SUCCESS:
				return { icon: <CheckCircleIcon sx={{ mr: 1 }} />, color: 'success' as const };
			case AlertTypes.WARNING:
			default:
				return { icon: <WarningIcon sx={{ mr: 1 }} />, color: 'warning' as const };
		}
	}, [alertType]);

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-labelledby="confirmation-dialog-title"
			aria-describedby="confirmation-dialog-description"
		>
			<DialogTitle id="confirmation-dialog-title">
				<Typography color={color} display="flex" alignItems="center">
					{icon}
					{' '}
					{title}
				</Typography>
			</DialogTitle>
			<DialogContent>
				{ description && (
					<DialogContentText id="confirmation-dialog-description">
						{description}
					</DialogContentText>
				) }
				{ content }
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancelar</Button>
				<Button onClick={onConfirm} color={color} variant="contained" autoFocus>
					Confirmar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

ConfirmationDialog.defaultProps = {
	title: 'Confirmar exclusão',
	description: 'Tem certeza que deseja excluir o registro selecionado?',
	content: null,
	alertType: AlertTypes.ERROR,
};

export default ConfirmationDialog;
