import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import JsBarcode from 'jsbarcode';
import { EditProductFormProps, ILocationWithPagination } from './LocationAssets';
import LocationPresentational from '../../components/Location/Location';
import {
	deleteLocationProduct,
	getLocations,
	saveLocationProduct,
	updateLocationProduct,
} from '../../services/location';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { getProductsWithoutDetails } from '../../services/product';
import { IProductWithoutDetails } from '../Product/ProductAssets';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import PrintLabelTemplate from '../../constants/printLabelTemplate';

enum ActionType {
	LOADING,
	LOCATION,
	PRODUCT,
	LOCATIONS_FILTER,
	LOADING_PRODUCTS
}

interface IState {
	loading: boolean;
	loadingProducts: boolean;
	location: ILocationWithPagination;
	products: IProductWithoutDetails[];
	locationsFilter: ILocationWithPagination;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.LOADING_PRODUCTS; payload: { loadingProducts: boolean } }
	| { type: ActionType.PRODUCT; payload: { products: IProductWithoutDetails[] } }
	| { type: ActionType.LOCATION; payload: { location: ILocationWithPagination } }
	| { type: ActionType.LOCATIONS_FILTER; payload: { locationsFilter: ILocationWithPagination } };

interface ILocationActions {
    setLoading(loading: boolean): void;
    setLoadingProducts(loadingProducts: boolean): void;
	getLocations: (queryParams?: LocationProductQueryParams) => void;
	printLabel(barCode: string): void;
	updateLocationProduct(
		productId: string,
		locationId: string,
        values: EditProductFormProps,
	): Promise<void>;
	saveLocationProduct(
		productId: string,
		locationId: string,
        valuedQuantity: string
	): Promise<void>;
	getProductsWithoutDetails(queryParams: ProductQueryParams): void;
	deleteLocationProduct(productId: string, locationId: string): void;
}

const initialState: IState = {
	loading: false,
	loadingProducts: false,
	location: { count: 0, locations: [] },
	products: [],
	locationsFilter: { count: 0, locations: [] },
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.LOADING_PRODUCTS:
			return { ...state, loadingProducts: action.payload.loadingProducts };
		case ActionType.PRODUCT:
			return { ...state, products: action.payload.products };
		case ActionType.LOCATION:
			return {
				...state,
				location: action.payload.location,
			};
		case ActionType.LOCATIONS_FILTER:
			return {
				...state,
				locationsFilter: action.payload.locationsFilter,
			};
		default:
			throw new Error();
	}
};

const LocationActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): ILocationActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setLoadingProducts(loadingProducts: boolean) {
			dispatch({ type: ActionType.LOADING_PRODUCTS, payload: { loadingProducts } });
		},
		getLocations(queryParams?: LocationProductQueryParams) {
			actions.setLoading(true);
			const take = queryParams?.take ?? 10;

			const dataParams = {
				take,
				orderBy: 'asc',
				skip: queryParams?.skip ? (queryParams.skip - 1) * take : 0,
				currentQuantity: queryParams?.currentQuantity ? queryParams?.currentQuantity : undefined,
				locationValue: queryParams?.locationValue ? queryParams.locationValue : undefined,
				productIds: queryParams?.productIds?.length ? queryParams.productIds.join(',') : undefined,
				available: queryParams?.available !== undefined && (queryParams?.available !== '' as any) ? queryParams?.available : undefined,
			};

			getLocations(dataParams as LocationProductQueryParams)
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION,
						payload: {
							location: response.data,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		async updateLocationProduct(
			productId: string,
			locationId: string,
			values: EditProductFormProps,
		) {
			actions.setLoading(true);

			try {
				const result = await updateLocationProduct(productId, locationId, values);

				enqueueSnackbar(
					result.data.message || 'Produto atualizado com sucesso.',
					{ variant: 'success' },
				);
			} catch (e) {
				const error = e as AxiosError;
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			} finally {
				actions.setLoading(false);
			}
		},
		async saveLocationProduct(
			productId: string,
			locationId: string,
			valuedQuantity: string,
		) {
			actions.setLoading(true);
			try {
				const result = await saveLocationProduct(productId, locationId, valuedQuantity);
				enqueueSnackbar(
					result.data.message || 'Produto atualizado com sucesso.',
					{ variant: 'success' },
				);
			} catch (e) {
				const error = e as AxiosError;
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			} finally {
				actions.setLoading(false);
			}
		},
		getProductsWithoutDetails(queryParams: ProductQueryParams) {
			actions.setLoadingProducts(true);
			getProductsWithoutDetails(queryParams).then((response) => {
				dispatch({
					type: ActionType.PRODUCT,
					payload: {
						products: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			})
				.finally(() => {
					actions.setLoadingProducts(false);
				});
		},
		deleteLocationProduct(productId: string, locationId: string) {
			actions.setLoading(true);
			deleteLocationProduct(productId, locationId)
				.then((response) => {
					enqueueSnackbar(response?.data.message, { variant: 'success' });
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},
		printLabel(barCode: string) {
			const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

			JsBarcode(svgElement, barCode, {
				format: 'CODE128',
				displayValue: true,
				width: 2,
				height: 80,
				fontSize: 40,
			});

			const svgContent = svgElement.outerHTML;

			const templateString = PrintLabelTemplate({ svgContent });

			const printWindow = window.open('', '_blank');
			if (printWindow) {
				printWindow.document.open();
				printWindow.document.write(templateString);
				printWindow.document.close();
				printWindow.onload = () => printWindow.print();
			} else {
				enqueueSnackbar('Não foi possível abrir a janela de impressão.', {
					variant: 'error',
				});
			}
		},
	};

	return actions;
};

const Location = ():JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => LocationActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return (<LocationPresentational {...state} {...actions} />);
};

export default Location;
