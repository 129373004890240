import React, { useCallback, useState } from 'react';
import {
	Button,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormikContext, FieldArray } from 'formik';
import { IBusinessPartner, IProductBusinessPartner } from '../../../containers/BusinessPartner/BusinessPartnerAssets';
import { IProductWithoutDetails } from '../../../containers/Product/ProductAssets';
import Select from '../../Common/Form/Select';
import SwitchField from '../../Common/Form/Switch';
import Input from '../../Common/Form/Input';
import Pagination from '../../Common/Pagination';
import { BusinessPartnerType } from '../../../enums/BusinessPartnerType';
import Autocomplete from '../../Common/Form/Autocomplete';

interface ProductBPFormProps {
	products: IProductWithoutDetails[];
}

const ProductBPForm = ({ products }: ProductBPFormProps): JSX.Element => {
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { values, setFieldValue } = useFormikContext<IBusinessPartner>();

	const handleAddProduct = useCallback((arrayHelpers) => {
		const newProductBP = {
			productId: '',
			automaticPurchaseOrder: false,
			commissionValue: 0,
			businessPartnerId: values.id,
		};
		arrayHelpers.push(newProductBP);
	}, [values.id]);

	const getUnitOptions = useCallback(() => [
		{ value: values.id || values.name, label: 'Matriz', key: values.id },
		...values.units.map((unit, unitIndex) => (
			{ value: unit.id || unit.tempId, label: `Unidade ${unitIndex + 1} - ${unit.marketName || ''}`, key: unit.id || unit.tempId }
		)),
	], [values.id, values.name, values.units]);

	const handlePaginationChange = useCallback((newPage: number, newRowsPerPage: number) => {
		setPage(newPage);
		setRowsPerPage(newRowsPerPage);
	}, []);

	const renderProductBusinessPartners = useCallback((
		productBusinessPartners,
		arrayHelpers,
		unitIndex = null,
	) => {
		const productsBP = Array.isArray(productBusinessPartners) ? productBusinessPartners : [];
		return productsBP.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((
			productBP: IProductBusinessPartner,
			index: number,
		) => {
			const productBpIndex = unitIndex !== null ? `units.${unitIndex}.productBusinessPartners.${index}` : `productBusinessPartners.${index}`;
			return (
				<TableRow key={productBP.id || `${unitIndex}-${index}`}>
					<TableCell>
						<Autocomplete
							name={`${productBpIndex}.productId`}
							label=""
							options={products}
							labelKey="description"
							valueKey="id"
							valueLabel="code"
						/>
					</TableCell>
					{values.type === BusinessPartnerType.SUPPLIER && (
						<TableCell>
							<SwitchField
								name={`${productBpIndex}.automaticPurchaseOrder`}
								label="Pedido Automático"
								checked={productBP.automaticPurchaseOrder}
								color="primary"
								disabled={values.addAllProducts}
							/>
						</TableCell>
					)}
					{values.type !== BusinessPartnerType.SUPPLIER && (
						<TableCell>
							<Input.InputField
								name={`${productBpIndex}.comissionValue`}
								value={productBP.comissionValue}
								label=""
								fullWidth
								required
								disabled={values.addAllProducts}
							/>
						</TableCell>
					)}
					<TableCell>
						<Select
							name={`${productBpIndex}.businessPartnerId`}
							label=""
							options={getUnitOptions()}
							labelKey="label"
							valueKey="value"
						/>
					</TableCell>
					<TableCell>
						<IconButton onClick={() => arrayHelpers.remove(index)} color="error">
							<DeleteIcon />
						</IconButton>
					</TableCell>
				</TableRow>
			);
		});
	}, [getUnitOptions, page, products, rowsPerPage, values.addAllProducts, values.type]);

	return (
		<>
			<Typography variant="caption" display="block" sx={{ marginBottom: 2 }}>
				Todos os campos são obrigatórios!
			</Typography>
			<TableContainer
				component={Paper}
				sx={{
					'& .MuiTableCell-root': {
						py: 0.5,
					},
					'& .MuiTableRow-root': {
						height: 'auto',
					},
				}}
			>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{ flex: 1 }}>Produto</TableCell>
							{values.type === BusinessPartnerType.SUPPLIER && (
								<TableCell sx={{ flex: 1 }}>Pedido Automático</TableCell>
							)}
							{values.type !== BusinessPartnerType.SUPPLIER && (
								<TableCell sx={{ flex: 1 }}>% Comissão</TableCell>
							)}
							<TableCell sx={{ flex: 1 }}>Unidade</TableCell>
							<TableCell sx={{ flex: 1 }}>Ações</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<FieldArray
							name="productBusinessPartners"
							render={(arrayHelpers) => (
								<>
									{renderProductBusinessPartners(values.productBusinessPartners, arrayHelpers)}
									{values.units.map((unit, unitIndex) => (
										<FieldArray
											key={unit.id}
											name={`units.${unitIndex}.productBusinessPartners`}
											render={(unitArrayHelpers) => (
												renderProductBusinessPartners(
													unit.productBusinessPartners,
													unitArrayHelpers,
													unitIndex,
												)
											)}
										/>
									))}
									<TableRow>
										<TableCell colSpan={5}>
											<Box display="flex" justifyContent="space-between" alignItems="center">
												<Button onClick={() => handleAddProduct(arrayHelpers)} color="primary" disabled={values.addAllProducts}>
													Adicionar Produto
												</Button>
												<SwitchField
													name="addAllProducts"
													label="Adicionar Todos os Produtos"
													onChange={(event) => setFieldValue('addAllProducts', event.target.checked)}
													color="primary"
												/>
											</Box>
										</TableCell>
									</TableRow>
									{values.addAllProducts && (
										<TableRow>
											<TableCell colSpan={5}>
												<Box display="flex" justifyContent="space-between" alignItems="center">
													{values.type === BusinessPartnerType.SUPPLIER && (
														<SwitchField
															name="addAllAutomaticPurchaseOrder"
															label="Pedido Automático para Todos"
															onChange={(event) => setFieldValue('addAllAutomaticPurchaseOrder', event.target.checked)}
															color="primary"
														/>
													)}
													{values.type === BusinessPartnerType.CLIENT && (
														<Input.InputField
															name="addAllComissionValue"
															label="Valor Comissão para Todos %"
															fullWidth
															onChange={(event) => setFieldValue('addAllComissionValue', event.target.value)}
															required
														/>
													)}
												</Box>
											</TableCell>
										</TableRow>
									)}
								</>
							)}
						/>
					</TableBody>
				</Table>
				<Pagination
					loading={false}
					count={Math.ceil(values.productBusinessPartners.length / rowsPerPage)}
					page={page}
					take={rowsPerPage}
					onChange={handlePaginationChange}
				/>
			</TableContainer>
		</>
	);
};

export default ProductBPForm;
