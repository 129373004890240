import React from 'react';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import Decimal from 'decimal.js';
import { currencyBRLMask } from '../../../helpers/intl';
import ProgressBar from '../../Common/Dashboard/ProgressBar';

export const unchangeableDataGridColumns = [
	{
		id: 1,
		field: 'totalValue',
		headerName: 'Orçado',
		type: 'number',
		width: 150,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => currencyBRLMask(new Decimal(params.value as string)),
	},
	{
		id: 2,
		field: 'realizedValue',
		headerName: 'Realizado',
		type: 'number',
		width: 150,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => currencyBRLMask(new Decimal(params.value as string)),
	},
	{
		id: 3,
		field: 'commitedValue',
		headerName: 'Aprovado',
		type: 'number',
		width: 150,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => currencyBRLMask(new Decimal(params.value as string)),
	},
	{
		id: 4,
		field: 'balance',
		headerName: 'Saldo',
		type: 'number',
		width: 150,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => currencyBRLMask(new Decimal(params.value as string)),
	},
	{
		id: 5,
		field: 'balancePercentage',
		headerName: '% Saldo',
		renderCell: (
			params: any,
		) => <ProgressBar value={Number.parseInt(params.value as string, 10)} />,
		width: 150,
	},
	{
		id: 6,
		field: 'toCommitValue',
		headerName: 'A aprovar',
		type: 'number',
		width: 150,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => currencyBRLMask(new Decimal(params.value as string)),
	},
	{
		id: 7,
		field: 'toCommitPercentage',
		headerName: '% a aprovar',
		renderCell: (
			params: any,
		) => <ProgressBar reverse value={Number.parseInt(params.value as string, 10)} />,
		width: 150,
	},
];
