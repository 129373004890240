import { AxiosResponse } from 'axios';
import api from './api';
import { IMessageResponse } from '../interfaces/MessageResponse';
import { ILocationType, ILocationTypes } from '../containers/Location/LocationAssets';

export const getLocationTypes = (): Promise<AxiosResponse<ILocationTypes>> => api.get<ILocationTypes>('/location-type');

export const getLocationTypeById = (id: string): Promise<AxiosResponse<ILocationType>> => api.get<ILocationType>(`/location-type/${id}`);

export const saveLocationType = (
	data: ILocationType,
): Promise<AxiosResponse<IMessageResponse>> => api.post<ILocationType, AxiosResponse<IMessageResponse>>('/location-type', data);

export const updateLocationType = (id: string, data: ILocationType): Promise<AxiosResponse<IMessageResponse>> => api.patch<ILocationType, AxiosResponse<IMessageResponse>>(`/location-type/${id}`, data);

export const deleteLocationType = (id: string): Promise<AxiosResponse<IMessageResponse>> => api.delete<ILocationType, AxiosResponse<IMessageResponse>>(`/location-type/${id}`);
