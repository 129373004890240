import { AxiosResponse } from 'axios';
import api from './api';
import {
	ISaleOrder, IPaymentTerms,
	ISaleOrderResponse,
} from '../containers/SaleOrder/SaleOrderAssets';
import { SaleOrderQueryParams } from '../interfaces/SaleOrderQueryParams';

export interface IMessageResponse {
	message: string;
}

export const getSaleOrdersAdmin = (params: SaleOrderQueryParams): Promise<AxiosResponse<ISaleOrderResponse>> => api.get<ISaleOrderResponse>('/sale-order/admin', { params });

export const getSaleOrders = (params: SaleOrderQueryParams): Promise<AxiosResponse<ISaleOrderResponse>> => api.get<ISaleOrderResponse>('/sale-order', { params });

export const getSaleOrderById = (
	id: string,
): Promise<AxiosResponse<ISaleOrder>> => api.get<ISaleOrder>(`/sale-order/${id}`);

export const saveSaleOrder = (data: ISaleOrder): Promise<AxiosResponse<ISaleOrderResponse>> => api.post<ISaleOrderResponse, AxiosResponse<ISaleOrderResponse>>('/sale-order', data);

export const updateSaleOrder = (id: string, data: ISaleOrder): Promise<AxiosResponse<IMessageResponse>> => api.patch<ISaleOrder, AxiosResponse<IMessageResponse>>(`/sale-order/${id}`, data);

export const cancelSaleOrder = (id: string, actionJustificative: string): Promise<AxiosResponse<IMessageResponse>> => api.patch<IMessageResponse>(`/sale-order/cancel/${id}`, { actionJustificative });

export const deleteSaleOrder = (id: string): Promise<AxiosResponse<IMessageResponse>> => api.delete<IMessageResponse>(`/sale-order/${id}`);

export const getTopSellingProducts = (
	businessPartnerId: string,
	limit: number,
): Promise<AxiosResponse<any>> => api.get<any>(`/sale-order/top-selling-products?businessPartnerId=${businessPartnerId}&limit=${limit}`);

export const getRangePriceCommissions = (
	products?: string[],
	paymentTerm?: string,
): Promise<AxiosResponse<any>> => api.get<any>('/sale-order/range-price-commissions', {
	params: {
		products,
		paymentTerm,
	},
});

export const getRangePriceCommissionsByValue = (
	paymentTermId: string,
	productId: string,
	price: number,
): Promise<AxiosResponse<any>> => api.get<any>('/sale-order/get-price-commission-by-value', {
	params: {
		paymentTermId,
		productId,
		price,
	},
});

export const getPaymentTerms = (): Promise<AxiosResponse<IPaymentTerms>> => api.get<IPaymentTerms>('/sale-order/payment-terms');
