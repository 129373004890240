import React, {
	Box, SxProps, Theme, Tooltip,
} from '@mui/material';

interface ITruncateTooltipCellProps {
  value?: string;
}

const sxText: SxProps<Theme> = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

const TruncateTooltipCell = ({ value }: ITruncateTooltipCellProps): JSX.Element => (
	<Tooltip title={value}>
		<Box sx={sxText} component="span">{value}</Box>
	</Tooltip>
);

TruncateTooltipCell.defaultProps = {
	value: '',
};

export default TruncateTooltipCell;
