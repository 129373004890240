import { AxiosResponse } from 'axios';
import localizationApi from './localizationApi';
import { IMessageResponse } from '../interfaces/MessageResponse';

export interface IRegionState {
  id: string;
  abbrev: string;
  name: string;
}

export interface IRegionStates {
  data: IRegionState[];
  count: number;
}

export const getStates = (): Promise<AxiosResponse<IRegionStates>> => localizationApi.get<IRegionStates>('/state');

export const getStateById = (id: string): Promise<AxiosResponse<IRegionState>> => localizationApi.get<IRegionState>(`/state/${id}`);

export const saveState = (data: IRegionState): Promise<AxiosResponse<IMessageResponse>> => localizationApi.post<IRegionState, AxiosResponse<IMessageResponse>>('/state', data);

export const updateState = (id: string, data: IRegionState): Promise<AxiosResponse<IMessageResponse>> => localizationApi.patch<IRegionState, AxiosResponse<IMessageResponse>>(`/state/${id}`, data);

export const deleteState = (id: string): Promise<AxiosResponse<IMessageResponse>> => localizationApi.delete<IMessageResponse>(`/state/${id}`);
