import React, {
	useEffect,
	useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper/Paper';
import Stack from '@mui/material/Stack/Stack';
import { FormTitle } from '../User/Forms/UserFormSections';
import {
	IBranchActions,
	IState,
} from '../../containers/Branch/BranchEdit';
import BranchForm from './BranchForm';
import { EditBranch, IBranch } from '../../containers/Branch/BranchAssets';
import { Loading } from '../Common/Loading';

interface BranchEditProps extends Omit<IState, 'branch'>, IBranchActions {
	branch?: IBranch;
	loading: boolean;
}

const BranchEdit = ({
	loading,
	branch,
	companies,
	getCompanies,
	getBranchById,
	handleEditBranch,
}: BranchEditProps): JSX.Element => {
	const { id, companyId } = useParams<{ id: string; companyId?: string }>();

	useEffect(() => {
		getCompanies();
	}, [getCompanies]);

	useEffect(() => {
		if (id) {
			getBranchById(id, companyId ?? '');
		}
	}, [id, companyId, getBranchById]);

	const handleSubmit = useCallback(
		(values: EditBranch): void => {
			const formData = new FormData();
			if (values.logo && values.logo.file) {
				formData.append('logo', values.logo.file);
			}

			const keysWithoutLogo = Object.keys(values).filter((key) => key !== 'logo');
			keysWithoutLogo.forEach((key) => {
				const value = values[key as keyof EditBranch];
				formData.append(key, String(value));
			});

			handleEditBranch({ id: branch?.id, data: formData }, branch);
		},
		[branch, handleEditBranch],
	);

	if (id && !branch) {
		return <Loading />;
	}

	return (
		<Paper sx={{ mt: 3, p: 3 }} elevation={3}>
			<Stack spacing="4rem">
				<FormTitle>
					{`${branch ? 'Editar' : 'Cadastrar'} Filial`}
				</FormTitle>
				<BranchForm
					branch={branch}
					companies={companies}
					onSubmit={handleSubmit}
					loading={loading}
				/>
			</Stack>
		</Paper>
	);
};

BranchEdit.defaultProps = {
	branch: undefined,
};

export default BranchEdit;
