import React, { useCallback, useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { userTypeMenu } from '../../../containers/Order/PurchaseOrderApproval';

interface IUserTypeSelectorProps {
    userTypes: number[],
    userType: number,
    onChange: (userType: number) => void
}

export const UserTypeSelector = ({
	userTypes,
	userType,
	onChange,
}: IUserTypeSelectorProps): JSX.Element | null => {
	const userTypeItems = useMemo(() => {
		if (userTypes.length === 0) {
			return null;
		}

		return userTypes.map((currentUserType) => (
			<MenuItem key={currentUserType} value={currentUserType}>
				{ userTypeMenu[currentUserType] }
			</MenuItem>
		));
	}, [userTypes]);

	const handleChangeUserType = useCallback((event: SelectChangeEvent) => {
		onChange(Number.parseInt(event.target.value, 10));
	}, [onChange]);

	if (userTypes.length === 1) {
		return null;
	}

	return (
		<FormControl>
			<InputLabel id="userType">Nível de uso</InputLabel>
			<Select
				labelId="userType"
				id="userType"
				value={userType.toString()}
				autoWidth
				label="Nível de uso"
				onChange={handleChangeUserType}
			>
				{ userTypeItems }
			</Select>
		</FormControl>
	);
};
