import { LocationAvailable } from '../enums/LocationAvailable';

export const availableFilterData = [
	{
		label: 'Disponível',
		value: LocationAvailable.AVAILABLE,
	},
	{
		label: 'Não Disponível',
		value: LocationAvailable.NOT_AVAILABLE,
	},
];
