import React, { useMemo, useState } from 'react';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import { PageHeaderButtonProps, PageHeaderMenuProps } from '../../../interfaces/PageHeaderInterface';

export const PageHeaderMenu = ({
	options,
}: PageHeaderMenuProps): JSX.Element => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);

	const handleClick = (event: any): void => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
		setOpen(false);
	};

	const optionsMemo = useMemo(
		() => options.map((option) => {
			const props = omit(option, [
				'text',
				'startIcon',
				'show',
				'isCustomComponent',
			]);

			const menuItemProps: { key: string, props?: Omit<PageHeaderButtonProps, 'text' | 'startIcon' | 'show' | 'isCustomComponent'> } = {
				key: option.text,
			};

			if (!option.isCustomComponent) {
				menuItemProps.props = props;
			}

			/* TODO -
			Verificar essa questão aqui, pois não está herdando do ButtonBase como na documentação. */
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			/* @ts-ignore */
			// eslint-disable-next-line react/jsx-props-no-spreading
			return <MenuItem key={menuItemProps.key} {...menuItemProps?.props}>{option.text}</MenuItem>;
		}),
		[options],
	);

	return (
		<>
			<IconButton onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{optionsMemo}
			</Menu>
		</>
	);
};
