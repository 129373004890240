import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FormObserver = (): null => {
	const { values, setFieldValue }: any = useFormikContext();

	useEffect(() => {
		if (values.initialMonth > values.finalMonth) {
			setFieldValue('finalMonth', values.initialMonth === 11 ? values.initialMonth : values.initialMonth + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.initialMonth]);

	useEffect(() => {
		if (values.initialMonth > values.finalMonth) {
			setFieldValue('initialMonth', values.finalMonth === 0 ? values.finalMonth : values.finalMonth - 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.finalMonth]);

	return null;
};

export default FormObserver;
