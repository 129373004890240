import * as Yup from 'yup';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { InventoryTaskStatus } from '../enums/InventoryTaskStatus';
import {
	InventoryTaskQueryParams,
	InventoryTaskTransferProductInitialValue,
} from '../interfaces/InventoryTaskQueryParams';
import {
	ActionTableTask,
	Branch,
	ChangeScreenTask,
	CountDiffTaskMap,
	StatusTaskMap,
	StatusTaskMapParams,
} from '../containers/Task/InventoryTaskAssets';
import { InventoryTaskDiff } from '../enums/InventoryTaskDiff';
import { InventoryTaskAction } from '../enums/InventoryTaskAction';
import { formatUTCDateToLocal } from '../helpers/DateUtils';
import { validationMessage } from '../helpers/yupMessages';
import { TransferProductModalProps } from '../components/Task/Modals/TransferProductModal';
import { ILocationProductData } from '../containers/Product/ProductAssets';
import { formatNumberForLocale } from '../helpers/NumberUtils';

export const inventoryTaskStatus = [
	'Pendente',
	'Em fila',
	'Em armazenagem',
	'Concluído',
	'Encerrado',
];

export const createStatusTaskMap = ({ operatingLabel }: StatusTaskMapParams): StatusTaskMap => ({
	[InventoryTaskStatus.PENDING]: { label: 'Pendente' },
	[InventoryTaskStatus.WAITING]: { label: 'Em fila' },
	[InventoryTaskStatus.OPERATING]: { label: operatingLabel },
	[InventoryTaskStatus.FINISHED]: { label: 'Concluído' },
	[InventoryTaskStatus.DELETED]: { label: 'Encerrado' },
});

export const statusTask: StatusTaskMap = {
	[InventoryTaskStatus.PENDING]: { label: 'Pendente' },
	[InventoryTaskStatus.OPERATING]: { label: 'Em andamento' },
	[InventoryTaskStatus.FINISHED]: { label: 'Concluído' },
};

export const countDiffTask: CountDiffTaskMap = {
	[InventoryTaskDiff.MINUS]: { label: 'A menor' },
	[InventoryTaskDiff.MORE]: { label: 'A maior' },
};

export const inventoryTaskStatusFilter = [
	{
		id: '0',
		label: 'Pendente',
		value: String(InventoryTaskStatus.PENDING),
	},
	{
		id: '1',
		label: 'Em andamento',
		value: String(InventoryTaskStatus.OPERATING),
	},
	{
		id: '2',
		label: 'Concluído',
		value: String(InventoryTaskStatus.FINISHED),
	},
	{
		id: '3',
		label: 'Todas',
		value: '99',
	},
];

export const changeScreenData: Array<ChangeScreenTask> = [
	{
		id: InventoryTaskAction.SUPPLY,
		label: 'Armazenagem',
		value: InventoryTaskAction.SUPPLY,
	},
	{
		id: InventoryTaskAction.DISPATCH,
		label: 'Expedição',
		value: InventoryTaskAction.DISPATCH,
	},
	{
		id: InventoryTaskAction.MOVE,
		label: 'Transferência',
		value: InventoryTaskAction.MOVE,
	},
];

export const actionTable: Array<ActionTableTask> = [
	{
		id: 0,
		label: 'Encerrar tarefa',
		value: 0,
	},
];

export const drawerFilterInventoryPendingTask = Yup.object().shape({
	client: Yup.string().nullable(),
	companyId: Yup.string().nullable(),
	startDate: Yup.date().nullable(),
	endDate: Yup.date()
		.nullable()
		.when('startDate', (startDate, schema) => (startDate
			? schema
				.required('Informe uma data')
				.min(
					startDate,
					"A data do campo 'Data final', não pode ser menor que o campo 'Data inicial'",
				)
			: schema)),
	saleOrder: Yup.string().nullable(),
	status: Yup.number().nullable(),
});

export const validationSchemaTrasnferTask = (
	props: Partial<TransferProductModalProps>,
): Yup.AnyObjectSchema => Yup.object().shape({
	productId: Yup.string().required(validationMessage.string.required),
	productQuantity: Yup.number()
		.required(validationMessage.number.required)
		.min(1, validationMessage.number.min(1))
		.test(
			'check-quantity-origin',
			'A quantidade informada excede a quantidade disponível na localização de origem.',
			(value, context) => {
				const productQuantityValue = value || 0;
				const { productLocations = [] } = props;
				const { productId, locationOriginId } = context.parent;

				if (!productId || !locationOriginId) {
					return false;
				}

				const {
					product: { currentQuantity },
				} = productLocations.find((productLocation) => productLocation.id === locationOriginId
                && productLocation.product.id === productId) || ({} as ILocationProductData);

				if (productQuantityValue > Number(currentQuantity)) {
					return context.createError({
						message: `A quantidade informada excede a quantidade de ${formatNumberForLocale(
							currentQuantity,
							2,
						)} disponíveis na localização de origem`,
					});
				}

				return true;
			},
		)
		.test(
			'check-quantity-destiny',
			'A quantidade informada excede a capacidade da localização de destino.',
			(value, context) => {
				const productQuantityValue = value || 0;
				const { productLocations = [] } = props;
				const { productId, locationDestinyId } = context.parent;

				if (!productId || !locationDestinyId) {
					return false;
				}

				const {
					product: { currentQuantity, valuedQuantity },
				} = productLocations.find((productLocation) => productLocation.id === locationDestinyId
                && productLocation.product.id === productId) || ({} as ILocationProductData);

				const remainingCapacity = Number(valuedQuantity) - Number(currentQuantity);

				if (productQuantityValue > remainingCapacity) {
					return context.createError({
						message: `A quantidade informada excede a capacidade disponível de ${formatNumberForLocale(
							remainingCapacity,
							2,
						)} itens na localização de destino.`,
					});
				}

				return !(productQuantityValue > remainingCapacity);
			},
		),
	locationOriginId: Yup.string()
		.nullable()
		.required(validationMessage.string.required),
	locationDestinyId: Yup.string()
		.nullable()
		.required(validationMessage.string.required),
});

const inventoryTypeString = localStorage.getItem('inventoryType');
export const defaultFilterContextScreen = inventoryTypeString ? Number(
	inventoryTypeString,
) : InventoryTaskAction.SUPPLY;

export const defaultInventoryTaskQueryParams: InventoryTaskQueryParams = {
	skip: 0,
	take: 10,
	status: InventoryTaskStatus.PENDING,
	type: defaultFilterContextScreen,
	client: '',
	shipping: '',
	startDate: '',
	endDate: '',
	companyId: '',
	branchId: '',
	saleOrder: '',
};

export const defaultTrasferProductParams: InventoryTaskTransferProductInitialValue = {
	productId: '',
	invoiceValue: 0,
	companyDocument: '',
	company: '',
	branch: '',
	locationOriginId: '',
	locationDestinyId: '',
	productCode: '',
	productQuantity: 0,
	product: '' as any,
};

export const companyDataAllTech = {
	document: '04600412000113',
	branch: '01',
	company: '01',
};

export const columnTransfer: Array<MUIDataTableColumnDef> = [
	{
		name: 'status',
		label: 'Status',
		options: {
			customBodyRender: (value: InventoryTaskStatus) => statusTask[value]?.label || 'Status Desconhecido',
		},
	},
	{
		name: 'branch',
		label: 'Empresa/Filial',
		options: {
			customBodyRender: (branch: Branch) => `${branch.company.name} / ${branch.name}`,
		},
	},
	{
		name: 'invoiceNumber',
		label: 'Documento',
	},
	{
		name: 'fiscalEntry',
		label: 'Data criação',
		options: {
			customBodyRender: (value: Date) => formatUTCDateToLocal(value, 'dd/MM/yyyy'),
		},
	},
];
