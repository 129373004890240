import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Decimal from 'decimal.js';
import {
	DataGrid,
	GridColDef,
	GridSelectionModel,
	GridColumnVisibilityModel,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import Tooltip from '@mui/material/Tooltip';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps } from '@mui/system';
import NoDataPage from '../../Common/NoDataPage';
import { UserType } from '../../../containers/Order/PurchaseOrderApproval';
import { currencyBRLMask } from '../../../helpers/intl';
import useColumns from '../../../hooks/useColumnsPurchaseOrder';
import { useManageColumns } from '../../../hooks/useManageColumns';
import { ToolbarComponent } from '../../Common/Datagrid/DataGridToolBar';
import ActionJustificativeModal from '../../Common/ActionJustificativeModal';
import { BudgetApprover, IPurchasesWithJustificative, ISendToOverrun } from '../../../interfaces/PurchaseOrderApproval';
import useExpandAccordions from '../../../hooks/useExpandAccordions';

const budgetContainer: SxProps<Theme> = {
	width: '100%',
	display: 'flex',
	my: '10px',
	justifyContent: 'space-between',
};
const budgetInfo: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
	gap: '5px',
	flex: '1 1',
};
const buttons: SxProps<Theme> = {
	display: 'flex',
	gap: '1rem',
	mt: '10px',
};

const localStorageKey = 'purchaseOrdersColumns';

const columnsVisibility = [
	'nrOrder',
	'status',
	'providerName',
	'productDescription',
	'quantity',
	'unitValue',
	'totalValue',
	'lastPurchaseValue',
	'observation',
	'actions',
];

const initialModalProps = {
	open: false,
	id: '',
	title: '',
	action: undefined,
};

interface ModalProps {
	open: boolean;
	id?: string;
	title: string;
	action: ((justificative: string, id?: string) => void) | undefined;
}

interface PurchaseOrderApprovalTableProps {
	rows: any[];
	userType: UserType;
	expandAccordions: boolean;
	selectAllRows?: boolean;
	approve(budgetId: string, ids: string[], userType?: UserType): void;
	reject(data: IPurchasesWithJustificative): void;
	openBudgetValueTransferModal(budgetId: string): void;
	sendToOverrun(data: ISendToOverrun): void;
	onApportionmentClick(id: string): void;
	onDetailsClick(id: string): void;
}

interface IDataGrid {
	id: string;
	rows: any[];
	selectAllRows?: boolean;
	currentColumns: GridColDef[];
	onSelect(params: { id: string, rows: any }): void;
	columnVisibilityModel: GridColumnVisibilityModel;
	handleColumnVisibilityModelChange(model: GridColumnVisibilityModel): void;
	onRestoreClick: () => void;
}

const DataGridConnected = ({
	id,
	rows,
	onSelect,
	selectAllRows,
	currentColumns,
	handleColumnVisibilityModelChange,
	columnVisibilityModel,
	onRestoreClick,
}: IDataGrid): JSX.Element => {
	const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);

	useEffect(() => {
		if (selectAllRows) {
			const allRowIds = rows.map((row) => row.id);
			setSelectedRows(allRowIds);
			onSelect({ id, rows: allRowIds });
		} else {
			setSelectedRows([]);
			onSelect({ id, rows: [] });
		}
	}, [id, onSelect, rows, selectAllRows]);

	const onSelectRows = useCallback(
		(selectionModel: GridSelectionModel) => {
			setSelectedRows(selectionModel);
			onSelect({ id, rows: selectionModel });
		},
		[id, onSelect],
	);

	return (
		<DataGrid
			rows={rows}
			columns={currentColumns}
			hideFooter
			checkboxSelection
			onSelectionModelChange={onSelectRows}
			selectionModel={selectedRows}
			disableSelectionOnClick
			disableColumnMenu
			autoHeight
			columnVisibilityModel={columnVisibilityModel}
			onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
			components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
		/>
	);
};

DataGridConnected.defaultProps = {
	selectAllRows: false,
};

interface RejectAndApproveButtonsProps {
	id: string;
	userType: UserType;
	disabled: boolean;
	approve(id: string): void;
	overrun: boolean;
	reject(justificative: string, id?: string): void;
	openJustificativeModal: (
		id: string, title: string, action: (justificative: string, id?: string) => void) => void;
}

const RejectAndApproveButtons = ({
	id, userType, disabled, approve, reject, openJustificativeModal, overrun,
}: RejectAndApproveButtonsProps): JSX.Element => (
	<Box sx={buttons}>
		{userType !== UserType.RESPONSIBLE && (
			<Button
				variant="contained"
				color="error"
				fullWidth
				disabled={disabled}
				onClick={() => openJustificativeModal(id, 'Rejeitar Pedido', reject)}
			>
				Rejeitar
			</Button>
		)}
		<Button
			variant="contained"
			color="primary"
			fullWidth
			disabled={disabled || overrun}
			onClick={() => approve(id)}
		>
			{overrun
				? 'Não há saldo suficiente no orçamento para aprovação'
				: 'Aprovar'}
		</Button>
	</Box>
);

interface OverrunButtonsProps {
	id: string;
	userType: UserType;
	disabled: boolean;
	openTransferModal(id: string): void;
	sendToOverrun(justificative: string, id?: string): void;
	openJustificativeModal: (
		id: string, title: string, action: (justificative: string, id?: string) => void) => void;
}

const OverrunButtons = ({
	id, userType, disabled, openTransferModal, sendToOverrun, openJustificativeModal,
}: OverrunButtonsProps): JSX.Element => (
	<Box sx={buttons}>
		{userType !== UserType.CONTROLLERSHIP && userType !== UserType.RESPONSIBLE && (
			<Button
				variant="contained"
				color="primary"
				fullWidth
				onClick={() => openTransferModal(id)}
			>
				Solicitar transferência de saldo
			</Button>
		)}
		<Button
			variant="contained"
			color="warning"
			fullWidth
			disabled={disabled}
			onClick={() => openJustificativeModal(id, 'Enviar Para Estouro', sendToOverrun)}
		>
			Enviar pedido(s) para aprovação de estouro.
		</Button>
	</Box>
);

const PurchaseOrderApprovalTable = (props: PurchaseOrderApprovalTableProps): JSX.Element => {
	const {
		rows,
		userType,
		expandAccordions,
		selectAllRows,
		reject,
		approve,
		openBudgetValueTransferModal,
		sendToOverrun,
		onApportionmentClick,
		onDetailsClick,
	} = props;
	const [selectedRows, setSelectedRows] = useState<{ id: string; rows: Array<string> }[]>([]);
	const { expandPanels, togglePanel } = useExpandAccordions(rows, expandAccordions);
	const [modalProps, setModalProps] = useState<ModalProps>(initialModalProps);

	const openJustificativeModal = useCallback(
		(id: string, title: string, action: (justificative: string, id?: string) => void) => {
			setModalProps({
				open: true,
				id,
				title,
				action,
			});
		},
		[],
	);

	const closeJustificativeModal = useCallback(() => {
		setModalProps((prev) => ({ ...prev, open: false }));
	}, []);

	const { columns, defaultVisibility } = useColumns({
		isDetailed: false,
		onApportionmentClick,
		onDetailsClick,
		columnsVisibility,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	const handleAccordionChange = useCallback(
		(panelId: string) => () => {
			togglePanel(panelId);
		},
		[togglePanel],
	);

	const onSelect = useCallback(
		(tableRow) => {
			setSelectedRows((currentSelectedRows) => {
				const newSelectedRows = [...currentSelectedRows];
				const selectedRow = newSelectedRows.find((row) => row.id === tableRow.id);

				if (selectedRow) {
					selectedRow.rows = tableRow.rows;
				} else {
					newSelectedRows.push(tableRow);
				}

				return newSelectedRows;
			});
		},
		[],
	);

	const hasSelectedRows = useCallback(
		(id: string) => selectedRows.some((row) => row.id === id && row.rows && row.rows.length > 0),
		[selectedRows],
	);

	const rejectProxy = useCallback(
		(justificative: string, id?: string) => {
			const selectedRow = selectedRows.find((row) => row.id === id);

			if (selectedRow && selectedRow.rows.length > 0) {
				const rejectData: IPurchasesWithJustificative = {
					ids: selectedRow.rows,
					actionJustificative: justificative,
				};
				reject(rejectData);
			}
		},
		[reject, selectedRows],
	);

	const approveProxy = useCallback(
		(id: string) => {
			const selectedRow = selectedRows.find((row) => row.id === id);

			if (selectedRow && selectedRow.rows.length > 0) {
				approve(selectedRow.id, selectedRow.rows, userType);
			}
		},
		[approve, selectedRows, userType],
	);

	const sendToOverrunProxy = useCallback((justificative: string, id?: string) => {
		const selectedRow = selectedRows.find((row) => row.id === id);

		if (selectedRow && selectedRow.rows.length > 0) {
			const sendToOverrunData: ISendToOverrun = {
				ids: selectedRow.rows,
				actionJustificative: justificative,
				type: userType,
			};
			sendToOverrun(sendToOverrunData);
		}
	}, [selectedRows, sendToOverrun, userType]);

	const getOnGoingValue = useCallback(
		(id: string, purchases: any[]) => {
			const selectedRow = selectedRows.find((row) => row.id === id);

			if (selectedRow) {
				const purchasesFiltered = purchases.filter(
					(purchase: any) => selectedRow.rows.find((row) => row === purchase.id),
				);
				return purchasesFiltered.reduce(
					(total: Decimal, purchase) => total.plus(purchase.totalValue),
					new Decimal(0),
				);
			}

			return 0;
		},
		[selectedRows],
	);

	const accountingAccountTooltip = useCallback((budgetApprovers: BudgetApprover[]) => {
		if (budgetApprovers.length === 0) {
			return <span>Não há aprovadores cadastrados</span>;
		}

		return (
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				{budgetApprovers.map((approver) => (
					<span key={approver.user.name}>{approver.user.name}</span>
				))}
			</Box>
		);
	}, []);

	const content = useMemo(() => {
		if (!rows || (rows && rows.length === 0)) {
			return <NoDataPage />;
		}

		return (
			rows
			&& rows.map((row) => {
				const inProcessValue = row.totalValue
					.minus(row.commitedValue)
					.minus(row.realizedValue)
					.minus(getOnGoingValue(row.id, row.purchaseOrderApprovals));
				const totalPurchaseValue = row.purchaseOrderApprovals.reduce(
					(total: Decimal, purchase: any) => total.plus(purchase.totalValue),
					new Decimal(0),
				);
				const overrun = userType !== UserType.CONTROLLERSHIP && inProcessValue < 0;

				return (
					<Accordion
						key={row.id}
						expanded={expandPanels.includes(row.id)}
						onChange={handleAccordionChange(row.id)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`${row.id}-content`}
							id={`${row.id}-header`}
						>
							<Typography
								sx={{
									width: '10%',
									flexShrink: 0,
								}}
							>
								{`${row.code}`}
							</Typography>
							<Typography
								sx={{
									width: '60%',
									flexShrink: 0,
									color: 'text.secondary',
								}}
							>
								<Tooltip title={accountingAccountTooltip(row.accountingAccount.budgetApprovers)}>
									<span>{`Cta. Contábil: ${row.accountingAccount.name}`}</span>
								</Tooltip>
								{` - C. Custo: ${row.costCenter.name} - Filial: ${row.accountingItem.name} - CliFor.: ${row.classValue.name}`}
							</Typography>
							<Typography
								sx={{
									color: 'text.primary',
								}}
							>
								{`Total a aprovar: ${currencyBRLMask(totalPurchaseValue)}`}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<React.Fragment key={row.id}>
								<Box sx={budgetContainer}>
									<Box sx={budgetInfo}>
										<span>(O)rçado</span>
										<span>{currencyBRLMask(row.totalValue)}</span>
									</Box>
									<Box sx={budgetInfo}>
										<span>(R)ealizado</span>
										<span>{currencyBRLMask(row.realizedValue)}</span>
									</Box>
									<Box sx={budgetInfo}>
										<span>(A)provado</span>
										<Box sx={{ display: 'flex' }}>
											<Typography
												sx={{ color: 'text.primary' }}
											>
												{currencyBRLMask(row.commitedValue)}
											</Typography>
											+
											<Typography
												sx={{
													color: 'pallete.info.main',
												}}
											>
												{currencyBRLMask(getOnGoingValue(
													row.id,
													row.purchaseOrderApprovals,
												))}
											</Typography>
										</Box>
									</Box>
									<Box sx={budgetInfo}>
										<span>Saldo em processo</span>
										<span>
											<Tooltip title="Orçado - Aprovado - Realizado">
												<span>(O-A-R) =</span>
											</Tooltip>
											{' '}
											{currencyBRLMask(inProcessValue)}
										</span>
									</Box>
									<Box sx={budgetInfo}>
										<span>Saldo Realizado</span>
										<span>
											<Tooltip title="Orçado - Realizado">
												<span>(O-R) =</span>
											</Tooltip>
											{' '}
											{currencyBRLMask(row.totalValue.minus(row.realizedValue))}
										</span>
									</Box>
								</Box>
								<DataGridConnected
									id={row.id}
									rows={row.purchaseOrderApprovals}
									currentColumns={currentColumns}
									selectAllRows={selectAllRows}
									onSelect={onSelect}
									columnVisibilityModel={columnVisibilityModel}
									handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
									onRestoreClick={onRestoreClick}
								/>
							</React.Fragment>
							<RejectAndApproveButtons
								id={row.id}
								userType={userType}
								disabled={!hasSelectedRows(row.id)}
								reject={rejectProxy}
								overrun={overrun}
								approve={approveProxy}
								openJustificativeModal={openJustificativeModal}
							/>
							{overrun && (
								<OverrunButtons
									id={row.id}
									userType={userType}
									disabled={!hasSelectedRows(row.id)}
									openTransferModal={openBudgetValueTransferModal}
									sendToOverrun={sendToOverrunProxy}
									openJustificativeModal={openJustificativeModal}
								/>
							)}
						</AccordionDetails>
					</Accordion>
				);
			})
		);
	}, [
		rows,
		getOnGoingValue,
		userType,
		expandPanels,
		handleAccordionChange,
		accountingAccountTooltip,
		currentColumns,
		selectAllRows,
		onSelect,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
		hasSelectedRows,
		rejectProxy,
		approveProxy,
		openBudgetValueTransferModal,
		sendToOverrunProxy,
		openJustificativeModal,
	]);

	const actionJustificativeModal = useMemo(() => (
		<ActionJustificativeModal
			id={modalProps.id}
			title={modalProps.title}
			action={modalProps.action}
			open={modalProps.open}
			onClose={closeJustificativeModal}
		/>
	), [modalProps, closeJustificativeModal]);

	const footer = useMemo(() => {
		const purchases: any[] = [];

		if (!rows || (rows && rows.length === 0)) {
			return null;
		}

		rows.forEach((row) => {
			row.purchaseOrderApprovals.forEach((purchase: any) => {
				const purchaseIndex = purchases.findIndex(
					(provider) => provider.id === purchase.providerCode,
				);

				if (purchaseIndex > -1) {
					purchases[purchaseIndex].name = purchase.providerName;
					purchases[purchaseIndex].total = purchases[purchaseIndex].total.plus(purchase.totalValue);
				} else {
					purchases.push({
						id: purchase.providerCode,
						name: purchase.providerName,
						total: new Decimal(purchase.totalValue),
					});
				}
			});
		});

		const total = purchases.reduce(
			(currentTotal: Decimal, provider: any) => currentTotal.plus(provider.total),
			new Decimal(0),
		);

		return (
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="footer-content"
					id="footer-header"
				>
					<Typography sx={{ width: '33%', flexShrink: 0 }}>
						Total por Fornecedor
					</Typography>
					<Typography sx={{ color: 'text.secondary' }}>
						Total:
						{' '}
						{currencyBRLMask(total)}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{purchases.map((purchase) => (
						<Box key={purchase.id}>
							<Typography>
								{`${purchase.name}: ${currencyBRLMask(purchase.total)}`}
							</Typography>
						</Box>
					))}
				</AccordionDetails>
			</Accordion>
		);
	}, [rows]);

	return (
		<>
			{content}
			{footer}
			{actionJustificativeModal}
		</>
	);
};

PurchaseOrderApprovalTable.defaultProps = {
	selectAllRows: false,
};

export default PurchaseOrderApprovalTable;
