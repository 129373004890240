import { DeliveryType } from '../../enums/DeliveryType';
import { ItemType } from '../../enums/ItemType';
import { PaymentMethod } from '../../enums/PaymentMethod';
import { Status } from '../../enums/SaleOrderStatusType';
import { getFirstDayOfCurrentMonth } from '../../helpers/Utils';
import Yup from '../../helpers/yupMethods';
import { SaleOrderQueryParams } from '../../interfaces/SaleOrderQueryParams';
import { IBusinessPartner } from '../BusinessPartner/BusinessPartnerAssets';

export interface IPaymentMethod {
  description: string;
  id: PaymentMethod;
}

export interface IRangePriceCommission {
  productId: string;
  price: number;
  commissionValue: number;
  commission: number;
}

export interface IRangePriceCommissions {
  data: IRangePriceCommission[];
  count: number;
}

export interface ISaleOrderItem {
  id: string;
  price: number;
  quantity: number;
  total: number;
  discount: number;
  totalDiscount: number;
  commission: number;
  deliveryDate: Date | string;
  description: string;
  clientOrder: string;
  type: ItemType;
  productId: string;
  imageB64?: string;
}

export interface ISaleOrderItemExtended extends ISaleOrderItem {
  originalPrice: number;
	commissionValue: number;
  totalWithoutDiscount: number;
  totalWithDiscount: number;
  edit?: boolean;
}

export interface BusinessPartnersLabels {
    id: string;
    marketName: string;
    salesman: string;
}

interface Salesman {
  name: string;
}

export interface ISaleOrder {
  deliveryAddress: string;
  discount: number;
  deliveryType: DeliveryType;
  volumeQuantity: number;
  grossWeight: number;
  netWeight: number;
  totalOrder: number;
  paymentTermId: string;
  paymentMethod: PaymentMethod;
  saleOrderItems: ISaleOrderItemExtended[];
  status: Status;
  id?: string;
  code?: number;
  description?: string;
  clientId?: string;
  transporterId?: string;
  salesmans?: Salesman[];
  salesman?: string;
  clientName?: string;
  actionJustificative?: string;
  client?: IBusinessPartner;
  transporter?: IBusinessPartner;
  transporterDetails?: IBusinessPartner;
}

export interface ISaleOrderExtended extends ISaleOrder {
  saleOrderItems: ISaleOrderItemExtended[];
  deliveryDate?: Date | string;
  paymentTerm?: IPaymentTerm;
  deliveryPlaceId?: string;
}

interface ExtraData {
  draftCount: number;
  canceledCount: number;
  budgetCount: number;
  saleOrderCount: number;
}

export interface ISaleOrderResponse {
	data: ISaleOrder[];
  count: number;
  message: string;
  extraData: ExtraData;
}

export const initialValuesFilter: SaleOrderQueryParams = {
	skip: 0,
	dateFrom: getFirstDayOfCurrentMonth(),
	dateTo: new Date(),
	canceledStatus: false,
};

export const initialValuesFormik: ISaleOrder = {
	clientId: '',
	saleOrderItems: [],
	deliveryAddress: '',
	discount: 0,
	deliveryType: DeliveryType.CIF,
	volumeQuantity: 0,
	grossWeight: 0,
	netWeight: 0,
	totalOrder: 0,
	transporterId: '',
	paymentTermId: '',
	paymentMethod: PaymentMethod.TRANSFER,
	status: Status.SALE_ORDER,
};

export interface IPaymentTerm {
  id: string;
  code: string;
  description: string;
  averageTerm: number;
  interestRate: number;
}

export interface IPaymentTerms {
  data: IPaymentTerm[];
  count: number;
}

export interface ISalesman {
	id: string;
	name: string;
	email: string;
}

const consumerSchema: Yup.AnySchema = Yup.object().shape({
	clientId: Yup.string().required('Por favor, selecione um cliente'),
});

const productsSchema = Yup.object().shape({
	saleOrderItems: Yup.array().min(1, 'É necessário adicionar pelo menos um produto')
		.required('É necessário adicionar pelo menos um produto'),
});

const paymentSchema: Yup.AnySchema = Yup.object().shape({
	paymentTermId: Yup.string().required('Por favor, selecione uma condição de pagamento'),
	paymentMethod: Yup.string().required('Por favor, selecione uma forma de pagamento'),
});

const deliverySchema: Yup.AnySchema = Yup.object().shape({
	transporterId: Yup.string().required('Por favor, selecione uma transportadora'),
	deliveryPlaceId: Yup.string().required('Por favor, selecione um local de entrega'),
});

export const validationSchemas = [
	consumerSchema,
	productsSchema,
	paymentSchema,
	deliverySchema,
];
