import React from 'react';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import RestoreIcon from '@mui/icons-material/Restore';

const fileNameDate = `Rodalog_${new Date().toLocaleDateString().replace(/\//g, '-')}_${new Date().getHours()}-${new Date().getMinutes()}`;

const csvOptions = {
	fileName: fileNameDate,
	delimiter: ';',
	utf8WithBom: true,
};

interface GridToolBarProps {
	onRestoreClick: () => void;
}

const GridToolBar = ({ onRestoreClick }: GridToolBarProps): JSX.Element => (
	<GridToolbarContainer>
		<GridToolbarColumnsButton />
		<GridToolbarExport csvOptions={csvOptions} />
		<Button
			onClick={() => {
				onRestoreClick();
			}}
			startIcon={<RestoreIcon />}
			sx={{ fontSize: '0.835rem' }}
		>
			Restaurar
		</Button>
	</GridToolbarContainer>
);

export const ToolbarComponent = (onRestoreClick: () => void) => () => (
	<GridToolBar onRestoreClick={onRestoreClick} />
);

export default GridToolBar;
