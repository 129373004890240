import { AxiosResponse } from 'axios';
import api from './api';

interface IProviders {
	count: number;
	data: any[];
}

export const getProviders = (): Promise<AxiosResponse<IProviders>> => api.get<IProviders>('/provider');

export const getProviderById = (id: string): Promise<AxiosResponse<any>> => api.get<any[]>(`provider/${id}`);

export const saveProvider = (data: any): Promise<AxiosResponse<any>> => api.post<any, AxiosResponse<any>>('/provider', data);

export const updateProvider = (id: string, data: any): Promise<AxiosResponse<any>> => api.post<any, AxiosResponse<any>>(`/provider/${id}`, data);

export const approveProvider = (
	id: string,
): Promise<AxiosResponse<any>> => api.patch<{ id: string }, AxiosResponse<any>>(
	`/provider/approve/${id}`,
);
