import React from 'react';
import Box from '@mui/material/Box';
import { CssBaseline, Stack, SxProps } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import NavbarMobile from '../NavbarMobile';
import BreadcrumbMobile from '../BreadcrumbMobile';

const sxContent: SxProps<Theme> = {
	width: '100%',
	height: '100%',
	overflowX: 'hidden',
	p: '1rem',
	mt: '64px',
};

const MobileLayout = (): JSX.Element => (
	<Box sx={{ display: 'flex', height: '100%' }}>
		<CssBaseline />
		<NavbarMobile />
		<Stack sx={sxContent}>
			<BreadcrumbMobile />
			<Outlet />
		</Stack>
	</Box>
);

export default MobileLayout;
