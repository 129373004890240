import React, {
	useCallback,
	useEffect,
	useRef,
} from 'react';
import Box from '@mui/material/Box';
import {
	Form,
	Formik,
} from 'formik';
import { isValid } from 'date-fns';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Loading } from '../Common/Loading';
import { DatePicker } from '../Common/Form/DatePicker';
import Autocomplete from '../Common/Form/Autocomplete';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import NoDataPage from '../Common/NoDataPage';
import {
	ClockHoursParams,
	ERPClockHoursMap,
	UserClock,
	validationSchema,
} from '../../containers/HourProject/ClockHoursAssets';
import ERPClockHoursTable from './ERPClockHoursTable';

interface ClockHoursProps {
	loading: boolean;
	users: UserClock[];
	erpClockInOuts: ERPClockHoursMap;
	erpClockInOutsCount: number;
	getUsers(queryParams: ClockHoursParams): void;
	getERPClockHours(queryParams: ClockHoursParams): void;
	clearState(): void;
}

const actualDate = new Date();
const initialValues: ClockHoursParams = {
	dateFrom: actualDate,
	dateTo: actualDate,
	userId: null,
};

const ClockHours = (props: ClockHoursProps): JSX.Element => {
	const {
		loading,
		users,
		erpClockInOuts,
		erpClockInOutsCount,
		getUsers,
		getERPClockHours,
		clearState,
	} = props;
	const dateFrom = useRef(initialValues.dateFrom);
	const dateTo = useRef(initialValues.dateTo);

	useEffect(() => {
		getUsers({ dateFrom: initialValues.dateFrom, dateTo: initialValues.dateTo });
	}, [getUsers]);

	const getUsersWithDate = useCallback((_dateFrom, _dateTo) => {
		if (
			isValid(new Date(_dateFrom)) && isValid(new Date(_dateTo))
			&& new Date(_dateFrom).getFullYear().toString().length === 4
			&& new Date(_dateTo).getFullYear().toString().length === 4
			&& (_dateFrom !== dateFrom.current || _dateTo !== dateTo.current)
		) {
			getUsers({ dateFrom: _dateFrom, dateTo: _dateTo });
			dateFrom.current = _dateFrom;
			dateTo.current = _dateTo;
		}
	}, [getUsers]);

	const onSubmit = useCallback((values: ClockHoursParams) => {
		getERPClockHours({
			dateFrom: values.dateFrom,
			dateTo: values.dateTo,
			userId: values.userId,
		});
	}, [getERPClockHours]);

	const onReset = useCallback((resetForm) => {
		resetForm({ values: initialValues });
		clearState();
		getUsers({ dateFrom: initialValues.dateFrom, dateTo: initialValues.dateTo });
	}, [clearState, getUsers]);

	return (
		<Box>
			<PageHeader
				title="Consulta"
			/>
			<Paper sx={{ p: 2, width: '100%' }}>
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmit}
					validationSchema={validationSchema}
				>
					{({ values, handleReset }) => {
						getUsersWithDate(values.dateFrom, values.dateTo);
						return (
							<Form noValidate>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<Grid container columnSpacing={3} rowSpacing={1}>
											<Grid item xs={12}>
												<Typography variant="body1">Data</Typography>
											</Grid>
											<Grid item xs={6}>
												<DatePicker name="dateFrom" label="De" required />
											</Grid>
											<Grid item xs={6}>
												<DatePicker name="dateTo" label="Até" required />
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6} sx={{ alignSelf: 'flex-end' }}>
										<Autocomplete<{ name: string; id: string, login: string; }>
											limitTags={1}
											label="Usuário"
											name="userId"
											options={users || []}
											labelKey="name"
											valueKey="id"
											valueLabel="login"
											required
										/>
									</Grid>
									<Grid container spacing={3} mt={1}>
										<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
											<Button variant="outlined" onClick={() => onReset(handleReset)}>RESTAURAR</Button>
											<Button variant="contained" type="submit" disabled={!values.userId}>FILTRAR</Button>
										</Grid>
									</Grid>
								</Grid>
							</Form>
						);
					}}
				</Formik>
			</Paper>
			{loading && <Loading />}
			{erpClockInOuts.size > 0 ? (
				<Paper sx={{ p: 2, width: '100%', mt: 3 }}>
					<ERPClockHoursTable
						erpClockInOuts={erpClockInOuts}
						erpClockInOutsCount={erpClockInOutsCount}
					/>
				</Paper>
			) : <NoDataPage />}
		</Box>
	);
};

export default ClockHours;
