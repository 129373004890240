/* eslint-disable no-param-reassign */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Box,
	Button,
	Grid,
	Paper,
	Typography,
	IconButton,
	Stack,
} from '@mui/material';
import {
	TreeItem,
	TreeView,
} from '@mui/lab';
import {
	Add,
	ChevronRight,
	Edit,
	ExpandMore,
	Delete,
	AddCircle,
} from '@mui/icons-material';
import { Form, useFormik, FormikContext } from 'formik';
import {
	ILocation,
	ILocationInfo,
	ILocationRequest,
	ILocationType,
	initialValuesDefault,
	OperationType,
} from '../../containers/Location/LocationAssets';
import Autocomplete from '../Common/Form/Autocomplete';
import Input from '../Common/Form/Input';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import LocationModal from './Modals/LocationModal';
import { formatBarCode } from '../../helpers/masks';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { BranchParams } from '../../containers/Inventory/InventoryAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { Loading } from '../Common/Loading';
import NoDataPage from '../Common/NoDataPage';

interface LocationEditProps {
  loading: boolean;
  locationsMain: ILocation[];
  getLocationTypes: () => void;
  getLocationsMain: () => void;
  getAllNodeIds: (locationsMain: ILocation[]) => void;
  allNodeIds: string[];
  locationTypes: ILocationType[];
  saveLocation: (data: ILocationRequest) => void;
  updateLocation: (id: string, data: Partial<ILocationRequest>) => void;
  deleteLocation: (id: string) => void;
  companies: ICompanyWithoutDetails[];
  getCompanies: () => void;
  getBranches: (params: BranchParams) => void;
  branches: IBranch[];
}

const initialValues = {
	name: '',
	locationTypeId: '',
	locationId: '',
	barCode: '',
	barCodeInitial: '',
	barCodeNumber: '',
	childName: '',
};

const LocationEdit = ({
	loading,
	locationsMain,
	getLocationsMain,
	deleteLocation,
	updateLocation,
	saveLocation,
	locationTypes,
	companies,
	getCompanies,
	getLocationTypes,
	getBranches,
	branches,
	allNodeIds,
	getAllNodeIds,
}: LocationEditProps): JSX.Element => {
	const [selectedLocation, setSeletedLocation] = useState<ILocation | null>(
		null,
	);
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();
	const [openModal, setOpenModal] = useState(false);
	const [operationType, setOperationType] = useState<OperationType | null>(
		null,
	);

	const [isEditing, setIsEditing] = useState(false);

	const handlerModalLocation = (
		open: boolean,
		operation: OperationType | null = null,
	): void => {
		setOpenModal(open);
		setOperationType(operation);
	};

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			const barCode = values.barCodeInitial.concat(values.barCodeNumber) || '';
			saveLocation({
				locationId: values.locationId,
				locationTypeId: values.locationTypeId,
				name: values.name,
				barCode,
			});

			formik.resetForm();
		},
	});

	useEffect(() => {
		getLocationsMain();
		getLocationTypes();
	}, [getLocationTypes, getLocationsMain]);

	useEffect(() => {
		getAllNodeIds(locationsMain);
	}, [getAllNodeIds, locationsMain]);

	const handleDelete = useCallback((id: string) => {
		deleteLocation(id);
		setSeletedLocation(null);
		formik.resetForm();
	}, [deleteLocation, formik]);

	const renderTreeItems = useCallback(
		(locations: Array<ILocation>) => {
			const treeItems = locations.map((locationTree: ILocation) => {
				locationTree.barCodeNumber = locationTree.barCode.slice(-2);
				locationTree.barCodeInitial = locationTree.barCode.slice(0, -2);

				return (
					<TreeItem
						key={locationTree.id}
						nodeId={locationTree.id}
						label={locationTree.name}
						onClick={() => {
							formik.setFieldValue('locationId', locationTree.id);
							setSeletedLocation(locationTree);
							formik.setFieldValue('childName', locationTree.name);
						}}
					>
						{locationTree.locations && locationTree.locations.length > 0
							? renderTreeItems(locationTree.locations)
							: null}
					</TreeItem>
				);
			});

			return treeItems;
		},
		[formik, setSeletedLocation],
	);

	const filteredLocationTypes = useMemo(() => {
		if (!selectedLocation || !selectedLocation.locationType) {
			return locationTypes;
		}
		const selectedLevel = selectedLocation.locationType.level;
		return locationTypes.filter((type) => Number(type.level) > selectedLevel);
	}, [selectedLocation, locationTypes]);

	const filteredLocationInitial = useMemo(
		() => locationTypes.filter((type) => Number(type.level) === 0),
		[locationTypes],
	);

	const onSubmitDataLocationModal = useCallback(
		(values: ILocationInfo): void => {
			const data: Partial<ILocationRequest> = {
				barCode: values.barCodeInitial.concat(values.barCodeNumber),
				name: values.name,
				locationTypeId: values.locationTypeId || '',
				companyId: values.companyId || '',
				branchId: values.branchId || '',
			};

			handlerModalLocation(false);
			setSeletedLocation(null);
			saveLocation(data as ILocationRequest);
		},
		[saveLocation],
	);

	const locationNameMemo = useMemo(() => (
		<Box>
			{isEditing ? (
				<Input.InputField
					id="childName"
					name="childName"
					label="Nome da Localização"
					onBlur={() => {
						updateLocation(formik.values.locationId, {
							name: formik.values.childName,
						});
						setIsEditing(false);
					}}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							updateLocation(formik.values.locationId, {
								name: formik.values.childName,
							});
							setIsEditing(false);
						}
					}}
					autoComplete="off"
					autoFocus
					fullWidth
					required
				/>
			) : (
				<Typography
					variant="h5"
					color="primary"
					style={{ cursor: 'pointer' }}
				>
					{formik.values.childName || selectedLocation?.name}
				</Typography>
			)}

		</Box>
	), [
		formik.values.childName,
		formik.values.locationId,
		isEditing,
		selectedLocation?.name,
		updateLocation,
	]);

	const locationModalMemo = useMemo(
		() => (
			<LocationModal
				open={openModal}
				initialValues={
					operationType === OperationType.EDITING
						? (selectedLocation as any)
						: initialValuesDefault
				}
				onClose={handlerModalLocation}
				operationType={operationType as OperationType}
				loading={loading}
				locationTypes={
					operationType === OperationType.EDITING
						? locationTypes
						: filteredLocationInitial
				}
				onSubmit={onSubmitDataLocationModal}
				companies={companies}
				getCompanies={getCompanies}
				branches={branches}
				getBranches={getBranches}
			/>
		),
		[
			openModal,
			operationType,
			selectedLocation,
			loading,
			locationTypes,
			filteredLocationInitial,
			onSubmitDataLocationModal,
			companies,
			getCompanies,
			branches,
			getBranches,
		],
	);

	const buildValueBarCodePath = useCallback(
		(locationTypeId: string | null): string => {
			let barCodeValue = '';

			if (selectedLocation) {
				barCodeValue = selectedLocation.barCode;
			}

			const locationTypeValue = locationTypes.find(
				(locationType) => locationType.id === locationTypeId,
			);

			if (locationTypeValue) {
				barCodeValue = barCodeValue.concat(locationTypeValue.prefix);
			}

			return barCodeValue;
		},
		[locationTypes, selectedLocation],
	);

	const treeViewComponent = useMemo(() => {
		if (allNodeIds.length === 0) {
			return null;
		}

		return (
			<TreeView
				defaultCollapseIcon={<ExpandMore />}
				defaultExpandIcon={<ChevronRight />}
				defaultExpanded={allNodeIds}
			>
				{renderTreeItems(locationsMain)}
			</TreeView>
		);
	}, [allNodeIds, locationsMain, renderTreeItems]);

	return (
		<>
			{locationModalMemo}
			{loading
				? (<Loading />)
				: (
					<FormikContext.Provider value={formik}>
						<Form noValidate>
							<Box>
								<Typography mb={4} color="primary" variant="h4" gutterBottom>
									Gerenciar Localizações
								</Typography>
								<Box
									display="flex"
									p={2}
									minHeight="70vh"
									className="content"
									component={Paper}
								>
									<Box width="300px" pr={2} borderRight="1px solid #ddd">
										<Box
											display="flex"
											alignItems="center"
											alignContent="center"
											justifyContent="space-between"
										>
											<Box>
												<Typography variant="h6">
													Estrutura de Localização
												</Typography>
											</Box>
											<Box>
												<IconButton
													color="primary"
													onClick={() => handlerModalLocation(true, OperationType.NEW)}
												>
													<AddCircle sx={{ fontSize: 30 }} color="primary" />
												</IconButton>
											</Box>
										</Box>

										{treeViewComponent}
									</Box>

									<Box flex={1} pl={2}>
										{selectedLocation ? (
											<Box>
												<Stack direction="row" mb={2} alignItems="center" spacing={1}>
													{locationNameMemo}

													<IconButton
														color="primary"
														aria-label="edit"
														onClick={() => setIsEditing(true)}
													>
														<Edit />
													</IconButton>
													<IconButton
														color="error"
														aria-label="delete"
														onClick={() => requestConfirm({
															description:
				'Tem certeza que deseja remover a localização selecionada?',
															callback: () => handleDelete(selectedLocation.id),
														})}
													>
														<Delete />
													</IconButton>
												</Stack>
												<Grid container spacing={2} alignItems="center">
													<Grid item xs={12} md={6}>
														<Autocomplete
															label="Tipo de Localização"
															valueLabel="level"
															valueKey="id"
															labelKey="name"
															name="locationTypeId"
															options={filteredLocationTypes || []}
															fullWidth
															onChange={(event, newValue) => {
																formik.setFieldValue(
																	'locationTypeId',
																	newValue || '',
																);
																const barCodeInitial = buildValueBarCodePath(newValue);
																formik.setFieldValue(
																	'barCodeInitial',
																	barCodeInitial,
																);
															}}
															required
														/>
													</Grid>

													<Grid item xs={12} md={6}>
														<Input.InputField
															id="name"
															name="name"
															label="Nome da Localização"
															autoComplete="off"
															fullWidth
															required
														/>
													</Grid>

													<Grid item xs={12} md={3}>
														<Typography variant="subtitle1" color="#00000099">
															Código de Barras
														</Typography>

														<Typography
															variant="subtitle1"
															sx={{
																overflowY: 'auto',
															}}
														>
															{formatBarCode(formik.values.barCodeInitial || '') || '-'}
														</Typography>
													</Grid>
													<Grid item xs={12} md={3}>
														<Input.InputField
															label="Composição do Código de Barras"
															name="barCodeNumber"
															fullWidth
															required
														/>
													</Grid>

													<Grid item xs={12}>
														<Box mt={2} display="flex" gap={1}>
															<Button
																variant="contained"
																color="primary"
																startIcon={<Add />}
																sx={{ textTransform: 'none' }}
																type="submit"
																disabled={!selectedLocation}
															>
																Adicionar
															</Button>
														</Box>
													</Grid>
												</Grid>
											</Box>
										) : (
											<Box mt={4}>
												<NoDataPage
													title="Nenhuma Localização Selecionada"
													description="Selecione uma localização para adicionar um nível inferior."
												/>
											</Box>
										)}
									</Box>
								</Box>
							</Box>
						</Form>
					</FormikContext.Provider>
				)}

			{confirmationDialog}
		</>
	);
};

export default LocationEdit;
