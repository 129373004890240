export const purchaseOrderApprovalStatus = [
	'Ativo',
	'Rejeitado',
	'Aprovado',
	'Ag. aprovação',
	'Ag. aprovação',
];

export const purchaseOrderApprovalStatusDetailed = [
	'Ativo',
	'Rejeitado',
	'Aprovado',
	'Ag. aprovação nível corporativo',
	'Ag. aprovação controladoria',
];

export const purchaseOrderApprovalStatusFilter = [
	{ id: 'active', label: 'Ativo' },
	{ id: 'rejected', label: 'Rejeitado' },
	{ id: 'approved', label: 'Aprovado' },
	{ id: 'waitingapprovalcl', label: 'Ag. aprovação nível corporativo' },
	{ id: 'waitingapprovalc', label: 'Ag. aprovação controladoria' },
];
