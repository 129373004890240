import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
	IRangePriceCommission, ISaleOrder, ISaleOrderExtended, ISaleOrderItemExtended,
} from '../../containers/SaleOrder/SaleOrderAssets';
import SaleOrderForm from './SaleOrderForm';
import { ISaleOrderActions, IState } from '../../containers/SaleOrder/SaleOrderEdit';
import { filterObject } from '../../helpers/Utils';
import { Status } from '../../enums/SaleOrderStatusType';
import { calculateTotalDiscount } from '../../helpers/CalcTotals';

interface EditSaleOrderProps extends IState, ISaleOrderActions {
	loading: boolean;
	saleOrder?: ISaleOrder;
	rangePriceComissionsByValue?: IRangePriceCommission;
}

const SaleOrderEdit = ({
	loading,
	saleOrder,
	paymentTerms,
	productsBusinessPartner,
	transporters,
	clients,
	topSellingProducts,
	rangePriceCommissions,
	rangePriceComissionsByValue,
	getSaleOrderById,
	getClients,
	getProductBusinessPartnerDetails,
	getRangePriceCommissions,
	getPaymentTerms,
	getTransporters,
	getTopSellingProducts,
	getRangePriceComissionByValue,
	handleEditSaleOrder,
}: EditSaleOrderProps): JSX.Element => {
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getSaleOrderById(id);
		}
		getClients();
		getPaymentTerms();
	}, []);

	const filterSaleOrderItems = (
		items: ISaleOrderItemExtended[],
	): ISaleOrderItemExtended[] => items.map((item) => {
		const omitItems: any[] = [
			'originalPrice', 'commissionValue', 'edit',
			'totalWithoutDiscount', 'totalWithDiscount', 'imageB64',
			'saleOrderItemAddresses', 'saleOrderId',
		];

		const price = item.originalPrice !== undefined ? item.originalPrice : item.price;

		const totalDiscount = (price - item.total) > 0 ? price - item.total : 0;

		const filteredItem = filterObject(item, omitItems) as ISaleOrderItemExtended;

		if (!filteredItem.clientOrder) {
			filteredItem.clientOrder = 'ORD-PADRAO';
		}

		filteredItem.quantity = Number(item.quantity);
		filteredItem.price = Number(price);
		filteredItem.total = Number(item.total);
		filteredItem.discount = Number(item.discount);
		filteredItem.totalDiscount = Number(totalDiscount);
		filteredItem.commission = Number(item.commission);

		return filteredItem;
	});

	const handleSubmit = useCallback((items: ISaleOrderExtended, action: 'Budget' | 'SaleOrder') => {
		const status = action === 'Budget' ? Status.BUDGET : Status.SALE_ORDER;
		const omitMainItems: any[] = [
			'client', 'transporterDetails', 'salesmans', 'code', 'createdAt', 'updatedAt',
			'paymentMethodId', 'deliveryPlaceId', 'deliveryDate', 'transporter', 'salesman',
			'paymentTerm',
		];
		const totalDiscountHeader = calculateTotalDiscount(items.saleOrderItems);

		const paymentTermId = items?.paymentTerm?.id ?? items.paymentTermId;

		const filteredMainValues = filterObject(items, omitMainItems);

		const filteredSaleOrderItems = filterSaleOrderItems(items.saleOrderItems);

		const finalValues: ISaleOrder = {
			...filteredMainValues,
			deliveryAddress: items.deliveryAddress || 'Endereço padrão',
			discount: Number(totalDiscountHeader) || 0,
			deliveryType: Number(filteredMainValues.deliveryType) || 0,
			volumeQuantity: Number(filteredMainValues.volumeQuantity) || 0,
			grossWeight: Number(filteredMainValues.grossWeight) || 0,
			netWeight: Number(filteredMainValues.netWeight) || 0,
			totalOrder: Number(items.totalOrder) || 0,
			paymentTermId: paymentTermId || '',
			paymentMethod: Number(filteredMainValues.paymentMethod) || 0,
			clientId: filteredMainValues.clientId || '',
			transporterId: filteredMainValues.transporterId || '',
			saleOrderItems: filteredSaleOrderItems,
			status,
		};

		handleEditSaleOrder({ id: saleOrder?.id, data: finalValues });
	}, [handleEditSaleOrder, saleOrder?.id]);

	return (
		<SaleOrderForm
			loading={loading}
			saleOrder={saleOrder}
			clients={clients}
			transporters={transporters}
			productsBusinessPartner={productsBusinessPartner}
			paymentTerms={paymentTerms}
			getProductBusinessPartnerDetails={getProductBusinessPartnerDetails}
			getTopSellingProducts={getTopSellingProducts}
			topSellingProducts={topSellingProducts}
			handleSubmit={handleSubmit}
			getRangePriceCommissions={getRangePriceCommissions}
			getRangePriceComissionByValue={getRangePriceComissionByValue}
			getTransporters={getTransporters}
			rangePriceCommissions={rangePriceCommissions}
			rangePriceComissionsByValue={rangePriceComissionsByValue}
		/>
	);
};

SaleOrderEdit.defaultProps = {
	saleOrder: undefined,
	rangePriceComissionsByValue: undefined,
};

export default SaleOrderEdit;
