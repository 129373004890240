import React, { useEffect } from 'react';
import {
	Paper,
	Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Form,
	useFormik,
	FormikContext,
} from 'formik';
import {
	addUserValidationSchema,
	editUserValidationSchema,
	initialValuesAddUser,
	AddUser,
	EditUser,
	User,
} from '../../containers/User/UserAssets';
import { AddUserForm } from './Forms/AddUserForm';
import { EditUserForm } from './Forms/EditUserForm';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { BranchParams } from '../../containers/Inventory/InventoryAssets';
import { normalizeUserBranchesPost } from '../../helpers/normalizeUserBranches';

interface UserFormProps {
	loading: boolean;
	user?: User;
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	handleEditUser: (values: {
		id?: string;
		data: AddUser | EditUser;
	}) => void;
	getBranches: (params: BranchParams) => void;
}

const UserForm = ({
	loading,
	user,
	companies,
	branches,
	handleEditUser,
	getBranches,
}: UserFormProps): JSX.Element => {
	const initialValues = user ? {
		name: user.name,
		email: user.email,
		phone: user.phone,
		cpf: user.cpf,
		registry: user.registry,
		registerHour: user.registerHour,
		canWorkHoliday: user.canWorkHoliday,
		daysBackAppointment: user.daysBackAppointment,
		companyBranches: user.companyBranches || [],
		companyIds: user.companyIds || [],
		preferredCompanyId: user.preferredCompanyId,
		preferredBranchId: user.preferredBranchId,
		preferredBranch: user.preferredBranch,
		branchPreference: user.branchPreference,
	} : initialValuesAddUser;

	const formik = useFormik({
		initialValues,
		validationSchema: user ? editUserValidationSchema : addUserValidationSchema,
		onSubmit: (values) => {
			const data = normalizeUserBranchesPost(values) as AddUser;
			handleEditUser({
				id: user?.id,
				data,
			});
		},
		validateOnChange: false,
		validateOnBlur: false,
	});

	useEffect(() => {
		if (formik.values.companyIds?.length && formik.values.companyIds?.length > 0) {
			getBranches({ companyId: formik.values.companyIds[formik.values.companyIds?.length] });
		}
	}, [formik.values.companyIds, getBranches]);

	return (
		<FormikContext.Provider value={formik}>
			<Form noValidate>
				<Paper sx={{ mt: 3, p: 3 }} elevation={3}>
					<Stack spacing="4rem">
						{
							user
								? (
									<EditUserForm
										companies={companies}
										branches={branches}
										getBranches={getBranches}
									/>
								)
								: (
									<AddUserForm
										companies={companies}
										branches={branches}
										getBranches={getBranches}
									/>
								)
						}
						<LoadingButton
							loading={loading}
							variant="contained"
							color="primary"
							type="submit"
							sx={{ height: 'fit-content', width: 'fit-content' }}
						>
							Salvar
						</LoadingButton>
					</Stack>
				</Paper>
			</Form>
		</FormikContext.Provider>
	);
};

UserForm.defaultProps = {
	user: undefined,
};

export default UserForm;
