import React, {
	createContext, useState, useCallback, useMemo,
} from 'react';

export const BranchContext = createContext({
	refreshTriggerBranchId: '',
	updateBranchId: (newBranchId: string) => newBranchId,
});

export const BranchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [refreshTriggerBranchId, setRefreshTriggerBranchId] = useState('');

	const updateBranchId = useCallback((newBranchId: string) => {
		setRefreshTriggerBranchId(newBranchId);
		return newBranchId;
	}, []);

	const value = useMemo(() => ({
		refreshTriggerBranchId,
		updateBranchId,
	}), [refreshTriggerBranchId, updateBranchId]);

	return (
		<BranchContext.Provider value={value}>
			{children}
		</BranchContext.Provider>
	);
};
