import React, { useCallback, useEffect, useMemo } from 'react';
import {
	Form,
	Formik,
} from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { DatePicker } from '../../Common/Form/DatePicker';
import Input from '../../Common/Form/Input';
import SwitchField from '../../Common/Form/Switch';
import Autocomplete from '../../Common/Form/Autocomplete';
import { filterObject, getFirstDayOfCurrentMonth, getStoredFilterWithDates } from '../../../helpers/Utils';
import { IBranch, IForm, validationSchema } from '../../../containers/Order/PurchaseOrderAssets';
import { removeMaskBarCode } from '../../../helpers/masks';

interface IPurchaseOrderFilterProps {
	branches: IBranch[] | null;
	sendFilter: (values: IForm) => void
}

interface IPurchaseOrderFilterInternalProps extends IPurchaseOrderFilterProps {
	initialFilter: any;
}

const initialValues: IForm = {
	nrOrder: '',
	invoiceNumber: '',
	provider: '',
	apportioned: false,
	hasInvoices: false,
	branches: [],
	invoiceIssuanceFrom: getFirstDayOfCurrentMonth(),
	invoiceIssuanceTo: new Date(),
	orderIssuanceFrom: new Date(),
	orderIssuanceTo: new Date(),
};

const PurchaseOrderFilterInternal = ({
	branches,
	initialFilter,
	sendFilter,
}: IPurchaseOrderFilterInternalProps): JSX.Element => {
	useEffect(() => {
		sendFilter(filterObject(initialFilter) as IForm);
	}, [initialFilter, sendFilter]);

	const onSubmit = useCallback((values: IForm) => {
		const filteredValues = filterObject({
			...values,
			provider: removeMaskBarCode(values.provider),
		}) as IForm;
		sendFilter(filteredValues);
		localStorage.setItem('purchaseOrderFilter', JSON.stringify(filteredValues));
	}, [sendFilter]);

	const onReset = useCallback((resetForm) => {
		sendFilter({} as IForm);
		resetForm({ values: initialValues });
		localStorage.removeItem('purchaseOrderFilter');
	}, [sendFilter]);

	return (
		<Paper sx={{ p: 2, width: '100%' }}>
			<Formik
				initialValues={({ ...initialValues, ...initialFilter })}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
			>
				{({ resetForm, setFieldValue, isValid }) => (
					<Form noValidate>
						<Typography variant="body2" color="textSecondary" mb={2}>
							*Obrigatório
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<Grid container columnSpacing={3} rowSpacing={1}>
									<Grid item xs={12}>
										<Typography variant="body1">Emissão do Pedido</Typography>
									</Grid>
									<Grid item xs={6}>
										<DatePicker name="orderIssuanceFrom" label="De" required />
									</Grid>
									<Grid item xs={6}>
										<DatePicker name="orderIssuanceTo" label="Até" required />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Grid container columnSpacing={3} rowSpacing={1}>
									<Grid item xs={12}>
										<Typography variant="body1">Emissão da NF</Typography>
									</Grid>
									<Grid item xs={6}>
										<DatePicker name="invoiceIssuanceFrom" label="De" required />
									</Grid>
									<Grid item xs={6}>
										<DatePicker name="invoiceIssuanceTo" label="Até" required />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={3}>
								<Autocomplete<{ name: string; id: string, code: string; }>
									multiple
									limitTags={2}
									label="Filial"
									name="branches"
									options={branches || []}
									labelKey="name"
									valueKey="id"
									valueLabel="code"
									required
								/>
							</Grid>
							<Grid item xs={3}>
								<Input.InputField
									id="nrOrder"
									name="nrOrder"
									label="Nr do pedido"
								/>
							</Grid>
							<Grid item xs={3}>
								<Input.InputField
									id="invoiceNumber"
									name="invoiceNumber"
									label="Nr da nota"
								/>
							</Grid>
							<Grid item xs={3}>
								<Input.InputField
									id="provider"
									name="provider"
									label="Nome/CNPJ do Fornecedor"
								/>
							</Grid>
							<Grid item xs={3}>
								<SwitchField
									name="apportioned"
									label="Postado"
									onChange={(e) => {
										if (!e.target.checked) {
											setFieldValue('hasInvoices', false);
										}
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<SwitchField
									name="hasInvoices"
									label="Classificado"
									onChange={(e) => {
										if (e.target.checked) {
											setFieldValue('apportioned', true);
										}
									}}
								/>
							</Grid>
							<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
								<Button variant="outlined" onClick={() => onReset(resetForm)}>RESTAURAR</Button>
								<Button variant="contained" type="submit" disabled={!isValid}>FILTRAR</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};

export const PurchaseOrderFilter = ({
	branches,
	sendFilter,
}: IPurchaseOrderFilterProps): JSX.Element => {
	const initialFilter = useMemo(() => getStoredFilterWithDates<IForm>('purchaseOrderFilter', initialValues), []);

	return (
		<PurchaseOrderFilterInternal
			branches={branches}
			initialFilter={initialFilter}
			sendFilter={sendFilter}
		/>
	);
};
