import React, { useMemo } from 'react';
import { Stack, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { defaultCardSpacing } from '../../../constants/dashboard';
import { PieChartComponent as PieChart } from '../../Common/Charts/PieChart';
import Card from '../../Common/Card';

interface BudgetPieChartsProps {
    total: number;
    realized: number;
    committed: number;
    toApprove: number;
    balance: number;
}

const pieChartCard: React.CSSProperties = {
	flex: 1,
	width: '20rem',
};

export const BudgetPieCharts = ({
	total,
	realized,
	committed,
	toApprove,
	balance,
}: BudgetPieChartsProps): JSX.Element => {
	const theme = useTheme();

	const chartsData = useMemo(() => ({
		inProcessBalance: [
			{ name: 'Saldo', value: new Decimal(balance).toNumber(), fill: theme.palette.primary.main },
			{ name: 'Aprovado', value: new Decimal(committed ?? 0).toNumber(), fill: theme.palette.primary.light },
			{ name: 'A aprovar', value: new Decimal(toApprove ?? 0).toNumber(), fill: theme.palette.secondary.light },
			{ name: 'Realizado', value: new Decimal(realized ?? 0).toNumber(), fill: theme.palette.success.main },
		],
		realizedBalance: [
			{ name: 'Saldo', value: new Decimal(total).toNumber(), fill: theme.palette.primary.main },
			{ name: 'Realizado', value: new Decimal(realized ?? 0).toNumber(), fill: theme.palette.success.main },
		],
	}), [balance, committed, realized, theme.palette, toApprove, total]);

	return (
		<Stack direction="row" spacing={defaultCardSpacing} sx={{ height: '20rem', width: '100%' }}>
			<Card title="Saldo em processo" sx={pieChartCard}>
				<PieChart data={chartsData.inProcessBalance} />
			</Card>
			<Card title="Saldo realizado" sx={pieChartCard}>
				<PieChart data={chartsData.realizedBalance} />
			</Card>
		</Stack>
	);
};
