import React, { useCallback, useEffect, useRef } from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
} from '@mui/material';
import { Form, FormikContext, useFormik } from 'formik';
import Input from '../../../../../Common/Form/Input';

interface InventoryStartProps {
	confirmProduct: (productBarCode: string, readerCode?: boolean) => void
	handleBack: () => void
}

const StepProduct = ({
	confirmProduct,
	handleBack,
}: InventoryStartProps):JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const formik = useFormik({
		initialValues: {
			productBarCode: '',
		},
		onSubmit: (values) => {
			confirmProduct(values.productBarCode);
		},
	});

	const handleBlurOrEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			const readerCode = true;
			confirmProduct(
				formik.values.productBarCode,
				readerCode,
			);
		}
	}, [confirmProduct, formik.values.productBarCode]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Card>
					<CardContent>
						<Typography textAlign="center" variant="h6" gutterBottom>
							Produto
						</Typography>
						<Typography
							textAlign="center"
							variant="subtitle1"
							gutterBottom
							sx={{ marginBottom: 2 }}
						>
							Leia o código de barras do produto
						</Typography>

						<Input.InputField
							id="productBarCode"
							name="productBarCode"
							label="Código de Barras do Produto"
							format={(value) => value.toUpperCase()}
							autoComplete="off"
							inputRef={inputRef}
							onKeyDown={handleBlurOrEnter}
							fullWidth
							required
						/>
					</CardContent>
					<CardActions>
						<Button
							variant="contained"
							size="large"
							fullWidth
							type="submit"
						>
							Confirmar
						</Button>
					</CardActions>
				</Card>
				<Button variant="text" sx={{ my: 2 }} onClick={handleBack}>
					Voltar
				</Button>
			</Form>
		</FormikContext.Provider>
	);
};

export default StepProduct;
