import { IFilter } from '../interfaces/BudgetValueTransferModalInterfaces';

const isFilterValueInRowValue = (filterValue: string, rowValue: string): boolean => (
	filterValue
		? rowValue.toUpperCase().includes(filterValue.toUpperCase())
		: true
);

export const getFilteredBudgets = (
	budgets: any[],
	filters: IFilter,
): any[] => budgets.filter((budget) => {
	const isAccountingAccountFiltered = isFilterValueInRowValue(
		filters.accountingAccount,
		budget.accountingAccount.name,
	);
	const isCostCenterFiltered = isFilterValueInRowValue(filters.costCenter, budget.costCenter.name);
	const isAccountingItemFiltered = isFilterValueInRowValue(
		filters.accountingItem,
		budget.accountingItem.name,
	);
	const isClassValueFiltered = isFilterValueInRowValue(filters.classValue, budget.classValue.name);

	return (
		isAccountingAccountFiltered
        && isCostCenterFiltered
        && isAccountingItemFiltered
        && isClassValueFiltered
	);
});
