import axios, { AxiosError } from 'axios';
import { getToken, logout } from './auth';

const api = axios.create({
	baseURL: '/api/',
});

const isViaCep = (url?: string): boolean => {
	if (url) {
		return Boolean(url.match('viacep'));
	}

	return false;
};

api.interceptors.request.use((requestConfig) => {
	const token = getToken();
	const config = requestConfig;
	if (token && !isViaCep(config.url)) {
		if (config.headers) {
			config.headers.Authorization = `Bearer ${token}`;
		}
	}
	return config;
});

api.interceptors.response.use(
	(response) => response,
	(error: AxiosError) => {
		if (error.response?.status === 401) {
			logout(error.response?.data.error === 'DROP_SESSION');
		}

		return Promise.reject(error);
	},
);

export default api;
