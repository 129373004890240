import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { Icon } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GenericDrawer from '../Common/GenericDrawer';
import { AcquisitionType, ILicenseDetails, Profile } from '../../containers/License/LicenseAssets';

interface IDetailsDrawerProps {
    openDetails?: ILicenseDetails;
    onClose: () => void;
}

const DetailsDrawer = ({
	openDetails,
	onClose,
}: IDetailsDrawerProps): JSX.Element => {
	const contentMemo = useMemo(
		() => (
			<Grid container spacing={1}>
				{openDetails && (
					<>
						<Grid item xs={6}>
							<Typography variant="subtitle2">Perfil</Typography>
							<Box>{Profile[openDetails.prl]}</Box>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="subtitle2">Tipo de Aquisição</Typography>
							<Box>{AcquisitionType[openDetails.atp]}</Box>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle2">N. Licenças</Typography>
							<Box>{openDetails.nof}</Box>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle2">Licenças em uso</Typography>
							<Box>{openDetails.inUse}</Box>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle2">Licenças disponíveis</Typography>
							<Box>{openDetails.nof - openDetails.inUse}</Box>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="subtitle2">Emitido em</Typography>
							<Box>{new Date(openDetails.iat * 1000).toLocaleDateString()}</Box>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="subtitle2">Expira em</Typography>
							<Box>{new Date(openDetails.exp * 1000).toLocaleDateString()}</Box>
						</Grid>
						<Grid item xs={12} mt={2}>
							<Typography variant="subtitle2">Módulos</Typography>
							<Grid container spacing={1}>
								<Grid container item xs={12} direction="row" justifyContent="space-between">
									<Grid item xs={2}>
										<Typography variant="subtitle2">Ícone</Typography>
									</Grid>
									<Grid item xs={5}>
										<Typography variant="subtitle2">Nome</Typography>
									</Grid>
									<Grid item xs={5}>
										<Typography variant="subtitle2">Link</Typography>
									</Grid>
								</Grid>
								{openDetails.mod && openDetails.mod.map((module) => (
									<Grid container item xs={12} key={module.code} direction="row" justifyContent="space-between">
										<Grid item xs={2}>
											<Icon>{module.icon}</Icon>
										</Grid>
										<Grid item xs={5}>
											{module.name}
										</Grid>
										<Grid item xs={5}>
											{module.link}
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</>
				)}
			</Grid>
		),
		[openDetails],
	);

	return (
		<GenericDrawer
			open={Boolean(openDetails)}
			title="Detalhes da Licença"
			content={contentMemo}
			onClose={onClose}
		/>
	);
};

DetailsDrawer.defaultProps = {
	openDetails: undefined,
};

export default DetailsDrawer;
