import axios, { AxiosResponse } from 'axios';
import api from './api';
import {
	TaskClockInOut,
	UserProjectsTasksHoursParams,
	UserProjectsTasksHoursResponse,
	ERPClockInOut,
	HourProject,
	CreateTaskClockInOutResponse,
	CreateOverflowTaskData,
	CreateOverflowTaskResponse,
	UpdateTask,
} from '../containers/HourProject/ApportionmentAssets';
import { UserHourProjectInconsistenceResponse } from '../containers/HourProject/HourProjectInconsistenciesAssets';
import { UserHourProjectInconsistenceQueryParams } from '../interfaces/UserHourProjectInconsistenceQueryParams';
import { UserTasksAppointedHoursParams } from '../containers/HourProject/UserTasksAppointedHoursAssets';
import { ClockHoursParams, UserClock } from '../containers/HourProject/ClockHoursAssets';
import { AddUsersToProjectTasksParams, HourProjectQueryParams } from '../interfaces/HourProject';
import { IPGeolocationResponse } from '../interfaces/GeoLocationResponse';

const externalApi = axios.create();

export const getProjects = (queryParams?: HourProjectQueryParams): Promise<AxiosResponse> => api.get<HourProject[]>('/hour-project', {
	params: queryParams,
});

export const getUserProjectsTasksHours = (queryParams?: UserProjectsTasksHoursParams): Promise<AxiosResponse<UserProjectsTasksHoursResponse>> => api.get<UserProjectsTasksHoursResponse>('/hour-project/user-projects-tasks-hours', { params: queryParams });

export const getUserHourProjectsInconsistencies = (
	queryParams: UserHourProjectInconsistenceQueryParams,
): Promise<AxiosResponse<UserHourProjectInconsistenceResponse>> => api.get<UserHourProjectInconsistenceResponse>('/hour-project/check-users-inconsistencies', {
	params: queryParams,
});

export const getUserTasksAppointedHours = (
	queryParams:UserTasksAppointedHoursParams,
): Promise<AxiosResponse<UserProjectsTasksHoursResponse>> => api.get<UserProjectsTasksHoursResponse>('/hour-project/user-tasks-appointed-hours', { params: queryParams });

export const createTaskClockInOut = (data: TaskClockInOut): Promise<AxiosResponse<CreateTaskClockInOutResponse>> => api.post<CreateTaskClockInOutResponse>('/task-clock-in-out', data);

export const removeTaskClockInOut = (id: string): Promise<AxiosResponse<{message: string}>> => api.delete<{message: string}>(`/task-clock-in-out/${id}`);

export const removeAdminTaskClockInOut = (id: string): Promise<AxiosResponse<{message: string}>> => api.delete<{message: string}>(`/task-clock-in-out/admin/${id}`);

export const updateTaskClockInOut = (id: string, data: TaskClockInOut): Promise<AxiosResponse<{message: string, data: TaskClockInOut}>> => api.patch<{message: string, data: TaskClockInOut}>(`/task-clock-in-out/${id}`, data);

export const getUsersWithERPHours = (queryParams: ClockHoursParams): Promise<AxiosResponse<{data: UserClock[]}>> => api.get<{data: UserClock[]}>('/user/erp-hours', {
	params: {
		dateFrom: queryParams.dateFrom,
		dateTo: queryParams.dateTo,
	},
});

export const getUsersWithTaskHours = (): Promise<AxiosResponse> => api.get<UserClock[]>('/user/task-hours');

export const getERPClockHours = (queryParams: ClockHoursParams): Promise<AxiosResponse<{data: ERPClockInOut[]}>> => api.get<{data: ERPClockInOut[]}>(`/task-clock-in-out/erp-clock-hours/${queryParams.userId}`, {
	params: {
		dateFrom: queryParams.dateFrom,
		dateTo: queryParams.dateTo,
	},
});

export const sendExecutedPercentage = (taskId: string, value: string): Promise<AxiosResponse> => api.post<{taskId: string, value: number}>(`/task-clock-in-out/executed-percentage/${taskId}`, { value });

export const addUsersToProjectTasks = (projectId: string, data: AddUsersToProjectTasksParams): Promise<AxiosResponse<{ message: string }>> => api.post<{ message: string }>(`/hour-project/${projectId}/add-users-to-tasks`, data);

export const updateProjectTask = (id: string, data: UpdateTask): Promise<AxiosResponse<{message: string, data: UpdateTask}>> => api.patch<{message: string, data: UpdateTask}>(`/project-task/${id}`, data);

export const createOverflowTask = (data: CreateOverflowTaskData): Promise<AxiosResponse<CreateOverflowTaskResponse>> => api.post<CreateOverflowTaskResponse>('/project-task/create-overflow-task', data);

export const getGeolocationByIP = (): Promise<AxiosResponse<IPGeolocationResponse>> => externalApi.get<IPGeolocationResponse>(`
	https://ipapi.co/json/`);

export const sendApproval = (taskClockInOutIds: string[]): Promise<AxiosResponse<{message: string}>> => api.post('/task-clock-in-out/send-approval', taskClockInOutIds);

export const exportHourProjects = (queryParams?: HourProjectQueryParams): Promise<AxiosResponse<Blob>> => api.get<Blob>('/hour-project/exportHourProjects', {
	params: queryParams,
	responseType: 'blob',
});
