/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SelectMui, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';

interface ISelectProps extends Omit<SelectProps, 'event' | 'onChange'> {
	name: string;
	label: string;
	options: any[];
	labelKey: string;
	valueKey: string;
	required?: boolean;
	size?: 'small' | 'medium';
	onChange?: (event: SelectChangeEvent) => void;
	value?: any;
	disabled?: boolean;
}

const Select = ({
	name, label, options, labelKey, valueKey, required, size, onChange, value, disabled, ...propsField
}: ISelectProps): JSX.Element => {
	const [field, meta, helpers] = useField({ name });

	const handleChange = (event: SelectChangeEvent): void => {
		helpers.setValue(event.target.value);
		if (onChange) {
			onChange(event);
		}
	};

	const optionsMemo = useMemo(() => (options.map((option) => (
		<MenuItem
			value={option[valueKey]}
			key={option[valueKey]}
		>
			{option[labelKey]}
		</MenuItem>
	))), [options, labelKey, valueKey]);

	return (
		<FormControl sx={{ width: '100%', minWidth: 'fit-content' }}>
			<InputLabel required={required}>{label}</InputLabel>
			<SelectMui
				{...field}
				value={(value || field.value) ?? ''}
				label={label}
				inputProps={{ required }}
				error={Boolean(meta.touched) && Boolean(meta.error)}
				size={size}
				onChange={handleChange}
				disabled={disabled}
				{...propsField}
			>
				{optionsMemo}
			</SelectMui>
			{meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
		</FormControl>
	);
};

Select.defaultProps = {
	required: false,
	size: 'medium',
	onChange: undefined,
	value: '',
	disabled: false,
};

export default Select;
