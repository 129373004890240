import React, { useEffect } from 'react';
import { Link, RouteProps } from 'react-router-dom';
import ProviderClientTable from '../Common/ProviderClient/ProviderClientTable';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';

interface ClientProps {
	loading: boolean;
	count?: number;
	clients?: any[];
	getClients(): void;
	approveClient(id: string): void;
}

const headerButtonsProps: PageHeaderButtonProps[] = [
	{
		variant: 'contained',
		color: 'primary',
		type: 'submit',
		fullWidth: true,
		component: Link,
		to: 'edit',
		text: 'Criar',
	},
];

const Client = ({
	loading, count, clients, approveClient, getClients,
}: ClientProps): JSX.Element => {
	useEffect(() => {
		getClients();
	}, []);

	return (
		<div className="content">
			<PageHeader title="Cliente" buttons={headerButtonsProps} />
			<ProviderClientTable
				loading={loading}
				rowsPerPage={5}
				page={count ?? 0}
				rows={clients ?? []}
				approve={approveClient}
			/>
		</div>
	);
};

Client.defaultProps = {
	count: 0,
	clients: [],
};

export default Client;
