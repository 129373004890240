import React, { useEffect, useRef } from 'react';
import {
	Box,
	Button,
	Grid,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import { useSnackbar } from 'notistack';
import { Loading } from '../../Common/Loading';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import Input from '../../Common/Form/Input';
import { formatBarCode } from '../../../helpers/masks';

interface TransferProductDetailsProps {
	transferTask: ITask;
	loading: boolean;
	handleConfirmTask: (task: ITask, quantity: number) => void;
}

const initialValues = {
	productQuantity: 0,
	barcodeProduct: '',
};

const TransferProductDetails = ({
	loading,
	handleConfirmTask,
	transferTask,
}:TransferProductDetailsProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [loading, transferTask]);

	const formik = useFormik({
		initialValues,
		onSubmit: () => {
			handleConfirmTask(
				transferTask,
				formik.values.productQuantity,
			);
			formik.setFieldValue('barcodeProduct', '');
			formik.setFieldValue('productQuantity', 0);
		},
	});

	const handleIncrement = ():void => {
		formik.setFieldValue('productQuantity', (Number(formik.values.productQuantity) ?? 0) + 1);
	};

	const handleDecrement = ():void => {
		if (formik.values.productQuantity > 0) {
			formik.setFieldValue('productQuantity', (Number(formik.values.productQuantity) ?? 0) - 1);
		}
	};

	const handleBlurOrEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const target = event.target as HTMLInputElement;
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			if (formik.values.barcodeProduct === target.value) {
				handleIncrement();
			} else {
				enqueueSnackbar('Código de barras inválido!', {
					variant: 'error',
				});
			}
			formik.setFieldValue('barcodeProduct', '');
		}
	};

	if (loading) {
		<Loading />;
	}

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Box padding={1}>
					<Typography variant="subtitle1" align="center">
						{`Localização de Origem: ${formatBarCode(transferTask?.locationOrigin.barCode)}`}
					</Typography>
					<Box
						sx={{
							padding: 2,
							border: 1,
							borderColor: '#bbb',
							bgcolor: 'white',
							borderRadius: 2,
							marginY: 2,
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Stack direction="column">
							<Typography variant="subtitle1" align="center">
								{`${transferTask?.product.code} - ${transferTask?.product.description}`}
							</Typography>
							<Typography
								variant="subtitle1"
								align="center"
								sx={{ marginBottom: 2, fontWeight: 'bold' }}
							>
								{`${transferTask.quantity} ${transferTask.product.measures[0].description}(s)`}
							</Typography>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<Input.InputField
										label="Código de Barras"
										id="barcodeProduct"
										name="barcodeProduct"
										onKeyDown={(
											event: React.KeyboardEvent<HTMLInputElement>,
										) => handleBlurOrEnter(event)}
										inputRef={inputRef}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Box
										sx={{
											width: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<IconButton size="large" disabled={formik.values.productQuantity === 0} onClick={handleDecrement}>
											<Remove />
										</IconButton>

										<Input.InputField
											id="productQuantity"
											name="productQuantity"
											style={{ width: 100, textAlign: 'center' }}
											type="number"
											required
										/>

										<IconButton size="large" onClick={handleIncrement}>
											<Add />
										</IconButton>
									</Box>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									display="flex"
									justifyContent="space-between"
									marginTop={2}
									gap={2}
								>
									<Button
										variant="contained"
										size="small"
										color="primary"
										sx={{ width: '100%' }}
										type="submit"
										disabled={
											Number(
												formik.values.productQuantity,
											) > Number(
												transferTask.quantity,
											)
										}
									>
										Confirmar
									</Button>
								</Grid>
							</Grid>
						</Stack>
					</Box>
				</Box>
			</Form>
		</FormikContext.Provider>
	);
};

export default TransferProductDetails;
