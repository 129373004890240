import React, {
	useMemo,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	Box,
	Grid,
	Typography,
	FormGroup,
	TextField,
	Theme,
	Paper,
	IconButton,
	InputAdornment,
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import LoadingButton from '@mui/lab/LoadingButton';
import { SxProps } from '@mui/system';
import {
	Formik, Form, FieldArray, FormikHandlers, FormikHelpers, FieldArrayConfig,
} from 'formik';
import { AxiosResponse } from 'axios';
import Input from '../Form/Input';
import RadioField from '../Form/Radio';
import Tabs from '../Tabs';
import {
	getValidationSchema,
	initialValues,
	paymentMethodOptions,
	ProviderClient,
	shippingOptions,
	taxRegimeOptions,
} from './ProviderClientAssets';
import {
	cepMask, cpfMask, cnpjMask, phoneMask,
} from '../../../helpers/masks';
import { Cep } from '../../../interfaces/cep';
import { getAddressByCep } from '../../../services/app';

interface ProviderClientFormProps {
	loading: boolean;
	handleSubmit: (values: { id?: string; data: ProviderClient }) => void;
	// editData?: HandleSubmitData
}

const ProviderClientForm = ({ loading, handleSubmit }: ProviderClientFormProps): JSX.Element => {
	const [loadingCep, setLoadingCep] = useState<boolean>(false);
	const addressRef = useRef<HTMLInputElement>(null);
	const numberRef = useRef<HTMLInputElement>(null);
	const districtRef = useRef<HTMLInputElement>(null);
	const cityRef = useRef<HTMLInputElement>(null);
	const ufRef = useRef<HTMLInputElement>(null);

	const getAddressByCepProxy = (
		e: any,
		handleBlur: any,
		setFieldValue: any,
	): void => {
		const cep = e.target.value;

		handleBlur(e);

		setFieldValue('address', '');
		setFieldValue('district', '');
		setFieldValue('city', '');
		setFieldValue('uf', '');

		addressRef.current!.disabled = true;
		districtRef.current!.disabled = true;
		cityRef.current!.disabled = true;
		ufRef.current!.disabled = true;

		setLoadingCep(true);

		getAddressByCep(cep)
			.then((response) => {
				setLoadingCep(false);

				if (!response.data.erro) {
					numberRef.current!.focus();

					setFieldValue('address', response.data.logradouro);
					setFieldValue('district', response.data.bairro);
					setFieldValue('city', response.data.localidade);
					setFieldValue('uf', response.data.uf);
				} else {
					addressRef.current!.disabled = false;
					districtRef.current!.disabled = false;
					cityRef.current!.disabled = false;
					ufRef.current!.disabled = false;

					addressRef.current!.focus();
				}
			})
			.catch(() => {
				addressRef.current!.disabled = false;
				districtRef.current!.disabled = false;
				cityRef.current!.disabled = false;
				ufRef.current!.disabled = false;

				addressRef.current!.focus();
			});
	};

	const getCompanyForm = (handleBlur: any, setFieldValue: any): JSX.Element => (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="caption" display="block" gutterBottom>
					Os campos com * são obrigatórios!
				</Typography>
			</Grid>
			<Grid item xs={12} container spacing={2}>
				<Grid item xs={12}>
					<Input.InputField
						id="companyName"
						name="companyName"
						label="Razão Social"
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<Input.InputField
						id="fantasyName"
						name="fantasyName"
						label="Nome Fantasia"
					/>
				</Grid>
				<Grid item xs={4}>
					<Input.InputMaskField
						id="cep"
						name="cep"
						label="CEP"
						mask={cepMask}
						onBlur={(e) => getAddressByCepProxy(e, handleBlur, setFieldValue)}
						required
					/>
				</Grid>
				<Grid item xs={6}>
					<Input.InputField
						id="address"
						name="address"
						label="Endereço"
						required
						inputRef={addressRef}
					/>
				</Grid>
				<Grid item xs={2}>
					<Input.InputField
						id="number"
						name="number"
						label="Nº"
						required
						inputRef={numberRef}
					/>
				</Grid>
				<Grid item xs={5}>
					<Input.InputField
						id="district"
						name="district"
						label="Bairro"
						required
						inputRef={districtRef}
					/>
				</Grid>
				<Grid item xs={5}>
					<Input.InputField
						id="city"
						name="city"
						label="Cidade"
						required
						inputRef={cityRef}
					/>
				</Grid>
				<Grid item xs={2}>
					<Input.InputField
						id="uf"
						name="uf"
						label="UF"
						required
						inputRef={ufRef}
					/>
				</Grid>
				<Grid item xs={4}>
					<Input.InputMaskField
						id="cnpj"
						name="cnpj"
						label="CPF/CNPJ"
						mask={[{ mask: cpfMask }, { mask: cnpjMask }]}
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<Input.InputField
						id="stateRegistration"
						name="stateRegistration"
						label="Inscrição Estadual"
					/>
				</Grid>
				<Grid item xs={4}>
					<Input.InputField
						id="cityRegistration"
						name="cityRegistration"
						label="Inscrição Municipal"
					/>
				</Grid>
				<Grid item xs={7}>
					<RadioField
						name="taxRegime"
						label="Regime Tributário"
						options={taxRegimeOptions}
						orientation="row"
						required
					/>
				</Grid>
				<Grid item xs={5}>
					<Input.InputMaskField
						id="phone"
						name="phone"
						label="Fone"
						mask={phoneMask}
						required
					/>
				</Grid>
			</Grid>
		</Grid>
	);

	const getContactForm = (): JSX.Element => (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="caption" display="block" gutterBottom>
					Os campos com * são obrigatórios!
				</Typography>
			</Grid>
			<Grid item xs={12} container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom>
						Representante
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Input.InputField
						id="representativeName"
						name="representativeName"
						label="Nome"
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<Input.InputMaskField
						id="representativePhone"
						name="representativePhone"
						label="Telefone"
						mask={phoneMask}
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<Input.InputField
						id="representativeEmail"
						name="representativeEmail"
						label="E-mail"
						required
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom>
						Comercial
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Input.InputField
						id="commercialName"
						name="commercialName"
						label="Nome"
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<Input.InputMaskField
						id="commercialPhone"
						name="commercialPhone"
						label="Telefone"
						mask={phoneMask}
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<Input.InputField
						id="commercialEmail"
						name="commercialEmail"
						label="E-mail"
						required
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom>
						Financeiro
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Input.InputField
						id="financialName"
						name="financialName"
						label="Nome"
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<Input.InputMaskField
						id="financialPhone"
						name="financialPhone"
						label="Telefone"
						mask={phoneMask}
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<Input.InputField
						id="financialEmail"
						name="financialEmail"
						label="E-mail"
						required
					/>
				</Grid>
			</Grid>
		</Grid>
	);

	const getPrincipalClientsForm = (values: any): JSX.Element => (
		<Grid item xs={12} container spacing={2}>
			<FieldArray name="clients">
				{({ push, remove }) => (
					<>
						<Grid item xs={6}>
							<Typography
								variant="caption"
								display="block"
								gutterBottom
							>
								Os campos com * são obrigatórios!
							</Typography>
						</Grid>
						<Grid
							item
							xs={6}
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<IconButton
								color="default"
								aria-label="Adicionar linha de cliente"
								component="span"
								size="small"
								onClick={() => push({
									company: '',
									companyContact: '',
									companyPhone: '',
								})}
							>
								<Add />
							</IconButton>
						</Grid>
						{values.clients.length > 0
								&& values.clients.map(
									(client: any, index: number) => (
										<Grid
											item
											xs={12}
											container
											spacing={2}
											// eslint-disable-next-line react/no-array-index-key
											key={index}
										>
											<Grid item xs={4}>
												<Input.InputField
													id="company"
													name={`clients.${index}.company`}
													type="text"
													label="Empresa"
													required
												/>
											</Grid>
											<Grid item xs={4}>
												<Input.InputField
													id="companyContact"
													name={`clients.${index}.companyContact`}
													type="text"
													label="Contato"
													required
												/>
											</Grid>
											<Grid item xs={3}>
												<Input.InputMaskField
													id="companyPhone"
													name={`clients.${index}.companyPhone`}
													type="text"
													label="Fone"
													mask={phoneMask}
													required
												/>
											</Grid>
											<Grid item xs={1}>
												<IconButton
													color="default"
													aria-label="Botão de remover"
													component="span"
													size="small"
													onClick={() => remove(index)}
													disabled={index === 0}
												>
													<Remove />
												</IconButton>
											</Grid>
										</Grid>
									),
								)}
					</>
				)}
			</FieldArray>
		</Grid>
	);

	const getFinancialForm = (values: any): JSX.Element => (
		<Grid item xs={12} container spacing={2}>
			<FieldArray name="financials">
				{({ push, remove }) => (
					<>
						<Grid item xs={6}>
							<Typography
								variant="caption"
								display="block"
								gutterBottom
							>
								Os campos com * são obrigatórios!
							</Typography>
						</Grid>
						<Grid
							item
							xs={6}
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<IconButton
								color="default"
								aria-label="Adicionar linha de banco"
								component="span"
								size="small"
								onClick={() => push({
									bank: '',
									agencyNumber: '',
									accountNumber: '',
									opCaixaEcon: '',
								})}
							>
								<Add />
							</IconButton>
						</Grid>
						<Grid item xs={12}>
							<Typography
								variant="caption"
								display="block"
								gutterBottom
							>
								(Preferencialmente informar dados dos
								bancos: Brasil, Bradesco, Itaú, caso seja
								correntista, se possuir mais que 3 bancos
								favor enviar ficha cadastral com os dados
								bancário).
							</Typography>
						</Grid>
						{values.financials.length > 0
								&& values.financials.map(
									(financial: any, index: number) => (
										<Grid
											item
											xs={12}
											container
											spacing={2}
											// eslint-disable-next-line react/no-array-index-key
											key={index}
										>
											<Grid item xs={3}>
												<Input.InputField
													id="bank"
													name={`financials.${index}.bank`}
													type="text"
													label="Nome do Banco"
													required
												/>
											</Grid>
											<Grid item xs={3}>
												<Input.InputField
													id="agencyNumber"
													name={`financials.${index}.agencyNumber`}
													type="text"
													label="Nº da Agência"
													required
												/>
											</Grid>
											<Grid item xs={3}>
												<Input.InputField
													id="accountNumber"
													name={`financials.${index}.accountNumber`}
													type="text"
													label="Nº da Conta"
													required
												/>
											</Grid>
											<Grid item xs={2}>
												<Input.InputField
													id="opCaixaEcon"
													name={`financials.${index}.opCaixaEcon`}
													type="text"
													label="Op. Caixa Econômica"
												/>
											</Grid>
											<Grid item xs={1}>
												<IconButton
													color="default"
													aria-label="Botão de remover"
													component="span"
													size="small"
													onClick={() => remove(index)}
													disabled={index === 0}
												>
													<Remove />
												</IconButton>
											</Grid>
										</Grid>
									),
								)}
						<Grid item xs={12}>
							<RadioField
								name="paymentMethod"
								label="Forma de Pagamento"
								options={paymentMethodOptions}
								orientation="row"
								required
							/>
						</Grid>
					</>
				)}
			</FieldArray>
		</Grid>
	);

	const getCommercialForm = (): JSX.Element => (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="caption" display="block" gutterBottom>
					Os campos com * são obrigatórios!
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<Input.FinancialInputField
					id="minimumBilling"
					name="minimumBilling"
					label="Faturamento Mínimo"
				/>
				{/* <Input.InputMaskField
					id="minimumBilling"
					name="minimumBilling"
					type="text"
					label="Faturamento Mínimo"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">R$</InputAdornment>
						),
					}}
					mask={Number}
					required
				/> */}
			</Grid>
			<Grid item xs={4}>
				<Input.InputField
					id="paymentTerms"
					name="paymentTerms"
					type="text"
					label="Condição de Pagamento"
					required
				/>
			</Grid>
			<Grid item xs={4}>
				<RadioField
					name="shipping"
					label="Frete"
					options={shippingOptions}
					orientation="row"
					required
				/>
			</Grid>
			<Grid item xs={6}>
				<Input.InputField
					id="emailXMLDanfe"
					name="emailXMLDanfe"
					type="text"
					label="E-mail para envio de XML/Danfe"
					required
				/>
			</Grid>
			<Grid item xs={6}>
				<Input.InputField
					id="website"
					name="website"
					type="text"
					label="Homepage"
				/>
			</Grid>
		</Grid>
	);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={getValidationSchema()}
			onSubmit={(values: ProviderClient) => {
				handleSubmit({ id: undefined, data: values });
			}}
		>
			{({
				values, handleBlur, setFieldValue,
			}) => (
				<Form noValidate>
					<Paper sx={{ mt: 3, p: 2 }} elevation={3}>
						<Tabs
							id="provider-client"
							tabs={[
								{
									label: 'DADOS DA EMPRESA',
									content: getCompanyForm(
										handleBlur,
										setFieldValue,
									),
								},
								{
									label: 'CONTATOS',
									content: getContactForm(),
								},
								{
									label: 'PRINCIPAIS CLIENTES',
									content: getPrincipalClientsForm(values),
								},
								{
									label: 'FINANCEIRO',
									content: getFinancialForm(values),
								},
								{
									label: 'COMERCIAL',
									content: getCommercialForm(),
								},
							]}
						/>
						<LoadingButton
							loading={loading}
							variant="contained"
							color="primary"
							type="submit"
						>
							Salvar
						</LoadingButton>
					</Paper>
				</Form>
			)}
		</Formik>
	);
};

export default ProviderClientForm;
