import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { SxProps } from '@mui/system';

interface ExpandButtonProps {
	toggleExpand: () => void;
	expandAccordions: boolean;
}

const fabStyles: SxProps<Theme> = {
	position: 'fixed',
	bottom: '9px',
	right: '40px',
};

const ExpandButton: React.FC<ExpandButtonProps> = ({
	toggleExpand,
	expandAccordions,
}) => {
	const [hover, setHover] = useState(false);
	const expandLabel = expandAccordions ? 'Recolher' : 'Expandir';

	return (
		<Fab
			variant={hover ? 'extended' : 'circular'}
			size="small"
			color="primary"
			onMouseOver={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={() => {
				toggleExpand();
				setHover(false);
			}}
			sx={fabStyles}
		>
			{expandAccordions
				? <UnfoldLessIcon sx={hover ? { mr: 1 } : {}} />
				: <UnfoldMoreIcon sx={hover ? { mr: 1 } : {}} />}
			{hover && <Box sx={{ pr: 1 }}>{expandLabel}</Box>}
		</Fab>
	);
};

export default ExpandButton;
