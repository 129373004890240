import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import StoragePresentational from '../../../components/Mobile/Storage/StorageCheck';
import {
	getLocationOrigin, saveTakeTask,
	SaveTaskType,
} from '../../../services/storage';
import { InventoryTaskStatus } from '../../../enums/InventoryTaskStatus';
import { ITask } from '../TaskAssets';

enum ActionType {
	LOADING,
	TASKS,
}

interface IState {
	loading: boolean;
	tasks: ITask[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.TASKS; payload: { tasks: ITask[] } };

interface IStorageActions {
	setLoading(loading: boolean): void;
	getLocationOrigin(barCode: string, status: InventoryTaskStatus): void;
	saveTakeTask(data: SaveTaskType): void;
}

const initialState: IState = {
	loading: false,
	tasks: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.TASKS:
			return {
				...state,
				tasks: action.payload.tasks,
			};
		default:
			throw new Error();
	}
};

const StorageActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IStorageActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},

		getLocationOrigin(barCode: string, status: InventoryTaskStatus) {
			actions.setLoading(true);
			getLocationOrigin(barCode, status).then(
				(response) => {
					dispatch({
						type: ActionType.TASKS,
						payload: { tasks: response.data.data },
					});
				},
			).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu ao obter a localização de origem. Tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			})
				.finally(() => {
					actions.setLoading(false);
				});
		},

		saveTakeTask(task: SaveTaskType) {
			actions.setLoading(true);
			saveTakeTask(task).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
				actions.getLocationOrigin('A01Z01', InventoryTaskStatus.WAITING);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},

	};

	return actions;
};

const StorageCheck = ():JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => StorageActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<StoragePresentational {...state} {...actions} />
	);
};

export default StorageCheck;
