import React, { useCallback, useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import {
	EditProductFormProps,
	ILocationProductTableRow,
	validationSchemaEditProduct,
} from '../../../containers/Location/LocationAssets';
import Input from '../../Common/Form/Input';

interface EditProductModalProps {
  open: boolean;
  onClose: () => void;
  locationProduct: ILocationProductTableRow | null;
  loading: boolean;
  onSubmit(productId: string, locationId: string, values: EditProductFormProps): Promise<void>;
}

const EditProductModal = ({
	open,
	onClose,
	locationProduct,
	loading,
	onSubmit,
}: EditProductModalProps): JSX.Element => {
	const handleEditLocationProduct = useCallback(
		async (values: EditProductFormProps) => {
			if (locationProduct) {
				await onSubmit(
					locationProduct.id,
					locationProduct.locationProduct.locationId,
					values,
				);
			}
		},
		[locationProduct, onSubmit],
	);

	const initialValuesLoad = useMemo(
		() => (locationProduct
			? {
				valuedQuantity: locationProduct.valuedQuantity,
				currentQuantity: locationProduct.currentQuantity,
			}
			: { valuedQuantity: '', currentQuantity: '' }),
		[locationProduct],
	);

	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<Formik
				onSubmit={handleEditLocationProduct}
				initialValues={initialValuesLoad}
				validationSchema={validationSchemaEditProduct}
				validateOnChange={false}
				validateOnBlur={false}
			>
				{({ isSubmitting }) => (
					<Form noValidate>
						<DialogTitle>Editar Produto</DialogTitle>
						<DialogContent>
							<Input.InputField
								label="Capacidade"
								name="valuedQuantity"
								type="number"
								sx={{
									mt: 2,
									'& input[type=number]': {
										MozAppearance: 'textfield',
									},
									'& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
										WebkitAppearance: 'none',
										margin: 0,
									},
								}}
								fullWidth
								required
							/>
							<Input.InputField
								label="Utilizado"
								name="currentQuantity"
								type="number"
								sx={{
									mt: 2,
									'& input[type=number]': {
										MozAppearance: 'textfield',
									},
									'& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
										WebkitAppearance: 'none',
										margin: 0,
									},
								}}
								fullWidth
								required
							/>
						</DialogContent>
						<DialogActions sx={{ gap: 1 }}>
							<Button onClick={onClose}>Cancelar</Button>
							<LoadingButton
								type="submit"
								variant="contained"
								disabled={isSubmitting}
								loading={loading}
							>
								Salvar
							</LoadingButton>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default EditProductModal;
