import { AxiosResponse } from 'axios';
import api from './api';
import {
	IBusinessPartner,
	IBusinessPartners,
	IProductBusinessPartnerDetails,
	ISalesmans,
} from '../containers/BusinessPartner/BusinessPartnerAssets';
import { BusinessPartnerQueryParams } from '../interfaces/BusinessPartnerQueryParams';
import { BusinessPartnerType } from '../enums/BusinessPartnerType';
import { IMessageResponse } from '../interfaces/MessageResponse';

export const getBusinessPartners = (params?: BusinessPartnerQueryParams): Promise<AxiosResponse<IBusinessPartners>> => api.get<IBusinessPartners>('/business-partner', { params });

export const getBusinessPartnersAdmin = (params?: BusinessPartnerQueryParams): Promise<AxiosResponse<IBusinessPartners>> => api.get<IBusinessPartners>('/business-partner/admin', { params });

export const getBusinessPartnersSalesAdmin = (params?: BusinessPartnerQueryParams): Promise<AxiosResponse<IBusinessPartners>> => api.get<IBusinessPartners>('/business-partner/sales-admin', { params });

export const getBusinessPartnerById = (id: string): Promise<AxiosResponse<IBusinessPartner>> => api.get<IBusinessPartner>(`business-partner/${id}`);

export const saveBusinessPartner = (data: IBusinessPartner): Promise<AxiosResponse<IMessageResponse>> => api.post<IBusinessPartner, AxiosResponse<IMessageResponse>>('/business-partner', data);

export const updateBusinessPartner = (id: string, data: IBusinessPartner): Promise<AxiosResponse<IMessageResponse>> => api.patch<IBusinessPartner, AxiosResponse<IMessageResponse>>(`/business-partner/${id}`, data);

export const deleteBusinessPartner = (id: string): Promise<AxiosResponse<IMessageResponse>> => api.delete<IMessageResponse>(`/business-partner/${id}`);

export const approveBusinessPartner = (
	id: string,
): Promise<AxiosResponse<any>> => api.patch<{ id: string }, AxiosResponse<any>>(
	`/business-partner/approve/${id}`,
);

export const getSalesmans = (): Promise<AxiosResponse<ISalesmans>> => api.get<ISalesmans>('/business-partner/salesmans');

export const getSalesmansAdmin = (): Promise<AxiosResponse<ISalesmans>> => api.get<ISalesmans>('/business-partner/salesmansAdmin');

export const getProductBusinessPartnerDetails = (
	businessPartnerId: string,
	description?: string,
): Promise<AxiosResponse<IProductBusinessPartnerDetails>> => {
	const queryParam = description ? `?description=${description}` : '';
	return api.get<IProductBusinessPartnerDetails>(`/business-partner/productsBusinessPartner/${businessPartnerId}${queryParam}`);
};

export const getBusinessPartnersByType = (type: BusinessPartnerType): Promise <AxiosResponse<any>> => api.get<any>(`/business-partner/type/ ${type} `);

export const getBusinessPartnersByTypeAdmin = (type: BusinessPartnerType): Promise <AxiosResponse<any>> => api.get<any>(`/business-partner/typeAdmin/ ${type} `);

export const handleBusinessPartnerActiveStatus = (
	id: string,
	active: boolean,
): Promise<AxiosResponse<{ message: string, data: IBusinessPartner }>> => {
	const data = { id, active };
	return api.post<{ message: string, data: IBusinessPartner }>('/business-partner/handleActive', data);
};
