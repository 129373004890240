import { ProjectTaskStatus } from '../../enums/ProjectTaskStatus';
import { OrderBy } from '../../interfaces/HourProject';

export interface CreateTaskClockInOutResponse {
	message: string,
	warning:string,
	data: TaskClockInOut
}

export interface TaskClockInOut {
  id?: string;
  dateTime: Date;
  projectTaskId: string;
  userId?: string;
  registeredAt?: Date;
  hasInconsistence?: boolean;
  user?: { name: string };
  geoLocation?: string;
  geoCoordinates?: string;
}

export interface ProjectTaskProduct {
  projectTaskId: string;
  productId: string;
  status: boolean;
  value: number;
  description: string;
}

export interface ProjectTaskUser {
  projectTaskId: string;
  userId: string;
  status: boolean;
  value: number;
  description: string;
}

export interface Task {
  id: string;
  code: string;
  hourProjectId: string;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  quantity: string;
  canWorkHoliday: boolean;
  actionJustificative: string;
  status: ProjectTaskStatus;
  requesterName: string;
  projectTaskId: string;
  tasksClockInOut: TaskClockInOut[];
  projectTaskUsers: ProjectTaskUser[];
  projectTaskProducts: ProjectTaskProduct[];
  workedHours: string;
  completionPercentage: number;
  executedPercentage: number;
  hoursBalance: string;
  periodClockInOutCount: number;
  totalClockInOutCount: number;
}

export type UpdateTask = Partial<Task>;

export interface CreateOverflowTaskData {
  taskId: string;
  quantity: number;
  actionJustificative: string;
}

export interface CreateOverflowTaskResponse {
  message: string;
  data: Task;
}

export interface HourProject {
  id: string;
  code: string;
  name: string;
  description: string;
  quantity: string;
  startDate: Date;
  endDate: Date;
  classValueId: string;
  client: string;
  store: string;
  revision: string;
  budget: string;
  phase: string;
  finished: boolean;
  tasks: Task[];
  projectTaskUsers: ProjectTaskUser[];
  workedHours: string;
  completionPercentage: number;
  executedPercentage: number;
  hoursBalance: string;
  periodClockInOutCount: number;
  totalClockInOutCount: number;
}

export interface UserProjectsTasksHoursParams {
  date: Date | string;
  skip: number;
  name?: string;
  take?: number;
  orderBy?: OrderBy;
}

export interface ERPClockInOut {
  id?: string;
  userId: string;
  dateTime: Date;
  type: string;
}

export interface UserProjectsTasksHoursResponse {
  user: {
    name: string,
    registerHour: boolean
  },
  projects: HourProject[];
  erpClockInOuts: ERPClockInOut[];
  workedHoursDay: string;
  hasApproval: boolean;
  count: number;
  daysBackAppointment: string;
}

export interface IForm {
  name?: string;
  hasQuantity?: boolean;
  skip: number;
}

export const initialValues: IForm = {
	name: '',
	hasQuantity: true,
	skip: 0,
};
