import React, {
	useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
	DataGrid,
	GridActionsCellItem,
	GridColDef,
	GridRenderCellParams,
	GridSortModel,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { Print } from '@mui/icons-material';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import { getBase64Image } from '../../helpers/Utils';

interface ProductTableProps {
	loading?: boolean;
	rows: IProductWithoutDetails[];
	productsPages: number;
	productsPage: number;
	productsTake: number;
	sortModel: GridSortModel;
	onChangePage(productsTake: number): void;
	onChangePageSize(pageSize: number): void;
	onSortModelChange(sortModel: GridSortModel): void
	handleDeleteProduct(id: string): void;
	printLabel(barCode: string): void;
}

const ProductTable = ({
	rows,
	loading,
	productsPages,
	productsPage,
	productsTake,
	sortModel,
	onChangePage,
	onChangePageSize,
	onSortModelChange,
	handleDeleteProduct,
	printLabel,
}:ProductTableProps):JSX.Element => {
	const columns = useMemo<GridColDef[]>(() => [
		{
			field: 'image',
			headerName: 'Imagem',
			width: 120,
			align: 'center',
			renderCell: (params: GridRenderCellParams) => {
				const imageDataUrl = getBase64Image(params.row.imageB64);
				const boxStyle = {
					width: '50px',
					height: '50px',
					backgroundImage: `url(${imageDataUrl})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					borderRadius: '50%',
				};

				return (
					<Box style={boxStyle} />
				);
			},
		},
		{ field: 'code', headerName: 'Código', flex: 1 },
		{
			field: 'description',
			headerName: 'Descrição',
			flex: 1.5,
			renderCell: (params: GridRenderCellParams) => (
				<Tooltip title={params.row.description}>
					<span>{params.value as string}</span>
				</Tooltip>
			),
		},
		{
			field: 'familyCode',
			headerName: 'Cód. Família',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (
				<span>{params.value as string || '-'}</span>
			),
		},
		{
			field: 'familyDescription',
			headerName: 'Desc. Família',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (
				<Tooltip title={params.row.familyDescription}>
					<span>{params.value as string || '-'}</span>
				</Tooltip>
			),
		},
		{
			field: 'primaryMeasureId',
			headerName: 'Unidade de Medida',
			flex: 1,
			sortable: false,
		},
		{
			field: 'typeId',
			headerName: 'Tipo',
			flex: 1,
			sortable: false,
		},
		{
			field: 'actions',
			headerName: 'Ações',
			width: 120,
			sortable: false,
			renderCell: (params: GridRenderCellParams) => {
				const handleDelete = (): void => handleDeleteProduct(
					params.id.toString(),
				);

				return (
					<>
						<Tooltip title="Imprimir Etiqueta">
							<GridActionsCellItem
								icon={<Print />}
								label="Imprimir"
								color="primary"
								onClick={() => printLabel(params.row.barCode)}
							/>
						</Tooltip>
						<Tooltip title="Editar">
							<Link to={`edit/${params.id}`}>
								<GridActionsCellItem
									icon={<EditIcon />}
									label="Editar"
									color="primary"
								/>
							</Link>
						</Tooltip>
						<Tooltip title="Excluir">
							<GridActionsCellItem
								icon={<DeleteIcon />}
								label="Excluir"
								color="error"
								onClick={handleDelete}
							/>
						</Tooltip>
					</>
				);
			},
		},
	], [handleDeleteProduct, printLabel]);

	return (
		<Box component={Paper} sx={{ width: '100%' }}>
			<DataGrid
				autoHeight
				rows={rows}
				rowCount={productsPages}
				pagination
				paginationMode="server"
				sortingMode="server"
				columns={columns}
				page={productsPage}
				pageSize={productsTake}
				rowsPerPageOptions={[10, 25, 50, 100]}
				loading={loading}
				sortModel={sortModel}
				onPageChange={onChangePage}
				onPageSizeChange={onChangePageSize}
				onSortModelChange={onSortModelChange}
				disableSelectionOnClick
				disableColumnMenu
			/>
		</Box>
	);
};

ProductTable.defaultProps = {
	loading: false,
};

export default ProductTable;
