import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import InventoryLocation from './Steps/StepLocation';
import InventoryProduct from './Steps/StepProduct';
import { removeBarCodeMask } from '../../../../../helpers/masks';
import { ValidateInventoryParams } from '../../../../../containers/Mobile/Inventory/InventoryAssets';

interface InventoryFirstProps {
	handleValidateInventory(params: ValidateInventoryParams): void;
	countNumber: number;
}

enum ActiveStep {
	LOCATION,
	PRODUCT,
}

const InventoryStartFirst = ({
	handleValidateInventory,
	countNumber,
}: InventoryFirstProps): JSX.Element => {
	const [activeStep, setActiveStep] = useState<ActiveStep>(ActiveStep.LOCATION);
	const [locationBarCode, setLocationBarCode] = useState('');

	const { id } = useParams();

	const handleConfirmLocation = useCallback((location: string) => {
		setLocationBarCode(removeBarCodeMask(location));
		setActiveStep(ActiveStep.PRODUCT);
	}, []);

	const handleConfirmProduct = useCallback((product: string, reader?: boolean) => {
		if (id) {
			handleValidateInventory({
				inventoryId: id,
				barCodeLocation: removeBarCodeMask(locationBarCode),
				barCodeProduct: product,
				reader,
			});
		}
	}, [handleValidateInventory, id, locationBarCode]);

	return (
		<Box>
			<Typography variant="caption" textTransform="uppercase">{`${countNumber}ª Contagem`}</Typography>
			{activeStep === ActiveStep.LOCATION
				&& (
					<InventoryLocation
						confirmLocation={handleConfirmLocation}
					/>
				)}

			{activeStep === ActiveStep.PRODUCT
				&& (
					<InventoryProduct
						confirmProduct={handleConfirmProduct}
						handleBack={() => setActiveStep(ActiveStep.LOCATION)}
					/>
				)}
		</Box>
	);
};

export default InventoryStartFirst;
