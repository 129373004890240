import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { formatValueOrNA } from '../helpers/Utils';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';

function formatDate(value: Date | string): string {
	const date = (typeof value === 'string' ? new Date(value) : value);
	return format(date, 'dd/MM/yyyy HH:mm');
}

export const taskClockInOutColumns: GridColDef[] = [
	{
		field: 'user',
		headerName: 'Usuário',
		flex: 1,
		sortable: true,
		valueGetter: (params) => params.row.user?.name,
	},
	{
		field: 'dateTime',
		headerName: 'Data e Hora',
		flex: 0.5,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, formatDate),
	},
	{
		field: 'registeredAt',
		headerName: 'Registrado em',
		flex: 0.5,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, formatDate),
	},
	{
		field: 'geoLocation',
		headerName: 'Localização Aproximada',
		flex: 1.5,
		sortable: true,
		renderCell: (params: GridRenderCellParams<string>) => (
			params.value ? (
				<TruncateTooltipCell value={params.value} />
			) : 'N/A'
		),
	},
];
