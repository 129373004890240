import { AxiosResponse } from 'axios';
import { ITask } from '../containers/Mobile/TaskAssets';
import api from './api';
import { IMessageResponse } from './saleOrder';

type ResponseTasksOrigin = {
  data: ITask[];
  count: number;
};

export type SaveTaskType = {
  taskId: string;
  quantity?: number;
}

export const getTransfers = (): Promise<AxiosResponse<ResponseTasksOrigin>> => api.get<ResponseTasksOrigin>('/inventory-task?action=1&status[]=1&status[]=2');

export const getTaskById = (id: string): Promise<AxiosResponse<ITask>> => api.get<ITask>(`/inventory-task/${id}`);

export const saveTakeTask = (data: SaveTaskType):
Promise<AxiosResponse<IMessageResponse>> => api.post<SaveTaskType,
AxiosResponse<IMessageResponse>>('/inventory-task/takeTask', data);

export const saveTaskCount = (data: SaveTaskType):
Promise<AxiosResponse<IMessageResponse>> => api.post<SaveTaskType,
AxiosResponse<IMessageResponse>>('/inventory-task/taskCount/', data);
