import { Role } from '../interfaces/Module';
import { getModules } from '../services/app';

export const hasPermission = (role: Role): boolean => {
	const modules = getModules();

	const module = modules.find((currentModule) => currentModule.module.code === role.module);

	if (!module) {
		return false;
	}

	if (role.edit && !module.canEdit) {
		return false;
	}

	if (role.admin && !module.isAdmin) {
		return false;
	}

	return true;
};
