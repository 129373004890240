/* eslint-disable import/no-duplicates */
import React, {
	useCallback,
	useEffect, useState,
} from 'react';
import {
	Typography,
	Grid,
	Box,
	FormLabel,
	RadioGroup,
	Radio,
	IconButton,
	Collapse,
	TableRow,
	TableCell,
	Table,
	TableHead,
	TableBody,
	TableContainer,
	FormControlLabel,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useFormikContext } from 'formik';
import { ptBR } from 'date-fns/locale';
import { addDays, format } from 'date-fns';
import { ISaleOrderExtended } from '../../../containers/SaleOrder/SaleOrderAssets';
import { tableAddressColumns } from '../../../constants/saleOrderColumns';
import { DatePicker } from '../../Common/Form/DatePicker';
import Autocomplete from '../../Common/Form/Autocomplete';
import { IBusinessPartner } from '../../../containers/BusinessPartner/BusinessPartnerAssets';

interface Address {
	id: string;
	address: string;
	amount: number;
	needDate: string | Date;
}

interface StepDeliveryProps {
	transporters: IBusinessPartner[];
	clients: IBusinessPartner[];
	getTransporters: () => void;
}

const StepDelivery = ({
	transporters,
	clients,
	getTransporters,
}: StepDeliveryProps): JSX.Element => {
	const [selectOption, setSelectedOption] = useState(false);
	const [selectedClient, setSelectedClient] = useState<undefined | IBusinessPartner>();
	const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});

	const { values, setFieldValue } = useFormikContext<ISaleOrderExtended>();

	useEffect(() => {
		getTransporters();
		const client = clients.find((c) => c.id === values.clientId);
		setSelectedClient(client);

		if (values.saleOrderItems.length > 0 && !values.deliveryDate) {
			setFieldValue('deliveryDate', values.saleOrderItems[0].deliveryDate);
		}
		if (client && values.deliveryAddress) {
			let initialPlaceId = client.id;
			client.units.forEach((unit) => {
				const address = `${unit.address || ''}, ${unit.number || ''}, ${unit.district || ''}, ${unit.city || ''}, ${unit.state || ''}`;
				if (address === values.deliveryAddress) {
					initialPlaceId = unit.id;
				}
			});
			setFieldValue('deliveryPlaceId', initialPlaceId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.deliveryAddress]);

	useEffect(() => {
		if (values.clientId && selectedClient && selectedClient.address) {
			if (!values.deliveryAddress || values.deliveryAddress.startsWith('Selecione')) {
				const address = `${selectedClient.address}, ${selectedClient.number}, ${selectedClient.district}, ${selectedClient.city}, ${selectedClient.state}`;
				setFieldValue('deliveryAddress', address);
			}
		}

		if (values.transporterId) {
			const transporterDetails = transporters.find((t) => t.id === values.transporterId);
			setFieldValue('transporterDetails', transporterDetails);
		}
		if (selectedClient?.units.length === 0) {
			setFieldValue('deliveryPlaceId', values.clientId);
		}
		if (values.deliveryDate) {
			const updatedItems = values.saleOrderItems.map((item) => ({
				...item,
				deliveryDate: values.deliveryDate,
			}));
			setFieldValue('saleOrderItems', updatedItems);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		values.clientId,
		selectedClient,
		values.transporterId,
		transporters,
		setFieldValue,
		values.deliveryDate,
	]);

	const generateAllocationRows = useCallback((): Address[] => {
		const rows = [];

		if (selectedClient?.address) {
			rows.push({
				id: 'consumer',
				address: values.deliveryAddress ? values.deliveryAddress : `${selectedClient.address}, ${selectedClient.number}, ${selectedClient.district}, ${selectedClient.city}, ${selectedClient.state}`,
				amount: 0,
				needDate: format(addDays(new Date(), 30), 'dd/MM/yyyy', {
					locale: ptBR,
				}),
			});
		}

		if (selectedClient?.units && selectedClient?.units?.length > 0) {
			selectedClient.units.forEach((unit, index) => {
				rows.push({
					id: `unit-${index}`,
					address: `${unit.address}, ${unit.number}, ${unit.district}, ${unit.city}, ${unit.state}`,
					amount: 0,
					needDate: format(addDays(new Date(), 30), 'dd/MM/yyyy', {
						locale: ptBR,
					}),
				});
			});
		}

		return rows;
	}, [selectedClient, values.deliveryAddress]);

	const handleRowExpandToggle = useCallback((index: number): void => {
		setExpandedRows((prevExpandedRows) => ({
			...prevExpandedRows,
			[index]: !prevExpandedRows[index],
		}));
	}, []);

	const handleAddressChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
		const selectedPlaceId = event.target.value;
		const selectedPlace = selectedClient?.units
			.find((unit) => unit.id === selectedPlaceId) || selectedClient;
		if (selectedPlace) {
			const address = `${selectedPlace.address || ''}, ${selectedPlace.number || ''}, ${selectedPlace.district || ''}, ${selectedPlace.city || ''}, ${selectedPlace.state || ''}`;
			setFieldValue('deliveryPlaceId', selectedPlace.id);
			setFieldValue('deliveryAddress', address);
		}
	}, [selectedClient, setFieldValue]);

	return (
		<Box>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<Autocomplete<IBusinessPartner>
						options={transporters}
						label="Escolha a transportadora"
						name="transporterId"
						labelKey="name"
						valueKey="id"
						valueLabel="taxId"
						required
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<DatePicker name="deliveryDate" label="Data da necessidade" required />
				</Grid>
			</Grid>

			{!selectOption && (
				<Box>
					<Typography variant="h6" gutterBottom mt={4}>
						Local de Entrega
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							{selectedClient?.units && selectedClient.units.length > 0 && (
								<FormLabel id="demo-radio-buttons-group-label" sx={{ marginBottom: 2 }}>
									Escolha um endereço de entrega
								</FormLabel>
							)}
							{selectedClient && (
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									name="deliveryPlaceId"
									value={values.deliveryPlaceId || ''}
									onChange={handleAddressChange}
								>
									<FormControlLabel
										value={selectedClient.id}
										control={<Radio />}
										label={`${selectedClient.address}, ${selectedClient.number}, ${selectedClient.district}, ${selectedClient.city}, ${selectedClient.state}`}
									/>
									{selectedClient.units && selectedClient.units.map((unit) => (
										<FormControlLabel
											key={unit.id}
											value={unit.id}
											control={<Radio />}
											label={`${unit.address}, ${unit.number}, ${unit.district}, ${unit.city}, ${unit.state}`}
										/>
									))}
								</RadioGroup>
							)}
						</Grid>
					</Grid>
				</Box>
			)}

			{selectOption && (
				<TableContainer>
					<Table sx={{ my: 4 }}>
						<TableHead>
							<TableRow>
								<TableCell />
								<TableCell>Descrição</TableCell>
								<TableCell>Quantidade Total</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{values.saleOrderItems.map((saleOrderItem, index) => (
								<React.Fragment key={saleOrderItem.id}>
									<TableRow
										sx={{ '& > *': { borderBottom: 'unset' } }}
										key={saleOrderItem.id}
									>
										<TableCell>
											<IconButton
												aria-label="expand row"
												size="small"
												onClick={() => handleRowExpandToggle(index)}
											>
												{expandedRows[index] ? (
													<KeyboardArrowUp />
												) : (
													<KeyboardArrowDown />
												)}
											</IconButton>
										</TableCell>
										<TableCell component="th" scope="row">
											{saleOrderItem.description}
										</TableCell>
										<TableCell>{saleOrderItem.quantity}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell
											style={{ paddingBottom: 0, paddingTop: 0 }}
											colSpan={6}
										>
											<Collapse
												in={expandedRows[index]}
												timeout="auto"
												unmountOnExit
											>
												<Box sx={{ margin: 1 }}>
													<Typography variant="h6" gutterBottom component="div">
														Rateio
														{' '}
														{saleOrderItem.description}
													</Typography>
													<DataGrid
														autoHeight
														rows={generateAllocationRows()}
														columns={tableAddressColumns}
														disableSelectionOnClick
													/>
												</Box>
											</Collapse>
										</TableCell>
									</TableRow>
								</React.Fragment>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Box>
	);
};

export default StepDelivery;
