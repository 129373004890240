import React, { useCallback, useMemo } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Form, Formik, FormikHelpers } from 'formik';
import { difference } from 'lodash';
import { useSearchParams, Link } from 'react-router-dom';

import { BudgetApproverType } from '../../../../containers/Budget/BudgetUsers';
import { IUserLevelFormData } from '../../../../interfaces/BudgetUserLevels';
import Checkbox from '../../../Common/Form/Checkbox';
import { CorporativeLevelField } from './CorporativeLevelField';
import { CorporativeLevelAllFields } from './CorporativeLevelAllFields';

interface IBudgetUserCollapseProps {
  approver: any;
  open: boolean;
  index: number;
  accountingAccounts: any[];
  costCenters: any[];
  accountingItems: any[];
  classValues: any[];
  handleBudgetApprovers: (data: any) => void;
  handleOpen: (index: number | null) => void;
}

export const BudgetUserCollapse = ({
	approver,
	open,
	index,
	accountingAccounts,
	costCenters,
	accountingItems,
	classValues,
	handleBudgetApprovers,
	handleOpen,
}: IBudgetUserCollapseProps): JSX.Element => {
	const [params] = useSearchParams();
	const showAllFields: boolean = params.get('all') === 'true';

	const getInitialValues = useCallback((budgetApprovers: any) => {
		const types: Array<any> = budgetApprovers.map((budgetApprover: any) => budgetApprover.type);
		const CLApprover = budgetApprovers
			.find((budgetApprover: any) => budgetApprover.type === BudgetApproverType.CORPORATIVE_LEVEL);

		return {
			corporativeLevel: types.includes(BudgetApproverType.CORPORATIVE_LEVEL),
			controllership: types.includes(BudgetApproverType.CONTROLLERSHIP),
			accountingAccounts: CLApprover?.accountingAccounts
				.map((accountingAccount: any) => accountingAccount.code),
			...(showAllFields ? {
				costCenters: CLApprover?.costCenters
					.map((currentCostCenters: any) => currentCostCenters.code),
				accountingItems: CLApprover?.accountingItems
					.map((currentAccountingItems: any) => currentAccountingItems.code),
				classValues: CLApprover?.classValues
					.map((currentClassValues: any) => currentClassValues.code),
			} : {}),
		};
	}, [showAllFields]);

	const initialValues = getInitialValues(approver.budgetApprovers);

	// const validate = (values: IUserLevelFormData) => {
	//     const errors: any = {}

	//     if (values.corporativeLevel && values.accountingAccounts.length === 0) {
	//         errors.accountingAccounts = 'Selecione ao menos uma conta'
	//     }

	//     return errors;
	// }

	const handleSubmit = useCallback((
		values: IUserLevelFormData,
		actions: FormikHelpers<any>,
	) => {
		const data: any[] = [];
		const deleteCorporativeLevel = initialValues.corporativeLevel !== values.corporativeLevel
			&& !values.corporativeLevel;
		const deleteControllership = initialValues.controllership !== values.controllership
			&& !values.controllership;

		if (deleteCorporativeLevel) {
			data.push({
				type: BudgetApproverType.CORPORATIVE_LEVEL,
				userId: approver.id,
				deleteRecord: true,
			});
		}

		if (values.corporativeLevel) {
			const removeDifference = {
				accountingAccountsId: difference<string>(initialValues.accountingAccounts, values.accountingAccounts, 'code'),
				costCentersId: difference<string>(initialValues.costCenters, values.costCenters ?? [], 'code'),
				classValuesId: difference<string>(initialValues.classValues, values.classValues ?? [], 'code'),
				accountingItemsId: difference<string>(initialValues.accountingItems, values.accountingItems ?? [], 'code'),
			};

			const addDifference = {
				accountingAccountsId: difference<string>(values.accountingAccounts, initialValues.accountingAccounts, 'code'),
				costCentersId: difference<string>(values.costCenters ?? [], initialValues.costCenters, 'code'),
				classValuesId: difference<string>(values.classValues ?? [], initialValues.classValues, 'code'),
				accountingItemsId: difference<string>(values.accountingItems ?? [], initialValues.accountingItems, 'code'),
			};

			data.push({
				type: BudgetApproverType.CORPORATIVE_LEVEL,
				userId: approver.id,
				add: addDifference,
				remove: removeDifference,
			});
		}

		if (values.controllership || deleteControllership) {
			data.push({
				type: BudgetApproverType.CONTROLLERSHIP,
				userId: approver.id,
				deleteRecord: deleteControllership,
			});
		}

		handleBudgetApprovers(data);

		actions.resetForm({
			isSubmitting: false,
			values: initialValues,
		});
	}, [handleBudgetApprovers]);

	const corporativeFieldsMemo = useMemo(() => {
		if (showAllFields) {
			return (
				<CorporativeLevelAllFields
					accountingAccounts={accountingAccounts}
					costCenters={costCenters}
					accountingItems={accountingItems}
					classValues={classValues}
				/>
			);
		}

		return (
			<CorporativeLevelField accountingAccounts={accountingAccounts} />
		);
	}, [accountingAccounts, accountingItems, classValues, costCenters, showAllFields]);

	const formMemo = useMemo(() => (
		<Formik
			key={approver.id}
			initialValues={initialValues}
			onSubmit={(
				values: IUserLevelFormData,
				actions: FormikHelpers<any>,
			) => handleSubmit(values, actions)}
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
		>
			{({ values }) => (
				<Form noValidate>
					<Paper elevation={open ? 20 : 2} sx={{ p: 2 }}>
						<Stack direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent="space-between">
							<Typography variant="h6" fontWeight={400}>{approver.name}</Typography>

							<Stack direction="row" spacing={1}>
								{open
									? (
										<>
											<Button type="button" onClick={(e) => { e.preventDefault(); handleOpen(null); }} variant="text">Cancelar</Button>
											<Button type="submit" variant="contained">Salvar</Button>
										</>
									)
									: (
										<>
											<Button type="button" onClick={(e) => { e.preventDefault(); handleOpen(index); }} variant="outlined">Editar nível</Button>
											<Button type="button" component={Link} to={`responsible/${approver.id}`} variant="outlined">Definir %</Button>
										</>
									)}
							</Stack>
						</Stack>
						<Collapse in={open}>
							<Grid container spacing={3} sx={{ p: 2, width: '100%' }} alignItems="center">

								<Grid item xs={12}>
									<Checkbox.SingleCheckbox
										name="controllership"
										label="Controladoria"
										defaultChecked={values.controllership}
									/>
								</Grid>

								<Grid item xs>
									<Checkbox.SingleCheckbox
										name="corporativeLevel"
										label="Nível corporativo"
										defaultChecked={values.corporativeLevel}
									/>
								</Grid>

								{values.corporativeLevel && (
									<Grid item xs={9} xl={10} container>
										{corporativeFieldsMemo}
									</Grid>
								)}
							</Grid>
						</Collapse>
					</Paper>
				</Form>
			)}
		</Formik>
	), [approver, corporativeFieldsMemo, handleOpen, handleSubmit, index, initialValues, open]);

	return formMemo;
};
