import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
	DataGrid,
	GridActionsCellItem,
	GridColDef,
	GridRenderCellParams,
	GridValueFormatterParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { ILicense } from '../../containers/License/LicenseAssets';

interface LicenseTableProps {
	loading?: boolean;
	rows: ILicense[];
	handleDeleteLicense: (id: string) => void;
	onDetailsClick(license: string, inUse: number): void;
}

interface CustomValueFormatterParams extends GridValueFormatterParams<unknown> {
	value: number | string;
}

const LicenseTable: React.FC<LicenseTableProps> = ({
	rows, loading, handleDeleteLicense, onDetailsClick,
}) => {
	const columns = useMemo<GridColDef[]>(() => [
		{ field: 'identifier', headerName: 'Identificador', flex: 1 },
		{
			field: 'expireDate',
			headerName: 'Expira em',
			flex: 1,
			valueFormatter: (params: CustomValueFormatterParams) => {
				const date = new Date(params.value);
				return date.toLocaleString('pt-BR');
			},
		},
		{
			field: 'actions',
			headerName: 'Ações',
			width: 120,
			renderCell: (params: GridRenderCellParams) => {
				const handleDelete = (): void => handleDeleteLicense(params.id.toString());

				return (
					<>
						<Link to={`edit/${params.id}`}>
							<GridActionsCellItem
								icon={<EditIcon />}
								label="Editar"
								color="primary"
							/>
						</Link>
						<GridActionsCellItem
							icon={<DeleteIcon />}
							label="Excluir"
							color="error"
							onClick={handleDelete}
						/>
						<GridActionsCellItem
							icon={<InfoIcon />}
							label="Detalhes"
							color="default"
							onClick={() => onDetailsClick(
								params.row.license,
								params.row.accessGroups
									.reduce((
										acc: number,
										curr: { _count: { users: number } },
									// eslint-disable-next-line no-underscore-dangle
									) => acc + curr._count.users, 0),
							)}
						/>
					</>
				);
			},
		},
	], [handleDeleteLicense, onDetailsClick]);

	return (
		<Box component={Paper} sx={{ width: '100%' }}>
			{
				rows && (
					<DataGrid
						autoHeight
						rows={rows}
						pagination
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[10]}
						loading={loading}
						disableSelectionOnClick
					/>
				)
			}
		</Box>
	);
};

LicenseTable.defaultProps = {
	loading: false,
};

export default LicenseTable;
