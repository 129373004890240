import { PaymentMethod } from '../enums/PaymentMethod';

export const PaymentMethodTypeLabels = {
	[PaymentMethod.DOC]: 'DOC',
	[PaymentMethod.TED]: 'TED',
	[PaymentMethod.PIX]: 'PIX',
	[PaymentMethod.BOLETO]: 'Boleto',
	[PaymentMethod.TRANSFER]: 'Transferência',
	[PaymentMethod.CHECK]: 'Cheque',
	[PaymentMethod.CASH]: 'Dinheiro',
};
