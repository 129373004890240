import React, {
	Dispatch,
	Reducer,
	useContext,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import CompanyBranchModal from '../../components/CompanyBranchModal/CompanyBranchModal';
import { ICompany, ICompanyWithoutDetails } from '../Company/CompanyAssets';
import { IBranch } from '../Branch/BranchAssets';
import { getUserBranchesByCompany, getUserById, getUserCompanies } from '../../services/user';
import { User } from '../User/UserAssets';
import { normalizeUserBranches } from '../../helpers/normalizeUserBranches';
import {
	ChangeBranchPreference, setBranch,
	setBranchId,
	setBranchName,
	setCompanyId,
	setToken,
} from '../../services/auth';
import { BranchContext } from '../../contexts/BranchContext';

enum ActionType {
	LOADING,
	COMPANY,
	BRANCH,
	SELECTED_COMPANY,
	SELECTED_BRANCH,
	USER,
}

interface IState {
	loading: boolean;
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	selectedCompany: ICompany | null;
	selectedBranch: IBranch | null;
	count: number;
	user?: User;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.COMPANY; payload: { companies: ICompanyWithoutDetails[]; count: number } }
	| { type: ActionType.BRANCH; payload: { branches: IBranch[]; count: number } }
	| { type: ActionType.SELECTED_COMPANY; payload: { selectedCompany: ICompany | null } }
	| { type: ActionType.SELECTED_BRANCH; payload: { selectedBranch: IBranch | null } }
	| { type: ActionType.USER; payload: { user: User } };

interface ICompanyBranchActions {
	setLoading(loading: boolean): void;
	getCompanies(): void;
	getBranches(companyId: string): void;
	handleCompanyChange(company: ICompany | null): void;
	handleBranchChange(branch: IBranch | null): void;
	getUserById(id: string): void;
	setUser(user: User): void;
	setBranch(data: ChangeBranchPreference): void;
}

const initialState: IState = {
	loading: false,
	companies: [],
	branches: [],
	selectedCompany: null,
	selectedBranch: null,
	count: 0,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.COMPANY:
			return {
				...state,
				companies: action.payload.companies,
				count: action.payload.count,
			};
		case ActionType.BRANCH:
			return {
				...state,
				branches: action.payload.branches,
				count: action.payload.count,
			};
		case ActionType.SELECTED_COMPANY:
			return {
				...state,
				selectedCompany: action.payload.selectedCompany,
				selectedBranch: null,
				branches: [],
			};
		case ActionType.SELECTED_BRANCH:
			return { ...state, selectedBranch: action.payload.selectedBranch };
		case ActionType.USER:
			return { ...state, user: action.payload.user };
		default:
			throw new Error();
	}
};

const CompanyBranchActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	handleClose: () => void,
	updateBranchId: (branchId: string) => void,
): ICompanyBranchActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getCompanies() {
			actions.setLoading(true);
			getUserCompanies().then((response) => {
				dispatch({
					type: ActionType.COMPANY,
					payload: {
						companies: response.data.data,
						count: response.data.count,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar empresas.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		getUserById(id: string) {
			actions.setLoading(true);
			getUserById(id).then((response) => {
				actions.setUser(response.data);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar usuário.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		getBranches(companyId: string) {
			actions.setLoading(true);
			getUserBranchesByCompany(companyId).then((response) => {
				dispatch({
					type: ActionType.BRANCH,
					payload: {
						branches: response.data.data,
						count: response.data.count,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar filiais.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		setBranch(data: ChangeBranchPreference) {
			setBranch(data).then((response) => {
				setToken(response.data.token);
				setBranchName(response.data.branchName);
				setCompanyId(response.data.companyId);
				setBranchId(response.data.branchId);
				updateBranchId(data.branchId);
				handleClose();
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao mudar de filial.', {
					variant: 'error',
				});
			});
		},
		handleCompanyChange(company: ICompany | null) {
			dispatch({
				type: ActionType.SELECTED_COMPANY,
				payload: { selectedCompany: company },
			});
			if (company) {
				actions.getBranches(company.id);
			}
		},
		handleBranchChange(branch: IBranch | null) {
			dispatch({
				type: ActionType.SELECTED_BRANCH,
				payload: { selectedBranch: branch },
			});
		},
		setUser(user: User) {
			const normalizedUser = normalizeUserBranches(user);
			dispatch({ type: ActionType.USER, payload: { user: normalizedUser } });
		},
	};

	return actions;
};

interface CompanyBranchContainerProps {
	open: boolean;
	handleClose: () => void;
}

const CompanyBranchContainer: React.FC<CompanyBranchContainerProps> = ({ open, handleClose }) => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const { updateBranchId } = useContext(BranchContext);
	const actions = useMemo(
		() => CompanyBranchActions(dispatch, enqueueSnackbar, handleClose, updateBranchId),
		[enqueueSnackbar, handleClose, updateBranchId],
	);

	return (
		<CompanyBranchModal
			open={open}
			handleClose={handleClose}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...state}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...actions}
		/>
	);
};

export default CompanyBranchContainer;
