import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import {
	FormControlLabel,
	FormControl,
	FormHelperText,
	Switch,
} from '@mui/material';
import { useField } from 'formik';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
	label: string;
	disabled?: boolean;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SwitchField = ({
	name,
	label,
	disabled,
	onChange,
	...props
}: Props): JSX.Element => {
	const [field, meta, helpers] = useField(name);

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		helpers.setValue(event.target.checked);
		onChange?.(event);
	};

	return (
		<FormControl>
			<FormControlLabel
				control={(
					<Switch
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...field}
						aria-label={props['aria-label']}
						checked={field.value}
						onChange={handleChange}
						disabled={disabled}
					/>
				)}
				label={label}
			/>
			<FormHelperText error={Boolean(meta.touched) && Boolean(meta.error)}>
				{Boolean(meta.error) && meta.error}
			</FormHelperText>
		</FormControl>
	);
};

SwitchField.defaultProps = {
	onChange: null,
	disabled: false,
};

export default React.memo(SwitchField);
