/* eslint-disable */
import '../../../helpers/yupMethods';
import * as Yup from 'yup';
import { validationMessage } from '../../../helpers/yupMessages';

interface Client {
	company: string;
	companyContact: string;
	companyPhone: string;
}

interface Commercial {
	emailXMLDanfe: string;
	minimumBilling: number;
	paymentTerms: string;
	shipping: string; // Verificar
}

interface Company {
	address: string;
	cep: string;
	city: string;
	cityRegistration: string; // Verificar
	cnpj: number;
	companyName: string;
	district: string;
	fantasyName: string;
	phone: string;
	stateRegistration: string; // Verificar
	taxRegime: string; // Verificar
	uf: string;
}

interface Contact {
	commercial: string;
	commercialEmail: string;
	commercialPhone: string;
	financial: string;
	financialEmail: string;
	financialPhone: string;
	representative: string;
	representativeEmail: string;
	representativePhone: string;
}

interface FinancialData {
	bank: string;
	agencyNumber: number;
	accountNumber: number;
}

interface Financial {
	data: FinancialData[];
	paymentMethod: string; // Verificar
}

export interface HandleSubmitData {
	clients: Client[];
	commercial: Commercial;
	company: Company;
	contacts: Contact;
	financial: Financial;
}

export const taxRegimeOptions = [
	{ id: 'realProfit', value: '0', label: 'Lucro Real' },
	{ id: 'presumedProfit', value: '1', label: 'Lucro Presumido' },
	{ id: 'simpleNational', value: '2', label: 'Simples Nacional' },
];

export const paymentMethodOptions = [
	{ id: 'boleto', value: '0', label: 'Boleto' },
	{ id: 'transferency', value: '1', label: 'Transferência' },
];

export const shippingOptions = [
	{ id: 'cif', value: '0', label: 'CIF' },
	{ id: 'fob', value: '1', label: 'FOB' },
];

export const urlRegex = new RegExp(
	/^((https?|ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
);

export const initialValues = {
	companyName: '',
	fantasyName: '',
	cep: '',
	address: '',
	number: '',
	district: '',
	city: '',
	uf: '',
	cnpj: '',
	stateRegistration: '',
	cityRegistration: '',
	taxRegime: '',
	phone: '',
	representativeName: '',
	representativePhone: '',
	representativeEmail: '',
	commercialName: '',
	commercialPhone: '',
	commercialEmail: '',
	financialName: '',
	financialPhone: '',
	financialEmail: '',
	clients: [{ company: '', companyContact: '', companyPhone: '' }],
	financials: [
		{
			bank: '', agencyNumber: '', accountNumber: '', opCaixaEcon: '',
		},
	],
	paymentMethod: '',
	minimumBilling: '',
	paymentTerms: '',
	shipping: '',
	emailXMLDanfe: '',
	website: '',
};

export interface ProviderClient {
	companyName: string;
	fantasyName: string;
	cep: string;
	address: string;
	number: string;
	district: string;
	city: string;
	uf: string;
	cnpj: string;
	stateRegistration: string;
	cityRegistration: string;
	taxRegime: string;
	phone: string;
	representativeName: string;
	representativePhone: string;
	representativeEmail: string;
	commercialName: string;
	commercialPhone: string;
	commercialEmail: string;
	financialName: string;
	financialPhone: string;
	financialEmail: string;
	clients: {
		company: string;
		companyContact: string;
		companyPhone: string;
	}[];
	financials: {
		bank: string;
		agencyNumber: string;
		accountNumber: string;
		opCaixaEcon: string;
	}[];
	paymentMethod: string;
	minimumBilling: string;
	paymentTerms: string;
	shipping: string;
	emailXMLDanfe: string;
	website: string;
}

export const getValidationSchema = () => {

	return Yup.object().shape({
		address: Yup.string().required(validationMessage.required),
		cep: Yup.string().required(validationMessage.required),
		city: Yup.string().required(validationMessage.required),
		cityRegistration: Yup.number()
			.notRequired()
			.typeError(validationMessage.number.typeError),
		cnpj: (Yup.string() as any)
			.cpf()
			.cnpj()
			.required(validationMessage.required),
		companyName: Yup.string().required(validationMessage.required),
		district: Yup.string().required(validationMessage.required),
		fantasyName: Yup.string()
			.notRequired()
			.typeError(validationMessage.number.typeError),
		number: Yup.number()
			.required(validationMessage.required)
			.typeError(validationMessage.number.typeError),
		phone: Yup.string()
			.min(10, validationMessage.string.min(15))
			.max(16, validationMessage.string.max(16))
			.required(validationMessage.required),
		stateRegistration: Yup.number()
			.notRequired()
			.typeError(validationMessage.number.typeError),
		taxRegime: Yup.string().max(1).required(validationMessage.required),
		uf: Yup.string().max(2).required(validationMessage.required),
		commercialName: Yup.string().required(validationMessage.required),
		commercialEmail: Yup.string()
			.email(validationMessage.string.email)
			.required(validationMessage.required),
		commercialPhone: Yup.string()
			.min(10, validationMessage.string.min(15))
			.max(16, validationMessage.string.max(16))
			.required(validationMessage.required),
		financialName: Yup.string().required(validationMessage.required),
		financialEmail: Yup.string()
			.email(validationMessage.string.email)
			.required(validationMessage.required),
		financialPhone: Yup.string()
			.min(10, validationMessage.string.min(15))
			.max(16, validationMessage.string.max(16))
			.required(validationMessage.required),
		representativeName: Yup.string().required(validationMessage.required),
		representativeEmail: Yup.string()
			.email(validationMessage.string.email)
			.required(validationMessage.required),
		representativePhone: Yup.string()
			.min(10, validationMessage.string.min(15))
			.max(16, validationMessage.string.max(16))
			.required(validationMessage.required),
		clients: Yup.array().of(
			Yup.object().shape({
				company: Yup.string().required(validationMessage.required),
				companyContact: Yup.string().required(
					validationMessage.required,
				),
				companyPhone: Yup.string()
					.min(10, validationMessage.string.min(15))
					.max(16, validationMessage.string.max(16))
					.required(validationMessage.required),
			}),
		),
		financials: Yup.array().of(
			Yup.object().shape({
				bank: Yup.string().required(validationMessage.required),
				agencyNumber: Yup.number()
					.required(validationMessage.required)
					.typeError(validationMessage.number.typeError),
				accountNumber: Yup.number()
					.required(validationMessage.required)
					.typeError(validationMessage.number.typeError),
				opCaixaEcon: Yup.number()
					.notRequired()
					.typeError(validationMessage.number.typeError),
			}),
		),
		paymentMethod: Yup.string().required(validationMessage.required),
		minimumBilling: Yup.number()
			.min(1)
			.required(validationMessage.required),
		paymentTerms: Yup.string().required(validationMessage.required),
		shipping: Yup.string().max(1).required(validationMessage.required),
		emailXMLDanfe: Yup.string()
			.email(validationMessage.string.email)
			.required(validationMessage.required),
		website: Yup.string()
			.matches(urlRegex, { message: validationMessage.string.url })
			.notRequired(),
	});
};
