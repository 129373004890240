/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import { Box, Grid } from '@mui/material';
import {
	ILocationInfo,
	ILocationType,
	OperationType,
	validationSchemaLocation,
} from '../../../containers/Location/LocationAssets';
import Input from '../../Common/Form/Input';
import Autocomplete from '../../Common/Form/Autocomplete';
import { formatBarCode } from '../../../helpers/masks';
import { ICompanyWithoutDetails } from '../../../containers/Company/CompanyAssets';
import { IBranch } from '../../../containers/Branch/BranchAssets';
import { BranchParams } from '../../../containers/Inventory/InventoryAssets';
import { BRANCH_ID_KEY, COMPANY_ID_KEY } from '../../../services/auth';

interface LocationModalProps {
  open: boolean;
  loading: boolean;
  initialValues: ILocationInfo;
  locationTypes: Array<ILocationType>;
  operationType: OperationType;
  onClose: (open: boolean) => void;
  onSubmit: (locationData: ILocationInfo) => void;
  companies: ICompanyWithoutDetails[];
  branches: IBranch[];
  getCompanies: () => void;
  getBranches: (params: BranchParams) => void;
}

const LocationModal = ({
	open,
	initialValues,
	loading,
	locationTypes,
	operationType,
	onSubmit,
	onClose,
	companies,
	getCompanies,
	branches,
	getBranches,
}: LocationModalProps): JSX.Element => {
	const companyId = localStorage.getItem(COMPANY_ID_KEY);
	const branchId = localStorage.getItem(BRANCH_ID_KEY);

	const formik = useFormik({
		initialValues: {
			...initialValues,
			companyId: companyId || '',
			branchId: branchId || '',
		},
		onSubmit: (values) => {
			onSubmit(values);
			formik.resetForm();
		},
		validationSchema: validationSchemaLocation,
		validateOnChange: true,
		validateOnBlur: false,
		validateOnMount: true,
	});

	useEffect(() => {
		getCompanies();
	}, [getCompanies]);

	useEffect(() => {
		if (formik.values.companyId) {
			getBranches({
				companyId: formik.values.companyId,
			});
		}
	}, [formik.values.companyId, getBranches]);

	const buildValueBarCode = useCallback(
		(locationTypeId: string | null): string => {
			let barCodeValue = '';

			const locationTypeValue = locationTypes.find(
				(locationType) => locationType.id === locationTypeId,
			);

			if (locationTypeValue) {
				barCodeValue = locationTypeValue.prefix;
			}

			return barCodeValue;
		},
		[locationTypes],
	);

	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<FormikContext.Provider value={formik}>
				<Form noValidate>
					<DialogTitle>
						{`${
							operationType === OperationType.NEW ? 'Criar' : 'Editar'
						} Localização`}
					</DialogTitle>
					<DialogContent dividers>
						<Typography variant="body2" color="textSecondary" mb={2}>
							*Obrigatório
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} md={12} sm={12}>
								<Autocomplete
									label="Empresa"
									labelKey="name"
									valueKey="id"
									valueLabel="code"
									name="companyId"
									options={companies || []}
									fullWidth
									readOnly
									closeOnSelect
									required
								/>
							</Grid>
							<Grid item xs={12} md={12} sm={12}>
								<Autocomplete
									label="Filial"
									labelKey="name"
									valueKey="id"
									valueLabel="code"
									disabled={!formik.values.companyId}
									name="branchId"
									options={branches || []}
									fullWidth
									readOnly
									closeOnSelect
									required
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Input.InputField
									label="Nome da Localização"
									name="name"
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12} md={12} sm={12}>
								<Autocomplete
									label="Tipo de Localização"
									valueLabel="level"
									valueKey="id"
									labelKey="name"
									disabled={operationType === OperationType.EDITING}
									name="locationTypeId"
									options={locationTypes || []}
									fullWidth
									isOptionEqualToValue={(option: string, value: string) => option === value}
									onChange={(event, newValue) => {
										formik.setFieldValue('locationTypeId', newValue || '');
										const barCodeInitial = buildValueBarCode(newValue);
										formik.setFieldValue('barCodeInitial', barCodeInitial);
									}}
									closeOnSelect
									required
								/>
							</Grid>
							{operationType === OperationType.NEW ? (
								<>
									<Grid item xs={12} sm={12} md={6}>
										<Typography variant="subtitle1" color="#00000099">
											Código de Barras
										</Typography>
										<Typography variant="subtitle1">
											{formatBarCode(formik.values.barCodeInitial || '') || '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<Input.InputField
											label="Composição do Código de Barras"
											name="barCodeNumber"
											fullWidth
											required
										/>
									</Grid>
								</>
							) : (
								<Grid item xs={12} sm={12} md={12}>
									<TextField
										label="Código de Barras"
										name="barCode"
										disabled
										value={formatBarCode(formik.values.barCode)}
										fullWidth
										required
									/>
								</Grid>
							)}
						</Grid>
					</DialogContent>
					<DialogActions sx={{ gap: 1 }}>
						<Button onClick={() => {
							formik.resetForm();
							onClose(false);
						}}
						>
							Cancelar
						</Button>
						<LoadingButton
							type="submit"
							variant="contained"
							loading={loading}
							disabled={!formik.isValid}
						>
							Salvar
						</LoadingButton>
					</DialogActions>
				</Form>
			</FormikContext.Provider>
		</Dialog>
	);
};

export default LocationModal;
