import React, { useEffect, useState } from 'react';
import {
	Box,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormikContext } from 'formik';
import {
	cepMask,
	phoneMask,
	cpfMask,
	cnpjMask,
} from '../../../helpers/masks';
import RadioField from '../../Common/Form/Radio';
import Input from '../../Common/Form/Input';
import Autocomplete from '../../Common/Form/Autocomplete';
import { ICountry } from '../../../services/country';
import { IRegionState } from '../../../services/state';
import { ICity } from '../../../services/city';
import { useConditionalSearch } from '../../../hooks/useConditionalSearchProps';
import {
	legalEntityOptions,
	creditRiskTypeOptions,
	subtypeOptions,
	IBusinessPartner,
} from '../../../containers/BusinessPartner/BusinessPartnerAssets';
import { DatePicker } from '../../Common/Form/DatePicker';
import { LegalEntityType } from '../../../enums/LegalEntityType';
import { BusinessPartnerType } from '../../../enums/BusinessPartnerType';

interface BusinessPartnerFormProps {
	countries: ICountry[];
	states: IRegionState[];
	cities: ICity[];
	getCountries(searchQuery?: string): void;
	getCities(searchQuery?: string): void;
	handleCepBlur(cep: string, unitIndex?: number): void;
	index: number;
	removeUnit: (index: number) => void;
}

const UnitBPForm = ({
	countries,
	states,
	cities,
	getCities,
	getCountries,
	handleCepBlur,
	index,
	removeUnit,
}: BusinessPartnerFormProps): JSX.Element => {
	const { values, handleBlur } = useFormikContext<IBusinessPartner>();
	const [countryInputValue, setCountryInputValue] = useState('');
	const [cityInputValue, setCityInputValue] = useState('');

	useConditionalSearch({
		inputValue: countryInputValue,
		loadedItems: countries,
		searchFunction: getCountries,
		minLength: 2,
		compareKey: 'name',
	});

	useConditionalSearch({
		inputValue: cityInputValue,
		loadedItems: cities,
		searchFunction: getCities,
		minLength: 3,
		compareKey: 'name',
	});

	return (
		<Box mb={2} p={2} border={1} borderRadius={1} borderColor="grey.300">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h6">
						Unidade
						{' '}
						{index + 1}
						<IconButton onClick={() => removeUnit(index)} size="small" sx={{ ml: 2 }}>
							<DeleteIcon />
						</IconButton>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<RadioField
						name={`units[${index}].subtype`}
						label="Tipo da Unidade"
						options={subtypeOptions}
						orientation="row"
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<RadioField
						name={`units[${index}].legalEntity`}
						label="Entidade Legal"
						options={legalEntityOptions}
						orientation="row"
						required
					/>
				</Grid>
				<>
					<Grid item xs={6}>
						<Input.InputField
							id={`units[${index}].name`}
							name={`units[${index}].name`}
							label="Razão Social"
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<Input.InputField
							id={`units[${index}].marketName`}
							name={`units[${index}].marketName`}
							label="Nome de Mercado"
							required
						/>
					</Grid>
					{values.units[index].legalEntity === LegalEntityType.PHYSICAL && (
						<Grid item xs={2}>
							<Input.InputMaskField
								id={`units[${index}].taxId`}
								name={`units[${index}].taxId`}
								label="CPF"
								mask={cpfMask}
								onBlur={handleBlur}
								required
							/>
						</Grid>
					)}
					{values.units[index].legalEntity === LegalEntityType.LEGAL && (
						<Grid item xs={2}>
							<Input.InputMaskField
								id={`units[${index}].taxId`}
								name={`units[${index}].taxId`}
								label="CNPJ"
								mask={cnpjMask}
								onBlur={handleBlur}
								required
							/>
						</Grid>
					)}
					{values.units[index].legalEntity === LegalEntityType.FOREIGNER && (
						<Grid item xs={2}>
							<Input.InputField
								id={`units[${index}].taxId`}
								name={`units[${index}].taxId`}
								label="TAXID"
								required
							/>
						</Grid>
					)}
					<Grid item xs={2}>
						<Autocomplete
							name={`units[${index}].country`}
							label="País"
							options={countries}
							labelKey="id"
							valueKey="name"
							onInputChange={(event, newInputValue) => setCountryInputValue(newInputValue)}
							required
						/>
					</Grid>
					<Grid item xs={2}>
						<Input.InputMaskField
							id={`units[${index}].zipCode`}
							name={`units[${index}].zipCode`}
							label="CEP"
							mask={cepMask}
							onBlur={(e) => handleCepBlur(e.target.value, index)}
							required
						/>
					</Grid>
					<Grid item xs={4}>
						<Input.InputField
							id={`units[${index}].address`}
							name={`units[${index}].address`}
							label="Logradouro"
							required
						/>
					</Grid>
					<Grid item xs={2}>
						<Input.InputField
							id={`units[${index}].number`}
							name={`units[${index}].number`}
							label="Número"
							required
						/>
					</Grid>
					<Grid item xs={2}>
						<Input.InputField
							id={`units[${index}].addressOne`}
							name={`units[${index}].addressOne`}
							label="Complemento"
						/>
					</Grid>
					<Grid item xs={2}>
						<Input.InputField
							id={`units[${index}].district`}
							name={`units[${index}].district`}
							label="Bairro"
							required
						/>
					</Grid>
					<Grid item xs={2}>
						<Autocomplete
							name={`units[${index}].state`}
							label="Estado"
							options={states}
							labelKey="abbrev"
							valueKey="name"
							required
						/>
					</Grid>
					<Grid item xs={2}>
						<Autocomplete
							name={`units[${index}].city`}
							label="Cidade"
							options={cities}
							labelKey="id"
							valueKey="name"
							onInputChange={(event, newInputValue) => setCityInputValue(newInputValue)}
							required
						/>
					</Grid>
					<Grid item xs={2}>
						<Input.InputMaskField
							id={`units[${index}].phone`}
							name={`units[${index}].phone`}
							label="Telefone"
							mask={phoneMask}
							onBlur={handleBlur}
							required
						/>
					</Grid>
					<Grid item xs={2}>
						<Input.InputField
							id={`units[${index}].email`}
							name={`units[${index}].email`}
							label="E-mail"
							required
						/>
					</Grid>
					<Grid item xs={2}>
						<Input.InputField
							id={`units[${index}].invoiceMail`}
							name={`units[${index}].invoiceMail`}
							label="E-mail fiscal"
						/>
					</Grid>
					<Grid item xs={2}>
						<Input.InputField
							id={`units[${index}].website`}
							name={`units[${index}].website`}
							label="Site"
						/>
					</Grid>
					<Grid item xs={2}>
						<Input.InputField
							id={`units[${index}].stateTaxId`}
							name={`units[${index}].stateTaxId`}
							label="Inscrição Estadual"
						/>
					</Grid>
					<Grid item xs={2}>
						<Input.InputField
							id={`units[${index}].cityTaxId`}
							name={`units[${index}].cityTaxId`}
							label="Inscrição Municipal"
						/>
					</Grid>
					{!values.groupCredit && values.type === BusinessPartnerType.CLIENT && (
						<>
							<Grid item xs={2}>
								<Input.InputField
									id={`units[${index}].creditValue`}
									name={`units[${index}].creditValue`}
									label="Valor de Crédito"
									type="number"
									required
								/>
							</Grid>
							<Grid item xs={2}>
								<DatePicker name={`units[${index}].creditDate`} label="Data de Crédito" required />
							</Grid>
							<Grid item xs={12}>
								<RadioField
									name={`units[${index}].creditRisk`}
									label="Risco de Crédito"
									options={creditRiskTypeOptions}
									orientation="row"
									required
								/>
							</Grid>
						</>
					)}
				</>
			</Grid>
		</Box>
	);
};

export default UnitBPForm;
