import React, { useEffect, useMemo } from 'react';
import {
	Box,
	Paper,
	Stack,
	Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { PageHeaderButtonProps } from '../../../interfaces/PageHeaderInterface';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import { InventorySummaryParams } from '../../../interfaces/InventoryParams';
import useDataGridManagement from '../../../hooks/useDataGridManagement';
import { formatBarCode } from '../../../helpers/masks';
import { IInventoryInfoReport, IInventorySummary } from '../../../containers/Inventory/InventoryAssets';
import { formatUTCDateToLocal } from '../../../helpers/DateUtils';
import TruncateTooltipCell from '../../Common/Datagrid/TruncateTooltipCell';

interface InventorySummaryProps {
	loading: boolean,
	inventorySummaryItems: IInventorySummary[],
	inventorySummaryCount: number,
	inventorySummaryPage: number,
	inventorySummaryTake: number,
	getInventorySummaryById: (id: string, queryParams: InventorySummaryParams) => void,
	reportSummaryPDF: (id: string) => void
	inventoryInfo: IInventoryInfoReport
}

const InventorySummaryReport = ({
	inventorySummaryItems,
	loading,
	inventorySummaryCount,
	inventorySummaryPage,
	inventorySummaryTake,
	getInventorySummaryById,
	reportSummaryPDF,
	inventoryInfo,
}:InventorySummaryProps): JSX.Element => {
	const { id } = useParams();

	const columns: GridColDef<(typeof inventorySummaryItems)[number]>[] = [
		{
			field: 'locationCode',
			headerName: 'Localização',
			flex: 0.8,
			valueFormatter: (params) => formatBarCode(params.value),

		},
		{
			field: 'product',
			headerName: 'Produto',
			flex: 1,
			renderCell: (params: GridRenderCellParams<string>) => (
				<TruncateTooltipCell value={`${params.row.productCode} - ${params.row.productDescription}`} />
			),
		},
		{
			field: 'previousQuantity',
			headerName: 'Disponível',
			flex: 0.5,
		},
		{
			field: 'countNumber1',
			headerName: '1ª Contagem',
			flex: 0.5,
		},
		{
			field: 'countNumber2',
			headerName: '2ª Contagem',
			flex: 0.5,
		},
		{
			field: 'countNumber3',
			headerName: '3ª Contagem',
			flex: 0.5,
		},
		{
			field: 'countNumber4',
			headerName: '4ª Contagem',
			flex: 0.5,
		},
	];

	const headerButtonsProps = useMemo(
		(): PageHeaderButtonProps[] => [
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				onClick: () => {
					if (id) {
						reportSummaryPDF(id);
					}
				},
				text: 'Imprimir',
			},
		],
		[id, reportSummaryPDF],
	);

	const {
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<InventorySummaryParams>({
		initialPageSize: inventorySummaryTake,
		initialPage: inventorySummaryPage,
		fetchData: (params) => {
			if (id) {
				return getInventorySummaryById(id, params);
			}
			return Promise.resolve(null);
		},
	});

	return (
		<Box>
			<PageHeader
				title="Relatório de resumo do inventário"
				buttons={headerButtonsProps}
			/>

			<Box component={Paper} p={2}>
				<Box mb={2}>
					<Stack direction="row" spacing={1}>
						<Typography fontWeight="bold">Número do Inventário:</Typography>
						<Typography>{inventoryInfo.document}</Typography>
					</Stack>
					<Stack direction="row" spacing={1}>
						<Typography fontWeight="bold">Data:</Typography>
						<Typography>{inventoryInfo.date ? formatUTCDateToLocal(inventoryInfo.date, 'dd/MM/yyyy') : ''}</Typography>
					</Stack>
					<Stack direction="row" spacing={1}>
						<Typography fontWeight="bold">Responsável:</Typography>
						<Typography>{inventoryInfo.responsibleName}</Typography>
					</Stack>
				</Box>
				<DataGrid
					rows={inventorySummaryItems}
					columns={columns}
					autoHeight
					loading={loading}
					rowCount={inventorySummaryCount}
					pagination
					paginationMode="server"
					sortingMode="server"
					rowsPerPageOptions={[10, 25, 50, 100]}
					pageSize={inventorySummaryTake}
					page={inventorySummaryPage}
					onPageChange={onChangePage}
					onPageSizeChange={onChangePageSize}
					onSortModelChange={onSortModelChange}
					sortModel={sortModel}
				/>
			</Box>
		</Box>
	);
};

export default InventorySummaryReport;
