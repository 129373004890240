import { AxiosResponse } from 'axios';
import api from './api';
import { IMessageResponse } from '../interfaces/MessageResponse';
import { ITask } from '../containers/Mobile/TaskAssets';
import { InventoryTaskStatus } from '../enums/InventoryTaskStatus';

export type SaveTaskType = {
    taskId: string;
    quantity: number;
}

export type ResponseTasksOrigin = {
    data: ITask[];
    count: number;
}

export const getLocationOrigin = (barCode: string, status: InventoryTaskStatus): Promise<AxiosResponse<ResponseTasksOrigin>> => api.get<ResponseTasksOrigin>(`/inventory-task/location-origin/${barCode}/${status}`);

export const saveTaskCount = (data: SaveTaskType): Promise<AxiosResponse<IMessageResponse>> => api.post<SaveTaskType, AxiosResponse<IMessageResponse>>('/inventory-task/taskCount', data);

export const saveTakeTask = (data: SaveTaskType): Promise<AxiosResponse<IMessageResponse>> => api.post<SaveTaskType, AxiosResponse<IMessageResponse>>('/inventory-task/takeTask/', data);
