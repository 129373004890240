import * as Yup from 'yup';
import { validationMessage } from '../../helpers/yupMessages';
import { ILocationType } from '../Location/LocationAssets';

export const initialValues: ILocationType = {
	id: undefined,
	name: '',
	prefix: '',
	level: '',
	canEditPrefix: true,
	companyId: '',
	branchId: '',
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const validationSchema = () => Yup.object().shape({
	id: Yup.string().nullable(),
	canEditPrefix: Yup.boolean(),
	name: Yup.string().required(validationMessage.required),
	prefix: Yup.string().required(validationMessage.required),
	level: Yup.number()
		.min(0, validationMessage.number.min(0))
		.required(validationMessage.required),
});
