import { AxiosResponse } from 'axios';
import api from './api';
import { IFilterField } from '../interfaces/BudgetOrderFourFields';
import {
	IProducts,
	IProduct,
	IMeasures,
	IProductTypes,
	IProductsWithoutDetails,
	ILocationProductData,
} from '../containers/Product/ProductAssets';
import {
	ProductQueryParams,
} from '../interfaces/ProductQueryParams';

export const getProducts = (
	params?: ProductQueryParams,
): Promise<AxiosResponse<IProducts>> => api.get<IProducts>('/product', { params });

export const getProductsWithoutDetails = (
	params?: ProductQueryParams,
): Promise<AxiosResponse<IProductsWithoutDetails>> => api.get<IProductsWithoutDetails>('/product/withoutDetails', { params });

export const getProductById = (id: string): Promise<AxiosResponse<IProduct>> => api.get<IProduct>(`/product/${id}`);

export const createProduct = (
	data: FormData,
): Promise<AxiosResponse<{ message: string }>> => api.post<FormData, AxiosResponse>('/product', data, {
	headers: { 'Content-Type': 'multipart/form-data' },
});

export const createBatchProducts = (
	products: FormData[],
): Promise<AxiosResponse<{ message: string }>> => api.post<FormData[], AxiosResponse>('/product/batch', products, {
	headers: { 'Content-Type': 'multipart/form-data' },
});

export const updateProduct = (
	id: string,
	data: FormData,
): Promise<AxiosResponse> => api.patch(`/product/${id}`, data, {
	headers: { 'Content-Type': 'multipart/form-data' },
});

export const deleteProduct = (id: string): Promise<AxiosResponse<any>> => api.delete<any>(`/product/${id}`);

export const getProductLocationData = (
	id: string,
): Promise<AxiosResponse<ILocationProductData[]>> => api.get<ILocationProductData[]>(`/product/${id}/locations`);

export const getAccountingAccounts = (): Promise<
  AxiosResponse<IFilterField[]>
> => api.get('/budget/accounting-accounts');

export const getMeasures = (): Promise<AxiosResponse<IMeasures>> => api.get<IMeasures>('/measure');

export const getProductTypes = (): Promise<AxiosResponse<IProductTypes>> => api.get<IProductTypes>('/product-type');
