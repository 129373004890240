/* eslint-disable react/jsx-props-no-spreading */
import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { IInventory, InventoryQueryParams } from '../../Inventory/InventoryAssets';
import { getInventories } from '../../../services/inventory';
import InventoryPresentational from '../../../components/Mobile/Inventory/Inventory';

enum ActionType {
	LOADING,
	INVENTORY,
}

interface IState {
	loading: boolean;
	inventories: IInventory[];
	count: number;
	inventoryCount: number;
	inventoryPage: number;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.INVENTORY; payload: {
		inventories: IInventory[];
		inventoryCount: number;
		inventoryPage: number
	}}

interface IInventoryActions {
	setLoading(loading: boolean): void;
	getInventories(params: Partial<InventoryQueryParams>): void;
}

const initialState: IState = {
	loading: false,
	inventories: [],
	count: 0,
	inventoryPage: 0,
	inventoryCount: 0,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.INVENTORY:
			return {
				...state,
				inventories: action.payload.inventories,
				inventoryPage: action.payload.inventoryPage,
				inventoryCount: action.payload.inventoryCount,
			};
		default:
			throw new Error();
	}
};

const InventoryActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IInventoryActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getInventories(queryParams: InventoryQueryParams) {
			actions.setLoading(true);
			const params = {
				...queryParams,
			};
			getInventories(params).then((response) => {
				dispatch({
					type: ActionType.INVENTORY,
					payload: {
						inventories: response.data.inventorys,
						inventoryPage: queryParams.skip ?? 0,
						inventoryCount: response.data.count,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
	};

	return actions;
};

const Inventory = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();

	const actions = useMemo(
		() => InventoryActions(dispatch, enqueueSnackbar),
		[enqueueSnackbar],
	);

	return (
		<InventoryPresentational
			{...state}
			{...actions}
		/>
	);
};

export default Inventory;
