import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { UserType } from '../../../containers/Order/PurchaseOrderApproval';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import { Loading } from '../../Common/Loading';
import Pagination from '../../Common/Pagination';
import { ManagePurchaseOrders } from '../../../interfaces/ManagePurchaseOrders';
import ManagePurchaseOrdersTable from './ManagePurchaseOrdersTable';
import ManagePurchaseOrdersFilter from './ManagePurchaseOrdersFilter';
import ApportionmentDrawer from '../Drawers/ApportionmentDrawer';
import DetailsDrawer from '../Drawers/DetailsDrawer';
import { UserTypeSelector } from '../UserManagement/UserTypeSelector';
import { IBudget, IPurchaseOrderApproval, IPurchasesWithJustificative } from '../../../interfaces/PurchaseOrderApproval';
import { IFilterFieldsValues } from '../../../interfaces/BudgetOrderFourFields';
import ExpandButton from '../../Common/ExpandButton';
import { UnauthorizedPage } from '../../Common/UnauthorizedPage';

const filters: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-start',
	gap: '1rem',
	mb: '1rem',
};

interface PurchaseOrderApprovalApprovedsProps {
	loading: boolean;
	loadingApportionments: boolean;
	userTypes: UserType[];
	purchasesPages: number;
	purchasesPage: number;
	purchasesTake: number;
	purchases: IBudget[];
	apportionments: any[];
	filterFieldsValues: IFilterFieldsValues | null;
	getAllPurchasesByBudget(queryParams: ManagePurchaseOrders): void;
	getApportionmentById(id: string): void;
	getUserTypes(): void;
	getFilterFieldsValues: (userType?: UserType) => void;
	reactivatePurchase(data: IPurchasesWithJustificative): void;
}

const PurchaseOrderApprovalApproveds = ({
	loading,
	userTypes,
	purchases,
	purchasesPages,
	purchasesPage,
	purchasesTake,
	apportionments,
	loadingApportionments,
	filterFieldsValues,
	getAllPurchasesByBudget,
	getApportionmentById,
	getUserTypes,
	getFilterFieldsValues,
	reactivatePurchase,
}: PurchaseOrderApprovalApprovedsProps): JSX.Element => {
	const [userType, setUserType] = useState(0);
	const [expandAccordions, setExpandAccordions] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [filter, setFilter] = useState({
		year: new Date().getFullYear(),
		initialMonth: new Date().getMonth(),
		finalMonth: new Date().getMonth(),
	});
	const [openApportionmentId, setOpenApportionmentId] = useState<string>('');
	const [openDetails, setOpenDetails] = useState<IPurchaseOrderApproval | undefined>(undefined);

	useEffect(() => {
		getAllPurchasesByBudget({
			...filter,
			skip: 1,
		});
		getFilterFieldsValues();
		getUserTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setUserType(Math.max(...userTypes));
	}, [userTypes]);

	const handleChangeUserType = useCallback((value: UserType) => {
		const userTypeFilter = { ...filter, type: value, skip: 1 };

		setUserType(value);
		getAllPurchasesByBudget(userTypeFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	const sendFilter = useCallback((values: any) => {
		setSelectAll(values.selectAll);

		const filterValues = {
			...values,
			type: userType,
			skip: 1,
		};
		delete filterValues.selectAll;

		getAllPurchasesByBudget(filterValues);
		setFilter(filterValues);
	}, [getAllPurchasesByBudget, userType]);

	const pageHeaderMemo = useMemo(() => <PageHeader title="Controle de Pedidos" />, []);
	const unauthorizedPageMemo = useMemo(() => <UnauthorizedPage />, []);

	const onApportionmentClick = useCallback((id: string) => {
		setOpenApportionmentId(id);
		getApportionmentById(id);
	}, [getApportionmentById]);

	const onCloseApportionment = useCallback(() => {
		setOpenApportionmentId('');
	}, []);

	const onDetailsClick = useCallback((id: string) => {
		const selectedOrder = purchases
			.flatMap((purchase) => purchase.purchaseOrderApprovals)
			.find((approval) => approval.id === id);

		setOpenDetails(selectedOrder);
	}, [purchases]);

	const onCloseDetails = useCallback(() => {
		setOpenDetails(undefined);
	}, []);

	const onChangePagination = useCallback((page, take) => {
		const pageFilter = {
			...filter, type: userType, skip: page, take,
		};

		getAllPurchasesByBudget(pageFilter);
	}, [getAllPurchasesByBudget, userType, filter]);

	const toggleExpand = useCallback(() => {
		setExpandAccordions(!expandAccordions);
	}, [expandAccordions]);

	const expandButton = useMemo(() => (
		<ExpandButton
			expandAccordions={expandAccordions}
			toggleExpand={toggleExpand}
		/>
	), [expandAccordions, toggleExpand]);

	return (
		userType !== UserType.RESPONSIBLE ? (
			<div className="content">
				{pageHeaderMemo}
				<Box sx={filters}>
					<UserTypeSelector
						userTypes={userTypes}
						userType={userType}
						onChange={handleChangeUserType}
					/>
					<ManagePurchaseOrdersFilter
						filterFieldsValues={filterFieldsValues}
						sendFilter={sendFilter}
					/>
				</Box>
				{loading && <Loading />}
				{!loading && (
					<ManagePurchaseOrdersTable
						rows={purchases}
						userType={userType}
						onApportionmentClick={onApportionmentClick}
						onDetailsClick={onDetailsClick}
						reactivatePurchase={reactivatePurchase}
						expandAccordions={expandAccordions}
						selectAllRows={selectAll}
					/>
				)}
				<Pagination
					loading={loading}
					count={purchasesPages}
					page={purchasesPage}
					take={purchasesTake}
					onChange={onChangePagination}
				/>
				<ApportionmentDrawer
					openId={openApportionmentId}
					apportionments={apportionments}
					loadingApportionments={loadingApportionments}
					onClose={onCloseApportionment}
				/>
				<DetailsDrawer
					openDetails={openDetails}
					onClose={onCloseDetails}
				/>
				{expandButton}
			</div>
		) : unauthorizedPageMemo
	);
};

export default PurchaseOrderApprovalApproveds;
