import React, { useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ErpClockInOutsMap } from '../../containers/HourProject/ClockHoursAssets';

const ERPClockHoursTable = (props: ErpClockInOutsMap): JSX.Element => {
	const { erpClockInOuts, erpClockInOutsCount } = props;
	const ins = useRef(0);
	const outs = useRef(0);

	return (
		<Table size="medium" aria-label="purchases" sx={{ border: 'none' }}>
			<TableHead>
				<TableRow sx={{ display: 'flex', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
					<TableCell width={110} sx={{ border: 'none' }}>Data</TableCell>
					{Array(erpClockInOutsCount)
						.fill('')
						.map((_, index) => {
							const appointment = Math.floor(index / 2) + 1;
							if (index % 2 === 0) {
								ins.current = 1;
								return (
									// eslint-disable-next-line react/no-array-index-key
									<TableCell key={index} align="right" width={90} sx={{ border: 'none' }}>
										{Array(ins.current)
											.fill('')
											.map(() => `${appointment}ª Ent.`)}
									</TableCell>
								);
							}
							outs.current = 1;
							return (
								// eslint-disable-next-line react/no-array-index-key,
								<TableCell key={index} align="left" width={90} sx={{ border: 'none' }}>
									{Array(outs.current)
										.fill('')
										.map(() => `${appointment}ª Saí.`)}
								</TableCell>
							);
						})}
				</TableRow>
			</TableHead>
			<TableBody>
				{Array.from(erpClockInOuts.keys()).map((date) => (
					<TableRow
						key={date}
						sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)', display: 'flex' }}
					>
						<TableCell sx={{ border: 'none' }}>{date}</TableCell>
						{erpClockInOuts.get(date)?.map((item, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<React.Fragment key={item.hour}>
								{index % 2 === 0 ? (
									<TableCell align="right" width={90} sx={{ border: 'none' }}>{item.hour}</TableCell>
								) : (
									<TableCell align="left" width={90} sx={{ border: 'none' }}>{item.hour}</TableCell>
								)}
							</React.Fragment>
						))}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

export default ERPClockHoursTable;
