import React, {
	Dispatch, memo, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import BudgetUsersPresentational from '../../components/Budget/Users/BudgetUsers';
import { getBudgetApprovers, handleBudgetApprovers, getFilterFieldsValues } from '../../services/budget';
import { BudgetApproverQueryParams } from '../../interfaces/BudgetApproverQueryParams';

enum ActionType {
	LOADING,
	APPROVERS,
	ACCOUNTING_ACCOUNTS,
	COST_CENTERS,
	ACCOUNTING_ITEMS,
	CLASS_VALUES,
}

interface IState {
	loading: boolean;
	budgetApprovers: any[];
	accountingAccounts: any[];
	costCenters: any[];
	accountingItems: any[];
	classValues: any[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.APPROVERS; payload: { budgetApprovers: any[] } }
	| { type: ActionType.ACCOUNTING_ACCOUNTS; payload: { accountingAccounts: any[] } }
	| { type: ActionType.COST_CENTERS; payload: { costCenters: any[] } }
	| { type: ActionType.ACCOUNTING_ITEMS; payload: { accountingItems: any[] } }
	| { type: ActionType.CLASS_VALUES; payload: { classValues: any[] } }

interface IBudgetUserLevelActions {
	setLoading(loading: boolean): void;
    getFilterFieldsValues(): void;
    getBudgetApprovers(filter: BudgetApproverQueryParams): void;
    handleBudgetApprovers(data: any): void;
}

export enum BudgetApproverType {
    CORPORATIVE_LEVEL,
    CONTROLLERSHIP
}

const initialState: IState = {
	loading: false,
	budgetApprovers: [],
	accountingAccounts: [],
	costCenters: [],
	accountingItems: [],
	classValues: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.APPROVERS:
			return { ...state, budgetApprovers: action.payload.budgetApprovers };
		case ActionType.ACCOUNTING_ACCOUNTS:
			return { ...state, accountingAccounts: action.payload.accountingAccounts };
		case ActionType.COST_CENTERS:
			return { ...state, costCenters: action.payload.costCenters };
		case ActionType.ACCOUNTING_ITEMS:
			return { ...state, accountingItems: action.payload.accountingItems };
		case ActionType.CLASS_VALUES:
			return { ...state, classValues: action.payload.classValues };
		default:
			throw new Error();
	}
};

const BudgetUserLevelsActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IBudgetUserLevelActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getFilterFieldsValues() {
			getFilterFieldsValues(undefined, true).then((response: any) => {
				dispatch({
					type: ActionType.ACCOUNTING_ACCOUNTS,
					payload: { accountingAccounts: response.data.accountingAccounts },
				});
				dispatch({
					type: ActionType.COST_CENTERS,
					payload: { costCenters: response.data.costCenters },
				});
				dispatch({
					type: ActionType.ACCOUNTING_ITEMS,
					payload: { accountingItems: response.data.accountingItems },
				});
				dispatch({
					type: ActionType.CLASS_VALUES,
					payload: { classValues: response.data.classValues },
				});
			});
		},
		getBudgetApprovers(filter: BudgetApproverQueryParams) {
			actions.setLoading(true);
			getBudgetApprovers(filter).then((budgetApprovers) => {
				actions.setLoading(false);
				dispatch({
					type: ActionType.APPROVERS,
					payload: { budgetApprovers: budgetApprovers.data.data },
				});
			});
		},
		handleBudgetApprovers(data: any) {
			actions.setLoading(true);
			handleBudgetApprovers(data).then((response) => {
				actions.getBudgetApprovers({});
				enqueueSnackbar(response.data.message, {
					variant: 'success',
				});
			})
				.catch((error: AxiosError<{ error: string }>) => {
					actions.setLoading(false);
					enqueueSnackbar(error.response?.data.error, {
						variant: 'error',
					});
				});
		},
	};

	return actions;
};

const BudgetUserLevels = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => BudgetUserLevelsActions(dispatch, enqueueSnackbar),
		[enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <BudgetUsersPresentational {...state} {...actions} />;
};

export default memo(BudgetUserLevels);
