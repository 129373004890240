import { TaskClockInOut } from './ApportionmentAssets';

export interface UserHourProjectInconsistence {
    id: string;
    name: string;
    login: string;
    tasksClockInOut: TaskClockInOut[]
    numberOfProjects: number;
    numberOfTasks: number;
    totalRegisteredHours: string;
    hasInconsistencies: boolean;
    integrated: boolean;
}

export interface UserHourProjectInconsistenceResponse {
    data: UserHourProjectInconsistence[]
}

export interface IForm {
    date: Date,
    userId?: string;
    hasInconsistence?: boolean;
    hasIntegration?: boolean;
}

export const initialValues: IForm = {
	date: new Date(),
	hasInconsistence: undefined,
	hasIntegration: false,
};

export const inconsistencyOptions = [
	{ id: 'both', value: 'undefined', label: 'Ambos' },
	{ id: 'inconsistent', value: 'true', label: 'Inconsistente' },
	{ id: 'consistent', value: 'false', label: 'Consistente' },
];
