import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AddUser, EditUser, User } from '../../containers/User/UserAssets';
import UserForm from './UserForm';
import { Loading } from '../Common/Loading';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { BranchParams } from '../../containers/Inventory/InventoryAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';

interface UserEditProps {
	loading: boolean;
	user?: User;
	getUserById: (id: string) => void;
	handleEditUser: (values: { id?: string; data: AddUser | EditUser }) => void;
	getCompaniesWithoutDetails: () => void;
	companies: ICompanyWithoutDetails[];
	getBranches: (params: BranchParams) => void;
	branches: IBranch[];
}

const UserEdit = ({
	loading,
	handleEditUser,
	getUserById,
	user,
	companies,
	branches,
	getCompaniesWithoutDetails,
	getBranches,
}: UserEditProps): JSX.Element => {
	const { id } = useParams();
	useEffect(() => {
		if (id) {
			getUserById(id);
		}
		getCompaniesWithoutDetails();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (id && !user) {
		return <Loading />;
	}

	return (
		<UserForm
			loading={loading}
			handleEditUser={handleEditUser}
			user={user}
			companies={companies}
			branches={branches}
			getBranches={getBranches}
		/>
	);
};

UserEdit.defaultProps = {
	user: undefined,
};

export default UserEdit;
