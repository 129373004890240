import React, { useEffect, useRef } from 'react';
import {
	Box,
	Typography,
	IconButton,
	Paper,
	Button,
} from '@mui/material';
import {
	ChevronRight,
	Search,
} from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import Input from '../../Common/Form/Input';
import { Loading } from '../../Common/Loading';
import { GroupedTasks } from './Expedition';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import { formatBarCode } from '../../../helpers/masks';

interface ExpeditionProductsProps {
	groupedTasks: GroupedTasks | null;
	location: string;
	saleOrder: string;
	loading: boolean;
	handleProductDetails: (isBarCode: boolean) => void;
	handleBackList: () => void;
}

const ExpeditionProducts = ({
	loading,
	location,
	saleOrder,
	handleBackList,
	handleProductDetails,
	groupedTasks,
}:ExpeditionProductsProps): JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);
	const { values, setFieldValue } = useFormikContext<{
		productsSearch: string;
		locationSelected: string
	}>();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [location, saleOrder, groupedTasks]);

	const handleValidateLocation = (isBarCode: boolean): void => {
		if (groupedTasks) {
			const matchTask = groupedTasks[saleOrder].locations[location].tasks.find(
				(task) => task.product.code.toUpperCase() === values.productsSearch,
			);

			if (matchTask) {
				setFieldValue('productSelected', matchTask);
				handleProductDetails(isBarCode);
			} else {
				enqueueSnackbar('Produto não encontrado!', {
					variant: 'error',
				});
			}
		}
	};

	const handleClickProduct = (taskSelected: ITask): void => {
		setFieldValue('productSelected', taskSelected);
		const isBarCode = false;
		handleProductDetails(isBarCode);
	};

	const handleBlurOrEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			const isBarCode = true;
			handleValidateLocation(isBarCode);
		}
	};

	if (loading) {
		<Loading />;
	}

	return (
		<Box padding={1}>
			<Typography variant="h6" align="center">
				Produtos Pendentes
			</Typography>
			<Typography variant="subtitle1" align="center">
				{`Localização: ${formatBarCode(location)}`}
			</Typography>
			<Typography variant="subtitle1" align="center">
				{`Pedido de Venda: ${saleOrder}`}
			</Typography>

			<Paper sx={{ boxShadow: 'none', marginY: 2 }}>
				<Input.InputField
					id="productsSearch"
					name="productsSearch"
					label="Código de Barras do Produto"
					autoComplete="off"
					InputProps={{
						// eslint-disable-next-line react/no-unstable-nested-components
						endAdornment: <IconButton color="primary" aria-label="search location"><Search /></IconButton>,
						inputProps: {
							min: 0,
							step: 'any',
						},
					}}
					inputRef={inputRef}
					onKeyDown={handleBlurOrEnter}
					format={(value) => value.toUpperCase()}
					fullWidth
					required
				/>
			</Paper>
			<Typography variant="overline">
				Produtos:
			</Typography>
			{groupedTasks && groupedTasks[saleOrder].locations[location].tasks.map((task) => (
				<Box
					key={task.id}
					sx={{
						padding: 2,
						border: 1,
						borderColor: '#bbb',
						bgcolor: 'white',
						borderRadius: 2,
						mb: 2,
						display: 'flex',
						justifyContent: 'space-between',
					}}
					onClick={() => handleClickProduct(task)}
				>
					<Typography>{`${task.product.code} - ${task.product.description}`}</Typography>
					<ChevronRight color="primary" />
				</Box>
			))}
			<Button sx={{ my: 2 }} size="large" variant="contained" onClick={handleBackList}>
				Voltar
			</Button>
		</Box>
	);
};

export default ExpeditionProducts;
