import React, {
	Dispatch, memo, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError, AxiosResponse } from 'axios';
import Decimal from 'decimal.js';
import PurchaseOrderApprovalPresentational from '../../components/Order/PurchaseOrderApproval/PurchaseOrderApproval';
import ManagePurchaseOrdersPresentational from '../../components/Order/ManagePurchaseOrders/ManagePurchaseOrders';
import {
	getPurchases,
	getApportionmentById,
	approvePurchase,
	rejectPurchase,
	sendToOverrun,
	getAllPurchasesByBudget,
	reactivatePurchase,
} from '../../services/purchaseOrderApproval';
import {
	getBudgetsFrom, getBudgetsTo, getUserTypes, sendValueTransfer, getFilterFieldsValues,
} from '../../services/budget';
import { IValueTransferData } from '../../interfaces/BudgetValueTransfer';
import { ForApprovalQueryParams } from '../../interfaces/ForApprovalQueryParams';
import { ManagePurchaseOrders } from '../../interfaces/ManagePurchaseOrders';
import { IFilterFieldsValues } from '../../interfaces/BudgetOrderFourFields';
import { IPurchasesWithJustificative, ISendToOverrun } from '../../interfaces/PurchaseOrderApproval';

enum ActionType {
	LOADING,
	SHOW_VALUE_TRANSFER_MODAL,
	PURCHASES,
	USER_TYPE,
	BUDGETS_FROM,
	BUDGETS_TO,
	APPORTIONMENT,
	FILTER_FIELDS_VALUES,
	FILTERS,
	MANAGE_ORDERS_FILTERS
}

export enum UserType {
	RESPONSIBLE,
	CORPORATIVE_LEVEL,
	CONTROLLERSHIP
}

export const userTypeMenu = [
	'Responsável',
	'Nível corporativo',
	'Controladoria',
];

interface IState {
	loading: boolean;
	showValueTransferModal: boolean;
	loadingApportionments: boolean;
	purchasesPages: number;
	purchasesPage: number;
	purchasesTake: number;
	purchases: any[];
	apportionments: any[];
	userTypes: UserType[];
	budgetsFrom: any[];
	budgetsTo: any[];
	filterFieldsValues: IFilterFieldsValues | null;
	filters: ForApprovalQueryParams | null;
	manageOrdersFilters: ManagePurchaseOrders | null;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.SHOW_VALUE_TRANSFER_MODAL; payload: { showValueTransferModal: boolean } }
	| {
		type: ActionType.PURCHASES; payload: {
			purchases: any[],
			purchasesPages: number,
			purchasesPage: number,
			purchasesTake: number,
		}
	}
	| { type: ActionType.USER_TYPE; payload: { userTypes: UserType[] } }
	| { type: ActionType.BUDGETS_FROM; payload: { budgetsFrom: any[] } }
	| { type: ActionType.BUDGETS_TO; payload: { budgetsTo: any[] } }
	| {
		type: ActionType.APPORTIONMENT; payload: {
			apportionments: any[],
			loadingApportionments: boolean,
		}
	}
	| { type: ActionType.FILTER_FIELDS_VALUES; payload: { filterFieldsValues: IFilterFieldsValues } }
	| { type: ActionType.FILTERS; payload: { filters: ForApprovalQueryParams } }
	| {
		type: ActionType.MANAGE_ORDERS_FILTERS; payload: {
			manageOrdersFilters: ManagePurchaseOrders
		}
	};

interface IPurchaseOrderApprovalActions {
	setLoading(loading: boolean): void;
	setShowValueTransferModal(showValueTransferModal: boolean): void;
	getPurchases(queryParams: ForApprovalQueryParams): void;
	getAllPurchasesByBudget(queryParams: ManagePurchaseOrders): void;
	getApportionmentById(id: string): void;
	getBudgetsFrom(type?: UserType): void;
	getBudgetsTo(type?: UserType): void;
	getUserTypes(): void;
	approvePurchase(budgetId: string, ids: string[], type?: UserType): void;
	rejectPurchase(data: IPurchasesWithJustificative): void;
	sendValueTransfer(data: IValueTransferData): void;
	sendToOverrun(data: ISendToOverrun): void;
	getFilterFieldsValues: (type?: UserType) => void;
	reactivatePurchase(data: IPurchasesWithJustificative): void;
}

const initialState: IState = {
	loading: false,
	showValueTransferModal: false,
	loadingApportionments: false,
	purchasesPages: 0,
	purchasesPage: 0,
	purchasesTake: 10,
	purchases: [],
	apportionments: [],
	userTypes: [],
	budgetsFrom: [],
	budgetsTo: [],
	filterFieldsValues: null,
	filters: null,
	manageOrdersFilters: null,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.SHOW_VALUE_TRANSFER_MODAL:
			return { ...state, showValueTransferModal: action.payload.showValueTransferModal };
		case ActionType.PURCHASES:
			return {
				...state,
				purchases: action.payload.purchases,
				purchasesPages: action.payload.purchasesPages,
				purchasesPage: action.payload.purchasesPage,
				purchasesTake: action.payload.purchasesTake,
			};
		case ActionType.USER_TYPE:
			return { ...state, userTypes: action.payload.userTypes };
		case ActionType.BUDGETS_FROM:
			return { ...state, budgetsFrom: action.payload.budgetsFrom };
		case ActionType.BUDGETS_TO:
			return { ...state, budgetsTo: action.payload.budgetsTo };
		case ActionType.APPORTIONMENT:
			return {
				...state,
				apportionments: action.payload.apportionments,
				loadingApportionments: action.payload.loadingApportionments,
			};
		case ActionType.FILTER_FIELDS_VALUES:
			return { ...state, filterFieldsValues: action.payload.filterFieldsValues };
		case ActionType.FILTERS:
			return { ...state, filters: action.payload.filters };
		case ActionType.MANAGE_ORDERS_FILTERS:
			return { ...state, manageOrdersFilters: action.payload.manageOrdersFilters };
		default:
			throw new Error();
	}
};

const PurchaseOrderApprovalActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	state: IState,
): IPurchaseOrderApprovalActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setShowValueTransferModal(showValueTransferModal: boolean) {
			dispatch({
				type: ActionType.SHOW_VALUE_TRANSFER_MODAL,
				payload: { showValueTransferModal },
			});
		},
		getPurchases(queryParams: ForApprovalQueryParams) {
			dispatch({ type: ActionType.LOADING, payload: { loading: true } });
			dispatch({ type: ActionType.FILTERS, payload: { filters: queryParams } });
			actions.setLoading(true);
			const params = { ...queryParams, skip: (queryParams.skip - 1) * 10 };
			getPurchases(params).then((purchases) => {
				const data = purchases.data.data.map((purchaseData) => {
					const purchase = purchaseData;

					purchase.totalValue = new Decimal(purchase.totalValue);
					purchase.commitedValue = new Decimal(purchase.commitedValue);
					purchase.realizedValue = new Decimal(purchase.realizedValue);
					return purchase;
				});

				dispatch({
					type: ActionType.PURCHASES,
					payload: {
						purchases: data,
						purchasesPages: Math.ceil(purchases.data.count / (queryParams.take ?? 10)),
						purchasesPage: queryParams.skip,
						purchasesTake: queryParams.take ?? 10,
					},
				});
				actions.setLoading(false);
			});
		},
		getAllPurchasesByBudget(queryParams: ManagePurchaseOrders) {
			dispatch(
				{ type: ActionType.MANAGE_ORDERS_FILTERS, payload: { manageOrdersFilters: queryParams } },
			);
			actions.setLoading(true);
			const params = { ...queryParams, skip: (queryParams.skip - 1) * 10 };

			getAllPurchasesByBudget(params).then((purchases) => {
				const data = purchases.data.data.map((purchaseData) => {
					const purchase = purchaseData;

					purchase.totalValue = new Decimal(purchase.totalValue);
					purchase.commitedValue = new Decimal(purchase.commitedValue);
					purchase.realizedValue = new Decimal(purchase.realizedValue);
					return purchase;
				});

				dispatch({
					type: ActionType.PURCHASES,
					payload: {
						purchases: data,
						purchasesPages: Math.ceil(purchases.data.count / (queryParams.take ?? 10)),
						purchasesPage: queryParams.skip,
						purchasesTake: queryParams.take ?? 10,
					},
				});
				actions.setLoading(false);
			});
		},
		getApportionmentById(id: string) {
			dispatch({
				type: ActionType.APPORTIONMENT,
				payload: { apportionments: [], loadingApportionments: true },
			});

			getApportionmentById(id).then((apportionments) => {
				dispatch({
					type: ActionType.APPORTIONMENT,
					payload: { apportionments: apportionments.data.data, loadingApportionments: false },
				});
			});
		},
		getBudgetsFrom(type?: UserType) {
			getBudgetsFrom(type).then((budgets) => {
				const data = budgets.data.data.map((budgetData: any) => {
					const budget = budgetData;

					budget.balanceValue = new Decimal(budget.balanceValue);
					return budget;
				});

				dispatch({
					type: ActionType.BUDGETS_FROM,
					payload: { budgetsFrom: data },
				});
			});
		},
		getBudgetsTo(type?: UserType) {
			getBudgetsTo(type).then((budgets) => {
				const data = budgets.data.data.map((budgetData: any) => {
					const budget = budgetData;

					budget.balanceValue = new Decimal(budget.balanceValue);
					return budget;
				});

				dispatch({
					type: ActionType.BUDGETS_TO,
					payload: { budgetsTo: data },
				});
			});
		},
		getUserTypes() {
			getUserTypes().then((userTypes) => {
				dispatch({
					type: ActionType.USER_TYPE,
					payload: { userTypes: userTypes.data.data },
				});
			});
		},
		approvePurchase(budgetId: string, ids: string[], type?: UserType) {
			actions.setLoading(true);
			approvePurchase(budgetId, ids, type)
				.then((response) => {
					actions.setLoading(false);
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
					actions.getPurchases(state.filters ?? { skip: 1 });
				})
				.catch((error: AxiosError<{ message: string }>) => {
					actions.setLoading(false);
					enqueueSnackbar(error.response?.data.message, {
						variant: 'error',
					});
				});
		},
		rejectPurchase(data: IPurchasesWithJustificative) {
			actions.setLoading(true);
			rejectPurchase(data)
				.then((response) => {
					actions.setLoading(false);
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
					actions.getPurchases(state.filters ?? { skip: 1 });
				})
				.catch((error: AxiosError<{ message: string }>) => {
					actions.setLoading(false);
					enqueueSnackbar(error.response?.data.message, {
						variant: 'error',
					});
				});
		},
		reactivatePurchase(data: IPurchasesWithJustificative) {
			actions.setLoading(true);
			reactivatePurchase(data)
				.then((response) => {
					actions.setLoading(false);
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
					actions.getAllPurchasesByBudget(state.manageOrdersFilters ?? {
						skip: 1,
						initialMonth: new Date().getMonth(),
						finalMonth: new Date().getMonth(),
						year: new Date().getFullYear(),
					});
				})
				.catch((error: AxiosError<{ message: string }>) => {
					actions.setLoading(false);
					enqueueSnackbar(error.response?.data.message, {
						variant: 'error',
					});
				});
		},
		sendValueTransfer(data: IValueTransferData) {
			sendValueTransfer(data)
				.then((response) => {
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
				})
				.catch((error: AxiosError<{ message: string }>) => {
					enqueueSnackbar(error.response?.data.message, {
						variant: 'error',
					});
				});
		},
		sendToOverrun(data: ISendToOverrun) {
			actions.setLoading(true);
			sendToOverrun(data)
				.then((response) => {
					actions.setLoading(false);
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
					actions.getPurchases(state.filters ?? { skip: 1, type: data.type });
				})
				.catch((error: AxiosError<{ message: string }>) => {
					actions.setLoading(false);
					enqueueSnackbar(error.response?.data.message, {
						variant: 'error',
					});
				});
		},
		getFilterFieldsValues(type?: UserType) {
			getFilterFieldsValues(type).then((response: AxiosResponse) => {
				dispatch({
					type: ActionType.FILTER_FIELDS_VALUES,
					payload: { filterFieldsValues: response.data },
				});
			});
		},
	};

	return actions;
};

const PurchaseOrderApproval = ({ manage }: { manage?: boolean }): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => PurchaseOrderApprovalActions(dispatch, enqueueSnackbar, state),
		[enqueueSnackbar, state],
	);

	if (manage) {
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <ManagePurchaseOrdersPresentational {...state} {...actions} />;
	}

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <PurchaseOrderApprovalPresentational {...state} {...actions} />;
};

PurchaseOrderApproval.defaultProps = {
	manage: false,
};

export default memo(PurchaseOrderApproval);
