import React, { useMemo } from 'react';
import {
	DataGrid,
	GridActionsCellItem,
	GridRenderCellParams,
	GridSortModel,
} from '@mui/x-data-grid';
import { Box, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import { ILocationType } from '../../containers/Location/LocationAssets';
import useDataGridManagement from '../../hooks/useDataGridManagement';

interface ILocationTypeTable {
  rows: ILocationType[];
  loading: boolean;
  handleDeleteLocationType: (id: string) => void;
  getLocationTypes: () => void;
}

const LocationTypeTable = ({
	rows,
	loading,
	handleDeleteLocationType,
	getLocationTypes,
}: ILocationTypeTable): JSX.Element => {
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const initialSortModel: GridSortModel = [
		{ field: 'name', sort: 'asc' },
	];

	const columns = useMemo(
		() => [
			{
				field: 'prefix',
				headerName: 'Prefixo',
				editable: true,
				flex: 1,
			},
			{
				field: 'name',
				headerName: 'Nome',
				editable: true,
				flex: 1,
			},
			{
				field: 'level',
				headerName: 'Nível',
				editable: true,
				flex: 1,
			},
			{
				field: 'actions',
				headerName: 'Ações',
				width: 120,
				renderCell: (params: GridRenderCellParams) => (
					<>
						<Link to={`edit/${params.id}`} state={{ locations: rows }}>
							<GridActionsCellItem
								icon={<EditIcon />}
								label="Editar"
								color="primary"
							/>
						</Link>
						<GridActionsCellItem
							icon={<DeleteIcon />}
							label="Excluir"
							color="error"
							onClick={() => requestConfirm({
								description:
                    'Tem certeza que deseja excluir o tipo de localização selecionada?',
								callback: () => handleDeleteLocationType(params.id.toString()),
							})}
						/>
					</>
				),
			},
		],
		[handleDeleteLocationType, requestConfirm, rows],
	);

	useDataGridManagement({
		initialSortModel,
		initialPageSize: 100,
		initialPage: 0,
		fetchData: getLocationTypes,
	});

	return (
		<Box component={Paper} sx={{ width: '100%' }}>
			<DataGrid rows={rows} columns={columns} autoHeight loading={loading} />
			{confirmationDialog}
		</Box>
	);
};

export default LocationTypeTable;
