import * as Yup from 'yup';

export interface UserClock {
    id: string;
    login: string;
    name: string;
}

export interface ClockHoursParams {
    dateFrom: Date | string;
    dateTo: Date | string;
    userId?: string | null;
}

export type ERPClockHoursMap = Map<string, { hour: string, type: string }[]>;

export interface ErpClockInOutsMap {
	erpClockInOuts: ERPClockHoursMap;
	erpClockInOutsCount: number;
}

export const validationSchema = Yup.object().shape({
	dateFrom: Yup.date()
		.required('Data de início é obrigatória'),
	dateTo: Yup.date()
		.required('Data de término é obrigatória'),
	userId: Yup.string()
		.required('Usuário é obrigatório'),
});
