import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SwitchField from '../../Common/Form/Switch';
import { IForm, inconsistencyOptions, initialValues } from '../../../containers/HourProject/HourProjectInconsistenciesAssets';
import Autocomplete from '../../Common/Form/Autocomplete';
import { UserClock } from '../../../containers/HourProject/ClockHoursAssets';
import RadioField from '../../Common/Form/Radio';

interface IFilterProps {
	sendFilter: (values: IForm) => void;
	initialValuesLoad: IForm;
	usersFilter: UserClock[];
}

const HourProjectInconsistenciesFilter = ({
	sendFilter, initialValuesLoad, usersFilter,
}: IFilterProps): JSX.Element => {
	const onSubmit = useCallback((values: any) => {
		sendFilter({
			...values,
			hasInconsistence: values.hasInconsistence === 'undefined' ? undefined : values.hasInconsistence,
		});
	}, [sendFilter]);

	const onReset = useCallback((resetForm) => {
		resetForm();
		sendFilter(initialValues);
	}, [sendFilter]);

	return (
		<Paper sx={{ p: 2, width: '100%', mb: 2 }}>
			<Formik
				initialValues={{
					...initialValuesLoad,
					hasInconsistence: initialValuesLoad.hasInconsistence === undefined ? 'undefined' : initialValuesLoad.hasInconsistence.toString(),
				}}
				onSubmit={onSubmit}
			>
				{({ resetForm, setFieldValue, values }) => (
					<Form>
						<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
							<Grid item xs={12}>
								<Autocomplete<{ name: string, id: string, login: string }>
									multiple
									limitTags={1}
									label="Usuário"
									name="userId"
									options={usersFilter || []}
									labelKey="name"
									valueKey="id"
									valueLabel="login"
								/>
							</Grid>
							<Grid item xs={12}>
								<RadioField
									name="hasInconsistence"
									label="Status Apontamento"
									options={inconsistencyOptions}
									onChange={(event) => {
										const { value } = event.target;
										setFieldValue('hasInconsistence', value);
										if (value === 'true') {
											setFieldValue('hasIntegration', false);
										}
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<SwitchField
									name="hasIntegration"
									label="Integrado"
									onChange={(event) => {
										const isChecked = event.target.checked;
										setFieldValue('hasIntegration', isChecked);
										if (isChecked && values.hasInconsistence === 'true') {
											setFieldValue('hasInconsistence', 'undefined');
										}
									}}
								/>
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
								<Button variant="outlined" onClick={() => onReset(resetForm)}>
									Restaurar
								</Button>
								<Button variant="contained" type="submit">
									Filtrar
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};

export default HourProjectInconsistenciesFilter;
