import React from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { getValidFilters } from '../../../helpers/BudgetDashboardFilter';
import { IFilter } from '../../../interfaces/BudgetResponsible';
import { IFilterFieldsValues } from '../../../interfaces/BudgetOrderFourFields';
import BudgetOrderFourFieldsFilter from '../../Common/BudgetOrderFourFields/BudgetOrderFourFieldsFilter';

interface IBudgetResponsibleFilterProps {
  filterFieldsValues: IFilterFieldsValues | null;
  loading: boolean;
  handleFilter: (values: IFilter) => void;
}

const initialValues = {
	accountingItems: [],
	costCenters: [],
	classValues: [],
	accountingAccounts: [],
};

export const BudgetResponsibleFilter = ({
	filterFieldsValues, loading, handleFilter,
}: IBudgetResponsibleFilterProps): JSX.Element => {
	const onSubmit = (values: IFilter): void => {
		const validFilters = getValidFilters(values, filterFieldsValues);
		handleFilter(validFilters);
	};

	const onReset = (values: IFilter): void => {
		handleFilter(values);
	};

	return (
		<Paper sx={{ p: 2, position: 'relative' }}>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				onReset={onReset}
			>
				<Form>
					<Grid container spacing={3} alignItems="center" justifyContent="flex-end">
						{filterFieldsValues && (
							<BudgetOrderFourFieldsFilter
								accountingAccounts={filterFieldsValues.accountingAccounts}
								accountingItems={filterFieldsValues.accountingItems}
								classValues={filterFieldsValues.classValues}
								costCenters={filterFieldsValues.costCenters}
								breakpoints={{ xs: 3 }}
							/>
						)}
						<Grid item sx={{ width: 'fit-content' }}>
							<Button variant="outlined" type="reset">RESTAURAR</Button>
						</Grid>
						<Grid item sx={{ width: 'fit-content' }}>
							<Button variant="contained" type="submit">FILTRAR</Button>
						</Grid>
					</Grid>
				</Form>
			</Formik>
			{loading && (
				<Skeleton
					sx={{
						position: 'absolute', top: 0, left: 0, zIndex: 500, backgroundColor: '#e3e3e3',
					}}
					variant="rectangular"
					animation="wave"
					width="100%"
					height="100%"
				/>
			)}
		</Paper>
	);
};
