import React, { useCallback, useEffect, useRef } from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Typography,
} from '@mui/material';
import { Form, FormikContext, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import Input from '../../../../../Common/Form/Input';
import { formatBarCode } from '../../../../../../helpers/masks';
import { InventoryTaskDetails } from '../../../../../../containers/Inventory/InventoryAssets';

interface StepProductProps {
    inventoryTask: InventoryTaskDetails | null;
	confirmProduct: (productBarCode: string, reader?: boolean) => void;
}

const StepProduct = ({
	inventoryTask,
	confirmProduct,
}: StepProductProps): JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const formik = useFormik({
		initialValues: {
			locationBarCode: '',
			productBarCode: '',
			productQuantity: 0,
		},
		onSubmit: (value) => {
			if (value.productBarCode === inventoryTask?.product.barCode) {
				const reader = false;
				confirmProduct(formik.values.productBarCode, reader);
			} else {
				enqueueSnackbar('Código de barras inválido', {
					variant: 'error',
				});
			}
			formik.setFieldValue('productBarCode', '');
		},
	});

	const handleBlurOrEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
		const target = event.target as HTMLInputElement;
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			const reader = true;
			const product = target.value;
			if (target.value === inventoryTask?.product.barCode) {
				confirmProduct(product, reader);
			} else {
				enqueueSnackbar('Código de barras inválido', {
					variant: 'error',
				});
			}
			formik.setFieldValue('productBarCode', '');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Card>
					<CardContent>
						<Typography
							textAlign="center"
							variant="h6"
						>
							{`${inventoryTask?.product.barCode}
							 - ${inventoryTask?.product.description}`}
						</Typography>
						<Typography
							textAlign="center"
						>
							{formatBarCode(inventoryTask?.locationOrigin.barCode || '')}
						</Typography>

						<Divider sx={{ marginY: 2 }} />
						<Typography sx={{ marginBottom: 2 }}>
							Leia o código de barras do produto:
						</Typography>
						<Input.InputField
							id="productBarCode"
							name="productBarCode"
							label="Código de Barras do Produto"
							autoComplete="off"
							inputRef={inputRef}
							onKeyDown={handleBlurOrEnter}
							fullWidth
							required
						/>
					</CardContent>
					<CardActions>
						<Button
							variant="contained"
							size="large"
							type="submit"
							fullWidth
						>
							Confirmar
						</Button>
					</CardActions>
				</Card>
			</Form>
		</FormikContext.Provider>
	);
};

export default StepProduct;
