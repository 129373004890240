import React, {
	useCallback, useMemo, useState,
} from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Box,
	Tooltip,
	IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useFormikContext } from 'formik';
import { currencyBRLMask } from '../../../helpers/intl';
import {
	initialSaleOrderSimulationItem,
	initialSalePriceFormationAnalysis,
	ISaleOrderSimulation,
} from '../../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import {
	calculateUnitValueRangePriceCommission,
	getSalePriceFormation,
	getSelectedItem,
	getSelectedItemIndex,
} from '../../../helpers/ProfitabilityAnalysisCalculations';
import { ProfitabilityAnalysisStatus } from '../../../enums/ProfitabilityAnalysisStatus';

interface IRangePriceCommissionSimulationProps {
	updateSelectedRangeSimulation(
		saleOrderSimulationItemId: string,
		rangeCommissionId: string,
	): void;
}

const RangePriceCommissionSimulationTable = ({
	updateSelectedRangeSimulation,
}: IRangePriceCommissionSimulationProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<ISaleOrderSimulation>();
	const [showExtraColumns, setShowExtraColumns] = useState(true);

	const selectedItem = useMemo(
		() => getSelectedItem(
			values.saleOrderSimulationItems,
			initialSaleOrderSimulationItem,
		),
		[values.saleOrderSimulationItems],
	);

	const selectedItemIndex = useMemo(
		() => getSelectedItemIndex(values.saleOrderSimulationItems),
		[values.saleOrderSimulationItems],
	);

	const salePriceFormation = useMemo(
		() => getSalePriceFormation(selectedItem, initialSalePriceFormationAnalysis),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedItem],
	);

	const isReadOnly = useMemo(
		() => [
			ProfitabilityAnalysisStatus.APPROVED,
			ProfitabilityAnalysisStatus.REJECTED,
		].includes(values.status),
		[values.status],
	);

	const handleRowClick = useCallback(
		(rangeCommission: any, index: number): void => {
			if (isReadOnly || !salePriceFormation?.rangeCommissionSalePriceFormations) return;

			salePriceFormation.indexValueRangeCommissionSimulation = salePriceFormation
				.rangeCommissionSalePriceFormations[index].sppvIndex;
			salePriceFormation.unitValueRangeCommissionSimulation = salePriceFormation
				.rangeCommissionSalePriceFormations[index].sppvUnitValueR;

			const updatedRangeCommissionSalePriceFormations = salePriceFormation
				.rangeCommissionSalePriceFormations.map((range) => ({
					...range,
				}));

			setFieldValue(
				`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.rangeCommissionSalePriceFormations`,
				updatedRangeCommissionSalePriceFormations,
			);

			setFieldValue(
				`saleOrderSimulationItems[${selectedItemIndex}].selectedRangeCommissionId`,
				rangeCommission.rangeCommissionId,
			);

			updateSelectedRangeSimulation(
				selectedItem.id,
				rangeCommission.rangeCommissionId,
			);
		},

		[
			isReadOnly,
			salePriceFormation,
			setFieldValue,
			selectedItemIndex,
			updateSelectedRangeSimulation,
			selectedItem.id,
		],
	);

	const backgroundColor = useMemo(
		() => (showExtraColumns ? 'primary.dark' : 'primary.main'),
		[showExtraColumns],
	);

	return (
		<Box sx={{ display: 'flex', position: 'relative' }}>
			<TableContainer component={Paper} sx={{ flexGrow: 1 }}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '5%' }}>
								<strong>Faixa</strong>
							</TableCell>
							<TableCell align="right" sx={{ width: '10%' }}>
								<strong>Comissão</strong>
							</TableCell>
							<TableCell align="right" sx={{ width: '17.5%' }}>
								<strong>Índice</strong>
							</TableCell>
							<TableCell align="right" sx={{ width: '17.5%' }}>
								<strong>R$ Unitário</strong>
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell align="right" sx={{ backgroundColor: '#f0f0f0', width: '25%' }}>
										<strong>Índice</strong>
									</TableCell>
									<TableCell align="right" sx={{ backgroundColor: '#f0f0f0', width: '25%' }}>
										<strong>R$ Unitário</strong>
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip
									title={
										showExtraColumns
											? 'Ocultar colunas de simulação'
											: 'Visualizar colunas de simulação'
									}
								>
									<IconButton
										sx={{ color: 'white' }}
										onClick={() => setShowExtraColumns(!showExtraColumns)}
									>
										{showExtraColumns ? (
											<VisibilityOffIcon />
										) : (
											<VisibilityIcon />
										)}
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{salePriceFormation.rangeCommissionSalePriceFormations?.map(
							(rangeCommission, index) => {
								const isHighlighted = selectedItem.commission
								=== rangeCommission.rangeCommission.range;

								const isExtraColumnSelected = rangeCommission.rangeCommissionId
								=== selectedItem.selectedRangeCommissionId
								|| (!selectedItem.selectedRangeCommissionId
									&& rangeCommission.rangeCommission.range === selectedItem.commission);

								const defaultCellStyle = {
									fontWeight: isHighlighted ? 'bold' : 'normal',
									backgroundColor: 'transparent',
									cursor: 'default',
								};

								const extraCellStyle = {
									fontWeight: isExtraColumnSelected ? 'bold' : 'normal',
									backgroundColor: isExtraColumnSelected ? '#e0e0e0' : 'transparent',
									cursor: 'pointer',
								};

								const indexValue = salePriceFormation.indexValues?.[
									rangeCommission.commissionRate.toString()
								] || '0';
								const unitValue = calculateUnitValueRangePriceCommission(
									salePriceFormation.totalDirectCost || 0,
									parseFloat(indexValue.replace(',', '.')),
									rangeCommission.commissionRate,
									salePriceFormation.totalIndirectCost || 0,
								);

								const sppvIndex = salePriceFormation?.rangeCommissionSalePriceFormations?.[
									index
								]?.sppvIndex || 0;
								const sppvUnitValue = salePriceFormation?.rangeCommissionSalePriceFormations?.[
									index
								]?.sppvUnitValueR || 0;

								return (
									<TableRow key={rangeCommission.rangeCommission.range}>
										<TableCell sx={defaultCellStyle}>
											{rangeCommission.rangeCommission.range}
										</TableCell>
										<TableCell align="right" sx={defaultCellStyle}>
											{Number(rangeCommission.commissionRate)
												.toFixed(1)
												.replace('.', ',')}
											%
										</TableCell>
										<TableCell align="right" sx={defaultCellStyle}>{indexValue}</TableCell>
										<TableCell align="right" sx={defaultCellStyle}>
											{currencyBRLMask(unitValue, { decimalPlaces: 3 })}
										</TableCell>
										{showExtraColumns && (
											<>
												<TableCell
													align="right"
													sx={{
														...extraCellStyle,
														backgroundColor: '#f0f0f0',
													}}
													onClick={() => !isReadOnly && handleRowClick(rangeCommission, index)}
												>
													{sppvIndex.toFixed(4)}
												</TableCell>
												<TableCell
													align="right"
													sx={{
														...extraCellStyle,
														backgroundColor: '#f0f0f0',
													}}
													onClick={() => handleRowClick(rangeCommission, index)}
												>
													{currencyBRLMask(sppvUnitValue, { decimalPlaces: 3 })}
												</TableCell>
											</>
										)}
									</TableRow>
								);
							},
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				sx={{
					width: '40px',
					backgroundColor,
					position: 'absolute',
					right: 0,
					top: '40px',
					bottom: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: 'calc(100% - 40px)',
				}}
			>
				<Typography
					sx={{
						writingMode: 'vertical-rl',
						transform: 'rotate(180deg)',
						color: 'white',
						fontWeight: 'bold',
						fontSize: '0.75rem',
						letterSpacing: '1px',
						textAlign: 'center',
						lineHeight: 'normal',
					}}
				>
					Simulação
				</Typography>
			</Box>
		</Box>
	);
};

export default RangePriceCommissionSimulationTable;
