import React, { useState, useCallback } from 'react';
import ConfirmationDialog from '../components/Common/ConfirmationDialog';
import { AlertTypes } from '../enums/ConfirmationDialogType';

interface ConfirmationDialogArgs {
	callback: () => void;
	title?: string;
	description?: string;
	content?: React.ReactNode;
	alertType?: AlertTypes;
}

interface UseConfirmationDialogReturn {
	requestConfirm: (args: ConfirmationDialogArgs) => void;
	confirmationDialog: JSX.Element;
}

const useConfirmationDialog = (): UseConfirmationDialogReturn => {
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const [args, setArgs] = useState<ConfirmationDialogArgs>({ callback: () => { } });

	const requestConfirm = useCallback((newArgs: ConfirmationDialogArgs): void => {
		setArgs(newArgs);
		setOpenDialog(true);
	}, []);

	const handleClose = useCallback((): void => {
		setOpenDialog(false);
	}, []);

	const confirmAction = useCallback((): void => {
		args.callback();
		setOpenDialog(false);
	}, [args]);

	const confirmationDialog = (
		<ConfirmationDialog
			open={openDialog}
			onClose={handleClose}
			onConfirm={confirmAction}
			title={args.title}
			description={args.description}
			content={args.content}
			alertType={args.alertType}
		/>
	);

	return { requestConfirm, confirmationDialog };
};

export default useConfirmationDialog;
