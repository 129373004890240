import React, {
	useState, useCallback, useEffect, useMemo,
} from 'react';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
	Box, Button, DialogActions, Grid,
} from '@mui/material';
import {
	useFormik, FormikContext, Form,
} from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { BranchPreference } from '../../enums/brenchPreferenceType';
import { branchPreferenceOptions, User } from '../../containers/User/UserAssets';
import RadioField from '../Common/Form/Radio';
import Autocomplete from '../Common/Form/Autocomplete';
import {
	getUserBranchesByCompany,
	getUserCompanies,
	updateLoginPreference,
	getUserById,
} from '../../services/user';
import { ID_KEY } from '../../services/auth';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { Loading } from '../Common/Loading';

const validationSchema = Yup.object().shape({
	branchPreference: Yup.string().required(),
	branchCompanyId: Yup.string().when('branchPreference', {
		is: String(BranchPreference.PREFERRED),
		then: (schema) => schema.required(),
	}),
	branchId: Yup.string().when('branchPreference', {
		is: String(BranchPreference.PREFERRED),
		then: (schema) => schema.required(),
	}),
});

interface ChangeLoginPreferenceDialogProps {
	open: boolean
	onClose: () => void
}

const ChangeLoginPreferenceDialog = ({
	open,
	onClose,
}: ChangeLoginPreferenceDialogProps): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const [loadingCompanies, setLoadingCompanies] = useState(false);
	const [loadingBranches, setLoadingBranches] = useState(false);
	const [companies, setCompanies] = useState<ICompanyWithoutDetails[]>([]);
	const [branches, setBranches] = useState<IBranch[]>([]);
	const [userData, setUserData] = useState<User>();
	const { enqueueSnackbar } = useSnackbar();

	const fetchCompanies = useCallback(() => {
		setLoadingCompanies(true);
		getUserCompanies()
			.then((response) => {
				setCompanies(response.data.data);
			})
			.catch(() => {
				enqueueSnackbar('Erro ao carregar empresas', { variant: 'error' });
			})
			.finally(() => {
				setLoadingCompanies(false);
			});
	}, [enqueueSnackbar]);

	const fetchBranches = useCallback((companyId: string) => {
		if (!companyId) return;

		setLoadingBranches(true);
		getUserBranchesByCompany(companyId)
			.then((response) => {
				setBranches(response.data.data);
			})
			.catch(() => {
				enqueueSnackbar('Erro ao carregar filiais', { variant: 'error' });
			})
			.finally(() => {
				setLoadingBranches(false);
			});
	}, [enqueueSnackbar]);

	const fetchUserData = useCallback(() => {
		const userId = localStorage.getItem(ID_KEY);
		if (!userId) return;

		getUserById(userId)
			.then((response) => {
				setUserData(response.data);
				if (response.data?.preferredBranch?.branchCompanyId) {
					fetchBranches(response.data.preferredBranch.branchCompanyId);
				}
			})
			.catch(() => {
				enqueueSnackbar('Erro ao carregar dados do usuário', { variant: 'error' });
			});
	}, [enqueueSnackbar, fetchBranches]);

	const handleSubmit = useCallback((values) => {
		setLoading(true);

		const submitData = values.branchPreference === BranchPreference.LAST_USED
			? { branchPreference: values.branchPreference }
			: values;

		updateLoginPreference(submitData)
			.then(() => {
				enqueueSnackbar('Preferência de login atualizada com sucesso', {
					variant: 'success',
				});
				onClose();
			})
			.catch(() => {
				enqueueSnackbar('Erro ao atualizar preferência de login', {
					variant: 'error',
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}, [enqueueSnackbar, onClose]);

	const initialValues = useMemo(() => ({
		branchPreference: userData?.branchPreference ?? BranchPreference.LAST_USED,
		branchCompanyId: userData?.preferredBranch?.branchCompanyId ?? '',
		branchId: userData?.preferredBranch?.branchId ?? '',
	}), [userData]);

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: handleSubmit,
	});

	useEffect(() => {
		if (open) {
			fetchCompanies();
			fetchUserData();
		}
	}, [fetchCompanies, fetchUserData, open]);

	useEffect(() => {
		if (formik.values.branchCompanyId) {
			fetchBranches(formik.values.branchCompanyId);
		}
	}, [formik.values.branchCompanyId, fetchBranches]);

	if (loadingCompanies && loadingBranches && loading) return <Loading />;

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogTitle>Preferência de Login</DialogTitle>
			<FormikContext.Provider value={formik}>
				<Form>
					<DialogContent>
						<Box>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<RadioField
										name="branchPreference"
										label="Selecione a preferência de login"
										options={branchPreferenceOptions}
									/>
								</Grid>

								{String(formik.values.branchPreference) === String(BranchPreference.PREFERRED) && (
									<>
										<Grid item xs={6}>
											<Autocomplete
												label="Empresa preferida"
												name="branchCompanyId"
												valueKey="id"
												valueLabel="name"
												labelKey="name"
												options={companies}
												required
												loading={loadingCompanies}
											/>
										</Grid>
										<Grid item xs={6}>
											<Autocomplete
												label="Filial preferida"
												name="branchId"
												valueKey="id"
												valueLabel="code"
												labelKey="name"
												options={branches}
												required
												loading={loadingBranches}
												disabled={!formik.values.branchCompanyId}
											/>
										</Grid>
									</>
								)}
							</Grid>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose} disabled={loading}>Cancelar</Button>
						<LoadingButton
							type="submit"
							variant="contained"
							loading={loading}
						>
							Salvar
						</LoadingButton>
					</DialogActions>
				</Form>
			</FormikContext.Provider>
		</Dialog>
	);
};

export default ChangeLoginPreferenceDialog;
