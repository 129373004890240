/* eslint-disable react/jsx-props-no-spreading */
import React, {
	memo, useMemo, useState,
} from 'react';
import { SxProps } from '@mui/system';
import {
	PieChart, Pie, Sector, Cell, ResponsiveContainer, Label,
} from 'recharts';
import Decimal from 'decimal.js';
import { RADIAN } from '../../../constants/charts';
import { currencyBRLMask } from '../../../helpers/intl';

interface ChartData {
  name: string;
  value: number | Decimal;
  fill: string;
}

interface PieChartProps {
	data: ChartData[];
}

interface RenderActiveShapeProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  fill: string;
  payload: {
    name: string;
  };
  percent: number;
  value: number;
}

const renderActiveShape = (props: RenderActiveShapeProps): JSX.Element => {
	const {
		cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor="middle" fill="#0000006f" fontSize={18} fontWeight="bold">
				{`${(percent * 100).toFixed(1)}%`}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{currencyBRLMask(value)}</text>
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
				{payload.name}
			</text>
		</g>
	);
};

export const PieChartComponent = ({ data }: PieChartProps): JSX.Element => {
	const [activeIndex, setActiveIndex] = useState(0);

	const onPieEnter = (_: any, index: number): void => {
		setActiveIndex(index);
	};

	const renderChartSectors = data.map((entry, index) => (
		// eslint-disable-next-line react/no-array-index-key
		<Cell key={`cell-${index}`} fill={data[index].fill} />
	));

	const pieProps = {
		activeIndex,
		activeShape: renderActiveShape,
		data,
		cx: '50%',
		cy: '50%',
		innerRadius: '50%',
		outerRadius: '60%',
		dataKey: 'value',
		onMouseEnter: onPieEnter,
	};

	return (
		<ResponsiveContainer width="100%" height="100%">
			<PieChart>
				<Pie {...pieProps}>
					{renderChartSectors}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
};
