import React, { useState, useCallback, useMemo } from 'react';
import {
	Box, IconButton, Typography, Tooltip, Dialog, DialogContent,
} from '@mui/material';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
	format, subDays, addDays, isToday,
	parseISO,
} from 'date-fns';

interface IDayCarousel {
    onDateChange: (date: Date) => void;
    daysBackAppointment?: string;
    title?: string;
    initialValuesDate?: string;
}

const DayCarousel = ({
	onDateChange,
	daysBackAppointment,
	title,
	initialValuesDate,
}: IDayCarousel): JSX.Element => {
	const initialDate = initialValuesDate ? parseISO(initialValuesDate) : new Date();
	const [currentDate, setCurrentDate] = useState<Date>(initialDate);
	const [openDatePicker, setOpenDatePicker] = useState(false);

	const handlePreviousDay = useCallback(() => {
		setCurrentDate((prev) => {
			const date = subDays(prev, 1);
			onDateChange(date);
			return date;
		});
	}, [onDateChange]);

	const handleNextDay = useCallback(() => {
		setCurrentDate((prev) => {
			const date = addDays(prev, 1);
			onDateChange(date);
			return date;
		});
	}, [onDateChange]);

	const toggleDatePicker = useCallback(() => {
		setOpenDatePicker(!openDatePicker);
	}, [openDatePicker]);

	const handleDateChange = useCallback((date: Date | null) => {
		if (date) {
			setCurrentDate(date);
			onDateChange(date);
		}
		setOpenDatePicker(false);
	}, [onDateChange]);

	const minDate = useMemo(() => (
		daysBackAppointment ? subDays(new Date(), parseInt(daysBackAppointment, 10)) : undefined
	), [daysBackAppointment]);

	const dateDisplay = useMemo(() => (
		<Tooltip title="Exibir calendário">
			<Typography
				variant="subtitle2"
				sx={{ mx: 2, cursor: 'pointer' }}
				onClick={toggleDatePicker}
			>
				{format(currentDate, 'dd/MM/yyyy')}
			</Typography>
		</Tooltip>
	), [currentDate, toggleDatePicker]);

	const calendarPickerDialog = useMemo(() => (
		<Dialog open={openDatePicker} onClose={() => setOpenDatePicker(false)}>
			<DialogContent sx={{ padding: 0 }}>
				<CalendarPicker
					date={currentDate}
					onChange={handleDateChange}
					minDate={minDate}
					maxDate={new Date()}
				/>
			</DialogContent>
		</Dialog>
	), [openDatePicker, currentDate, handleDateChange, minDate]);

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
					{title && (
						<Typography variant="h6">{title}</Typography>
					)}
				</Box>
				<Box sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flex: 1,
				}}
				>
					<IconButton onClick={handlePreviousDay} disabled={minDate && currentDate <= minDate}>
						<ArrowBackIosNewIcon />
					</IconButton>
					{dateDisplay}
					<IconButton onClick={handleNextDay} disabled={isToday(currentDate)}>
						<ArrowForwardIosIcon />
					</IconButton>
				</Box>
				<Box sx={{ flex: 1 }} />
			</Box>
			{calendarPickerDialog}
		</>
	);
};

DayCarousel.defaultProps = {
	daysBackAppointment: null,
	title: null,
	initialValuesDate: null,
};

export default DayCarousel;
