/* eslint-disable import/no-duplicates */
import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { currencyBRLMask } from '../helpers/intl';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';
import { applyCnpjMask } from '../helpers/masks';
import { formatValueOrNA } from '../helpers/Utils';
import { JustificativeType, JustificativeTypeTranslations } from '../enums/JustificativeType';

function formatDate(value: Date | string): string {
	const [date, time] = new Date(value).toISOString().split('T');
	return `${date.split('-').reverse().join('/')} ${time.slice(0, 5)}`;
}

export const defaultColumns: GridColDef[] = [
	{
		field: 'nrOrder',
		headerName: 'Nr do pedido',
		flex: 0.55,
		sortable: true,
		align: 'center',
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'providerName',
		headerName: 'Fornecedor',
		flex: 1.5,
		sortable: true,
		renderCell: (params: GridRenderCellParams<string>) => (
			params.value ? <TruncateTooltipCell value={params.value} /> : 'N/A'),
	},
	{
		field: 'productDescription',
		headerName: 'Produto',
		flex: 1.5,
		sortable: true,
		renderCell: (params: GridRenderCellParams<string>) => (
			params.value ? <TruncateTooltipCell value={params.value} /> : 'N/A'),
	},
	{
		type: 'number',
		field: 'quantity',
		headerName: 'Quantidade',
		flex: 0.5,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		type: 'number',
		field: 'unitValue',
		headerName: 'Valor unitário',
		flex: 0.7,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
	},
	{
		type: 'number',
		field: 'totalValueWithoutDiscount',
		headerName: 'Valor Produtos',
		flex: 0.7,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
	},
	{
		type: 'number',
		field: 'totalDiscount',
		headerName: 'Desconto NF',
		flex: 0.7,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
	},
	{
		type: 'number',
		field: 'totalValue',
		headerName: 'Valor da NF',
		flex: 0.7,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
	},
	{
		type: 'number',
		field: 'lastPurchaseValue',
		headerName: 'Último valor de compra',
		flex: 0.85,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, currencyBRLMask),
	},
	{
		field: 'approvedAt',
		headerName: 'Aprovado em',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, formatDate),
	},
	{
		field: 'branch',
		headerName: 'Filial de Origem',
		flex: 1,
		sortable: true,
		valueGetter: (params) => params.value?.name || 'N/A',
	},
	{
		field: 'deliveryBranch',
		headerName: 'Filial de Entrega',
		flex: 1,
		sortable: true,
		valueGetter: (params) => params.value?.name || 'N/A',
	},
	{
		field: 'costType',
		headerName: 'Tipo de custo',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'createdAt',
		headerName: 'Criado em',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, formatDate),
	},
	{
		field: 'dtIntegrated',
		headerName: 'Data de Integração',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, formatDate),
	},
	{
		field: 'integrated',
		headerName: 'Integrado',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'itemOrder',
		headerName: 'Ordem do item',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'orderIssuance',
		headerName: 'Emissão do Pedido',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, formatDate),
	},
	{
		field: 'OSNumber',
		headerName: 'Número da OS',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'paymentCode',
		headerName: 'Código de pagamento',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'paymentCodeDescription',
		headerName: 'Descrição do código de pagamento',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'hasInvoices',
		headerName: 'Situação da NF',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => (params.value === true ? 'Classificada' : 'Não Classificada'),
	},
	{
		field: 'invoiceNumber',
		headerName: 'Número da NF',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'invoiceSerie',
		headerName: 'Serie da NF',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'invoiceIssuance',
		headerName: 'Emissão da NF',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, formatDate),
	},
	{
		field: 'productCode',
		headerName: 'Código do produto',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'providerCnpj',
		headerName: 'CNPJ do fornecedor',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, applyCnpjMask),
	},
	{
		field: 'providerCode',
		headerName: 'Código do fornecedor',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'providerStore',
		headerName: 'Loja do fornecedor',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'requesterLogin',
		headerName: 'Login do solicitante',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'requesterName',
		headerName: 'Nome do solicitante',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'updatedAt',
		headerName: 'Atualizado em',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params, formatDate),
	},
	{
		field: 'userApproved',
		headerName: 'Usuário aprovador',
		flex: 1,
		sortable: true,
		valueFormatter: (params) => formatValueOrNA(params),
	},
	{
		field: 'justificativeType',
		headerName: 'Tipo de Justificativa',
		flex: 1.5,
		sortable: true,
		renderCell: (params: GridRenderCellParams<JustificativeType>) => (
			params.value != null ? <TruncateTooltipCell value={JustificativeTypeTranslations[params.value]} /> : 'N/A'
		),
	},
	{
		field: 'actionJustificative',
		headerName: 'Justificativa',
		flex: 1.5,
		sortable: true,
		renderCell: (params: GridRenderCellParams<string>) => (
			params.value ? <TruncateTooltipCell value={params.value} /> : 'N/A'),
	},
	{
		field: 'observation',
		headerName: 'Observação',
		flex: 1.5,
		sortable: true,
		renderCell: (params: GridRenderCellParams<string>) => (
			params.value ? <TruncateTooltipCell value={params.value} /> : 'N/A'),
	},
];
