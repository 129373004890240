import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import SaleOrderEditPresentional from '../../components/SaleOrder/SaleOrderEdit';
import { IPaymentTerm, IRangePriceCommission, ISaleOrder } from './SaleOrderAssets';
import { getBusinessPartnersByType, getBusinessPartnersByTypeAdmin, getProductBusinessPartnerDetails } from '../../services/businessPartner';
import { IBusinessPartner, IProductBusinessPartnerDetail, ITopSellingProduct } from '../BusinessPartner/BusinessPartnerAssets';
import {
	getPaymentTerms,
	getRangePriceCommissions,
	getRangePriceCommissionsByValue,
	getSaleOrderById,
	getTopSellingProducts,
	saveSaleOrder,
	updateSaleOrder,
} from '../../services/saleOrder';
import { BusinessPartnerType } from '../../enums/BusinessPartnerType';
import { removeDuplicates } from '../../helpers/Utils';
import usePermission from '../../hooks/usePermission';
import { IModule } from '../../interfaces/Module';

enum ActionType {
	LOADING,
	SALE_ORDER,
	PRODUCTS_BUSINESS_PARTNER,
	PAYMENT_TERMS,
	TOP_SELLING_PRODUCTS,
	CLIENTS,
	TRANSPORTERS,
	RANGE_PRICE_COMISSIONS,
	RANGE_PRICE_BY_VALUE
}

export interface IState {
	count: number;
	saleOrder?: ISaleOrder;
	paymentTerms: IPaymentTerm[];
	productsBusinessPartner: IProductBusinessPartnerDetail[];
	clients: IBusinessPartner[];
	transporters: IBusinessPartner[];
	topSellingProducts: ITopSellingProduct[];
	loading: boolean;
	rangePriceCommissions: IRangePriceCommission[],
	rangePriceComissionsByValue?: IRangePriceCommission,
}

const initialState: IState = {
	count: 0,
	saleOrder: undefined,
	paymentTerms: [],
	productsBusinessPartner: [],
	topSellingProducts: [],
	clients: [],
	transporters: [],
	rangePriceCommissions: [],
	rangePriceComissionsByValue: undefined,
	loading: false,
};

type TAction =
	{
		type: ActionType.PRODUCTS_BUSINESS_PARTNER;
		payload: { productsBusinessPartner: IProductBusinessPartnerDetail[]; count: number }
	}
	| { type: ActionType.SALE_ORDER; payload: { saleOrder: ISaleOrder } }
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.PAYMENT_TERMS; payload: { paymentTerms: IPaymentTerm[]; count: number } }
	| {
		type: ActionType.TOP_SELLING_PRODUCTS;
		payload: { topSellingProducts: ITopSellingProduct[]; count: number }
	}
	| {
		type: ActionType.CLIENTS;
		payload: { clients: IBusinessPartner[]; count: number }
	}
	| {
		type: ActionType.TRANSPORTERS;
		payload: { transporters: IBusinessPartner[]; count: number }
	} | {
		type: ActionType.RANGE_PRICE_COMISSIONS;
		payload: { rangePriceCommissions: IRangePriceCommission[]; count: number }
	} | {
		type: ActionType.RANGE_PRICE_BY_VALUE;
		payload: { rangePriceComissionsByValue: IRangePriceCommission }
	}

export interface ISaleOrderActions {
	setLoading(loading: boolean): void;
	setSaleOrder(saleOrder: ISaleOrder): void;
	getProductBusinessPartnerDetails(businessPartnerId: string, description?: string): void;
	getTransporters(): void;
	getClients(): void;
	getPaymentTerms(): void;
	getTopSellingProducts(businessPartnerId: string, limit: number): void;
	getRangePriceCommissions(products?: string[], paymentTermId?: string): void;
	getRangePriceComissionByValue(productId: string, paymentTermId: string, price: number): void;
	handleEditSaleOrder(values: {
		id?: string;
		data: ISaleOrder;
	}): void;
	getSaleOrderById(id: string): void;
}

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.SALE_ORDER:
			return { ...state, saleOrder: action.payload.saleOrder };
		case ActionType.PRODUCTS_BUSINESS_PARTNER: {
			const newProducts = action.payload.productsBusinessPartner;
			const updatedProducts = removeDuplicates([...state.productsBusinessPartner, ...newProducts], 'id');
			return {
				...state,
				productsBusinessPartner: updatedProducts,
				count: updatedProducts.length,
			};
		}
		case ActionType.CLIENTS:
			return {
				...state,
				clients: action.payload.clients,
				count: action.payload.count,
			};
		case ActionType.TRANSPORTERS:
			return {
				...state,
				transporters: action.payload.transporters,
				count: action.payload.count,
			};
		case ActionType.PAYMENT_TERMS:
			return {
				...state,
				paymentTerms: action.payload.paymentTerms,
				count: action.payload.count,
			};
		case ActionType.TOP_SELLING_PRODUCTS:
			return {
				...state,
				topSellingProducts: action.payload.topSellingProducts,
				count: action.payload.count,
			};
		case ActionType.RANGE_PRICE_COMISSIONS:
			return {
				...state,
				rangePriceCommissions: action.payload.rangePriceCommissions,
				count: action.payload.count,
			};
		case ActionType.RANGE_PRICE_BY_VALUE:
			return {
				...state,
				rangePriceComissionsByValue: action.payload.rangePriceComissionsByValue,
			};
		default:
			throw new Error();
	}
};

const SaleOrderActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
	permission: IModule | undefined,
): ISaleOrderActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setSaleOrder(saleOrder: ISaleOrder) {
			dispatch({ type: ActionType.SALE_ORDER, payload: { saleOrder } });
		},
		handleEditSaleOrder(saleOrder: { id?: string, data: ISaleOrder }) {
			if (saleOrder.id) {
				updateSaleOrder(saleOrder.id, saleOrder.data).then((response) => {
					navigate('/sale-order');
					enqueueSnackbar(response.data.message, { variant: 'success' });
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
			} else {
				saveSaleOrder(saleOrder.data).then((response) => {
					navigate('/sale-order');
					enqueueSnackbar(response.data.message, { variant: 'success' });
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
			}
		},
		getSaleOrderById(id: string) {
			getSaleOrderById(id).then((response) => {
				if (!response.data) {
					enqueueSnackbar('Ordem de venda não encontrada.', {
						variant: 'error',
					});
					navigate('/sale-order');
				}
				actions.setSaleOrder(response.data);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			});
		},
		getTransporters() {
			getBusinessPartnersByType(BusinessPartnerType.DELIVERY)
				.then((response) => {
					dispatch({
						type: ActionType.TRANSPORTERS,
						payload: {
							transporters: response.data.data,
							count: response.data.count,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
		},
		getClients() {
			const fetchBusinessPartners = permission?.isAdmin
				? getBusinessPartnersByTypeAdmin
				: getBusinessPartnersByType;

			fetchBusinessPartners(BusinessPartnerType.CLIENT)
				.then((response) => {
					dispatch({
						type: ActionType.CLIENTS,
						payload: {
							clients: response.data.data,
							count: response.data.count,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{
							variant: 'error',
						},
					);
				});
		},
		getProductBusinessPartnerDetails(businessPartnerId: string, description?: string) {
			actions.setLoading(true);
			getProductBusinessPartnerDetails(businessPartnerId, description)
				.then((response) => {
					dispatch({
						type: ActionType.PRODUCTS_BUSINESS_PARTNER,
						payload: {
							productsBusinessPartner: response.data.data,
							count: response.data.count,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				}).finally(() => actions.setLoading(false));
		},
		getPaymentTerms() {
			getPaymentTerms()
				.then((response) => {
					dispatch({
						type: ActionType.PAYMENT_TERMS,
						payload: {
							paymentTerms: response.data.data,
							count: response.data.count,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
		},
		getTopSellingProducts(businessPartnerId: string, limit: number) {
			getTopSellingProducts(businessPartnerId, limit)
				.then((response) => {
					dispatch({
						type: ActionType.TOP_SELLING_PRODUCTS,
						payload: {
							topSellingProducts: response.data.data,
							count: response.data.count,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
		},
		getRangePriceCommissions(products?: string[], paymentTermId?: string) {
			getRangePriceCommissions(products, paymentTermId)
				.then((response: { data: { data: any; count: number; }; }) => {
					dispatch({
						type: ActionType.RANGE_PRICE_COMISSIONS,
						payload: {
							rangePriceCommissions: response.data.data,
							count: response.data.count,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
		},
		getRangePriceComissionByValue(productId: string, paymentTermId: string, price: number) {
			getRangePriceCommissionsByValue(productId, paymentTermId, price)
				.then((response: { data: any; }) => {
					dispatch({
						type: ActionType.RANGE_PRICE_BY_VALUE,
						payload: {
							rangePriceComissionsByValue: response.data,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
		},
	};

	return actions;
};

const SaleOrderEditContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const permission = usePermission('BUSINESS_PARTNER');

	const actions = useMemo(
		() => SaleOrderActions(dispatch, enqueueSnackbar, navigate, permission),
		[enqueueSnackbar, navigate, permission],
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<SaleOrderEditPresentional {...state} {...actions} />
	);
};

export default SaleOrderEditContainer;
