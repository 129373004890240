import { format } from 'date-fns';
import { productTags, xmlTags } from '../../constants/xmlTags';
import { applyAppropriateMask } from '../masks';
import { htmlTemplate } from './html';

const formatNumbers = (value: string | null | undefined): string => {
	const numericValue = parseFloat(value || '');

	return !Number.isNaN(numericValue)
		? new Intl.NumberFormat('pt-BR', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(numericValue)
		: '';
};

function escapeRegExp(string: string): string {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const extractXMLValues = (
	xmlElement: Element | Document,
	tags: { [key: string]: string },
): { [key: string]: string } => {
	const values: { [key: string]: string } = {};

	Object.keys(tags).forEach((key) => {
		const element = xmlElement.querySelector(key);
		if (element) {
			values[tags[key]] = element.textContent || '';
		} else {
			values[tags[key]] = '';
		}
	});

	return values;
};

const extractProductValues = (
	xmlDoc: Document,
	tagName: string,
	tags: { [key: string]: string },
): Array<{ [key: string]: string }> => {
	const items = xmlDoc.querySelectorAll(tagName);
	const allValues: Array<{ [key: string]: string }> = [];

	items.forEach((item) => {
		const values = extractXMLValues(item, tags);
		allValues.push(values);
	});

	return allValues;
};

export const convertXMLToHTML = (xmlString: string): string => {
	const parser = new DOMParser();
	const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
	const tagValues = extractXMLValues(xmlDoc, xmlTags);

	const allProducts = extractProductValues(xmlDoc, 'det', productTags);

	let productsHTML = '';
	allProducts.forEach((product) => {
		productsHTML += `
		<tr class="tr-dashed-border-below">
		<td class="txtc">${product.cProd}</td>
		<td class="txtc">${product.xProd}</td>
		<td class="txtc">${product.NCM}</td>
		<td class="txtc">${product.orig}/${product.ICMS61CST || '00'}</td>
		<td class="txtc">${product.CFOP}</td>
		<td class="txtc">${product.uCom}</td>
		<td class="txt-right">${String(product.qCom).replace('.', ',')}</td>
		<td class="txt-right">${String(product.vUnCom).replace('.', ',')}</td>
		<td class="txt-right">${formatNumbers(product.vProd)}</td>
		<td class="txt-right">${formatNumbers(product.vDesc)}</td>
		<td class="txt-right">${formatNumbers(product.vBC)}</td>
		<td class="txt-right">${formatNumbers(product.vICMS)}</td>
		<td class="txt-right">${formatNumbers(product.vIPI)}</td>
		<td class="txt-right">${formatNumbers(product.pICMS)}</td>
		<td class="txt-right">${formatNumbers(product.pIPI)}</td>
	  </tr>
  `;
	});

	const formatFunctions: { [key: string]: (value: string) => string } = {
		dhSaiEnt: (dateStr: string) => format(new Date(dateStr), 'dd/MM/yyyy'),
		dhEmi: (dateStr: string) => format(new Date(dateStr), 'dd/MM/yyyy'),
		EmitenteCNPJ: applyAppropriateMask,
		DestinatarioCNPJ: applyAppropriateMask,
		EmitenteCEP: applyAppropriateMask,
		DestinatarioCEP: applyAppropriateMask,
		qCom: formatNumbers,
		vBC: formatNumbers,
		vBCST: formatNumbers,
		vCOFINS: formatNumbers,
		vDesc: formatNumbers,
		vFCP: formatNumbers,
		vFrete: formatNumbers,
		vICMS: formatNumbers,
		vII: formatNumbers,
		vIPI: formatNumbers,
		vNF: formatNumbers,
		vOutro: formatNumbers,
		vPIS: formatNumbers,
		vProd: formatNumbers,
		vProdTotal: formatNumbers,
		vSeg: formatNumbers,
		vST: formatNumbers,
		vTotTrib: formatNumbers,
		vTotTribICMS: formatNumbers,
		vUnCom: formatNumbers,
		vPag: formatNumbers,
		qTrib: formatNumbers,
	};

	let HTML = htmlTemplate(tagValues, productsHTML);

	Object.keys(formatFunctions).forEach((key) => {
		if (tagValues[key]) {
			const formatter = formatFunctions[key];
			const placeholder = `[${key}]`;
			const escapedPlaceholder = escapeRegExp(placeholder);
			HTML = HTML.replace(
				new RegExp(escapedPlaceholder, 'g'),
				formatter(tagValues[key]),
			);
		}
		const escapedTimePlaceholder = escapeRegExp('[dhSaiEntForTime]');
		HTML = HTML.replace(
			new RegExp(escapedTimePlaceholder, 'g'),
			format(new Date(tagValues.dhSaiEnt), 'HH:mm:ss'),
		);
	});

	Object.keys(tagValues).forEach((key) => {
		const placeholder = `[${key}]`;
		const escapedPlaceholder = escapeRegExp(placeholder);
		HTML = HTML.replace(new RegExp(escapedPlaceholder, 'g'), tagValues[key]);
	});

	return HTML;
};
