import React, { useCallback } from 'react';
import {
	Button,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormikContext, FieldArray } from 'formik';
import { IBusinessPartner, IContact } from '../../../containers/BusinessPartner/BusinessPartnerAssets';
import { ITypeContact } from '../../../services/typeContact';
import Input from '../../Common/Form/Input';
import Select from '../../Common/Form/Select';
import { phoneMask } from '../../../helpers/masks';

interface ContactFormProps {
	typeContacts: ITypeContact[];
}

const ContactForm = ({ typeContacts }: ContactFormProps): JSX.Element => {
	const { values } = useFormikContext<IBusinessPartner>();

	const handleAddContact = useCallback((arrayHelpers) => {
		const newContact = {
			name: '',
			email: '',
			phone: '',
			typeContactId: '',
			businessPartnerId: '',
		};
		arrayHelpers.push(newContact);
	}, []);

	const getUnitOptions = useCallback(() => [
		{ value: values.id || values.name, label: 'Matriz', key: values.id },
		...values.units.map((unit, unitIndex) => (
			{ value: unit.id || unit.tempId, label: `Unidade ${unitIndex + 1} - ${unit.marketName || ''}`, key: unit.id || unit.tempId }
		)),
	], [values.id, values.name, values.units]);

	const renderContacts = useCallback((contactsProp, arrayHelpers, unitIndex = null) => {
		const contacts = Array.isArray(contactsProp) ? contactsProp : [];
		return contacts.map((contact: IContact, index: number) => {
			const contactIndex = unitIndex !== null ? `units.${unitIndex}.contacts.${index}` : `contacts.${index}`;
			return (
				<TableRow key={contact.id || `${unitIndex}-${index}`}>
					<TableCell>
						<Select
							name={`${contactIndex}.typeContactId`}
							label=""
							options={typeContacts}
							labelKey="name"
							valueKey="id"
							required
						/>
					</TableCell>
					<TableCell>
						<Input.InputField
							name={`${contactIndex}.name`}
							fullWidth
							required
						/>
					</TableCell>
					<TableCell>
						<Input.InputMaskField
							name={`${contactIndex}.phone`}
							mask={phoneMask}
							fullWidth
							required
						/>
					</TableCell>
					<TableCell>
						<Input.InputField
							name={`${contactIndex}.email`}
							fullWidth
							required
						/>
					</TableCell>
					<TableCell>
						<Select
							name={`${contactIndex}.businessPartnerId`}
							label=""
							options={getUnitOptions()}
							labelKey="label"
							valueKey="value"
						/>
					</TableCell>
					<TableCell>
						<IconButton onClick={() => arrayHelpers.remove(index)} color="error">
							<DeleteIcon />
						</IconButton>
					</TableCell>
				</TableRow>
			);
		});
	}, [getUnitOptions, typeContacts]);

	return (
		<>
			<Typography variant="caption" display="block" sx={{ marginBottom: 2 }}>
				Todos os campos são obrigatórios!
			</Typography>
			<TableContainer
				component={Paper}
				sx={{
					'& .MuiTableCell-root': {
						py: 0.5,
					},
					'& .MuiTableRow-root': {
						height: 'auto',
					},
				}}
			>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Tipo de Contato</TableCell>
							<TableCell>Nome</TableCell>
							<TableCell>Telefone</TableCell>
							<TableCell>E-mail</TableCell>
							<TableCell>Unidade</TableCell>
							<TableCell>Ações</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<FieldArray
							name="contacts"
							render={(arrayHelpers) => (
								<>
									{renderContacts(values.contacts, arrayHelpers)}
									{values.units.map((unit, unitIndex) => (
										<FieldArray
											key={unit.id}
											name={`units.${unitIndex}.contacts`}
											render={(unitArrayHelpers) => (
												renderContacts(unit.contacts, unitArrayHelpers, unitIndex)
											)}
										/>
									))}
									<TableRow>
										<TableCell colSpan={6}>
											<Button onClick={() => handleAddContact(arrayHelpers)} color="primary">
												Adicionar Contato
											</Button>
										</TableCell>
									</TableRow>
								</>
							)}
						/>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default ContactForm;
