import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '../Common/Form/Input';
import {
	ILicense,
	initialValues,
	validationSchema,
} from '../../containers/License/LicenseAssets';

interface LicenseFormProps {
	license?: ILicense;
	onSubmit: (values: ILicense) => void;
	loading: boolean;
}

const LicenseForm: React.FC<LicenseFormProps> = ({
	license,
	onSubmit,
	loading,
}) => {
	const initialValuesLoad = useMemo(() => (license ? {
		identifier: license.identifier,
		license: license.license,
	} : initialValues), [license]);

	return (
		<Formik
			initialValues={initialValuesLoad}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			validateOnChange={false}
			validateOnBlur={false}
		>
			<Form noValidate>
				<Paper sx={{ mt: 3, p: 3 }} elevation={3}>
					<Stack spacing="4rem">
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<Input.InputField
									id="identifier"
									name="identifier"
									label="Identificador"
									required
									fullWidth
								/>
							</Grid>

							<Grid item xs={8}>
								<Input.InputField
									id="license"
									name="license"
									label="Licença"
									required
									fullWidth
								/>
							</Grid>
						</Grid>
						<Box>
							<LoadingButton
								loading={loading}
								variant="contained"
								color="primary"
								type="submit"
							>
								Salvar
							</LoadingButton>
						</Box>
					</Stack>
				</Paper>
			</Form>
		</Formik>
	);
};

LicenseForm.defaultProps = {
	license: undefined,
};

export default LicenseForm;
