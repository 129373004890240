import { Grid } from '@mui/material';
import React from 'react';
import Autocomplete from '../../../Common/Form/Autocomplete';

type Props = {
	accountingAccounts: any[];
	costCenters: any[];
	accountingItems: any[];
	classValues: any[];
}

export const CorporativeLevelAllFields = ({
	accountingAccounts,
	costCenters,
	accountingItems,
	classValues,
}: Props): JSX.Element => (
	<Grid container spacing={2}>
		<Grid item xs={6}>
			<Autocomplete<{name: string; code: string;}>
				multiple
				limitTags={2}
				name="accountingAccounts"
				label="Conta Contábil"
				options={accountingAccounts}
				labelKey="name"
				valueKey="code"
				fullWidth
				sx={{ height: 'fit-content' }}
			/>
		</Grid>
		<Grid item xs={6}>
			<Autocomplete<{name: string; code: string;}>
				multiple
				limitTags={2}
				name="costCenters"
				label="Centro de Custo"
				options={costCenters}
				labelKey="name"
				valueKey="code"
				fullWidth
				sx={{ height: 'fit-content' }}
			/>
		</Grid>
		<Grid item xs={6}>
			<Autocomplete<{name: string; code: string;}>
				multiple
				limitTags={2}
				name="accountingItems"
				label="Filial"
				options={accountingItems}
				labelKey="name"
				valueKey="code"
				fullWidth
				sx={{ height: 'fit-content' }}
			/>
		</Grid>
		<Grid item xs={6}>
			<Autocomplete<{name: string; code: string;}>
				multiple
				limitTags={2}
				name="classValues"
				label="Cliente/Fornecedor"
				options={classValues}
				labelKey="name"
				valueKey="code"
				fullWidth
				sx={{ height: 'fit-content' }}
			/>
		</Grid>
	</Grid>
);
