import React, { useCallback, useMemo, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScaleIcon from '@mui/icons-material/Scale';
import InfoIcon from '@mui/icons-material/Info';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IconButton from '@mui/material/IconButton';
import { taskClockInOutColumns } from '../../../constants/taskClockInOutColumns';
import { Task, UpdateTask } from '../../../containers/HourProject/ApportionmentAssets';
import { convertHoursToDecimal, formatDateAndHours, formatHoursMinutes } from '../../../helpers/Utils';
import { ProjectTaskStatus } from '../../../enums/ProjectTaskStatus';
import ProjectTaskModal from './ProjectTaskModal';
import { taskStatusMessages } from '../../../constants/taskStatus';

interface TaskAccordionProps {
	projectId: string;
	task: Task;
	allTasks: Task[];
	updateProjectTask: (id: string, data: UpdateTask) => void;
}

const TaskAccordion = ({
	projectId,
	task,
	allTasks,
	updateProjectTask,
}: TaskAccordionProps): JSX.Element => {
	const [modalOpen, setModalOpen] = useState(false);

	const handleOpenModal = useCallback(() => {
		setModalOpen(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	const handleApprove = useCallback((taskId: string, quantity: string) => {
		updateProjectTask(taskId, {
			status: ProjectTaskStatus.APPROVED,
			quantity: convertHoursToDecimal(quantity),
		});
		handleCloseModal();
	}, [updateProjectTask, handleCloseModal]);

	const handleReject = useCallback((taskId: string, actionJustificative?: string) => {
		updateProjectTask(taskId, {
			status: ProjectTaskStatus.REJECTED,
			actionJustificative,
		});
		handleCloseModal();
	}, [updateProjectTask, handleCloseModal]);

	const subTasks = useMemo(() => allTasks.filter(
		(subTask) => subTask.projectTaskId === task.id,
	), [allTasks, task.id]);

	const renderSubTasks = useMemo(() => (
		subTasks.map((subTask) => (
			<TaskAccordion
				key={subTask.id}
				projectId={projectId}
				task={subTask}
				allTasks={allTasks}
				updateProjectTask={updateProjectTask}
			/>
		))
	), [subTasks, projectId, allTasks, updateProjectTask]);

	const rows = task.tasksClockInOut.map((item, index) => ({
		id: item.id || index,
		user: item.user,
		dateTime: item.dateTime,
		registeredAt: item.registeredAt,
		geoLocation: item.geoLocation || 'Não disponível',
	}));

	const message = useMemo(() => taskStatusMessages[task.status], [task.status]);

	const projectTaskModalMemo = useMemo(() => (
		<ProjectTaskModal
			task={task}
			open={modalOpen}
			onClose={handleCloseModal}
			onApprove={handleApprove}
			onReject={handleReject}
		/>
	), [task, modalOpen, handleCloseModal, handleApprove, handleReject]);

	return (
		<>
			<Accordion
				sx={{
					width: '100%',
					'& .MuiAccordion-root': {
						width: '100%',
					},
					'& .MuiAccordionDetails-root': {
						padding: 0,
					},
				}}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
						<Box display="flex" alignItems="center" gap={2} flex="1">
							<Tooltip
								title={(
									<>
										<Typography color="inherit">Informações da Tarefa</Typography>
										<Box>
											<Typography variant="body2">
												Descrição:
												{' '}
												{task.description}
											</Typography>
											<Typography variant="body2">
												Início:
												{' '}
												{formatDateAndHours(task.startDate)}
											</Typography>
											<Typography variant="body2">
												Término:
												{' '}
												{formatDateAndHours(task.endDate)}
											</Typography>
										</Box>
									</>
								)}
							>
								<Typography sx={{ cursor: 'help' }}>
									{`${task.code} - ${task.name}`}
								</Typography>
							</Tooltip>
						</Box>
						{message && (
							<>
								<Box sx={{ backgroundColor: '#FFEBE5', p: 1, borderRadius: 4 }}>
									<Typography variant="body2">{message}</Typography>
								</Box>
								<Tooltip title="Clique para aprovar ou rejeitar a tarefa">
									<IconButton onClick={handleOpenModal} color="primary">
										<CheckCircleOutlineIcon />
									</IconButton>
								</Tooltip>
							</>
						)}
						<Tooltip
							title={(
								<Box display="flex" flexDirection="column" alignItems="flex-start">
									<Box display="flex" alignItems="center" gap={0.5}>
										<AccessTimeIcon />
										<Typography variant="body2">
											Quantidade de Horas:
											{' '}
											{formatHoursMinutes(task.quantity)}
										</Typography>
									</Box>
									<Box display="flex" alignItems="center" gap={0.5}>
										<CheckCircleIcon />
										<Typography variant="body2">
											Horas Trabalhadas:
											{' '}
											{formatHoursMinutes(task.workedHours)}
										</Typography>
									</Box>
									<Box display="flex" alignItems="center" gap={0.5}>
										<ScaleIcon />
										<Typography variant="body2">
											Saldo de Horas:
											{' '}
											{formatHoursMinutes(task.hoursBalance)}
										</Typography>
									</Box>
									<Box display="flex" alignItems="center" gap={0.5}>
										<AlarmOnIcon />
										<Typography variant="body2">
											% Horas Realizadas:
											{' '}
											{`${task.completionPercentage || 0}%`}
										</Typography>
									</Box>
								</Box>
							)}
							arrow
						>
							<InfoIcon />
						</Tooltip>
						<Tooltip title={`Mostrando ${task.periodClockInOutCount} registros para o período selecionado. Total de apontamentos para a tarefa: ${task.totalClockInOutCount}.`}>
							<Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>
								{task.periodClockInOutCount}
								/
								{task.totalClockInOutCount}
							</Typography>
						</Tooltip>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					{task.tasksClockInOut.length > 0 && (
						<Box>
							<DataGrid
								autoHeight
								rows={rows}
								columns={taskClockInOutColumns}
								pageSize={5}
								rowsPerPageOptions={[5]}
								density="compact"
							/>
						</Box>
					)}
					{renderSubTasks}
				</AccordionDetails>
			</Accordion>
			{projectTaskModalMemo}
		</>
	);
};

export default TaskAccordion;
