import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import { UserType } from '../../../containers/Order/PurchaseOrderApproval';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import { Loading } from '../../Common/Loading';
import Pagination from '../../Common/Pagination';
import { PageHeaderButtonProps } from '../../../interfaces/PageHeaderInterface';
import { IValueTransferData } from '../../../interfaces/BudgetValueTransfer';
import { ForApprovalQueryParams } from '../../../interfaces/ForApprovalQueryParams';
import PurchaseOrderApprovalTable from './PurchaseOrderApprovalTable';
import BudgetValueTransferModal from '../Modals/BudgetValueTransferModal';
import { PurchaseOrderApprovalFilter } from './PurchaseOrderApprovalFilter';
import ApportionmentDrawer from '../Drawers/ApportionmentDrawer';
import DetailsDrawer from '../Drawers/DetailsDrawer';
import { UserTypeSelector } from '../UserManagement/UserTypeSelector';
import { IFilterFieldsValues } from '../../../interfaces/BudgetOrderFourFields';
import {
	IBudget,
	IPurchaseOrderApproval,
	IPurchasesWithJustificative,
	ISendToOverrun,
} from '../../../interfaces/PurchaseOrderApproval';
import ExpandButton from '../../Common/ExpandButton';

const filters: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-start',
	gap: '1rem',
	mb: '1rem',
};

interface PurchaseOrderApprovalProps {
	loading: boolean;
	showValueTransferModal: boolean;
	loadingApportionments: boolean;
	userTypes: UserType[];
	purchasesPages: number;
	purchasesPage: number;
	purchasesTake: number;
	purchases: IBudget[];
	apportionments: any[];
	budgetsFrom: any[];
	budgetsTo: any[];
	filterFieldsValues: IFilterFieldsValues | null;
	getPurchases(queryParams: ForApprovalQueryParams): void;
	getApportionmentById(id: string): void;
	approvePurchase(budgetId: string, ids: string[], userType?: UserType): void;
	rejectPurchase(data: IPurchasesWithJustificative): void;
	getUserTypes(): void;
	setShowValueTransferModal(showValueTransferModal: boolean): void;
	getBudgetsFrom(userType?: UserType): void;
	getBudgetsTo(userType?: UserType): void;
	sendValueTransfer(data: IValueTransferData): void;
	sendToOverrun(data: ISendToOverrun): void;
	getFilterFieldsValues: (userType?: UserType) => void;
}

const PurchaseOrderApproval = (props: PurchaseOrderApprovalProps): JSX.Element => {
	const {
		loading,
		userTypes,
		purchases,
		purchasesPages,
		purchasesPage,
		purchasesTake,
		showValueTransferModal,
		budgetsFrom,
		budgetsTo,
		apportionments,
		loadingApportionments,
		filterFieldsValues,
		getPurchases,
		getApportionmentById,
		approvePurchase,
		rejectPurchase,
		getUserTypes,
		setShowValueTransferModal,
		getBudgetsFrom,
		getBudgetsTo,
		sendValueTransfer,
		sendToOverrun,
		getFilterFieldsValues,
	} = props;
	const [userType, setUserType] = React.useState(0);
	const [budgetTo, setBudgetTo] = React.useState('');
	const [filter, setFilter] = React.useState({});
	const [openApportionmentId, setOpenApportionmentId] = useState<string>('');
	const [openDetails, setOpenDetails] = useState<IPurchaseOrderApproval | undefined>(undefined);
	const [expandAccordions, setExpandAccordions] = React.useState(false);
	const [selectAll, setSelectAll] = React.useState(false);

	useEffect(() => {
		getPurchases({ skip: 1 });
		getFilterFieldsValues();
		getUserTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setUserType(Math.max(...userTypes));
	}, [userTypes]);

	const handleChangeUserType = useCallback((value: UserType) => {
		const userTypeFilter = { ...filter, type: value, skip: 1 };

		setUserType(value);
		getPurchases(userTypeFilter);
		getFilterFieldsValues(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	const sendFilter = useCallback((values: any) => {
		setSelectAll(values.selectAll);

		const filterValues = {
			...values,
			type: userType,
			skip: 1,
		};
		delete filterValues.selectAll;

		getPurchases(filterValues);
		setFilter(filterValues);
	}, [getPurchases, userType]);

	const openBudgetValueTransferModal = useCallback((selectedBudgetTo) => {
		if (selectedBudgetTo) {
			setBudgetTo(selectedBudgetTo);
		}

		setShowValueTransferModal(true);
	}, [setShowValueTransferModal]);

	const closeBudgetValueTransferModal = useCallback(() => {
		setBudgetTo('');
		setShowValueTransferModal(false);
	}, [setShowValueTransferModal]);

	const pageHeaderMemo = useMemo(() => {
		const buttonsProps: PageHeaderButtonProps[] = [
			{
				text: 'Solicitar transferência de saldo',
				variant: 'contained',
				color: 'primary',
				onClick: () => openBudgetValueTransferModal(''),
				show: userType !== UserType.RESPONSIBLE,
			},
			{
				component: Link,
				variant: 'contained',
				to: '/order',
				startIcon: <SettingsIcon />,
				text: 'Controle de Pedidos',
				show: userType === UserType.CONTROLLERSHIP,
			},
		];

		return <PageHeader title="Gerenciamento de Pedidos" buttons={buttonsProps} />;
	}, [openBudgetValueTransferModal, userType]);

	const onApportionmentClick = useCallback((id: string) => {
		setOpenApportionmentId(id);
		getApportionmentById(id);
	}, [getApportionmentById]);

	const onCloseApportionment = useCallback(() => {
		setOpenApportionmentId('');
	}, []);

	const onDetailsClick = useCallback((id: string) => {
		const selectedOrder = purchases
			.flatMap((purchase) => purchase.purchaseOrderApprovals)
			.find((approval) => approval.id === id);

		setOpenDetails(selectedOrder);
	}, [purchases]);

	const onCloseDetails = useCallback(() => {
		setOpenDetails(undefined);
	}, []);

	const toggleExpand = useCallback(() => {
		setExpandAccordions(!expandAccordions);
	}, [expandAccordions]);

	const onChangePagination = useCallback((page, take) => {
		const pageFilter = {
			...filter, type: userType, skip: page, take,
		};

		getPurchases(pageFilter);
	}, [getPurchases, userType, filter]);

	const expandButton = useMemo(() => (
		<ExpandButton
			expandAccordions={expandAccordions}
			toggleExpand={toggleExpand}
		/>
	), [expandAccordions, toggleExpand]);

	return (
		<div className="content">
			{pageHeaderMemo}
			<Box sx={filters}>
				<UserTypeSelector
					userTypes={userTypes}
					userType={userType}
					onChange={handleChangeUserType}
				/>
				<PurchaseOrderApprovalFilter
					filterFieldsValues={filterFieldsValues}
					sendFilter={sendFilter}
				/>
			</Box>
			{loading && <Loading />}
			{!loading && (
				<PurchaseOrderApprovalTable
					rows={purchases}
					userType={userType}
					approve={approvePurchase}
					reject={rejectPurchase}
					openBudgetValueTransferModal={openBudgetValueTransferModal}
					sendToOverrun={sendToOverrun}
					onApportionmentClick={onApportionmentClick}
					onDetailsClick={onDetailsClick}
					expandAccordions={expandAccordions}
					selectAllRows={selectAll}
				/>
			)}
			<Pagination
				loading={loading}
				count={purchasesPages}
				page={purchasesPage}
				take={purchasesTake}
				onChange={onChangePagination}
			/>
			<BudgetValueTransferModal
				userType={userType}
				showBudgetValueTransferModal={showValueTransferModal}
				closeBudgetValueTransferModal={closeBudgetValueTransferModal}
				getBudgetsFrom={getBudgetsFrom}
				getBudgetsTo={getBudgetsTo}
				budgetsFrom={budgetsFrom}
				budgetsTo={budgetsTo}
				sendValueTransfer={sendValueTransfer}
				budgetTo={budgetTo}
			/>
			<ApportionmentDrawer
				openId={openApportionmentId}
				apportionments={apportionments}
				loadingApportionments={loadingApportionments}
				onClose={onCloseApportionment}
			/>
			<DetailsDrawer
				openDetails={openDetails}
				onClose={onCloseDetails}
			/>
			{expandButton}
		</div>
	);
};

export default PurchaseOrderApproval;
