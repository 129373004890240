import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

type TLoadingProps =
  | {
      bgColor?: string;
      absolute: true;
    }
  | {
      bgColor?: never;
      absolute?: boolean;
    }

const sxLoading: SxProps<Theme> = {
	height: '100%',
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

const sxLoadingAbsolute: SxProps<Theme> = {
	position: 'absolute',
	top: 0,
	left: 0,
	backgroundColor: 'white',
	zIndex: 999,
};

export const Loading = ({ absolute, bgColor }: TLoadingProps): JSX.Element => {
	if (bgColor) {
		sxLoadingAbsolute.backgroundColor = bgColor;
	}

	return (
		<Box sx={absolute ? { ...sxLoading, ...sxLoadingAbsolute } : sxLoading}>
			<CircularProgress />
		</Box>
	);
};
