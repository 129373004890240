import React, { useEffect, useRef } from 'react';
import {
	Box,
	Typography,
	Button,
	Paper,
	IconButton,
} from '@mui/material';
import { ChevronRight, Search } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../Common/Loading';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import Input from '../../Common/Form/Input';
import { formatBarCode, removeBarCodeMask } from '../../../helpers/masks';

interface TransferLocationsProps {
	loading: boolean;
	transferTasks: ITask[];
	handleSelectTask(task: ITask): void;
	handleBack(): void;

}

const initialValues = {
	locationTransferSearch: '',
};

const TransferLocations = ({
	loading,
	handleBack,
	transferTasks,
	handleSelectTask,
}: TransferLocationsProps): JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [transferTasks, loading]);

	const handleValidateLocation = (locationTransferSearch: string): void => {
		const matchTask = transferTasks.find(
			(task) => task.locationOrigin.barCode.toUpperCase()
				=== removeBarCodeMask(locationTransferSearch).toUpperCase(),
		);

		if (matchTask) {
			handleSelectTask(matchTask);
		} else {
			enqueueSnackbar('Localização não encontrada.', {
				variant: 'error',
			});
		}
	};

	const handleBlurOrEnter = (
		event: React.KeyboardEvent<HTMLInputElement>,
		locationTransferSearch: string,
		validateForm: () => void,
	): void => {
		if (event.key === 'Tab' || event.key === 'Enter') {
			event.preventDefault();
			validateForm();
			handleValidateLocation(locationTransferSearch);
		}
	};

	if (loading) {
		return <Loading />;
	}

	if (!loading && transferTasks.length === 0) {
		return (
			<Box sx={{ mt: 2 }}>
				<Typography textAlign="center">Não há pedidos pendentes.</Typography>
				<Button
					sx={{ mt: 4, width: '100%' }}
					size="large"
					variant="contained"
					onClick={handleBack}
				>
					Ir para Tela Inicial
				</Button>
			</Box>
		);
	}

	return (
		<Box padding={1}>
			<Typography variant="h6" align="center">
				Transferências
			</Typography>

			<Paper sx={{ boxShadow: 'none', marginTop: 2, marginBottom: 4 }}>
				<Formik
					initialValues={initialValues}
					onSubmit={(values) => handleValidateLocation(values.locationTransferSearch)}
				>
					{({ values, validateForm }) => (
						<Form>
							<Input.InputField
								id="locationTransferSearch"
								name="locationTransferSearch"
								label="Buscar Localização"
								autoComplete="off"
								format={(value) => formatBarCode(value).toUpperCase()}
								InputProps={{
									endAdornment: (
										<IconButton
											color="primary"
											aria-label="search location"
											type="submit"
										>
											<Search />
										</IconButton>
									),
									inputProps: {
										min: 0,
										step: 'any',
									},
								}}
								fullWidth
								inputRef={inputRef}
								onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleBlurOrEnter(
									event,
									values.locationTransferSearch,
									validateForm,
								)}
							/>
						</Form>
					)}
				</Formik>
			</Paper>

			<Typography variant="overline">
				Siga para as localizações abaixo:
			</Typography>
			{transferTasks.map((task) => (
				<Box
					sx={{
						padding: 2,
						border: 1,
						borderColor: '#bbb',
						bgcolor: 'white',
						borderRadius: 2,
						mb: 2,
						display: 'flex',
						justifyContent: 'space-between',
					}}
					key={task.id}
					onClick={() => handleSelectTask(task)}
				>
					<Typography>{formatBarCode(task.locationOrigin.barCode)}</Typography>
					<ChevronRight color="primary" />
				</Box>
			))}
			<Button variant="contained" sx={{ my: 2 }} onClick={() => navigate('/app')}>
				Voltar
			</Button>
		</Box>
	);
};

export default TransferLocations;
