/* eslint-disable default-param-last */
import Decimal from 'decimal.js';

export const formatNumberForLocale = (
	value: string | number | null,
	decimalPlaces = 2,
	options?: Intl.NumberFormatOptions,
): string => {
	let valueFormatter = '';

	if (value === null || value === undefined) {
		return valueFormatter;
	}

	const decimalValue = new Decimal(value);

	if (decimalValue.isFinite()) {
		const formattedNumber = Number(decimalValue.toFixed(decimalPlaces));
		valueFormatter = new Intl.NumberFormat('pt-BR', {
			...options,
			minimumFractionDigits: decimalPlaces,
			maximumFractionDigits: decimalPlaces,
		}).format(formattedNumber);
	}

	return valueFormatter;
};
