import React, {
	Dispatch,
	Reducer,
	useReducer,
	useMemo,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import AccessGroupPresentational from '../../components/AccessGroup/AccessGroup';
import {
	getAccessGroups,
	deleteAccessGroup,
} from '../../services/accessGroup';
import { IAccessGroup } from './AccessGroupAssets';

enum ActionType {
    LOADING,
    ACCESS_GROUP,
}

interface IState {
    count: number;
    loading: boolean;
    accessGroups: IAccessGroup[];
}

type TAction =
    | { type: ActionType.LOADING; payload: { loading: boolean } }
    | { type: ActionType.ACCESS_GROUP; payload: {
		accessGroups: IAccessGroup[],
		count: number,
	} };

interface IAccessGroupActions {
    setLoading(loading: boolean): void;
    getAccessGroups(): void;
    deleteAccessGroup(id: string): void;
}

const initialState: IState = {
	count: 0,
	loading: false,
	accessGroups: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.ACCESS_GROUP:
			return {
				...state,
				accessGroups: action.payload.accessGroups,
				count: action.payload.count,
			};
		default:
			throw new Error();
	}
};

const AccessGroupActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IAccessGroupActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getAccessGroups() {
			actions.setLoading(true);
			getAccessGroups()
				.then((response) => {
					dispatch({
						type: ActionType.ACCESS_GROUP,
						payload: {
							accessGroups: response.data,
							count: response.data.count,
						},
					});
				})
				.catch(() => {
					enqueueSnackbar('Erro ao carregar a lista de grupos de acesso.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		deleteAccessGroup(id: string) {
			actions.setLoading(true);
			deleteAccessGroup(id)
				.then(() => {
					enqueueSnackbar('Grupo de acesso deletado com sucesso.', {
						variant: 'success',
					});
					actions.getAccessGroups();
				})
				.catch(() => {
					enqueueSnackbar('Erro ao deletar o grupo de acesso.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
	};

	return actions;
};

const AccessGroupContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(() => AccessGroupActions(
		dispatch,
		enqueueSnackbar,
	), [enqueueSnackbar]);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <AccessGroupPresentational {...state} {...actions} />;
};

export default AccessGroupContainer;
