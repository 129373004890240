import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { UserType } from '../../../containers/Order/PurchaseOrderApproval';
import { currencyBRLMask } from '../../../helpers/intl';
import { IValueTransfer, IManageValueTransferData } from '../../../interfaces/BudgetValueTransfer';

const listItemStyle: SxProps<Theme> = {
	flex: '1 1',
};

interface BudgetValueTransferModalProps {
    userType: UserType;
    showManageValueTransferModal: boolean;
    valueTransfers: IValueTransfer[];
    closeManageBudgetValueTransferModal(event: any): void;
	getValueTransfers(userType?: UserType): void;
    sendManageValueTransfer(data: IManageValueTransferData): void;
}

const BudgetValueTransferModal = ({
	userType,
	showManageValueTransferModal,
	valueTransfers,
	closeManageBudgetValueTransferModal,
	getValueTransfers,
	sendManageValueTransfer,
}: BudgetValueTransferModalProps): JSX.Element => {
	const [valueTransferId, setValueTransferId] = useState<string>('');

	useEffect(() => {
		if (showManageValueTransferModal) {
			getValueTransfers(userType);
		}
	}, [getValueTransfers, showManageValueTransferModal, userType]);

	const closeManageBudgetValueTransferModalProxy = useCallback((event, reason?) => {
		if (reason === 'backdropClick') {
			event.preventDefault();
			return;
		}

		setValueTransferId('');
		closeManageBudgetValueTransferModal(event);
	}, [closeManageBudgetValueTransferModal]);

	const handleValueTransferClick = useCallback((id) => {
		setValueTransferId(id);
	}, []);

	const rejectManageValueTransferProxy = useCallback((event) => {
		const data: IManageValueTransferData = {
			id: valueTransferId,
			status: 'REJECTED',
			userType,
		};

		sendManageValueTransfer(data);
		setValueTransferId('');
	}, [valueTransferId, userType, sendManageValueTransfer]);

	const approveManageValueTransferProxy = useCallback((event) => {
		const data: IManageValueTransferData = {
			id: valueTransferId,
			status: 'APPROVED',
			userType,
		};

		sendManageValueTransfer(data);
		setValueTransferId('');
	}, [valueTransferId, userType, sendManageValueTransfer]);

	const content = useMemo(() => {
		if (valueTransfers.length === 0) {
			return <Typography variant="h5" color="Highlight">Não há transferências para serem rejeitadas/aprovadas!</Typography>;
		}

		return (
			<List>
				{
					valueTransfers.map((valueTransfer) => (
						<React.Fragment key={valueTransfer.id}>
							<ListItem disablePadding>
								<ListItemButton
									selected={valueTransferId === valueTransfer.id}
									onClick={() => handleValueTransferClick(valueTransfer.id)}
								>
									<ListItemText sx={listItemStyle} primary={valueTransfer.budgetFrom.accountingAccount.name} secondary="Cta. Contábil" />
									<ListItemText sx={listItemStyle} primary={valueTransfer.budgetFrom.costCenter.name} secondary="C. Custo" />
									<ListItemText sx={listItemStyle} primary={valueTransfer.budgetFrom.accountingItem.name} secondary="Filial" />
									<ListItemText sx={listItemStyle} primary={valueTransfer.budgetFrom.classValue.name} secondary="CliFor" />
									<DoubleArrowIcon sx={{ ...listItemStyle, maxWidth: '50px' }} />
									<ListItemText sx={listItemStyle} primary={valueTransfer.budgetTo.accountingAccount.name} secondary="Cta. Contábil" />
									<ListItemText sx={listItemStyle} primary={valueTransfer.budgetTo.costCenter.name} secondary="C. Custo" />
									<ListItemText sx={listItemStyle} primary={valueTransfer.budgetTo.accountingItem.name} secondary="Filial" />
									<ListItemText sx={listItemStyle} primary={valueTransfer.budgetTo.classValue.name} secondary="CliFor" />
									<Divider orientation="vertical" flexItem sx={{ mr: '10px' }} />
									<ListItemText sx={listItemStyle} primary={currencyBRLMask(valueTransfer.value)} secondary="Valor total" />
								</ListItemButton>
							</ListItem>
							<Divider />
						</React.Fragment>
					))
				}
			</List>
		);
	}, [handleValueTransferClick, valueTransferId, valueTransfers]);

	return (
		<Dialog
			onClose={closeManageBudgetValueTransferModalProxy}
			open={showManageValueTransferModal}
			disableEscapeKeyDown
			maxWidth="lg"
			fullWidth
		>
			<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				Gerenciar Transferência de saldo
				<IconButton
					color="inherit"
					onClick={closeManageBudgetValueTransferModalProxy}
					aria-label="close"
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent dividers>
				{ content }
			</DialogContent>

			<DialogActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Button onClick={closeManageBudgetValueTransferModalProxy}>
					Fechar
				</Button>
				<LoadingButton
					onClick={rejectManageValueTransferProxy}
					endIcon={<CancelIcon />}
					// loading={BudgetValueTransfering}
					loading={false}
					loadingPosition="end"
					variant="contained"
					color="error"
					disabled={!valueTransferId}
					sx={{ marginLeft: 'auto !important' }}
				>
					Rejeitar
				</LoadingButton>
				<LoadingButton
					onClick={approveManageValueTransferProxy}
					endIcon={<CheckCircleIcon />}
					// loading={BudgetValueTransfering}
					loading={false}
					loadingPosition="end"
					variant="contained"
					disabled={!valueTransferId}
				>
					Aprovar
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default BudgetValueTransferModal;
