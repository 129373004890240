/* eslint-disable import/no-duplicates */
/* eslint-disable camelcase */
import React, {
	Dispatch, memo, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import AppointmentPresentational from '../../components/HourProject/Appointment/Appointment';
import {
	createOverflowTask,
	createTaskClockInOut,
	getUserProjectsTasksHours,
	removeTaskClockInOut,
	sendExecutedPercentage,
} from '../../services/hourProject';
import {
	CreateOverflowTaskData,
	HourProject,
	TaskClockInOut,
	UserProjectsTasksHoursParams,
} from './ApportionmentAssets';

enum ActionType {
	LOADING,
	PROJECTS,
}

interface IState {
	loading: boolean;
	projects: HourProject[];
	projectsPages: number;
	projectsPage: number;
	projectsTake: number;
	workedHoursDay: string;
	daysBackAppointment: string;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| {
		type: ActionType.PROJECTS; payload: {
			projects: HourProject[],
			projectsPages: number,
			projectsPage: number,
			projectsTake: number,
			workedHoursDay: string,
			daysBackAppointment: string,
		}
	};

interface IAppointmentActions {
	setLoading(loading: boolean): void;
	getProjects(queryParams: UserProjectsTasksHoursParams): void;
	sendClockAppointment(data: TaskClockInOut): void;
	removeClockAppointment(id: string): void;
	sendExecutedPercentage(taskId: string, value: number): void;
	createOverflowTask(
		data: CreateOverflowTaskData,
		queryParams?: UserProjectsTasksHoursParams,
	): void;
}

const initialState: IState = {
	loading: false,
	projects: [],
	projectsPages: 0,
	projectsPage: 0,
	projectsTake: 10,
	workedHoursDay: '',
	daysBackAppointment: '',
};

let lastQueryParams: UserProjectsTasksHoursParams;

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.PROJECTS:
			return {
				...state,
				...action.payload,
			};
		default:
			throw new Error();
	}
};

const AppointmentActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IAppointmentActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getProjects(queryParams: UserProjectsTasksHoursParams, setLoading?: boolean) {
			actions.setLoading(setLoading !== false);
			const take = queryParams.take ?? 10;
			const skip = queryParams.skip ?? 0;
			const currentPage = Math.floor(skip / take) + 1;
			const params: any = { ...queryParams };
			if (queryParams?.date && queryParams.date !== lastQueryParams?.date) {
				params.date = format(new Date(queryParams.date), 'yyyy-MM-dd', { locale: ptBR });
				lastQueryParams = params;
			}
			getUserProjectsTasksHours(params).then((response) => {
				dispatch({
					type: ActionType.PROJECTS,
					payload: {
						projects: response.data.projects,
						projectsPages: Math.ceil(response.data.count / take),
						projectsPage: currentPage,
						projectsTake: take,
						workedHoursDay: response.data.workedHoursDay,
						daysBackAppointment: response.data.daysBackAppointment,
					},
				});
				actions.setLoading(false);
			}).catch(() => {
				enqueueSnackbar('Erro ao buscar projetos', { variant: 'error' });
			});
		},
		sendClockAppointment(data: TaskClockInOut) {
			const userId = localStorage.getItem('id') ?? '';

			const sendRequest = (geoCoordinates?: string): void => {
				const requestData: TaskClockInOut = { ...data, userId, geoCoordinates };
				createTaskClockInOut(requestData).then((response) => {
					const message = response.data.warning || response.data.message;
					const variant = response.data.warning ? 'warning' : 'success';
					enqueueSnackbar(message, { variant });
					actions.getProjects(lastQueryParams, false);
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
						autoHideDuration: 10000,
					});
				});
			};

			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					const geoCoordinates = `${latitude},${longitude}`;
					sendRequest(geoCoordinates);
				},
				() => {
					sendRequest();
					enqueueSnackbar('Acesso à localização foi negado. Enviando dados sem localização.', { variant: 'warning' });
				},
			);
		},
		removeClockAppointment(id: string) {
			removeTaskClockInOut(id).then((response) => {
				enqueueSnackbar(response?.data.message, { variant: 'success' });
				actions.getProjects(lastQueryParams, false);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Não foi possível remover o apontamento. Tente novamente ou contate o administrador.', {
					variant: 'error',
				});
			});
		},
		sendExecutedPercentage(taskId: string, value: number) {
			sendExecutedPercentage(taskId, value.toString()).then((response) => {
				enqueueSnackbar(response?.data.message, { variant: 'success' });
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Não foi possível salvar o percentual de execução. Tente novamente ou contate o administrador.', {
					variant: 'error',
				});
			});
		},
		createOverflowTask(data: CreateOverflowTaskData, queryParams?: UserProjectsTasksHoursParams) {
			createOverflowTask(data).then((response) => {
				enqueueSnackbar(response?.data.message, { variant: 'success' });
				if (queryParams) {
					actions.getProjects(queryParams, false);
				}
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Não foi possível criar a tarefa. Tente novamente ou contate o administrador.', {
					variant: 'error',
				});
			});
		},
	};

	return actions;
};

const Appointment = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => AppointmentActions(dispatch, enqueueSnackbar),
		[enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <AppointmentPresentational {...state} {...actions} />;
};

export default memo(Appointment);
