import { AxiosResponse } from 'axios';
import api from './api';
import { UserType } from '../containers/Order/PurchaseOrderApproval';
import { DashboardFilter } from '../containers/Dashboards/BudgetDashboardAssets';
import { IManageValueTransferData, IValueTransfer, IValueTransferData } from '../interfaces/BudgetValueTransfer';
import { IFilter, IUpdateBudgetsData } from '../interfaces/BudgetResponsible';
import { BudgetFilter } from '../interfaces/Budget';
import { BudgetApproverQueryParams } from '../interfaces/BudgetApproverQueryParams';

interface IBudgets {
	count: number;
	data: any[];
}

interface IValueTransferMessage extends IValueTransfer {
	message: string;
}

export const getReport = (
	filterData: DashboardFilter,
	type?: UserType,
): Promise<AxiosResponse<any>> => api.get<DashboardFilter, AxiosResponse<any>>(
	'/budget/report',
	{
		params: {
			...filterData,
			type,
		},
	},
);

export const getDetailedBudgetVision = (
	filterData: any,
	vision: string,
	type?: UserType,
): Promise<AxiosResponse<any>> => api.get<DashboardFilter, AxiosResponse<any>>(
	'/budget/reportVision',
	{
		params: {
			...filterData,
			vision,
			type,
		},
	},
);

export const getFilterFieldsValues = (
	type?: UserType,
	configuration?: boolean,
): Promise<AxiosResponse<IBudgets>> => api.get<IBudgets>(
	'/budget/filterFieldsValues',
	{
		params: {
			type,
			configuration,
		},
	},
);

export const getBudgets = (params: BudgetFilter): Promise<AxiosResponse<IBudgets>> => api.get<IBudgets>('/budget', { params });

export const getBudgetsFrom = (type?: UserType): Promise<AxiosResponse<any>> => api.get<any>(`/budget/budgetsFrom${type !== undefined && type !== null ? (`?type=${type}`) : ''}`);

export const getBudgetsTo = (type?: UserType): Promise<AxiosResponse<any>> => api.get<any>(`/budget/budgetsTo${type !== undefined && type !== null ? (`?type=${type}`) : ''}`);

export const getUserTypes = (): Promise<AxiosResponse<any>> => api.get<any>('/budget/userTypes');

export const getValueTransfers = (type?: UserType): Promise<AxiosResponse<any>> => api.get<any>('/budget-value-transfer/waitingApproval', {
	params: {
		type,
	},
});

export const duplicateBudget = (budgetId: string): Promise<AxiosResponse<any>> => api.post<any>('/budget/duplicate', { id: budgetId });

export const sendValueTransfer = (
	data: IValueTransferData,
): Promise<AxiosResponse<IValueTransferMessage>> => api.post<IValueTransferData, AxiosResponse<IValueTransferMessage>>('/budget-value-transfer', data);

export const sendManageValueTransfer = (
	data: IManageValueTransferData,
): Promise<AxiosResponse<IValueTransferMessage>> => api.post<IManageValueTransferData, AxiosResponse<IValueTransferMessage>>('/budget-value-transfer/handle', data);

export const getBudgetResponsible = (
	userId: string,
	filterData?: IFilter,
): Promise<AxiosResponse<any>> => api.get<any>(`budget-responsible/${userId}`, { params: filterData });

export const updateBudgetResponsible = (
	data: IUpdateBudgetsData,
): Promise<AxiosResponse<any & { message: string }>> => api.post<IUpdateBudgetsData, AxiosResponse<any & { message: string }>>('budget-responsible', data);

export const getBudgetApprovers = (filter?: BudgetApproverQueryParams): Promise<AxiosResponse<any>> => api.get<any>('budget-approver', { params: filter });

export const handleBudgetApprovers = (data: any): Promise<AxiosResponse<any>> => api.post<any, AxiosResponse<any>>('budget-approver/batch', data);

export const uploadBudgetFile = (
	formData: any,
): Promise<AxiosResponse<any>> => api.post<any[], AxiosResponse<any>, any[]>(
	'budget/upload',
	formData,
	{
		headers: {
			// eslint-disable-next-line no-underscore-dangle
			'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
		},
	},
);
