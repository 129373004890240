import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import CountUp from 'react-countup';
import { defaultCardSpacing } from '../../../constants/dashboard';
import Card from '../../Common/Card';

interface BudgetSummaryCardsProps {
  budgeted: number;
  realized: number;
  committed: number;
  toApprove: number;
  balance: number;
}

export const BudgetSummaryCards = ({
	budgeted, realized, committed, toApprove, balance,
}: BudgetSummaryCardsProps): JSX.Element => {
	const cardsData = useMemo(() => ([
		{
			title: 'Orçado',
			value: budgeted,
		},
		{
			title: 'Realizado',
			value: realized,
		},
		{
			title: 'Aprovado',
			value: committed,
		},
		{
			title: 'A Aprovar',
			value: toApprove,
		},
		{
			title: 'Saldo',
			value: balance,
		},
	]), [balance, budgeted, committed, realized, toApprove]);

	return (
		<div>
			<Grid container spacing={defaultCardSpacing} columns={30}>
				{cardsData.map((cardData) => (
					<Grid item xs={30} sm={10} xl={6} key={cardData.title}>
						<Card title={cardData.title} sx={{ flex: 1 }}>
							<Typography variant="h5" sx={{ whiteSpace: 'nowrap' }}>
								<CountUp
									end={cardData.value}
									duration={3}
									prefix="R$ "
									decimal=","
									separator="."
									preserveValue
								/>
							</Typography>
						</Card>
					</Grid>
				))}
			</Grid>
		</div>
	);
};
