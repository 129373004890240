import React, { useMemo } from 'react';
import {
	Formik, Form, FieldArray,
} from 'formik';
import difference from 'lodash/difference';
import jwtDecode from 'jwt-decode';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Icon } from '@mui/material';
import Input from '../Common/Form/Input';
import {
	IAccessGroup,
	EditAccessGroup,
	initialValues,
	validationSchema,
	Module,
} from '../../containers/AccessGroup/AccessGroupAssets';
import Autocomplete from '../Common/Form/Autocomplete';
import { ILicense, ILicenseDetails } from '../../containers/License/LicenseAssets';
import Select from '../Common/Form/Select';
import Checkbox from '../Common/Form/Checkbox';

interface AccessGroupFormProps {
	accessGroup?: IAccessGroup;
	users: Array<{ id: string; name: string; login: string }>;
	licenses: ILicense[];
	onSubmit: (values: EditAccessGroup) => void;
	loading: boolean;
}

const AccessGroupForm: React.FC<AccessGroupFormProps> = ({
	accessGroup,
	users,
	licenses,
	onSubmit,
	loading,
}) => {
	const initialValuesLoad = useMemo(
		() => (accessGroup ? {
			name: accessGroup.name,
			licenseId: accessGroup.licenseId,
			usersId: accessGroup.users?.map((user) => user.id) || [],
			modules: accessGroup.modules || [],
		} : initialValues),
		[accessGroup],
	);

	return (
		<Formik
			initialValues={initialValuesLoad}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{({ values }) => {
				const license = licenses.find((currentLicense) => currentLicense.id === values.licenseId);
				const inUse = license && license.accessGroups && license.accessGroups
					.reduce((
						acc: number,
						curr: { _count: { users: number } },
					// eslint-disable-next-line no-underscore-dangle
					) => acc + curr._count.users, 0);
				const details = license && { ...jwtDecode(license?.license), inUse } as ILicenseDetails;
				const removedDiff = difference(initialValuesLoad.usersId, values.usersId).length;
				const addedDiff = difference(values.usersId, initialValuesLoad.usersId).length;
				const availableLicenses = (details
					&& details.nof - (inUse || 0) + removedDiff - addedDiff) || 0;

				return (
					<Form noValidate>
						<Paper sx={{ mt: 3, p: 3 }} elevation={3}>
							<Stack spacing="4rem">
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Input.InputField
											id="name"
											name="name"
											label="Nome"
											required
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<Select
											name="licenseId"
											label="Licença"
											options={licenses}
											labelKey="identifier"
											valueKey="id"
											required
										/>
									</Grid>
									{values.licenseId && (
										<>
											<Grid item xs={4}>
												<Typography variant="subtitle2">Número de Licenças</Typography>
												<Typography>{details?.nof}</Typography>
											</Grid>
											<Grid item xs={4}>
												<Typography variant="subtitle2">Licenças em uso</Typography>
												<Typography>{details?.inUse}</Typography>
											</Grid>
											<Grid item xs={4}>
												<Typography variant="subtitle2">Licenças disponíveis</Typography>
												<Typography>{availableLicenses}</Typography>
											</Grid>
										</>
									)}
									{ values.modules && details && (
										<Grid item xs={12}>
											<Typography variant="h5">Módulos</Typography>
											<TableContainer component={Paper}>
												<Table size="small" aria-label="simple table">
													<TableHead>
														<TableRow sx={{ '& th': { fontSize: '1rem', fontWeight: 500 } }}>
															<TableCell />
															<TableCell>Ícone</TableCell>
															<TableCell>Módulo</TableCell>
															<TableCell>Pode Editar</TableCell>
															<TableCell>Admin</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														<FieldArray name="modules">
															{({ push, remove }) => (
																details.mod.map((module) => {
																	const currentModule = (values.modules as Module[])
																		.findIndex((mod) => mod.module.code === module.code);

																	return (
																		<TableRow key={module.code}>
																			<TableCell>
																				<Switch
																					checked={currentModule > -1}
																					onChange={(
																						ev,
																						checked,
																					) => (checked ? push({ module }) : remove(currentModule))}
																				/>
																			</TableCell>
																			<TableCell>
																				<Icon>{module.icon}</Icon>
																			</TableCell>
																			<TableCell>
																				{module.name}
																			</TableCell>
																			<TableCell>
																				{currentModule > -1 && (
																					<Checkbox.SingleCheckbox
																						name={`modules.${currentModule}.canEdit`}
																					/>
																				)}
																			</TableCell>
																			<TableCell>
																				{currentModule > -1 && (
																					<Checkbox.SingleCheckbox
																						name={`modules.${currentModule}.isAdmin`}
																					/>
																				)}
																			</TableCell>
																		</TableRow>
																	);
																})
															)}
														</FieldArray>
													</TableBody>
												</Table>
											</TableContainer>
										</Grid>
									)}
									<Grid item xs={12}>
										<Autocomplete<{ name: string; id: string; login: string }>
											multiple
											limitTags={details?.nof || 2}
											label="Usuários"
											name="usersId"
											options={users || []}
											labelKey="name"
											valueKey="id"
											valueLabel="login"
											disableAllOptions={availableLicenses <= 0}
										/>
									</Grid>
								</Grid>
								<Box>
									<LoadingButton
										loading={loading}
										variant="contained"
										color="primary"
										type="submit"
									>
										Salvar
									</LoadingButton>
								</Box>
							</Stack>
						</Paper>
					</Form>
				);
			}}
		</Formik>
	);
};

AccessGroupForm.defaultProps = {
	accessGroup: undefined,
};

export default AccessGroupForm;
