/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo, CSSProperties } from 'react';
import {
	TextField,
	TextFieldProps,
	InputAdornment,
	IconButton,
} from '@mui/material';
import { useField } from 'formik';
import { IMaskInput, IMask } from 'react-imask';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import NumberFormat from 'react-number-format';

interface FinancialInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  decimalScale?: number;
  isPercentage?: boolean;
  maxValue?: number;
  textAlign?: CSSProperties['textAlign'];
}

const InputField = ({
	name,
	defaultValue,
	helperText,
	maxLength,
	textAlign,
	format,
	onBlur,
	onChange,
	...props
}: TextFieldProps
& {
	format?: (value: string) => string | number,
	maxLength?: number,
	textAlign?: CSSProperties['textAlign'],
}): JSX.Element => {
	const [field, meta, helpers] = useField({
		name: name as string,
		defaultValue: defaultValue as string,
	});

	const helperTextMemo = useMemo(() => {
		if (Boolean(meta.touched) && Boolean(meta.error)) {
			return meta.error;
		}
		if (helperText) {
			return helperText;
		}
		if (maxLength && field.value) {
			const characterCount = maxLength - field.value.length;
			return `${characterCount} caracteres restantes`;
		}
		return null;
	}, [meta.touched, meta.error, helperText, maxLength, field.value]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): any => {
		let { value } = event.target;

		if (props.type === 'number') {
			value = value.replace(',', '.');
			if (value === '' || Number.isNaN(Number(value)) || !Number.isFinite(Number(value))) {
				value = '0';
			} else {
				value = value.replace(/^0+(?=\d)/, '');
			}
		}

		helpers.setValue(format ? format(value) : value);
		if (onChange) {
			onChange(event);
		}
	};

	const buildValueInput = (value: string | number) => {
		if (props.type === 'number' && Number.isNaN(Number(value))) {
			return '';
		}

		return value;
	};

	return (
		<TextField
			{...props}
			{...field}
			onChange={handleChange}
			fullWidth
			error={Boolean(meta.touched) && Boolean(meta.error)}
			helperText={helperTextMemo}
			value={buildValueInput(field.value)}
			inputProps={{
				...props.inputProps,
				maxLength,
				style: { textAlign },
			}}
			onBlur={onBlur}
		/>
	);
};

InputField.defaultProps = {
	format: null,
	maxLength: null,
	textAlign: 'left',
};

const PasswordInputField = ({
	margin,
	...props
}: TextFieldProps): JSX.Element => {
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = (): void => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>,
	): void => {
		event.preventDefault();
	};

	return (
		<InputField
			{...props}
			type={showPassword ? 'text' : 'password'}
			margin={margin || 'normal'}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => {
								handleClickShowPassword();
							}}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	);
};

export const FinancialInput = React.forwardRef<
  NumberFormat<number>,
  FinancialInputProps
>((props, ref) => {
	const {
		onChange,
		decimalScale,
		isPercentage,
		maxValue,
		...other
	} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values: any) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator="."
			decimalSeparator=","
			decimalScale={decimalScale}
			fixedDecimalScale
			isNumericString
			prefix={isPercentage ? '' : 'R$ '}
			suffix={isPercentage ? '%' : ''}
			isAllowed={(values) => {
				const { floatValue } = values;
				if (maxValue !== undefined) {
					return floatValue === undefined || floatValue <= maxValue;
				}
				return true;
			}}
		/>
	);
});

FinancialInput.defaultProps = {
	decimalScale: 2,
	isPercentage: false,
	maxValue: undefined,
	textAlign: 'right',
};

const FinancialInputField = ({
	decimalScale,
	isPercentage,
	maxValue,
	textAlign,
	onBlur,
	...props
}: TextFieldProps & {
	decimalScale?: number;
	isPercentage?: boolean;
	maxValue?: number,
	textAlign?: CSSProperties['textAlign']
}) => {
	const [field, meta, helpers] = useField(props.name || '');

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let { value } = e.target;

		if (value === '' || Number.isNaN(Number(value)) || !Number.isFinite(Number(value))) {
			value = '0';
		} else {
			value = value.replace(/^0+(?=\d)/, '');
		}

		helpers.setValue(value);
	};

	return (
		<TextField
			{...props}
			{...field}
			onChange={handleChange}
			error={Boolean(meta.touched && meta.error)}
			helperText={meta.touched && meta.error ? meta.error : ''}
			InputProps={{
				// eslint-disable-next-line react/destructuring-assignment
				...props.InputProps,
				inputComponent: FinancialInput as any,
				inputProps: {
					decimalScale,
					isPercentage,
					maxValue,
					style: { textAlign },
				},
			}}
			onBlur={onBlur}
		/>
	);
};

FinancialInputField.defaultProps = {
	decimalScale: 2,
	isPercentage: false,
	maxValue: undefined,
	textAlign: 'right',
};

interface IMask {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: any;
  definitions?: any;
  overwrite?: boolean;
}

const Mask = React.forwardRef<IMask.MaskElement, IMask>((props, ref) => {
	const {
		onChange, mask, definitions, overwrite, ...other
	} = props;

	const handleChange = (value: unknown): void => {
		if (typeof value === 'string') {
			const numbers = value.match(/\d+/g) || [];
			const cleanedValue = numbers.join('');
			onChange({ target: { name: props.name, value: cleanedValue } });
		}
	};

	return (
		<IMaskInput
			{...other}
			mask={mask}
			definitions={definitions}
			inputRef={ref}
			onAccept={handleChange}
			overwrite={overwrite}
		/>
	);
});

Mask.defaultProps = {
	definitions: null,
	overwrite: false,
};

interface IInputMaskField {
  mask: string | { mask: string }[] | any;
  definitions?: any | any[];
  overwrite?: boolean;
}

type TInputMaskField = TextFieldProps & IInputMaskField;

const InputMaskField = ({
	InputProps,
	mask,
	definitions,
	overwrite,
	onBlur,
	...props
}: TInputMaskField): JSX.Element => (
	<InputField
		{...props}
		InputProps={{
			...InputProps,
			inputComponent: Mask as any,
			inputProps: {
				mask,
				definitions,
				overwrite,
			},
			onBlur,
		}}
	/>
);

InputMaskField.defaultProps = {
	definitions: null,
	overwrite: false,
};

export default {
	InputField: React.memo(InputField),
	InputMaskField: React.memo(InputMaskField),
	PasswordInputField: React.memo(PasswordInputField),
	FinancialInputField: React.memo(FinancialInputField),
};
