import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ProductEdit from '../../components/Product/ProductEdit';
import { IMeasure, IProduct, IProductType } from './ProductAssets';
import {
	getProductById, updateProduct, createProduct, getAccountingAccounts, getMeasures, getProductTypes,
} from '../../services/product';
import { getCompaniesWithoutDetails } from '../../services/company';
import { ICompanyWithoutDetails } from '../Company/CompanyAssets';
import { IBranch } from '../Branch/BranchAssets';
import { getBranches } from '../../services/branch';
import { IFilterField } from '../../interfaces/BudgetOrderFourFields';

enum ActionType {
	LOADING,
	PRODUCT,
	BRANCH,
	COMPANY,
	ACCOUNTING_ACCOUNT,
	MEASURE,
	TYPE,
}
export interface IState {
	loading: boolean;
	product?: IProduct;
	branches: IBranch[];
	companies: ICompanyWithoutDetails[];
	accountingAccounts: IFilterField[];
	measures: IMeasure[];
	productTypes: IProductType[];
}
type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.PRODUCT; payload: { product: IProduct } }
	| { type: ActionType.BRANCH; payload: { branches: IBranch[] } }
	| { type: ActionType.COMPANY; payload: { companies: ICompanyWithoutDetails[] } }
	| { type: ActionType.ACCOUNTING_ACCOUNT; payload: { accountingAccounts: IFilterField[] } }
	| { type: ActionType.MEASURE; payload: { measures: IMeasure[] } }
	| { type: ActionType.TYPE; payload: { productTypes: IProductType[] } };
export interface IProductActions {
	setLoading(loading: boolean): void;
	setProduct(product: IProduct): void;
	getCompanies(): void;
	getBranches(): void;
	getAccountingAccounts(): void;
	getMeasures(): void;
	getProductTypes(): void;
	handleEditProduct: (params: {
		id?: string;
		data: FormData;
	}, product?: IProduct) => void;
	getProductById(id: string): void;
}
const initialState: IState = {
	loading: false,
	product: undefined,
	branches: [],
	companies: [],
	accountingAccounts: [],
	measures: [],
	productTypes: [],
};
const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.PRODUCT:
			return { ...state, product: action.payload.product };
		case ActionType.COMPANY:
			return { ...state, companies: action.payload.companies };
		case ActionType.BRANCH:
			return { ...state, branches: action.payload.branches };
		case ActionType.ACCOUNTING_ACCOUNT:
			return { ...state, accountingAccounts: action.payload.accountingAccounts };
		case ActionType.MEASURE:
			return { ...state, measures: action.payload.measures };
		case ActionType.TYPE:
			return { ...state, productTypes: action.payload.productTypes };
		default:
			throw new Error();
	}
};

const ProductActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IProductActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setProduct(product: IProduct) {
			dispatch({ type: ActionType.PRODUCT, payload: { product } });
		},
		handleEditProduct(values: {
			id?: string;
			data: FormData;
		}): void {
			actions.setLoading(true);
			const { id, data } = values;

			if (id) {
				updateProduct(id, data)
					.then((response) => {
						navigate('/product');
						enqueueSnackbar(response.data.message, { variant: 'success' });
					})
					.catch((error: AxiosError) => {
						enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
							variant: 'error',
						});
						actions.setLoading(false);
					});
			} else {
				createProduct(data)
					.then((response) => {
						navigate('/product');
						enqueueSnackbar(response.data.message, { variant: 'success' });
					})
					.catch((error: AxiosError) => {
						enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
							variant: 'error',
						});
						actions.setLoading(false);
					});
			}
		},
		getProductById(id: string) {
			actions.setLoading(true);
			getProductById(id)
				.then((response) => {
					if (!response.data) {
						enqueueSnackbar('Produto não encontrado.', {
							variant: 'error',
						});
						navigate('/product');
					}
					actions.setProduct(response.data);
					actions.setLoading(false);
				})
				.catch(() => {
					actions.setLoading(false);
				});
		},
		getCompanies() {
			dispatch({ type: ActionType.LOADING, payload: { loading: true } });
			getCompaniesWithoutDetails().then((response) => {
				dispatch({
					type: ActionType.COMPANY,
					payload: { companies: response.data.data },
				});
				dispatch({ type: ActionType.LOADING, payload: { loading: false } });
			});
		},
		getBranches() {
			dispatch({ type: ActionType.LOADING, payload: { loading: true } });
			getBranches().then((response) => {
				dispatch({
					type: ActionType.BRANCH,
					payload: {
						branches: response.data.data,
					},
				});
				dispatch({ type: ActionType.LOADING, payload: { loading: false } });
			});
		},
		getAccountingAccounts() {
			dispatch({ type: ActionType.LOADING, payload: { loading: true } });
			getAccountingAccounts()
				.then((response) => {
					dispatch({
						type: ActionType.ACCOUNTING_ACCOUNT,
						payload: { accountingAccounts: response.data },
					});
					dispatch({ type: ActionType.LOADING, payload: { loading: false } });
				});
		},
		getMeasures() {
			dispatch({ type: ActionType.LOADING, payload: { loading: true } });
			getMeasures()
				.then((response) => {
					dispatch({
						type: ActionType.MEASURE,
						payload: {
							measures: response.data.data,
						},
					});
					dispatch({ type: ActionType.LOADING, payload: { loading: false } });
				});
		},
		getProductTypes() {
			dispatch({ type: ActionType.LOADING, payload: { loading: true } });
			getProductTypes()
				.then((response) => {
					dispatch({
						type: ActionType.TYPE,
						payload: {
							productTypes: response.data.data,
						},
					});
					dispatch({ type: ActionType.LOADING, payload: { loading: false } });
				});
		},
	};
	return actions;
};

const ProductEditContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => ProductActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <ProductEdit {...state} {...actions} />;
};
export default ProductEditContainer;
