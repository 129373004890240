import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import Decimal from 'decimal.js';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import {
	uploadBudgetFile,
	getReport,
	getFilterFieldsValues,
	getDetailedBudgetVision,
	getUserTypes,
	getValueTransfers,
	sendManageValueTransfer,
} from '../../services/budget';
import { getPurchasesCount } from '../../services/purchaseOrderApproval';
import BudgetDashboardPresentational from '../../components/Dashboards/Budget/BudgetDashboard';
import { DashboardFilter } from './BudgetDashboardAssets';
import { budgetDashboardInitialFilter, dataGridVisions } from '../../constants/dashboard';
import { UserType } from '../Order/PurchaseOrderApproval';
import { IValueTransfer, IManageValueTransferData } from '../../interfaces/BudgetValueTransfer';

enum ActionType {
	LOADING,
	SHOW_UPLOAD_MODAL,
	UPLOADING,
	BUDGETS,
	BUDGET_DATA,
	FILTER_FIELDS_VALUES,
	DASHBOARD_FILTER,
	SELECTED_VISION,
	USER_TYPE,
	PURCHASES_COUNT,
	SHOW_MANAGE_VALUE_TRANSFER_MODAL,
	VALUE_TRANSFERS,
}

interface IState {
	loading: boolean;
	budgets: any | null;
	budgetData: any;
	filterFieldsValues: any;
	showUploadModal: boolean;
	uploading: boolean;
	dashboardFilter: DashboardFilter;
	selectedVision: any;
	userTypes: UserType[];
	purchasesCount: number;
	showManageValueTransferModal: boolean;
	valueTransfers: IValueTransfer[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.SHOW_UPLOAD_MODAL; payload: { showUploadModal: boolean } }
	| { type: ActionType.UPLOADING; payload: { uploading: boolean } }
	| { type: ActionType.BUDGETS; payload: { budgets: any | null } }
	| { type: ActionType.BUDGET_DATA; payload: { budgetData: any } }
	| { type: ActionType.FILTER_FIELDS_VALUES; payload: { filterFieldsValues: boolean } }
	| { type: ActionType.DASHBOARD_FILTER; payload: { dashboardFilter: DashboardFilter } }
	| { type: ActionType.SELECTED_VISION; payload: { selectedVision: any } }
	| { type: ActionType.USER_TYPE; payload: { userTypes: UserType[] } }
	| { type: ActionType.PURCHASES_COUNT; payload: { purchasesCount: number } }
	| { type: ActionType.SHOW_MANAGE_VALUE_TRANSFER_MODAL; payload: {
		showManageValueTransferModal: boolean
	} }
	| { type: ActionType.VALUE_TRANSFERS; payload: { valueTransfers: IValueTransfer[] } };

interface IDashboardActions {
	setLoading(loading: boolean): void;
    setShowUploadModal(showUploadModal: boolean): void;
    setUploadingFile(uploading: boolean): void;
	setSelectedVision(selectedVision: any): void;
    uploadBudgetFile(formData: FormData): void;
    getFilterFieldsValues(type?: UserType): void;
	getReport(filter: DashboardFilter, type?: UserType): void;
    getDetailedBudgetVision(filter: DashboardFilter, vision: string, type?: UserType): void;
    setDashboardFilter(dashboardFilter: DashboardFilter): void;
	handleDashboardFilter(filter: DashboardFilter, vision: string, type?: UserType): void;
    getUserTypes(): void;
    getPurchasesCount(type?: UserType): void;
    setShowManageValueTransferModal(showManageValueTransferModal: boolean): void;
    getValueTransfers(type?: UserType): void;
    sendManageValueTransfer(data: IManageValueTransferData): void;
}

const initialState: IState = {
	loading: false,
	showUploadModal: false,
	uploading: false,
	budgets: null,
	budgetData: undefined,
	filterFieldsValues: null,
	dashboardFilter: budgetDashboardInitialFilter,
	selectedVision: dataGridVisions[0],
	userTypes: [],
	purchasesCount: 0,
	showManageValueTransferModal: false,
	valueTransfers: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.SHOW_UPLOAD_MODAL:
			return { ...state, showUploadModal: action.payload.showUploadModal };
		case ActionType.UPLOADING:
			return { ...state, uploading: action.payload.uploading };
		case ActionType.BUDGETS:
			return { ...state, budgets: action.payload.budgets };
		case ActionType.BUDGET_DATA:
			return { ...state, budgetData: action.payload.budgetData };
		case ActionType.FILTER_FIELDS_VALUES:
			return { ...state, filterFieldsValues: action.payload.filterFieldsValues };
		case ActionType.DASHBOARD_FILTER:
			return { ...state, dashboardFilter: action.payload.dashboardFilter };
		case ActionType.SELECTED_VISION:
			return { ...state, selectedVision: action.payload.selectedVision };
		case ActionType.USER_TYPE:
			return { ...state, userTypes: action.payload.userTypes };
		case ActionType.PURCHASES_COUNT:
			return { ...state, purchasesCount: action.payload.purchasesCount };
		case ActionType.SHOW_MANAGE_VALUE_TRANSFER_MODAL:
			return {
				...state,
				showManageValueTransferModal: action.payload.showManageValueTransferModal,
			};
		case ActionType.VALUE_TRANSFERS:
			return { ...state, valueTransfers: action.payload.valueTransfers };
		default:
			throw new Error();
	}
};

const DashboardActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IDashboardActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setShowUploadModal(showUploadModal: boolean) {
			dispatch({
				type: ActionType.SHOW_UPLOAD_MODAL,
				payload: { showUploadModal },
			});
		},
		setUploadingFile(uploading: boolean) {
			dispatch({ type: ActionType.UPLOADING, payload: { uploading } });
		},
		setSelectedVision(selectedVision: any) {
			dispatch({ type: ActionType.SELECTED_VISION, payload: { selectedVision } });
		},
		uploadBudgetFile(formData: FormData) {
			actions.setUploadingFile(true);

			uploadBudgetFile(formData)
				.then((response) => {
					actions.setUploadingFile(false);
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
				})
				.catch((error: AxiosError<{ error: string }>) => {
					actions.setUploadingFile(false);
					enqueueSnackbar(error.response?.data.error, {
						variant: 'error',
					});
				});
		},
		getFilterFieldsValues(type?: UserType) {
			getFilterFieldsValues(type).then((response: any) => {
				dispatch({
					type: ActionType.FILTER_FIELDS_VALUES,
					payload: { filterFieldsValues: response.data },
				});
			});
		},
		getReport(filter: DashboardFilter, type?: UserType) {
			actions.setLoading(true);

			getReport(filter, type).then((response: any) => {
				actions.setLoading(false);
				dispatch({ type: ActionType.BUDGETS, payload: { budgets: response.data } });
			});
		},
		getDetailedBudgetVision(filter: DashboardFilter, vision: string, type?: UserType) {
			actions.setLoading(true);

			getDetailedBudgetVision(filter, vision, type).then((response: any) => {
				actions.setLoading(false);
				dispatch({ type: ActionType.BUDGET_DATA, payload: { budgetData: response.data } });
			});
		},
		setDashboardFilter(dashboardFilter: DashboardFilter) {
			dispatch({ type: ActionType.DASHBOARD_FILTER, payload: { dashboardFilter } });
		},
		handleDashboardFilter(filter: DashboardFilter, vision: string, type?: UserType) {
			actions.setDashboardFilter(filter);
			actions.getDetailedBudgetVision(filter, vision, type);
			actions.getReport(filter, type);
		},
		getUserTypes() {
			getUserTypes().then((userTypes) => {
				dispatch({
					type: ActionType.USER_TYPE,
					payload: { userTypes: userTypes.data.data },
				});
			});
		},
		getPurchasesCount(type?: UserType) {
			getPurchasesCount(type).then((response) => {
				dispatch({
					type: ActionType.PURCHASES_COUNT,
					payload: { purchasesCount: response.data.count },
				});
			});
		},
		setShowManageValueTransferModal(showManageValueTransferModal: boolean) {
			dispatch({
				type: ActionType.SHOW_MANAGE_VALUE_TRANSFER_MODAL,
				payload: { showManageValueTransferModal },
			});
		},
		getValueTransfers(type?: UserType) {
			getValueTransfers(type).then((valueTransfers) => {
				const data = valueTransfers.data.data.map((valueTransferData: IValueTransfer) => {
					const valueTransfer = valueTransferData;

					valueTransfer.value = new Decimal(valueTransfer.value);
					return valueTransfer;
				});

				dispatch({
					type: ActionType.VALUE_TRANSFERS,
					payload: { valueTransfers: data },
				});
			});
		},
		sendManageValueTransfer(data: IManageValueTransferData) {
			sendManageValueTransfer(data)
				.then((response) => {
					actions.getValueTransfers(data.userType);
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
				})
				.catch((error: AxiosError<{ message: string }>) => {
					enqueueSnackbar(error.response?.data.message, {
						variant: 'error',
					});
				});
		},
	};

	return actions;
};

const BudgetDashboard = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => DashboardActions(dispatch, enqueueSnackbar),
		[enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <BudgetDashboardPresentational {...state} {...actions} />;
};

export default BudgetDashboard;
