import React, { useState, useCallback, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';

interface ActionJustificativeModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (justification: string) => void;
}

const justificativeMaxLength = 100;

const Transition = React.forwardRef((
	props: TransitionProps & { children: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
	// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

const ActionJustificativeModal: React.FC<ActionJustificativeModalProps> = ({
	open,
	onClose,
	onConfirm,
}) => {
	const [justificative, setJustificative] = useState('');
	const [disableConfirm, setDisableConfirm] = useState(true);

	const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setJustificative(value);
		setDisableConfirm(value.trim().length === 0);
	}, []);

	const handleConfirm = useCallback(() => {
		onConfirm(justificative);
		setJustificative('');
		setDisableConfirm(true);
	}, [onConfirm, justificative]);

	const { icon, color } = useMemo(() => ({ icon: <WarningIcon sx={{ mr: 1 }} />, color: 'error' as const }), []);

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-labelledby="confirmation-dialog-title"
			aria-describedby="confirmation-dialog-description"
		>
			<DialogTitle id="confirmation-dialog-title">
				<Typography color={color} display="flex" alignItems="center">
					{icon}
					{' '}
					Confirmar Cancelamento
				</Typography>
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="confirmation-dialog-description">
					Para cancelar a ordem de venda adicione uma justificativa.
					Essa ação não poderá ser desfeita.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="justificative"
					label="Justificativa"
					placeholder="Justificativa..."
					type="text"
					multiline
					rows={6}
					fullWidth
					value={justificative}
					onChange={handleChange}
					inputProps={{
						maxLength: justificativeMaxLength,
						style: { fontSize: '18px' },
					}}
					InputLabelProps={{
						style: { fontSize: '17px' },
					}}
					required
				/>
				<FormHelperText>
					{`${justificativeMaxLength - justificative.length} caracteres restantes`}
				</FormHelperText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancelar</Button>
				<Button onClick={handleConfirm} color={color} variant="contained" autoFocus disabled={disableConfirm}>
					Confirmar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ActionJustificativeModal;
