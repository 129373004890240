interface PrintLabelTemplateProps {
  svgContent: string;
}

const PrintLabelTemplate = ({ svgContent }: PrintLabelTemplateProps): string => `
    <html>
      <head>
        <title>Impressão de Código de Barras</title>
        <style>
          @media print {
            @page {
              margin: 0;
            }
            body {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
              margin: 0;
            }
            svg {
              width: 100%;
              height: 100px;
              display: block;
              margin: 0 auto;
            }
          }
          body, html {
            margin: 0;
            padding: 0;
            text-align: center;
          }
          .column {
            display: flex;
            flex-direction: column;
            align-items: center;
            page-break-inside: avoid;
          }
          img {
            width: 100px;
          }
        </style>
      </head>
      <body>
        <div class="column">
          <img src="/api/configuration/logo" alt="Logo" />
          ${svgContent}
        </div>
      </body>
    </html>
  `;

export default PrintLabelTemplate;
