import * as Yup from 'yup';
import '../../helpers/yupMethods';
import { validationMessage } from '../../helpers/yupMessages';
import { User } from '../User/UserAssets';

export interface AccessModule {
    code: string;
    link: string;
    icon: string;
    name: string;
}

export interface Module {
    module: AccessModule;
    canEdit: boolean;
    isAdmin: boolean;
}

export interface IAccessGroup {
    id?: string;
    licenseId: string;
    modules: Module[] | string;
    name: string;
    users?: User[];
}

export interface IAccessGroupResponse {
	data: IAccessGroup[];
	count: number;
}

export interface EditAccessGroup {
    name: string;
    modules: Module[] | string;
    licenseId: string;
    usersId: string[];
}

export const initialValues: EditAccessGroup = {
	name: '',
	modules: [],
	licenseId: '',
	usersId: [],
};

export const validationSchema: Yup.AnySchema = Yup.object().shape({
	name: Yup.string().required(validationMessage.required),
	licenseId: Yup.string().required(validationMessage.required),
	usersId: Yup.array().min(1, validationMessage.array.min(1)),
});
