/* eslint-disable react/jsx-props-no-spreading */
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	NavigateFunction,
	useNavigate,
} from 'react-router-dom';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import InventoryPresentational from '../../components/Inventory/Inventory';
import { ICompanyWithoutDetails } from '../Company/CompanyAssets';
import { IBranch } from '../Branch/BranchAssets';
import { getBranches } from '../../services/branch';
import { getCompaniesWithoutDetails } from '../../services/company';
import {
	BranchParams,
	IInventory,
	InventoryQueryParams,
	InventoryType,
	ParamsStartInventory,
} from './InventoryAssets';
import {
	getInventories,
	startInventoryAll,
} from '../../services/inventory';

enum ActionType {
	LOADING,
	LOADING_INVENTORY,
	COMPANY,
	BRANCH,
	INVENTORY,
	START_MODAL,
}

interface IState {
	loading: boolean;
	loadingInventory: boolean;
	startModal: boolean;
	branches: IBranch[];
	companies: ICompanyWithoutDetails[];
	inventories: IInventory[];
	count: number;
	inventoryCount: number;
	inventoryPage: number;
	inventoryTake: number;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.LOADING_INVENTORY; payload: { loadingInventory: boolean } }
	| { type: ActionType.BRANCH; payload: { branches: IBranch[]; count: number } }
	| { type: ActionType.START_MODAL; payload: { startModal: boolean } }
	| { type: ActionType.INVENTORY; payload: {
		inventories: IInventory[];
		inventoryCount: number;
		inventoryTake: number;
		inventoryPage: number
	} }
	| { type: ActionType.COMPANY; payload: { companies: ICompanyWithoutDetails[]; count: number } }

interface IInventoryActions {
	setLoading(loading: boolean): void;
	setLoadingInventory(loading: boolean): void;
	setStartModal(startModal: boolean): void;
	getCompanies(): void;
	getInventories(params: Partial<InventoryQueryParams>): void;
	getBranches(params?: BranchParams): void;
	startInventory(params: ParamsStartInventory): void;
}

const initialState: IState = {
	loading: false,
	loadingInventory: false,
	startModal: false,
	branches: [],
	companies: [],
	inventories: [],
	count: 0,
	inventoryPage: 0,
	inventoryCount: 0,
	inventoryTake: 10,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.LOADING_INVENTORY:
			return { ...state, loadingInventory: action.payload.loadingInventory };
		case ActionType.START_MODAL:
			return { ...state, startModal: action.payload.startModal };
		case ActionType.BRANCH:
			return {
				...state,
				branches: action.payload.branches,
			};
		case ActionType.COMPANY:
			return {
				...state,
				companies: action.payload.companies,
			};
		case ActionType.INVENTORY:
			return {
				...state,
				inventories: action.payload.inventories,
				inventoryPage: action.payload.inventoryPage,
				inventoryCount: action.payload.inventoryCount,
				inventoryTake: action.payload.inventoryTake,
			};
		default:
			throw new Error();
	}
};

const InventoryActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IInventoryActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setLoadingInventory(loadingInventory: boolean) {
			dispatch({ type: ActionType.LOADING_INVENTORY, payload: { loadingInventory } });
		},
		setStartModal(startModal: boolean) {
			dispatch({ type: ActionType.START_MODAL, payload: { startModal } });
		},
		getInventories(queryParams: InventoryQueryParams) {
			actions.setLoadingInventory(true);
			const take = queryParams.take ?? 10;
			const skip = queryParams.skip ?? 0;
			const params = {
				...queryParams,
				skip: skip * take,
				take: queryParams.take ?? 10,
			};
			getInventories(params).then((response) => {
				dispatch({
					type: ActionType.INVENTORY,
					payload: {
						inventories: response.data.inventorys,
						inventoryPage: queryParams.skip ?? 0,
						inventoryCount: response.data.count,
						inventoryTake: take,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoadingInventory(false));
		},
		startInventory(params: Partial<ParamsStartInventory>) {
			actions.setLoading(true);
			actions.setLoadingInventory(true);
			actions.setStartModal(false);
			if (params.type === InventoryType.GERAL) {
				startInventoryAll({
					branchId: params.branchId,
					companyId: params.companyId,
				}).then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					actions.getInventories({ skip: 0 });
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message
				|| 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				}).finally(() => {
					actions.setLoading(false);
					actions.setLoadingInventory(false);
				});
			}

			if (params.type === InventoryType.PARTIAL) {
				actions.setLoading(false);
				navigate('start', { state: { branchId: params.branchId, companyId: params.companyId, type: params.type } });
			}
		},
		getBranches(params: BranchParams) {
			actions.setLoading(true);
			getBranches(params).then((response) => {
				dispatch({
					type: ActionType.BRANCH,
					payload: {
						branches: response.data.data,
						count: response.data.count,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		getCompanies() {
			actions.setLoading(true);
			getCompaniesWithoutDetails().then((response) => {
				dispatch({
					type: ActionType.COMPANY,
					payload: {
						companies: response.data.data,
						count: response.data.count,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
	};

	return actions;
};

const Inventory = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const actions = useMemo(
		() => InventoryActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	return (
		<InventoryPresentational
			{...state}
			{...actions}
		/>
	);
};

export default Inventory;
