import React, { useCallback, useMemo } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { currencyBRLMask } from '../../../helpers/intl';
import { IExtendedSalePriceFormation } from '../../../containers/SalePriceFormation/SalePriceFormationAssets';
import { aliasesSalePriceFormation } from '../../../constants/aliases';
import { useFormikValuesUpdater } from '../../../hooks/useFormikValuesUpdater';
import { AverageCostSource } from '../../../enums/AliasAverageCostSource';

const RangePriceComissionTable: React.FC = () => {
	const { values } = useFormikContext<IExtendedSalePriceFormation>();

	const calculateInitialValue = useCallback(
		(commissionRate: number, interestRate: number): number => {
			if (!values.formula) return 0;

			try {
				const valueA = values.aliasAverageCostSource === AverageCostSource.AVERAGE_COST
					? values.averageCost
					: values.totalDirectCost;
				const indexValueKey = commissionRate.toString();
				const indexValueString = values.indexValues?.[indexValueKey]?.replace(',', '.');
				const indexValue = parseFloat(indexValueString || '0');
				const formula = Object.keys(aliasesSalePriceFormation).reduce((acc, key) => {
					let value;

					if (key === 'B') {
						value = commissionRate;
					} else if (key === 'C') {
						value = interestRate;
					} else if (key === 'E') {
						value = indexValue;
					} else if (key === 'A') {
						value = valueA;
					} else {
						value = values[aliasesSalePriceFormation[key] as keyof IExtendedSalePriceFormation];
					}

					return acc.replace(new RegExp(key, 'g'), value?.toString() || '0');
				}, values.formula);

				// eslint-disable-next-line no-eval
				const result = eval(formula);
				const { readjustment } = values; // Valor padrão 0 se não estiver definido
				return parseFloat(result) * (1 + readjustment / 100);
			} catch (error) {
				return 0;
			}
		},
		[values],
	);

	const calculateValue = useCallback(
		(initialValue: number, interestRate: number): number => {
			const result = initialValue * (interestRate / 100 + 1);
			return result;
		},
		[],
	);

	const calculateValueWithDiscount = useCallback(
		(value: number): string => {
			const discount = values.priceListStateSalePriceFormation.discount || 0;
			const discountedValue = value * (1 - discount / 100);
			return currencyBRLMask(discountedValue, { decimalPlaces: 3 });
		},
		[values.priceListStateSalePriceFormation?.discount],
	);

	const isVista = useCallback((description: string | undefined): boolean => {
		const normalizedDescription = (description ?? '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
		return ['a vista', 'à vista', 'avista', 'vista'].includes(normalizedDescription);
	}, []);

	const sortedTerms = useMemo(() => values.averagePaymentTermSalePriceFormations
		?.slice().sort((a, b) => {
			if (isVista(a.description)) return -1;
			if (isVista(b.description)) return 1;
			return 0;
		}), [values.averagePaymentTermSalePriceFormations, isVista]);

	const firstTermInterestRate = useMemo(() => sortedTerms?.[0]?.interestRate || 0, [sortedTerms]);

	const calculatedPrices = useMemo(() => values.rangeCommissionSalePriceFormations
		?.flatMap((rangeCommission) => values.averagePaymentTermSalePriceFormations
			?.map((term, index) => {
				const initialValue = calculateInitialValue(
					rangeCommission.commissionRate,
					term.interestRate,
				);
				const calculatedPrice = index === 0
					? initialValue
					: calculateValue(initialValue, term.interestRate);

				return {
					rangeCommissionId: rangeCommission.rangeCommissionId,
					averagePaymentTermSalePriceFormationId: term.id || '',
					price: calculatedPrice,
				};
			})) || [], [values, calculateInitialValue, calculateValue]);

	useFormikValuesUpdater(
		{ rangePriceCommissionSalePriceFormations: calculatedPrices },
		[calculatedPrices],
	);

	return (
		<TableContainer component={Paper} sx={{ maxHeight: 320 }}>
			<Table stickyHeader sx={{ minWidth: 650 }} aria-label="commission table">
				<TableHead>
					<TableRow>
						<TableCell sx={{ width: '150px' }}>Comissão (%)</TableCell>
						<TableCell sx={{ width: '150px' }}>Índice</TableCell>
						{sortedTerms?.map((term) => (
							<React.Fragment key={term.averagePaymentTermId}>
								<TableCell sx={{ width: '150px' }}>{term.description}</TableCell>
								{term.withDiscount && (
									<TableCell sx={{ width: '150px' }} key={`${term.averagePaymentTermId}-discount`}>
										{`${term.description} Com Desc.`}
									</TableCell>
								)}
							</React.Fragment>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{values.rangeCommissionSalePriceFormations?.map((rangeCommission) => {
						const initialValue = calculateInitialValue(
							rangeCommission.commissionRate,
							firstTermInterestRate,
						);

						return (
							<TableRow key={rangeCommission.rangeCommissionId}>
								<TableCell>{rangeCommission.range}</TableCell>
								<TableCell>
									{Number(rangeCommission.commissionRate).toFixed(2).replace('.', ',')}
									%
								</TableCell>
								{sortedTerms?.map((term, index) => {
									const value = index === 0
										? initialValue
										: calculateValue(initialValue, term.interestRate);

									return (
										<React.Fragment key={term.averagePaymentTermId}>
											<TableCell>{currencyBRLMask(value, { decimalPlaces: 3 })}</TableCell>
											{term.withDiscount && (
												<TableCell>
													{calculateValueWithDiscount(value)}
												</TableCell>
											)}
										</React.Fragment>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default RangePriceComissionTable;
