import { format, set } from 'date-fns';

export const setDate = (
	date: Date,
	values: {
    year?: number;
    month?: number;
    date?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
  },
): Date => {
	let newDate = date;

	if (newDate) {
		newDate = set(newDate, values);
	}

	return newDate;
};

export const formatUTCDateToLocal = (
	dateUTC: Date | string,
	dateFormat: string,
): string => {
	const date = new Date(dateUTC);

	const dateLocal = new Date(date.getTime());

	return format(dateLocal, dateFormat);
};

const pad = (value: number): string => (value < 10 ? `0${value}` : value.toString());

export const formatLocalDateToISOString = (date: Date): string => {
	const offset = -date.getTimezoneOffset();
	const sign = offset >= 0 ? '+' : '-';
	const absOffset = Math.abs(offset);
	const hoursOffset = pad(Math.floor(absOffset / 60));
	const minutesOffset = pad(absOffset % 60);

	const dateTimeISO = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
		date.getDate(),
	)}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
		date.getSeconds(),
	)}${sign}${hoursOffset}:${minutesOffset}`;

	return dateTimeISO;
};
