import {
	AddUser, CompanyBranches, EditUser, User,
} from '../containers/User/UserAssets';
import { BranchPreference } from '../enums/brenchPreferenceType';

export const normalizeUserBranches = (user: User): User => {
	const branchGroups = (user.branches || []).reduce((
		acc: Record<string, CompanyBranches>,
		branch,
	) => {
		const { branchId, branchCompanyId } = branch;
		if (!acc[branchCompanyId]) {
			acc[branchCompanyId] = {
				companyId: branchCompanyId,
				branchIds: [],
			};
		}
		acc[branchCompanyId].branchIds.push(branchId);
		return acc;
	}, {});

	if (user.companyIds) {
		user.companyIds.forEach((companyId) => {
			if (!branchGroups[companyId]) {
				branchGroups[companyId] = {
					companyId,
					branchIds: [],
				};
			}
		});
	}

	return {
		...user,
		companyBranches: Object.values(branchGroups),
		preferredBranchId: user.preferredBranch?.branchId || '',
		preferredCompanyId: user.preferredBranch?.branchCompanyId || '',
	};
};

export const normalizeUserBranchesPost = (request: AddUser | EditUser): User => {
	const formattedBranches = (request.companyBranches ?? [])
		.filter((branch) => branch.branchIds.length > 0)
		.flatMap((branch) => branch.branchIds.map((branchId) => ({
			branchId,
			branchCompanyId: branch.companyId,
		})));

	const companyIds = (request.companyBranches ?? [])
		.map((branch) => branch.companyId);

	return {
		...request,
		branches: formattedBranches,
		companyIds,
		companyBranches: undefined,
		preferredBranch: request.preferredBranchId && request.preferredCompanyId ? {
			branchId: request.preferredBranchId,
			branchCompanyId: request.preferredCompanyId,
		} : undefined,
		branchPreference: !Number.isNaN(request.branchPreference)
			? Number(request.branchPreference)
			: undefined,
		preferredBranchId: undefined,
		preferredCompanyId: undefined,
	} as User;
};
