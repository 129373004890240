/* eslint-disable import/no-duplicates */
import React, {
	Dispatch, memo, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import HourProjectInconsistenciesPresentational from '../../components/HourProject/HourProjectInconsistencies/HourProjectInconsistencies';
import {
	getUserHourProjectsInconsistencies, getUsersWithTaskHours,
} from '../../services/hourProject';
import { UserHourProjectInconsistence } from './HourProjectInconsistenciesAssets';
import { UserHourProjectInconsistenceQueryParams } from '../../interfaces/UserHourProjectInconsistenceQueryParams';
import { UserClock } from './ClockHoursAssets';

enum ActionType {
	LOADING,
	USERS,
	USERS_FILTER
}

interface IState {
	loading: boolean;
	users: UserHourProjectInconsistence[];
	usersFilter: UserClock[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| {
		type: ActionType.USERS; payload: {
			users: UserHourProjectInconsistence[],
		}
	}
	| { type: ActionType.USERS_FILTER; payload: { usersFilter: UserClock[] } };

interface IHourProjectInconsistenciesActions {
	setLoading(loading: boolean): void;
	getUsersInconsistencies(queryParams: UserHourProjectInconsistenceQueryParams): void;
	getUsersWithTaskHours(): void;
}

const initialState: IState = {
	loading: false,
	users: [],
	usersFilter: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.USERS:
			return {
				...state,
				users: action.payload.users,
			};
		case ActionType.USERS_FILTER:
			return {
				...state,
				usersFilter: action.payload.usersFilter,
			};
		default:
			throw new Error();
	}
};

const HourProjectInconsistenciesActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IHourProjectInconsistenciesActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getUsersInconsistencies(queryParams: UserHourProjectInconsistenceQueryParams) {
			actions.setLoading(true);
			const params: UserHourProjectInconsistenceQueryParams = { ...queryParams };
			if (queryParams.date) {
				params.date = format(new Date(params.date), 'yyyy-MM-dd', { locale: ptBR });
			}
			getUserHourProjectsInconsistencies(params)
				.then((response) => {
					dispatch({
						type: ActionType.USERS,
						payload: {
							users: response.data.data,
						},
					});
					actions.setLoading(false);
				})
				.catch((error: AxiosError) => {
					const errorMessage = error.response?.data.message || 'Ocorreu um erro ao buscar as inconsistências.';
					enqueueSnackbar(errorMessage, { variant: 'error' });
					actions.setLoading(false);
				});
		},
		getUsersWithTaskHours() {
			getUsersWithTaskHours().then((response) => {
				dispatch({
					type: ActionType.USERS_FILTER,
					payload: { usersFilter: response.data.data },
				});
			});
		},
	};

	return actions;
};

const HourProjectInconsistencies = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => HourProjectInconsistenciesActions(dispatch, enqueueSnackbar),
		[enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <HourProjectInconsistenciesPresentational {...state} {...actions} />;
};

export default memo(HourProjectInconsistencies);
