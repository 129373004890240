import React from 'react';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface FilterDrawerProps {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const FilterDrawer = ({ open, onClose, children }: FilterDrawerProps): JSX.Element => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Drawer
			anchor={isMobile ? 'bottom' : 'right'}
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					width: isMobile ? 'auto' : 450,
					maxHeight: '100vh',
					overflow: 'auto',
				},
			}}
		>
			{children}
		</Drawer>
	);
};

export default FilterDrawer;
