import { AxiosResponse } from 'axios';
import api from './api';
import {
	InventoryTaskQueryParams,
	InventoryTaskTransferProductData,
	InventoryTaskTransferParams,
	ReleaseTasksParams,
	RemoveTasksParams,
	ReprocessTasksParams,
} from '../interfaces/InventoryTaskQueryParams';
import { InventoryTask } from '../containers/Task/InventoryTaskAssets';
import { ErrorResponse } from '../interfaces/ErrorResponse';

interface GetTasks {
  data: InventoryTask[];
  count: number;
}

interface DefaultReturn {
  message: string;
  data: {
    count: number;
  };
}

type ReleaseTasksReturn = DefaultReturn;
type ReprocessTasks = DefaultReturn;
type FinishTask = Pick<DefaultReturn, 'message'>;
type TransferUserTask = Pick<DefaultReturn, 'message'>;
type TransferProductTask = Pick<DefaultReturn, 'message'> & {
  data: { id: string };
};

interface IProductAndPlace {
  product: string;
  place: string;
}

export const getByProductAndPlace = (
	product: string,
	place: string,
): Promise<AxiosResponse<IProductAndPlace>> => api.get<IProductAndPlace, AxiosResponse<any>>(
	`inventory/productAndPlace/${product}/${place}`,
);

export const getFirstRecord = (
	countNumber?: number,
): Promise<AxiosResponse<any>> => api.get<{ countNumber?: number }, AxiosResponse<any>>(
	`inventory/firstRecord/${countNumber}`,
);

export const getTasks = (
	params: InventoryTaskQueryParams,
): Promise<AxiosResponse<GetTasks>> => {
	const inventoryTypeString = localStorage.getItem('inventoryType');
	const inventoryTypeNumber = inventoryTypeString ? Number(inventoryTypeString) : 0;

	return api.get<GetTasks>('/inventory-task/invoice', {
		params: {
			...params,
			type: inventoryTypeNumber,
		},
	});
};

export const releaseTasks = (
	data: ReleaseTasksParams,
): Promise<AxiosResponse<ReleaseTasksReturn>> => api.post<ReprocessTasks>('/inventory-task/release', data);

export const reprocessLocationTasks = (
	data: ReprocessTasksParams,
): Promise<AxiosResponse<ReprocessTasks>> => api.post<ReprocessTasks>('/inventory-task/findLocation', data);

export const finishTask = (
	data: RemoveTasksParams,
): Promise<AxiosResponse<FinishTask>> => api.patch<FinishTask>('/inventory-task/remove', data);

export const transferUserTask = (
	userId: string,
	data: InventoryTaskTransferParams,
): Promise<AxiosResponse<TransferUserTask>> => api.post<TransferUserTask>(`/inventory-task/transferTask/${userId}`, data);

export const createTransferProductTask = (
	data: InventoryTaskTransferProductData,
): Promise<AxiosResponse<TransferProductTask, ErrorResponse>> => api.post<
    TransferProductTask,
    AxiosResponse<TransferProductTask, ErrorResponse>,
	InventoryTaskTransferProductData
  >('/inventory-task/move', data);
