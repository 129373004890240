import React, { useCallback, useState, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import Autocomplete from '../Common/Form/Autocomplete';
import Input from '../Common/Form/Input';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import { User } from '../../containers/User/UserAssets';
import { InventoryItemsByIdParams } from '../../interfaces/InventoryParams';
import { UserQueryParams } from '../../interfaces/UserQueryParams';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { filterObject } from '../../helpers/Utils';

interface InventoryTasksFilterProps {
	loading: boolean;
	products: IProductWithoutDetails[];
	sendFilter: (params: Partial<LocationProductQueryParams>) => void;
	users: User[];
	initialValues: Partial<InventoryItemsByIdParams>;
	countNumber: number;
	getUsers: (queryParams: UserQueryParams) => void;
	getProducts: (queryParams?: ProductQueryParams) => void;
	setSelectedOperators: (products: User[]) => void;
	setSelectedProducts: (products: IProductWithoutDetails[]) => void;
	selectedProducts: IProductWithoutDetails[];
	selectedOperators: User[];
}

const counterList = [
	{ value: 1, label: 'Contagem', pre: '1ª' },
	{ value: 2, label: 'Contagem', pre: '2ª' },
	{ value: 3, label: 'Contagem', pre: '3ª' },
	{ value: 4, label: 'Contagem', pre: '4ª' },
];

const InventoryTasksFilter = ({
	loading,
	products,
	initialValues,
	users,
	sendFilter,
	countNumber,
	getUsers,
	getProducts,
	setSelectedProducts,
	selectedProducts,
	setSelectedOperators,
	selectedOperators,
}: InventoryTasksFilterProps): JSX.Element => {
	const [productOptions, setProductOptions] = useState<IProductWithoutDetails[]>(products);

	const formik = useFormik({
		initialValues,
		onSubmit: () => {
			sendFilter(filterObject(formik.values));
		},
	});

	useEffect(() => {
		getUsers({ skip: 0 });
		getProducts({ skip: 0 });
	}, [getProducts, getUsers]);

	useEffect(() => {
		const updatedOptions = [...selectedProducts,
			...products.filter((p) => !selectedProducts.some((sp) => sp.id === p.id))];
		setProductOptions(updatedOptions);
	}, [products, selectedProducts]);

	const onReset = useCallback((resetForm) => {
		sendFilter({});
		resetForm();
		setSelectedProducts([]);
	}, [sendFilter, setSelectedProducts]);

	const filteredCounterList = counterList.filter((counter) => counter.value <= countNumber);

	const handleChangeOperators = useCallback((event, newValue, reason) => {
		if (reason === 'selectOption' && newValue !== null) {
			const operatorToAdd = users.find((
				operator,
			) => newValue[newValue.length - 1].includes(operator.id));
			if (operatorToAdd) {
				setSelectedOperators([...selectedOperators, operatorToAdd]);
			}
			formik.setFieldValue('operatorIds', newValue);
		} else if (reason === 'input') {
			getUsers({ name: newValue, skip: 0 });
		} else if (reason === 'removeOption' || reason === 'clear') {
			formik.setFieldValue('operatorIds', newValue);
		}
	}, [users, formik, setSelectedOperators, selectedOperators, getUsers]);

	const handleChangeProducts = useCallback((__event, newValue, reason) => {
		if (reason === 'selectOption' && newValue !== null) {
			const productToAdd = products.find((product) => newValue.includes(product.id));
			if (productToAdd) {
				setSelectedProducts([...selectedProducts, productToAdd]);
			}
			formik.setFieldValue('productIds', newValue);
		} else if (reason === 'input') {
			getProducts({ description: newValue, skip: 0 });
		} else if (reason === 'removeOption' || reason === 'clear') {
			formik.setFieldValue('productIds', newValue);
		}
	}, [products, formik, setSelectedProducts, selectedProducts, getProducts]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
					<Grid item xs={12}>
						<Input.InputField
							id="locationValue"
							name="locationValue"
							label="Localizações"
							autoComplete="off"
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							label="Produtos"
							name="productIds"
							valueKey="id"
							valueLabel="code"
							labelKey="description"
							options={productOptions || []}
							loading={loading}
							onInputChange={(
								event,
								newValue,
								reason,
							) => {
								if (newValue.length > 2) {
									handleChangeProducts(event, newValue, reason);
								}
							}}
							onChange={(event, newValue, reason) => handleChangeProducts(event, newValue, reason)}
							multiple
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							label="Operadores"
							name="operatorIds"
							valueKey="id"
							valueLabel="name"
							labelKey="login"
							options={users.concat(selectedOperators)}
							loading={loading}
							multiple
							onInputChange={(
								event,
								newValue,
								reason,
							) => handleChangeOperators(event, newValue, reason)}
							onChange={(event, newValue, reason) => handleChangeOperators(event, newValue, reason)}
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							label="Contagem"
							name="counters"
							valueKey="value"
							valueLabel="pre"
							labelKey="label"
							options={filteredCounterList || []}
							loading={loading}
							multiple
							fullWidth
						/>
					</Grid>

					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
						<Button variant="outlined" onClick={() => onReset(formik.handleReset)}>
							Restaurar
						</Button>
						<Button variant="contained" type="submit">
							Filtrar
						</Button>
					</Grid>
				</Grid>
			</Form>
		</FormikContext.Provider>
	);
};

export default InventoryTasksFilter;
