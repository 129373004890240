import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import { AxiosError } from 'axios';
import {
	getLocationDestiny, saveTakeTaskBatch, saveTaskCountBatch, SaveTaskType,
} from '../../../services/expedition';
import ExpeditionPresentational from '../../../components/Mobile/Expedition/Expedition';
import { ITask } from '../TaskAssets';

enum ActionType {
	LOADING,
	EXPEDITION_TASKS,
}

interface IState {
	loading: boolean;
	expeditionTasks: ITask[];
	count: number;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.EXPEDITION_TASKS; payload: { expeditionTasks: ITask[], count: number } };

interface IExpeditionActions {
    setLoading(loading: boolean): void;
    getLocationDestiny(): void;
	saveTakeTaskBatch(tasks: SaveTaskType[]): void;
	saveTaskCountBatch(task: SaveTaskType[]): void;
}

const initialState: IState = {
	loading: false,
	expeditionTasks: [],
	count: 0,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.EXPEDITION_TASKS:
			return {
				...state,
				expeditionTasks: action.payload.expeditionTasks,
				count: action.payload.count,
			};
		default:
			throw new Error();
	}
};

const ExpeditionActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IExpeditionActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},

		getLocationDestiny() {
			actions.setLoading(true);
			getLocationDestiny().then(
				(response) => {
					dispatch({
						type: ActionType.EXPEDITION_TASKS,
						payload: {
							expeditionTasks: response.data.data,
							count: response.data.count,
						},
					});
				},
			).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu ao obter a localização de origem. Tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			}).finally(() => {
				actions.setLoading(false);
			});
		},
		saveTakeTaskBatch(tasks: SaveTaskType[]) {
			actions.setLoading(true);
			saveTakeTaskBatch(tasks).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},

		saveTaskCountBatch(task: SaveTaskType[]) {
			actions.setLoading(true);
			saveTaskCountBatch(task).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
				getLocationDestiny();
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},

	};

	return actions;
};

const Expedition = ():JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => ExpeditionActions(
			dispatch,
			enqueueSnackbar,
		),
		[dispatch, enqueueSnackbar],
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<ExpeditionPresentational {...state} {...actions} />
	);
};

export default Expedition;
