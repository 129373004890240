import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import {
	Box, Button, Paper, Stack, Step, StepLabel, Stepper,
} from '@mui/material';
import { Form, FormikContext, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import StepConsumer from './Forms/StepConsumer';
import {
	IPaymentTerm, IRangePriceCommission, ISaleOrder, initialValuesFormik,
	validationSchemas,
} from '../../containers/SaleOrder/SaleOrderAssets';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import StepProducts from './Forms/StepProducts';
import StepPaymentMethod from './Forms/StepPaymentMethod';
import StepOrderResume from './Forms/StepOrderResume';
import StepDelivery from './Forms/StepDelivery';
import { IBusinessPartner, IProductBusinessPartnerDetail, ITopSellingProduct } from '../../containers/BusinessPartner/BusinessPartnerAssets';

export interface SaleOrderFormProps {
	loading: boolean;
	saleOrder?: ISaleOrder;
	clients: IBusinessPartner[];
	transporters: IBusinessPartner[];
	productsBusinessPartner: IProductBusinessPartnerDetail[];
	paymentTerms: IPaymentTerm[];
	topSellingProducts: ITopSellingProduct[];
	getProductBusinessPartnerDetails: (businessPartnerId: string, description?: string) => void;
	getTopSellingProducts(businessPartnerId: string, limit: number): void;
	handleSubmit: (values: ISaleOrder, action: 'Budget' | 'SaleOrder') => void;
	getRangePriceCommissions: (products?: string[], paymentTermId?: string) => void;
	getRangePriceComissionByValue: (productId: string, paymentTermId: string, price: number) => void;
	rangePriceCommissions: IRangePriceCommission[];
	rangePriceComissionsByValue?: IRangePriceCommission;
	getTransporters: () => void;
}

const steps = [
	'Informe o cliente',
	'Escolha os produtos',
	'Selecione a forma de pagamento',
	'Selecione o local de entrega',
	'Resumo do pedido',
];

export const SaleOrderForm = ({
	loading,
	saleOrder,
	clients,
	transporters,
	productsBusinessPartner,
	paymentTerms,
	topSellingProducts,
	rangePriceCommissions,
	rangePriceComissionsByValue,
	getProductBusinessPartnerDetails,
	getTopSellingProducts,
	handleSubmit,
	getRangePriceComissionByValue,
	getRangePriceCommissions,
	getTransporters,
}:
	SaleOrderFormProps): JSX.Element => {
	const initialStep = saleOrder ? 3 : 0;
	const [activeStep, setActiveStep] = useState(initialStep);
	const [action, setAction] = useState<'Budget' | 'SaleOrder'>('SaleOrder');
	const { id } = useParams();

	const formik = useFormik({
		initialValues: saleOrder ? { ...saleOrder } : {
			...initialValuesFormik,
		},
		enableReinitialize: true,
		validateOnMount: true,
		validationSchema: validationSchemas[activeStep],
		onSubmit: (values) => {
			handleSubmit(values, action);
		},
	});

	useEffect(() => {
		if (saleOrder) {
			setActiveStep(3);
		}
	}, [saleOrder]);

	useEffect(() => {
		formik.validateForm();
	}, [activeStep]);

	const costumerFormMemo = useMemo(() => (
		<StepConsumer
			clients={clients}
			getTopSellingProducts={getTopSellingProducts}
		/>
	), [clients, getTopSellingProducts]);

	const productsFormMemo = useMemo(() => (
		<StepProducts
			productsBusinessPartner={productsBusinessPartner}
			topSellingProducts={topSellingProducts}
			getProductBusinessPartnerDetails={getProductBusinessPartnerDetails}
		/>
		// eslint-disable-next-line react-hooks/exhaustive-deps
	), [productsBusinessPartner, topSellingProducts]);

	const deliveryFormMemo = useMemo(() => (
		<StepDelivery
			transporters={transporters}
			clients={clients}
			getTransporters={getTransporters}
		/>
	), [transporters, clients, getTransporters]);

	const paymentFormMemo = useMemo(() => (
		<StepPaymentMethod
			paymentTerms={paymentTerms}
			rangePriceCommissions={rangePriceCommissions}
			getRangePriceCommissions={getRangePriceCommissions}
			getRangePriceComissionByValue={getRangePriceComissionByValue}
			rangePriceComissionsByValue={rangePriceComissionsByValue}
		/>
	), [
		getRangePriceComissionByValue,
		getRangePriceCommissions,
		paymentTerms,
		rangePriceCommissions,
		rangePriceComissionsByValue,
	]);

	const resumeFormMemo = useMemo(() => (
		<StepOrderResume />
	), []);

	const forms = useMemo(() => [
		costumerFormMemo, productsFormMemo, paymentFormMemo, deliveryFormMemo, resumeFormMemo], [
		costumerFormMemo, productsFormMemo, paymentFormMemo, deliveryFormMemo, resumeFormMemo,
	]);

	const handleNext = useCallback(() => {
		if (activeStep === steps.length - 1) {
			formik.submitForm();
		} else {
			if (activeStep === 1) {
				const productIds = formik?.values?.saleOrderItems.map((item) => item.productId);
				getRangePriceCommissions(productIds);
			}
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	}, [activeStep, formik, getRangePriceCommissions]);

	const handleBack = (): void => {
		if (!saleOrder || activeStep > 3) {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}
	};

	const handleActionClick = (nextAction: 'Budget' | 'SaleOrder'): void => {
		setAction(nextAction);
		formik.submitForm();
	};

	return (
		<Box className="content">
			<PageHeader title="Cadastrar Ordem de Venda" />
			<Paper sx={{ p: 8 }}>
				<Stepper sx={{ mb: 4 }} activeStep={activeStep}>
					{steps.map((step) => (
						<Step key={step}>
							<StepLabel>{step}</StepLabel>
						</Step>
					))}
				</Stepper>
				<FormikContext.Provider value={formik}>
					<Form>
						{forms[activeStep]}
						<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
							{!id && (
								<Button disabled={activeStep === 0} onClick={handleBack}>
									Voltar
								</Button>
							)}

							{id && activeStep === steps.length - 1 && (
								<Button disabled={activeStep === 0} onClick={handleBack}>
									Voltar
								</Button>
							)}

							<Box sx={{ flexGrow: 1 }} />

							{activeStep === steps.length - 1 ? (
								<Stack direction="row" sx={{ gap: 2 }}>
									<LoadingButton
										loading={loading}
										variant="contained"
										color="primary"
										disabled={!formik.isValid}
										onClick={() => handleActionClick('Budget')}
									>
										Orçamento
									</LoadingButton>

									<LoadingButton
										loading={loading}
										variant="contained"
										color="primary"
										disabled={!formik.isValid}
										onClick={() => handleActionClick('SaleOrder')}
									>
										Ordem de Venda
									</LoadingButton>
								</Stack>
							) : (
								<LoadingButton
									variant="text"
									color="primary"
									onClick={handleNext}
									disabled={!formik.isValid}
								>
									Avançar
								</LoadingButton>
							)}

						</Box>
					</Form>
				</FormikContext.Provider>
			</Paper>
		</Box>
	);
};

SaleOrderForm.defaultProps = {
	saleOrder: undefined,
	rangePriceComissionsByValue: undefined,
};

export default SaleOrderForm;
