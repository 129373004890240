/* eslint-disable import/no-duplicates */
import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ISaleOrder } from './SaleOrderAssets';
import {
	cancelSaleOrder,
	deleteSaleOrder,
	getSaleOrders,
	getSaleOrdersAdmin,
} from '../../services/saleOrder';
import SaleOrderPresentational from '../../components/SaleOrder/SaleOrder';
import { SaleOrderQueryParams } from '../../interfaces/SaleOrderQueryParams';

enum ActionType {
	LOADING,
	SALEORDERS,
}

interface IState {
	loading: boolean;
	saleOrders: ISaleOrder[];
	saleOrdersPages: number;
	saleOrdersPage: number;
	saleOrdersTake: number;
	draftCount: number;
	canceledCount: number;
	budgetCount: number;
	saleOrderCount: number;
}

const initialState: IState = {
	loading: false,
	saleOrders: [],
	saleOrdersPages: 0,
	saleOrdersPage: 0,
	saleOrdersTake: 10,
	draftCount: 0,
	canceledCount: 0,
	budgetCount: 0,
	saleOrderCount: 0,
};

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| {
		type: ActionType.SALEORDERS; payload: {
			saleOrders: ISaleOrder[];
			saleOrdersPages: number;
			saleOrdersPage: number;
			saleOrdersTake: number;
			draftCount: number;
			canceledCount: number;
			budgetCount: number;
			saleOrderCount: number;
		}
	}
interface ISaleOrderActions {
	setLoading(loading: boolean): void;
	getSaleOrders(queryParams: SaleOrderQueryParams): void;
	handleDeleteSaleOrder(id: string): void;
	handleCancelSaleOrder(id: string, actionJustificative: string): void;
	handleEdit(id: string): void;
}

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.SALEORDERS:
			return {
				...state,
				saleOrders: action.payload.saleOrders,
				saleOrdersPages: action.payload.saleOrdersPages,
				saleOrdersPage: action.payload.saleOrdersPage,
				saleOrdersTake: action.payload.saleOrdersTake,
				draftCount: action.payload.draftCount,
				canceledCount: action.payload.canceledCount,
				budgetCount: action.payload.budgetCount,
				saleOrderCount: action.payload.saleOrderCount,
			};
		default:
			throw new Error();
	}
};

const SaleOrderActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): ISaleOrderActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getSaleOrders(queryParams: SaleOrderQueryParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 10;
			const params = { ...queryParams, skip: queryParams.skip * take };

			if (params.dateFrom) {
				params.dateFrom = format(new Date(params.dateFrom), 'yyyy-MM-dd', { locale: ptBR });
			}
			if (params.dateTo) {
				params.dateTo = format(new Date(params.dateTo), 'yyyy-MM-dd', { locale: ptBR });
			}

			getSaleOrdersAdmin(params)
				.then((response) => {
					dispatch({
						type: ActionType.SALEORDERS,
						payload: {
							saleOrders: response.data.data,
							saleOrdersPages: response.data.count,
							saleOrdersPage: queryParams.skip,
							saleOrdersTake: take,
							draftCount: response.data.extraData.draftCount,
							canceledCount: response.data.extraData.canceledCount,
							budgetCount: response.data.extraData.budgetCount,
							saleOrderCount: response.data.extraData.saleOrderCount,
						},
					});
				})
				.catch(() => getSaleOrders(params)
					.then((response) => {
						dispatch({
							type: ActionType.SALEORDERS,
							payload: {
								saleOrders: response.data.data,
								saleOrdersPages: response.data.count,
								saleOrdersPage: queryParams.skip,
								saleOrdersTake: take,
								draftCount: response.data.extraData.draftCount,
								canceledCount: response.data.extraData.canceledCount,
								budgetCount: response.data.extraData.budgetCount,
								saleOrderCount: response.data.extraData.saleOrderCount,
							},
						});
					})
					.catch(() => {
						enqueueSnackbar('Erro ao carregar a lista de ordens de venda.', {
							variant: 'error',
						});
					}))
				.finally(() => {
					actions.setLoading(false);
				});
		},
		handleEdit(id: string) {
			navigate(`/edit/${id}`);
		},
		handleDeleteSaleOrder(id: string) {
			actions.setLoading(true);
			deleteSaleOrder(id)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
					actions.getSaleOrders({ skip: 0 });
				});
		},
		handleCancelSaleOrder(id: string, actionJustificative: string) {
			actions.setLoading(true);
			cancelSaleOrder(id, actionJustificative)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
					actions.getSaleOrders({ skip: 0 });
				});
		},
	};

	return actions;
};

const SaleOrderContainer = (): JSX.Element | null => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => SaleOrderActions(dispatch, enqueueSnackbar, navigate),
		[dispatch, enqueueSnackbar, navigate],
	);
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<SaleOrderPresentational {...state} {...actions} />
	);
};

export default SaleOrderContainer;
