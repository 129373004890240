import React, {
	Dispatch,
	Reducer,
	useReducer,
	useMemo,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import AccessGroupEdit from '../../components/AccessGroup/AccessGroupEdit';
import {
	getAccessGroupById,
	saveAccessGroup,
	updateAccessGroup,
} from '../../services/accessGroup';
import { getActiveUsersWithoutPagination } from '../../services/user';
import { EditAccessGroup, IAccessGroup } from './AccessGroupAssets';
import { User } from '../User/UserAssets';
import { getUnExpiredLicenses } from '../../services/license';
import { ILicense } from '../License/LicenseAssets';

enum ActionType {
	LOADING,
	ACCESS_GROUP,
	USERS,
	LICENSES,
}

export interface IState {
	loading: boolean;
	accessGroup?: IAccessGroup;
	users: User[];
	licenses: ILicense[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.ACCESS_GROUP; payload: { accessGroup: IAccessGroup } }
	| { type: ActionType.USERS; payload: { users: User[] } }
	| { type: ActionType.LICENSES; payload: { licenses: ILicense[] } };

export interface IAccessGroupActions {
	setLoading(loading: boolean): void;
	setAccessGroup(accessGroup: IAccessGroup): void;
	handleEditAccessGroup(values: {
		id?: string;
		data: EditAccessGroup;
	}): void;
	getAccessGroupById(id: string): void;
	getUsers(): void;
	getLicenses(): void;
}

const initialState: IState = {
	loading: false,
	accessGroup: undefined,
	users: [],
	licenses: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.ACCESS_GROUP:
			return { ...state, accessGroup: action.payload.accessGroup };
		case ActionType.USERS:
			return { ...state, users: action.payload.users };
		case ActionType.LICENSES:
			return { ...state, licenses: action.payload.licenses };
		default:
			throw new Error();
	}
};

const AccessGroupActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IAccessGroupActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setAccessGroup(accessGroup: IAccessGroup) {
			dispatch({ type: ActionType.ACCESS_GROUP, payload: { accessGroup } });
		},
		getAccessGroupById(id: string) {
			actions.setLoading(true);
			getAccessGroupById(id).then((response) => {
				if (!response.data) {
					enqueueSnackbar('Grupo de Acesso não encontrado.', {
						variant: 'error',
					});
				}
				const accessGroup = response.data;
				accessGroup.modules = JSON.parse(accessGroup.modules as string);
				actions.setAccessGroup(accessGroup);
				actions.setLoading(false);
			}).catch(() => {
				actions.setLoading(false);
			});
		},
		handleEditAccessGroup(accessGroup: { id?: string, data: EditAccessGroup }) {
			actions.setLoading(true);

			if (accessGroup.id) {
				updateAccessGroup(accessGroup.id, accessGroup.data)
					.then(() => {
						enqueueSnackbar('Grupo de acesso atualizado com sucesso.', {
							variant: 'success',
						});
						navigate('/access-group');
					})
					.catch(() => {
						enqueueSnackbar('Erro ao atualizar o grupo de acesso.', {
							variant: 'error',
						});
					})
					.finally(() => {
						actions.setLoading(false);
					});
			} else {
				saveAccessGroup(accessGroup.data)
					.then(() => {
						enqueueSnackbar('Grupo de acesso criado com sucesso.', {
							variant: 'success',
						});
						navigate('/access-group');
					})
					.catch(() => {
						enqueueSnackbar('Erro ao criar o grupo de acesso.', {
							variant: 'error',
						});
					})
					.finally(() => {
						actions.setLoading(false);
					});
			}
		},
		getUsers() {
			actions.setLoading(true);
			getActiveUsersWithoutPagination()
				.then((response) => {
					dispatch({
						type: ActionType.USERS,
						payload: { users: response.data.data },
					});
				})
				.catch(() => {
					enqueueSnackbar('Erro ao carregar usuários.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		getLicenses() {
			getUnExpiredLicenses().then((response) => {
				dispatch({
					type: ActionType.LICENSES,
					payload: { licenses: response.data },
				});
			});
		},
	};

	return actions;
};

const AccessGroupEditContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(() => AccessGroupActions(
		dispatch,
		enqueueSnackbar,
		navigate,
	), [enqueueSnackbar, navigate]);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <AccessGroupEdit {...state} {...actions} />;
};

export default AccessGroupEditContainer;
