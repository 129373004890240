import React from 'react';
import {
	Box, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { currencyBRLMask } from '../../../helpers/intl';
import { ISaleOrderExtended } from '../../../containers/SaleOrder/SaleOrderAssets';
import { applyAppropriateMask, applyPhoneMask } from '../../../helpers/masks';
import {
	calculateTotalBonus,
	calculateTotalCommission,
	calculateTotalDiscount,
	calculateTotalOrderWithDiscount,
	calculateTotalOrderWithoutDiscount,
} from '../../../helpers/CalcTotals';

const StepOrderResume = (): JSX.Element => {
	const { values } = useFormikContext<ISaleOrderExtended>();

	const {
		client, saleOrderItems, transporterDetails, salesman,
	} = values;

	const totalBonus = calculateTotalBonus(saleOrderItems);
	const totalCommission = calculateTotalCommission(saleOrderItems);
	const totalWithDiscount = calculateTotalOrderWithDiscount(saleOrderItems);
	const totalWithoutDiscount = calculateTotalOrderWithoutDiscount(saleOrderItems);
	const totalDiscount = calculateTotalDiscount(saleOrderItems);
	const total = totalWithDiscount - totalBonus;

	const formatValue = (value: number):string => {
		const numericValue = typeof value === 'number' ? value : parseFloat(value);
		return Number.isNaN(numericValue) ? '0.00' : `${numericValue.toFixed(2)}%`;
	};

	return (
		<Box sx={{ paddingY: 4, marginTop: 4 }}>
			<Typography variant="h4" sx={{ mb: 4 }}>Resumo do Pedido</Typography>
			<Grid container spacing={3} sx={{ mb: 4 }}>
				<Grid item xs={6}>
					<Typography variant="h6">Dados do Cliente</Typography>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Typography>
								CNPJ/CPF:
								{' '}
								{client?.taxId ? applyAppropriateMask(client.taxId) : ''}
							</Typography>
							<Typography>
								Razão Social:
								{' '}
								{client?.name || ''}
							</Typography>
							<Typography>
								Nome Comercial:
								{' '}
								{client?.marketName || ''}
							</Typography>
							<Typography>
								Email:
								{' '}
								{client?.email || ''}
							</Typography>
							<Typography>
								Telefone:
								{' '}
								{client?.phone ? applyPhoneMask(client.phone) : ''}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography>
								Endereço:
								{' '}
								{client?.address || ''}
							</Typography>
							<Typography>
								CEP:
								{' '}
								{client?.zipCode ? applyAppropriateMask(client.zipCode) : ''}
							</Typography>
							<Typography>
								Bairro:
								{' '}
								{client?.district || ''}
							</Typography>
							<Typography>
								Cidade:
								{' '}
								{client?.city || ''}
							</Typography>
							<Typography>
								Estado:
								{' '}
								{client?.state || ''}
							</Typography>
							<Typography>
								País:
								{' '}
								{client?.country || ''}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6">Dados da Transportadora</Typography>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Typography>
								CNPJ/CPF:
								{' '}
								{transporterDetails?.taxId ? applyAppropriateMask(transporterDetails.taxId) : ''}
							</Typography>
							<Typography>
								Razão Social:
								{' '}
								{transporterDetails?.name || ''}
							</Typography>
							<Typography>
								Nome Comercial:
								{' '}
								{transporterDetails?.marketName || ''}
							</Typography>
							<Typography>
								Email:
								{' '}
								{transporterDetails?.email || ''}
							</Typography>
							<Typography>
								Telefone:
								{' '}
								{transporterDetails?.phone ? applyPhoneMask(transporterDetails.phone) : ''}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} md={9}>
					<Typography variant="h6" gutterBottom>Produtos</Typography>
					<TableContainer>
						<Table sx={{ my: 4 }}>
							<TableHead>
								<TableRow>
									<TableCell>Descrição</TableCell>
									<TableCell>Tipo</TableCell>
									<TableCell>Valor Unitário</TableCell>
									<TableCell>Quantidade Total</TableCell>
									<TableCell>Total Tab. Preço</TableCell>
									<TableCell>Total</TableCell>
									<TableCell>Desconto (%)</TableCell>
									<TableCell>Comissão (%)</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{values.saleOrderItems.map((saleOrderItem, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<TableRow key={index}>
										<TableCell>{saleOrderItem.description}</TableCell>
										<TableCell>{saleOrderItem.type === 0 ? 'Venda' : 'Bonificação'}</TableCell>
										<TableCell>{currencyBRLMask(saleOrderItem.price)}</TableCell>
										<TableCell>{saleOrderItem.quantity}</TableCell>
										<TableCell>
											{currencyBRLMask(saleOrderItem.originalPrice
												? (saleOrderItem.originalPrice * saleOrderItem.quantity)
												: (saleOrderItem.price * saleOrderItem.quantity))}
										</TableCell>
										<TableCell>
											{currencyBRLMask(
												(
													saleOrderItem.price * saleOrderItem.quantity
												) * (1 - (saleOrderItem.discount / 100)),
											)}
										</TableCell>
										<TableCell>
											{formatValue(saleOrderItem.discount)}
										</TableCell>
										<TableCell>
											{formatValue(saleOrderItem.commission)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item xs={12} md={3}>
					<Box>
						<Typography variant="h6" gutterBottom>Resumo</Typography>
						<Divider sx={{ my: 1 }} />
						<Typography gutterBottom>
							Vendedor:
							{' '}
							{salesman || 'N/A'}
						</Typography>
						<Typography gutterBottom>
							Valor da comissão:
							{' '}
							{currencyBRLMask(totalCommission || 0)}
						</Typography>

						<Divider sx={{ my: 1 }} />
						<Typography gutterBottom>
							Total Tab. Preço:
							{' '}
							{currencyBRLMask(totalWithoutDiscount || 0)}
						</Typography>
						<Typography gutterBottom>
							Descontos:
							{' '}
							{currencyBRLMask(totalDiscount || 0)}
						</Typography>
						<Typography gutterBottom>
							Total com descontos:
							{' '}
							{currencyBRLMask(totalWithDiscount || 0)}
						</Typography>
						<Typography gutterBottom>
							Total de bonificações:
							{' '}
							{currencyBRLMask(totalBonus || 0)}
						</Typography>
						<Divider sx={{ my: 1 }} />
						<Typography fontWeight={500} gutterBottom>
							Total:
							{' '}
							{currencyBRLMask(total || 0)}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default StepOrderResume;
