import React from 'react';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { formatNumberForLocale } from '../helpers/NumberUtils';

export const locationProductsColumns: GridColDef[] = [
	{
		field: 'description',
		headerName: 'Produto',
		flex: 2,
	},
	{
		field: 'measure1',
		headerName: '1 UM',
		flex: 1,
	},
	{
		field: 'valuedQuantity',
		headerName: 'Capacidade 1 UM',
		headerAlign: 'left',
		type: 'number',
		align: 'left',
		flex: 1,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => formatNumberForLocale(params.value as string, 2),
	},
	{
		field: 'currentQuantity',
		headerName: 'Utilizado 1 UM',
		headerAlign: 'left',
		type: 'number',
		align: 'left',
		flex: 1,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => formatNumberForLocale(params.value as string, 2),
	},
	{
		field: 'measure2',
		headerName: '2 UM',
		flex: 1,
	},
	{
		field: 'valuedQuantity2',
		headerName: 'Capacidade 2 UM',
		headerAlign: 'left',
		type: 'number',
		align: 'left',
		flex: 1,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => formatNumberForLocale(params.value as string, 2),
	},
	{
		field: 'currentQuantity2',
		headerName: 'Utilizado 2 UM',
		headerAlign: 'left',
		type: 'number',
		align: 'left',
		flex: 1,
		valueFormatter: (
			params: GridValueFormatterParams,
		) => formatNumberForLocale(params.value as string, 2),
	},
];
