/* eslint-disable react/jsx-props-no-spreading */
import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { getInventoryById } from '../../../services/inventory';
import { SaveTaskType, ValidateInventoryParams } from './InventoryAssets';
import InventoryPresentational from '../../../components/Mobile/Inventory/InventoryDetails/InventoryFinish';
import { saveTaskCount } from '../../../services/storage';
import { InventoryTaskDetails } from '../../Inventory/InventoryAssets';

enum ActionType {
	LOADING,
	INVENTORY_TASK
}

interface IState {
	loading: boolean;
	countNumber: number;
	inventoryTask: InventoryTaskDetails;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.INVENTORY_TASK; payload: {
		inventoryTask: InventoryTaskDetails
		countNumber: number
	} }

interface IInventoryActions {
    setLoading(loading: boolean): void;
	saveTaskCount(task: SaveTaskType): void;
	getInventoryById(params: ValidateInventoryParams): void;
}

const initialState: IState = {
	loading: false,
	countNumber: 0,
	inventoryTask: {
		id: '',
		product: {
			barCode: '',
			code: '',
			description: '',
		},
		locationOrigin: {
			barCode: '',
			name: '',
		},
	},
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.INVENTORY_TASK:
			return {
				...state,
				inventoryTask: action.payload.inventoryTask,
				countNumber: action.payload.countNumber,
			};
		default:
			throw new Error();
	}
};

const InventoryActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IInventoryActions => {
	const { id } = useParams();
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		saveTaskCount(task: SaveTaskType) {
			actions.setLoading(true);
			saveTaskCount(task).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
					autoHideDuration: 6000,
				});
			}).finally(() => {
				actions.setLoading(false);
				navigate(`/app/inventory/${id}`);
			});
		},
		getInventoryById(params: ValidateInventoryParams) {
			actions.setLoading(true);
			getInventoryById(params.inventoryId, params).then((response) => {
				if (!response.data.inventoryTask?.id) {
					enqueueSnackbar(response.data.info || 'Esse produto não está mais disponível.', {
						variant: 'info',
					});
					navigate('/app/inventory');
					return;
				}
				dispatch({
					type: ActionType.INVENTORY_TASK,
					payload: {
						inventoryTask: response.data.inventoryTask,
						countNumber: response.data.countNumber,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
					autoHideDuration: 6000,
				});
				navigate('/app/inventory');
			}).finally(() => actions.setLoading(false));
		},
	};

	return actions;
};

const InventoryFinish = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const actions = useMemo(
		() => InventoryActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	return (
		<InventoryPresentational
			{...state}
			{...actions}
		/>
	);
};

export default InventoryFinish;
