export const DEFAULT_NAME = 'MAIS i9';
export const VERSION = '1.1.0';

export const BACKGROUND_COLOR = '#fafafa';
export const PRIMARY_COLOR = '#1c447f';
export const SECONDARY_COLOR = '#eb922c';
export const SUCCESS_COLOR = '#43a047';
export const INFO_COLOR = '#2196f3';
export const ERROR_COLOR = '#d32f2f';
export const WARNING_COLOR = '#ff9800';
export const TEXT_COLOR = '#363636';
