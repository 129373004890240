import React from 'react';
import { Grid, Typography } from '@mui/material';
import CountUp from 'react-countup';
import Card from '../Common/Card';

interface SummaryItem {
	title: string;
	count: number;
}

interface SummaryProps {
	summaryData: SummaryItem[];
}

export const SaleOrderSummaryCards: React.FC<SummaryProps> = ({ summaryData = [] }) => (
	<Grid container spacing={2} marginBottom={4}>
		{summaryData.map((card) => (
			<Grid item key={card.title} xs={2}>
				<Card title={card.title}>
					<Typography variant="h5" sx={{ whiteSpace: 'nowrap' }}>
						<CountUp end={card.count} duration={1} separator="." preserveValue />
					</Typography>
				</Card>
			</Grid>
		))}
	</Grid>
);

export default SaleOrderSummaryCards;
