/* eslint-disable react/jsx-props-no-spreading */
import React, { InputHTMLAttributes } from 'react';
import {
	FormControlLabel,
	FormControl,
	FormLabel,
	Radio,
	RadioGroup,
	FormHelperText,
	Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { useField } from 'formik';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
	options: {
		id: string;
		value: string;
		label: string;
	}[];
	orientation?: 'column' | 'row';
	label: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const column: SxProps<Theme> = {
	flexDirection: 'column',
};

const row: SxProps<Theme> = {
	flexDirection: 'row',
};

const RadioField = ({
	name,
	defaultValue,
	options,
	orientation,
	label,
	...props
}: Props): JSX.Element => {
	const [field, meta, helpers] = useField({ name });

	return (
		<FormControl component="fieldset">
			<FormLabel component="legend" required>
				{label}
			</FormLabel>
			<RadioGroup
				{...field}
				aria-label={props['aria-label']}
				defaultValue={defaultValue}
				sx={orientation === 'row' ? row : column}
				value={String(field.value)}
				onChange={(event) => {
					const value = Number(event.target.value);
					helpers.setValue(value);
					if (props.onChange) {
						props.onChange(event);
					}
				}}
			>
				{options.map((option) => (
					<FormControlLabel
						key={option.id}
						value={String(option.value)}
						control={<Radio />}
						label={option.label}
					/>
				))}
			</RadioGroup>
			<FormHelperText
				error={Boolean(meta.touched) && Boolean(meta.error)}
			>
				{Boolean(meta.error) && meta.error}
			</FormHelperText>
		</FormControl>
	);
};

RadioField.defaultProps = {
	orientation: 'row',
	onChange: undefined,
};

export default React.memo(RadioField);
