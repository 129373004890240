import React from 'react';
import { Stack } from '@mui/material';
import { stopPropagation, getBoundaryYears } from '../../../helpers/Utils';
import { MonthPicker, YearPicker } from './DatePicker';

const { minYear, maxYear } = getBoundaryYears();

export const PeriodPicker = (): JSX.Element => (
	<Stack direction="row" spacing={2} justifyContent="space-between" onClick={stopPropagation}>
		<YearPicker minYear={minYear} maxYear={maxYear} name="year" label="Ano" />
		<MonthPicker name="initialMonth" label="De" />
		<MonthPicker name="finalMonth" label="Até" />
	</Stack>
);
