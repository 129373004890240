import React, {
	Dispatch, memo, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { useNavigate, NavigateFunction, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import UserTasksAppointedHoursPresentational from '../../components/HourProject/UserTasksAppointedHours';
import {
	getUserTasksAppointedHours,
	createTaskClockInOut,
	updateTaskClockInOut,
	removeAdminTaskClockInOut,
	sendApproval,
} from '../../services/hourProject';
import { UserInfo, UserTasksAppointedHoursParams } from './UserTasksAppointedHoursAssets';
import { HourProject, TaskClockInOut } from './ApportionmentAssets';
import { ERPClockHoursMap, ErpClockInOutsMap } from './ClockHoursAssets';
import { getErpClockInOutsMap, getTaskClockInOutIds } from './helper';

enum ActionType {
	LOADING,
	USERINFO,
	PROJECTS,
	ERP_CLOCK_IN_OUT,
	TASK_CLOCK_IN_OUT_IDS,
	HAS_INTEGRATION,
}

interface IState {
	loading: boolean;
	userInfo: UserInfo;
	projects: HourProject[];
	erpClockInOuts: ERPClockHoursMap;
	erpClockInOutsCount: number;
	taskClockInOutIds: string[];
	hasApproval: boolean;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.USERINFO; payload: { userInfo: UserInfo } }
	| {
		type: ActionType.PROJECTS; payload: {
			projects: HourProject[],
		}
	}
	| {
		type: ActionType.ERP_CLOCK_IN_OUT; payload: ErpClockInOutsMap
	}
	| {
		type: ActionType.TASK_CLOCK_IN_OUT_IDS; payload: string[]
	}
	| {
		type: ActionType.HAS_INTEGRATION; payload: boolean
	};

interface IHourProjectInconsistenciesActions {
	setLoading(loading: boolean): void;
	getUserTasksAppointments(queryParams: UserTasksAppointedHoursParams): void;
	createClockAppointment(data: TaskClockInOut): void;
	updateClockAppointment(id: string, data: TaskClockInOut): void;
	removeClockAppointment(id: string): void;
	sendApprovalIds(taskClockInOutIds: string[]): void;
}

const initialState: IState = {
	loading: false,
	userInfo: {
		name: '',
		registerHour: false,
	},
	projects: [],
	erpClockInOuts: new Map(),
	erpClockInOutsCount: 0,
	taskClockInOutIds: [],
	hasApproval: false,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.USERINFO:
			return { ...state, userInfo: action.payload.userInfo };
		case ActionType.PROJECTS:
			return {
				...state,
				projects: action.payload.projects,
			};
		case ActionType.ERP_CLOCK_IN_OUT:
			return {
				...state,
				erpClockInOuts: action.payload.erpClockInOuts,
				erpClockInOutsCount: action.payload.erpClockInOutsCount,
			};
		case ActionType.TASK_CLOCK_IN_OUT_IDS:
			return {
				...state,
				taskClockInOutIds: action.payload,
			};
		case ActionType.HAS_INTEGRATION:
			return {
				...state,
				hasApproval: action.payload,
			};
		default:
			throw new Error();
	}
};

const UserTasksAppointedHoursActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
	searchParams: URLSearchParams,
): IHourProjectInconsistenciesActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getUserTasksAppointments(queryParams: UserTasksAppointedHoursParams) {
			actions.setLoading(true);
			getUserTasksAppointedHours(queryParams).then((response) => {
				const {
					erpClockInOuts,
					erpClockInOutsCount,
				} = getErpClockInOutsMap(response.data.erpClockInOuts);

				const taskClockInOutIds = getTaskClockInOutIds(response.data.projects);

				dispatch({
					type: ActionType.USERINFO,
					payload: {
						userInfo: response.data.user,
					},
				});
				dispatch({
					type: ActionType.PROJECTS,
					payload: {
						projects: response.data.projects,
					},
				});
				dispatch({
					type: ActionType.ERP_CLOCK_IN_OUT,
					payload: {
						erpClockInOuts,
						erpClockInOutsCount,
					},
				});
				dispatch({
					type: ActionType.TASK_CLOCK_IN_OUT_IDS,
					payload: taskClockInOutIds,
				});
				dispatch({
					type: ActionType.HAS_INTEGRATION,
					payload: response.data.hasApproval,
				});
				actions.setLoading(false);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao buscar os dados.', { variant: 'error' });
				actions.setLoading(false);
			});
		},
		createClockAppointment(data: TaskClockInOut) {
			actions.setLoading(true);
			const userId = searchParams.get('userId') || '';
			const date = searchParams.get('date') || format(new Date(), 'yyyy-MM-dd');
			navigator.geolocation.getCurrentPosition((position) => {
				const { latitude, longitude } = position.coords;
				const geoCoordinates = `${latitude},${longitude}`;
				const clockAppointment = {
					...data, userId, geoCoordinates, isAdmin: true,
				};

				createTaskClockInOut(clockAppointment).then((response) => {
					const message = response.data.warning || response.data.message;
					const variant = response.data.warning ? 'warning' : 'success';
					enqueueSnackbar(message, { variant });
					actions.setLoading(false);
					actions.getUserTasksAppointments({ date, userId });
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
			}, () => {
				enqueueSnackbar('Acesso à localização foi negado.', { variant: 'error' });
				actions.setLoading(false);
			});
		},
		updateClockAppointment(id: string, data: TaskClockInOut) {
			actions.setLoading(true);
			updateTaskClockInOut(id, data).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
				const date = searchParams.get('date') || format(new Date(), 'yyyy-MM-dd');
				const userId = searchParams.get('userId') || '';

				actions.getUserTasksAppointments({ date, userId });
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
				actions.setLoading(false);
			});
		},
		removeClockAppointment(id: string) {
			actions.setLoading(true);
			removeAdminTaskClockInOut(id).then((response) => {
				const date = searchParams.get('date') || format(new Date(), 'yyyy-MM-dd');
				const userId = searchParams.get('userId') || '';
				enqueueSnackbar(response?.data.message, { variant: 'success' });
				actions.setLoading(false);
				actions.getUserTasksAppointments({ date, userId });
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Não foi possível remover o apontamento. Tente novamente ou contate o administrador.', {
					variant: 'error',
				});
				actions.setLoading(false);
			});
		},
		sendApprovalIds(taskClockInOutIds: string[]) {
			actions.setLoading(true);
			sendApproval(taskClockInOutIds).then((response) => {
				navigate('/hour-project/manage-hours');
				enqueueSnackbar(response?.data.message, { variant: 'success' });
				actions.setLoading(false);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao integrar os registros de TaskClockInOut.', {
					variant: 'error',
				});
				actions.setLoading(false);
			});
		},

	};

	return actions;
};

const UserTasksAppointedHours = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const actions = useMemo(
		() => UserTasksAppointedHoursActions(dispatch, enqueueSnackbar, navigate, searchParams),
		[enqueueSnackbar, navigate, searchParams],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <UserTasksAppointedHoursPresentational {...state} {...actions} />;
};

export default memo(UserTasksAppointedHours);
