import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import {
	IIndirectCostSaleOrderSimulationItem,
	ISaleOrderSimulation,
} from '../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import ProfitabilityAnalysisForm from './ProfitabilityAnalysisForm';
import { Loading } from '../Common/Loading';

interface ProfitabilityAnalysisEditProps {
	saleOrderSimulation?: ISaleOrderSimulation;
	getSaleOrderSimulationById: (id: string) => void;
	updateSelectedRangeSimulation(
		saleOrderSimulationItemId: string,
		rangeCommissionId: string,
	): void;
	updateSelectedAveragePaymentSimulation(
		saleOrderSimulationItemId: string,
		averagePaymentTermId: string,
	): void;
	upsertIndirectCostSimulation(data: IIndirectCostSaleOrderSimulationItem): void;
	updateSimulationPrice(
		saleOrderSimulationItemId: string,
		simulationPrice: number,
	): void;
}

const ProfitabilityAnalysisEdit = ({
	saleOrderSimulation,
	getSaleOrderSimulationById,
	updateSelectedRangeSimulation,
	updateSelectedAveragePaymentSimulation,
	upsertIndirectCostSimulation,
	updateSimulationPrice,
}: ProfitabilityAnalysisEditProps): JSX.Element => {
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		if (id) {
			getSaleOrderSimulationById(id);
		}
	}, [id, getSaleOrderSimulationById]);

	if (id && !saleOrderSimulation) {
		return <Loading />;
	}

	return (
		<Paper sx={{ mt: 3, p: 3 }} elevation={3}>
			<Stack spacing="1rem">
				<Typography variant="h4" display="block" color="primary" gutterBottom>
					Editar Análise de Rentabilidade
				</Typography>
				<Formik
					initialValues={saleOrderSimulation || {}}
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onSubmit={() => {}}
				>
					<ProfitabilityAnalysisForm
						saleOrderSimulation={saleOrderSimulation}
						updateSelectedRangeSimulation={updateSelectedRangeSimulation}
						updateSelectedAveragePaymentSimulation={updateSelectedAveragePaymentSimulation}
						upsertIndirectCostSimulation={upsertIndirectCostSimulation}
						updateSimulationPrice={updateSimulationPrice}
					/>
				</Formik>
			</Stack>
		</Paper>
	);
};

ProfitabilityAnalysisEdit.defaultProps = {
	saleOrderSimulation: undefined,
};

export default ProfitabilityAnalysisEdit;
