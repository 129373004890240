import React, { useMemo } from 'react';
import {
	DataGrid, GridColDef, GridRenderCellParams, GridRowParams, GridSortModel,
} from '@mui/x-data-grid';
import { formatUTCDateToLocal } from '../../helpers/DateUtils';
import { formatBarCode } from '../../helpers/masks';
import { IInventoryItems } from '../../containers/Inventory/InventoryAssets';

interface InventoryTasksTableProps {
	loading: boolean
	inventoryItems: IInventoryItems[]
	inventoryItemsCount: number
	inventoryItemsTake: number
	inventoryItemsPage: number
	sortModel: GridSortModel
	onChangePage(productsTake: number): void
	onChangePageSize(pageSize: number): void
	onSortModelChange(sortModel: GridSortModel): void
	handleSelectedTask(taskId: string): void
}

const InventoryTasksTable = ({
	loading,
	inventoryItemsCount,
	inventoryItemsPage,
	inventoryItemsTake,
	inventoryItems,
	sortModel,
	onChangePage,
	onChangePageSize,
	onSortModelChange,
	handleSelectedTask,
}: InventoryTasksTableProps):JSX.Element => {
	const columns = useMemo<GridColDef[]>(() => [
		{
			field: 'locationName',
			headerName: 'Localização',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (
				formatBarCode(params.row.inventoryItem.location.barCode)
			),
		},
		{
			field: 'productCode',
			headerName: 'Código',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (params.row.inventoryItem.product.code),
		},
		{
			field: 'productDescription',
			headerName: 'Produto',
			flex: 2,
			renderCell: (params: GridRenderCellParams) => (params.row.inventoryItem.product.description),
		},
		{
			field: 'countNumber',
			headerName: 'Contagem',
			flex: 1,
			valueFormatter: (params) => (params.value ? `${params.value}ª` : 'N/A'),
		},
		{
			field: 'quantity',
			headerName: 'Quantidade Contada',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (params.row.quantity),
		},
		{
			field: 'operator',
			headerName: 'Operador',
			flex: 1,
			valueFormatter: (params) => (params.value ? params.value.name : 'N/A'),
		},
		{
			field: 'date',
			headerName: 'Data da Contagem',
			flex: 1,
			valueFormatter: (params) => (params.value ? formatUTCDateToLocal(params.value, 'dd/MM/yyyy HH:mm') : 'N/A'),
		},
	], []);

	return (
		<DataGrid
			autoHeight
			rows={inventoryItems || []}
			columns={columns}
			loading={loading}
			rowCount={inventoryItemsCount}
			pagination
			paginationMode="server"
			sortingMode="server"
			rowsPerPageOptions={[10, 25, 50, 100]}
			pageSize={inventoryItemsTake}
			page={inventoryItemsPage}
			onPageChange={onChangePage}
			onPageSizeChange={onChangePageSize}
			onSortModelChange={onSortModelChange}
			sortModel={sortModel}
			onRowClick={(
				item,
			) => handleSelectedTask(item.id as string)}
		/>
	);
};

export default InventoryTasksTable;
