import React, { useMemo } from 'react';
import {
	FormControl, InputLabel, MenuItem, Select, SxProps, Theme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Card from '../../Common/Card';
import { dataGridVisions } from '../../../constants/dashboard';
import { unchangeableDataGridColumns } from './DataGridColumns';

interface BudgetDataGridProps {
  budgetData: any;
  selectedVision: any;
	handleDataGridVisionChange(vision: string): void;
}

const gridStyle: SxProps<Theme> = {
	minHeight: 500,
	'& .MuiDataGrid-virtualScrollerRenderZone': {
		'& .MuiDataGrid-row': {
			'&:nth-of-type(2n)': { backgroundColor: 'rgba(235, 235, 235, 0.2)' },
		},
	},
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: 'rgba(32, 32, 32, 0.1)',
	},
};

const visionSelect: SxProps<Theme> = {
	width: '100%',
	minWidth: 'fit-content',
	maxWidth: 200,
	mb: 2,
};

export const BudgetDataGrid = ({
	budgetData,
	handleDataGridVisionChange,
	selectedVision,
}: BudgetDataGridProps): JSX.Element => {
	const columns = useMemo(() => (
		[
			{
				id: 0,
				field: 'name',
				headerName: selectedVision.label,
				width: 300,
			},
			...unchangeableDataGridColumns,
		]
	), [selectedVision]);

	const handleVisionChange = (e: any): void => {
		const vision = JSON.parse(e.target.value);

		handleDataGridVisionChange(vision);
	};

	return (
		<Card title="Dados detalhados">
			<>
				<FormControl sx={visionSelect}>
					<InputLabel>Visão</InputLabel>
					<Select label="Visão" onChange={handleVisionChange} value={JSON.stringify(selectedVision)}>
						{dataGridVisions.map((option) => (
							<MenuItem value={JSON.stringify(option)} key={option.label}>{option.label}</MenuItem>
						))}
					</Select>
				</FormControl>
				{budgetData && (
					<DataGrid
						getRowId={(row) => row.code}
						rows={budgetData}
						columns={columns}
						pageSize={100}
						rowsPerPageOptions={[100]}
						disableSelectionOnClick
						sx={gridStyle}
					/>
				)}
			</>
		</Card>
	);
};
