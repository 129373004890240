export enum JustificativeType {
  OVERRUN,
  REJECT,
  REACTIVATE,
}

export const JustificativeTypeTranslations = [
	'Enviado para Estouro',
	'Rejeitado',
	'Reativado',
];
