import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	SnackbarMessage,
	OptionsObject,
	SnackbarKey,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import LicensePresentational from '../../components/License/License';
import { getLicenses, deleteLicense } from '../../services/license';
import { ILicense } from './LicenseAssets';

enum ActionType {
	LOADING,
	LICENSE,
}

interface IState {
	loading: boolean;
	licenses: ILicense[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.LICENSE; payload: { licenses: ILicense[] } };

interface ILicenseActions {
	setLoading(loading: boolean): void;
	getLicenses(): void;
	handleDeleteLicense(id: string): void;
}

const initialState: IState = {
	loading: false,
	licenses: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.LICENSE:
			return {
				...state,
				licenses: action.payload.licenses,
			};
		default:
			throw new Error();
	}
};

const LicenseActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): ILicenseActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getLicenses() {
			actions.setLoading(true);
			getLicenses()
				.then((response) => {
					dispatch({
						type: ActionType.LICENSE,
						payload: {
							licenses: response.data,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu ao obter as empresas. Tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		handleDeleteLicense(id: string) {
			actions.setLoading(true);
			deleteLicense(id)
				.then((response) => {
					enqueueSnackbar(response?.data.message, { variant: 'success' });
					actions.getLicenses();
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},
	};

	return actions;
};

const LicenseContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => LicenseActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <LicensePresentational {...state} {...actions} />;
};

export default LicenseContainer;
