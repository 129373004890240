import { hasDefaultPasswordRequirements } from './passwordRequirements';

interface IValues {
  newPassword: string;
  confirmPassword: string;
}

interface IErrors {
  newPassword?: string;
  confirmPassword?: string;
}

export const validate = (values: IValues): IErrors => {
	const errors: IErrors = {};

	if (!hasDefaultPasswordRequirements(values.newPassword)) {
		errors.newPassword = 'A senha deve atender aos requisitos';
	}

	if (values.newPassword !== values.confirmPassword) {
		errors.confirmPassword = 'As senhas devem ser idênticas';
	}

	return errors;
};
