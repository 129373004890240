/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, ReactNode, useRef } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export interface ButtonGroupDropDownProps<T> extends ButtonGroupProps {
  options: Array<{
    id: number | string;
    label: string;
    value: T;
    optionProps?: MenuItemProps;
  }>;
  selectedIndex?: number;
  showLabelButton?: boolean;
  open: boolean;
  handleMenuItemClick: (
    index: number,
    value: {
      id: number | string;
      label: string;
      value: T;
      optionProps?: MenuItemProps;
    }
  ) => void;
  handleToggle: (open: boolean) => void;
  // eslint-disable-next-line react/require-default-props
  buttonLeftProps?: ButtonProps;
  // eslint-disable-next-line react/require-default-props
  buttonRightProps?: ButtonProps & Partial<{ icon: ReactNode }>;
}

const ButtonGroupDropDownComponent = <T, >({
	open,
	options,
	selectedIndex,
	showLabelButton = true,
	buttonLeftProps,
	buttonRightProps,
	variant,
	handleMenuItemClick,
	handleToggle,
}: ButtonGroupDropDownProps<T>): JSX.Element => {
	const buttonDropDownRef = useRef<HTMLDivElement>(null);

	const handleClose = (event: Event): void => {
		if (
			buttonDropDownRef.current
      && buttonDropDownRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}
		handleToggle(false);
	};

	return (
		<>
			<ButtonGroup
				variant={variant}
				ref={buttonDropDownRef}
				aria-label="Button group with a nested menu"
			>
				{showLabelButton && (
					<Button
						{...buttonLeftProps}
						variant="contained"
						color="primary"
						sx={{
							width: 'fit-content',
							height: 'fit-content',
							minWidth: { xs: 'fit-content', lg: 200 },
						}}
					>
						{options[selectedIndex as any].label}
					</Button>
				)}
				<Button
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					variant="contained"
					color="primary"
					onClick={() => handleToggle(!open)}
					sx={{
						width: '10px',
						height: 'fit-content',
						minWidth: { xs: 'fit-content', lg: 200 },
					}}
					{...buttonRightProps}
				>
					{buttonRightProps?.icon ? (
						buttonRightProps.icon
					) : (
						<ArrowDropDownIcon />
					)}
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1,
				}}
				open={open}
				anchorEl={buttonDropDownRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{options.map((option, index) => (
										<MenuItem
											key={option.id}
											selected={index === selectedIndex}
											onClick={() => handleMenuItemClick(index, option)}
											{...option.optionProps}
										>
											{option.label}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

const MemoizedButtonGroupDropDownComponent = memo(
	ButtonGroupDropDownComponent,
) as <T>(props: ButtonGroupDropDownProps<T>) => JSX.Element;

const ButtonGroupDropDown = <T, >(
	props: ButtonGroupDropDownProps<T>,
): JSX.Element => <MemoizedButtonGroupDropDownComponent {...props} />;

export { ButtonGroupDropDown };
