import React from 'react';
import { Formik, Form } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Task } from '../../../containers/HourProject/ApportionmentAssets';
import { formatDateAndHours, formatTimeInput } from '../../../helpers/Utils';
import Input from '../../Common/Form/Input';
import { validationSchema } from '../../../containers/Dashboards/HourProjectDashboardAssets';
import { ProjectTaskStatusTranslations } from '../../../constants/taskStatus';

interface ProjectTaskModalProps {
	task: Task;
	open: boolean;
	onClose: () => void;
	onApprove: (taskId: string, quantity: string) => void;
	onReject: (taskId: string, justificativa: string) => void;
}

const ProjectTaskModal = ({
	task,
	open,
	onClose,
	onApprove,
	onReject,
}: ProjectTaskModalProps): JSX.Element => (
	<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
		<DialogTitle>
			Detalhes da Tarefa
			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
		</DialogTitle>
		<Formik
			initialValues={{ quantity: task.quantity, actionJustificative: '', isRejecting: false }}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				if (values.isRejecting) {
					onReject(task.id, values.actionJustificative);
				} else {
					onApprove(task.id, values.quantity);
				}
			}}
		>
			{({ setFieldValue }) => (
				<Form>
					<DialogContent dividers>
						<Typography variant="body1" gutterBottom>
							<strong>Nome:</strong>
							{' '}
							{task.name}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Código:</strong>
							{' '}
							{task.code}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Descrição:</strong>
							{' '}
							{task.description}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Início:</strong>
							{' '}
							{formatDateAndHours(task.startDate)}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Término:</strong>
							{' '}
							{formatDateAndHours(task.endDate)}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Horas Adicionais:</strong>
							<Input.InputField
								name="quantity"
								type="text"
								format={formatTimeInput}
								size="small"
								sx={{
									width: 100,
									ml: 1,
									flex: 1,
									backgroundColor: 'rgba(0, 0, 0, 0.1)',
									'& .MuiInputBase-root': {
										minHeight: '25px',
										padding: '0px 4px',
									},
									'& .MuiInputBase-input': {
										padding: '1px 8px',
									},
								}}
							/>
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Status:</strong>
							{' '}
							{ProjectTaskStatusTranslations[task.status]}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Solicitante:</strong>
							{' '}
							{task.requesterName}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Justificativa do Solicitante:</strong>
							{' '}
							{task.actionJustificative}
						</Typography>
						<Input.InputField
							name="actionJustificative"
							label="Justificativa para rejeição"
							placeholder="Justificativa..."
							multiline
							rows={6}
							fullWidth
							maxLength={100}
						/>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" type="submit">Aprovar</Button>
						<Button onClick={() => setFieldValue('isRejecting', true)} type="submit" variant="contained" color="error">Rejeitar</Button>
						<Button onClick={onClose}>Cancelar</Button>
					</DialogActions>
				</Form>
			)}
		</Formik>
	</Dialog>
);

export default ProjectTaskModal;
