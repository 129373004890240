import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import UserEdit from '../../components/User/UserEdit';
import { AddUser, EditUser, User } from './UserAssets';
import { getUserById, updateUser, saveUser } from '../../services/user';
import { getNumbersFromPhone } from '../../helpers/Utils';
import { getCompaniesWithoutDetails } from '../../services/company';
import { ICompanyWithoutDetails } from '../Company/CompanyAssets';
import { IBranch } from '../Branch/BranchAssets';
import { getBranches } from '../../services/branch';
import { BranchParams } from '../Inventory/InventoryAssets';
import { normalizeUserBranches } from '../../helpers/normalizeUserBranches';

enum ActionType {
	LOADING,
	USER,
	COMPANY,
	BRANCH
}

interface IState {
	loading: boolean;
	user?: User;
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.USER; payload: { user: User } }
	| { type: ActionType.COMPANY; payload: { companies: ICompanyWithoutDetails[] } }
	| { type: ActionType.BRANCH; payload: { branches: IBranch[] } };

interface IUserActions {
	setLoading(loading: boolean): void;
    setUser(user: User): void;
    handleEditUser(values: {
        id?: string;
        data: AddUser | EditUser;
    }): void;
    getUserById(id: string): void;
	getCompaniesWithoutDetails(): void;
	getBranches(params: BranchParams): void;
}

const initialState: IState = {
	loading: false,
	user: undefined,
	companies: [],
	branches: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.USER:
			return { ...state, user: action.payload.user };
		case ActionType.COMPANY:
			return { ...state, companies: action.payload.companies };
		case ActionType.BRANCH:
			return { ...state, branches: action.payload.branches };
		default:
			throw new Error();
	}
};

const UserActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IUserActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setUser(user: User) {
			const normalizedUser = normalizeUserBranches(user);
			dispatch({ type: ActionType.USER, payload: { user: normalizedUser } });
		},
		handleEditUser(values: { id?: string, data: AddUser | EditUser }) {
			actions.setLoading(true);
			const user = values;
			user.data.phone = getNumbersFromPhone(user.data.phone);

			if (user.id) {
				updateUser(user.id, user.data).then((response) => {
					navigate('/user');
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
					actions.setLoading(false);
				}).catch((error: AxiosError) => {
					if (error.response?.status === 409) {
						enqueueSnackbar('Já existe um usuário com email informado.', {
							variant: 'error',
						});
					} else {
						enqueueSnackbar('Algum erro ocorreu, tente novamente ou contate um administrador.', {
							variant: 'error',
						});
					}
					actions.setLoading(false);
				});
			} else {
				saveUser(user.data).then((response) => {
					navigate('/user');
					enqueueSnackbar(response.data.message, {
						variant: 'success',
					});
					actions.setLoading(false);
				}).catch((error: AxiosError) => {
					if (error.response?.status === 409) {
						enqueueSnackbar('Já existe um usuário com email ou login informados.', {
							variant: 'error',
						});
					} else {
						enqueueSnackbar('Algum erro ocorreu, tente novamente ou contate um administrador.', {
							variant: 'error',
						});
					}
					actions.setLoading(false);
				});
			}
		},
		getUserById(id: string) {
			actions.setLoading(true);
			getUserById(id).then((response) => {
				if (!response.data) {
					enqueueSnackbar('Usuário não encontrado.', {
						variant: 'error',
					});
					navigate('/user');
				}
				actions.setUser(response.data);
				actions.setLoading(false);
			}).catch(() => {
				actions.setLoading(false);
			});
		},
		getCompaniesWithoutDetails() {
			dispatch({ type: ActionType.LOADING, payload: { loading: true } });
			getCompaniesWithoutDetails().then((response) => {
				dispatch({
					type: ActionType.COMPANY,
					payload: {
						companies: response.data.data,
					},
				});
				dispatch({ type: ActionType.LOADING, payload: { loading: false } });
			});
		},
		getBranches(params: BranchParams) {
			getBranches(params).then((response) => {
				dispatch({
					type: ActionType.BRANCH,
					payload: {
						branches: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			});
		},
	};

	return actions;
};

const UserEditContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => UserActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <UserEdit {...state} {...actions} />;
};

export default UserEditContainer;
