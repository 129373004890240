import { AxiosResponse } from 'axios';

import api from './api';
import { IMessageResponse } from '../interfaces/MessageResponse';

export interface ITypeContact {
  id?: string;
  name: string;
}

export interface ITypeContacts {
  data: ITypeContact[];
  count: number;
}

export const getTypeContacts = (): Promise<AxiosResponse<ITypeContacts>> => api.get<ITypeContacts>('/type-contact');

export const getTypeContactById = (id: string): Promise<AxiosResponse<ITypeContact>> => api.get<ITypeContact>(`/type-contact/${id}`);

export const saveTypeContact = (data: ITypeContact): Promise<AxiosResponse<IMessageResponse>> => api.post<ITypeContact, AxiosResponse<IMessageResponse>>('/type-contact', data);

export const updateTypeContact = (id: string, data: ITypeContact): Promise<AxiosResponse<IMessageResponse>> => api.patch<ITypeContact, AxiosResponse<IMessageResponse>>(`/type-contact/${id}`, data);

export const deleteTypeContact = (
	id: string,
): Promise<AxiosResponse<IMessageResponse>> => api.delete<IMessageResponse>(`/type-contact/${id}`);
