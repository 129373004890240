import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GenericDrawer from '../../Common/GenericDrawer';
import { formatOrRenderValue } from '../../../helpers/Utils';
import { salePriceFormationColumns } from '../../../constants/salePriceFormationColumns';
import { ISalePriceFormation } from '../../../containers/SalePriceFormation/SalePriceFormationAssets';

interface IDetailsDrawerProps {
    openDetails?: ISalePriceFormation;
    onClose: () => void;
}

const DetailsDrawer = ({ openDetails, onClose }: IDetailsDrawerProps): JSX.Element => {
	const contentMemo = useMemo(() => {
		if (!openDetails) {
			return null;
		}

		return (
			<Grid container spacing={1}>
				{salePriceFormationColumns.map((column) => {
					const { field } = column;
					const value = openDetails[field as keyof ISalePriceFormation];
					const gridSize = 6;

					if (value !== null && value !== undefined) {
						const displayValue = formatOrRenderValue(column, value);
						return (
							<Grid item xs={gridSize} key={field}>
								<Typography variant="subtitle2">{column.headerName}</Typography>
								<Box>{displayValue}</Box>
							</Grid>
						);
					}
					return null;
				})}
			</Grid>
		);
	}, [openDetails]);

	return (
		<GenericDrawer
			open={Boolean(openDetails)}
			title="Detalhes"
			content={contentMemo}
			onClose={onClose}
		/>
	);
};

DetailsDrawer.defaultProps = {
	openDetails: undefined,
};

export default DetailsDrawer;
