import React, { useEffect } from 'react';
import {
	Box,
	Typography,
	Chip,
	Card,
	CardContent,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useFormikContext } from 'formik';
import { ISaleOrderExtended } from '../../../containers/SaleOrder/SaleOrderAssets';
import Autocomplete from '../../Common/Form/Autocomplete';
import { IBusinessPartner } from '../../../containers/BusinessPartner/BusinessPartnerAssets';

interface StepConsumerProps {
	clients: IBusinessPartner[];
	getTopSellingProducts(businessPartnerId: string, limit: number): void;
}

const StepConsumer = ({
	clients,
	getTopSellingProducts,
}: StepConsumerProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<ISaleOrderExtended>();
	const { clientId } = values;

	const selectedClient = clients.find((c) => c.id === clientId);

	useEffect(() => {
		if (clientId) {
			setFieldValue('client', selectedClient);
			if (selectedClient?.salesmans && selectedClient.salesmans[0]?.name) {
				setFieldValue('salesman', selectedClient.salesmans[0].name || 'N/A');
			}
			getTopSellingProducts(clientId, 5);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientId, selectedClient, setFieldValue]);

	return (
		<>
			{selectedClient?.salesmans?.length ? (
				<Chip
					icon={<AccountCircleIcon />}
					label={`Vendedor: ${selectedClient.salesmans[0]?.name || 'N/A'}`}
					variant="outlined"
					sx={{ marginBottom: 4 }}
				/>
			) : null}
			<Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography variant="h6" gutterBottom>
						Selecione o Cliente
					</Typography>

					<Autocomplete<IBusinessPartner>
						sx={{ width: 500 }}
						name="clientId"
						options={clients || []}
						label="Buscar..."
						labelKey="marketName"
						valueKey="id"
						valueLabel="taxId"
						required
					/>

					{selectedClient && (
						<Box sx={{ width: 500, marginTop: 4 }}>
							<Typography variant="h6" gutterBottom>
								Dados do Cliente:
							</Typography>
							<Card>
								<CardContent>
									<Typography variant="h6" gutterBottom>
										{selectedClient.name}
									</Typography>
									<Typography>
										Telefone:
										{' '}
										{selectedClient.phone}
									</Typography>
									<Typography>
										Endereço:
										{' '}
										{selectedClient.address}
									</Typography>
									<Typography>
										E-mail:
										{' '}
										{selectedClient.email}
									</Typography>
								</CardContent>
							</Card>
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
};

export default StepConsumer;
