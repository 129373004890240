import Yup from '../../helpers/yupMethods';
import { validationMessage } from '../../helpers/yupMessages';

export const validationSchema = Yup.object({
	quantity: Yup.string().required(validationMessage.required),
	actionJustificative: Yup.string().when('isRejecting', {
		is: true,
		then: Yup.string().required('Escreva uma justificativa para rejeitar'),
		otherwise: Yup.string(),
	}),
	isRejecting: Yup.boolean(),
});
