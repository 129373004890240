import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
	ILicense,
} from '../../containers/License/LicenseAssets';
import LicenseForm from './LicenseForm';
import { Loading } from '../Common/Loading';

interface LicenseEditProps {
	loading: boolean;
	license?: ILicense;
	getLicenseById: (id: string) => void;
	handleEditLicense: (values: { id?: string; data: ILicense }) => void;
}

const LicenseEdit = ({
	loading, handleEditLicense, getLicenseById, license,
}: LicenseEditProps): JSX.Element => {
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getLicenseById(id);
		}
	}, [id, getLicenseById]);

	const handleSubmit = useCallback((values: ILicense) => {
		handleEditLicense({ id: license?.id, data: values });
	}, [handleEditLicense, license]);

	if (id && !license) {
		return <Loading />;
	}

	return (
		<div>
			<Typography variant="h4" gutterBottom>
				{`${license?.id ? 'Editar' : 'Cadastrar'} Licença`}
			</Typography>
			<LicenseForm
				license={license}
				onSubmit={handleSubmit}
				loading={loading}
			/>
		</div>
	);
};

LicenseEdit.defaultProps = {
	license: undefined,
};

export default LicenseEdit;
