import * as Yup from 'yup';

interface AccountingAccount {
  name: string;
}

interface AccountingItem {
  name: string;
}

interface ClassValue {
  name: string;
}

interface CostCenter {
  name: string;
}

interface Branch {
  name: string;
}

export interface IBudgetApportionment {
  accountingAccount: AccountingAccount;
  accountingAccountId: string;
  accountingItem: AccountingItem;
  accountingItemId: string;
  classValue: ClassValue;
  classValueId: string;
  costCenter: CostCenter;
  costCenterId: string;
  id: string;
}

export interface PurchaseOrderItemApportionment {
  budget: IBudgetApportionment;
  budgetId?: string;
  percentage: string;
}

export interface PurchaseOrderItem {
  id: string;
  itemOrder: string;
  lastPurchaseValue: number;
  productCode: string;
  productDescription: string;
  purchaseOrderId: string;
  purchaseOrderItemApportionments: PurchaseOrderItemApportionment[];
  quantity: number;
  totalValue: number;
  unitValue: number;
  discountValue: number;
}

export interface IPurchaseOrder {
  OSNumber?: string;
  branch: Branch;
  branchId: string;
  companyId: string;
  costType?: string;
  createdAt: string;
  deliveryBranch?: Branch;
  id: string;
  invoiceIssuance?: string;
  invoiceNumber?: string;
  invoiceSerie?: string;
  invoiceType?: string;
  nrOrder: string;
  observation: string;
  orderIssuance: string;
  paymentCode?: string;
  paymentCodeDescription?: string;
  providerCnpj: string;
  providerCode: string;
  providerName: string;
  providerStore: string;
  purchaseOrderItems: PurchaseOrderItem[];
  requesterLogin: string;
  requesterName: string;
  status: number;
  totalValue: string;
  updatedAt: string;
  userUpdate: string;
  totalDiscount?: string;
  totalValueWithoutDiscount?: string;
}

export interface IBranch {
  address: string;
  cnpj: string;
  code: string;
  companyId: string;
  createdAt: string;
  email: string;
  id: string;
  logo?: string;
  name: string;
  phone: string;
  updatedAt?: string;
}

export interface IForm {
  nrOrder: string;
  invoiceNumber: string;
  provider: string;
  apportioned: boolean;
  hasInvoices: boolean;
  branches: IBranch[];
  invoiceIssuanceFrom?: Date;
  invoiceIssuanceTo?: Date;
  orderIssuanceFrom?: Date;
  orderIssuanceTo?: Date;
}

export const validationSchema = Yup.object().shape({
	branches: Yup.array()
		.min(1, 'O campo Filial é obrigatório')
		.required('O campo Filial é obrigatório'),
	orderIssuanceFrom: Yup.date()
		.required('Data de início é obrigatória'),
	orderIssuanceTo: Yup.date()
		.required('Data de término é obrigatória'),
	invoiceIssuanceFrom: Yup.date()
		.required('Data de início é obrigatória'),
	invoiceIssuanceTo: Yup.date()
		.required('Data de término é obrigatória'),
});
