import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	SnackbarMessage,
	OptionsObject,
	SnackbarKey,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import BusinessPartnerEditPresentational from '../../components/BusinessPartner/BusinessPartnerEdit';
import {
	getBusinessPartnerById,
	saveBusinessPartner,
	updateBusinessPartner,
	getSalesmans,
	getSalesmansAdmin,
} from '../../services/businessPartner';
import { IBusinessPartner, ISalesman } from './BusinessPartnerAssets';
import { getAddressByCep } from '../../services/app';
import { ITypeContact, getTypeContacts } from '../../services/typeContact';
import { ICountry, getCountries } from '../../services/country';
import { Cep } from '../../interfaces/cep';
import { IProductWithoutDetails } from '../Product/ProductAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { getProductsWithoutDetails } from '../../services/product';
import { IRegionState, getStates } from '../../services/state';
import { ICity, getCities } from '../../services/city';
import { getActiveUsersWithoutPaginationBP, getActiveUsersWithoutPaginationBPAdmin } from '../../services/user';
import { removeDuplicates } from '../../helpers/Utils';
import { User } from '../User/UserAssets';
import usePermission from '../../hooks/usePermission';
import { IModule } from '../../interfaces/Module';

enum ActionType {
	LOADING,
	BUSINESSPARTNER,
	SALESMANS,
	TYPECONTACT,
	PRODUCT,
	COUNTRY,
	STATE,
	CITY,
	CEP,
	USERS,
}

export interface IState {
	loading: boolean;
	businessPartner?: IBusinessPartner;
	salesmans: ISalesman[];
	cep?: Cep;
	unitCep?: { cep: Cep; unitIndex: number };
	products: IProductWithoutDetails[];
	typeContacts: ITypeContact[];
	countries: ICountry[];
	states: IRegionState[];
	cities: ICity[];
	users: User[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.BUSINESSPARTNER; payload: { businessPartner: IBusinessPartner } }
	| { type: ActionType.SALESMANS; payload: { salesmans: ISalesman[] } }
	| { type: ActionType.CEP; payload: { cep: Cep; unitIndex?: number } }
	| { type: ActionType.PRODUCT; payload: { products: IProductWithoutDetails[] } }
	| { type: ActionType.TYPECONTACT; payload: { typeContacts: ITypeContact[] } }
	| { type: ActionType.COUNTRY; payload: { countries: ICountry[] } }
	| { type: ActionType.STATE; payload: { states: IRegionState[] } }
	| { type: ActionType.CITY; payload: { cities: ICity[] } }
	| { type: ActionType.USERS; payload: { users: User[] } };

export interface IBusinessPartnerActions {
	setLoading(loading: boolean): void;
	setBusinessPartner(businessPartner: IBusinessPartner): void;
	handleEditBusinessPartner(values: {
		id?: string;
		data: IBusinessPartner;
	}): void;
	getSalesmans(): void;
	getBusinessPartnerById(id: string): void;
	getProducts(queryParams: ProductQueryParams): void;
	getAddressByCep(cep: string, unitIndex?: number): void;
	getTypeContacts(): void;
	getCountries(searchQuery?: string): void;
	getStates(): void;
	getCities(searchQuery?: string): void;
	getUsers(): void;
}

const initialState: IState = {
	loading: false,
	businessPartner: undefined,
	salesmans: [],
	cep: undefined,
	products: [],
	typeContacts: [],
	countries: [],
	states: [],
	cities: [],
	users: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.BUSINESSPARTNER:
			return { ...state, businessPartner: action.payload.businessPartner };
		case ActionType.SALESMANS:
			return { ...state, salesmans: action.payload.salesmans };
		case ActionType.PRODUCT:
			return { ...state, products: action.payload.products };
		case ActionType.TYPECONTACT:
			return { ...state, typeContacts: action.payload.typeContacts };
		case ActionType.CEP: {
			if (action.payload.unitIndex !== undefined) {
				const updatedUnits = state.businessPartner ? [...state.businessPartner.units] : [];
				if (state.businessPartner) {
					const unit = updatedUnits[action.payload.unitIndex];
					if (unit) {
						unit.address = action.payload.cep.logradouro;
						unit.district = action.payload.cep.bairro;
						unit.city = action.payload.cep.localidade;
						unit.state = action.payload.cep.uf;
					}
				}
				return {
					...state,
					businessPartner: state.businessPartner
						? { ...state.businessPartner, units: updatedUnits } : state.businessPartner,
					unitCep: { cep: action.payload.cep, unitIndex: action.payload.unitIndex },
				};
			}
			return { ...state, cep: action.payload.cep };
		}
		case ActionType.COUNTRY: {
			const newCountries = action.payload.countries;
			const updatedCountries = removeDuplicates([...state.countries, ...newCountries], 'id');
			return { ...state, countries: updatedCountries };
		}
		case ActionType.STATE:
			return { ...state, states: action.payload.states };
		case ActionType.CITY: {
			const newCities = action.payload.cities;
			const updatedCities = removeDuplicates([...state.cities, ...newCities], 'id');
			return { ...state, cities: updatedCities };
		}
		case ActionType.USERS:
			return { ...state, users: action.payload.users };
		default:
			throw new Error();
	}
};

const BusinessPartnerActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
	permission: IModule | undefined,
): IBusinessPartnerActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setBusinessPartner(businessPartner: IBusinessPartner) {
			dispatch({ type: ActionType.BUSINESSPARTNER, payload: { businessPartner } });
		},
		handleEditBusinessPartner(businessPartner: { id?: string, data: IBusinessPartner }) {
			actions.setLoading(true);
			if (businessPartner.id) {
				updateBusinessPartner(businessPartner.id, businessPartner.data).then((response) => {
					navigate('/business-partner');
					enqueueSnackbar(response.data.message, { variant: 'success' });
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
			} else {
				saveBusinessPartner(businessPartner.data).then((response) => {
					navigate('/business-partner');
					enqueueSnackbar(response.data.message, { variant: 'success' });
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
			}
		},
		getBusinessPartnerById(id: string) {
			actions.setLoading(true);
			getBusinessPartnerById(id).then((response) => {
				if (!response.data) {
					enqueueSnackbar('Business Partner não encontrado.', {
						variant: 'error',
					});
					navigate('/business-partner');
				}
				actions.setBusinessPartner(response.data);
				actions.setLoading(false);
			}).catch(() => {
				actions.setLoading(false);
			});
		},
		getProducts(queryParams: ProductQueryParams) {
			getProductsWithoutDetails(queryParams).then((response) => {
				dispatch({
					type: ActionType.PRODUCT,
					payload: {
						products: response.data.data,
					},
				});
			});
		},
		getAddressByCep(cep: string, unitIndex?: number) {
			getAddressByCep(cep).then((response) => {
				if (response.data && !response.data.erro) {
					const result = {
						...response,
						data: {
							...response.data,
							unitIndex,
						},
					};
					dispatch({
						type: ActionType.CEP,
						payload: { cep: result.data, unitIndex },
					});
				} else {
					enqueueSnackbar('CEP não encontrado.', { variant: 'error' });
				}
			}).catch(() => {
				enqueueSnackbar('Erro ao buscar o CEP.', { variant: 'error' });
			});
		},
		getTypeContacts() {
			getTypeContacts().then((response) => {
				dispatch({
					type: ActionType.TYPECONTACT,
					payload: {
						typeContacts: response.data.data,
					},
				});
			});
		},
		getCountries(searchQuery?: string) {
			getCountries(searchQuery).then((response) => {
				dispatch({
					type: ActionType.COUNTRY,
					payload: {
						countries: response.data.data,
					},
				});
			});
		},
		getStates() {
			getStates().then((response) => {
				dispatch({
					type: ActionType.STATE,
					payload: {
						states: response.data.data,
					},
				});
			});
		},
		getCities(searchQuery?: string) {
			getCities(searchQuery).then((response) => {
				dispatch({
					type: ActionType.CITY,
					payload: {
						cities: response.data.data,
					},
				});
			});
		},
		getSalesmans() {
			actions.setLoading(true);
			const fetchSalesmans = permission?.isAdmin
				? getSalesmansAdmin
				: getSalesmans;

			fetchSalesmans()
				.then((response) => {
					dispatch({
						type: ActionType.SALESMANS,
						payload: {
							salesmans: response.data.data,
						},
					});
				})
				.catch(() => {
					enqueueSnackbar('Erro ao carregar os vendedores.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		getUsers() {
			actions.setLoading(true);
			const fetchUsers = permission?.isAdmin
				? getActiveUsersWithoutPaginationBPAdmin
				: getActiveUsersWithoutPaginationBP;

			fetchUsers()
				.then((response) => {
					dispatch({
						type: ActionType.USERS,
						payload: {
							users: response.data.data,
						},
					});
				})
				.catch(() => {
					enqueueSnackbar('Erro ao carregar usuários.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
	};

	return actions;
};

const BusinessPartner = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const permission = usePermission('BUSINESS_PARTNER');
	const actions = useMemo(
		() => BusinessPartnerActions(dispatch, enqueueSnackbar, navigate, permission),
		[enqueueSnackbar, navigate, permission],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <BusinessPartnerEditPresentational {...state} {...actions} />;
};

export default BusinessPartner;
