import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

const dragStyle: SxProps<Theme> = {
	width: '100%',
	height: '100%',
	textAlign: 'left',
	cursor: 'move',
};

interface DraggableColumnsProps {
    headerName: string;
    columns: GridColDef[];
    setColumns: (newColumns: GridColDef[]) => void;
    index: number;
}

const DraggableColumns = ({
	headerName,
	columns,
	setColumns,
	index,
}: DraggableColumnsProps): JSX.Element => {
	const handleDragStart = useCallback(
		(event: React.DragEvent<HTMLDivElement>) => {
			event.dataTransfer.setData('index', index.toString());
		},
		[index],
	);

	const handleDrop = useCallback(
		(event: React.DragEvent<HTMLDivElement>) => {
			event.preventDefault();
			const fromIndex = parseInt(event.dataTransfer.getData('index'), 10);
			const updateColumns = [...columns];
			const moveColumn = updateColumns.splice(fromIndex, 1)[0];
			updateColumns.splice(index, 0, moveColumn);

			setColumns(updateColumns);
		},
		[columns, setColumns, index],
	);

	return (
		<Box
			sx={dragStyle}
			draggable
			onDragStart={handleDragStart}
			onDrop={handleDrop}
			onDragOver={(event) => event.preventDefault()}
		>
			{headerName}
		</Box>
	);
};

export default DraggableColumns;
