import React, {
	useMemo, useState, useEffect,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Chip from '@mui/material/Chip';
import GenericDrawer from '../../Common/GenericDrawer';
import { Loading } from '../../Common/Loading';
import NoDataPage from '../../Common/NoDataPage';
import { currencyBRLMask } from '../../../helpers/intl';
import { purchaseOrderApprovalStatus } from '../../../constants/purchaseOrderApprovalStatus';

interface IApportionmentDrawerProps {
    openId: string | null,
    apportionments: any[],
    loadingApportionments: boolean,
    onClose: () => void
}

type Colors = 'default' | 'error' | 'success' | 'warning';

const chipColor: Colors[] = [
	'default',
	'error',
	'success',
	'warning',
	'warning',
];

const apportionmentHeader: SxProps<Theme> = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
};

const collapse: SxProps<Theme> = {
	backgroundColor: '#f0f0f0',
	borderRadius: '5px',
	padding: '0.5rem',
};

const showingBox: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
};

const ApportionmentDrawer = ({
	openId, apportionments, loadingApportionments, onClose,
}: IApportionmentDrawerProps): JSX.Element => {
	const [checked, setChecked] = useState(false);
	const [order, setOrder] = useState<any>(null);
	const [apportionmentsState, setApportionments] = useState<any>(apportionments);

	const handleChange = (): void => {
		setChecked((prev) => !prev);
	};

	useEffect(() => {
		const apportionmentsCloned = cloneDeep(apportionments);

		if (openId && apportionmentsCloned.length > 0) {
			const orderIndex = apportionmentsCloned
				.findIndex((apportionment) => apportionment.id === openId);

			if (orderIndex > -1) {
				setOrder(apportionmentsCloned.splice(orderIndex, 1)[0]);
				setApportionments(apportionmentsCloned);
			}
		}
	}, [openId, apportionments]);

	const headerMemo = useMemo(() => {
		if (!order) {
			return null;
		}

		return (
			<>
				<Stack direction="row" alignItems="center" justifyContent="space-around" gap="10px" mb="1rem">
					<Box>
						<Typography variant="subtitle2">Nr do pedido</Typography>
						<Typography variant="body1">{order.nrOrder}</Typography>
					</Box>
					<Box>
						<Typography variant="subtitle2">Produto</Typography>
						<Typography variant="body1">{order.productDescription}</Typography>
					</Box>
					<Box>
						<Typography variant="subtitle2">Fornecedor</Typography>
						<Typography variant="body1">{order.providerName}</Typography>
					</Box>
				</Stack>
				<Divider />
			</>
		);
	}, [order]);

	const getApportionmentNumberMemo = useMemo(() => {
		const apportionmentsLength = apportionmentsState.length;

		if (apportionmentsLength === 1) {
			return '1 pedido';
		}

		return `${apportionmentsState.length} pedidos`;
	}, [apportionmentsState]);

	const contentMemo = useMemo(() => {
		if (apportionmentsState.length === 0) {
			return <NoDataPage />;
		}

		return (
			<>
				<Typography variant="h6" mt="1rem">Rateios</Typography>
				<Box sx={apportionmentHeader}>
					<Typography variant="body2">{ getApportionmentNumberMemo }</Typography>
					<FormControlLabel
						control={<Switch checked={checked} onChange={handleChange} />}
						label="Expandir"
					/>
				</Box>
				<Stack direction="column" justifyContent="space-between" gap="10px">
					{ apportionmentsState.map((apportionment: any) => {
						const { budget } = apportionment;
						const approvers = budget.accountingAccount.budgetApprovers;

						return (
							<Collapse
								key={apportionment.id}
								in={checked}
								collapsedSize={60}
								sx={collapse}
							>
								<Stack direction="row" alignItems="center" justifyContent="space-between" gap="10px" mb="1rem">
									<Box sx={showingBox}>
										<Typography variant="button">{ budget.code }</Typography>
									</Box>
									<Chip
										label={purchaseOrderApprovalStatus[apportionment.status]}
										color={chipColor[apportionment.status]}
									/>
									<Box sx={showingBox}>
										<Typography variant="button">{ currencyBRLMask(apportionment.unitValue) }</Typography>
										<Typography variant="overline">Valor unitário</Typography>
									</Box>
									<Box sx={showingBox}>
										<Typography variant="button">{ apportionment.quantity }</Typography>
										<Typography variant="overline">QTE</Typography>
									</Box>
									<Box sx={showingBox}>
										<Typography variant="button">{ currencyBRLMask(apportionment.totalValue) }</Typography>
										<Typography variant="overline">Valor total</Typography>
									</Box>
								</Stack>
								<Grid container spacing={2} sx={{ textAlign: 'center' }} mb="10px">
									<Grid item xs={6}>
										<Stack direction="column">
											<Typography variant="caption" lineHeight={1.1}>Conta contábil</Typography>
											<Typography variant="button" lineHeight={1.1}>{ budget.accountingAccount.name }</Typography>
											<Typography variant="overline" lineHeight={1.1}>{ budget.accountingAccount.code }</Typography>
										</Stack>
									</Grid>
									<Grid item xs={6}>
										<Stack direction="column">
											<Typography variant="caption" lineHeight={1.1}>Centro de custo</Typography>
											<Typography variant="button" lineHeight={1.1}>{ budget.costCenter.name }</Typography>
											<Typography variant="overline" lineHeight={1.1}>{ budget.costCenter.code }</Typography>
										</Stack>
									</Grid>
									<Grid item xs={6}>
										<Stack direction="column">
											<Typography variant="caption" lineHeight={1.1}>Filial</Typography>
											<Typography variant="button" lineHeight={1.1}>{ budget.accountingItem.name }</Typography>
											<Typography variant="overline" lineHeight={1.1}>{ budget.accountingItem.code }</Typography>
										</Stack>
									</Grid>
									<Grid item xs={6}>
										<Stack direction="column">
											<Typography variant="caption" lineHeight={1.1}>CliFor</Typography>
											<Typography variant="button" lineHeight={1.1}>{ budget.classValue.name }</Typography>
											<Typography variant="overline" lineHeight={1.1}>{ budget.classValue.code }</Typography>
										</Stack>
									</Grid>
								</Grid>
								<Divider textAlign="left"><Typography variant="overline">Aprovadores</Typography></Divider>
								<Stack direction="row" gap="5px" flexWrap="wrap" mb="10px">
									{ approvers.map((approver: any) => (
										<Chip key={approver.user.name} label={approver.user.name} color={apportionment.userApprovedId === approver.user.id ? 'success' : 'default'} />
									)) }
									{ apportionment.userApprovedId && !approvers.find((approver: any) => approver.user.id === apportionment.userApprovedId) && <Chip key={apportionment.userApprovedId} label={apportionment.userApproved.name} color="success" />}
								</Stack>
								<Divider textAlign="left"><Typography variant="overline">Responsáveis</Typography></Divider>
								<Stack direction="row" gap="5px" flexWrap="wrap" mb="10px">
									{ budget.budgetResponsibles.map((responsible: any) => (
										<Chip key={responsible.user.name} label={responsible.user.name} />
									)) }
								</Stack>
							</Collapse>
						);
					}) }
				</Stack>
			</>
		);
	}, [getApportionmentNumberMemo, checked, apportionmentsState]);

	return (
		<GenericDrawer
			open={Boolean(openId)}
			title="Rateios do pedido"
			content={(
				<>
					{ loadingApportionments && <Loading /> }
					{ !loadingApportionments && headerMemo }
					{ !loadingApportionments && contentMemo }
				</>
			)}
			onClose={onClose}
		/>
	);
};

export default ApportionmentDrawer;
