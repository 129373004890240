import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format } from 'date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScaleIcon from '@mui/icons-material/Scale';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import {
	CreateOverflowTaskData,
	HourProject,
	IForm,
	Task,
	TaskClockInOut,
	UserProjectsTasksHoursParams,
	initialValues,
} from '../../../containers/HourProject/ApportionmentAssets';
import AppointmentFilter from './AppointmentFilter';
import { PageHeaderButtonProps } from '../../../interfaces/PageHeaderInterface';
import DrawerFilter from '../../Common/DrawerFilter';
import { formatDateAndHours, formatHoursMinutes } from '../../../helpers/Utils';
import TaskAccordion from './TaskAccordion';
import { Loading } from '../../Common/Loading';
import NoDataPage from '../../Common/NoDataPage';
import Pagination from '../../Common/Pagination';
import DayCarousel from '../../Common/DayCarousel';
import TaskOverflowModal from './TaskOverflowModal';

interface AppointmentProps {
	loading: boolean;
	projects: HourProject[];
	projectsPages: number;
	projectsPage: number;
	projectsTake: number;
	workedHoursDay: string;
	daysBackAppointment: string;
	getProjects(queryParams?: UserProjectsTasksHoursParams): void;
	sendClockAppointment(data: TaskClockInOut): void;
	removeClockAppointment(id: string): void;
	createOverflowTask(
		data: CreateOverflowTaskData,
		queryParams?: UserProjectsTasksHoursParams,
	): void;
}

const Appointment = (props: AppointmentProps): JSX.Element => {
	const {
		loading,
		projects,
		projectsPages,
		projectsPage,
		projectsTake,
		workedHoursDay,
		daysBackAppointment,
		getProjects,
		sendClockAppointment,
		removeClockAppointment,
		createOverflowTask,
	} = props;
	const [appointments, setAppointments] = useState<{ [key: string]: Date | null }>({});
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [filter, setFilter] = useState<IForm>(initialValues);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [overflowDialogOpen, setOverflowDialogOpen] = useState(false);
	const [selectedTask, setSelectedTask] = useState<Task | null>(null);

	useEffect(() => {
		const params: UserProjectsTasksHoursParams = {
			...filter,
			date: currentDate,
			skip: filter.skip,
		};
		getProjects(params);
	}, [currentDate, filter, getProjects]);

	useEffect(() => {
		const initialAppointments: { [key: string]: Date | null } = {};
		projects.forEach((project) => {
			project.tasks.forEach((task) => {
				initialAppointments[task.id] = null;
			});
		});
		setAppointments(initialAppointments);
	}, [projects]);

	const sendFilter = useCallback((values: any) => {
		setFilterDrawerOpen(false);
		setFilter({ ...values, skip: 0 });
	}, []);

	const onChangeAppointment = useCallback((taskId: string, value: Date | null) => {
		setAppointments((prevAppointments) => ({
			...prevAppointments,
			[taskId]: value,
		}));
	}, []);

	const sendAppointment = useCallback((taskId: string) => {
		let appointmentTime = appointments[taskId];

		if (!appointmentTime) {
			appointmentTime = new Date();
		}

		const baseDate = currentDate ? new Date(currentDate) : new Date();
		const dateTime = new Date(
			baseDate.getFullYear(),
			baseDate.getMonth(),
			baseDate.getDate(),
			appointmentTime.getHours(),
			appointmentTime.getMinutes(),
		);

		const clockInOutData: TaskClockInOut = {
			dateTime,
			projectTaskId: taskId,
		};

		sendClockAppointment(clockInOutData);

		setAppointments((prevAppointments) => ({
			...prevAppointments,
			[taskId]: null,
		}));
	}, [appointments, currentDate, sendClockAppointment]);

	const handleSendAppointment = useCallback((taskId: string) => {
		let task: Task | undefined;

		projects.forEach(({ tasks }) => {
			if (!task) {
				task = tasks.find(({ id }) => id === taskId);
			}
		});
		sendAppointment(taskId);
	}, [projects, sendAppointment]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const onChangePagination = useCallback((page, take) => {
		const skip = (page - 1) * take;
		setFilter((prevFilters) => ({
			...prevFilters,
			skip,
			take,
		}));
	}, [setFilter]);

	const openOverflowModal = useCallback((task) => {
		setSelectedTask(task);
		setOverflowDialogOpen(true);
	}, []);

	const closeOverflowModal = useCallback(() => {
		setOverflowDialogOpen(false);
		setSelectedTask(null);
	}, []);

	const handleOverflowSubmit = useCallback((quantity: string, actionJustificative: string) => {
		if (!selectedTask) return;

		const data = {
			taskId: selectedTask.id,
			quantity: parseFloat(quantity),
			actionJustificative,
		};

		const queryParams: UserProjectsTasksHoursParams = {
			...filter,
			date: currentDate,
			skip: filter.skip,
		};

		createOverflowTask(data, queryParams);
	}, [createOverflowTask, selectedTask, filter, currentDate]);

	const appointmentFilterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<AppointmentFilter
				sendFilter={sendFilter}
				filterValues={filter}
			/>
		</DrawerFilter>
	), [filter, isFilterDrawerOpen, sendFilter, toggleFilterDrawer]);

	const headerButtonsProps = useMemo((): PageHeaderButtonProps[] => [
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
	], [toggleFilterDrawer]);

	const currentDateStr = useMemo(() => format(currentDate, 'yyyy-MM-dd'), [currentDate]);

	const todayStr = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);

	const dayCarouselMemo = useMemo(() => (
		<Paper sx={{
			padding: 3,
			marginBlock: 3,
		}}
		>
			<DayCarousel
				daysBackAppointment={daysBackAppointment}
				onDateChange={setCurrentDate}
			/>
		</Paper>
	), [daysBackAppointment]);

	const taskAccordionsMemo = useMemo(() => projects.flatMap(
		(project) => project.tasks
			.filter((task) => !task.projectTaskId
				|| !project.tasks.some((parentTask) => parentTask.id === task.projectTaskId))
			.map((task) => (
				<TaskAccordion
					key={task.id}
					projectId={project.id}
					task={task}
					allTasks={project.tasks}
					appointments={appointments}
					onChangeAppointment={onChangeAppointment}
					handleSendAppointment={handleSendAppointment}
					removeClockAppointment={removeClockAppointment}
					openOverflowModal={() => openOverflowModal(task)}
					nestingLevel={0}
				/>
			)),
	), [
		projects,
		appointments,
		onChangeAppointment,
		handleSendAppointment,
		openOverflowModal,
		removeClockAppointment,
	]);

	const taskOverflowModalMemo = useMemo(() => (
		<TaskOverflowModal
			open={overflowDialogOpen}
			onClose={closeOverflowModal}
			onSubmit={handleOverflowSubmit}
		/>
	), [closeOverflowModal, handleOverflowSubmit, overflowDialogOpen]);

	return (
		<Box>
			<PageHeader
				title="Registro de horas de projeto"
				subtitle={`Data: ${format(new Date(currentDate), 'dd/MM/yyyy')}. Horas trabalhadas no dia: ${formatHoursMinutes(workedHoursDay)}`}
				buttons={headerButtonsProps}
			/>
			{dayCarouselMemo}
			{currentDateStr < todayStr && (
				<Alert
					icon={<WarningAmberIcon fontSize="inherit" />}
					style={{ backgroundColor: '#FFEBE5', margin: '10px 0' }}
					severity="warning"
				>
					Atenção: A data é retroativa!
				</Alert>
			)}
			{appointmentFilterMemo}
			{loading && <Loading />}
			{!loading && projects.length > 0 ? projects.map((project) => (
				<Accordion key={project.id} sx={{ marginBottom: 2 }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${project.id}-content`} id={`${project.id}-header`}>
						<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
							<Box display="flex" alignItems="center" gap={2} flex="1">
								<Tooltip
									title={(
										<>
											<Typography color="inherit">Informações do Projeto</Typography>
											<Box>
												<Typography variant="body2">
													Descrição:
													{project.description}
												</Typography>
												<Typography variant="body2">
													Início:
													{formatDateAndHours(project.startDate)}
												</Typography>
												<Typography variant="body2">
													Término:
													{formatDateAndHours(project.endDate)}
												</Typography>
												<Typography variant="body2">
													Cliente:
													{project.client}
												</Typography>
												<Typography variant="body2">
													Loja:
													{project.store}
												</Typography>
												<Typography variant="body2">
													Fase:
													{project.phase}
												</Typography>
												<Typography variant="body2">
													Concluído:
													{project.finished ? 'Sim' : 'Não'}
												</Typography>
											</Box>
										</>
									)}
								>
									<Typography variant="h6" sx={{ cursor: 'help' }}>
										{`${project.code} - ${project.name}`}
									</Typography>
								</Tooltip>
							</Box>
							<Tooltip
								title={(
									<Box>
										<Box display="flex" alignItems="center" gap={0.5}>
											<AccessTimeIcon />
											<Typography variant="subtitle2">
												Horas previstas:
												{' '}
												{formatHoursMinutes(project.quantity)}
											</Typography>
										</Box>
										<Box display="flex" alignItems="center" gap={0.5}>
											<CheckCircleIcon />
											<Typography variant="subtitle2">
												Horas realizadas:
												{' '}
												{formatHoursMinutes(project.workedHours)}
											</Typography>
										</Box>
										<Box display="flex" alignItems="center" gap={0.5}>
											<ScaleIcon />
											<Typography variant="subtitle2">
												Saldo de Horas:
												{' '}
												{formatHoursMinutes(project.hoursBalance)}
											</Typography>
										</Box>
										<Box display="flex" alignItems="center" gap={0.5}>
											<AlarmOnIcon />
											<Typography variant="subtitle2">
												% Horas Realizadas:
												{' '}
												{`${project.completionPercentage || 0}%`}
											</Typography>
										</Box>
									</Box>
								)}
								arrow
							>
								<InfoIcon />
							</Tooltip>
						</Box>
					</AccordionSummary>
					<AccordionDetails>
						{taskAccordionsMemo.filter((accordionElement) => accordionElement.props.projectId
							=== project.id)}
					</AccordionDetails>
				</Accordion>
			)) : (
				!loading && <NoDataPage />
			)}
			<Pagination
				loading={loading}
				count={projectsPages}
				page={projectsPage}
				take={projectsTake}
				onChange={onChangePagination}
			/>
			{overflowDialogOpen && taskOverflowModalMemo}
		</Box>
	);
};
export default Appointment;
