import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { format } from 'date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimerIcon from '@mui/icons-material/Timer';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScaleIcon from '@mui/icons-material/Scale';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { Task } from '../../../containers/HourProject/ApportionmentAssets';
import { formatDateAndHours, formatHoursMinutes } from '../../../helpers/Utils';
import { ProjectTaskStatus } from '../../../enums/ProjectTaskStatus';

const textFieldStyle = {
	width: 100,
	'& .MuiInput-underline:before, & .MuiInput-underline': {
		borderBottomColor: '#b71c1c',
		borderBottomWidth: '2px',
	},
};

const clockInOutStyle = {
	display: 'inline-block',
	padding: '2px 5px',
	borderRadius: '4px',
	backgroundColor: '#f5f5f5',
};

interface TaskAccordionProps {
	projectId: string;
	task: Task;
	allTasks: Task[];
	appointments: { [key: string]: Date | null };
	onChangeAppointment(taskId: string, value: Date | null): void;
	handleSendAppointment(taskId: string): void;
	removeClockAppointment(id: string): void;
	openOverflowModal: (task: Task) => void;
	nestingLevel: number;
}

const TaskAccordion = ({
	projectId,
	task,
	allTasks,
	appointments,
	onChangeAppointment,
	handleSendAppointment,
	removeClockAppointment,
	openOverflowModal,
	nestingLevel,
}: TaskAccordionProps): JSX.Element => {
	const subTasks = useMemo(() => allTasks.filter(
		(subTask) => subTask.projectTaskId === task.id,
	), [allTasks, task.id]);

	const canRegisterTime = useMemo(
		() => subTasks.length === 0
			&& task.quantity !== '0:00'
			&& task.status === ProjectTaskStatus.APPROVED
			&& task.completionPercentage < 100,
		[subTasks.length, task.quantity, task.status, task.completionPercentage],
	);

	const lastExtraTask = useMemo(() => {
		const baseCode = task.code.split(' (Tarefa Extra')[0];
		const extraTasks = allTasks.filter((subTask): subTask is Task => subTask.code.startsWith(baseCode) && subTask.code.includes('Tarefa Extra'));
		return extraTasks.at(-1);
	}, [allTasks, task.code]);

	const showNewTaskButton = useMemo(() => {
		const isEligibleForExtraTask = task.completionPercentage >= 100;
		return isEligibleForExtraTask && (!lastExtraTask || task.id
			=== lastExtraTask.id || lastExtraTask.status === ProjectTaskStatus.REJECTED);
	}, [task, lastExtraTask]);

	const message = useMemo(() => {
		if (lastExtraTask && task.id !== lastExtraTask.id) {
			return 'Saldo de horas da tarefa esgotado! Utilize a tarefa extra para o registro de horas.';
		}
		return 'Saldo de horas da tarefa esgotado! Se necessário crie uma tarefa extra para continuar.';
	}, [lastExtraTask, task.id]);

	const renderSubTasks = useMemo(() => (
		subTasks.map((subTask) => (
			<TaskAccordion
				key={subTask.id}
				projectId={projectId}
				task={subTask}
				allTasks={allTasks}
				appointments={appointments}
				onChangeAppointment={onChangeAppointment}
				handleSendAppointment={handleSendAppointment}
				removeClockAppointment={removeClockAppointment}
				openOverflowModal={() => openOverflowModal(task)}
				nestingLevel={nestingLevel + 1}
			/>
		))
	), [
		projectId,
		subTasks,
		allTasks,
		appointments,
		onChangeAppointment,
		handleSendAppointment,
		removeClockAppointment,
		openOverflowModal,
		task,
		nestingLevel,
	]);

	const renderTimeRegistration = useMemo(() => (
		canRegisterTime ? (
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell sx={{ width: '15%' }}>% HR Real</TableCell>
						<TableCell sx={{ width: '75%' }}>Horários registrados</TableCell>
						<TableCell sx={{ width: '10%', minWidth: '150px' }}>Hora</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							<Box sx={{
								display: 'flex',
								justifyContent: 'center',
								maxWidth: '80px',
							}}
							>
								{`${task.completionPercentage || 0}%`}
							</Box>
						</TableCell>
						<TableCell>
							{task.tasksClockInOut.map((clockInOut) => (
								<Box key={clockInOut.id} sx={{ display: 'inline-flex', alignItems: 'left' }}>
									<span style={clockInOutStyle}>
										{format(new Date(clockInOut.dateTime), 'HH:mm')}
									</span>
									<IconButton size="small" onClick={() => removeClockAppointment(clockInOut.id as string)} sx={{ padding: '0px', color: 'error.main', marginLeft: '-8px' }}>
										<DeleteIcon fontSize="small" />
									</IconButton>
								</Box>
							))}
							{task.tasksClockInOut.length === 0 && 'Nenhuma hora registrada'}
						</TableCell>
						<TableCell>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
								<TimePicker
									renderInput={(params: TextFieldProps) => (
										<TextField
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...params}
											InputProps={{ ...params.InputProps, endAdornment: null }}
											variant="standard"
											sx={textFieldStyle}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													e.preventDefault();
													handleSendAppointment(task.id);
												}
											}}
										/>
									)}
									onChange={
										(newValue) => onChangeAppointment(task.id, newValue)
									}
									value={appointments[task.id] || null}
								/>
								<Button
									variant="contained"
									onClick={() => handleSendAppointment(task.id)}
									size="small"
									startIcon={<TimerIcon />}
								>
									Registrar
								</Button>
							</Box>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		) : null
	), [
		canRegisterTime,
		task,
		appointments,
		onChangeAppointment,
		handleSendAppointment,
		removeClockAppointment,
	]);

	return (
		<Accordion
			sx={{
				boxShadow: nestingLevel ? 3 : 1,
				width: '100%',
				'& .MuiAccordion-root': {
					width: '100%',
				},
				'& .MuiAccordionDetails-root': {
					padding: 0,
				},
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
					<Box display="flex" alignItems="center" gap={2} flex="1">
						<Tooltip
							title={(
								<>
									<Typography color="inherit">Informações da Tarefa</Typography>
									<Box>
										<Typography variant="body2">
											Descrição:
											{' '}
											{task.description}
										</Typography>
										<Typography variant="body2">
											Início:
											{' '}
											{formatDateAndHours(task.startDate)}
										</Typography>
										<Typography variant="body2">
											Término:
											{' '}
											{formatDateAndHours(task.endDate)}
										</Typography>
									</Box>
								</>
							)}
							arrow
						>
							<Typography sx={{ cursor: 'help' }}>
								{`${task.code} - ${task.name}`}
							</Typography>
						</Tooltip>
					</Box>
					{task.status === ProjectTaskStatus.ACTIVE && (
						<Box sx={{
							backgroundColor: '#FFEBE5', p: 1, mr: 1, borderRadius: 4,
						}}
						>
							<Typography variant="body2">A Tarefa precisa de aprovação do administrador para registrar hora!</Typography>
						</Box>
					)}
					{task.status === ProjectTaskStatus.REJECTED && (
						<Box sx={{
							backgroundColor: '#FFEBE5',
							p: 1,
							mr: 1,
							borderRadius: 4,
						}}
						>
							<Typography variant="body2">Tarefa rejeitada pelo administrador!</Typography>
						</Box>
					)}
					{task.completionPercentage >= 100 && (
						<Box sx={{
							backgroundColor: '#FFEBE5',
							p: 1,
							mr: 1,
							borderRadius: 4,
						}}
						>
							<Typography variant="body2">{message}</Typography>
						</Box>
					)}
					{showNewTaskButton && (
						<Tooltip title="Criar tarefa extra">
							<IconButton onClick={() => openOverflowModal(task)} size="small">
								<AddIcon />
							</IconButton>
						</Tooltip>
					)}
					<Tooltip
						title={(
							<Box>
								<Box display="flex" alignItems="center" gap={0.5}>
									<AccessTimeIcon />
									<Typography variant="subtitle2">
										Horas previstas:
										{' '}
										{formatHoursMinutes(task.quantity)}
									</Typography>
								</Box>
								<Box display="flex" alignItems="center" gap={0.5}>
									<CheckCircleIcon />
									<Typography variant="subtitle2">
										Horas realizadas:
										{' '}
										{formatHoursMinutes(task.workedHours)}
									</Typography>
								</Box>
								<Box display="flex" alignItems="center" gap={0.5}>
									<ScaleIcon />
									<Typography variant="subtitle2">
										Saldo de Horas:
										{' '}
										{formatHoursMinutes(task.hoursBalance)}
									</Typography>
								</Box>
								<Box display="flex" alignItems="center" gap={0.5}>
									<AlarmOnIcon />
									<Typography variant="subtitle2">
										% Horas Realizadas:
										{' '}
										{`${task.completionPercentage || 0}%`}
									</Typography>
								</Box>
								{task.status === ProjectTaskStatus.REJECTED && (
									<Box display="flex" alignItems="center" gap={0.5}>
										<InfoIcon color="error" />
										<Typography variant="subtitle2">
											Justificativa da Rejeição:
											{' '}
											{task.actionJustificative}
										</Typography>
									</Box>
								)}
							</Box>
						)}
						arrow
					>
						<InfoIcon />
					</Tooltip>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				{renderSubTasks.length > 0 ? renderSubTasks : renderTimeRegistration}
			</AccordionDetails>
		</Accordion>
	);
};

export default TaskAccordion;
