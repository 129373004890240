import React, { useCallback, useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	CircularProgress,
} from '@mui/material';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import * as Yup from 'yup';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import {
	BRANCH_ID_KEY, ChangeBranchPreference, COMPANY_ID_KEY,
} from '../../services/auth';
import Autocomplete from '../Common/Form/Autocomplete';
import { Loading } from '../Common/Loading';

interface CompanyBranchModalProps {
	open: boolean;
	loading: boolean;
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	getBranches: (companyId: string) => void;
	handleClose: () => void;
	setBranch: (data: ChangeBranchPreference) => void;
	getCompanies: () => void;
}

const validationSchema = Yup.object().shape({
	branchCompanyId: Yup.string().required('Empresa é obrigatória'),
	branchId: Yup.string().required('Filial é obrigatória'),
});

const CompanyBranchModal: React.FC<CompanyBranchModalProps> = ({
	open,
	loading,
	companies,
	branches,
	handleClose,
	getBranches,
	setBranch,
	getCompanies,
}) => {
	const [initialValues, setInitialValues] = useState({
		branchId: '',
		branchCompanyId: '',
	});

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: (values) => {
			setBranch({
				branchId: values.branchId,
			});
		},
	});

	useEffect(() => {
		const initialValuesLoad = {
			branchCompanyId: localStorage.getItem(COMPANY_ID_KEY) || '',
			branchId: localStorage.getItem(BRANCH_ID_KEY) || '',
		};

		setInitialValues(initialValuesLoad);
		if (open) {
			getCompanies();
			if (formik.values.branchCompanyId) {
				getBranches(formik.values.branchCompanyId);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, getCompanies, formik.values.branchCompanyId, getBranches]);

	const handleCloseModal = useCallback(() => {
		handleClose();
		formik.resetForm();
	}, [formik, handleClose]);

	if (!companies && !branches) return <Loading />;

	return (
		<Dialog
			open={open}
			maxWidth="sm"
			fullWidth
			onClose={handleCloseModal}
		>
			<FormikContext.Provider value={formik}>
				<Form>
					<DialogTitle>Selecionar empresa e filial</DialogTitle>
					<DialogContent>
						{loading ? (
							<Grid container justifyContent="center" sx={{ py: 3 }}>
								<CircularProgress />
							</Grid>
						) : (
							<Grid container spacing={2} sx={{ mt: 1 }}>
								<Grid item xs={12}>
									<Autocomplete
										label="Empresa"
										name="branchCompanyId"
										valueKey="id"
										valueLabel="name"
										labelKey="name"
										options={companies}
										closeOnSelect
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<Autocomplete
										label="Filial"
										name="branchId"
										valueKey="id"
										valueLabel="code"
										labelKey="name"
										options={branches}
										closeOnSelect
										required
										disabled={!formik.values.branchCompanyId}
									/>
								</Grid>
							</Grid>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseModal}>
							Cancelar
						</Button>
						<Button
							type="submit"
							variant="contained"
							disabled={loading || !formik.isValid}
						>
							Salvar
						</Button>
					</DialogActions>
				</Form>
			</FormikContext.Provider>
		</Dialog>
	);
};

export default CompanyBranchModal;
