import { getModules } from '../services/app';
import { IModule } from '../interfaces/Module';

const usePermission = (moduleCode: string): IModule | undefined => {
	const module = getModules().find((currentModule) => currentModule.module.code === moduleCode);

	return module;
};

export default usePermission;
