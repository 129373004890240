import React from 'react';
import { Formik, Form } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LockResetIcon from '@mui/icons-material/LockReset';
import Box from '@mui/material/Box';
import Input from '../Common/Form/Input';
import GenericDrawer from '../Common/GenericDrawer';
import SwitchField from '../Common/Form/Switch';
import { CurrentUserProps } from '../../containers/User/UserAssets';

const initialValues = {
	newPassword: '',
	resetOnNextAccess: true,
};

interface IResetPasswordDrawerProps {
	openReset?: CurrentUserProps;
	handleClose: () => void;
	changePassword: (id: string, password: string, changePasswordLogin: boolean) => void;
}

export const ResetPasswordDrawer = ({
	openReset,
	handleClose,
	changePassword,
}: IResetPasswordDrawerProps): JSX.Element => {
	const handleGeneratePassword = (setFieldValue: (field: string, value: string) => void): void => {
		const generatedPassword = Math.random().toString(36).substring(2, 8);
		setFieldValue('newPassword', generatedPassword);
	};

	const handleSubmit = (values: typeof initialValues): void => {
		if (openReset) {
			changePassword(openReset.id, values.newPassword, values.resetOnNextAccess);
			handleClose();
		}
	};

	const handleCopyToClipboard = (value: string): void => {
		navigator.clipboard.writeText(value);
	};

	const content = (
		<Formik initialValues={initialValues} onSubmit={handleSubmit}>
			{({ values, setFieldValue }) => (
				<Form>
					<Input.InputField
						name="newPassword"
						type="password"
						label="Nova senha"
						value={values.newPassword}
						autoComplete="new-password"
						required
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => handleGeneratePassword(setFieldValue)}
									>
										<LockResetIcon titleAccess="Gerar senha aleatória" />
									</IconButton>
									<IconButton
										onClick={() => handleCopyToClipboard(values.newPassword)}
									>
										<FileCopyIcon titleAccess="Copiar" />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<Box my={2}>
						<SwitchField
							name="resetOnNextAccess"
							label="Redefinir no próximo acesso"
						/>
					</Box>
					<LoadingButton
						variant="contained"
						color="primary"
						type="submit"
					>
						Redefinir Senha
					</LoadingButton>
				</Form>
			)}
		</Formik>
	);

	return (
		<GenericDrawer
			open={Boolean(openReset)}
			onClose={handleClose}
			title="Redefinição de Senha"
			subtitle={openReset?.name}
			content={content}
		/>
	);
};

ResetPasswordDrawer.defaultProps = {
	openReset: null,
};
