import React, { useMemo } from 'react';
import {
	Alert, Box, FormGroup, TextField, Theme,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { SxProps } from '@mui/system';
import { Form, Formik } from 'formik';
import Input from '../Common/Form/Input';
import { LoginData } from '../../services/auth';

interface LoginFormProps {
	loading: boolean;
	errorMessage: string;
	alreadyLogged: boolean;
	handleSubmit: (loginData: LoginData) => void;
}

const form: SxProps<Theme> = {
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '2rem',
};

const LoginForm = ({
	loading,
	errorMessage,
	alreadyLogged,
	handleSubmit,
}: LoginFormProps): JSX.Element => {
	const invalidLoginError = useMemo(() => {
		if (errorMessage) {
			return <Alert sx={{ width: '100%', boxSizing: 'border-box' }} severity="error">{errorMessage}</Alert>;
		}

		return null;
	}, [errorMessage]);

	const alreadyLoggedInfo = useMemo(() => {
		if (alreadyLogged) {
			return (
				<Alert
					sx={{ width: '100%', boxSizing: 'border-box' }}
					severity="info"
				>
					Já existe uma sessão aberta para o seu usuário, caso deseje entrar aqui,
					a outra sessão será derrubada e você vai perder qualquer trabalho que estiver fazendo.
				</Alert>
			);
		}

		return null;
	}, [alreadyLogged]);

	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
			}}
			onSubmit={(values: { email: string; password: string }) => {
				handleSubmit({ username: values.email, password: values.password });
			}}
		>
			<Box
				component={Form}
				sx={form}
				noValidate
			>
				{invalidLoginError}
				<FormGroup sx={{ width: '100%', display: 'flex', rowGap: '3rem' }}>
					<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
						<Input.InputField
							id="email"
							name="email"
							type="email"
							label="Login/E-mail"
						/>
						<Input.PasswordInputField
							id="password"
							name="password"
							label="Senha"
						/>
						{alreadyLoggedInfo}
					</Box>
					<LoadingButton
						loading={loading}
						variant="contained"
						color="primary"
						type="submit"
					>
						{ alreadyLogged ? 'Entrar mesmo assim' : 'Entrar'}
					</LoadingButton>
				</FormGroup>
			</Box>
		</Formik>
	);
};

export default LoginForm;
