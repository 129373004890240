import React, { useCallback, useState } from 'react';
import { FormikContext, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { SaveTaskType } from '../../../services/storage';
import { validationSchema } from '../../../containers/Mobile/Storage/StorageAssets';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import { InventoryTaskStatus } from '../../../enums/InventoryTaskStatus';
import StorageStartCheck from './StorageCheckStart';
import StorageCheckConfirmation from './StorageCheckConfirmation';
import { removeBarCodeMask } from '../../../helpers/masks';

interface StorageProps {
	loading: boolean;
	getLocationOrigin: (barCode: string, status: InventoryTaskStatus) => void;
	tasks: ITask[];
	saveTakeTask: (data: SaveTaskType) => void;
}

export enum StepsStorage {
	START,
	PRODUCTS,
}

const initialValues = {
	barCodeOrigin: '',
	confirmedTasks: [],
};

const StorageCheck = ({
	loading,
	getLocationOrigin,
	tasks,
	saveTakeTask,
}: StorageProps): JSX.Element => {
	const [steps, setSteps] = useState<StepsStorage>(StepsStorage.START);

	const navigate = useNavigate();

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: () => {
			navigate('/app');
		},
		enableReinitialize: true,
	});

	const handleBarCodeOrigin = useCallback((barCode: string): void => {
		setSteps(StepsStorage.PRODUCTS);
		getLocationOrigin(removeBarCodeMask(barCode), InventoryTaskStatus.WAITING);
	}, [getLocationOrigin]);

	const handleConfirmStorage = useCallback((): void => {
		navigate('/app');
	}, [navigate]);

	const handleBack = useCallback((): void => {
		formik.setFieldValue('barCodeOrigin', '');
		navigate('/app');
	}, [formik, navigate]);

	return (
		<FormikContext.Provider value={formik}>
			{steps === StepsStorage.START && (
				<StorageStartCheck
					handleBarCodeOrigin={handleBarCodeOrigin}
				/>
			)}

			{steps === StepsStorage.PRODUCTS && (
				<StorageCheckConfirmation
					loading={loading}
					getLocationOrigin={getLocationOrigin}
					saveTakeTask={saveTakeTask}
					tasks={tasks}
					handleConfirmStorage={handleConfirmStorage}
					handleBack={handleBack}
				/>
			)}

		</FormikContext.Provider>
	);
};

export default StorageCheck;
