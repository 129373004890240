/* eslint-disable react/jsx-props-no-spreading */
import { LoadingButton } from '@mui/lab';
import {
	Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle: React.CSSProperties = {
	flex: '1 1 auto',
	display: 'flex',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const focusedStyle: React.CSSProperties = {
	borderColor: '#2196f3',
};

const acceptStyle: React.CSSProperties = {
	borderColor: '#00e676',
};

const rejectStyle: React.CSSProperties = {
	borderColor: '#ff1744',
};

export const UploadBudgetDialog = (props: any): JSX.Element => {
	const {
		showUploadModal, setShowUploadModal, uploading, uploadBudgetFile,
	} = props;

	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		accept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
		multiple: false,
		onDrop: () => null,
		onDragEnter: () => null,
		onDragOver: () => null,
		onDragLeave: () => null,
	});

	const closeUploadModal = useCallback(
		(event, reason?) => {
			if (reason === 'backdropClick') {
				event.preventDefault();
				return;
			}

			setShowUploadModal(false);
		},
		[setShowUploadModal],
	);

	const handleUpload = useCallback(() => {
		const formData = new FormData();

		formData.append('file', acceptedFiles[0]);
		uploadBudgetFile(formData);
		closeUploadModal(null, null);
	}, [acceptedFiles, closeUploadModal, uploadBudgetFile]);

	const dropzoneStyle = useMemo(
		() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject],
	);

	const getUploader = useCallback(() => {
		const files = acceptedFiles.map((item: any) => (
			<>
				<h4>Arquivo:</h4>
				<ul>
					<li key={item.name}>
						{item.name}
						{' '}
						-
						{item.size}
						{' '}
						bytes
					</li>
				</ul>
			</>
		));

		return (
			<section className="container">
				<div
					{...getRootProps({
						className: 'dropzone',
						style: dropzoneStyle,
					})}
				>
					<input type="file" {...getInputProps()} />
					<p>Arraste um arquivo ou clique para selecionar um arquivo</p>
				</div>
				<aside>{files}</aside>
			</section>
		);
	}, [acceptedFiles, getRootProps, getInputProps, dropzoneStyle]);

	const getUploadModal = (): JSX.Element => (
		<Dialog
			onClose={closeUploadModal}
			open={showUploadModal}
			disableEscapeKeyDown
			maxWidth="lg"
			fullWidth
		>
			<DialogTitle>Importar orçamento</DialogTitle>

			<DialogContent dividers>{getUploader()}</DialogContent>

			<DialogActions>
				<Button onClick={closeUploadModal} disabled={uploading}>
					Fechar
				</Button>
				<LoadingButton
					onClick={handleUpload}
					endIcon={<SendIcon />}
					loading={uploading}
					loadingPosition="end"
					variant="contained"
				>
					Enviar
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);

	return (getUploadModal());
};
