import { AxiosResponse } from 'axios';
import api from './api';

interface IClients {
	count: number;
	data: any[];
}

export const getClients = (): Promise<AxiosResponse<IClients>> => api.get<IClients>('/client');

export const getClientById = (id: string): Promise<AxiosResponse<any>> => api.get<any[]>(`client/${id}`);

export const saveClient = (data: any): Promise<AxiosResponse<any>> => api.post<any, AxiosResponse<any>>('/client', data);

export const approveClient = (
	id: string,
): Promise<AxiosResponse<any>> => api.patch<{ id: string }, AxiosResponse<any>>(
	`/client/approve/${id}`,
);
