import Decimal from 'decimal.js';
import { ILocation, ILocationProductTableRow } from '../containers/Location/LocationAssets';
import { ConversionType } from '../enums/ConversionType';

export const formatProductsByLocationTable = (
	location: ILocation,
): ILocationProductTableRow[] => {
	if (location.products) {
		const rows:ILocationProductTableRow[] = location.products.map((product) => ({
			id: product.productId,
			valuedQuantity: product.valuedQuantity,
			currentQuantity: product.currentQuantity,
			description: `${product.product.code} - ${product.product.description}`,
			measure1: product.product.measures[0]?.description ?? 'N/A',
			measure2: product.product.measures[1]?.description ?? 'N/A',
			valuedQuantity2: String(product.product.conversionType === ConversionType.DIVIDER
				? new Decimal(product.valuedQuantity).times(product.product.conversionIndex)
				: new Decimal(product.valuedQuantity).dividedBy(product.product.conversionIndex)),
			currentQuantity2: String(product.product.conversionType === ConversionType.DIVIDER
				? new Decimal(product.currentQuantity).times(product.product.conversionIndex)
				: new Decimal(product.currentQuantity).dividedBy(product.product.conversionIndex)),
			locationProduct: product,
		}));

		return rows;
	}

	return [];
};
