import { getTokenDecoded } from '../services/app';

export const getUserData = (): any => {
	const data = getTokenDecoded();

	if (!data) {
		return null;
	}

	return {
		...data,
		firstName: data.name.split(' ')[0],
	};
};
