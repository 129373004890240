export const passwordRequirements = {
	lowerCase: {
		code: '(?=.*[a-z])',
		text: 'Uma letra minúscula',
	},
	upperCase: {
		code: '(?=.*[A-Z])',
		text: 'Uma letra maiúscula',
	},
	number: {
		code: '(?=.*\\d)',
		text: 'Um número',
	},
	specialChar: {
		code: '(?=.*[-+_!@#$%^&*., ?])',
		text: 'Um caractere especial',
	},
};

export const defaultPasswordRequirements = [
	passwordRequirements.lowerCase,
	passwordRequirements.upperCase,
	passwordRequirements.specialChar,
];

export const hasPasswordRequirements = (string: string, requirements: Array<string>): boolean => {
	const pattern = new RegExp(`^${requirements.join('')}.+$`);

	if (!string) {
		return false;
	}

	return pattern.test(string);
};

export const hasDefaultPasswordRequirements = (string: string): boolean => {
	const requirementsCodesArray = defaultPasswordRequirements.map((requirement) => requirement.code);

	return hasPasswordRequirements(string, requirementsCodesArray);
};
