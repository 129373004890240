import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { SxProps } from '@mui/system';
import noDataImage from '../../assets/no_data.svg';

const pageWrapper: SxProps<Theme> = {
	height: '100%',
	width: '100%',
};

const image: SxProps<Theme> = {
	height: '20rem',
};

interface INoDataPage {
	title?: string;
	description?: string;
}

const NoDataPage = ({
	title,
	description,
}: INoDataPage): JSX.Element => (
	<Stack spacing={10} direction="column" alignItems="center" justifyContent="center" sx={pageWrapper}>
		<Box>
			<Box
				component="img"
				src={noDataImage}
				alt="Nenhum registro encontrado"
				sx={image}
			/>
		</Box>
		<Stack spacing={3}>
			<Typography variant="h2">{ title }</Typography>
			<Typography textAlign="center" variant="body1">
				{ description }
			</Typography>
		</Stack>
	</Stack>
);

NoDataPage.defaultProps = {
	title: 'Nenhum registro encontrado!',
	description: 'Não foi encontrado nenhum registro, caso tenha feito algum filtro, tente refazê-lo.',
};

export default NoDataPage;
