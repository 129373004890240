import React, { useCallback } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import MuiPagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface IPaginationProps {
    loading: boolean;
    count: number;
    page: number;
    take: number;
    onChange: (page: number, take: number) => void;
	onChangeItems?: (page: number, take: number) => void;
}

const container: SxProps<Theme> = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '1rem',
	mt: '1rem',
};

const Pagination = ({
	loading, count, page, take, onChange, onChangeItems,
}: IPaginationProps): JSX.Element | null => {
	const handleChange = useCallback((event: SelectChangeEvent) => {
		const currentTake = event.target.value;
		const takeDefault = 1;

		if (onChangeItems) {
			onChangeItems(takeDefault, Number.parseInt(currentTake, 10));
		} else {
			onChange(takeDefault, Number.parseInt(currentTake, 10));
		}
	}, [onChange, onChangeItems]);

	const onChangePage = useCallback((event: React.ChangeEvent<unknown>, currentPage: number) => {
		onChange(currentPage, take);
	}, [onChange, take]);

	return (
		<Box sx={container}>
			<FormControl size="small">
				<InputLabel id="pagination-items">Itens</InputLabel>
				<Select
					labelId="pagination-items"
					id="paginationItems"
					value={take.toString()}
					label="Itens"
					onChange={handleChange}
					autoWidth
				>
					<MenuItem value={10}>10</MenuItem>
					<MenuItem value={25}>25</MenuItem>
					<MenuItem value={50}>50</MenuItem>
					<MenuItem value={100}>100</MenuItem>
				</Select>
			</FormControl>
			<MuiPagination
				count={count}
				shape="rounded"
				page={page}
				onChange={onChangePage}
			/>
			{loading ? <CircularProgress size={22} /> : null}
		</Box>
	);
};

Pagination.defaultProps = {
	onChangeItems: undefined,
};

export default Pagination;
