import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import BranchTable from './BranchTable';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';

interface BranchProps {
	loading: boolean;
	branches: IBranch[];
	getBranches: () => void;
	handleDeleteBranch: (id: string, companyId: string) => void;
  }

const headerButtonsProps: PageHeaderButtonProps[] = [
	{
		variant: 'contained',
		color: 'primary',
		type: 'submit',
		fullWidth: true,
		component: Link,
		to: 'edit',
		text: 'Novo',
	},
];

const Branch = ({
	loading, branches, getBranches, handleDeleteBranch,
}: BranchProps): JSX.Element => {
	useEffect(() => {
		getBranches();
	}, [getBranches]);

	return (
		<Box className="content">
			<PageHeader title="Filial" buttons={headerButtonsProps} />
			<BranchTable
				loading={loading}
				rows={branches}
				handleDeleteBranch={handleDeleteBranch}
			/>
		</Box>
	);
};

export default Branch;
