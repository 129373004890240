import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box, Typography, Theme } from '@mui/material';

interface ProgressBarProps {
  value: number;
  reverse?: boolean;
}

interface ICommonProgressBarStyle {
	[x: string]: string | {
		backgroundColor: string;
		borderRadius?: undefined;
	} | {
		borderRadius: string;
		backgroundColor?: undefined;
	};
	height: string;
	textSize: string;
	borderRadius: string;
	color: string;
}

const CommonProgressBarStyle = (theme: Theme): ICommonProgressBarStyle => ({
	height: '1.5rem',
	textSize: '1rem',
	borderRadius: '.15rem',
	color: 'rgba(0, 0, 0, 0.87)',
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[200],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: '.15rem',
	},
});

const ReverseLinearProgress = styled(LinearProgress)(({ theme }) => ({
	...CommonProgressBarStyle(theme),
	'&.low': {
		[`& .${linearProgressClasses.bar}`]: {
			backgroundColor: theme.palette.success.light,
		},
	},
	'&.medium': {
		[`& .${linearProgressClasses.bar}`]: {
			backgroundColor: theme.palette.warning.light,
		},
	},
	'&.high': {
		[`& .${linearProgressClasses.bar}`]: {
			backgroundColor: theme.palette.error.light,
		},
	},
}));

const DefaultLinearProgress = styled(LinearProgress)(({ theme }) => ({
	...CommonProgressBarStyle(theme),
	'&.low': {
		[`& .${linearProgressClasses.bar}`]: {
			backgroundColor: theme.palette.error.light,
		},
	},
	'&.medium': {
		[`& .${linearProgressClasses.bar}`]: {
			backgroundColor: theme.palette.warning.light,
		},
	},
	'&.high': {
		[`& .${linearProgressClasses.bar}`]: {
			backgroundColor: theme.palette.success.light,
		},
	},
}));

const ProgressBar = ({ reverse, value }: ProgressBarProps): JSX.Element => {
	const linearProgressPropsMemo = useMemo(() => {
		const failSafeProgressValue = value > 100 ? 100 : value;

		const getValue = (): string => {
			if (value < 0) {
				return 'low';
			}

			if (value <= 50) {
				return 'medium';
			}

			return 'high';
		};

		return {
			value: failSafeProgressValue < 0 ? failSafeProgressValue + 200 : failSafeProgressValue,
			sx: { zIndex: 5 },
			className: getValue(),
		};
	}, [value]);

	return (
		<Box sx={{ width: '100%', position: 'relative' }}>
			<Typography sx={{
				width: '100%', position: 'absolute', zIndex: 10, textAlign: 'center',
			}}
			>
				{value}
				%
			</Typography>
			{reverse
				// eslint-disable-next-line react/jsx-props-no-spreading
				? <ReverseLinearProgress variant="determinate" {...linearProgressPropsMemo} />
				// eslint-disable-next-line react/jsx-props-no-spreading
				: <DefaultLinearProgress variant="determinate" {...linearProgressPropsMemo} />}
		</Box>
	);
};

ProgressBar.defaultProps = {
	reverse: false,
};

export default ProgressBar;
