import React from 'react';
import { Stack } from '@mui/material';
import {
	FormTitle, PersonalData, LoginConfig, CompanyBranchAccess,
} from './UserFormSections';
import { ICompanyWithoutDetails } from '../../../containers/Company/CompanyAssets';
import { IBranch } from '../../../containers/Branch/BranchAssets';
import { BranchParams } from '../../../containers/Inventory/InventoryAssets';

interface AddUserFormProps {
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	getBranches: (params: BranchParams) => void;
}

export const AddUserForm = ({
	companies,
	branches,
	getBranches,
}: AddUserFormProps): JSX.Element => (
	<Stack spacing={5}>
		<FormTitle>Adicionar usuário</FormTitle>
		<PersonalData />
		<LoginConfig />
		<CompanyBranchAccess
			companies={companies}
			branches={branches}
			getBranches={getBranches}
		/>
	</Stack>
);
