import React, { useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import {
	DataGrid, GridColDef, GridPreProcessEditCellProps, GridRowId,
} from '@mui/x-data-grid';
import { IBudgetsToShow } from '../../../interfaces/BudgetResponsible';

interface IResponsibleDataGridProps {
  rows: IBudgetsToShow[],
  selectedRows: GridRowId[];
  loading: boolean;
  handleRowChange: (budgetId: GridRowId, propertyKey: keyof IBudgetsToShow, newValue: any) => void;
  handleSelectedRowsChange: (rows: GridRowId[]) => void;
}
const BudgetResponsibleDataGrid = ({
	rows,
	selectedRows,
	loading,
	handleRowChange,
	handleSelectedRowsChange,
}: IResponsibleDataGridProps): JSX.Element => {
	const columns: GridColDef[] = useMemo(() => ([
		{
			field: 'isOwner',
			headerName: 'Responsável?',
			flex: 1,
			type: 'boolean',
			renderCell: (params: any) => <Switch checked={params.row.isOwner} onChange={() => handleRowChange(params.row.id, 'isOwner', !params.row.isOwner)} />,
		},
		{
			field: 'accountingAccount',
			headerName: 'Conta contábil',
			flex: 2,
		},
		{
			field: 'costCenter',
			headerName: 'Centro de custo',
			flex: 1,
		},
		{
			field: 'accountingItem',
			headerName: 'Filial',
			flex: 1,
		},
		{
			field: 'classValue',
			headerName: 'Cliente/Fornecedor',
			flex: 1,
		},
		{
			field: 'approvalPercent',
			headerName: '%',
			flex: 0.5,
			type: 'number',
			editable: true,
			preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
				const valueIsNotValid: boolean = params.props.value < 0 || params.props.value > 100;
				const valueHasNotChanged: boolean = params.props.value === params.row.approvalPercent;

				const hasError = valueIsNotValid || valueHasNotChanged;

				return { ...params.props, error: hasError };
			},
			valueFormatter: (params) => {
				if (params.value == null) {
					return '';
				}

				const row: IBudgetsToShow | undefined = rows
					.find((currentRow) => currentRow.id === params.id);
				const valueFormatted = row?.isOwner ? `${params.value} %` : '';

				return valueFormatted;
			},
		},
	]), [handleRowChange, rows]);

	return (
		<>
			{loading && <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />}
			{!loading && (
				<div style={{ height: '100%', width: '100%' }}>
					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={100}
						rowsPerPageOptions={[5]}
						checkboxSelection
						disableSelectionOnClick
						selectionModel={selectedRows}
						onSelectionModelChange={handleSelectedRowsChange}
						onCellEditCommit={(e) => handleRowChange(
							e.id,
							(e.field as keyof IBudgetsToShow),
							e.value?.toString(),
						)}
						isCellEditable={(params) => params.row.isOwner}
						disableColumnMenu
					/>
				</div>
			)}
		</>
	);
};

export default BudgetResponsibleDataGrid;
