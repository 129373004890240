import React, { useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Input from '../../Common/Form/Input';
import CheckboxField from '../../Common/Form/Checkbox';
import BudgetOrderFourFieldsFilter from '../../Common/BudgetOrderFourFields/BudgetOrderFourFieldsFilter';
import { IFilterFieldsValues } from '../../../interfaces/BudgetOrderFourFields';
import { filterObject } from '../../../helpers/Utils';

interface IPurchaseOrderApprovalFilterProps {
	filterFieldsValues: IFilterFieldsValues | null;
	sendFilter: (values: any) => void
}

interface IForm {
	nrOrder: string;
	providerName: string;
	productDescription: string;
	active: boolean;
	overrun: boolean;
	status?: string;
	accountingItems: any[];
	costCenters: any[];
	accountingAccounts: any[];
	classValues: any[];
	selectAll: boolean;
}

const initialValues = {
	nrOrder: '',
	providerName: '',
	productDescription: '',
	active: true,
	overrun: true,
	accountingItems: [],
	costCenters: [],
	accountingAccounts: [],
	classValues: [],
	selectAll: false,
};

export const PurchaseOrderApprovalFilter = ({
	filterFieldsValues,
	sendFilter,
}: IPurchaseOrderApprovalFilterProps): JSX.Element => {
	const onSubmit = useCallback((values: IForm) => {
		const filterValues = cloneDeep(values);

		if (
			(filterValues.active && !filterValues.overrun)
			|| (!filterValues.active && filterValues.overrun)
		) {
			if (filterValues.active) { filterValues.status = 'ACTIVE'; }
			if (filterValues.overrun) { filterValues.status = 'OVERRUN'; }
		}

		sendFilter(filterObject(filterValues, ['active', 'overrun']));
	}, [sendFilter]);

	const onReset = useCallback((handleReset) => {
		sendFilter({});
		handleReset();
	}, [sendFilter]);

	return (
		<Paper sx={{ p: 2, width: '100%' }}>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
			>
				{({ values, handleReset }) => (
					<Form>
						<Grid container spacing={3}>
							<Grid item xs={4}>
								<Input.InputField
									id="nrOrder"
									name="nrOrder"
									label="Nr do pedido"
								/>
							</Grid>
							<Grid item xs={4}>
								<Input.InputField
									id="providerName"
									name="providerName"
									label="Fornecedor"
								/>
							</Grid>
							<Grid item xs={4}>
								<Input.InputField
									id="productDescription"
									name="productDescription"
									label="Produto"
								/>
							</Grid>
							{filterFieldsValues && (
								<BudgetOrderFourFieldsFilter
									accountingAccounts={filterFieldsValues.accountingAccounts}
									accountingItems={filterFieldsValues.accountingItems}
									classValues={filterFieldsValues.classValues}
									costCenters={filterFieldsValues.costCenters}
									breakpoints={{ xs: 6, xl: 3 }}
									limitTags={2}
								/>
							)}
							<Grid item xs={12}>
								<CheckboxField.CheckboxField
									name="status"
									options={[
										{ id: 'active', checked: values.active, label: 'Ativo' },
										{ id: 'overrun', checked: values.overrun, label: 'Estouro' },
									]}
									orientation="row"
								/>
							</Grid>
							<Grid item xs={6}>
								<CheckboxField.CheckboxField
									name="selectAll"
									options={[
										{ id: 'selectAll', checked: values.selectAll, label: 'Selecionar Filtrados' },
									]}
									orientation="row"
								/>
							</Grid>
							<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
								<Button variant="outlined" onClick={() => onReset(handleReset)}>RESTAURAR</Button>
								<Button variant="contained" type="submit">FILTRAR</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};
