import { useEffect } from 'react';

interface UseConditionalSearchProps<T> {
    inputValue: string;
    loadedItems?: T[];
    searchFunction: (searchTerm: string) => void;
    minLength: number;
    compareKey: keyof T;
}

export function useConditionalSearch<T>({
	inputValue,
	loadedItems,
	searchFunction,
	minLength,
	compareKey,
}: UseConditionalSearchProps<T>): void {
	useEffect(() => {
		if (inputValue.length >= minLength) {
			const isItemPresent = loadedItems?.some(
				(item) => String(
					item[compareKey],
				).toLowerCase().includes(inputValue.toLowerCase()),
			);

			if (!isItemPresent) {
				searchFunction(inputValue);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue]);
}
