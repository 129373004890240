import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import LocationTypePresentational from '../../components/LocationType/LocationType';
import { deleteLocationType, getLocationTypes } from '../../services/locationType';
import { ILocationType } from '../Location/LocationAssets';

enum ActionType {
    LOADING,
	LOCATION_TYPES
}

interface IState {
    loading: boolean;
	count: number;
	locationTypes: ILocationType[];
}

type TAction =
    { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.LOCATION_TYPES; payload: { locationTypes: ILocationType[]; count: number } };

const initialState: IState = {
	loading: false,
	count: 0,
	locationTypes: [],
};

interface ILocationTypeActions {
    setLoading(loading: boolean): void;
	getLocationTypes(): void;
	handleDeleteLocationType(id: string): void;
}

export interface LocationDataParams {
	locations: Array<ILocationType>
}

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.LOCATION_TYPES:
			return { ...state, count: action.payload.count, locationTypes: action.payload.locationTypes };
		default:
			throw new Error();
	}
};

const LocationTypeActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): ILocationTypeActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getLocationTypes() {
			actions.setLoading(true);
			getLocationTypes()
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION_TYPES,
						payload: {
							locationTypes: response.data.data,
							count: response.data.count,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{
							variant: 'error',
						},
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		handleDeleteLocationType(id: string) {
			actions.setLoading(true);
			deleteLocationType(id)
				.then((response) => {
					enqueueSnackbar(response?.data.message, { variant: 'success' });
					actions.getLocationTypes();
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},
	};

	return actions;
};

const LocationType = ():JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => LocationTypeActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<LocationTypePresentational {...state} {...actions} />
	);
};

export default LocationType;
