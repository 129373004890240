import Decimal from 'decimal.js';
import { Currency, Locale } from '../enums/intl';

type TValue = number | Decimal;

interface CurrencyMaskOptions {
	decimalPlaces?: number;
  }

export function currencyMask(
	value: TValue,
	currency: Currency,
	locale: Locale,
	options?: CurrencyMaskOptions,
): string {
	let numberValue: number;

	if (value instanceof Decimal) {
		numberValue = value.toNumber();
	} else {
		numberValue = value;
	}

	const decimalPlaces = options?.decimalPlaces !== undefined ? options.decimalPlaces : 2;

	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
		minimumFractionDigits: decimalPlaces,
		maximumFractionDigits: decimalPlaces,
	}).format(numberValue);
}

export function currencyBRLMask(value: TValue, options?: CurrencyMaskOptions): string {
	return currencyMask(value, Currency.BRL, Locale.PT_BR, options);
}
