import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import jwtDecode from 'jwt-decode';
import LicenseTable from './LicenseTable';
import { ILicense, ILicenseDetails } from '../../containers/License/LicenseAssets';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import DetailsDrawer from './DetailsDrawer';

interface LicenseProps {
	loading: boolean;
	licenses: ILicense[];
	getLicenses(): void;
	handleDeleteLicense: (id: string) => void;
  }

const headerButtonsProps: PageHeaderButtonProps[] = [
	{
		variant: 'contained',
		color: 'primary',
		type: 'submit',
		fullWidth: true,
		component: Link,
		to: 'edit',
		text: 'Nova',
	},
];

const License = ({
	loading, licenses, getLicenses, handleDeleteLicense,
}: LicenseProps): JSX.Element => {
	const [openDetails, setOpenDetails] = useState<ILicenseDetails | undefined>(undefined);

	useEffect(() => {
		getLicenses();
	}, [getLicenses]);

	const onDetailsClick = useCallback((license: string, inUse: number) => {
		const decoded = jwtDecode(license) as ILicenseDetails;
		setOpenDetails({ ...decoded, inUse });
	}, []);

	const onCloseDetails = useCallback(() => {
		setOpenDetails(undefined);
	}, []);

	return (
		<Box className="content">
			<PageHeader title="Licença" buttons={headerButtonsProps} />
			<LicenseTable
				loading={loading}
				rows={licenses}
				handleDeleteLicense={handleDeleteLicense}
				onDetailsClick={onDetailsClick}
			/>
			<DetailsDrawer
				openDetails={openDetails}
				onClose={onCloseDetails}
			/>
		</Box>
	);
};

export default License;
