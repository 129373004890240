import React from 'react';
import { RouteProps } from 'react-router-dom';
import ProviderClientForm from '../Common/ProviderClient/ProviderClientForm';
import { ProviderClient } from '../Common/ProviderClient/ProviderClientAssets';

interface ProviderEditProps {
	loading: boolean;
	// provider?: any;
	saveProvider: (values: { id?: string; data: ProviderClient }) => void;
}

const ProviderEdit = ({ loading, saveProvider }: ProviderEditProps): JSX.Element => (
	<ProviderClientForm
		loading={loading}
		handleSubmit={saveProvider}
	/>
);

// ProviderEdit.defaultProps = {
// 	provider: null,
// };

export default ProviderEdit;
