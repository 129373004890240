import axios, { AxiosError } from 'axios';

const localizationApi = axios.create({
	baseURL: '/localization/',
});

localizationApi.interceptors.response.use(
	(response) => response,
	(error: AxiosError) => Promise.reject(error),
);

export default localizationApi;
