import { SalePriceFormationStatus } from '../enums/SalePriceFormationStatus';

export const SalePriceFormationStatusTypeLabels = {
	[SalePriceFormationStatus.PENDING]: 'Pendente',
	[SalePriceFormationStatus.HISTORIC]: 'Histórico',
	[SalePriceFormationStatus.ACTIVE]: 'Ativa',
	[SalePriceFormationStatus.UNDER_REVIEW]: 'Em Revisão',
	[SalePriceFormationStatus.APPROVED]: 'Aprovada',
	[SalePriceFormationStatus.REJECTED]: 'Reprovada',
};
