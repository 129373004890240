import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import React, {
	Dispatch, Reducer, useCallback, useEffect, useMemo, useReducer,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';
import LoginPresentational from '../components/Login/Login';
import {
	login as loginService,
	setToken,
	setId,
	setPermissions,
	hasPermissions,
	changePasswordLogin,
	LoginData,
	isAuthenticated,
	setBranchId,
	setBranchName,
	setCompanyId,
} from '../services/auth';
import { getUserPermissions } from '../services/user';

enum ActionType {
	LOADING,
	IS_CHANGING_PASSWORD,
	LOGIN_ERROR,
}

interface IState {
	loading: boolean;
	isChangingPassword: boolean;
	errorMessage: string;
	dropSession: boolean;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.IS_CHANGING_PASSWORD; payload: { isChangingPassword: boolean } }
	| { type: ActionType.LOGIN_ERROR; payload: { errorMessage: string, dropSession: boolean } };

interface ILoginActions {
    setLoading(loading: boolean): void;
    setIsChangingPassword(isChangingPassword: boolean): void;
    login(loginData: LoginData, dropSession: boolean): void;
    handleChangePassword(password: string): void;
}

const initialState: IState = {
	loading: false,
	isChangingPassword: false,
	errorMessage: '',
	dropSession: false,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.IS_CHANGING_PASSWORD:
			return { ...state, isChangingPassword: action.payload.isChangingPassword };
		case ActionType.LOGIN_ERROR:
			return {
				...state,
				errorMessage: action.payload.errorMessage,
				dropSession: action.payload.dropSession,
			};
		default:
			throw new Error();
	}
};

const LoginActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	setup: () => void,
): ILoginActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setIsChangingPassword(isChangingPassword: boolean) {
			dispatch({ type: ActionType.IS_CHANGING_PASSWORD, payload: { isChangingPassword } });
			actions.setLoading(false);
		},
		login(loginData: LoginData, dropSession: boolean) {
			actions.setLoading(true);
			loginService(loginData, dropSession).then((response) => {
				setToken(response.data.token);
				setId(response.data.id);
				setBranchName(response.data.branchName);
				setCompanyId(response.data.companyId);
				setBranchId(response.data.branchId);

				if (response.data.changePasswordLogin) {
					actions.setIsChangingPassword(true);
				} else {
					setup();
				}
			}).catch((error: AxiosError) => {
				const { response } = error;

				dispatch({ type: ActionType.LOADING, payload: { loading: false } });
				if (response?.status === 403) {
					dispatch({
						type: ActionType.LOGIN_ERROR,
						payload: { errorMessage: response?.data.message, dropSession: response?.data.error === 'ALREADY_LOGGED' },
					});
				}
			});
		},
		handleChangePassword(password: string) {
			actions.setLoading(true);

			changePasswordLogin({ password }).then((response) => {
				enqueueSnackbar(response.data.message, {
					variant: 'success',
				});
				setup();
			}).catch(() => {
				actions.setLoading(false);
				enqueueSnackbar('Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			});
		},
	};

	return actions;
};

const Login = (): JSX.Element => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);

	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const setup = useCallback(() => {
		if (!hasPermissions()) {
			getUserPermissions().then((response: any) => {
				setPermissions(response.data.data);
				navigate(isMobile ? '/app' : '/', { replace: true });
			});
		} else {
			navigate(isMobile ? '/app' : '/', { replace: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile]);

	const actions = useMemo(
		() => LoginActions(dispatch, enqueueSnackbar, setup),
		[enqueueSnackbar, setup],
	);

	useEffect(() => {
		if (isAuthenticated()) {
			setup();
		}
	}, [setup]);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <LoginPresentational {...state} {...actions} />;
};

export default Login;
