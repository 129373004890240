import React, { useCallback, useEffect, useMemo } from 'react';
import {
	Box, Divider, FormControl, Grid, MenuItem, Select, Typography,
} from '@mui/material';
import {
	DataGrid, GridCellEditCommitParams, GridColDef, GridRenderCellParams,
	GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useFormikContext } from 'formik';
import Decimal from 'decimal.js';
import {
	IPaymentMethod,
	IPaymentTerm,
	IRangePriceCommission,
	ISaleOrder,
	ISaleOrderItemExtended,
} from '../../../containers/SaleOrder/SaleOrderAssets';
import Autocomplete from '../../Common/Form/Autocomplete';
import { currencyBRLMask } from '../../../helpers/intl';
import { PaymentMethod } from '../../../enums/PaymentMethod';
import { ItemType } from '../../../enums/ItemType';
import {
	calculateTotalBonus,
	calculateTotalCommission,
	calculateTotalDiscount,
	calculateTotalOrderWithDiscount,
	calculateTotalOrderWithoutDiscount,
} from '../../../helpers/CalcTotals';

const paymentMethodOptions: IPaymentMethod[] = [
	{ description: 'DOC', id: PaymentMethod.DOC },
	{ description: 'TED', id: PaymentMethod.TED },
	{ description: 'PIX', id: PaymentMethod.PIX },
	{ description: 'Boleto', id: PaymentMethod.BOLETO },
	{ description: 'Transferência', id: PaymentMethod.TRANSFER },
	{ description: 'Cheque', id: PaymentMethod.CHECK },
	{ description: 'Dinheiro', id: PaymentMethod.CASH },
];

interface PaymentProps {
	paymentTerms: IPaymentTerm[]
	rangePriceCommissions: IRangePriceCommission[];
	rangePriceComissionsByValue?: IRangePriceCommission;
	getRangePriceCommissions: (products?: string[], paymentTermId?: string) => void;
	getRangePriceComissionByValue: (productId: string, paymentTermId: string, price: number) => void;
}

const StepPaymentMethod = ({
	paymentTerms,
	rangePriceCommissions,
	rangePriceComissionsByValue,
	getRangePriceCommissions,
	getRangePriceComissionByValue,
}: PaymentProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<ISaleOrder>();

	const { saleOrderItems, paymentTermId } = values;

	const columns: GridColDef[] = useMemo(() => [
		{
			field: 'description',
			headerName: 'Descrição',
			flex: 1,
		},
		{
			field: 'type',
			headerName: 'Tipo',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (
				<Select
					size="small"
					value={params.value}
					onChange={(event) => {
						const newValue = event.target.value;
						params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue });
					}}
					fullWidth
				>
					<MenuItem value={ItemType.SALE}>Venda</MenuItem>
					<MenuItem value={ItemType.BONUS}>Bonificação</MenuItem>
				</Select>
			),
		},
		{
			field: 'price',
			headerName: 'Valor Unitário',
			editable: true,
			flex: 1,
			valueFormatter: (params: GridValueFormatterParams) => {
				const value = params.value?.toString() || '';
				const formattedValue = value.replace(',', '.');
				return currencyBRLMask(new Decimal(formattedValue || 0));
			},
			valueParser: (value: string) => {
				const formattedValue = value.replace(',', '.');
				return formattedValue;
			},
		},
		{
			field: 'quantity',
			headerName: 'Quantidade',
			editable: true,
			flex: 1,
		},
		{
			field: 'discount',
			headerName: 'Desconto %',
			flex: 1,
			valueFormatter: (params: GridValueFormatterParams) => {
				const value = params.value as number;
				return value ? `${value.toFixed(2)}%` : '0.00%';
			},
		},
		{
			field: 'totalWithoutDiscount',
			headerName: 'Total Tab. Preço',
			align: 'left',
			flex: 1,
			valueFormatter: (params: GridValueFormatterParams) => {
				const value = params.value as string;
				return currencyBRLMask(new Decimal(value || 0));
			},
		},
		{
			field: 'totalWithDiscount',
			headerName: 'Total',
			align: 'left',
			flex: 1,
			valueFormatter: (params: GridValueFormatterParams) => {
				const value = params.value as string;
				return currencyBRLMask(new Decimal(value || 0));
			},
		},
		{
			field: 'commission',
			headerName: 'Comissão (%)',
			editable: false,
			flex: 1,
			valueFormatter: (params: GridValueFormatterParams) => {
				const value = params.value as string;
				return value ? `${parseFloat(value).toFixed(2)}%` : '0%';
			},
		},
		{
			field: 'commissionValue',
			headerName: 'Comissão (R$)',
			editable: false,
			flex: 1,
			valueFormatter: (params: GridValueFormatterParams) => {
				const value = params.value as string;
				return currencyBRLMask(new Decimal(value || 0));
			},
		},
	], [setFieldValue]);

	useEffect(() => {
		const payment45 = paymentTerms.find((paymentTerm) => paymentTerm.code === '019');
		if (payment45) {
			setFieldValue('paymentTermId', payment45?.id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (paymentTermId) {
			const productIds = saleOrderItems
				.filter((item) => !item.edit)
				.map((item) => item.productId);

			getRangePriceCommissions(productIds, paymentTermId);
		}
	}, [paymentTermId]);

	useEffect(() => {
		if (rangePriceCommissions.length > 0) {
			const updatedItems = saleOrderItems.map((item) => {
				if (item.edit) return item;

				const rangePriceCommission = rangePriceCommissions.find(
					(rpc) => rpc.productId === item.productId,
				);

				if (rangePriceCommission) {
					return {
						...item,
						price: rangePriceCommission.price,
						total: rangePriceCommission.price,
						originalPrice: rangePriceCommission.price,
						commission: rangePriceCommission.commission,
						commissionValue: rangePriceCommission.commissionValue * item.quantity,
						totalWithoutDiscount: rangePriceCommission.price * item.quantity,
						totalWithDiscount: (rangePriceCommission.price * item.quantity),
					};
				}

				return item;
			});

			setFieldValue('saleOrderItems', updatedItems);
		}
	}, [rangePriceCommissions]);

	useEffect(() => {
		if (rangePriceComissionsByValue) {
			const updatedIndex = saleOrderItems.findIndex(
				(item) => item.productId === rangePriceComissionsByValue.productId,
			);

			if (updatedIndex !== -1) {
				const updatedItem = saleOrderItems[updatedIndex];

				let discount = 0;

				if (updatedItem.price < updatedItem.originalPrice) {
					discount = ((updatedItem.originalPrice - updatedItem.price)
					/ updatedItem.originalPrice) * 100;
				}

				const updatedProduct = {
					...updatedItem,
					commission: rangePriceComissionsByValue.commission,
					commissionValue: rangePriceComissionsByValue.commissionValue * updatedItem.quantity,
					total: Number(updatedItem.price),
					totalWithDiscount: Number(updatedItem.price)
					* updatedItem.quantity,
					totalWithoutDiscount: Number(updatedItem.originalPrice)
					* updatedItem.quantity,
					discount,
				};

				const newSaleOrderItems = saleOrderItems.map(
					(item, index) => (index === updatedIndex ? updatedProduct : item),
				);

				setFieldValue('saleOrderItems', newSaleOrderItems);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rangePriceComissionsByValue]);

	const handleCellEditCommit = useCallback((params: GridCellEditCommitParams): void => {
		const { id, field, value } = params;

		const product = saleOrderItems.find(
			(saleOrderItem) => saleOrderItem.id === id,
		);

		if (product) {
			const updatedProducts = saleOrderItems.map((item: ISaleOrderItemExtended) => {
				if (item.id === id) {
					const parsedValue = parseFloat(value as string);
					const updatedProduct = { ...item, [field]: parsedValue };
					if (field === 'price') {
						updatedProduct.edit = true;
					}

					if (field === 'quantity') {
						updatedProduct.commissionValue *= Number(value);
						getRangePriceComissionByValue(
							paymentTermId,
							product.id,
							updatedProduct.price,
						);
					}

					return updatedProduct;
				}
				return item;
			});

			setFieldValue('saleOrderItems', updatedProducts);

			if (field === 'price') {
				getRangePriceComissionByValue(
					paymentTermId,
					product.id,
					parseFloat(value as string),
				);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saleOrderItems]);

	const totals = useMemo(() => {
		const totalBonus = calculateTotalBonus(saleOrderItems);
		const totalCommission = calculateTotalCommission(saleOrderItems);
		const totalWithDiscount = calculateTotalOrderWithDiscount(saleOrderItems);
		const totalWithoutDiscount = calculateTotalOrderWithoutDiscount(saleOrderItems);
		const totalDiscount = calculateTotalDiscount(saleOrderItems);
		const total = totalWithDiscount - totalBonus;

		setFieldValue('totalOrder', totalWithDiscount);
		setFieldValue('volumeQuantity', saleOrderItems.reduce((sum, item) => sum + item.quantity, 0));

		return {
			totalBonus,
			totalCommission,
			totalWithDiscount,
			totalWithoutDiscount,
			totalDiscount,
			total,
		};
	}, [saleOrderItems, setFieldValue]);

	return (
		<Box sx={{ paddingY: 4, marginTop: 4 }}>
			<Grid container spacing={3}>
				<Typography variant="h6" gutterBottom>
					Pagamento
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={3}>
						<FormControl fullWidth>
							<Autocomplete<IPaymentTerm>
								options={paymentTerms}
								label="Condições de Pagamento"
								name="paymentTermId"
								labelKey="description"
								valueKey="id"
								valueLabel="code"
								required
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6} lg={3}>
						<Autocomplete<IPaymentMethod>
							options={paymentMethodOptions}
							label="Forma de Pagamento"
							name="paymentMethod"
							labelKey="description"
							valueKey="id"
							required
						/>
					</Grid>

					<Grid item xs={12} md={9} height="100%">
						<Typography variant="h6" gutterBottom>
							Produtos
						</Typography>
						<DataGrid
							autoHeight
							rows={saleOrderItems}
							pagination
							columns={columns}
							pageSize={10}
							rowsPerPageOptions={[10]}
							disableSelectionOnClick
							onCellEditCommit={handleCellEditCommit}
						/>
					</Grid>

					<Grid item xs={12} md={3}>
						<Box>
							<Typography variant="h6" gutterBottom>
								Resumo
							</Typography>

							<Divider sx={{ my: 1 }} />

							<Typography gutterBottom>
								Valor da comissão:
								{' '}
								{currencyBRLMask(totals.totalCommission || 0)}
							</Typography>

							<Divider sx={{ my: 1 }} />

							<Typography gutterBottom>
								Total Tab. Preço:
								{' '}
								{currencyBRLMask(totals.totalWithoutDiscount || 0)}
							</Typography>

							<Typography gutterBottom>
								Descontos:
								{' '}
								{currencyBRLMask(totals.totalDiscount || 0)}
							</Typography>

							<Typography gutterBottom>
								Total de bonificações:
								{' '}
								{currencyBRLMask(totals.totalBonus || 0)}
							</Typography>

							<Divider sx={{ my: 1 }} />

							<Typography fontWeight={500} gutterBottom>
								Total:
								{' '}
								{currencyBRLMask(totals.total || 0)}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

StepPaymentMethod.defaultProps = {
	rangePriceComissionsByValue: undefined,
};

export default StepPaymentMethod;
