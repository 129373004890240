import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import { AxiosError } from 'axios';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { ICompany } from '../Company/CompanyAssets';
import { getCompanies, deleteCompany } from '../../services/company';
import BusinessIntelligencePresentational from '../../components/Dashboards/BusinessIntelligence/BusinessIntelligence';

enum ActionType {
	LOADING,
	COMPANY,
}

interface IState {
	count: number;
	loading: boolean;
	companies: ICompany[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.COMPANY; payload: { companies: ICompany[]; count: number } };

interface IBusinessInteligenceActions {
	setLoading(loading: boolean): void;
	getCompanies(): void;
}

const initialState: IState = {
	count: 0,
	loading: false,
	companies: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.COMPANY:
			return {
				...state,
				companies: action.payload.companies,
				count: action.payload.count,
			};
		default:
			throw new Error();
	}
};

const BusinessInteligenceActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IBusinessInteligenceActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getCompanies() {
			actions.setLoading(true);
			getCompanies()
				.then((response) => {
					dispatch({
						type: ActionType.COMPANY,
						payload: {
							companies: response.data.data,
							count: response.data.count,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu ao obter as empresas. Tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},

		handleDeleteCompany(id: string) {
			actions.setLoading(true);
			deleteCompany(id)
				.then((response) => {
					enqueueSnackbar(response?.data.message, { variant: 'success' });
					actions.getCompanies();
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},
	};

	return actions;
};

const BusinessIntelligenceContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => BusinessInteligenceActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);
	return (
		/* eslint-disable react/jsx-props-no-spreading */
		<BusinessIntelligencePresentational {...state} {...actions} />
	);
};

export default BusinessIntelligenceContainer;
