import React, {
	Dispatch, memo, Reducer, useMemo, useReducer,
} from 'react';
import { format } from 'date-fns';
import ClockHoursPresentational from '../../components/HourProject/ClockHours';
import {
	getUsersWithERPHours, getERPClockHours,
} from '../../services/hourProject';
import {
	UserClock, ClockHoursParams, ERPClockHoursMap, ErpClockInOutsMap,
} from './ClockHoursAssets';
import { getErpClockInOutsMap } from './helper';

enum ActionType {
	LOADING,
	USERS,
	ERP_CLOCK_IN_OUT,
	CLEAR,
}

interface IState {
	loading: boolean;
	users: UserClock[];
	erpClockInOuts: ERPClockHoursMap;
	erpClockInOutsCount: number;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| {
		type: ActionType.USERS; payload: {
			users: UserClock[],
		}
	}
	| {
		type: ActionType.ERP_CLOCK_IN_OUT; payload: ErpClockInOutsMap
	}
	| {
		type: ActionType.CLEAR;
	};

interface IHourProjectInconsistenciesActions {
	setLoading(loading: boolean): void;
	getUsers(queryParams: ClockHoursParams): void;
	getERPClockHours(queryParams: ClockHoursParams): void;
	clearState(): void;
}

const initialState: IState = {
	loading: false,
	users: [],
	erpClockInOuts: new Map(),
	erpClockInOutsCount: 0,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.USERS:
			return {
				...state,
				users: action.payload.users,
			};
		case ActionType.ERP_CLOCK_IN_OUT:
			return {
				...state,
				erpClockInOuts: action.payload.erpClockInOuts,
				erpClockInOutsCount: action.payload.erpClockInOutsCount,
			};
		case ActionType.CLEAR:
			return initialState;
		default:
			throw new Error();
	}
};

const ClockHoursActions = (
	dispatch: Dispatch<TAction>,
): IHourProjectInconsistenciesActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		clearState() {
			dispatch({ type: ActionType.CLEAR });
		},
		getUsers(queryParams: ClockHoursParams) {
			const params = { ...queryParams };
			params.dateTo = format(new Date(params.dateTo), 'yyyy-MM-dd');
			params.dateFrom = format(new Date(params.dateFrom), 'yyyy-MM-dd');
			getUsersWithERPHours(params).then((response) => {
				dispatch({
					type: ActionType.USERS,
					payload: { users: response.data.data },
				});
			});
		},
		getERPClockHours(queryParams: ClockHoursParams) {
			actions.setLoading(true);
			const params = { ...queryParams };
			params.dateTo = format(new Date(params.dateTo), 'yyyy-MM-dd');
			params.dateFrom = format(new Date(params.dateFrom), 'yyyy-MM-dd');
			getERPClockHours(params).then((response) => {
				actions.setLoading(false);

				const { erpClockInOuts, erpClockInOutsCount } = getErpClockInOutsMap(response.data.data);

				dispatch({
					type: ActionType.ERP_CLOCK_IN_OUT,
					payload: { erpClockInOuts, erpClockInOutsCount },
				});
			});
		},
	};

	return actions;
};

const ClockHours = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const actions = useMemo(
		() => ClockHoursActions(dispatch),
		[],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <ClockHoursPresentational {...state} {...actions} />;
};

export default memo(ClockHours);
