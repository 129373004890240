import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import { AxiosError } from 'axios';
import BranchPresentational from '../../components/Branch/Branch';
import { getBranches, deleteBranch } from '../../services/branch';
import { IBranch } from './BranchAssets';

enum ActionType {
	LOADING,
	BRANCH,
}

interface IState {
	count: number;
	loading: boolean;
	branches: IBranch[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.BRANCH; payload: { branches: IBranch[]; count: number } };

interface IBranchActions {
	setLoading(loading: boolean): void;
	getBranches(): void;
	handleDeleteBranch(id: string, companyId: string): void;
}

const initialState: IState = {
	count: 0,
	loading: false,
	branches: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.BRANCH:
			return {
				...state,
				branches: action.payload.branches,
				count: action.payload.count,
			};
		default:
			throw new Error();
	}
};

const BranchActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IBranchActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getBranches() {
			actions.setLoading(true);
			getBranches().then((response) => {
				dispatch({
					type: ActionType.BRANCH,
					payload: {
						branches: response.data.data,
						count: response.data.count,
					},
				});
				actions.setLoading(false);
			});
		},
		handleDeleteBranch(id: string, companyId: string) {
			actions.setLoading(true);
			deleteBranch(id, companyId)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
					actions.getBranches();
				});
		},
	};

	return actions;
};

const BranchContainer = (): JSX.Element | null => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => BranchActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<BranchPresentational {...state} {...actions} />
	);
};

export default BranchContainer;
