import { DeliveryType } from '../enums/DeliveryType';

export const DeliveryTypeLabels = {
	[DeliveryType.CIF]: 'CIF',
	[DeliveryType.FOB]: 'FOB',
	[DeliveryType.THIRD_PARTY_ACCOUNT]: 'Conta de Terceiros',
	[DeliveryType.SENDER_ACCOUNT]: 'Conta do Remetente',
	[DeliveryType.RECIPIENT_ACCOUNT]: 'Conta do Destinatário',
	[DeliveryType.NO_FREIGHT]: 'Sem Frete',
};
