import React, { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import {
	Button, Stack, Tooltip, IconButton,
} from '@mui/material';
import { Add, Delete, Info } from '@mui/icons-material';
import Input from '../../Common/Form/Input';
import SwitchField from '../../Common/Form/Switch';
import { cpfMask, newPhoneMask, phoneMask } from '../../../helpers/masks';
import Autocomplete from '../../Common/Form/Autocomplete';
import { ICompanyWithoutDetails } from '../../../containers/Company/CompanyAssets';
import { IBranch } from '../../../containers/Branch/BranchAssets';
import { BranchParams } from '../../../containers/Inventory/InventoryAssets';
import { branchPreferenceOptions, CompanyBranches } from '../../../containers/User/UserAssets';
import RadioField from '../../Common/Form/Radio';
import { BranchPreference } from '../../../enums/brenchPreferenceType';

export const FormTitle = ({ children }: { children: string }): JSX.Element => (
	<Box>
		<Typography variant="h4" display="block" color="primary" gutterBottom>
			{children}
		</Typography>
		<Typography variant="caption">
			*Obrigatório
		</Typography>
	</Box>
);

export const SectionTitle = ({ children }: { children: string }): JSX.Element => (
	<Grid item xs={12}>
		<Typography variant="h6" display="block" color="primary" gutterBottom>
			{children}
		</Typography>
	</Grid>
);

export const PersonalData = (): JSX.Element => (
	<Box>
		<Grid container spacing={2}>
			<SectionTitle>Dados pessoais</SectionTitle>

			<Grid item xs={4}>
				<Input.InputField
					id="name"
					name="name"
					label="Nome"
					autoComplete="off"
					required
				/>
			</Grid>

			<Grid item xs={4}>
				<Input.InputField
					id="email"
					name="email"
					label="Email"
					autoComplete="off"
					required
				/>
			</Grid>

			<Grid item xs={4}>
				<Input.InputMaskField
					id="phone"
					name="phone"
					label="Telefone"
					autoComplete="off"
					mask={[{ mask: newPhoneMask, maxLength: 10 }, { mask: phoneMask, maxLength: 11 }]}
					required
				/>
			</Grid>
			<Grid item xs={4}>
				<Input.InputMaskField
					id="cpf"
					name="cpf"
					label="CPF"
					mask={cpfMask}
					autoComplete="off"
				/>
			</Grid>
			<Grid item xs={4}>
				<Input.InputField
					id="registry"
					name="registry"
					label="Matrícula"
					autoComplete="off"
				/>
			</Grid>
			<Grid item xs={4}>
				<Input.InputField
					id="daysBackAppointment"
					name="daysBackAppointment"
					label="Dias Retroativos Para Apontamentos de Projeto"
					fullWidth
				/>
			</Grid>
			<Grid item xs={12}>
				<SwitchField
					name="registerHour"
					label="Registra Ponto"
				/>
			</Grid>
			<Grid item xs={12}>
				<SwitchField
					name="canWorkHoliday"
					label="Pode Trabalhar em Feriados"
				/>
			</Grid>
		</Grid>
	</Box>
);

export const LoginConfig = (): JSX.Element => (
	<Box>
		<Grid container spacing={2} alignItems="center">
			<SectionTitle>Configurações de login</SectionTitle>

			<Grid item xs={4}>
				<Input.InputField
					id="login"
					name="login"
					label="Nome de usuário"
					autoComplete="off"
					required
				/>
			</Grid>

			<Grid item xs={4}>
				<Input.PasswordInputField
					id="password"
					name="password"
					label="Senha"
					margin="none"
					autoComplete="new-password"
					required
				/>
			</Grid>

			<Grid item xs={4}>
				<SwitchField
					name="changePasswordLogin"
					label="Alterar senha no primeiro acesso"
				/>
			</Grid>
		</Grid>
	</Box>
);

interface CompanyBranchAccessProps {
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	getBranches: (params: BranchParams) => void;
}

export const CompanyBranchAccess = ({
	companies,
	branches,
	getBranches,
}: CompanyBranchAccessProps): JSX.Element => {
	const formik = useFormikContext<{
		companyBranches: CompanyBranches[];
		preferredBranchId: string;
		preferredCompanyId: string;
		branchPreference: BranchPreference;
	}>();

	useEffect(() => {
		if (!formik.values.companyBranches || formik.values.companyBranches.length === 0) {
			formik.setFieldValue('companyBranches', [{ companyId: '', branchIds: [] }]);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAvailableCompanies = useCallback((currentIndex: number): ICompanyWithoutDetails[] => {
		const selectedCompanyIds = formik.values.companyBranches
			.map((item) => item.companyId)
			.filter((id: string) => id !== '');

		return companies.filter((company) => {
			const companyId = company.id;
			const currentCompanyId = formik.values.companyBranches[currentIndex]?.companyId;
			return companyId === currentCompanyId || !selectedCompanyIds.includes(companyId);
		});
	}, [companies, formik.values.companyBranches]);

	const handleAddCompanyBranch = useCallback((): void => {
		const currentBranches = formik.values.companyBranches || [];

		if (currentBranches.length === 0) {
			formik.setFieldValue('companyBranches', [{
				companyId: '',
				branchIds: [],
			}]);
		} else {
			formik.setFieldValue('companyBranches', [
				...currentBranches,
				{ companyId: '', branchIds: [] },
			]);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.companyBranches]);

	const canAddMore = useCallback((): boolean => {
		if (formik.values.companyBranches.length >= companies.length) {
			return false;
		}

		const selectedCompanyIds = formik.values.companyBranches
			.map((item) => item.companyId)
			.filter((id: string) => id !== '');

		return selectedCompanyIds.length < companies.length;
	}, [companies, formik.values.companyBranches]);

	const handleRemoveCompanyBranch = useCallback((index: number): void => {
		const currentBranches = [...(formik.values.companyBranches || [])];
		currentBranches.splice(index, 1);
		formik.setFieldValue('companyBranches', currentBranches);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.companyBranches]);

	const handleCompanyChange = useCallback((index: number, companyId: string): void => {
		formik.setFieldValue(`companyBranches.${index}.companyId`, companyId || '');
		formik.setFieldValue(`companyBranches.${index}.branchIds`, []);
		if (companyId) {
			getBranches({ companyId });
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getPreferredCompanies = useCallback((): ICompanyWithoutDetails[] => {
		const selectedCompanyIds = formik.values.companyBranches
			.map((item) => item.companyId)
			.filter((id: string) => id !== '');

		return companies.filter((company) => selectedCompanyIds.includes(company.id));
	}, [companies, formik.values.companyBranches]);

	const getFilteredBranches = useCallback(
		(companyId: string, forPreferred = false): IBranch[] => {
			const filteredByCompany = branches.filter(
				(branch) => branch.companyId === companyId,
			);

			if (forPreferred) {
				const companyBranch = formik.values.companyBranches.find(
					(item) => item.companyId === companyId,
				);

				if (!companyBranch?.branchIds?.length) {
					return filteredByCompany;
				}

				return filteredByCompany.filter(
					(branch) => companyBranch.branchIds.includes(branch.id),
				);
			}

			return filteredByCompany;
		},
		[branches, formik.values.companyBranches],
	);

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box display="flex" alignItems="center" gap={1}>
						<Typography variant="h6" color="primary">Acesso a empresas e filiais</Typography>
						{/* <Tooltip title="Para dar acesso a todas as filiais, selecione apenas a empresa.">
							<IconButton size="small">
								<Info color="primary" />
							</IconButton>
						</Tooltip> */}
					</Box>
				</Grid>

				{(formik.values.companyBranches || []).map((item, index: number) => (
				// eslint-disable-next-line react/no-array-index-key
					<Grid item container spacing={2} key={index}>
						<Grid item xs={4}>
							<Autocomplete
								label="Empresas"
								name={`companyBranches.${index}.companyId`}
								valueKey="id"
								valueLabel="code"
								labelKey="name"
								options={getAvailableCompanies(index)}
								onChange={(_event, value) => {
									handleCompanyChange(index, value);
								}}
								required
							/>
						</Grid>
						<Grid item xs={4}>
							<Autocomplete
								label="Filiais"
								name={`companyBranches.${index}.branchIds`}
								valueKey="id"
								valueLabel="code"
								labelKey="name"
								options={getFilteredBranches(item.companyId) || []}
								multiple
								disabled={!item.companyId}
								required
							/>
						</Grid>
						<Grid item>
							<Stack direction="row" spacing={1}>
								{formik.values.companyBranches.length > 1 && (
									<Button
										color="error"
										onClick={() => handleRemoveCompanyBranch(index)}
										size="large"
										variant="contained"
										sx={{ height: '56px', minWidth: '56px', padding: '16px' }}
									>
										<Delete />
									</Button>
								)}

								{index === (formik.values.companyBranches?.length || 0) - 1 && (

									<Button
										color="primary"
										onClick={handleAddCompanyBranch}
										size="large"
										variant="contained"
										sx={{ height: '56px', minWidth: '56px', padding: '16px' }}
										disabled={!canAddMore()}
									>
										<Add />
									</Button>

								)}
							</Stack>
						</Grid>
					</Grid>
				))}

				<Grid mt={2} item xs={12}>
					<RadioField
						name="branchPreference"
						label="Selecione a preferência de login"
						options={branchPreferenceOptions}
						onChange={(event) => {
							const { value } = event.target;
							formik.setFieldValue('branchPreference', value);
							if (value === String(BranchPreference.LAST_USED)) {
								formik.setFieldValue('preferredCompanyId', '');
								formik.setFieldValue('preferredBranchId', '');
							}
						}}
					/>
				</Grid>

				{String(formik.values.branchPreference) === String(BranchPreference.PREFERRED) && (
					<>
						<Grid item xs={4}>
							<Autocomplete
								label="Empresa preferida"
								name="preferredCompanyId"
								valueKey="id"
								valueLabel="code"
								labelKey="name"
								options={getPreferredCompanies()}
								required
								onChange={(_event, value) => {
									formik.setFieldValue('preferredCompanyId', value);

									formik.setFieldValue('preferredBranchId', '');
									if (value) {
										getBranches({ companyId: value });
									}
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<Autocomplete
								label="Filial preferida"
								name="preferredBranchId"
								valueKey="id"
								valueLabel="code"
								labelKey="name"
								options={getFilteredBranches(formik.values.preferredCompanyId, true)}
								required
								disabled={!formik.values.preferredCompanyId}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</Box>
	);
};
