/* eslint-disable import/no-duplicates */
import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
	SnackbarMessage,
	OptionsObject,
	SnackbarKey,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import {
	addUsersToProjectTasks,
	exportHourProjects,
	getProjects,
	getUsersWithTaskHours,
	updateProjectTask,
} from '../../services/hourProject';
import HourProjectDashboardPresentational from '../../components/Dashboards/HourProject/HourProject';
import { HourProject, UpdateTask } from '../HourProject/ApportionmentAssets';
import { AddUsersToProjectTasksParams, HourProjectQueryParams } from '../../interfaces/HourProject';
import { UserClock } from '../HourProject/ClockHoursAssets';
import { UserWithProjectTaskStatus } from '../User/UserAssets';
import { getActiveUsersWithProjectTaskStatus } from '../../services/user';

enum ActionType {
	LOADING,
	PROJECTS,
	USERS_CLOCK,
	USERS,
}

interface IState {
	loading: boolean;
	projects: HourProject[];
	projectsPages: number;
	projectsPage: number;
	projectsTake: number;
	usersClock: UserClock[];
	users: UserWithProjectTaskStatus[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| {
		type: ActionType.PROJECTS; payload: {
			projects: HourProject[],
			projectsPages: number,
			projectsPage: number,
			projectsTake: number,
		}
	}
	| { type: ActionType.USERS_CLOCK; payload: { usersClock: UserClock[] } }
	| { type: ActionType.USERS; payload: { users: UserWithProjectTaskStatus[] } };

interface IDashboardActions {
	setLoading(loading: boolean): void;
	getProjects(params: HourProjectQueryParams): void;
	getUsersWithTaskHours(): void;
	getUsers(): void;
	addUsersToProjectTasks(
		projectId: string,
		data: AddUsersToProjectTasksParams,
	): void;
	updateProjectTask(
		id: string,
		data: UpdateTask,
	): void;
	exportHourProjects(): void;
}

const initialState: IState = {
	loading: false,
	projects: [],
	projectsPages: 0,
	projectsPage: 0,
	projectsTake: 10,
	usersClock: [],
	users: [],
};

let lastQueryParams: HourProjectQueryParams;

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.PROJECTS:
			return {
				...state,
				projects: action.payload.projects,
				projectsPages: action.payload.projectsPages,
				projectsPage: action.payload.projectsPage,
				projectsTake: action.payload.projectsTake,
			};
		case ActionType.USERS_CLOCK:
			return { ...state, usersClock: action.payload.usersClock };
		case ActionType.USERS:
			return { ...state, users: action.payload.users };
		default:
			throw new Error();
	}
};

const DashboardActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IDashboardActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getProjects(queryParams: HourProjectQueryParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 10;
			const skip = queryParams.skip ?? 0;
			const currentPage = Math.floor(skip / take) + 1;
			const params: HourProjectQueryParams = {
				...queryParams,
			};
			if (
				queryParams.startDate
				!== lastQueryParams?.startDate
				&& queryParams.endDate
				!== lastQueryParams?.endDate
			) {
				params.startDate = format(new Date(params.startDate), 'yyyy-MM-dd', { locale: ptBR });
				params.endDate = format(new Date(params.endDate), 'yyyy-MM-dd', { locale: ptBR });

				lastQueryParams = params;
			}
			getProjects(params).then((response) => {
				dispatch({
					type: ActionType.PROJECTS,
					payload: {
						projects: response.data.data,
						projectsPages: Math.ceil(response.data.count / take),
						projectsPage: currentPage,
						projectsTake: take,
					},
				});
			}).catch(() => {
				enqueueSnackbar('Erro ao carregar projetos.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},
		getUsersWithTaskHours() {
			getUsersWithTaskHours().then((response) => {
				dispatch({
					type: ActionType.USERS_CLOCK,
					payload: { usersClock: response.data.data },
				});
			});
		},
		getUsers() {
			getActiveUsersWithProjectTaskStatus()
				.then((response) => {
					dispatch({
						type: ActionType.USERS,
						payload: { users: response.data.data },
					});
				})
				.catch(() => {
					enqueueSnackbar('Erro ao carregar usuários.', {
						variant: 'error',
					});
				});
		},
		addUsersToProjectTasks(
			projectId: string,
			data: AddUsersToProjectTasksParams,
		) {
			actions.setLoading(true);
			addUsersToProjectTasks(projectId, data)
				.then((response) => {
					enqueueSnackbar(response?.data.message, { variant: 'success' });
					actions.getProjects(lastQueryParams);
				})
				.then(() => actions.getUsers())
				.then(() => actions.getUsersWithTaskHours())
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		updateProjectTask(
			id: string,
			data: UpdateTask,
		) {
			actions.setLoading(true);
			updateProjectTask(id, data)
				.then((response) => {
					enqueueSnackbar(response?.data.message, { variant: 'success' });
					actions.getProjects(lastQueryParams);
				})
				.then(() => actions.getUsers())
				.then(() => actions.getUsersWithTaskHours())
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},

		exportHourProjects() {
			actions.setLoading(true);
			exportHourProjects(lastQueryParams).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const formattedDate = new Date().toISOString().replace(/[:.]/g, '-');
				link.setAttribute('download', `Relatório_de_Projeto_${formattedDate}.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.parentNode?.removeChild(link);
				enqueueSnackbar('Relatório exportado com sucesso.', { variant: 'success' });
			}).catch(() => {
				enqueueSnackbar('Erro ao exportar relatório.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},
	};

	return actions;
};

const HourProjectDashboard = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => DashboardActions(dispatch, enqueueSnackbar),
		[enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <HourProjectDashboardPresentational {...state} {...actions} />;
};

export default HourProjectDashboard;
