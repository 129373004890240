import React, {
	useState,
	useCallback,
	useEffect,
} from 'react';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import DraggableColumns from '../components/Common/Datagrid/DraggableColumns';

interface UseManageColumnsOptions {
	initialColumns: GridColDef[];
	initialVisibility: GridColumnVisibilityModel;
	localStorageKey: string;
	isDraggable: boolean;
}

type UseManageColumnsReturnType = {
	currentColumns: GridColDef[],
	columnVisibilityModel: GridColumnVisibilityModel,
	handleColumnsChange: (newColumns: GridColDef[]) => void,
	handleColumnVisibilityModelChange: (newModel: GridColumnVisibilityModel) => void,
	onRestoreClick: () => void,
	isDraggable: boolean,
};

const addDraggableToColumns = (
	columns: GridColDef[],
	handleColumnsChange: (newColumns: GridColDef[]) => void,
	isDraggable: boolean,
): GridColDef[] => {
	if (!isDraggable) {
		return columns;
	}

	return columns.map((column, index) => ({
		...column,
		headerAlign: 'left',
		renderHeader: () => (
			<DraggableColumns
				headerName={column.headerName || ''}
				columns={columns}
				setColumns={handleColumnsChange}
				index={index}
			/>
		),
	}));
};

export const useManageColumns = ({
	initialColumns,
	initialVisibility,
	localStorageKey,
	isDraggable,
}: UseManageColumnsOptions): UseManageColumnsReturnType => {
	const [currentColumns, setCurrentColumns] = useState<GridColDef[]>(initialColumns);
	const [
		columnVisibilityModel,
		setColumnVisibilityModel,
	] = useState<GridColumnVisibilityModel>(initialVisibility);

	const onRestoreClick = useCallback(() => {
		localStorage.removeItem(localStorageKey);
		setCurrentColumns(initialColumns);
		setColumnVisibilityModel(initialVisibility);
	}, [initialColumns, initialVisibility, localStorageKey]);

	const saveColumnsToLocalStorage = useCallback((
		newColumns: GridColDef[],
		newColumnVisibilityModel: GridColumnVisibilityModel,
	): void => {
		const columnFields = newColumns.map((column) => column.field);
		const purchaseOrdersColumns = {
			ordination: columnFields,
			visibilityModel: newColumnVisibilityModel,
		};
		localStorage.setItem(localStorageKey, JSON.stringify(purchaseOrdersColumns));
	}, [localStorageKey]);

	useEffect(() => {
		const savedColumns = localStorage.getItem(localStorageKey);
		if (savedColumns) {
			const { ordination: columnFields, visibilityModel } = JSON.parse(savedColumns);
			const orderedColumns = initialColumns.filter((column) => columnFields.includes(column.field));
			orderedColumns.sort((a, b) => columnFields.indexOf(a.field) - columnFields.indexOf(b.field));
			setCurrentColumns(orderedColumns);
			setColumnVisibilityModel(visibilityModel);
		} else {
			setCurrentColumns(initialColumns);
		}
	}, [initialColumns, localStorageKey]);

	const handleColumnsChange = useCallback((newColumns: GridColDef[]): void => {
		setCurrentColumns(newColumns);
		saveColumnsToLocalStorage(newColumns, columnVisibilityModel);
	}, [saveColumnsToLocalStorage, columnVisibilityModel]);

	const handleColumnVisibilityModelChange = useCallback((
		newModel: GridColumnVisibilityModel,
	): void => {
		setColumnVisibilityModel(newModel);
		saveColumnsToLocalStorage(currentColumns, newModel);
	}, [currentColumns, saveColumnsToLocalStorage]);

	const draggableColumns = addDraggableToColumns(currentColumns, handleColumnsChange, isDraggable);

	return {
		currentColumns: draggableColumns,
		columnVisibilityModel,
		handleColumnsChange,
		handleColumnVisibilityModelChange,
		onRestoreClick,
		isDraggable,
	};
};
