/* eslint-disable react/jsx-props-no-spreading */
import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { IInventoryDifferenceItems, IInventoryInfoReport } from './InventoryAssets';
import { InventoryDifferenceParams } from '../../interfaces/InventoryParams';
import { getInventoryDifferenceById, reportDifferencePDF } from '../../services/inventory';
import InventoryDifferencesReportPresentational from '../../components/Inventory/Reports/InventoryDifferencesReport';

enum ActionType {
	LOADING,
	INVENTORY_DIFFERENCE,
	LOADING_PDF
}

interface IState {
    loading: boolean;
    inventoryDifferenceItems: IInventoryDifferenceItems[];
    inventoryDifferenceCount: number,
	inventoryDifferencePage: number,
	inventoryDifferenceTake: number,
	loadingPdf: boolean,
	inventoryInfo: IInventoryInfoReport;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.LOADING_PDF; payload: { loadingPdf: boolean } }
	| { type: ActionType.INVENTORY_DIFFERENCE;
		payload: {
			inventoryDifferenceItems: IInventoryDifferenceItems[];
			inventoryDifferenceCount: number;
			inventoryDifferencePage: number;
			inventoryDifferenceTake: number;
			countNumber: number;
			inventoryInfo: IInventoryInfoReport;
		} }

interface IInventoryDifferenceActions {
    setLoading(loading: boolean): void;
    setLoadingPdf(loadingPdf: boolean): void;
    getInventoryDifferenceById(id: string, queryParams: InventoryDifferenceParams): void;
	reportDifferencePDF(id: string): void;
}

const initialState: IState = {
	loading: false,
	loadingPdf: false,
	inventoryDifferenceItems: [],
	inventoryDifferenceCount: 0,
	inventoryDifferencePage: 0,
	inventoryDifferenceTake: 10,
	inventoryInfo: {
		date: '',
		document: '',
		responsibleName: '',
	},
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.LOADING_PDF:
			return { ...state, loadingPdf: action.payload.loadingPdf };
		case ActionType.INVENTORY_DIFFERENCE:
			return {
				...state,
				inventoryDifferenceItems: action.payload.inventoryDifferenceItems,
				inventoryDifferenceTake: action.payload.inventoryDifferenceTake,
				inventoryDifferencePage: action.payload.inventoryDifferencePage,
				inventoryDifferenceCount: action.payload.inventoryDifferenceCount,
				countNumber: action.payload.countNumber,
				inventoryInfo: action.payload.inventoryInfo,
			};
		default:
			throw new Error();
	}
};

const IInventoryDifferenceActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IInventoryDifferenceActions => {
	const actions = {
		setLoading(loadingPdf: boolean) {
			dispatch({ type: ActionType.LOADING_PDF, payload: { loadingPdf } });
		},
		setLoadingPdf(loadingPdf: boolean) {
			dispatch({ type: ActionType.LOADING_PDF, payload: { loadingPdf } });
		},
		getInventoryDifferenceById(id: string, queryParams: InventoryDifferenceParams) {
			actions.setLoading(true);

			const params = {
				...queryParams,
			};

			getInventoryDifferenceById(id, params).then((response) => {
				dispatch({
					type: ActionType.INVENTORY_DIFFERENCE,
					payload: {
						inventoryDifferenceItems: response.data.data,
						inventoryDifferenceCount: response.data.count,
						inventoryDifferencePage: queryParams.take
							? Math.floor((queryParams.skip ?? 0) / queryParams.take) + 1
							: 1,
						inventoryDifferenceTake: queryParams.take ?? 10,
						countNumber: response.data.count,
						inventoryInfo: response.data.inventory,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},

		reportDifferencePDF(id: string) {
			actions.setLoadingPdf(true);

			reportDifferencePDF(id).then((response) => {
				const blob = new Blob([response.data], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				window.open(url);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoadingPdf(false));
		},
	};

	return actions;
};

const InventoryDifferenceReport = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();

	const actions = useMemo(
		() => IInventoryDifferenceActions(dispatch, enqueueSnackbar),
		[enqueueSnackbar],
	);

	return (
		<InventoryDifferencesReportPresentational
			{...state}
			{...actions}
		/>
	);
};

export default InventoryDifferenceReport;
