import React, {
	useCallback,
	useState,
	useEffect,
	useContext,
} from 'react';
import {
	styled,
	Theme,
} from '@mui/material/styles';
import {
	SxProps,
} from '@mui/system';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import {
	Autorenew,
	Business,
	Home,
	LocationOn,
	Storage,
	Settings,
} from '@mui/icons-material';
import {
	Box,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Button,
	Divider,
	Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BRANCH_ID_KEY, BRANCH_NAME_KEY, logout } from '../../services/auth';
import { BranchContext } from '../../contexts/BranchContext';

interface StyledAppBarProps {
	theme: Theme;
	open?: boolean;
}

const navbar: SxProps<Theme> = {
	boxShadow: 0,
	backgroundColor: '#fff',
	borderBottom: '1px solid #e1e1e1',
};

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: StyledAppBarProps) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	[theme.breakpoints.down('md')]: {
		width: '100%',
		marginLeft: 0,
	},
}));

const toolbar: SxProps<Theme> = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: (theme) => theme.spacing(1),
};

const drawerWidth = 280;

const Navbar = (): JSX.Element => {
	const [openDrawer, setOpenDrawer] = useState(false);
	const [branchName, setBranchName] = useState('');

	const navigate = useNavigate();
	const currentPath = window.location.pathname;

	const toggleDrawer = useCallback((newOpen: boolean) => () => {
		setOpenDrawer(newOpen);
	}, []);

	const handleMenu = useCallback((): void => {
		setOpenDrawer((prevState) => !prevState);
	}, []);

	const handleLogout = useCallback(() => {
		setOpenDrawer(false);
		logout();
	}, []);

	const { refreshTriggerBranchId } = useContext(BranchContext);

	const branchId = localStorage.getItem(BRANCH_ID_KEY);

	useEffect(() => {
		const branchNameStorage = localStorage.getItem(BRANCH_NAME_KEY);
		setBranchName(branchNameStorage || 'Selecione uma filial');
	}, [refreshTriggerBranchId]);

	return (
		<>
			<AppBar color="primary" sx={navbar}>
				<Toolbar sx={toolbar}>
					<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
						<IconButton
							size="large"
							aria-label="menu"
							onClick={handleMenu}
							sx={{ color: '#666' }}
						>
							<MenuIcon />
						</IconButton>
						<Box component="img" alt="Logo" src="/api/configuration/logo" sx={{ height: 'auto', width: '8rem' }} />

						<Box sx={{ ml: 'auto' }}>
							<Button
								variant="outlined"
								size="small"
								startIcon={<Business sx={{ mr: 0.5 }} />}
								onClick={() => navigate('company-branch-selection')}
								disabled={!branchId || currentPath !== '/app'}
								sx={{
									borderColor: 'rgba(0, 0, 0, 0.12)',
									color: '#444',
									minWidth: 'auto',
									maxWidth: {
										xs: '150px',
										sm: '200px',
										md: '250px',
									},
									px: 1.5,
									py: 1,
									'& .MuiButton-startIcon': {
										mr: 0.5,
										marginY: 'auto',
										display: 'flex',
										alignItems: 'center',
									},
									'& .MuiButton-endIcon': {
										ml: 0.5,
									},
									'& span': {
										display: 'block',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										lineHeight: 1.5,
									},
									display: 'flex',
									alignItems: 'center',
									'&:hover': {
										borderColor: 'rgba(0, 0, 0, 0.24)',
										backgroundColor: 'rgba(0, 0, 0, 0.04)',
									},
								}}
							>
								<Box component="span">{branchName}</Box>
							</Button>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer
				open={openDrawer}
				onClose={toggleDrawer(false)}
				PaperProps={{
					sx: {
						width: drawerWidth,
						backgroundColor: '#f8f9fa',
					},
				}}
			>
				<Box
					sx={{
						width: drawerWidth,
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
					}}
					role="presentation"
				>
					<Box sx={{ mt: '64px', flex: 1 }}>
						<List sx={{ py: 1 }}>
							<ListItem disablePadding>
								<ListItemButton
									onClick={() => navigate('')}
									sx={{
										py: 1.5,
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.04)',
										},
									}}
								>
									<ListItemIcon>
										<Home sx={{ color: '#666' }} />
									</ListItemIcon>
									<ListItemText
										primary="Início"

									/>
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton
									onClick={() => navigate('storage')}
									sx={{
										py: 1.5,
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.04)',
										},
									}}
								>
									<ListItemIcon>
										<Storage sx={{ color: '#666' }} />
									</ListItemIcon>
									<ListItemText
										primary="Armazenagem"

									/>
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton
									onClick={() => navigate('expedition')}
									sx={{
										py: 1.5,
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.04)',
										},
									}}
								>
									<ListItemIcon>
										<LocationOn sx={{ color: '#666' }} />
									</ListItemIcon>
									<ListItemText
										primary="Expedição"

									/>
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton
									onClick={() => navigate('transfer')}
									sx={{
										py: 1.5,
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.04)',
										},
									}}
								>
									<ListItemIcon>
										<Autorenew sx={{ color: '#666' }} />
									</ListItemIcon>
									<ListItemText
										primary="Transferência"

									/>
								</ListItemButton>
							</ListItem>
						</List>
					</Box>

					<Box sx={{ mt: 'auto' }}>
						<Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.08)' }} />
						<List sx={{ py: 1 }}>
							<ListItem disablePadding>
								<ListItemButton
									onClick={() => navigate('change-password')}
									sx={{
										py: 1.5,
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.04)',
										},
									}}
								>
									<ListItemIcon>
										<Settings sx={{ color: '#666' }} />
									</ListItemIcon>
									<ListItemText
										primary="Alterar Senha"
									/>
								</ListItemButton>
							</ListItem>
						</List>
						<Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.08)' }} />
					</Box>

					<Box sx={{ p: 2 }}>
						<Button
							variant="contained"
							onClick={handleLogout}
							fullWidth
						>
							Sair
						</Button>
					</Box>
				</Box>
			</Drawer>
		</>
	);
};

export default Navbar;
