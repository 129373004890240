import { ProfitabilityAnalysisStatusTypeLabels } from '../../constants/profitabilityAnalysisStatusTypeLabels';
import { AverageCostSource } from '../../enums/AliasAverageCostSource';
import { DeliveryType } from '../../enums/DeliveryType';
import { ItemType } from '../../enums/ItemType';
import { ProfitabilityAnalysisStatus } from '../../enums/ProfitabilityAnalysisStatus';
import { SaleOrderSimulationQueryParams } from '../../interfaces/SaleOrderSimulationQueryParams';

export interface ISalePriceFormationAnalysis {
  id?: string;
  nrOrder?: string;
  revision?: string;
  totalDirectCost?: number;
  averageCost?: number;
  aliasAverageCostSource?: AverageCostSource;
  priceListStateSalePriceFormation?: IPriceListStateSalePriceFormationAnalysis;
  totalIndirectCost?: number;
  baseCommission?: number;
  salePrice?: number;
  formula?: string;
  costBaseMonth?: string;
  priceList?: string;
  averageTerm?: number;
  averageTermSimulation?: number;
  averageTermId?: string;
  averageTermSimulationId?: string;
  averagePaymentTermSalePriceFormations?: IAveragePaymentTermSalePriceFormationAnalysis[];
  rangeCommissionSalePriceFormations?: IRangeCommissionSalePriceFormationAnalysis[];
  rangePriceCommissionSalePriceFormations?: IRangePriceCommissionSalePriceFormationAnalysis[];
  indexValue?: string;
  indexValues?: { [key: string]: string };
  discountIndex?: number
  totalSPPVIndex?: number;
  formulas?: IFormula[];
  unitValueAverageTerm?: number;
  unitValueAverageTermSimulation?: number;
  unitValueProfit?: number;
  unitValueProfitSimulation?: number;
  unitValueRangeCommission?: number;
  indexValueRangeCommissionSimulation?: number;
  unitValueRangeCommissionSimulation?: number;
}

export interface IFormula {
  id: string;
  description: string;
}

export interface IRangeCommissionSalePriceFormationAnalysis {
  rangeCommissionId: string,
  salePriceFormationId: string,
  commissionRate: number;
  rangeCommission: {
    range: number;
  };
  sppvIndex?: number;
  sppvUnitValueR?: number;
  unitValueR?: number;
}

export interface IRangePriceCommissionSalePriceFormationAnalysis {
  averagePaymentTerm: string,
  commissionRate: number;
  price: number;
}

export interface IIndirectCostSalePriceFormationAnalysis {
  indirectCostId: string;
  salePriceFormationId: string;
  interestRate: number;
  interestRateSimulation: number;
  indirectCost: {
    code: string;
    description: string;
  };
  unitValueI?: number;
  sppvUnitValueI?: number;
}

export interface IIndirectCostSaleOrderSimulation {
  indirectCostId: string;
  salePriceFormationId: string;
  interestRate: number;
  interestRateSimulation: number;
	indirectCost: {
      code: string;
      description: string;
	}
  unitValueI?: number;
  sppvUnitValueI?: number;
}

export interface IIndirectCostSaleOrderSimulationItem {
  saleOrderSimulationItemId: string;
  indirectCostId: string;
  interestRateSimulation: number;
}

export interface IAveragePaymentTermSalePriceFormationAnalysis {
  averagePaymentTermId: string;
  interestRate: number;
  averagePaymentTerm: {
    code: number;
    description: string;
  };
  withDiscount?: boolean;
}

export interface IPriceListStateSalePriceFormationAnalysis {
  id: string;
  priceListStateId: string;
  salePriceFormationId: string;
  discount: number;
  priceListState: {
    priceList: string;
  };
}

export interface ISaleOrderSimulationItem {
  id: string;
  codeDescription?: string;
  nrItem: number;
  price: number;
  simulationPrice: number;
  quantity: number;
  total: number;
  discount: number;
  discountValue: number;
  totalWithDiscount: number;
  commission: number;
  commissionValue: number;
  type: ItemType;
  indirectCostSimulations?: IIndirectCostSaleOrderSimulation[];
  salePriceFormation?: ISalePriceFormationAnalysis;
  selected?: boolean;
  grossProfit?: number;
  netProfit?: number;
  grossMargin?: number;
  grossProfitSaleOrder?: number;
  grossProfitSimulation?: number;
  netProfitMargin?: number;
  netProfitSaleOrder?: number;
  netProfitSimulation?: number;
  netProfitMarginSaleOrder?: number;
  netProfitMarginSimulation?: number;
  totalIndirectCostSimulation?: number;
  totalUnitValueIndirectCost?: number;
  totalUnitValueIndirectCostSimulation?: number;
  selectedAveragePaymentTermId?: string;
  selectedRangeCommissionId?: string;
  saleOrderSimulationId?: string;
  financialCost?: number;
  financialCostSimulation?: number;
  totalSimulationIndirectFinancialCommissionCost?: number;
  totalIndirectFinancialCommissionCost?: number;
}

export interface ISaleOrderSimulation {
  id: string;
  companyId: string;
  nrOrder: string;
  revision: string;
  emissionDate: Date;
  salesman: string;
  client: string;
  locality: string;
  deliveryType: DeliveryType;
  priceList: string;
  paymentTerm: string;
  averagePaymentTerm: string;
  status: ProfitabilityAnalysisStatus;
  saleForceOrder: string;
  saleOrderSimulationItems: ISaleOrderSimulationItem[];
  branchId?: string;
}

export interface ISaleOrderSimulations {
  data: ISaleOrderSimulation[];
  count: number;
}

export interface SaleOrderSimulationRow {
	id: string;
	nrItem: number | null;
	codeDescription?: string;
	quantity: number | null;
	price: number | null;
	simulationPrice?: number;
	total?: number;
	discount?: number | null;
	discountValue?: number;
	totalWithDiscount?: number;
	commission: number | null;
	commissionValue?: number;
	totalDirectCost?: number;
	grossProfitSaleOrder?: number;
	netProfitSaleOrder?: number;
	grossProfitSimulation?: number;
	netProfitSimulation?: number;
	netProfitMarginSimulation?: number;
	financialCost?: number;
	financialCostSimulation?: number;
	totalUnitValueIndirectCost?: number;
	totalUnitValueIndirectCostSimulation?: number;
	totalSimulationIndirectFinancialCommissionCost?: number;
	totalIndirectFinancialCommissionCost?: number;
}

export const initialSaleOrderSimulationItem: ISaleOrderSimulationItem = {
	id: '',
	codeDescription: '',
	nrItem: 0,
	price: 0,
	simulationPrice: 0,
	quantity: 0,
	total: 0,
	discount: 0,
	discountValue: 0,
	totalWithDiscount: 0,
	commission: 0,
	commissionValue: 0,
	type: ItemType.SALE,
	indirectCostSimulations: [
		{
			indirectCostId: '',
			salePriceFormationId: '',
			interestRate: 0,
			interestRateSimulation: 0,
			unitValueI: 0,
			sppvUnitValueI: 0,
			indirectCost: {
				code: '',
				description: '',
			},
		},
	],
	salePriceFormation: {
		nrOrder: '',
		revision: '',
		totalDirectCost: 0,
		totalIndirectCost: 0,
		baseCommission: 0,
		salePrice: 0,
		formula: '',
		costBaseMonth: '',
		priceList: '',
		averageTerm: 0,
		indexValue: '0',
		discountIndex: 0,
		totalSPPVIndex: 0,
		priceListStateSalePriceFormation: {
			id: '',
			priceListStateId: '',
			salePriceFormationId: '',
			discount: 0,
			priceListState: {
				priceList: '',
			},
		},
		rangeCommissionSalePriceFormations: [
			{
				rangeCommissionId: '',
				salePriceFormationId: '',
				commissionRate: 0,
				rangeCommission: {
					range: 0,
				},
				sppvIndex: 0,
				sppvUnitValueR: 0,
				unitValueR: 0,
			},
		],
		rangePriceCommissionSalePriceFormations: [
			{
				averagePaymentTerm: '',
				commissionRate: 0,
				price: 0,
			},
		],
	},
	grossProfitSaleOrder: 0,
	grossProfitSimulation: 0,
	netProfitSaleOrder: 0,
	netProfitSimulation: 0,
	netProfitMarginSaleOrder: 0,
	netProfitMarginSimulation: 0,
};

export const initialSaleOrderSimulation: ISaleOrderSimulation = {
	id: '',
	companyId: '',
	nrOrder: '',
	revision: '',
	emissionDate: new Date(),
	salesman: '',
	client: '',
	locality: '',
	deliveryType: DeliveryType.CIF,
	priceList: '',
	paymentTerm: '',
	averagePaymentTerm: '',
	status: ProfitabilityAnalysisStatus.PENDING,
	saleForceOrder: '',
	saleOrderSimulationItems: [initialSaleOrderSimulationItem],
	branchId: undefined,
};

export const initialSalePriceFormationAnalysis: ISalePriceFormationAnalysis = {
	nrOrder: '',
	revision: '',
	totalDirectCost: 0,
	totalIndirectCost: 0,
	baseCommission: 0,
	salePrice: 0,
	formula: '',
	costBaseMonth: '',
	priceList: '',
	averageTerm: 0,
	indexValue: '0',
	discountIndex: 0,
	totalSPPVIndex: 0,
	priceListStateSalePriceFormation: {
		id: '',
		priceListStateId: '',
		salePriceFormationId: '',
		discount: 0,
		priceListState: {
			priceList: '',
		},
	},
	rangeCommissionSalePriceFormations: [
		{
			rangeCommissionId: '',
			salePriceFormationId: '',
			commissionRate: 0,
			rangeCommission: {
				range: 0,
			},
			sppvIndex: 0,
			sppvUnitValueR: 0,
			unitValueR: 0,
		},
	],
};

export const initialValuesFilter: SaleOrderSimulationQueryParams = {
	skip: 0,
};

export const statusOptions = Object.entries(
	ProfitabilityAnalysisStatusTypeLabels,
).map(([value, label]) => ({
	id: value,
	value,
	label,
}));
