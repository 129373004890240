import React, { useEffect } from 'react';
import { Link, RouteProps } from 'react-router-dom';
import ProviderClientTable from '../Common/ProviderClient/ProviderClientTable';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';

interface ProviderProps {
	loading: boolean;
	providers: any[];
	getProviders(): void;
	approveProvider(id: string): void;
}

const headerButtonsProps: PageHeaderButtonProps[] = [
	{
		variant: 'contained',
		color: 'primary',
		type: 'submit',
		fullWidth: true,
		component: Link,
		to: 'edit',
		text: 'Criar',
	},
];

const Provider = ({
	loading,
	providers,
	getProviders,
	approveProvider,
}: ProviderProps): JSX.Element => {
	useEffect(() => {
		getProviders();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="content">
			<PageHeader title="Fornecedor" buttons={headerButtonsProps} />
			<ProviderClientTable
				loading={loading}
				rowsPerPage={5}
				page={0}
				rows={providers}
				approve={approveProvider}
			/>
		</div>
	);
};

export default Provider;
