import * as Yup from 'yup';
import '../../helpers/yupMethods';
import { validationMessage } from '../../helpers/yupMessages';

export interface ILicense {
    id?: string;
    identifier: string;
    license: string;
    expireDate?: string;
    accessGroups?: { _count: { users: number } }[]
}

export interface IGetLicenses {
	data: ILicense[];
}

export const initialValues = {
	identifier: '',
	license: '',
	expireDate: '',
};

export interface IModule {
    code: string;
    link: string;
    icon: string;
    name: string;
}

export interface ILicenseDetails {
    prl: number;
    atp: number;
    mod: IModule[];
    nof: number;
    iat: number;
    exp: number;
    inUse: number;
}

export interface IGenerateLicense {
    modules: IModule[];
    numberOfUsers: number;
    profile: Profile;
    aquisitionType: AcquisitionType;
    expireDate: string;
    isAdmin: boolean;
}

export enum Profile {
    FULL = 0,
    BASIC = 1,
    VIEW = 2,
}

export enum AcquisitionType {
    MONTHLY = 0,
    YEARLY = 1,
    FIDELITY = 2,
}

export const validationSchema: Yup.AnySchema = Yup.object().shape({
	identifier: Yup.string().required(validationMessage.required),
	license: Yup.string().required(validationMessage.required),
});
