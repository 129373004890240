import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ChangePasswordForm } from './ChangePasswordForm';

interface LoginChangePasswordProps {
	loading: boolean;
	handleChangePassword: (password: string) => void;
}

const ChangePasswordLogin = ({
	loading, handleChangePassword,
}: LoginChangePasswordProps): JSX.Element => (
	<>
		<Box sx={{ mb: 2, width: '100%' }}>
			<Typography>
				Este é o seu primeiro acesso, altere sua senha para continuar.
			</Typography>
		</Box>

		<ChangePasswordForm loading={loading} handleChangePassword={handleChangePassword} />
	</>
);

export default ChangePasswordLogin;
