import React, { useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Form, Formik } from 'formik';
import { UserWithProjectTaskStatus } from '../../../containers/User/UserAssets';
import Autocomplete from '../../Common/Form/Autocomplete';

interface SelectedProjectInfo {
	projectId: string | null;
	userIds: string[];
}

interface ProjectTaskUsersModalProps {
	open: boolean;
	onClose: () => void;
	users: UserWithProjectTaskStatus[];
	selectedProjectInfo: SelectedProjectInfo;
	handleAddUsersToProject: (userIds: string[]) => void;
}

const ProjectTaskUsersModal = ({
	open,
	onClose,
	users,
	selectedProjectInfo,
	handleAddUsersToProject,
}: ProjectTaskUsersModalProps): JSX.Element => {
	const filteredSelectedUserIds = useCallback(() => {
		if (selectedProjectInfo.projectId) {
			return users
				.filter((user) => selectedProjectInfo.userIds.includes(user.id))
				.filter((user) => user.projectTaskUsers.some(
					(projectTaskUser) => projectTaskUser.hourProjectId
						=== selectedProjectInfo.projectId && projectTaskUser.status,
				))
				.map((user) => user.id);
		}
		return selectedProjectInfo.userIds;
	}, [users, selectedProjectInfo.userIds, selectedProjectInfo.projectId]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>
				Adicionar/Remover Usuários
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<Formik
				initialValues={{ usersId: filteredSelectedUserIds() }}
				onSubmit={(values) => {
					handleAddUsersToProject(values.usersId);
					onClose();
				}}
			>
				<Form>
					<DialogContent dividers>
						<Autocomplete
							multiple
							label="Usuários"
							name="usersId"
							options={users}
							labelKey="name"
							valueKey="id"
							valueLabel="login"
						/>
					</DialogContent>
					<DialogActions>
						<Button type="submit">Enviar</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
};

export default ProjectTaskUsersModal;
