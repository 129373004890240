import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Form, Formik } from 'formik';
import Input from '../../Common/Form/Input';

interface TaskOverflowModalProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (quantity: string, actionJustificative: string) => void;
}

const initialValues = {
	quantity: '',
	actionJustificative: '',
};

const TaskOverflowModal = ({
	open, onClose, onSubmit,
}: TaskOverflowModalProps): JSX.Element => (
	<Dialog open={open} onClose={onClose}>
		<DialogTitle>Criar Tarefa Extra</DialogTitle>
		<Formik
			initialValues={initialValues}
			onSubmit={(values) => {
				onSubmit(values.quantity, values.actionJustificative);
				onClose();
			}}
		>
			<Form>
				<DialogContent sx={{ '& > :not(:last-child)': { mb: 2 } }}>
					<Input.InputField
						id="quantity"
						name="quantity"
						label="Quantidade (Horas)"
						type="number"
						required
						fullWidth
					/>
					<Input.InputField
						name="actionJustificative"
						label="Justificativa"
						placeholder="Justificativa..."
						multiline
						rows={6}
						required
						fullWidth
						maxLength={100}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} sx={{ mr: 2 }}>Cancelar</Button>
					<Button type="submit" variant="contained" sx={{ mr: 2 }}>Criar</Button>
				</DialogActions>
			</Form>
		</Formik>
	</Dialog>
);

export default TaskOverflowModal;
