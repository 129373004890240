import React, { useCallback, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import {
	Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import Autocomplete from '../../Common/Form/Autocomplete';
import { User } from '../../../containers/User/UserAssets';
import { UserQueryParams } from '../../../interfaces/UserQueryParams';

interface CreateInventoryTaskModalProps {
    open: boolean;
    onClose: () => void;
    handleConfirmOperatorsInventoryTask: (operatorIds: string[]) => void;
    loading: boolean;
	getUsers: (queryParams: UserQueryParams) => void;
    operators: User[]
}

const initialValues = {
	operatorIds: [],
};

const CreateInventoryTaskSecondOrPlus = ({
	open,
	onClose,
	loading,
	handleConfirmOperatorsInventoryTask,
	operators,
	getUsers,
}: CreateInventoryTaskModalProps): JSX.Element => {
	const [selectedOperators, setSelectedOperators] = useState<User[]>([]);

	const formik = useFormik({
		initialValues,
		onSubmit: () => {
			handleConfirmOperatorsInventoryTask(formik.values.operatorIds);
		},
	});

	const handleChange = useCallback((_event, newValue, reason) => {
		if (reason === 'selectOption' && newValue !== null) {
			const operatorToAdd = operators.find((
				operator,
			) => newValue[newValue.length - 1].includes(operator.id));
			if (operatorToAdd) {
				setSelectedOperators((prevState) => [...prevState, operatorToAdd]);
			}
			formik.setFieldValue('operatorIds', newValue);
		} else if (reason === 'input') {
			getUsers({ name: newValue, skip: 0 });
		} else if (reason === 'removeOption') {
			formik.setFieldValue('operatorIds', newValue);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getUsers, operators, selectedOperators]);

	return (
		<FormikContext.Provider value={formik}>
			<Dialog open={open} fullWidth>
				<Form>
					<DialogTitle>Operadores do Inventário</DialogTitle>
					<DialogContent>
						<Autocomplete
							label="Operadores"
							options={operators.concat(selectedOperators)}
							name="operatorIds"
							valueKey="id"
							valueLabel="name"
							labelKey="login"
							loading={loading}
							sx={{ my: 2 }}
							multiple
							onInputChange={(event, newValue, reason) => handleChange(event, newValue, reason)}
							onChange={(event, newValue, reason) => handleChange(event, newValue, reason)}
						/>
					</DialogContent>
					<DialogActions sx={{ gap: 1 }}>
						<Button onClick={onClose}>Cancelar</Button>
						<Button
							type="submit"
							variant="contained"
							disabled={selectedOperators.length === 0}
						>
							Confirmar
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		</FormikContext.Provider>
	);
};

export default CreateInventoryTaskSecondOrPlus;
