import { AxiosResponse } from 'axios';
import api from './api';
import {
	EditCompany,
	ICompany,
	ICompanyResponse,
	ICompanyWithoutDetailsResponse,
} from '../containers/Company/CompanyAssets';

export const getCompanies = (): Promise<AxiosResponse<ICompanyResponse>> => api.get<ICompanyResponse>('/company');

export const getCompaniesWithoutDetails = (): Promise<AxiosResponse<ICompanyWithoutDetailsResponse>> => api.get<ICompanyWithoutDetailsResponse>('/company/withoutDetails');

export const getCompanyById = (id: string): Promise<AxiosResponse<ICompany>> => api.get<ICompany>(`company/${id}`);

export const saveCompany = (data: EditCompany): Promise<AxiosResponse<ICompany & { message: string }>> => api.post<EditCompany, AxiosResponse<ICompany & { message: string }>>('/company', data);

export const updateCompany = (id: string, data: EditCompany): Promise<AxiosResponse> => api.patch<EditCompany, AxiosResponse<ICompany>>(`/company/${id}`, data);

export const deleteCompany = (id: string): Promise<AxiosResponse<{ message: string }>> => api.delete(`/company/${id}`);
