import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import LicenseEdit from '../../components/License/LicenseEdit';
import { ILicense } from './LicenseAssets';
import {
	getLicenseById, updateLicense, createLicense,
} from '../../services/license';

enum ActionType {
	LOADING,
	LICENSE,
}

interface IState {
	loading: boolean;
	license?: ILicense;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.LICENSE; payload: { license: ILicense } };

interface ILicenseActions {
	setLoading(loading: boolean): void;
	setLicense(license: ILicense): void;
	handleEditLicense(values: {
		id?: string;
		data: ILicense;
	}): void;
	getLicenseById(id: string): void;
}

const initialState: IState = {
	loading: false,
	license: undefined,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.LICENSE:
			return { ...state, license: action.payload.license };
		default:
			throw new Error();
	}
};

const LicenseActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): ILicenseActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setLicense(license: ILicense) {
			dispatch({ type: ActionType.LICENSE, payload: { license } });
		},
		handleEditLicense(license: { id?: string, data: ILicense }) {
			actions.setLoading(true);

			if (license.id) {
				updateLicense(license.id, license.data).then((response) => {
					navigate('/license');
					enqueueSnackbar(response?.data.message, { variant: 'success' });
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
			} else {
				createLicense(license.data).then((response) => {
					navigate('/license');
					enqueueSnackbar(response?.data.message, { variant: 'success' });
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
			}
		},
		getLicenseById(id: string) {
			actions.setLoading(true);
			getLicenseById(id).then((response) => {
				if (!response.data) {
					enqueueSnackbar('Licença não encontrada.', {
						variant: 'error',
					});
					navigate('/license');
				}
				actions.setLicense(response.data);
				actions.setLoading(false);
			}).catch(() => {
				actions.setLoading(false);
			});
		},
	};

	return actions;
};

const LicenseEditContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => LicenseActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <LicenseEdit {...state} {...actions} />;
};

export default LicenseEditContainer;
