import { AxiosRequestConfig, AxiosResponse } from 'axios';
import api from './api';
import { PurchaseOrderQueryParams } from '../interfaces/PurchaseOrderQueryParams';

export const getPurchases = (
	params: PurchaseOrderQueryParams,
): Promise<AxiosResponse<any>> => api.get<any>('/order/purchaseOrder', {
	params,
});

export const getPurchase = (
	id: string,
): Promise<AxiosResponse<any>> => api.get<any>(`/order/purchaseOrder/${id}`);

export const getBranches = (): Promise<AxiosResponse<any>> => api.get<any>('/branch');
// class PurchaseOrderItemApportionment {
//     budgetId: string;
//     percentage: number;
// }

// export class CreatePurchaseOrderItemApportionmentsDto {
//     purchaseOrderItemId: string;
//     apportionments: PurchaseOrderItemApportionment[];
// }

export const saveApportionments = (
	data: any,
): Promise<AxiosResponse<any>> => api.post<any>('/order/purchaseOrderApportionments', data);

export const savePurchaseOrderApproval = (
	data: any,
): Promise<AxiosResponse<any>> => api.post<any>('/order/purchaseOrderApproval', data);

export const onNFDownloadClick = (
	id: string,
	options?: AxiosRequestConfig,
): Promise<AxiosResponse<any>> => api.get<any>(`/order/purchaseOrderInvoiceDownload/${id}`, options);

export const exportPurchases = (
	params: PurchaseOrderQueryParams,
): Promise<AxiosResponse<any>> => api.get<any>('/order/exportPurchaseOrder', {
	params,
	responseType: 'blob',
});

export const exportPurchasesApportionments = (
	params: PurchaseOrderQueryParams,
): Promise<AxiosResponse<any>> => api.get<any>('/order/exportPurchaseOrderWithApportionments', {
	params,
	responseType: 'blob',
});
