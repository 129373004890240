import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '../Common/Form/Input';
import {
	ICompany,
	EditCompany,
	initialValues,
	validationSchema,
} from '../../containers/Company/CompanyAssets';

interface CompanyFormProps {
	company?: ICompany;
	onSubmit: (values: EditCompany) => void;
	loading: boolean;
}

const CompanyForm: React.FC<CompanyFormProps> = ({
	company,
	onSubmit,
	loading,
}) => {
	const initialValuesLoad = useMemo(() => (company ? {
		name: company.name,
		code: company.code,
		urlBI: company.urlBI,
	} : initialValues), [company]);

	return (
		<Formik
			initialValues={initialValuesLoad}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			validateOnChange={false}
			validateOnBlur={false}
		>
			<Form noValidate>
				<Paper sx={{ mt: 3, p: 3 }} elevation={3}>
					<Stack spacing="4rem">
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<Input.InputField
									id="code"
									name="code"
									label="Código"
									required
									fullWidth
								/>
							</Grid>

							<Grid item xs={8}>
								<Input.InputField
									id="name"
									name="name"
									label="Nome"
									required
									fullWidth
								/>
							</Grid>

							<Grid item xs={12}>
								<Input.InputField
									id="urlBI"
									name="urlBI"
									label="URL BI"
									fullWidth
								/>
							</Grid>
						</Grid>
						<Box>
							<LoadingButton
								loading={loading}
								variant="contained"
								color="primary"
								type="submit"
							>
								Salvar
							</LoadingButton>
						</Box>
					</Stack>
				</Paper>
			</Form>
		</Formik>
	);
};

CompanyForm.defaultProps = {
	company: undefined,
};

export default CompanyForm;
