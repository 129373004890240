import React, {
	Dispatch, memo, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { getBudgetResponsible, getFilterFieldsValues, updateBudgetResponsible } from '../../services/budget';
import BudgetResponsiblesAccountsPresentational from '../../components/Budget/Responsible/BudgetResponsible';
import { getUserById } from '../../services/user';
import { User } from '../User/UserAssets';
import {
	IFilter, IBudgetsToShow, IUpdateBudgetsData,
} from '../../interfaces/BudgetResponsible';
import { IFilterFieldsValues } from '../../interfaces/BudgetOrderFourFields';

enum ActionType {
	LOADING,
	RESPONSIBLES,
	FILTER_FIELDS_VALUES,
	USER,
}

interface IState {
	loading: boolean;
	budgetResponsibles: IBudgetsToShow[];
	filterFieldsValues: IFilterFieldsValues | null;
	user?: User;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.RESPONSIBLES; payload: { budgetResponsibles: IBudgetsToShow[] } }
	| { type: ActionType.FILTER_FIELDS_VALUES; payload: { filterFieldsValues: IFilterFieldsValues } }
	| { type: ActionType.USER; payload: { user: User } };

interface IBudgetResponsibleActions {
	setLoading(loading: boolean): void;
    getBudgetResponsible(userId: string, filterData?: IFilter): void;
    getFilterFieldsValues(): void;
    setUser(user: any): void;
    getUserById(id: string): void;
    updateBudgets(userId: string, data: IUpdateBudgetsData): void;
}

const initialState: IState = {
	loading: false,
	budgetResponsibles: [],
	filterFieldsValues: null,
	user: undefined,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.RESPONSIBLES:
			return { ...state, budgetResponsibles: action.payload.budgetResponsibles };
		case ActionType.USER:
			return { ...state, user: action.payload.user };
		case ActionType.FILTER_FIELDS_VALUES:
			return { ...state, filterFieldsValues: action.payload.filterFieldsValues };
		default:
			throw new Error();
	}
};

const BudgetResponsibleActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IBudgetResponsibleActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getBudgetResponsible(userId: string, filterData?: IFilter) {
			actions.setLoading(true);

			getBudgetResponsible(userId, filterData).then((response: AxiosResponse) => {
				actions.setLoading(false);

				const budgetResponsibles = response.data.data.map((budget: any) => {
					const isOwner = budget.budgetResponsibles.length > 0;
					const approvalPercent = budget.budgetResponsibles[0]?.approvalPercent ?? '0';

					return {
						...budget,
						isOwner,
						approvalPercent,
						accountingAccount: budget.accountingAccount.name,
						costCenter: budget.costCenter.name,
						accountingItem: budget.accountingItem.name,
						classValue: budget.classValue.name,
					};
				});

				dispatch({
					type: ActionType.RESPONSIBLES,
					payload: { budgetResponsibles },
				});
			});
		},
		getFilterFieldsValues() {
			getFilterFieldsValues(undefined, true).then((response: AxiosResponse) => {
				dispatch({
					type: ActionType.FILTER_FIELDS_VALUES,
					payload: { filterFieldsValues: response.data },
				});
			});
		},
		setUser(user: User) {
			dispatch({ type: ActionType.USER, payload: { user } });
		},
		getUserById(id: string) {
			actions.setLoading(true);
			getUserById(id).then((response: AxiosResponse) => {
				if (!response.data) {
					enqueueSnackbar('Usuário não encontrado.', {
						variant: 'error',
					});
					navigate('/budget/users');
				}
				actions.setUser(response.data);
				actions.setLoading(false);
			}).catch(() => {
				actions.setLoading(false);
			});
		},
		updateBudgets(userId: string, data: IUpdateBudgetsData) {
			updateBudgetResponsible(data)
				.then(() => {
					enqueueSnackbar('Orçamentos alterados com sucesso', {
						variant: 'success',
					});
				})
				.catch(() => {
					enqueueSnackbar('Orçamentos alterados com sucesso', {
						variant: 'success',
					});
				})
				.finally(() => {
					actions.getBudgetResponsible(userId);
				});
		},
	};

	return actions;
};

const BudgetResponsible = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => BudgetResponsibleActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <BudgetResponsiblesAccountsPresentational {...state} {...actions} />;
};

export default memo(BudgetResponsible);
