import { AxiosResponse } from 'axios';
import localizationApi from './localizationApi';
import { IMessageResponse } from '../interfaces/MessageResponse';

export interface ICity {
  id: string;
  name: string;
  stateId?: string;
}

export interface ICities {
  data: ICity[];
  count: number;
}

export const getCities = (
	searchQuery?: string,
): Promise<AxiosResponse<ICities>> => {
	const queryParam = searchQuery ? `?search=${searchQuery}` : '';
	return localizationApi.get<ICities>(`/city${queryParam}`);
};

export const getCityById = (id: string): Promise<AxiosResponse<ICity>> => localizationApi.get<ICity>(`/city/${id}`);

export const saveCity = (
	data: ICity,
): Promise<AxiosResponse<IMessageResponse>> => localizationApi.post<ICity, AxiosResponse<IMessageResponse>>('/city', data);

export const updateCity = (
	id: string,
	data: ICity,
): Promise<AxiosResponse<IMessageResponse>> => localizationApi.patch<
ICity,
AxiosResponse<IMessageResponse>
>(
	`/city/${id}`,
	data,
);

export const deleteCity = (
	id: string,
): Promise<AxiosResponse<IMessageResponse>> => localizationApi.delete<IMessageResponse>(`/city/${id}`);
