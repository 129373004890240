import React, { useCallback, useMemo } from 'react';
import { GridRowId } from '@mui/x-data-grid';
import { Form, Formik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { IBudgetsToShow } from '../../../interfaces/BudgetResponsible';
import GenericDrawer from '../../Common/GenericDrawer';
import Input from '../../Common/Form/Input';

interface IBudgetResponsibleEditSelectionDrawerProps {
  open: boolean;
  selectedRows: GridRowId[];
  budgets: IBudgetsToShow[];
  handleClose: () => void;
  handleMultipleRowsChange: (budgetIds: GridRowId[], approvalPercent: string) => void;
}

export const BudgetResponsibleEditSelectionDrawer = ({
	open,
	selectedRows,
	budgets,
	handleClose,
	handleMultipleRowsChange,
}: IBudgetResponsibleEditSelectionDrawerProps): JSX.Element => {
	const initialValues = useMemo(() => ({
		approvalPercent: '',
	}), []);

	const validate = useCallback((values: any) => {
		const errors: any = {};

		if (values.approvalPercent === '' || !Number.parseInt(values.approvalPercent, 10)) {
			errors.approvalPercent = 'Insira um número válido';
		} else if (
			parseInt(values.approvalPercent, 10) < 0 || parseInt(values.approvalPercent, 10) > 100
		) {
			errors.approvalPercent = 'Insira um número entre 0 e 100';
		}

		return errors;
	}, []);

	const handleSubmit = useCallback((values) => {
		handleMultipleRowsChange(selectedRows, values.approvalPercent);
		handleClose();
	}, [handleClose, handleMultipleRowsChange, selectedRows]);

	const contentMemo = useMemo(() => (
		<Formik
			initialValues={initialValues}
			validate={validate}
			onSubmit={handleSubmit}
		>
			{({ isValid }) => (
				<Box component={Form} sx={{ height: '100%' }}>
					<Stack spacing={2} sx={{ height: '100%' }}>
						{ selectedRows.length === budgets.length && (
							<Typography variant="caption">
								Apesar de ter selecionado todos os orçamentos,
								a alteração será feita somente aos orçamentos que o usuário é responsável
							</Typography>
						)}
						<Input.InputField
							name="approvalPercent"
							InputProps={{
								endAdornment: <InputAdornment position="end">%</InputAdornment>,
							}}
							// eslint-disable-next-line react/jsx-no-duplicate-props
							inputProps={{
								inputMode: 'numeric',
							}}
						/>
						<Stack direction="row" spacing={1} sx={{ mt: 'auto!important' }}>
							<Button fullWidth onClick={handleClose} variant="outlined">Cancelar</Button>
							<Button fullWidth type="submit" variant="contained" disabled={!isValid}>Salvar</Button>
						</Stack>
					</Stack>
				</Box>
			)}
		</Formik>
	), [budgets.length, handleClose, handleSubmit, initialValues, selectedRows.length, validate]);

	return (
		<GenericDrawer
			open={open}
			onClose={handleClose}
			title="Editar seleção"
			width={300}
			content={contentMemo}
		/>
	);
};
