import React, { useEffect, useState } from 'react';
import { ptBR } from '@mui/x-data-grid';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptLocale from 'date-fns/locale/pt-BR';
import Routes from './Routes';
import './App.scss';
import { getConfiguration, getName } from './services/app';
import {
	BACKGROUND_COLOR,
	DEFAULT_NAME,
	ERROR_COLOR,
	INFO_COLOR,
	PRIMARY_COLOR,
	SECONDARY_COLOR,
	SUCCESS_COLOR,
	TEXT_COLOR,
	WARNING_COLOR,
} from './constants/general';
import { BranchProvider } from './contexts/BranchContext';

const theme = createTheme({
	palette: {
		background: {
			default: BACKGROUND_COLOR,
		},
		primary: {
			main: PRIMARY_COLOR,
		},
		secondary: {
			main: SECONDARY_COLOR,
		},
		success: {
			main: SUCCESS_COLOR,
		},
		info: {
			main: INFO_COLOR,
		},
		error: {
			main: ERROR_COLOR,
		},
		warning: {
			main: WARNING_COLOR,
		},
		text: {
			primary: TEXT_COLOR,
		},
	},
}, ptBR);

const App = (): JSX.Element => {
	const [name, setName] = useState(getName());

	useEffect(() => {
		getConfiguration().then((response) => {
			const newName = response.data.name || DEFAULT_NAME;

			localStorage.setItem('name', newName);
			setName(newName);
		});
	}, []);

	return (
		<BranchProvider>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptLocale}>
				<ThemeProvider theme={theme}>
					<StyledEngineProvider injectFirst>
						<SnackbarProvider maxSnack={3}>
							<BrowserRouter>
								<Routes name={name} />
							</BrowserRouter>
						</SnackbarProvider>
					</StyledEngineProvider>
				</ThemeProvider>
			</LocalizationProvider>
		</BranchProvider>
	);
};

export default App;
