import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Input from '../../Common/Form/Input';
import Checkbox from '../../Common/Form/Checkbox';
import { BudgetApproverQueryParams } from '../../../interfaces/BudgetApproverQueryParams';
import { filterObject } from '../../../helpers/Utils';

interface BudgetUserFilterProps {
	sendFilter: (queryParams: BudgetApproverQueryParams) => void;
}

const initialValues: BudgetApproverQueryParams & {
	corporativeLevel: boolean,
	controllership: boolean,
} = {
	name: '',
	type: [],
	corporativeLevel: false,
	controllership: false,
};

const BudgetUsersFilter = ({
	sendFilter,
}: BudgetUserFilterProps): JSX.Element => {
	const handleSubmit = useCallback(
		(values: BudgetApproverQueryParams & {
			corporativeLevel: boolean,
			controllership: boolean,
		}): void => {
			const { corporativeLevel, controllership, ...filterValues } = values;
			filterValues.type = [];

			if (corporativeLevel) { filterValues.type.push(0); }
			if (controllership) { filterValues.type.push(1); }

			sendFilter(filterObject(filterValues));
		},
		[sendFilter],
	);

	const onReset = useCallback((handleReset) => {
		sendFilter({});
		handleReset();
	}, [sendFilter]);

	return (
		<Paper sx={{ p: 2, width: '100%', mb: 4 }}>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
			>
				{({ values, handleReset }) => (
					<Form>
						<Grid container spacing={2} alignItems="flex-end">
							<Grid item xs={6}>
								<Input.InputField
									id="name"
									name="name"
									label="Buscar..."
									autoComplete="off"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<Checkbox.CheckboxField
									name="type"
									options={[
										{
											id: 'corporativeLevel',
											label: 'Nível Corporativo',
											checked: values.corporativeLevel,
										},
										{
											id: 'controllership',
											label: 'Controladoria',
											checked: values.controllership,
										},
									]}
									orientation="row"
								/>
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
								<Button variant="outlined" onClick={() => onReset(handleReset)}>
									Restaurar
								</Button>
								<Button variant="contained" type="submit">
									Filtrar
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};

export default BudgetUsersFilter;
