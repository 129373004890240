import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import AccessGroupForm from './AccessGroupForm';
import { Loading } from '../Common/Loading';
import { IAccessGroup, EditAccessGroup } from '../../containers/AccessGroup/AccessGroupAssets';
import { IAccessGroupActions, IState } from '../../containers/AccessGroup/AccessGroupEdit';

interface AccessGroupEditProps extends Omit<IState, 'branch'>, IAccessGroupActions {
	loading: boolean;
    accessGroup?: IAccessGroup;
}

const AccessGroupEdit = ({
	loading,
	handleEditAccessGroup,
	getAccessGroupById,
	accessGroup,
	users,
	licenses,
	getUsers,
	getLicenses,
}: AccessGroupEditProps): JSX.Element => {
	const { id } = useParams();

	useEffect(() => {
		getUsers();
		getLicenses();
	}, [getLicenses, getUsers]);

	useEffect(() => {
		if (id) {
			getAccessGroupById(id);
		}
	}, [id, getAccessGroupById]);

	const handleSubmit = useCallback((values: EditAccessGroup) => {
		handleEditAccessGroup({ id: accessGroup?.id, data: values });
	}, [handleEditAccessGroup, accessGroup]);

	if (id && !accessGroup) {
		return <Loading />;
	}

	return (
		<>
			<Typography variant="h4" color="primary" gutterBottom>
				{`${accessGroup?.id ? 'Editar' : 'Novo'} Grupo de Acesso`}
			</Typography>
			<AccessGroupForm
				accessGroup={accessGroup}
				users={users}
				licenses={licenses}
				onSubmit={handleSubmit}
				loading={loading}
			/>
		</>
	);
};

AccessGroupEdit.defaultProps = {
	accessGroup: undefined,
};

export default AccessGroupEdit;
