import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
	DataGrid,
	GridActionsCellItem,
	GridColDef,
	GridRenderCellParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import { IAccessGroup } from '../../containers/AccessGroup/AccessGroupAssets';
import AccessGroupFilter from './AccessGroupFilter';

interface AccessGroupProps {
	loading: boolean;
	accessGroups: IAccessGroup[];
	getAccessGroups: () => void;
	deleteAccessGroup: (id: string) => void;
}

const headerButtonsProps: PageHeaderButtonProps[] = [
	{
		variant: 'contained',
		color: 'primary',
		type: 'submit',
		fullWidth: true,
		component: Link,
		to: 'edit',
		text: 'Novo',
	},
];

const AccessGroup: React.FC<AccessGroupProps> = ({
	loading,
	accessGroups,
	getAccessGroups,
	deleteAccessGroup,
}) => {
	const [filter, setFilter] = useState({});

	useEffect(() => {
		getAccessGroups();
	}, [getAccessGroups]);

	const columns = useMemo<GridColDef[]>(() => [
		{ field: 'name', headerName: 'Nome do Grupo', flex: 2 },
		{
			field: 'actions',
			headerName: 'Ações',
			width: 120,
			renderCell: (params: GridRenderCellParams) => {
				const handleDelete = (): void => deleteAccessGroup(params.id.toString());

				return (
					<>
						<Link to={`edit/${params.id}`}>
							<GridActionsCellItem
								icon={<EditIcon />}
								label="Editar"
								color="primary"
							/>
						</Link>
						<GridActionsCellItem
							icon={<DeleteIcon />}
							label="Excluir"
							color="error"
							onClick={handleDelete}
						/>
					</>
				);
			},
		},
	], [deleteAccessGroup]);

	const sendFilter = useCallback((values: {name: string}) => {
		const pageFilter = {
			...values,
			skip: 0,
		};

		getAccessGroups();
		setFilter(pageFilter);
	}, [getAccessGroups]);

	const pageHeaderMemo = useMemo(() => <PageHeader title="Grupo de Acesso" buttons={headerButtonsProps} />, []);

	const filterMemo = useMemo(() => (
		<AccessGroupFilter
			sendFilter={sendFilter}
		/>
	), [sendFilter]);

	return (
		<Box className="content">
			{pageHeaderMemo}
			{filterMemo}
			<Box component={Paper} sx={{ width: '100%' }}>
				{
					accessGroups && (
						<DataGrid
							autoHeight
							rows={accessGroups}
							columns={columns}
							pageSize={10}
							rowsPerPageOptions={[10]}
							loading={loading}
							disableSelectionOnClick
						/>
					)
				}
			</Box>
		</Box>
	);
};

export default AccessGroup;
