import { AxiosResponse } from 'axios';
import localizationApi from './localizationApi';
import { IMessageResponse } from '../interfaces/MessageResponse';

export interface ICountry {
  id: string;
  name: string;
}

export interface ICountries {
  data: ICountry[];
  count: number;
}

export const getCountries = (searchQuery?: string): Promise<AxiosResponse<ICountries>> => {
	const queryParam = searchQuery ? `?search=${searchQuery}` : '';
	return localizationApi.get<ICountries>(`/country${queryParam}`);
};

export const getCountryById = (id: string): Promise<AxiosResponse<ICountry>> => localizationApi.get<ICountry>(`/country/${id}`);

export const saveCountry = (data: ICountry): Promise<AxiosResponse<IMessageResponse>> => localizationApi.post<ICountry, AxiosResponse<IMessageResponse>>('/country', data);

export const updateCountry = (id: string, data: ICountry): Promise<AxiosResponse<IMessageResponse>> => localizationApi.patch<ICountry, AxiosResponse<IMessageResponse>>(`/country/${id}`, data);

export const deleteCountry = (id: string): Promise<AxiosResponse<IMessageResponse>> => localizationApi.delete<IMessageResponse>(`/country/${id}`);
