import * as Yup from 'yup';
import { cnpjValidation, cpfValidation } from './Utils';

declare module 'yup' {
  interface StringSchema {
    cnpj(message?: string): StringSchema;
    cpf(message?: string): StringSchema;
  }
}

Yup.string.prototype.cnpj = function cnpjValidationMethod(message) {
	return this.test('cnpj', message || 'CNPJ inválido', (inputValue) => {
		if (!inputValue) {
			return false;
		}

		const cleanedValue = inputValue.replaceAll(/[./-]/g, '');

		if (cleanedValue.length !== 14) {
			return false;
		}

		return cnpjValidation(cleanedValue);
	});
};

Yup.string.prototype.cpf = function cpfValidationMethod(message) {
	return this.test('cpf', message || 'CPF inválido', (inputValue) => {
		if (!inputValue) {
			return true;
		}

		const cleanedValue = inputValue.replaceAll(/[.-]/g, '');

		if (cleanedValue.length !== 11) {
			return false;
		}

		return cpfValidation(cleanedValue);
	});
};

export default Yup;
