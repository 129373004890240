/* eslint-disable import/no-duplicates */
import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ProfitabilityAnalysisPresentational from '../../components/ProfitabilityAnalysis/ProfitabilityAnalysis';
import { ISaleOrderSimulation } from './ProfitabilityAnalysisAssets';
import { SaleOrderSimulationQueryParams } from '../../interfaces/SaleOrderSimulationQueryParams';
import { approveOrRejectSaleOrderSimulation, deleteSaleOrderSimulation, getSaleOrderSimulations } from '../../services/profitabilityAnalysis';
import { ProfitabilityAnalysisStatus } from '../../enums/ProfitabilityAnalysisStatus';

enum ActionType {
	LOADING,
	SALE_ORDER_SIMULATIONS,
}

interface IState {
	loading: boolean;
	saleOrderSimulations: ISaleOrderSimulation[];
	saleOrderSimulationsPages: number;
	saleOrderSimulationsPage: number;
	saleOrderSimulationsTake: number;
}

const initialState: IState = {
	loading: false,
	saleOrderSimulations: [],
	saleOrderSimulationsPages: 0,
	saleOrderSimulationsPage: 0,
	saleOrderSimulationsTake: 10,
};

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| {
		type: ActionType.SALE_ORDER_SIMULATIONS; payload: {
			saleOrderSimulations: ISaleOrderSimulation[];
			saleOrderSimulationsPages: number;
			saleOrderSimulationsPage: number;
			saleOrderSimulationsTake: number;
			simulationCount: number;
		}
	}

interface IProfitabilityAnalysisActions {
	setLoading(loading: boolean): void;
	getSaleOrderSimulations(queryParams: SaleOrderSimulationQueryParams): void;
	handleApproveReject(
		id: string,
		status: ProfitabilityAnalysisStatus,
		actionJustificative?: string,
	): void;
	handleDeleteSaleOrderSimulation(id: string): void;
	handleEdit(id: string): void;
}

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.SALE_ORDER_SIMULATIONS:
			return {
				...state,
				saleOrderSimulations: action.payload.saleOrderSimulations,
				saleOrderSimulationsPages: action.payload.saleOrderSimulationsPages,
				saleOrderSimulationsPage: action.payload.saleOrderSimulationsPage,
				saleOrderSimulationsTake: action.payload.saleOrderSimulationsTake,
				simulationCount: action.payload.simulationCount,
			};
		default:
			throw new Error();
	}
};

const ProfitabilityAnalysisActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IProfitabilityAnalysisActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getSaleOrderSimulations(queryParams: SaleOrderSimulationQueryParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 10;
			const params = { ...queryParams, skip: queryParams.skip * take };

			if (params.dateFrom) {
				params.dateFrom = format(new Date(params.dateFrom), 'yyyy-MM-dd', { locale: ptBR });
			}
			if (params.dateTo) {
				params.dateTo = format(new Date(params.dateTo), 'yyyy-MM-dd', { locale: ptBR });
			}

			getSaleOrderSimulations(params)
				.then((response) => {
					dispatch({
						type: ActionType.SALE_ORDER_SIMULATIONS,
						payload: {
							saleOrderSimulations: response.data.data,
							saleOrderSimulationsPages: response.data.count,
							saleOrderSimulationsPage: queryParams.skip,
							saleOrderSimulationsTake: take,
							simulationCount: response.data.count,
						},
					});
				})
				.catch(() => {
					enqueueSnackbar('Erro ao carregar a lista de simulações de pedidos de venda.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		handleEdit(id: string) {
			navigate(`/edit/${id}`);
		},
		handleApproveReject(
			id: string,
			status: ProfitabilityAnalysisStatus,
			actionJustificative?: string,
		) {
			actions.setLoading(true);
			approveOrRejectSaleOrderSimulation(id, status, actionJustificative)
				.then((response) => {
					enqueueSnackbar(response?.data.message, { variant: 'success' });
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Erro ao processar a operação.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
					actions.getSaleOrderSimulations({ skip: 0 });
				});
		},
		handleDeleteSaleOrderSimulation(id: string) {
			actions.setLoading(true);
			deleteSaleOrderSimulation(id)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
					actions.getSaleOrderSimulations({ skip: 0 });
				});
		},
	};

	return actions;
};

const ProfitabilityAnalysisContainer = (): JSX.Element | null => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => ProfitabilityAnalysisActions(dispatch, enqueueSnackbar, navigate),
		[dispatch, enqueueSnackbar, navigate],
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<ProfitabilityAnalysisPresentational {...state} {...actions} />
	);
};

export default ProfitabilityAnalysisContainer;
