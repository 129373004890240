import { AxiosResponse } from 'axios';
import api from './api';
import { IGenerateLicense, ILicense } from '../containers/License/LicenseAssets';

export const getLicenses = (): Promise<AxiosResponse<ILicense[]>> => api.get<ILicense[]>('/license');

export const getUnExpiredLicenses = (): Promise<AxiosResponse<ILicense[]>> => api.get<ILicense[]>('/license/unexpired');

export const getLicenseById = (id: string): Promise<AxiosResponse<ILicense>> => api.get<ILicense>(`/license/${id}`);

export const createLicense = (data: ILicense): Promise<AxiosResponse<ILicense & { message: string }>> => api.post<ILicense, AxiosResponse<ILicense & { message: string }>>('/license', data);

export const generateLicense = (data: IGenerateLicense): Promise<AxiosResponse<IGenerateLicense & { message: string }>> => api.post<IGenerateLicense, AxiosResponse<IGenerateLicense & { message: string }>>('/license/generate', data);

export const updateLicense = (id: string, data: ILicense): Promise<AxiosResponse<ILicense & { message: string }>> => api.patch<ILicense, AxiosResponse<ILicense & { message: string }>>(`/license/${id}`, data);

export const deleteLicense = (id: string): Promise<AxiosResponse<ILicense & { message: string }>> => api.delete<ILicense & { message: string }>(`/license/${id}`);
