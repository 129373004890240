import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import CompanyTable from './CompanyTable';
import { ICompany } from '../../containers/Company/CompanyAssets';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';

interface CompanyProps {
	loading: boolean;
	companies: ICompany[];
	getCompanies(): void;
	handleDeleteCompany: (id: string) => void;
  }

const headerButtonsProps: PageHeaderButtonProps[] = [
	{
		variant: 'contained',
		color: 'primary',
		type: 'submit',
		fullWidth: true,
		component: Link,
		to: 'edit',
		text: 'Novo',
	},
];

const Company = ({
	loading, companies, getCompanies, handleDeleteCompany,
}: CompanyProps): JSX.Element => {
	useEffect(() => {
		getCompanies();
	}, [getCompanies]);

	return (
		<Box className="content">
			<PageHeader title="Empresa" buttons={headerButtonsProps} />
			<CompanyTable
				loading={loading}
				rows={companies}
				handleDeleteCompany={handleDeleteCompany}
			/>
		</Box>
	);
};

export default Company;
