import React, { useMemo, useState, useCallback } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
	Tooltip,
	IconButton,
	Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CalculateIcon from '@mui/icons-material/Calculate';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import Decimal from 'decimal.js';
import {
	initialSaleOrderSimulationItem,
	initialSalePriceFormationAnalysis,
	ISaleOrderSimulation,
	ISalePriceFormationAnalysis,
} from '../../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import { currencyBRLMask } from '../../../helpers/intl';
import Input from '../../Common/Form/Input';
import {
	getSalePriceFormation,
	getSelectedItem,
	getSelectedItemIndex,
} from '../../../helpers/ProfitabilityAnalysisCalculations';
import { formatPercentage, getColorForValue } from '../../../helpers/Utils';
import { ProfitabilityAnalysisStatus } from '../../../enums/ProfitabilityAnalysisStatus';
import { AverageCostSource } from '../../../enums/AliasAverageCostSource';
import { aliasesSalePriceFormation } from '../../../constants/aliases';

interface SalePriceFormationSimulationTableProps {
	updateSimulationPrice(
		saleOrderSimulationItemId: string,
		simulationPrice: number,
	): void;
}

const SalePriceFormationSimulationTable = ({
	updateSimulationPrice,
}: SalePriceFormationSimulationTableProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<ISaleOrderSimulation>();
	const [showExtraColumns, setShowExtraColumns] = useState(true);
	const { enqueueSnackbar } = useSnackbar();

	const selectedItem = useMemo(
		() => getSelectedItem(
			values.saleOrderSimulationItems,
			initialSaleOrderSimulationItem,
		),
		[values.saleOrderSimulationItems],
	);

	const selectedItemIndex = useMemo(
		() => getSelectedItemIndex(values.saleOrderSimulationItems),
		[values.saleOrderSimulationItems],
	);

	const salePriceFormation = useMemo(
		() => getSalePriceFormation(selectedItem, initialSalePriceFormationAnalysis),
		[selectedItem],
	);

	const isReadOnly = useMemo(
		() => [
			ProfitabilityAnalysisStatus.APPROVED,
			ProfitabilityAnalysisStatus.REJECTED].includes(values.status),
		[values.status],
	);

	const handleCalculateSimulationPrice = useCallback(() => {
		if (!selectedItem || !salePriceFormation?.formula) {
			enqueueSnackbar('Fórmula não definida', { variant: 'error' });
			return;
		}

		try {
			const valueA = salePriceFormation.aliasAverageCostSource === AverageCostSource.AVERAGE_COST
				? salePriceFormation.averageCost
				: salePriceFormation.totalDirectCost;
			const vistaTermId = salePriceFormation.averagePaymentTermSalePriceFormations
				?.find((term) => {
					const normalizedDescription = (term.averagePaymentTerm.description ?? '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
					return ['a vista', 'à vista', 'avista', 'vista'].includes(normalizedDescription);
				})?.averagePaymentTermId;

			const vistaTerm = salePriceFormation.averagePaymentTermSalePriceFormations
				?.find((term) => term.averagePaymentTermId === vistaTermId);
			const vistaInterestRate = vistaTerm ? vistaTerm.interestRate : 0;

			const simulationAliases = {
				A: salePriceFormation.averageCost,
				B: selectedItem?.salePriceFormation?.baseCommission,
				C: vistaInterestRate,
				D: salePriceFormation.totalIndirectCost,
				E: selectedItem?.salePriceFormation?.indexValueRangeCommissionSimulation,
				F: selectedItem?.salePriceFormation?.discountIndex,
				G: salePriceFormation.totalDirectCost,
			};

			const replacedFormulaVista = Object.keys(simulationAliases).reduce((acc, key) => {
				let value;
				if (key === 'A') {
					value = valueA;
				} else if (key === 'C') {
					value = vistaInterestRate;
				} else {
					value = salePriceFormation[
						aliasesSalePriceFormation[key] as keyof ISalePriceFormationAnalysis
					] ?? 0;
				}
				return acc.replace(new RegExp(key, 'g'), value ? value.toString() : '0');
			}, salePriceFormation.formula);

			// eslint-disable-next-line no-eval
			const resultVista = eval(replacedFormulaVista.replace(/\\/g, '/'));

			const selectedTermId = selectedItem?.salePriceFormation?.averageTermSimulationId;
			const selectedTerm = salePriceFormation.averagePaymentTermSalePriceFormations
				?.find((term) => term.averagePaymentTermId === selectedTermId);
			const selectedInterestRate = selectedTerm ? selectedTerm.interestRate : 0;

			let finalResult = resultVista;

			if (selectedTerm?.averagePaymentTermId !== vistaTermId) {
				finalResult = resultVista * (1 + selectedInterestRate / 100);
			}

			updateSimulationPrice(selectedItem.id, finalResult);

			setFieldValue(`saleOrderSimulationItems[${selectedItemIndex}].simulationPrice`, finalResult);
		} catch (error) {
			enqueueSnackbar('Erro ao calcular o preço de simulação', { variant: 'error' });
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedItem,
		salePriceFormation,
		selectedItemIndex,
	]);

	const handleBlurSimulationPrice = useCallback(
		(value: string | number) => {
			const simulationPrice = typeof value === 'string'
				? parseFloat(value.replace(/R\$\s?/g, '').replace(/\./g, '').replace(',', '.'))
				: value;

			updateSimulationPrice(selectedItem.id, simulationPrice);
		},
		[selectedItem.id, updateSimulationPrice],
	);

	const backgroundColor = useMemo(
		() => (showExtraColumns ? 'primary.dark' : 'primary.main'),
		[showExtraColumns],
	);

	return (
		<Box sx={{ display: 'flex', position: 'relative' }}>
			<TableContainer component={Paper} sx={{ flexGrow: 1 }}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '20%' }}>
								<strong>Descrição (FPV)</strong>
							</TableCell>
							<TableCell sx={{ width: '20%' }} align="right">
								<strong>Valores</strong>
							</TableCell>
							<TableCell sx={{ width: '10%' }} align="right">
								<strong>(%)</strong>
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{
											width: '15%',
											backgroundColor: '#f0f0f0',
										}}
									>
										<strong>Pedido Venda</strong>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											width: '10%',
											backgroundColor: '#f0f0f0',
										}}
									>
										<strong>
											(%)
										</strong>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											width: '15%',
											backgroundColor: '#f0f0f0',
										}}
									>
										<strong>
											Simulação Preço
										</strong>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											width: '10%',
											backgroundColor: '#f0f0f0',
										}}
									>
										<strong>
											(%)
										</strong>
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip
									title={
										showExtraColumns
											? 'Ocultar colunas de simulação'
											: 'Visualizar colunas de simulação'
									}
								>
									<IconButton
										sx={{ color: 'white' }}
										onClick={() => setShowExtraColumns(
											!showExtraColumns,
										)}
									>
										{showExtraColumns ? (
											<VisibilityOffIcon />
										) : (
											<VisibilityIcon />
										)}
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Preço Venda</TableCell>
							<TableCell align="right">
								<Tooltip title={salePriceFormation?.formula}>
									<span>
										{currencyBRLMask(
											salePriceFormation?.salePrice || 0,
											{ decimalPlaces: 3 },
										)}
									</span>
								</Tooltip>
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(selectedItem.price, {
											decimalPlaces: 3,
										})}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										<Input.FinancialInputField
											name={`saleOrderSimulationItems[${selectedItemIndex}].simulationPrice`}
											size="small"
											decimalScale={3}
											onBlur={(e) => handleBlurSimulationPrice(e.target.value)}
											InputProps={{
												sx: {
													backgroundColor: '#e0e0e0',
													'& .MuiOutlinedInput-notchedOutline': {
														border: 'none',
													},
													'& input': {
														textAlign: 'right',
														padding: '2px 8px',
													},
												},
											}}
											disabled={isReadOnly}
										/>
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
						</TableRow>
						<TableRow>
							<TableCell>Custo Direto (CPV)</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									salePriceFormation?.aliasAverageCostSource === AverageCostSource.TOTAL_DIRECT_COST
										? salePriceFormation?.totalDirectCost ?? 0
										: salePriceFormation?.averageCost ?? 0,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											salePriceFormation?.aliasAverageCostSource
											=== AverageCostSource.TOTAL_DIRECT_COST
												? salePriceFormation?.totalDirectCost ?? 0
												: salePriceFormation?.averageCost ?? 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											salePriceFormation?.aliasAverageCostSource
											=== AverageCostSource.TOTAL_DIRECT_COST
												? salePriceFormation?.totalDirectCost ?? 0
												: salePriceFormation?.averageCost ?? 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
						<TableRow>
							<TableCell>Lucro Bruto</TableCell>
							<TableCell
								align="right"
								sx={{
									color: getColorForValue(
										selectedItem.grossProfit || 0,
									),
									fontWeight: 'bold',
								}}
							>
								{currencyBRLMask(
									selectedItem.grossProfit || 0,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell
								align="right"
								sx={{
									color: getColorForValue(
										(new Decimal(selectedItem.grossProfit || 0)
											.div(salePriceFormation.salePrice || 0)
											.times(100)
											.toNumber()),
									),
									fontWeight: 'bold',
								}}
							>
								{formatPercentage(new Decimal(selectedItem.grossProfit || 0)
									.div(salePriceFormation.salePrice || 0)
									.times(100)
									.toNumber())}
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{
											backgroundColor: '#f0f0f0',
											color: getColorForValue(
												selectedItem.grossProfitSaleOrder || 0,
											),
											fontWeight: 'bold',
										}}
									>
										{currencyBRLMask(
											selectedItem.grossProfitSaleOrder || 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{
											backgroundColor: '#f0f0f0',
											color: getColorForValue(
												(new Decimal(selectedItem.grossProfitSaleOrder || 0)
													.div(selectedItem.price || 0)
													.times(100)
													.toNumber()),
											),
											fontWeight: 'bold',
										}}
									>
										{formatPercentage(new Decimal(selectedItem.grossProfitSaleOrder || 0)
											.div(selectedItem.price || 0)
											.times(100)
											.toNumber())}
									</TableCell>
									<TableCell
										align="right"
										sx={{
											backgroundColor: '#f0f0f0',
											color: getColorForValue(
												selectedItem.grossProfitSimulation || 0,
											),
											fontWeight: 'bold',
										}}
									>
										{currencyBRLMask(
											selectedItem.grossProfitSimulation || 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{
											backgroundColor: '#f0f0f0',
											color: getColorForValue(
												(new Decimal(selectedItem.grossProfitSimulation || 0)
													.div(selectedItem.simulationPrice || 0)
													.times(100)
													.toNumber()),
											),
											fontWeight: 'bold',
										}}
									>
										{formatPercentage(new Decimal(selectedItem.grossProfitSimulation || 0)
											.div(selectedItem.simulationPrice || 0)
											.times(100)
											.toNumber())}
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
						<TableRow>
							<TableCell>Custo Financeiro</TableCell>
							<TableCell align="right">
								{currencyBRLMask(selectedItem.financialCost || 0, {
									decimalPlaces: 3,
								})}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(selectedItem.financialCost || 0, {
											decimalPlaces: 3,
										})}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(selectedItem.financialCostSimulation || 0, {
											decimalPlaces: 3,
										})}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
						<TableRow>
							<TableCell>Custo Indireto</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									selectedItem.totalUnitValueIndirectCost || 0,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell align="right" sx={{ backgroundColor: '#f0f0f0' }}>
										{currencyBRLMask(
											selectedItem.totalUnitValueIndirectCost || 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											selectedItem.totalUnitValueIndirectCostSimulation || 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
						<TableRow>
							<TableCell>Comissão</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									salePriceFormation?.unitValueRangeCommission
										|| 0,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											salePriceFormation?.unitValueRangeCommission
												|| 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											salePriceFormation?.unitValueRangeCommissionSimulation
										|| 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
						<TableRow>
							<TableCell>Lucro Líquido</TableCell>
							<TableCell
								align="right"
								sx={{
									color: getColorForValue(
										selectedItem.netProfit || 0,
									),
									fontWeight: 'bold',
								}}
							>
								{currencyBRLMask(
									selectedItem.netProfit || 0,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell
								align="right"
								sx={{
									color: getColorForValue(
										(new Decimal(selectedItem.netProfit || 0)
											.div(salePriceFormation.salePrice || 0)
											.times(100)
											.toNumber()),
									),
									fontWeight: 'bold',
								}}
							>
								{formatPercentage(new Decimal(selectedItem.netProfit || 0)
									.div(salePriceFormation.salePrice || 0)
									.times(100)
									.toNumber())}
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{
											backgroundColor: '#f0f0f0',
											color: getColorForValue(
												selectedItem.netProfitSaleOrder || 0,
											),
											fontWeight: 'bold',
										}}
									>
										{currencyBRLMask(
											selectedItem.netProfitSaleOrder || 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{
											backgroundColor: '#f0f0f0',
											color: getColorForValue(
												(new Decimal(selectedItem.netProfitSaleOrder || 0)
													.div(selectedItem.price || 0)
													.times(100)
													.toNumber()),
											),
											fontWeight: 'bold',
										}}
									>
										{formatPercentage(new Decimal(selectedItem.netProfitSaleOrder || 0)
											.div(selectedItem.price || 0)
											.times(100)
											.toNumber())}
									</TableCell>
									<TableCell
										align="right"
										sx={{
											backgroundColor: '#f0f0f0',
											color: getColorForValue(
												selectedItem.netProfitSimulation || 0,
											),
											fontWeight: 'bold',
										}}
									>
										{currencyBRLMask(
											selectedItem.netProfitSimulation || 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{
											backgroundColor: '#f0f0f0',
											color: getColorForValue(
												(new Decimal(selectedItem.netProfitSimulation || 0)
													.div(selectedItem.simulationPrice || 0)
													.times(100)
													.toNumber()),
											),
											fontWeight: 'bold',
										}}
									>
										{formatPercentage(new Decimal(selectedItem.netProfitSimulation || 0)
											.div(selectedItem.simulationPrice || 0)
											.times(100)
											.toNumber())}
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				sx={{
					width: '40px',
					backgroundColor: showExtraColumns ? 'primary.dark' : 'primary.main',
					position: 'absolute',
					right: 0,
					top: 0,
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{showExtraColumns && (
					<Box
						sx={{
							position: 'absolute',
							top: '40px',
						}}
					>
						<Tooltip title="Calcular Preço da Simulação">
							<IconButton
								onClick={handleCalculateSimulationPrice}
								disabled={isReadOnly}
							>
								<CalculateIcon sx={{ color: 'white' }} />
							</IconButton>
						</Tooltip>
					</Box>
				)}
				<Typography
					sx={{
						writingMode: 'vertical-rl',
						transform: 'rotate(180deg)',
						color: 'white',
						fontWeight: 'bold',
						fontSize: '0.75rem',
						letterSpacing: '1px',
						textAlign: 'center',
						lineHeight: 'normal',
						marginTop: '60px',
					}}
				>
					Simulação
				</Typography>
			</Box>
		</Box>
	);
};

export default SalePriceFormationSimulationTable;
