import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Grid,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	FormControl,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import {
	IIndirectCost,
	ICreateIndirectCost,
	IIndirectCostSalePriceFormation,
	IExtendedSalePriceFormation,
} from '../../../containers/SalePriceFormation/SalePriceFormationAssets';
import Input from '../../Common/Form/Input';
import Autocomplete from '../../Common/Form/Autocomplete';
import ModalForm from '../Modals/ModalForm';

interface IndirectCostFormProps {
	isReadOnly: boolean;
	indirectCosts?: IIndirectCost[];
	createIndirectCost: (data: ICreateIndirectCost) => void;
}

const IndirectCostForm = ({
	isReadOnly,
	indirectCosts,
	createIndirectCost,
}: IndirectCostFormProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<IExtendedSalePriceFormation>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	useEffect(() => {
		const total = values.indirectCostSalePriceFormations?.reduce((acc, current) => acc
			+ parseFloat(current.interestRate as unknown as string), 0) || 0;
		setFieldValue('totalIndirectCost', total);
	}, [values.indirectCostSalePriceFormations, setFieldValue]);

	const handleAddCost = useCallback((): void => {
		const selectedCost = indirectCosts?.find(
			(cost) => cost.id === values.currentCostId,
		);

		if (selectedCost && values.id && values.currentCostRate) {
			const newCost: IIndirectCostSalePriceFormation = {
				indirectCostId: selectedCost.id,
				salePriceFormationId: values.id,
				interestRate: values.currentCostRate,
				description: selectedCost.description,
			};

			const newIndirectCosts = [
				...(values.indirectCostSalePriceFormations || []),
				newCost,
			];

			setFieldValue('indirectCostSalePriceFormations', newIndirectCosts);
		}
		setFieldValue('currentCostRate', '');
	}, [indirectCosts, values, setFieldValue]);

	const handleDeleteIndirectCost = useCallback((indirectCostId: string): void => {
		const updatedCosts = values.indirectCostSalePriceFormations?.filter(
			(indirectCost) => indirectCost.indirectCostId !== indirectCostId,
		);
		setFieldValue('indirectCostSalePriceFormations', updatedCosts);
	}, [values.indirectCostSalePriceFormations, setFieldValue]);

	const handleOpenModal = useCallback((): void => {
		setIsModalOpen(true);
	}, []);

	const handleCloseModal = useCallback((): void => {
		setIsModalOpen(false);
		setFieldValue('newCostDescription', '');
		setFieldValue('newCostRate', '');
	}, [setFieldValue]);

	const handleCreateNewCost = useCallback(() => {
		if (values.newCostDescription && values.newCostRate) {
			createIndirectCost({
				description: values.newCostDescription,
				interestRate: parseFloat(values.newCostRate),
			});
			handleCloseModal();
		}
	}, [values, createIndirectCost, handleCloseModal]);

	const handleCostTypeInputChange = useCallback((
		event: any,
		value: string,
		reason: string,
	): void => {
		if (reason === 'reset') {
			const selectedCost = indirectCosts?.find((cost) => `${cost.code} - ${cost.description}` === value);
			if (selectedCost) {
				setFieldValue('currentCostId', selectedCost.id);
				setFieldValue('currentCostRate', selectedCost.interestRate);
				setFieldValue('currentCostDescription', selectedCost.description);
			}
		} else if (reason === 'clear') {
			setFieldValue('currentCostId', '');
			setFieldValue('currentCostRate', '');
			setFieldValue('currentCostCode', '');
			setFieldValue('currentCostDescription', '');
		}
	}, [indirectCosts, setFieldValue]);

	const isCostAdded = useCallback((costId: string) => values.indirectCostSalePriceFormations?.some(
		(ic) => ic.indirectCostId === costId,
	), [values.indirectCostSalePriceFormations]);

	const handleBlur = useCallback((event, indirectCost) => {
		const { value } = event.target;
		if (!value) return;

		const updatedIndirectCosts = values.indirectCostSalePriceFormations?.map(
			(existingIndirectCost) => (existingIndirectCost.indirectCostId
				=== indirectCost.indirectCostId
				? { ...existingIndirectCost, interestRate: value }
				: existingIndirectCost),
		);
		setFieldValue('indirectCostSalePriceFormations', updatedIndirectCosts);
	}, [values.indirectCostSalePriceFormations, setFieldValue]);

	const modalMemo = useMemo(() => (
		<ModalForm
			isOpen={isModalOpen}
			onClose={handleCloseModal}
			title="Custo Indireto Padrão"
			fields={[
				{ name: 'newCostDescription', label: 'Descrição' },
				{
					name: 'newCostRate',
					label: 'Porcentagem',
					type: 'number',
					adornment: '%',
				},
			]}
			onConfirm={handleCreateNewCost}
		/>
	), [isModalOpen, handleCloseModal, handleCreateNewCost]);

	const availableIndirectCosts = useMemo(() => indirectCosts
		?.filter((cost) => !isCostAdded(cost.id)) || [], [indirectCosts, isCostAdded]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TableContainer component={Paper} sx={{ maxHeight: '322px' }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell sx={{ width: '50%' }}>Descrição</TableCell>
								<TableCell align="right" sx={{ width: '10%' }}>Porcentagem</TableCell>
								<TableCell align="right" sx={{ width: '20%' }}>Ações</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{values.indirectCostSalePriceFormations?.map((indirectCost, index) => (
								<TableRow key={indirectCost.indirectCostId}>
									<TableCell sx={{ padding: '6px 16px' }}>{indirectCost.description}</TableCell>
									<TableCell align="right" sx={{ padding: '6px 16px', paddingRight: 0 }}>
										<Input.InputField
											name={`indirectCostSalePriceFormations[${index}].interestRate`}
											type="number"
											fullWidth
											margin="dense"
											sx={{
												height: '30px',
												margin: 0,
												'& .MuiInputBase-input': {
													height: '30px',
													padding: '0 5px',
													marginRight: 0,
												},
											}}
											textAlign="right"
											InputProps={{ endAdornment: '%' }}
											onBlur={(event) => handleBlur(event, indirectCost)}
											disabled={isReadOnly}
										/>
									</TableCell>
									<TableCell align="right" sx={{ padding: '6px 16px' }}>
										<IconButton onClick={() => handleDeleteIndirectCost(indirectCost.indirectCostId)} color="error" disabled={isReadOnly}>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
							<TableRow>
								<TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
								<TableCell align="left" sx={{ fontWeight: 'bold' }}>
									{Number(values.totalIndirectCost || 0).toFixed(3).replace('.', ',')}
									%
								</TableCell>
								<TableCell />
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={12} md={6}>
				<FormControl fullWidth margin="dense">
					<Autocomplete
						name="currentCostCode"
						label="Tipo de Custo"
						options={availableIndirectCosts || []}
						labelKey="description"
						valueKey="code"
						onInputChange={handleCostTypeInputChange}
						closeOnSelect
						disabled={isReadOnly}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={6}>
				<Input.InputField
					fullWidth
					label="Porcentagem"
					name="currentCostRate"
					variant="outlined"
					margin="dense"
					type="number"
					InputProps={{ endAdornment: '%' }}
					InputLabelProps={{ shrink: true }}
					disabled={isReadOnly}
				/>
			</Grid>
			<Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Button variant="contained" onClick={handleAddCost} color="primary" disabled={isReadOnly}>
					Adicionar
				</Button>
				<Button variant="outlined" onClick={handleOpenModal} startIcon={<AddIcon />} disabled={isReadOnly}>
					Custo Indireto Padrão
				</Button>
			</Grid>
			{modalMemo}
		</Grid>
	);
};

IndirectCostForm.defaultProps = {
	indirectCosts: undefined,
};

export default IndirectCostForm;
