import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { GridSortDirection } from '@mui/x-data-grid';
import NoDataPage from '../../Common/NoDataPage';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import { BudgetUserCollapse } from './BudgetUserCollapse/BudgetUserCollapse';
import BudgetUserFilter from './BudgetUsersFilter';
import { IFilterField } from '../../../interfaces/BudgetOrderFourFields';
import { IBudgetApprover } from '../../../interfaces/BudgetUserLevels';
import { BudgetApproverQueryParams } from '../../../interfaces/BudgetApproverQueryParams';

const fabStyles: SxProps<Theme> = {
	position: 'fixed',
	bottom: '16px',
	right: '16px',
};

interface FabProps {
	disabled: boolean;
	sortValue: GridSortDirection | undefined;
	setSort: (value: GridSortDirection) => void;
}

const FabComponent = React.memo(({
	disabled, sortValue, setSort,
}: FabProps): JSX.Element => {
	const [hover, setHover] = useState(false);

	const toggleSort = useCallback((): void => {
		const newSortValue = sortValue === 'asc' ? 'desc' : 'asc';
		setSort(newSortValue);
		setHover(false);
	}, [setSort, sortValue]);

	const sortLabel = useMemo(() => (sortValue === 'asc' ? 'Z-A' : 'A-Z'), [sortValue]);

	return (
		<Fab
			variant={hover ? 'extended' : 'circular'}
			size="large"
			color="primary"
			disabled={disabled}
			onMouseOver={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={toggleSort}
			sx={fabStyles}
		>
			<SortByAlphaIcon sx={hover ? { mr: 1 } : {}} />
			{ hover && `Ordernar de: ${sortLabel}` }
		</Fab>
	);
});

interface BudgetApproversProps {
	loading: boolean;
	budgetApprovers: IBudgetApprover[];
	accountingAccounts: IFilterField[];
	costCenters: IFilterField[];
	accountingItems: IFilterField[];
	classValues: IFilterField[];
	getFilterFieldsValues: () => void;
	getBudgetApprovers: (queryParams: BudgetApproverQueryParams) => void;
	handleBudgetApprovers: (data: IBudgetApprover) => void;
}

const BudgetUserLevels = ({
	loading,
	accountingAccounts,
	costCenters,
	accountingItems,
	classValues,
	budgetApprovers,
	getFilterFieldsValues,
	getBudgetApprovers,
	handleBudgetApprovers,
}: BudgetApproversProps): JSX.Element => {
	const [open, setOpen] = useState<number | null>(null);
	const [filter, setFilter] = useState<BudgetApproverQueryParams>({});
	const [sort, setSort] = useState<GridSortDirection>('asc');

	useEffect(() => {
		getFilterFieldsValues();
		getBudgetApprovers({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOpen = useCallback((index) => {
		setOpen(index);
	}, []);

	const sendFilter = useCallback((values: BudgetApproverQueryParams) => {
		const pageFilter = {
			...values,
			sort,
		};

		getBudgetApprovers(pageFilter);
		setFilter(pageFilter);
	}, [getBudgetApprovers, sort]);

	const setSortProxy = useCallback((currentSort: GridSortDirection) => {
		const pageFilter = {
			...filter,
			sort: currentSort,
		};

		getBudgetApprovers(pageFilter);
		setSort(currentSort);
	}, [filter, getBudgetApprovers]);

	const contentMemo = useMemo(() => {
		if (loading) {
			return (
				<Box sx={{ height: '100%', overflow: 'auto' }}>
					{[1, 2, 3, 4, 5].map((item) => (
						<Skeleton
							key={item}
							variant="rectangular"
							sx={{ height: '4rem', mb: 2 }}
						/>
					))}
				</Box>
			);
		}

		if (budgetApprovers.length === 0) {
			return <NoDataPage />;
		}

		return (
			<Stack spacing={2}>
				{budgetApprovers.map((approver, index) => (
					<BudgetUserCollapse
						key={approver.id}
						index={index}
						approver={approver}
						open={open === index}
						accountingAccounts={accountingAccounts}
						costCenters={costCenters}
						accountingItems={accountingItems}
						classValues={classValues}
						handleOpen={handleOpen}
						handleBudgetApprovers={handleBudgetApprovers}
					/>
				))}
			</Stack>
		);
	}, [
		loading,
		budgetApprovers,
		open,
		accountingAccounts,
		costCenters,
		accountingItems,
		classValues,
		handleOpen,
		handleBudgetApprovers,
	]);

	const filterMemo = useMemo(() => (
		<BudgetUserFilter sendFilter={sendFilter} />
	), [sendFilter]);

	const fabMemo = useMemo(() => (
		<FabComponent
			disabled={loading}
			sortValue={sort}
			setSort={setSortProxy}
		/>
	), [loading, setSortProxy, sort]);

	return (
		<>
			<PageHeader title="Usuários" />
			{filterMemo}
			{contentMemo}
			{fabMemo}
		</>
	);
};

export default BudgetUserLevels;
