export const xmlTags: { [key: string]: string } = {
	'emit>CNPJ': 'EmitenteCNPJ',
	'emit>xNome': 'EmitentexNome',
	'emit>xFant': 'EmitenteNomeFantasia',
	'emit>enderEmit>xLgr': 'EmitenteEndereco',
	'emit>enderEmit>nro': 'EmitenteNumero',
	'emit>enderEmit>xBairro': 'EmitenteBairro',
	'emit>enderEmit>cMun': 'EmitenteMunicipio',
	'emit>enderEmit>xMun': 'EmitenteNomeMunicipio',
	'emit>enderEmit>UF': 'EmitenteUF',
	'emit>enderEmit>CEP': 'EmitenteCEP',
	'emit>enderEmit>cPais': 'EmitenteCodigoPais',
	'emit>enderEmit>xPais': 'EmitentePais',
	'emit>enderEmit>fone': 'EmitenteTelefone',
	'emit>IE': 'EmitenteIE',
	'emit>IM': 'EmitenteIM',
	'emit>CNAE': 'EmitenteCNAE',
	'emit>CRT': 'EmitenteCRT',
	'dest>CNPJ': 'DestinatarioCNPJ',
	'dest>xNome': 'DestinatarioxNome',
	'dest>enderDest>xLgr': 'DestinatarioEndereco',
	'dest>enderDest>nro': 'DestinatarioNumero',
	'dest>enderDest>xCpl': 'DestinatarioComplemento',
	'dest>enderDest>xBairro': 'DestinatarioBairro',
	'dest>enderDest>cMun': 'DestinatarioMunicipio',
	'dest>enderDest>xMun': 'DestinatarioNomeMunicipio',
	'dest>enderDest>UF': 'DestinatarioUF',
	'dest>enderDest>CEP': 'DestinatarioCEP',
	'dest>enderDest>cPais': 'DestinatarioCodigoPais',
	'dest>enderDest>xPais': 'DestinatarioPais',
	'dest>enderDest>fone': 'DestinatarioTelefone',
	'dest>indIEDest': 'DestinatarioIndicadorIE',
	'dest>IE': 'DestinatarioIE',
	'transp>transporta>xNome': 'TransportadorRazaoSocial',
	'transp>veicTransp>RNTC': 'CodigoANTT',
	'transp>veicTransp>placa': 'PlacaVeiculo',
	'transp>veicTransp>UF': 'VeiculoUF',
	'transp>transporta>CNPJ': 'TransportadorCNPJ',
	'transp>transporta>CPF': 'TransportadorCPF',
	'transp>transporta>xEnder': 'TransportadorEndereco',
	'transp>transporta>xMun': 'TransportadorMunicipio',
	'transp>transporta>UF': 'TransportadorUF',
	'transp>transporta>IE': 'TransportadorIE',
	'transp>vol>qVol': 'VolumeQuantidade',
	'transp>vol>esp': 'VolumeEspecie',
	'transp>vol>marca': 'VolumeMarca',
	'transp>vol>nVol': 'VolumeNumeracao',
	'transp>vol>pesoB': 'PesoBruto',
	'transp>vol>pesoL': 'PesoLiquido',
	'det>prod>cProd': 'ProdutoCodigo',
	'det>prod>cEAN': 'ProdutoEAN',
	'det>prod>xProd': 'ProdutoDescricao',
	'det>prod>NCM': 'ProdutoNCM',
	'det>prod>CEST': 'ProdutoCEST',
	'det>prod>indEscala': 'ProdutoIndEscala',
	'det>prod>CFOP': 'ProdutoCFOP',
	'det>prod>uCom': 'ProdutoUnidadeComercial',
	'det>prod>qCom': 'ProdutoQuantidadeComercial',
	'det>prod>vUnCom': 'ProdutoValorUnitarioComercial',
	'det>prod>vProd': 'ProdutoValorTotal',
	'det>prod>cEANTrib': 'ProdutoEANTributavel',
	'det>prod>uTrib': 'ProdutoUnidadeTributavel',
	'det>prod>qTrib': 'ProdutoQuantidadeTributavel',
	'det>prod>vUnTrib': 'ProdutoValorUnitarioTributavel',
	'det>prod>vDesc': 'ProdutoValorDesconto',
	'det>prod>indTot': 'ProdutoIndicadorTotal',
	'det>prod>comb>cProdANP': 'CombustivelCodigoANP',
	'det>prod>comb>descANP': 'CombustivelDescricaoANP',
	'det>prod>comb>UFCons': 'CombustivelUFConsumo',
	'det>prod>comb>encerrante>nBico': 'EncerranteNumeroBico',
	'det>prod>comb>encerrante>nBomba': 'EncerranteNumeroBomba',
	'det>prod>comb>encerrante>nTanque': 'EncerranteNumeroTanque',
	'det>prod>comb>encerrante>vEncIni': 'EncerranteValorInicial',
	'det>prod>comb>encerrante>vEncFin': 'EncerranteValorFinal',
	'det>imposto>vTotTrib': 'ImpostoValorTotalTributos',
	'det>imposto>ICMS>ICMS61>orig': 'ICMS61Origem',
	'det>imposto>ICMS>ICMS61>CST': 'ICMS61CST',
	'det>imposto>ICMS>ICMS61>qBCMonoRet': 'ICMS61BaseCalculoMonoRet',
	'det>imposto>ICMS>ICMS61>adRemICMSRet': 'ICMS61AliquotaReducaoICMSRet',
	'det>imposto>ICMS>ICMS61>vICMSMonoRet': 'ICMS61ValorICMSMonoRet',
	'det>imposto>PIS>PISNT>CST': 'PISCST',
	'det>imposto>COFINS>COFINSNT>CST': 'COFINSCST',
	'det>infAdProd': 'InformacaoAdicionalProduto',
	'total>ICMSTot>vTotTrib': 'vTotTribICMS',
	'total>ICMSTot>vProd': 'vProdTotal',
	CFOP: 'CFOP',
	chNFe: 'chNFe',
	CNAE: 'CNAE',
	CRT: 'CRT',
	CST: 'CST',
	Cest: 'CEST',
	cDV: 'cDV',
	cEAN: 'cEAN',
	cEANTrib: 'cEANTrib',
	cMun: 'cMun',
	cMunFG: 'cMunFG',
	cPais: 'cPais',
	cProd: 'cProd',
	cProdANP: 'cProdANP',
	dhEmi: 'dhEmi',
	dhSaiEnt: 'dhSaiEnt',
	descANP: 'descANP',
	finNFe: 'finNFe',
	fone: 'fone',
	ICMS00: 'ICMS00',
	IE: 'IE',
	IEST: 'IEST',
	IM: 'IM',
	impICMSRet: 'impICMSRet',
	indFinal: 'indFinal',
	indIEDest: 'indIEDest',
	indPres: 'indPres',
	indTot: 'indTot',
	infNFe: 'infNFe',
	infAdic: 'Informacoes-Adicionais',
	infCpl: 'infCpl',
	infRespTec: 'Info-Responsavel-Tecnico',
	mod: 'mod',
	modBC: 'modBC',
	modFrete: 'modFrete',
	NCM: 'NCM',
	CEST: 'CEST',
	nItem: 'nItem',
	nNF: 'nNF',
	natOp: 'natOp',
	nfeProc: 'nfeProc',
	NFref: 'NFref',
	nProt: 'nProt',
	nro: 'nro',
	pBio: 'pBio',
	pag: 'Pagamento',
	pICMS: 'pICMS',
	pIPI: 'pIPI',
	qBCMono: 'qBCMono',
	qBCMonoRet: 'qBCMonoRet',
	qBCMonoReten: 'qBCMonoReten',
	qCom: 'qCom',
	qTrib: 'qTrib',
	serie: 'serie',
	tpAmb: 'tpAmb',
	tpEmis: 'tpEmis',
	tpImp: 'tpImp',
	tpNF: 'tpNF',
	tPag: 'tPag',
	UF: 'UF',
	UFCons: 'UFCons',
	vBC: 'vBC',
	vBCST: 'vBCST',
	vCOFINS: 'vCOFINS',
	vDesc: 'vDesc',
	vFCP: 'vFCP',
	vFCPST: 'vFCPST',
	vFCPSTRet: 'vFCPSTRet',
	vFrete: 'vFrete',
	vICMS: 'vICMS',
	vICMSDeson: 'vICMSDeson',
	vICMSMono: 'vICMSMono',
	vICMSMonoReten: 'vICMSMonoReten',
	vICMSMonoRet: 'vICMSMonoRet',
	vICMSUFDest: 'vICMSUFDest',
	vICMSUFRemet: 'vICMSUFRemet',
	vIPI: 'vIPI',
	vII: 'vII',
	vIPIDevol: 'vIPIDevol',
	vNF: 'vNF',
	vOutro: 'vOutro',
	vPIS: 'vPIS',
	vPag: 'vPag',
	vProd: 'vProd',
	vSeg: 'vSeg',
	vST: 'vST',
	vTotTrib: 'vTotTrib',
	vUnCom: 'vUnCom',
	uCom: 'uCom',
	xBairro: 'xBairro',
	xCpl: 'xCpl',
	xFant: 'xFant',
	xLgr: 'xLgr',
	xMun: 'xMun',
	xPais: 'xPais',
	xPag: 'xPag',
	xProd: 'xProd',
	xContato: 'xContato',
	verProc: 'verProc',
};

export const productTags = {
	cProd: 'cProd',
	xProd: 'xProd',
	NCM: 'NCM',
	orig: 'orig',
	'ICMS>ICMS61>CST': 'ICMS61CST',
	CFOP: 'CFOP',
	uCom: 'uCom',
	qCom: 'qCom',
	vUnCom: 'vUnCom',
	vProd: 'vProd',
	vDesc: 'vDesc',
	vBC: 'vBC',
	vICMS: 'vICMS',
	vIPI: 'vIPI',
	pICMS: 'pICMS',
	pIPI: 'pIPI',
};
