import React, { useCallback, useMemo, useState } from 'react';
import {
	Box,
	FormControl,
	Grid,
	TextField,
	Typography,
	List,
	ListItem,
	ListItemText,
	Card,
	CardContent,
	CardActions,
	IconButton,
	Stack,
} from '@mui/material';
import { Add, Remove, Close } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { ISaleOrder, ISaleOrderItem, ISaleOrderItemExtended } from '../../../containers/SaleOrder/SaleOrderAssets';
import { IProductBusinessPartnerDetail, ITopSellingProduct } from '../../../containers/BusinessPartner/BusinessPartnerAssets';
import Autocomplete from '../../Common/Form/Autocomplete';
import { getBase64Image } from '../../../helpers/Utils';
import notImage from '../../../assets/not_found_image.jpg';
import { ItemType } from '../../../enums/ItemType';
import { useConditionalSearch } from '../../../hooks/useConditionalSearchProps';

interface StepProductsProps {
	getProductBusinessPartnerDetails: (businessPartnerId: string, description?: string) => void;
	productsBusinessPartner: IProductBusinessPartnerDetail[];
	topSellingProducts: ITopSellingProduct[];
}

const StepProducts = ({
	getProductBusinessPartnerDetails,
	productsBusinessPartner,
	topSellingProducts,
}: StepProductsProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<ISaleOrder>();
	const [search, setSearch] = useState('');

	const searchProducts = useCallback((description: string) => {
		if (values.clientId) {
			getProductBusinessPartnerDetails(values.clientId, description);
		}
	}, [values.clientId, getProductBusinessPartnerDetails]);

	useConditionalSearch({
		inputValue: search,
		loadedItems: productsBusinessPartner,
		searchFunction: searchProducts,
		minLength: 3,
		compareKey: 'description',
	});

	const selectedProducts = useMemo(() => values.saleOrderItems || [], [values.saleOrderItems]);

	const mostSoldProducts = useMemo(() => topSellingProducts.filter(
		(product: any) => !selectedProducts.some((sProduct) => sProduct.id === product.product.id),
	), [topSellingProducts, selectedProducts]);

	const productsFilter = useMemo(() => productsBusinessPartner.filter(
		(product) => !selectedProducts.some((sProduct: ISaleOrderItem) => sProduct.productId
			=== product.id),
	), [selectedProducts, productsBusinessPartner]);

	const handleChange = useCallback((event, product, reason) => {
		if (reason === 'selectOption' && product !== null) {
			const productId = product;
			let productToAdd = productsBusinessPartner.find((productBP) => productBP.id === productId);

			if (!productToAdd) {
				const topSellingProduct = topSellingProducts.find((
					productBP: ITopSellingProduct,
				) => productBP.product.id === productId);
				if (topSellingProduct) {
					productToAdd = topSellingProduct.product;
				}
			}

			if (productToAdd) {
				const newProduct: ISaleOrderItemExtended = {
					id: productToAdd.id,
					price: 0,
					originalPrice: 0,
					quantity: 1,
					total: 0,
					discount: 0,
					totalDiscount: 0,
					totalWithDiscount: 0,
					totalWithoutDiscount: 0,
					commission: 0,
					commissionValue: 0,
					deliveryDate: new Date().toISOString(),
					description: productToAdd.description,
					clientOrder: '',
					type: ItemType.SALE,
					productId: productToAdd.id,
					imageB64: productToAdd.imageB64,
				};
				setFieldValue('saleOrderItems', [...selectedProducts, newProduct]);
			}
		} else if (reason === 'input') {
			setSearch(event.target.value);
		}
	}, [productsBusinessPartner, topSellingProducts, setFieldValue, selectedProducts]);

	const handleRemoveFromCart = useCallback((index: number) => {
		const updatedProducts = [...selectedProducts];
		updatedProducts.splice(index, 1);
		setFieldValue('saleOrderItems', updatedProducts);
	}, [selectedProducts, setFieldValue]);

	const handleQuantityChange = useCallback((value: number, index: number) => {
		if (value === 0) {
			handleRemoveFromCart(index);
		} else {
			const updatedProducts = [...selectedProducts];
			const product = updatedProducts[index];
			product.quantity = value;
			product.total = product.price * value;
			product.totalDiscount = product.total - product.discount;
			setFieldValue('saleOrderItems', updatedProducts);
		}
	}, [selectedProducts, handleRemoveFromCart, setFieldValue]);

	return (
		<Box sx={{ paddingY: 4, marginTop: 4 }}>
			<Grid container spacing={3}>

				<Grid item xs={12} md={9} height="100%">
					<Typography variant="h6" gutterBottom>
						Lista de Produtos
					</Typography>
					<FormControl fullWidth>
						<Autocomplete
							label="Digite o código ou a descrição do produto"
							name="productsBusinessPartner"
							options={productsFilter}
							labelKey="description"
							valueKey="id"
							valueLabel="code"
							onInputChange={(event, newValue, reason) => handleChange(event, newValue, reason)}
							onChange={(event, newValue, reason) => handleChange(event, newValue, reason)}
						/>
					</FormControl>
					<Grid container spacing={3} sx={{ marginTop: 1 }}>
						{selectedProducts.length === 0 && (
							<Stack
								width="100%"
								direction="column"
								alignItems="center"
								sx={{ marginTop: 8 }}
							>
								<Typography variant="h6">Carrinho vazio</Typography>
								<Typography>
									Use o campo de busca para adicionar um produto.
								</Typography>
							</Stack>
						)}
						{selectedProducts.map((product, index) => (
							<Grid item xs={12} sm={6} md={3} key={product.productId}>
								<Card sx={{ height: 310 }}>
									<Box sx={{
										position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center',
									}}
									>
										<IconButton
											onClick={() => handleRemoveFromCart(index)}
											sx={{
												position: 'absolute',
												top: 0,
												right: 0,
												margin: 1,
												backgroundColor: 'white',
												'&:hover': {
													backgroundColor: 'white',
												},
												'&:active': {
													backgroundColor: 'white',
												},
											}}
											size="small"
										>
											<Close />
										</IconButton>

										<img
											src={product.imageB64
												? getBase64Image(product.imageB64) : notImage}
											alt={product.description}
											style={{ marginRight: '10px' }}
											width={200}
											height={200}
										/>
									</Box>
									<CardContent style={{ textAlign: 'center', padding: 0 }}>
										<Typography gutterBottom>
											{product.description}
										</Typography>
									</CardContent>
									<CardActions style={{ justifyContent: 'center' }}>
										<Box display="flex" alignItems="center">
											<IconButton onClick={() => handleQuantityChange(product.quantity - 1, index)} size="small">
												<Remove />
											</IconButton>
											<TextField
												value={product.quantity}
												onChange={
													(e) => handleQuantityChange(parseInt(e.target.value, 10), index)
												}
												style={{ width: 50, textAlign: 'center' }}
												inputProps={{ min: 0, style: { textAlign: 'center' } }}
												size="small"
											/>
											<IconButton onClick={() => handleQuantityChange(product.quantity + 1, index)} size="small">
												<Add />
											</IconButton>
										</Box>
									</CardActions>
								</Card>
							</Grid>
						))}
					</Grid>
				</Grid>
				<Grid item xs={12} md={3}>
					<Typography variant="h6" gutterBottom>
						Itens mais vendidos
					</Typography>
					<List>
						{mostSoldProducts.map((product: any) => (
							<ListItem
								key={product.product.id}
								onClick={() => handleChange(null, product.product.id, 'selectOption')}
								sx={{ cursor: 'pointer' }}
							>
								<img
									src={product.product.imageB64
										? getBase64Image(product.product.imageB64) : notImage}
									alt={product.product.description}
									style={{ marginRight: '10px' }}
									width={50}
								/>
								<ListItemText
									primary={product.product.description}
								/>
							</ListItem>
						))}
					</List>
				</Grid>
			</Grid>
		</Box>
	);
};

export default StepProducts;
