import React, { useMemo } from 'react';
import {
	styled, useTheme, Theme, SxProps,
} from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PieChartIcon from '@mui/icons-material/PieChart';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import DrawerHeader from './DrawerHeader';
import { drawerWidth } from '../../constants/menu';
import { getMenu } from '../../services/app';
import { IMenu } from '../../interfaces/Module';
import { hasPermission } from '../../helpers/permission';

interface DrawerMenuProps {
	open: boolean;
	toggleDrawer(): void;
}

interface Mixin {
	width: number | string;
	transition: string;
	overflowX: string;
}

interface ClosedMixin extends Mixin {
	[x: string]: string | {
		width: string;
	};
	transition: string;
	overflowX: string;
	width: string;
}

const openedMixin = (theme: Theme): Mixin => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): ClosedMixin => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: any) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const sxListItemButton: SxProps<Theme> = {
	minHeight: 48,
	px: 2.5,
	'& > *': {
		transition: '200ms ease all',
	},
	justifyContent: 'initial',
	'&:hover > *:not(.MuiTouchRipple-root)': {
		transform: 'translateX(10px)',
	},
};

const sxClosedListItemButton: SxProps<Theme> = {
	...sxListItemButton,
	justifyContent: 'center',
	'&:hover > *:not(.MuiTouchRipple-root)': {
		transform: 'translateX(2.5px)',
	},
};

const DrawerMenu = ({ open, toggleDrawer }: DrawerMenuProps): JSX.Element => {
	const theme = useTheme();
	const location = useLocation();

	const menu = useMemo(() => getMenu().map((module: IMenu) => (
		<ListItemButton
			key={module.name}
			sx={open ? sxListItemButton : sxClosedListItemButton}
			component={Link}
			to={module.link}
			selected={location.pathname.split('/')[1] === module.link}
		>
			<ListItemIcon
				sx={{
					minWidth: 0,
					mr: open ? 3 : 'auto',
					justifyContent: 'center',
				}}
			>
				<Icon>{module.icon}</Icon>
			</ListItemIcon>
			<ListItemText primary={module.name} sx={{ opacity: open ? 1 : 0 }} />
		</ListItemButton>
	)), [location.pathname, open]);

	return (
		<Drawer variant="permanent" open={open}>
			<DrawerHeader>
				<IconButton onClick={toggleDrawer}>
					{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
				</IconButton>
			</DrawerHeader>
			<Divider />
			<List component="nav">
				{menu}
				{ (hasPermission({ module: 'BUDGET' }) || hasPermission({ module: 'ORDER' })) && (
					<ListItemButton
						sx={open ? sxListItemButton : sxClosedListItemButton}
						component={Link}
						to="/order/apportionment"
						selected={location.pathname.includes('/order/apportionment')}
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: open ? 3 : 'auto',
								justifyContent: 'center',
							}}
						>
							<PieChartIcon />
						</ListItemIcon>
						<ListItemText primary="Gerenciar Rateios" sx={{ opacity: open ? 1 : 0 }} />
					</ListItemButton>
				)}
			</List>
		</Drawer>
	);
};

export default DrawerMenu;
