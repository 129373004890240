import axios, { AxiosResponse } from 'axios';
import api from './api';
import { IAccessGroup, EditAccessGroup } from '../containers/AccessGroup/AccessGroupAssets';

export const saveAccessGroup = (data: EditAccessGroup): Promise<AxiosResponse<any>> => api.post<any, AxiosResponse<any>>('/access-group', data);

export const getAccessGroups = (): Promise<AxiosResponse<any>> => api.get<any>('/access-group');

export const getAccessGroupById = (id: string): Promise<AxiosResponse<IAccessGroup>> => api.get<IAccessGroup>(`/access-group/${id}`);

export const updateAccessGroup = (id: string, data: EditAccessGroup): Promise<AxiosResponse<any>> => api.patch<any, AxiosResponse<any>>(`/access-group/${id}`, data);

export const deleteAccessGroup = (id: string): Promise<AxiosResponse<any>> => api.delete<any, AxiosResponse<any>>(`/access-group/${id}`);
