import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Edit, Cancel, CheckCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

interface ProviderClientTableProps {
	loading: boolean;
	rowsPerPage: number;
	page: number;
	rows: any[];
	approve: (id: string) => void;
}

const columns: GridColDef[] = [
	// { field: 'companyName', headerName: 'Nome', width: 130 },
	// { field: 'approved', headerName: 'Aprovado', width: 130 },
	// { field: 'id', headerName: 'Ações', width: 70 },
	{ field: 'companyName', headerName: 'Nome', flex: 2 },
	{ field: 'approved', headerName: 'Aprovado', flex: 1 },
	{ field: 'id', headerName: 'Ações', flex: 1 },
];

const ProviderClientTable = ({
	loading, rowsPerPage, page, rows, approve,
}: ProviderClientTableProps): JSX.Element => {
	const emptyRows =		page > 0
		? Math.max(0, (1 + page) * rowsPerPage - (rows ? rows.length : 0))
		: 0;

	const approveProxy = React.useCallback(
		(id) => {
			approve(id);
		},
		[approve],
	);

	return (
		<Box component={Paper} sx={{ width: '100%' }}>
			<DataGrid
				autoHeight
				rows={rows}
				pagination
				columns={columns}
				pageSize={5}
				rowsPerPageOptions={[5]}
				loading={loading}
			/>
		</Box>
		// <TableContainer component={Paper}>
		//     <Table>
		//         <TableHead>
		//             <TableRow>
		//                 <TableCell component="th">Nome</TableCell>
		//                 <TableCell component="th">Aprovado</TableCell>
		//                 <TableCell component="th">Ações</TableCell>
		//             </TableRow>
		//         </TableHead>
		//         <TableBody>
		//             {(rowsPerPage > 0
		//                 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		//                 : rows
		//             ).map((row) => (
		//                 <TableRow key={row.id}>
		//                     <TableCell style={{ width: 160 }}>
		//                         {row.companyName}
		//                     </TableCell>
		//                     <TableCell style={{ width: 160 }}>
		//                         {row.approved ? <CheckCircle /> : <Cancel />}
		//                     </TableCell>
		//                     <TableCell style={{ width: 160, display: 'flex' }}>
		//                         { !row.approved && <Button
		// 							variant="contained"
		// 							color="primary"
		// 							type="submit"
		// 							fullWidth
		// 							onClick={() => approveProxy(row.id)}
		// 						>
		// 							Aprovar
		// 						</Button>}
		//                         <IconButton
		// 							color="secondary"
		// 							aria-label="edit"
		// 							component={Link}
		// 							size="small"
		// 							to={`provider/edit/${row.id}`}
		// 						>
		//                             <Edit />
		//                         </IconButton>
		//                     </TableCell>
		//                 </TableRow>
		//             ))}
		//             {emptyRows > 0 && (
		//                 <TableRow style={{ height: 53 * emptyRows }}>
		//                     <TableCell colSpan={6} />
		//                 </TableRow>
		//             )}
		//         </TableBody>
		//     </Table>
		// </TableContainer>
	);
};

export default ProviderClientTable;
