import React, { useEffect } from 'react';
import {
	Theme, SxProps,
} from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import usePermission from '../../../hooks/usePermission';
import { ICompany } from '../../../containers/Company/CompanyAssets';
import { Loading } from '../../Common/Loading';

const contentStyle: SxProps<Theme> = {
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	height: '100vh',
};

interface BusinessIntelligenceProps {
	loading: boolean
	getCompanies(): void;
	companies: ICompany[];
}

const BusinessIntelligence = ({
	loading,
	getCompanies,
	companies,
}: BusinessIntelligenceProps): JSX.Element => {
	useEffect(() => {
		getCompanies();
	}, [getCompanies]);

	// let iframeSrc = 'https://lookerstudio.google.com/embed/reporting/cb9b8788-e538-46dc-bcca-749e49a533dd/page/p_s8co0s7rdd';
	// if (saleOrderPermission?.isAdmin) {
	// 	iframeSrc = 'https://lookerstudio.google.com/embed/reporting/f2bb6c6f-88d1-477d-8aa3-25e374c3607e/page/FpP5D';
	// }

	if (loading) {
		return <Loading />;
	}

	return (
		<Box sx={contentStyle}>
			<Stack direction="column" height="90%">
				{companies[0]?.urlBI ? (
					<iframe
						title="Business Intelligence"
						src={companies[0].urlBI}
						width="75%"
						height="100%"
						allowFullScreen
						style={{ border: 'none' }}
						sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
					/>
				) : <Typography>Não há URL de BI cadastrada.</Typography>}
			</Stack>

		</Box>
	);
};

export default BusinessIntelligence;
