/* eslint-disable react/jsx-props-no-spreading */
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import InventoryPresentational from '../../../components/Mobile/Inventory/InventoryDetails/InventoryDetails';
import {
	getInventoryById,
} from '../../../services/inventory';
import { ValidateInventoryParams } from './InventoryAssets';
import { InventoryTaskDetails } from '../../Inventory/InventoryAssets';

enum ActionType {
	LOADING,
	INVENTORY_DETAILS
}

interface IState {
	loading: boolean;
	countNumber: number;
	inventoryTask: InventoryTaskDetails | null;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.INVENTORY_DETAILS; payload: {
		inventoryTask: InventoryTaskDetails
		countNumber: number
	} }

interface IInventoryActions {
	setLoading(loading: boolean): void;
	getInventoryById(id: string, params: any): void;
	handleValidateInventory(params: ValidateInventoryParams): void;
}

const initialState: IState = {
	loading: false,
	countNumber: 0,
	inventoryTask: {
		id: '',
		product: {
			barCode: '',
			code: '',
			description: '',
		},
		locationOrigin: {
			barCode: '',
			name: '',
		},
	},
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.INVENTORY_DETAILS:
			return {
				...state,
				inventoryTask: action.payload.inventoryTask,
				countNumber: action.payload.countNumber,
			};
		default:
			throw new Error();
	}
};

const InventoryActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IInventoryActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getInventoryById(id: string, params: any) {
			actions.setLoading(true);
			getInventoryById(id, params).then((response) => {
				if (!response.data.inventoryTask?.id) {
					enqueueSnackbar(response.data.info, {
						variant: 'info',
						autoHideDuration: 6000,
					});
					navigate('/app/inventory');
					return;
				}
				dispatch({
					type: ActionType.INVENTORY_DETAILS,
					payload: {
						inventoryTask: response.data.inventoryTask,
						countNumber: response.data.countNumber,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
					autoHideDuration: 6000,
				});
				navigate('/app/inventory');
			}).finally(() => actions.setLoading(false));
		},

		handleValidateInventory(params: ValidateInventoryParams) {
			navigate('finish', { state: params });
		},
	};

	return actions;
};

const InventoryMobileDetails = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const actions = useMemo(
		() => InventoryActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	return (
		<InventoryPresentational
			{...state}
			{...actions}
		/>
	);
};

export default InventoryMobileDetails;
