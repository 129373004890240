import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
	DataGrid,
	GridActionsCellItem,
	GridColDef,
	GridRenderCellParams,
	GridValueFormatterParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ICompany } from '../../containers/Company/CompanyAssets';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';

interface CompanyTableProps {
	loading?: boolean;
	rows: ICompany[];
	handleDeleteCompany: (id: string) => void;
}

interface CustomValueFormatterParams extends GridValueFormatterParams<unknown> {
	value: number | string;
}

const CompanyTable: React.FC<CompanyTableProps> = ({ rows, loading, handleDeleteCompany }) => {
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const columns = useMemo<GridColDef[]>(() => [
		{ field: 'code', headerName: 'Código', flex: 1 },
		{ field: 'name', headerName: 'Empresa', flex: 1.5 },
		{
			field: 'createdAt',
			headerName: 'Adicionado em',
			flex: 1,
			valueFormatter: (params: CustomValueFormatterParams) => {
				const date = new Date(params.value);
				return date.toLocaleString('pt-BR');
			},
		},
		{
			field: 'actions',
			headerName: 'Ações',
			width: 120,
			renderCell: (params: GridRenderCellParams) => (
				<>
					<Link to={`edit/${params.id}`}>
						<GridActionsCellItem
							icon={<EditIcon />}
							label="Editar"
							color="primary"
						/>
					</Link>
					<GridActionsCellItem
						icon={<DeleteIcon />}
						label="Excluir"
						color="error"
						onClick={() => requestConfirm({
							description: 'Tem certeza que deseja excluir a empresa selecionada?',
							callback: () => handleDeleteCompany(params.id.toString()),
						})}
					/>
				</>
			),
		},
	], [handleDeleteCompany, requestConfirm]);

	return (
		<>
			<Box component={Paper} sx={{ width: '100%' }}>
				{rows && (
					<DataGrid
						autoHeight
						rows={rows}
						pagination
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[10]}
						loading={loading}
						disableSelectionOnClick
					/>
				)}
			</Box>
			{confirmationDialog}
		</>
	);
};

CompanyTable.defaultProps = {
	loading: false,
};

export default CompanyTable;
