import { format } from 'date-fns';
import { ERPClockInOut, HourProject } from './ApportionmentAssets';
import { ErpClockInOutsMap } from './ClockHoursAssets';

export const getErpClockInOutsMap = (data: ERPClockInOut[]): ErpClockInOutsMap => {
	const erpClockInOuts = new Map();

	if (data.length === 0) {
		return {
			erpClockInOuts,
			erpClockInOutsCount: 0,
		};
	}

	data.forEach((clock) => {
		const { dateTime, type } = clock;
		const localDate = new Date(dateTime);
		const date = format(localDate, 'dd/MM/yyyy');
		const hour = format(localDate, 'HH:mm');

		if (erpClockInOuts.has(date)) {
			erpClockInOuts.get(date).push({ hour, type });
		} else {
			erpClockInOuts.set(date, [{ hour, type }]);
		}
	});

	const erpClockInOutsCount = Array.from(erpClockInOuts.values())
		.sort((a, b) => b.length - a.length)[0].length;

	return {
		erpClockInOuts,
		erpClockInOutsCount,
	};
};

export const getTaskClockInOutIds = (projects: HourProject[]): string[] => {
	const taskClockInOutIds = projects.flatMap((project) => project.tasks.flatMap(
		(task) => task.tasksClockInOut.flatMap((clockInOut) => (clockInOut.id ? [clockInOut.id] : [])),
	));

	return taskClockInOutIds;
};
