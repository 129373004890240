import React, {
	useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Loading } from '../../../Common/Loading';
import InventoryNotFirst from './NotFirstCount/InventoryNotFirst';
import InventoryFirst from './FirstCount/InventoryStartFirst';
import { ValidateInventoryParams } from '../../../../containers/Mobile/Inventory/InventoryAssets';
import { InventoryTaskDetails } from '../../../../containers/Inventory/InventoryAssets';

interface InventoryMobileProps {
	loading: boolean
	getInventoryById(id: string, params: any): void;
	handleValidateInventory(params: ValidateInventoryParams): void;
	countNumber: number;
	inventoryTask: InventoryTaskDetails | null;
}

const Inventory = ({
	loading,
	getInventoryById,
	countNumber,
	inventoryTask,
	handleValidateInventory,
}: InventoryMobileProps):JSX.Element => {
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getInventoryById(id, { skip: 0 });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	if (loading) {
		return (
			<Box padding={1}>
				<Loading />
			</Box>
		);
	}

	return (
		<Box>
			{countNumber === 1
				? (
					<InventoryFirst
						handleValidateInventory={handleValidateInventory}
						countNumber={countNumber}
					/>
				)
				: (
					<InventoryNotFirst
						inventoryTask={inventoryTask}
						countNumber={countNumber}
						handleValidateInventory={handleValidateInventory}
					/>
				)}
		</Box>
	);
};

export default Inventory;
