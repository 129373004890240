import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import SalePriceFormationEditPresentational from '../../components/SalePriceFormation/SalePriceFormationEdit';
import {
	createAveragePaymentTerm,
	createIndirectCost,
	createRangeCommission,
	createSalePriceFormation,
	getAveragePaymentTerms,
	getCostBaseMonths,
	getIndirectCosts,
	getRangeCommissions,
	getSalePriceFormationById,
	removeAveragePaymentTermFromFormation,
	removeRangeCommissionFromFormation,
	updateCostBaseMonth,
	updateSalePriceFormation,
	upsertAveragePaymentTermToFormation,
	upsertRangeCommissionToFormation,
} from '../../services/salePriceFormation';
import {
	IAveragePaymentTerm,
	IAveragePaymentTermSalePriceFormation,
	ICostBaseMonth,
	ICreateAveragePaymentTerm,
	ICreateIndirectCost,
	ICreateRangeCommission,
	IIndirectCost,
	IRangeCommission,
	IRangeCommissionSalePriceFormation,
	ISalePriceFormation,
	ISalePriceFormationItem,
	IUpdateSalePriceFormationDto,
} from './SalePriceFormationAssets';

enum ActionType {
	LOADING,
	SALE_PRICE_FORMATION,
	COST_BASE_MONTHS,
	INDIRECT_COSTS,
	AVERAGE_PAYMENT_TERMS,
	RANGE_COMMISSION,
	ITEMS,
	AVERAGE_COST,
	AVERAGE_PAYMENT_TERM_SALE_PRICE_FORMATIONS,
	RANGE_COMMISSION_SALE_PRICE_FORMATIONS,
}

export interface IState {
	loading: boolean;
	salePriceFormation?: ISalePriceFormation;
	costBaseMonths?: ICostBaseMonth[];
	indirectCosts?: IIndirectCost[];
	averagePaymentTerms?: IAveragePaymentTerm[];
	rangeCommissions?: IRangeCommission[];
	items?: ISalePriceFormationItem[];
	averageCost?: number;
	averagePaymentTermSalePriceFormations?: IAveragePaymentTermSalePriceFormation[];
	rangeCommissionSalePriceFormations?: IRangeCommissionSalePriceFormation[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.SALE_PRICE_FORMATION; payload: { salePriceFormation: ISalePriceFormation } }
	| { type: ActionType.COST_BASE_MONTHS; payload: { costBaseMonths: ICostBaseMonth[] } }
	| { type: ActionType.INDIRECT_COSTS; payload: { indirectCosts: IIndirectCost[] } }
	| {
		type: ActionType.AVERAGE_PAYMENT_TERMS; payload: {
			averagePaymentTerms: IAveragePaymentTerm[],
		}
	}
	| {
		type: ActionType.RANGE_COMMISSION; payload: {
			rangeCommissions: IRangeCommission[],
		}
	}
	| { type: ActionType.ITEMS; payload: { items: ISalePriceFormationItem[] } }
	| { type: ActionType.AVERAGE_COST; payload: { averageCost: number } }
	| {
		type: ActionType.AVERAGE_PAYMENT_TERM_SALE_PRICE_FORMATIONS;
		payload: { averagePaymentTermSalePriceFormations: IAveragePaymentTermSalePriceFormation[] }
	}
	| {
		type: ActionType.RANGE_COMMISSION_SALE_PRICE_FORMATIONS;
		payload: { rangeCommissionSalePriceFormations: IRangeCommissionSalePriceFormation[] }
	};

export interface ISalePriceFormationEditActions {
	setLoading(loading: boolean): void;
	getSalePriceFormationById(id: string, costBaseMonth?: Date): void;
	getCostBaseMonths(): void;
	getIndirectCosts(): void;
	getAveragePaymentTerms(): void;
	getRangeCommissions(): void;
	handleUpdate(id: string, data: IUpdateSalePriceFormationDto): void;
	handleUpdateCostBaseMonth(id: string, costBaseMonth: Date): void;
	createIndirectCost(data: ICreateIndirectCost): void;
	createAveragePaymentTerm(data: ICreateAveragePaymentTerm): void;
	createRangeCommission(data: ICreateRangeCommission): void;
	upsertAveragePaymentTermToFormation(data: IAveragePaymentTermSalePriceFormation): void;
	removeAveragePaymentTermFromFormation(id: string): void;
	upsertRangeCommissionToFormation(data: IRangeCommissionSalePriceFormation): void;
	removeRangeCommissionFromFormation(rangeCommissionId: string, salePriceFormationId: string): void;
	handleEditSalePriceFormation(data: ISalePriceFormation, id?: string): void;
}

const initialState: IState = {
	loading: false,
	salePriceFormation: undefined,
	costBaseMonths: undefined,
	indirectCosts: undefined,
	averagePaymentTerms: undefined,
	rangeCommissions: undefined,
	items: undefined,
	averageCost: undefined,
	averagePaymentTermSalePriceFormations: undefined,
	rangeCommissionSalePriceFormations: undefined,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.SALE_PRICE_FORMATION:
			return {
				...state,
				salePriceFormation: action.payload.salePriceFormation,
			};
		case ActionType.COST_BASE_MONTHS:
			return {
				...state,
				costBaseMonths: action.payload.costBaseMonths,
			};
		case ActionType.INDIRECT_COSTS:
			return {
				...state,
				indirectCosts: action.payload.indirectCosts,
			};
		case ActionType.AVERAGE_PAYMENT_TERMS:
			return {
				...state,
				averagePaymentTerms: action.payload.averagePaymentTerms,
			};
		case ActionType.RANGE_COMMISSION:
			return {
				...state,
				rangeCommissions: action.payload.rangeCommissions,
			};
		case ActionType.ITEMS:
			return {
				...state,
				items: action.payload.items,
			};
		case ActionType.AVERAGE_COST:
			return {
				...state,
				averageCost: action.payload.averageCost,
			};
		case ActionType.AVERAGE_PAYMENT_TERM_SALE_PRICE_FORMATIONS:
			return {
				...state,
				averagePaymentTermSalePriceFormations: action.payload.averagePaymentTermSalePriceFormations,
			};
		case ActionType.RANGE_COMMISSION_SALE_PRICE_FORMATIONS:
			return {
				...state,
				rangeCommissionSalePriceFormations: action.payload.rangeCommissionSalePriceFormations,
			};
		default:
			throw new Error();
	}
};

const SalePriceFormationEditActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): ISalePriceFormationEditActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getSalePriceFormationById(id: string, costBaseMonth?: Date) {
			actions.setLoading(true);
			getSalePriceFormationById(id, costBaseMonth).then((response) => {
				dispatch({
					type: ActionType.SALE_PRICE_FORMATION,
					payload: {
						salePriceFormation: response.data,
					},
				});
				actions.setLoading(false);
			});
		},
		handleUpdate(id: string, data: IUpdateSalePriceFormationDto) {
			actions.setLoading(true);
			updateSalePriceFormation(id, data)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
					navigate('/sale-price-formation');
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		handleUpdateCostBaseMonth(id: string, costBaseMonth: Date) {
			actions.setLoading(true);
			updateCostBaseMonth(id, costBaseMonth)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
					dispatch({
						type: ActionType.ITEMS,
						payload: {
							items: response.data.data.items,
						},
					});
					dispatch({
						type: ActionType.AVERAGE_COST,
						payload: {
							averageCost: response.data.data.averageCost,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		getCostBaseMonths() {
			actions.setLoading(true);
			getCostBaseMonths().then((response) => {
				dispatch({
					type: ActionType.COST_BASE_MONTHS,
					payload: {
						costBaseMonths: response.data,
					},
				});
				actions.setLoading(false);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
				actions.setLoading(false);
			});
		},
		getIndirectCosts() {
			actions.setLoading(true);
			getIndirectCosts().then((response) => {
				dispatch({
					type: ActionType.INDIRECT_COSTS,
					payload: {
						indirectCosts: response.data,
					},
				});
				actions.setLoading(false);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
				actions.setLoading(false);
			});
		},
		createIndirectCost(data: IIndirectCost) {
			actions.setLoading(true);
			createIndirectCost(data)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
					actions.getIndirectCosts();
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		getAveragePaymentTerms() {
			actions.setLoading(true);
			getAveragePaymentTerms().then((response) => {
				dispatch({
					type: ActionType.AVERAGE_PAYMENT_TERMS,
					payload: {
						averagePaymentTerms: response.data,
					},
				});
				actions.setLoading(false);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
				actions.setLoading(false);
			});
		},
		createAveragePaymentTerm(data: IAveragePaymentTerm) {
			actions.setLoading(true);
			createAveragePaymentTerm(data)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
					actions.getAveragePaymentTerms();
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		getRangeCommissions() {
			actions.setLoading(true);
			getRangeCommissions().then((response) => {
				dispatch({
					type: ActionType.RANGE_COMMISSION,
					payload: {
						rangeCommissions: response.data,
					},
				});
				actions.setLoading(false);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
				actions.setLoading(false);
			});
		},
		createRangeCommission(data: IRangeCommission) {
			actions.setLoading(true);
			createRangeCommission(data)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
					actions.getRangeCommissions();
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		upsertAveragePaymentTermToFormation(data: IAveragePaymentTermSalePriceFormation) {
			actions.setLoading(true);
			upsertAveragePaymentTermToFormation(data)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					dispatch({
						type: ActionType.AVERAGE_PAYMENT_TERM_SALE_PRICE_FORMATIONS,
						payload: {
							averagePaymentTermSalePriceFormations: response.data.data,
						},
					});
				})
				.catch((error) => {
					enqueueSnackbar(error.response?.data.message || 'Erro ao adicionar termo de pagamento médio.', { variant: 'error' });
				})
				.finally(() => actions.setLoading(false));
		},
		removeAveragePaymentTermFromFormation(id: string) {
			actions.setLoading(true);
			removeAveragePaymentTermFromFormation(id)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					dispatch({
						type: ActionType.AVERAGE_PAYMENT_TERM_SALE_PRICE_FORMATIONS,
						payload: {
							averagePaymentTermSalePriceFormations: response.data.data,
						},
					});
				})
				.catch((error) => {
					enqueueSnackbar(error.response?.data.message || 'Erro ao remover termo de pagamento médio.', { variant: 'error' });
				})
				.finally(() => actions.setLoading(false));
		},
		upsertRangeCommissionToFormation(data: IRangeCommissionSalePriceFormation) {
			actions.setLoading(true);
			upsertRangeCommissionToFormation(data)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					dispatch({
						type: ActionType.RANGE_COMMISSION_SALE_PRICE_FORMATIONS,
						payload: {
							rangeCommissionSalePriceFormations: response.data.data,
						},
					});
				})
				.catch((error) => {
					enqueueSnackbar(error.response?.data.message || 'Erro ao adicionar Faixa Comissão.', { variant: 'error' });
				})
				.finally(() => actions.setLoading(false));
		},
		removeRangeCommissionFromFormation(rangeCommissionId: string, salePriceFormationId: string) {
			actions.setLoading(true);
			removeRangeCommissionFromFormation(rangeCommissionId, salePriceFormationId)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					dispatch({
						type: ActionType.RANGE_COMMISSION_SALE_PRICE_FORMATIONS,
						payload: {
							rangeCommissionSalePriceFormations: response.data.data,
						},
					});
				})
				.catch((error) => {
					enqueueSnackbar(error.response?.data.message || 'Erro ao remover faixa comissão.', { variant: 'error' });
				})
				.finally(() => actions.setLoading(false));
		},
		handleEditSalePriceFormation(data: ISalePriceFormation, id?: string) {
			actions.setLoading(true);
			const request = id ? updateSalePriceFormation(id, data) : createSalePriceFormation(data);
			request
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });

					if (!id && response.data.data) {
						const salePriceFormationId = response.data.data;
						navigate(`/sale-price-formation/edit/${salePriceFormationId}`, { replace: true });
					} else if (id) {
						navigate('/sale-price-formation');
					}
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao salvar a formação de preço de venda.',
						{ variant: 'error' },
					);
				})
				.finally(() => actions.setLoading(false));
		},
	};

	return actions;
};

const SalePriceFormationEditContainer = (): JSX.Element | null => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => SalePriceFormationEditActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<SalePriceFormationEditPresentational {...state} {...actions} />
	);
};

export default SalePriceFormationEditContainer;
