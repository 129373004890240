import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSnackbar } from 'notistack';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

import Decimal from 'decimal.js';
import { Loading } from '../../Common/Loading';
import { PurchaseOrderProps } from './PurchaseOrder';
import { currencyBRLMask } from '../../../helpers/intl';
import PurchaseOrderApportionmentDialog from './PurchaseOrderApportionmentDialog';
import { IFilterFieldsValues } from '../../../interfaces/BudgetOrderFourFields';
import { BudgetFilter } from '../../../interfaces/Budget';
import { PurchaseOrderItem, PurchaseOrderItemApportionment } from '../../../containers/Order/PurchaseOrderAssets';

interface PurchaseOrderApportionmentProps extends PurchaseOrderProps {
	purchases: any[];
	purchase: any;
	filterFieldsValues: IFilterFieldsValues | null;
	budgets: any[];
	newBudget: any;
	getPurchase(purchases: any[], purchase: any): void;
	getFilterFieldsValues: () => void;
	sendFilter(filter: BudgetFilter): void;
	clearBudgets(): void;
	saveApportionments: (apportionments: any[], purchaseOrderItemId: string) => void;
	savePurchaseOrderApproval: (id: string) => void;
	duplicateBudget: (budgetId: string) => void;
}

interface RowProps {
	purchaseOrderItem: PurchaseOrderItem;
	hasInvoices: boolean;
	changedItems: Set<string>;
	apportionmentsEquals: boolean;
	openApportionmentModal: (purchaseOrderItemId: string) => void;
	removeApportionment: (purchaseOrderItemId: string, apportionmentIndex: number) => void;
	saveApportionments: (apportionments: any[], purchaseOrderItemId: string) => void;
	updateChangedItems: (itemId: string, action: 'add' | 'remove') => void;
	replicateApportionments: (
		apportionments: PurchaseOrderItemApportionment[],
		originItemId: string,
		shouldReplicate?: boolean,
	) => string[];
}

type ApportionmentType = PurchaseOrderItemApportionment[];

const generalDataSx: SxProps<Theme> = {
	p: 2,
	width: '100%',
};

const Row = (props: RowProps): JSX.Element => {
	const {
		purchaseOrderItem,
		hasInvoices,
		openApportionmentModal,
		removeApportionment,
		saveApportionments,
		updateChangedItems,
		replicateApportionments,
		apportionmentsEquals,
		changedItems,
	} = props;
	const [open, setOpen] = useState(false);
	const [apportionments, setApportionments] = useState<PurchaseOrderItemApportionment[]>([]);
	const [percentages, setPercentages] = useState<string[]>([]);

	useEffect(() => {
		const receivedApportionments = purchaseOrderItem.purchaseOrderItemApportionments || [];
		setApportionments(receivedApportionments);
		setPercentages(receivedApportionments.map(
			(currentApportionment: any) => currentApportionment.percentage,
		));
	}, [purchaseOrderItem]);

	const prorated = useMemo(
		() => percentages.reduce(
			(accumulator, current: any) => accumulator + (current ? Number.parseFloat(current) : 0),
			0,
		),
		[percentages],
	);

	const onChangePercentage = useCallback((index: number, value: string) => {
		const percentage = Number.parseFloat(value);

		if (percentage < 0 || percentage > 100) return;

		const updatedApportionments = [...apportionments];
		if (updatedApportionments[index]) {
			updatedApportionments[index] = {
				...updatedApportionments[index],
				percentage: Number.isNaN(percentage) ? '' : percentage.toString(),
			};
		}

		setApportionments(updatedApportionments);
		setPercentages(updatedApportionments.map((apportionment) => apportionment.percentage));

		if (!changedItems.has(purchaseOrderItem.id)) {
			updateChangedItems(purchaseOrderItem.id, 'add');
		}
	}, [apportionments, purchaseOrderItem.id, updateChangedItems, changedItems]);

	const allFieldsFilled = useMemo(() => apportionments.every(
		(apportionment) => apportionment.budget.id
			&& apportionment.percentage
			&& Number.parseFloat(apportionment.percentage) > 0,
	), [apportionments]);

	const isValidApportionment = useCallback(() => {
		const isTotal100 = prorated === 100;
		return isTotal100 && allFieldsFilled;
	}, [prorated, allFieldsFilled]);

	const apportionmentStatus = useMemo(() => {
		const isError = prorated !== 100 || !allFieldsFilled;
		const color = isError ? 'error' : 'inherit';

		const apportionedMessage = !allFieldsFilled ? 'Não pode haver rateio vazio ' : `% Rateada: ${prorated}`;
		const toApportionMessage = `% A Ratear: ${100 - prorated}`;

		return {
			color,
			apportionedMessage,
			toApportionMessage,
		};
	}, [prorated, allFieldsFilled]);

	const onSaveApportionments = useCallback(() => {
		const transformedApportionments = apportionments.map((apportionment) => ({
			budgetId: apportionment.budgetId || apportionment.budget.id,
			percentage: Number.parseFloat(apportionment.percentage),
		}));

		saveApportionments(transformedApportionments, purchaseOrderItem.id);
		replicateApportionments(apportionments, purchaseOrderItem.id, false);

		updateChangedItems(purchaseOrderItem.id.toString(), 'remove');
	}, [
		apportionments,
		purchaseOrderItem.id,
		replicateApportionments,
		saveApportionments,
		updateChangedItems,
	]);

	return (
		<>
			<TableRow
				sx={{
					'&:last-child td, &:last-child th': { border: 0 },
					'& td, & th': {
						fontWeight: 500,
					},
					backgroundColor: changedItems.has(purchaseOrderItem.id) ? 'rgba(255, 255, 0, 0.3)' : 'inherit',
				}}
			>
				<TableCell>{purchaseOrderItem.itemOrder}</TableCell>
				<TableCell>{purchaseOrderItem.productCode}</TableCell>
				<TableCell>{purchaseOrderItem.productDescription}</TableCell>
				<TableCell align="right">{Number(purchaseOrderItem.quantity).toLocaleString('pt-BR')}</TableCell>
				<TableCell align="right">{currencyBRLMask(purchaseOrderItem.unitValue)}</TableCell>
				<TableCell align="right">{currencyBRLMask(new Decimal(purchaseOrderItem.totalValue).plus(purchaseOrderItem.discountValue || 0))}</TableCell>
				<TableCell align="right">{currencyBRLMask(purchaseOrderItem.discountValue || 0)}</TableCell>
				<TableCell align="right">{currencyBRLMask(purchaseOrderItem.totalValue)}</TableCell>
				<TableCell>
					{changedItems.has(purchaseOrderItem.id) && (
						<Typography variant="caption" color="error">NÃO SALVO!</Typography>
					)}
				</TableCell>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
					<Collapse in={open} timeout="auto" unmountOnExit sx={{ ml: 12 }}>
						<Typography variant="h6" my={2}>Rateio</Typography>
						<Table size="small" aria-label="purchases">
							<TableHead>
								<TableRow>
									<TableCell>% Rateio</TableCell>
									<TableCell>Valor Rateado</TableCell>
									<TableCell>Conta Contábil</TableCell>
									<TableCell>Centro de Custo</TableCell>
									<TableCell>Filial de Origem</TableCell>
									<TableCell>Cliente/Fornecedor</TableCell>
									<TableCell align="center">
										<IconButton
											size="small"
											disabled={hasInvoices}
											onClick={() => openApportionmentModal(purchaseOrderItem.id)}
										>
											<AddIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{apportionments.map((apportionment: any, index: number) => (
									<TableRow key={apportionment.budget.id}>
										<TableCell>
											<InputBase
												autoFocus
												type="text"
												sx={{ width: 50, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
												value={apportionments[index]?.percentage || ''}
												onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
													onChangePercentage(index, event.target.value);
												}}
											/>
										</TableCell>
										<TableCell>
											{currencyBRLMask((apportionment.percentage / 100)
												* purchaseOrderItem.totalValue)}
										</TableCell>
										<TableCell>{apportionment.budget.accountingAccount.name}</TableCell>
										<TableCell>{apportionment.budget.costCenter.name}</TableCell>
										<TableCell>{apportionment.budget.accountingItem.name}</TableCell>
										<TableCell>{apportionment.budget.classValue.name}</TableCell>
										<TableCell align="center">
											<IconButton
												size="small"
												disabled={hasInvoices}
												tabIndex={-1}
												onClick={() => removeApportionment(purchaseOrderItem.id, index)}
											>
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography variant="subtitle1" my={2} color={apportionmentStatus.color} sx={{ fontWeight: 'bold', marginRight: '20px' }}>
								{apportionmentStatus.apportionedMessage}
							</Typography>
							<Typography variant="subtitle1" my={2} color={apportionmentStatus.color}>
								{apportionmentStatus.toApportionMessage}
							</Typography>
							<Box sx={{ flexGrow: 1 }} />
							<Button
								variant="outlined"
								color="primary"
								disabled={
									apportionmentsEquals
									|| changedItems.has(purchaseOrderItem.id)
									|| hasInvoices
								}
								onClick={() => replicateApportionments(apportionments, purchaseOrderItem.id)}
							>
								Replicar Rateios
							</Button>
							<Button
								variant="contained"
								color="primary"
								sx={{ marginLeft: '10px' }}
								disabled={
									!isValidApportionment()
									|| !changedItems.has(purchaseOrderItem.id)
									|| hasInvoices
								}
								onClick={onSaveApportionments}
							>
								Salvar rateios
							</Button>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

const PurchaseOrderApportionment = (props: PurchaseOrderApportionmentProps): JSX.Element => {
	const {
		loading,
		purchases,
		purchase,
		filterFieldsValues,
		budgets,
		newBudget,
		getPurchase,
		getFilterFieldsValues,
		sendFilter,
		clearBudgets,
		saveApportionments,
		savePurchaseOrderApproval,
		duplicateBudget,
	} = props;
	const { id } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const [purchaseOrderItemId, setPurchaseOrderItemId] = useState('');
	const [purchaseOrderItems, setPurchaseOrderItems] = useState<PurchaseOrderItem[]>([]);
	const [changedItems, setChangedItems] = useState<Set<string>>(new Set());

	useEffect(() => {
		getPurchase(purchases, id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		setPurchaseOrderItems(purchase?.purchaseOrderItems || []);
	}, [purchase?.purchaseOrderItems]);

	const updateChangedItems = useCallback((itemId: string, action: 'add' | 'remove') => {
		setChangedItems((prev) => {
			const newSet = new Set(prev);
			if (action === 'add') {
				newSet.add(itemId);
			} else if (action === 'remove') {
				newSet.delete(itemId);
			}
			return newSet;
		});
	}, []);

	const handleOpenApportionmentDialog = useCallback((selectedPurchaseOrderItemId: string) => {
		setPurchaseOrderItemId(selectedPurchaseOrderItemId);
	}, []);

	const handleCloseApportionmentDialog = useCallback((budget?: any) => {
		clearBudgets();
		setPurchaseOrderItemId('');

		if (budget) {
			const currentPurchaseOrderItems = [...purchaseOrderItems];
			const index = currentPurchaseOrderItems.findIndex((item) => item.id === purchaseOrderItemId);

			if (index !== -1) {
				const newPurchaseOrderItem = { ...currentPurchaseOrderItems[index] };
				newPurchaseOrderItem.purchaseOrderItemApportionments = [
					...newPurchaseOrderItem.purchaseOrderItemApportionments,
					{
						budgetId: budget.id,
						percentage: '',
						budget,
					},
				];
				currentPurchaseOrderItems[index] = newPurchaseOrderItem;

				updateChangedItems(purchaseOrderItemId, 'add');
			}

			setPurchaseOrderItems(currentPurchaseOrderItems);
		}
	}, [clearBudgets, purchaseOrderItems, purchaseOrderItemId, updateChangedItems]);

	const removeApportionment = useCallback((
		selectedPurchaseOrderItemId: string,
		apportionmentIndex: number,
	) => {
		const currentPurchaseOrderItems = purchaseOrderItems.map((item) => {
			if (item.id === selectedPurchaseOrderItemId && item.purchaseOrderItemApportionments) {
				const newApportionments = [
					...item.purchaseOrderItemApportionments.slice(0, apportionmentIndex),
					...item.purchaseOrderItemApportionments.slice(apportionmentIndex + 1),
				];
				return { ...item, purchaseOrderItemApportionments: newApportionments };
			}
			return item;
		});

		setPurchaseOrderItems(currentPurchaseOrderItems);
		updateChangedItems(selectedPurchaseOrderItemId, 'add');
	}, [purchaseOrderItems, updateChangedItems]);

	const handleReplicateApportionments = useCallback((
		newApportionments: ApportionmentType,
		originItemId: string,
		shouldReplicate = true,
	): string[] => {
		if (!shouldReplicate) {
			const updatedPurchaseOrderItems = purchaseOrderItems.map((item) => (item.id === originItemId
				? { ...item, purchaseOrderItemApportionments: newApportionments }
				: item));
			setPurchaseOrderItems(updatedPurchaseOrderItems);
			return [];
		}

		const changedIds: string[] = [];

		const updatedItems = purchaseOrderItems.map((item) => {
			if (item.id === originItemId) {
				return {
					...item,
					purchaseOrderItemApportionments: [...newApportionments],
				};
			}

			if (!isEqual(item.purchaseOrderItemApportionments, newApportionments)) {
				changedIds.push(item.id);
				return {
					...item,
					purchaseOrderItemApportionments: [...newApportionments],
				};
			}
			return item;
		});

		setPurchaseOrderItems(updatedItems);

		setChangedItems((prevChangedItems) => {
			const updatedSet = new Set(prevChangedItems);
			changedIds.forEach((changeId) => updatedSet.add(changeId));
			return updatedSet;
		});

		enqueueSnackbar(
			`Rateio replicado com sucesso para ${changedIds.length} item${changedIds.length > 1 ? 's' : ''} do pedido!
			Lembre-se de clicar em "Salvar Rateios" para Postar OC.`,
			{ variant: 'success' },
		);

		return changedIds;
	}, [enqueueSnackbar, purchaseOrderItems]);

	const hasApportionments = useMemo(() => purchaseOrderItems.some((purchaseOrderItem) => (
		purchaseOrderItem.purchaseOrderItemApportionments.length > 0
	)), [purchaseOrderItems]);

	const apportionmentsEquals = useMemo(() => {
		if (purchaseOrderItems.length <= 1) return true;

		const referenceApportionment = purchaseOrderItems[0].purchaseOrderItemApportionments;

		return purchaseOrderItems.every(
			(item) => isEqual(item.purchaseOrderItemApportionments, referenceApportionment),
		);
	}, [purchaseOrderItems]);

	const purchaseOrderItemEdit = useMemo(() => purchaseOrderItems.find(
		(item) => item.id === purchaseOrderItemId,
	), [purchaseOrderItems, purchaseOrderItemId]);

	return (
		<>
			<Typography variant="h5" my={2}>Dados do pedido</Typography>
			{loading && <Loading absolute />}
			{purchase && (
				<>
					<Card sx={{ minHeight: 125 }}>
						<CardContent>
							<Grid container spacing={3} sx={generalDataSx} alignItems="center">
								<Grid item xs={3}>
									<Box>
										<Typography variant="subtitle1" sx={{ fontWeight: 450 }}>Número</Typography>
										{purchase.nrOrder}
									</Box>
								</Grid>
								<Grid item xs={3}>
									<Typography variant="subtitle1" sx={{ fontWeight: 450 }}>Data de Emissão</Typography>
									<Box>{new Date(purchase.orderIssuance).toLocaleString('pt-BR')}</Box>
								</Grid>
								<Grid item xs={3}>
									<Typography variant="subtitle1" sx={{ fontWeight: 450 }}>Fornecedor</Typography>
									<Box>{purchase.providerName}</Box>
								</Grid>
								<Grid item xs={3}>
									<Typography variant="subtitle1" sx={{ fontWeight: 450 }}>Filial de Origem</Typography>
									<Box>{purchase.branch.name}</Box>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<Typography variant="h5" my={2}>Itens do pedido</Typography>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow sx={{ '& th': { fontSize: '1rem', fontWeight: 500 } }}>
									<TableCell>Item</TableCell>
									<TableCell>Produto</TableCell>
									<TableCell>Descrição</TableCell>
									<TableCell align="right">Quantidade</TableCell>
									<TableCell align="right">Valor Unitário</TableCell>
									<TableCell align="right">Valor Total</TableCell>
									<TableCell align="right">Valor Desconto</TableCell>
									<TableCell align="right">Valor Líquido</TableCell>
									<TableCell />
								</TableRow>
							</TableHead>
							<TableBody>
								{
									purchaseOrderItems
									&& purchaseOrderItems.map((purchaseOrderItem: any) => (
										<Row
											key={purchaseOrderItem.id}
											purchaseOrderItem={purchaseOrderItem}
											hasInvoices={purchase.hasInvoices}
											openApportionmentModal={handleOpenApportionmentDialog}
											removeApportionment={removeApportionment}
											saveApportionments={saveApportionments}
											updateChangedItems={updateChangedItems}
											replicateApportionments={handleReplicateApportionments}
											changedItems={changedItems}
											apportionmentsEquals={apportionmentsEquals}
										/>
									))
								}
							</TableBody>
						</Table>
					</TableContainer>
					<Button
						sx={{ mt: 2 }}
						variant="contained"
						color="primary"
						disabled={changedItems.size > 0 || !hasApportionments || purchase.hasInvoices}
						onClick={() => savePurchaseOrderApproval(purchase.id)}
					>
						Postar OC
					</Button>
					{purchase.hasInvoices && (
						<Typography variant="caption" mt={1} color="warning.main">
							Esse pedido já está classificado no protheus (Já existe uma nota fiscal para ele).
							Não é possível Postar a OC novamente, nem alterar os itens deste pedido.
						</Typography>
					)}
				</>
			)}
			<Typography variant="caption" mt={1}>
				<strong>Atenção: </strong>
				Caso já tenha criado pedidos para aprovação, ao clicar no botão de gerar novamente,
				os pedidos já criados serão apagados e criados novamente com status Ativo.
			</Typography>
			{purchaseOrderItemEdit && (
				<PurchaseOrderApportionmentDialog
					key={purchaseOrderItemEdit.id}
					loading={loading}
					filterFieldsValues={filterFieldsValues}
					isOpen={Boolean(purchaseOrderItemId)}
					budgets={budgets}
					purchaseOrderItemEdit={purchaseOrderItemEdit}
					newBudget={newBudget}
					getFilterFieldsValues={getFilterFieldsValues}
					sendFilter={sendFilter}
					handleClose={handleCloseApportionmentDialog}
					duplicateBudget={duplicateBudget}
				/>
			)}
		</>
	);
};

export default PurchaseOrderApportionment;
