import React from 'react';
import Autocomplete from '../../../Common/Form/Autocomplete';

type Props = {
  accountingAccounts: any[]
}

export const CorporativeLevelField = ({ accountingAccounts }: Props): JSX.Element => (
	<Autocomplete<{name: string; code: string;}>
		multiple
		limitTags={2}
		name="accountingAccounts"
		label="Conta Contábil"
		options={accountingAccounts}
		labelKey="name"
		valueKey="code"
		fullWidth
		sx={{ height: 'fit-content' }}
	/>
);
