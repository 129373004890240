import React, {
	useCallback, useEffect, useMemo, useState, ChangeEvent,
} from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { UserType } from '../../../containers/Order/PurchaseOrderApproval';
import { currencyBRLMask } from '../../../helpers/intl';
import { FinancialInput } from '../../Common/Form/Input';
import { debounce } from '../../../helpers/Utils';
import { filterInputs, filtersInitialValues } from '../../../helpers/BudgetValueTransferModalAssets';
import { IFilter } from '../../../interfaces/BudgetValueTransferModalInterfaces';
import { getFilteredBudgets } from '../../../helpers/BudgetValueTransferModalFilterHelper';
import { IValueTransferData } from '../../../interfaces/BudgetValueTransfer';

const steps = [
	'Selecione o orçamento que enviará o valor',
	'Selecione o orçamento que receberá o valor',
	'Informe o valor e confirme os dados',
];

const listItemStyle: SxProps<Theme> = {
	flex: '1 1',
};

const sxStepDefault: SxProps<Theme> = {
	overflowY: 'auto',
	flexBasis: '33.33%',
};

const sxConfirmData: SxProps<Theme> = {
	display: 'flex',
	alignItems: 'center',
	p: '10px',
	border: (theme) => `1px solid ${theme.palette.divider}`,
	borderRadius: 1,
	bgcolor: 'background.paper',
	'& svg': {
		mx: 0.5,
	},
	flex: '1 1',
};

const sxContent: SxProps<Theme> = {
	position: 'relative',
	height: '40rem',
	width: '100%',
	overflow: 'hidden',
	mt: 2,
};

const sxContentStepCommon: SxProps<Theme> = {
	position: 'absolute',
	width: '300%',
	height: '100%',
	transition: '1s all cubic-bezier(.17, .67, 0, 1.02)',
};

const sxContentStepOne: SxProps<Theme> = {
	...sxContentStepCommon,
	transform: 'translateX(0%)',
};

const sxContentStepTwo: SxProps<Theme> = {
	...sxContentStepCommon,
	transform: 'translateX(-33.33%)',
};

const sxContentStepThree: SxProps<Theme> = {
	...sxContentStepCommon,
	transform: 'translateX(-66.66%)',
};

const sxDialogTitle: SxProps<Theme> = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
};

const sxDialogActions: SxProps<Theme> = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
};

interface BudgetValueTransferModalProps {
    userType: UserType;
    showBudgetValueTransferModal: boolean;
	budgetsFrom: any[];
	budgetsTo: any[];
    budgetTo: string;
    closeBudgetValueTransferModal(event: any): void;
    sendValueTransfer(data: IValueTransferData): void;
	getBudgetsFrom(userType?: UserType): void;
	getBudgetsTo(userType?: UserType): void;
}

const BudgetValueTransferModal = ({
	userType,
	showBudgetValueTransferModal,
	budgetsFrom,
	budgetsTo,
	budgetTo,
	closeBudgetValueTransferModal,
	getBudgetsFrom,
	getBudgetsTo,
	sendValueTransfer,
}: BudgetValueTransferModalProps): JSX.Element => {
	const [activeStep, setActiveStep] = useState(0);
	const [budgetFromId, setBudgetFromId] = useState<string>('');
	const [budgetToId, setBudgetToId] = useState<string>('');
	const [value, setValue] = useState<string>('');
	const [wrongValue, setWrongValue] = useState<boolean>(false);
	const [filters, setFilters] = useState<IFilter>(filtersInitialValues);
	const [filteredBudgetsFrom, setFilteredBudgetsFrom] = useState<any[]>([]);
	const [filteredBudgetsTo, setFilteredBudgetsTo] = useState<any[]>([]);

	const clearFilters = (): void => {
		setFilters(filtersInitialValues);
	};

	const sxContentSteps = useMemo(() => {
		if (activeStep === 0) return sxContentStepOne;
		if (activeStep === 1) return sxContentStepTwo;
		if (activeStep === 2) return sxContentStepThree;

		return sxContentStepOne;
	}, [activeStep]);

	useEffect(() => {
		setBudgetToId(budgetTo);
	}, [budgetTo]);

	const handleFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setFilters((filterValues: IFilter) => ({
			...filterValues,
			[e.target.id]: e.target.value,
		}));
	}, []);

	useEffect(() => {
		if (showBudgetValueTransferModal) {
			getBudgetsFrom(userType);
			getBudgetsTo(userType);
		}
	}, [getBudgetsFrom, getBudgetsTo, showBudgetValueTransferModal, userType]);

	useEffect(() => {
		clearFilters();
	}, [activeStep]);

	useEffect(() => {
		if (budgetsFrom) setFilteredBudgetsFrom(budgetsFrom);
	}, [budgetsFrom]);

	useEffect(() => {
		if (budgetsTo) setFilteredBudgetsTo(budgetsTo);
	}, [budgetsTo]);

	const handleBudgetsFromFilter = useCallback((
		selectedBudgetsFrom: any[],
		filterValues: IFilter,
	) => {
		const filtered = getFilteredBudgets(selectedBudgetsFrom, filterValues);
		setFilteredBudgetsFrom(filtered);
	}, []);

	const handleBudgetsToFilter = useCallback((selectedBudgetsTo: any[], filterValues: IFilter) => {
		const filtered = getFilteredBudgets(selectedBudgetsTo, filterValues);
		setFilteredBudgetsTo(filtered);
	}, []);

	const debouncedHandleBudgetsFromFilter = useMemo(
		() => debounce(handleBudgetsFromFilter),
		[handleBudgetsFromFilter],
	);
	const debouncedHandleBudgetsToFilter = useMemo(
		() => debounce(handleBudgetsToFilter),
		[handleBudgetsToFilter],
	);

	useEffect(() => {
		if (activeStep === 0) {
			debouncedHandleBudgetsFromFilter(budgetsFrom, filters);
		}

		if (activeStep === 1) {
			debouncedHandleBudgetsToFilter(budgetsTo, filters);
		}
	}, [
		activeStep,
		budgetsFrom,
		budgetsTo,
		debouncedHandleBudgetsFromFilter,
		debouncedHandleBudgetsToFilter,
		filters,
	]);

	const closeBudgetValueTransferModalProxy = useCallback((event, reason?) => {
		if (reason === 'backdropClick') {
			event.preventDefault();
			return;
		}

		setActiveStep(0);
		setBudgetFromId('');
		setBudgetToId('');
		setValue('');
		setWrongValue(false);
		closeBudgetValueTransferModal(event);
	}, [closeBudgetValueTransferModal]);

	const handleNext = (): void => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = (): void => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleBudgetFromClick = useCallback((budgetId: string) => {
		setBudgetFromId(budgetId);
	}, []);

	const handleBudgetToClick = useCallback((budgetId: string) => {
		setBudgetToId(budgetId);
	}, []);

	const budgetFromTo = useMemo(() => {
		const budgetFrom = budgetsFrom.find((budgetFromFind) => budgetFromFind.id === budgetFromId);
		const findedBudgetTo = budgetsTo.find((budgetToFind) => budgetToFind.id === budgetToId);

		return {
			budgetFrom,
			findedBudgetTo,
		};
	}, [budgetsFrom, budgetsTo, budgetFromId, budgetToId]);

	const handleValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const { budgetFrom } = budgetFromTo;
		const { value: targetValue } = event.target;

		if (targetValue && budgetFrom.balanceValue.lessThan(targetValue)) {
			setWrongValue(true);
		} else {
			setWrongValue(false);
		}

		setValue(targetValue);
	}, [budgetFromTo]);

	const sendValueTransferProxy = useCallback((event) => {
		const data: IValueTransferData = {
			value,
			budgetFromId,
			budgetToId,
			userType,
		};

		sendValueTransfer(data);
		closeBudgetValueTransferModalProxy(event);
	}, [
		budgetFromId,
		budgetToId,
		value,
		userType,
		sendValueTransfer,
		closeBudgetValueTransferModalProxy,
	]);

	const filterInputsComponentMemo = useMemo(() => (
		<ListItem>
			<Stack component="form" direction="row" spacing={1} sx={{ width: '100%' }}>
				{filterInputs.map((filterInput) => (
					<TextField
						fullWidth
						key={filterInput.label}
						id={filterInput.name}
						label={filterInput.label}
						variant="outlined"
						onChange={handleFilterChange}
						value={(filters as any)[filterInput.name]}
					/>
				))}
			</Stack>
		</ListItem>
	), [filters, handleFilterChange]);

	const budgetFromStep = useMemo(() => (
		<List sx={sxStepDefault}>
			{filterInputsComponentMemo}
			{filteredBudgetsFrom.map((budgetFrom) => {
				if (budgetFrom.id === budgetToId) {
					return null;
				}

				return (
					<React.Fragment key={budgetFrom.id}>
						<ListItem disablePadding>
							<ListItemButton
								selected={budgetFromId === budgetFrom.id}
								onClick={() => handleBudgetFromClick(budgetFrom.id)}
							>
								<ListItemText sx={listItemStyle} primary={budgetFrom.accountingAccount.name} secondary="Cta. Contábil" />
								<ListItemText sx={listItemStyle} primary={budgetFrom.costCenter.name} secondary="C. Custo" />
								<ListItemText sx={listItemStyle} primary={budgetFrom.accountingItem.name} secondary="Filial" />
								<ListItemText sx={listItemStyle} primary={budgetFrom.classValue.name} secondary="CliFor" />
								<ListItemText sx={listItemStyle} primary={currencyBRLMask(budgetFrom.balanceValue)} secondary="Valor total" />
							</ListItemButton>
						</ListItem>
						<Divider />
					</React.Fragment>
				);
			})}
		</List>
	), [
		filterInputsComponentMemo,
		filteredBudgetsFrom,
		budgetToId,
		budgetFromId,
		handleBudgetFromClick,
	]);

	const budgetToStep = useMemo(() => (
		<List sx={sxStepDefault}>
			{filterInputsComponentMemo}
			{filteredBudgetsTo.map((currentBudgetTo) => {
				if (currentBudgetTo.id === budgetFromId) {
					return null;
				}

				return (
					<React.Fragment key={currentBudgetTo.id}>
						<ListItem disablePadding>
							<ListItemButton
								selected={budgetToId === currentBudgetTo.id}
								onClick={() => handleBudgetToClick(currentBudgetTo.id)}
							>
								<ListItemText sx={listItemStyle} primary={currentBudgetTo.accountingAccount.name} secondary="Cta. Contábil" />
								<ListItemText sx={listItemStyle} primary={currentBudgetTo.costCenter.name} secondary="C. Custo" />
								<ListItemText sx={listItemStyle} primary={currentBudgetTo.accountingItem.name} secondary="Filial" />
								<ListItemText sx={listItemStyle} primary={currentBudgetTo.classValue.name} secondary="CliFor" />
								<ListItemText sx={listItemStyle} primary={currencyBRLMask(currentBudgetTo.balanceValue)} secondary="Valor total" />
							</ListItemButton>
						</ListItem>
						<Divider />
					</React.Fragment>
				);
			})}
		</List>
	), [filterInputsComponentMemo, filteredBudgetsTo, budgetFromId, budgetToId, handleBudgetToClick]);

	const valueStep = useMemo(() => {
		const { budgetFrom, findedBudgetTo } = budgetFromTo;

		return (
			<Stack spacing={2} sx={{ ...sxStepDefault, p: 2, height: 'fit-content' }}>
				<Typography>Confirme os dados selecionados até aqui:</Typography>
				{budgetFrom && findedBudgetTo && (
					<Box sx={sxConfirmData}>
						<ListItemText primary={budgetFrom.accountingAccount.name} secondary="Cta. Contábil" />
						<ListItemText primary={budgetFrom.costCenter.name} secondary="C. Custo" />
						<ListItemText primary={budgetFrom.accountingItem.name} secondary="Filial" />
						<ListItemText primary={budgetFrom.classValue.name} secondary="CliFor" />
						<ListItemText primary={currencyBRLMask(budgetFrom.balanceValue)} secondary="Valor total" />
						{/* <Divider orientation="vertical" flexItem /> */}
						<DoubleArrowIcon />
						<ListItemText primary={findedBudgetTo.accountingAccount.name} secondary="Cta. Contábil" />
						<ListItemText primary={findedBudgetTo.costCenter.name} secondary="C. Custo" />
						<ListItemText primary={findedBudgetTo.accountingItem.name} secondary="Filial" />
						<ListItemText primary={findedBudgetTo.classValue.name} secondary="CliFor" />
						<ListItemText primary={currencyBRLMask(findedBudgetTo.balanceValue)} secondary="Valor total" />
					</Box>
				)}
				<Typography>Se estiver correto, informe o valor que deseja transferir abaixo:</Typography>
				<TextField
					id="transfer-value"
					label="Valor"
					value={value}
					onChange={handleValueChange}
					fullWidth
					InputProps={{
						inputComponent: FinancialInput as any,
					}}
					error={wrongValue}
					helperText={wrongValue && 'O valor informado não está disponível no orçamento selecionado'}
				/>
				<Typography variant="caption">Caso haja algum dado errado, você pode voltar os passos com o botão &quot;Voltar passo&quot;.</Typography>
			</Stack>
		);
	}, [handleValueChange, budgetFromTo, value, wrongValue]);

	const content = useMemo(() => (
		<Box sx={sxContent}>
			<Stack
				direction="row"
				sx={sxContentSteps}
			>
				{budgetFromStep}
				{budgetToStep}
				{valueStep}
			</Stack>
		</Box>
	), [sxContentSteps, budgetFromStep, budgetToStep, valueStep]);

	return (
		<Dialog
			onClose={closeBudgetValueTransferModalProxy}
			open={showBudgetValueTransferModal}
			disableEscapeKeyDown
			maxWidth="lg"
			fullWidth
		>
			<DialogTitle sx={sxDialogTitle}>
				Transferência de saldo
				<IconButton
					color="inherit"
					onClick={closeBudgetValueTransferModalProxy}
					aria-label="close"
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent dividers>
				<Stepper activeStep={activeStep}>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{ content }
			</DialogContent>

			<DialogActions sx={sxDialogActions}>
				<Button
					color="inherit"
					disabled={activeStep === 0}
					onClick={handleBack}
					sx={{ mr: 1 }}
				>
					Voltar passo
				</Button>
				{ (activeStep === 0) && (
					<Button onClick={handleNext} disabled={!budgetFromId}>
						Próximo passo
					</Button>
				) }
				{ (activeStep === 1) && (
					<Button onClick={handleNext} disabled={!budgetToId}>
						Próximo passo
					</Button>
				) }
				{ activeStep === steps.length - 1 && (
					<LoadingButton
						onClick={sendValueTransferProxy}
						endIcon={<SendIcon />}
						// loading={BudgetValueTransfering}
						loading={false}
						loadingPosition="end"
						variant="contained"
						disabled={!value || Boolean(wrongValue)}
					>
						Enviar para aprovação
					</LoadingButton>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default BudgetValueTransferModal;
