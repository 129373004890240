import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { GridRowId } from '@mui/x-data-grid';
import { User } from '../../../containers/User/UserAssets';
import { BudgetResponsibleFilter } from './BudgetResponsibleFilter';
import {
	IFilter, IBudgetsToShow, IUpdateBudgetsData,
} from '../../../interfaces/BudgetResponsible';
import { BudgetResponsibleEditSelectionDrawer } from './BudgetResponsibleEditSelectionDrawer';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../../interfaces/PageHeaderInterface';
import BudgetResponsibleDataGrid from './BudgetResponsibleDataGrid';
import { IFilterFieldsValues } from '../../../interfaces/BudgetOrderFourFields';

interface IBudgetResponsibleProps {
	loading: boolean;
	user?: User;
	budgetResponsibles: IBudgetsToShow[];
	filterFieldsValues: IFilterFieldsValues | null;
	getBudgetResponsible: (userId: string, filterData?: IFilter) => void;
	getFilterFieldsValues: () => void;
	getUserById: (id: string) => void;
	updateBudgets: (userId: string, data: IUpdateBudgetsData) => void;
}

const BudgetResponsible = ({
	loading,
	user,
	budgetResponsibles,
	filterFieldsValues,
	getBudgetResponsible,
	getUserById,
	getFilterFieldsValues,
	updateBudgets,
}: IBudgetResponsibleProps): JSX.Element => {
	const { userId } = useParams();
	const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
	const [changedRows, setChangedRows] = useState<any[]>([]);
	const [openEditSelectionDrawer, setOpenEditSelectionDrawer] = useState<boolean>(false);

	useEffect(() => {
		getUserById(userId ?? '');
		getBudgetResponsible(userId ?? '');
		getFilterFieldsValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!budgetResponsibles) return;

		setChangedRows(budgetResponsibles);
	}, [budgetResponsibles, userId]);

	const handleOpenEditSelectionDrawer = (): void => {
		setOpenEditSelectionDrawer(true);
	};

	const handleCloseEditSelectionDrawer = (): void => {
		setOpenEditSelectionDrawer(false);
	};

	const handleSelectedRowsChange = (rows: GridRowId[]): void => {
		setSelectedRows(rows);
	};

	const handleRowChange = useCallback((
		budgetId: GridRowId,
		propertyKey: keyof IBudgetsToShow,
		newValue: any,
	): void => {
		setChangedRows((currentChangedRows) => currentChangedRows.map((row) => {
			if (row.id === budgetId) {
				return { ...row, [propertyKey]: newValue };
			}

			return row;
		}));
	}, []);

	const handleMultipleRowsChange = useCallback((
		budgetIds: GridRowId[],
		approvalPercent: string,
	) => {
		if (userId) {
			updateBudgets(userId, {
				updateAll: {
					approvalPercent: Number.parseInt(approvalPercent, 10),
					budgetIds: (budgetIds as string[]),
					userId,
				},
			});
		}
	}, [updateBudgets, userId]);

	const handleSave = useCallback(() => {
		const updatedBudgets = changedRows.filter((row) => {
			const budget = budgetResponsibles.find((currentBudget) => currentBudget.id === row.id);

			return budget?.approvalPercent !== row.approvalPercent;
		});

		const ownershipChangedBudgets = changedRows.filter((row) => {
			const budget = budgetResponsibles.find((currentBudget) => currentBudget.id === row.id);

			return budget?.isOwner !== row.isOwner;
		});

		const data = {
			add: ownershipChangedBudgets.filter((budget) => budget.isOwner).map((budget) => ({
				budgetId: budget.id,
				userId: userId ?? '',
				approvalPercent: budget.approvalPercent,
			})),
			update: updatedBudgets.map((budget) => ({
				budgetId: budget.id,
				userId: userId ?? '',
				approvalPercent: budget.approvalPercent,
			})),
			remove: {
				budgetIds: ownershipChangedBudgets
					.filter((budget) => !budget.isOwner).map((budget) => budget.id),
				userId: userId ?? '',
			},
		};
		updateBudgets(userId ?? '', data);
		setSelectedRows([]);
	}, [budgetResponsibles, changedRows, updateBudgets, userId]);

	const saveButtonProps = {
		text: 'Salvar',
		onClick: handleSave,
	};

	const editSelectionButtonProps = {
		text: 'Editar Seleção',
		onClick: handleOpenEditSelectionDrawer,
	};

	const handleFilter = (filters: IFilter): void => {
		getBudgetResponsible(userId ?? '', filters);
	};

	const headerButtons: PageHeaderButtonProps[] = [
		{
			show: true,
			variant: 'contained',
			...(selectedRows.length === 0 ? saveButtonProps : editSelectionButtonProps),
		},
	];

	const editSelectionDrawerMemo = useMemo(() => (
		<BudgetResponsibleEditSelectionDrawer
			open={openEditSelectionDrawer}
			handleClose={handleCloseEditSelectionDrawer}
			selectedRows={selectedRows}
			handleMultipleRowsChange={handleMultipleRowsChange}
			budgets={changedRows}
		/>
	), [changedRows, handleMultipleRowsChange, openEditSelectionDrawer, selectedRows]);

	return (
		<>
			<PageHeader title={user?.name ?? ''} buttons={headerButtons} />
			<Stack spacing={4} sx={{ height: '100%' }}>
				<BudgetResponsibleFilter
					filterFieldsValues={filterFieldsValues}
					handleFilter={handleFilter}
					loading={loading}
				/>
				<BudgetResponsibleDataGrid
					rows={changedRows}
					handleSelectedRowsChange={handleSelectedRowsChange}
					selectedRows={selectedRows}
					handleRowChange={handleRowChange}
					loading={loading}
				/>
			</Stack>
			{editSelectionDrawerMemo}
		</>
	);
};

BudgetResponsible.defaultProps = {
	user: null,
};

export default BudgetResponsible;
