import React, {
	useCallback,
	useState,
} from 'react';
import {
	Box,
	Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import StepLocation from './Steps/StepLocation';
import StepProduct from './Steps/StepProduct';
import { ValidateInventoryParams } from '../../../../../containers/Mobile/Inventory/InventoryAssets';
import { removeBarCodeMask } from '../../../../../helpers/masks';
import { InventoryTaskDetails } from '../../../../../containers/Inventory/InventoryAssets';

interface InventoryNotFirstProps {
	inventoryTask: InventoryTaskDetails | null;
	countNumber: number;
	handleValidateInventory(params: ValidateInventoryParams): void;
}

enum ActiveStep {
	LOCATION,
	PRODUCT,
}

const InventoryNotFirst = ({
	inventoryTask,
	countNumber,
	handleValidateInventory,
}: InventoryNotFirstProps): JSX.Element => {
	const [activeStep, setActiveStep] = useState<ActiveStep>(ActiveStep.LOCATION);
	const [locationBarCode, setLocationBarCode] = useState('');
	const { id } = useParams();

	const handleConfirmLocation = useCallback((location: string) => {
		setLocationBarCode(location);
		setActiveStep(ActiveStep.PRODUCT);
	}, []);

	const handleConfirmProduct = useCallback((product: string, reader?: boolean) => {
		if (id) {
			handleValidateInventory({
				barCodeProduct: product,
				barCodeLocation: removeBarCodeMask(locationBarCode),
				inventoryId: id,
				reader,
			});
		}
	}, [handleValidateInventory, id, locationBarCode]);

	return (
		<Box>
			<Typography variant="caption" textTransform="uppercase">{`${countNumber}ª Contagem`}</Typography>
			{activeStep === ActiveStep.LOCATION
			&& inventoryTask && (
				<StepLocation
					inventoryTask={inventoryTask}
					confirmLocation={handleConfirmLocation}
				/>
			)}

			{activeStep === ActiveStep.PRODUCT && inventoryTask && (
				<StepProduct
					inventoryTask={inventoryTask}
					confirmProduct={handleConfirmProduct}
				/>
			)}
		</Box>
	);
};

export default InventoryNotFirst;
