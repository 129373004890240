import React, { useCallback } from 'react';
import { FormikContext, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { SaveTaskType } from '../../../services/storage';
import { validationSchema } from '../../../containers/Mobile/Storage/StorageAssets';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import { InventoryTaskStatus } from '../../../enums/InventoryTaskStatus';
import StorageConfirmation from './StorageConfirmation';

interface StorageProps {
	loading: boolean;
	getLocationOrigin: (barCode: string, status: InventoryTaskStatus) => void;
	tasks: ITask[];
	saveTaskCount: (data: SaveTaskType) => void;
}

const initialValues = {
	barCodeOrigin: '',
	confirmedTasks: [],
};

const Storage = ({
	loading,
	getLocationOrigin,
	tasks,
	saveTaskCount,
}: StorageProps): JSX.Element => {
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: () => {
			navigate('/app');
		},
		enableReinitialize: true,
	});

	const handleFinishStorage = useCallback((): void => {
		navigate('/app');
	}, [navigate]);

	const handleBack = useCallback((): void => {
		formik.setFieldValue('barCodeOrigin', '');
		navigate('/app');
	}, [formik, navigate]);

	return (
		<FormikContext.Provider value={formik}>
			<StorageConfirmation
				loading={loading}
				getLocationOrigin={getLocationOrigin}
				saveTaskCount={saveTaskCount}
				tasks={tasks}
				handleFinishStorage={handleFinishStorage}
				handleBack={handleBack}
			/>
		</FormikContext.Provider>
	);
};

export default Storage;
