/* eslint-disable react/jsx-props-no-spreading */
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import InventorySummaryReportPresentational from '../../components/Inventory/Reports/InventorySummaryReport';
import { getInventorySummaryById, reportSummaryPDF } from '../../services/inventory';
import { InventorySummaryParams } from '../../interfaces/InventoryParams';
import { IInventoryInfoReport, IInventorySummary } from './InventoryAssets';

enum ActionType {
	LOADING,
	INVENTORY_SUMMARY,
}

interface IState {
	loading: boolean;
	inventorySummaryItems: IInventorySummary[];
	inventorySummaryCount: number;
	inventorySummaryPage: number;
	inventorySummaryTake: number;
	countNumber: number;
	inventoryInfo: IInventoryInfoReport;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.INVENTORY_SUMMARY;
		payload: {
			inventorySummaryItems: IInventorySummary[];
			inventorySummaryCount: number;
			inventorySummaryPage: number;
			inventorySummaryTake: number;
			countNumber: number;
			inventoryInfo: IInventoryInfoReport;
		} }

interface IInventorySummaryActions {
    setLoading(loading: boolean): void;
	getInventorySummaryById(id: string, queryParams: InventorySummaryParams): void;
	reportSummaryPDF(id: string): void;
}

const initialState: IState = {
	loading: false,
	inventorySummaryItems: [],
	inventorySummaryCount: 0,
	inventorySummaryPage: 0,
	inventorySummaryTake: 10,
	countNumber: 0,
	inventoryInfo: {
		date: '',
		document: '',
		responsibleName: '',
	},
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.INVENTORY_SUMMARY:
			return {
				...state,
				inventorySummaryItems: action.payload.inventorySummaryItems,
				inventorySummaryTake: action.payload.inventorySummaryTake,
				inventorySummaryPage: action.payload.inventorySummaryPage,
				inventorySummaryCount: action.payload.inventorySummaryCount,
				countNumber: action.payload.countNumber,
				inventoryInfo: action.payload.inventoryInfo,
			};
		default:
			throw new Error();
	}
};

const IInventorySummaryActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IInventorySummaryActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getInventorySummaryById(id: string, queryParams: InventorySummaryParams) {
			actions.setLoading(true);

			const take = queryParams.take ?? 10;
			const skip = queryParams.skip ?? 0;
			const params = {
				...queryParams,
				skip: skip * take,
				take: queryParams.take ?? 10,
			};

			getInventorySummaryById(id, params).then((response) => {
				dispatch({
					type: ActionType.INVENTORY_SUMMARY,
					payload: {
						inventorySummaryItems: response.data.data,
						inventorySummaryCount: response.data.count,
						inventorySummaryPage: queryParams.skip ?? 0,
						inventorySummaryTake: take,
						countNumber: response.data.count,
						inventoryInfo: response.data.inventory,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		reportSummaryPDF(id: string) {
			actions.setLoading(true);

			reportSummaryPDF(id).then((response) => {
				const blob = new Blob([response.data], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				window.open(url);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
	};

	return actions;
};

const InventorySummaryReport = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();

	const actions = useMemo(
		() => IInventorySummaryActions(dispatch, enqueueSnackbar),
		[enqueueSnackbar],
	);

	return (
		<InventorySummaryReportPresentational
			{...state}
			{...actions}
		/>
	);
};

export default InventorySummaryReport;
