import {
	ISaleOrderItemExtended,
} from '../containers/SaleOrder/SaleOrderAssets';
import { ItemType } from '../enums/ItemType';

export const calculateTotalCommission = (
	saleOrderItems: ISaleOrderItemExtended[],
): number => saleOrderItems.reduce((acc: number, item: ISaleOrderItemExtended): number => {
	const commission: number = parseFloat(item.commission?.toString() ?? '0');
	const itemPrice: number = item.price;
	const commissionValue: number = (itemPrice * commission) / 100;
	return acc + commissionValue;
}, 0);

export const calculateTotalBonus = (saleOrderItems: ISaleOrderItemExtended[])
: number => saleOrderItems.reduce((acc: number, item): number => {
	const quantity: number = item.quantity ?? 0;
	const itemPrice: number = item.total ? item.total : item.price;
	const totalWithDiscount: number = (itemPrice * quantity);
	if (item.type === ItemType.BONUS) {
		return acc + (totalWithDiscount ?? 0);
	}
	return acc;
}, 0);

export const calculateTotalOrderWithoutDiscount = (
	saleOrderItems: ISaleOrderItemExtended[],
): number => saleOrderItems.reduce((acc: number, item: ISaleOrderItemExtended): number => {
	const quantity: number = item.quantity ?? 0;
	const itemPrice: number = item.originalPrice ? item.originalPrice : item.price;
	const totalWithoutDiscount: number = (itemPrice * quantity);
	return acc + totalWithoutDiscount;
}, 0);

export const calculateTotalOrderWithDiscount = (
	saleOrderItems: ISaleOrderItemExtended[],
): number => saleOrderItems.reduce((acc: number, item: ISaleOrderItemExtended): number => {
	const quantity: number = item.quantity ?? 0;
	const itemPrice: number = item.total ? item.total : item.price;
	const totalWithDiscount: number = (itemPrice * quantity);
	return acc + totalWithDiscount;
}, 0);

export const calculateTotalDiscount = (
	saleOrderItems: ISaleOrderItemExtended[],
): number => saleOrderItems.reduce((acc: number, item): number => {
	if (item.discount) {
		const discountValue = item.originalPrice
			? (item.originalPrice * item.quantity) * (item.discount / 100)
			: (item.price * item.quantity) * (item.discount / 100);
		return acc + discountValue;
	}
	return acc;
}, 0);
