import React, { useEffect } from 'react';
import { ChevronRight } from '@mui/icons-material';
import {
	Box,
	Stack,
	Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IInventory, InventoryQueryParams } from '../../../containers/Inventory/InventoryAssets';
import { formatUTCDateToLocal } from '../../../helpers/DateUtils';
import { Loading } from '../../Common/Loading';
import { InventoryStatus } from '../../../enums/inventory';

interface InventoryListProps {
	inventories: IInventory[],
	loading: boolean,
	getInventories(params: Partial<InventoryQueryParams>): void
}

const InventoryList = ({
	inventories,
	getInventories,
	loading,
}: InventoryListProps): JSX.Element => {
	const navigate = useNavigate();

	useEffect(() => {
		getInventories({ skip: 0, status: [InventoryStatus.PROGRESS] });
	}, [getInventories]);

	if (loading) {
		return (
			<Box padding={1}>
				<Loading />
			</Box>
		);
	}

	return (
		<Box padding={1}>
			<Typography variant="h6" align="center">Inventário</Typography>
			<Typography variant="subtitle1" align="center" marginBottom={2}>Selecione o Inventário</Typography>
			<Typography variant="overline">Inventários:</Typography>
			{inventories.length === 0
			&& (
				<Box>
					<Typography textAlign="center" mt={2}>Não há inventários disponíveis.</Typography>
				</Box>
			)}
			{
				inventories.map((inventory) => (
					<Box
						key={inventory.id}
						sx={{
							padding: 2,
							border: 1,
							borderColor: '#bbb',
							bgcolor: 'white',
							borderRadius: 2,
							mb: 2,
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
						onClick={() => navigate(inventory.id)}
					>
						<Stack direction="column">
							<Typography fontWeight="bold">
								{`Nº Documento: ${inventory.document}`}
							</Typography>
							<Typography>
								{`Data: ${formatUTCDateToLocal(inventory.date, 'dd/MM/yyyy')}`}
							</Typography>
						</Stack>
						<ChevronRight color="primary" />
					</Box>
				))
			}

		</Box>
	);
};

export default InventoryList;
