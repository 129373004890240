import React, {
	useEffect,
	useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {
	IProductActions,
	IState,
} from '../../containers/Product/ProductEdit';
import ProductForm from './ProductForm';
import { Loading } from '../Common/Loading';
import { EditProduct, IProduct } from '../../containers/Product/ProductAssets';
import { FormTitle } from '../User/Forms/UserFormSections';

interface ProductEditProps extends Omit<IState, 'product'>, IProductActions {
	product?: IProduct;
	loading: boolean;
}

const ProductEdit = ({
	loading,
	product,
	accountingAccounts,
	measures,
	productTypes,
	branches,
	companies,
	getAccountingAccounts,
	getMeasures,
	getProductTypes,
	getProductById,
	handleEditProduct,
	getBranches,
	getCompanies,
}: ProductEditProps): JSX.Element => {
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		getAccountingAccounts();
		getMeasures();
		getProductTypes();
		getBranches();
		getCompanies();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (id) {
			getProductById(id);
		}
	}, [id, getProductById]);

	const handleSubmit = useCallback((values: EditProduct) => {
		const formData = new FormData();
		const keysWithoutImage = Object.keys(values).filter((key) => key !== 'image');
		keysWithoutImage.forEach((key) => {
			const value = values[key as keyof EditProduct];
			if (value !== null && value !== '' && key !== 'primaryMeasureId' && key !== 'secondaryMeasureId') {
				formData.append(key, String(value));
			}
		});
		if (values.image && values.image.file) {
			formData.append('image', values.image.file);
		}

		const measureIds: string[] = [];
		if (values.primaryMeasureId) {
			measureIds.push(values.primaryMeasureId);
		}
		if (values.secondaryMeasureId) {
			measureIds.push(values.secondaryMeasureId);
		}
		formData.append('measureIds', JSON.stringify(measureIds));

		handleEditProduct({ id: product?.id, data: formData });
	}, [handleEditProduct, product]);

	if (id && !product) {
		return <Loading />;
	}

	return (
		<Paper sx={{ mt: 3, p: 3 }} elevation={3}>
			<Stack spacing="4rem">
				<FormTitle>
					{`${product ? 'Editar' : 'Cadastrar'} Produto`}
				</FormTitle>
				<ProductForm
					product={product}
					accountingAccounts={accountingAccounts}
					measures={measures}
					productTypes={productTypes}
					branches={branches}
					companies={companies}
					onSubmit={handleSubmit}
					loading={loading}
				/>
			</Stack>
		</Paper>
	);
};

ProductEdit.defaultProps = {
	product: undefined,
};

export default ProductEdit;
