import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import TransferPresentational from '../../../components/Mobile/Transfer/Transfer';
import { ITask } from '../TaskAssets';
import {
	getTransfers, saveTakeTask, saveTaskCount, SaveTaskType,
} from '../../../services/transfer';
import { StepsTransfer } from './TransferAssets';

enum ActionType {
	LOADING,
	ACTIVE_STEP,
	TRANSFER_TASKS,
	TRANSFER_TASK,
}

interface IState {
	loading: boolean;
	activeStep: StepsTransfer;
	transferTasks: ITask[];
	transferTask?: ITask;
	count: number;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.TRANSFER_TASK; payload: { transferTask: ITask } }
	| { type: ActionType.ACTIVE_STEP; payload: { activeStep: StepsTransfer } }
	| { type: ActionType.TRANSFER_TASKS; payload: { transferTasks: ITask[], count: number } };

interface ITransferActions {
    setLoading(loading: boolean): void;
    setTransferTask(task: ITask): void;
	setActiveStep(step: StepsTransfer): void;
    saveTaskCount(task: SaveTaskType): void;
    saveTakeTask(task: SaveTaskType): void;
    getTransfers(): void;
}

const initialState: IState = {
	loading: false,
	activeStep: StepsTransfer.LOCATIONS,
	transferTasks: [],
	transferTask: undefined,
	count: 0,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.ACTIVE_STEP:
			return { ...state, activeStep: action.payload.activeStep };
		case ActionType.TRANSFER_TASK:
			return { ...state, transferTask: action.payload.transferTask };
		case ActionType.TRANSFER_TASKS:
			return {
				...state,
				transferTasks: action.payload.transferTasks,
				count: action.payload.count,
			};
		default:
			throw new Error();
	}
};

const TransferActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): ITransferActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},

		setActiveStep(activeStep: StepsTransfer) {
			dispatch({ type: ActionType.ACTIVE_STEP, payload: { activeStep } });
		},

		setTransferTask(transferTask: ITask) {
			dispatch({ type: ActionType.TRANSFER_TASK, payload: { transferTask } });
		},

		getTransfers() {
			actions.setLoading(true);
			getTransfers().then(
				(response) => {
					dispatch({
						type: ActionType.TRANSFER_TASKS,
						payload: {
							transferTasks: response.data.data,
							count: response.data.count,
						},
					});
					actions.setActiveStep(StepsTransfer.LOCATIONS);
				},
			).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu ao obter a localização de origem. Tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			}).finally(() => {
				actions.setLoading(false);
			});
		},

		saveTakeTask(task: SaveTaskType) {
			actions.setLoading(true);
			saveTakeTask(task).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},

		saveTaskCount(task: SaveTaskType) {
			actions.setLoading(true);
			saveTaskCount(task).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
				actions.getTransfers();
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},
	};

	return actions;
};

const Transfer = ():JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => TransferActions(
			dispatch,
			enqueueSnackbar,
		),
		[dispatch, enqueueSnackbar],
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<TransferPresentational {...state} {...actions} />
	);
};

export default Transfer;
