import { GridSortDirection } from '@mui/x-data-grid/models';
import { getFirstDayOfCurrentMonth } from '../helpers/Utils';

export interface OrderBy {
  name: GridSortDirection;
}

export interface HourProjectQueryParams {
  skip: number,
  take?: number,
  startDate: Date | string;
  endDate: Date | string;
  name?: string;
  userId?: string[];
  hasQuantity?: boolean;
  hasAppointment?: boolean;
  hasOverflowTask?: boolean;
  orderBy?: OrderBy,
}

export const initialValuesLoad: HourProjectQueryParams = {
	skip: 0,
	startDate: getFirstDayOfCurrentMonth(),
	endDate: new Date(),
	userId: [],
	hasQuantity: false,
	hasAppointment: true,
	hasOverflowTask: false,
};

export interface AddUsersToProjectTasksParams {
  userIds: string[];
  values?: string[];
  descriptions?: string[];
}
