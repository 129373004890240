import React, { useCallback, useEffect, useMemo } from 'react';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import { Box, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Input from '../Common/Form/Input';
import {
	initialValues,
	validationSchema,
} from '../../containers/LocationType/LocationTypeAssets';
import { ILocationType } from '../../containers/Location/LocationAssets';
import Autocomplete from '../Common/Form/Autocomplete';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { BRANCH_ID_KEY, COMPANY_ID_KEY } from '../../services/auth';

interface LocationTypeFormProps {
	loading: boolean;
	locationType?: ILocationType;
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	onSubmit: (values: ILocationType) => void;
	getUserBranchesByCompany: (companyId: string) => void;
	getUserCompanies: () => void;
}

const LocationTypeForm = ({
	locationType,
	loading,
	companies,
	branches,
	getUserCompanies,
	getUserBranchesByCompany,
	onSubmit,
}: LocationTypeFormProps): JSX.Element => {
	const initialValuesLoad = useMemo(
		() => (locationType
			? {
				id: locationType.id,
				name: locationType.name,
				prefix: locationType.prefix,
				level: locationType.level,
				canEditPrefix: locationType.canEditPrefix,
				companyId: localStorage.getItem(COMPANY_ID_KEY) || '',
				branchId: localStorage.getItem(BRANCH_ID_KEY) || '',
			}
			: {
				...initialValues,
				companyId: localStorage.getItem(COMPANY_ID_KEY) || '',
				branchId: localStorage.getItem(BRANCH_ID_KEY) || '',
				level: '',
			}),
		[locationType],
	);

	const formik = useFormik({
		initialValues: initialValuesLoad,
		onSubmit,
		validationSchema,
		enableReinitialize: true,
	});

	useEffect(() => {
		if (formik.values.companyId) {
			getUserBranchesByCompany(formik.values.companyId);
		}
	}, [formik.values.companyId, getUserBranchesByCompany]);

	useEffect(() => {
		getUserCompanies();
	}, [getUserCompanies]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Box paddingTop={4}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<Autocomplete
								label="Empresa"
								options={companies}
								labelKey="name"
								valueKey="id"
								valueLabel="code"
								name="companyId"
								closeOnSelect
								loading={loading}
								readOnly
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Autocomplete
								options={branches}
								label="Filial"
								name="branchId"
								labelKey="name"
								valueKey="id"
								valueLabel="code"
								closeOnSelect
								loading={loading}
								readOnly
							/>
						</Grid>
					</Grid>
					<Box marginTop={4}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4}>
								<Input.InputField
									disabled={!(initialValuesLoad.canEditPrefix)}
									name="prefix"
									label="Prefixo"
									format={(value) => value.toUpperCase()}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<Input.InputField name="name" label="Nome" />
							</Grid>
							<Grid item xs={12} md={4}>
								<Input.InputField
									name="level"
									label="Nível"
									format={(value) => (value === '' || Number.isNaN(Number(value)) ? '' : Number(value))}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box marginTop={4}>
						<LoadingButton
							loading={loading}
							type="submit"
							variant="contained"
							color="primary"
						>
							Salvar
						</LoadingButton>
					</Box>
				</Box>
			</Form>
		</FormikContext.Provider>
	);
};

LocationTypeForm.defaultProps = {
	locationType: undefined,
};

export default LocationTypeForm;
