import React from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Add } from '@mui/icons-material';

interface GridToolBarProps {
	onAddClick: () => void;
}

const GridAddToolBar = ({ onAddClick }: GridToolBarProps): JSX.Element => (
	<GridToolbarContainer>
		<Button
			startIcon={<Add />}
			sx={{ fontSize: '0.835rem' }}
			onClick={() => {
				onAddClick();
			}}
		>
			Adicionar
		</Button>
	</GridToolbarContainer>
);

export const ToolbarAddComponent = (onAddClick: () => void) => () => (
	<GridAddToolBar onAddClick={onAddClick} />
);

export default GridAddToolBar;
