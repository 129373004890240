import React, { useCallback, useEffect, useRef } from 'react';
import {
	Box,
	Typography,
	Button,
	Paper,
	IconButton,
} from '@mui/material';
import {
	ChevronRight,
	Search,
} from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { Loading } from '../../Common/Loading';
import { GroupedTasks } from './Expedition';
import Input from '../../Common/Form/Input';
import { formatBarCode } from '../../../helpers/masks';

interface ExpeditionListProps {
	loading: boolean;
	saleOrder: string;
	groupedTasks: GroupedTasks | null;
	handleExpeditionLocation: (location: string) => void;
	handleBackStart: () => void;
}

const ExpeditionList = ({
	loading,
	saleOrder,
	groupedTasks,
	handleExpeditionLocation,
	handleBackStart,
}: ExpeditionListProps): JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);
	const { values, setFieldValue } = useFormikContext<{
		locationSearch: string;
		locationDestiny: string;
	}>();

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
		if (groupedTasks) {
			setFieldValue('locationDestiny', Object.values(groupedTasks[saleOrder].locations)[0].tasks[0].locationDestiny.barCode);
		}
	}, [groupedTasks, loading, saleOrder, setFieldValue]);

	const handleValidateLocation = useCallback((): void => {
		if (groupedTasks) {
			const matchLocation = Object.keys(groupedTasks[saleOrder].locations).find(
				(location) => formatBarCode(location).toUpperCase() === values.locationSearch.toUpperCase(),
			);

			setFieldValue('locationSearch', '');

			if (matchLocation) {
				handleExpeditionLocation(matchLocation);
			} else {
				enqueueSnackbar('Localização não encontrada.', {
					variant: 'error',
				});
			}
		}
	}, [groupedTasks,
		saleOrder,
		values.locationSearch,
		setFieldValue,
		handleExpeditionLocation,
		enqueueSnackbar]);

	const handleBlurOrEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			handleValidateLocation();
		}
	}, [handleValidateLocation]);

	if (loading) {
		return <Loading />;
	}

	return (
		<Box padding={1}>
			<Typography variant="h6" align="center">
				Localizações
			</Typography>
			<Typography align="center">
				{`Pedido de Venda: ${saleOrder}`}
			</Typography>

			<Paper sx={{ boxShadow: 'none', marginTop: 2, marginBottom: 4 }}>
				<Input.InputField
					id="locationSearch"
					name="locationSearch"
					label="Buscar Localização"
					autoComplete="off"
					format={(value) => formatBarCode(value).toUpperCase()}
					InputProps={{
						endAdornment: (
							<IconButton
								color="primary"
								aria-label="search location"
							>
								<Search />
							</IconButton>
						),
						inputProps: {
							min: 0,
							step: 'any',
						},
					}}
					fullWidth
					inputRef={inputRef}
					onKeyDown={handleBlurOrEnter}
				/>
			</Paper>

			<Typography variant="overline">
				Siga para as localizações abaixo:
			</Typography>
			{groupedTasks ? Object.keys(groupedTasks[saleOrder].locations).map((location) => (
				<Box
					sx={{
						padding: 2,
						border: 1,
						borderColor: '#bbb',
						bgcolor: 'white',
						borderRadius: 2,
						mb: 2,
						display: 'flex',
						justifyContent: 'space-between',
					}}
					onClick={() => handleExpeditionLocation(location)}
					key={location}
				>
					<Typography>{formatBarCode(location)}</Typography>
					<ChevronRight color="primary" />
				</Box>
			)) : (
				<Typography variant="subtitle1" align="center">
					Não há localizações para este pedido.
				</Typography>
			)}
			<Button variant="contained" sx={{ my: 2 }} onClick={() => handleBackStart()}>
				Voltar
			</Button>
		</Box>
	);
};

export default ExpeditionList;
