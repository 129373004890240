import cloneDeep from 'lodash/cloneDeep';
import { IFilter } from '../interfaces/BudgetResponsible';

const getValidSelectedFilterValues = (
	filterValues: string[],
	filterFieldsValues: string[],
): string[] => {
	if (filterValues.length === filterFieldsValues.length) {
		return [];
	}

	return filterValues;
};

export const getValidFilters = (values: IFilter, filterFieldsValues: any): IFilter => {
	const filter = cloneDeep(values);

	filter.accountingAccounts = getValidSelectedFilterValues(
		filter.accountingAccounts,
		filterFieldsValues.accountingAccounts,
	);
	filter.accountingItems = getValidSelectedFilterValues(
		filter.accountingItems,
		filterFieldsValues.accountingItems,
	);
	filter.classValues = getValidSelectedFilterValues(
		filter.classValues,
		filterFieldsValues.classValues,
	);
	filter.costCenters = getValidSelectedFilterValues(
		filter.costCenters,
		filterFieldsValues.costCenters,
	);

	return filter;
};
