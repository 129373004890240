import { useEffect, useRef } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';

export function useFormikValuesUpdater(
	fieldValues: Record<string, any>,
	dependencies: any[],
	formik?: FormikProps<any>,
): void {
	const formikContext = useFormikContext();
	const effectiveFormik = formik || formikContext;

	if (!effectiveFormik) {
		throw new Error(
			'useFormikValuesUpdater deve ser usado dentro de um FormikProvider ou ser fornecido com uma instância do formik.',
		);
	}

	const { setFieldValue } = effectiveFormik;
	const previousValues = useRef<Record<string, any>>({});

	useEffect(() => {
		Object.entries(fieldValues).forEach(([field, value]) => {
			const previousValue = previousValues.current[field];
			if (!isEqual(previousValue, value)) {
				setFieldValue(field, value, false);
				previousValues.current[field] = value;
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldValues, setFieldValue, ...dependencies]);
}
