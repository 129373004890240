import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SwitchField from '../../Common/Form/Switch';
import { IForm, initialValues } from '../../../containers/HourProject/ApportionmentAssets';
import Input from '../../Common/Form/Input';

interface IFilterProps {
	sendFilter: (values: IForm) => void;
	filterValues: IForm;
}

const AppointmentFilter = ({ sendFilter, filterValues }: IFilterProps): JSX.Element => {
	const onSubmit = useCallback((values: IForm) => {
		sendFilter({ ...values });
	}, [sendFilter]);

	const onReset = useCallback((handleReset) => {
		sendFilter(initialValues);
		handleReset();
	}, [sendFilter]);

	return (
		<Paper sx={{ p: 2, width: '100%', mb: 2 }}>
			<Formik
				initialValues={filterValues}
				onSubmit={onSubmit}
			>
				{({ handleReset }) => (
					<Form>
						<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
							<Grid item xs={12}>
								<Input.InputField
									id="name"
									name="name"
									label="Nome/Código do Projeto"
									autoComplete="off"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<SwitchField
									name="hasQuantity"
									label="Apenas Tarefas com Horas"
								/>
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
								<Button variant="outlined" onClick={() => onReset(handleReset)}>
									Restaurar
								</Button>
								<Button variant="contained" type="submit">
									Filtrar
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};

export default AppointmentFilter;
