import React, { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ChangePasswordForm } from './ChangePasswordForm';
import { changePassword } from '../../services/auth';

interface IChangePasswordDialogProps {
  open: boolean;
  handleClose: () => void;
}

const ChangePasswordDialog = ({ open, handleClose }: IChangePasswordDialogProps): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleChangePassword = useCallback((password: string) => {
		setLoading(true);

		changePassword({ password }).then((response) => {
			setLoading(false);

			enqueueSnackbar(response.data.message, {
				variant: 'success',
			});

			handleClose();
		}).catch(() => {
			setLoading(false);

			enqueueSnackbar('Algum erro ocorreu, tente novamente ou contate um administrador.', {
				variant: 'error',
			});
		});
	}, [enqueueSnackbar, handleClose]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
		>
			<DialogTitle>Alterar senha</DialogTitle>
			<DialogContent>
				<ChangePasswordForm loading={loading} handleChangePassword={handleChangePassword} />
			</DialogContent>
		</Dialog>
	);
};

export default ChangePasswordDialog;
