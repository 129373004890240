/* eslint-disable import/no-duplicates */
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import SalePriceFormationPresentational from '../../components/SalePriceFormation/SalePriceFormation';
import { getSalePriceFormations, deleteSalePriceFormation, getPriceLists } from '../../services/salePriceFormation';
import { ISalePriceFormation } from './SalePriceFormationAssets';
import { SalePriceFormationQueryParams } from '../../interfaces/SalePriceFormationQueryParams';

enum ActionType {
	LOADING,
	SALE_PRICE_FORMATION,
	PRICE_LISTS,
}

interface IState {
	count: number;
	loading: boolean;
	salePriceFormations: ISalePriceFormation[];
	salePriceFormationsPages: number;
	salePriceFormationsPage: number;
	salePriceFormationsTake: number;
	priceLists: string[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| {
		type: ActionType.SALE_PRICE_FORMATION; payload: {
			salePriceFormations: ISalePriceFormation[];
			count: number;
			salePriceFormationsPages: number;
			salePriceFormationsPage: number;
			salePriceFormationsTake: number;
		}
	}
	| { type: ActionType.PRICE_LISTS; payload: { priceLists: string[] } };

interface ISalePriceFormationActions {
	setLoading(loading: boolean): void;
	getSalePriceFormations(params: SalePriceFormationQueryParams): void;
	handleEdit(id: string): void;
	handleDeleteSalePriceFormation(id: string): void;
	getPriceLists(): void;
}

const initialState: IState = {
	count: 0,
	loading: false,
	salePriceFormations: [],
	salePriceFormationsPages: 0,
	salePriceFormationsPage: 0,
	salePriceFormationsTake: 10,
	priceLists: [],
};

let lastQueryParams: SalePriceFormationQueryParams;

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.SALE_PRICE_FORMATION:
			return {
				...state,
				salePriceFormations: action.payload.salePriceFormations,
				count: action.payload.count,
				salePriceFormationsPages: action.payload.salePriceFormationsPages,
				salePriceFormationsPage: action.payload.salePriceFormationsPage,
				salePriceFormationsTake: action.payload.salePriceFormationsTake,
			};
		case ActionType.PRICE_LISTS:
			return { ...state, priceLists: action.payload.priceLists };
		default:
			throw new Error();
	}
};

const SalePriceFormationActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): ISalePriceFormationActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getSalePriceFormations(queryParams: SalePriceFormationQueryParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 10;
			const params = { ...queryParams, skip: queryParams.skip * take };
			if (queryParams.startDate === '') {
				delete params.startDate;
			}
			if (queryParams.endDate === '') {
				delete params.endDate;
			}
			if (queryParams.startValidity === '') {
				delete params.startValidity;
			}
			if (queryParams.endValidity === '') {
				delete params.endValidity;
			}

			if (
				(queryParams.startDate !== lastQueryParams?.startDate)
				|| (queryParams.endDate !== lastQueryParams?.endDate)
				|| (queryParams.startValidity !== lastQueryParams?.startValidity)
				|| (queryParams.endValidity !== lastQueryParams?.endValidity)
			) {
				if (queryParams.startDate) {
					params.startDate = format(new Date(queryParams.startDate), 'yyyy-MM-dd', { locale: ptBR });
				}
				if (queryParams.endDate) {
					params.endDate = format(new Date(queryParams.endDate), 'yyyy-MM-dd', { locale: ptBR });
				}
				if (queryParams.startValidity) {
					params.startValidity = format(new Date(queryParams.startValidity), 'yyyy-MM-dd', { locale: ptBR });
				}
				if (queryParams.endValidity) {
					params.endValidity = format(new Date(queryParams.endValidity), 'yyyy-MM-dd', { locale: ptBR });
				}

				lastQueryParams = params;
			}

			getSalePriceFormations(params).then((response) => {
				dispatch({
					type: ActionType.SALE_PRICE_FORMATION,
					payload: {
						salePriceFormations: response.data.data,
						count: response.data.count,
						salePriceFormationsPages: response.data.count,
						salePriceFormationsPage: queryParams.skip,
						salePriceFormationsTake: take,
					},
				});
				actions.setLoading(false);
			});
		},
		handleEdit(id: string) {
			navigate(`/edit/${id}`);
		},
		handleDeleteSalePriceFormation(id: string) {
			actions.setLoading(true);
			deleteSalePriceFormation(id)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
					actions.getSalePriceFormations(lastQueryParams);
				});
		},
		getPriceLists() {
			getPriceLists().then((response) => {
				dispatch({
					type: ActionType.PRICE_LISTS,
					payload: { priceLists: response.data },
				});
			});
		},
	};

	return actions;
};

const SalePriceFormationContainer = (): JSX.Element | null => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => SalePriceFormationActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<SalePriceFormationPresentational {...state} {...actions} />
	);
};

export default SalePriceFormationContainer;
