import React, {
	useCallback,
} from 'react';
import { Form, Formik } from 'formik';
import _isEqual from 'lodash/isEqual';
import {
	Accordion, AccordionDetails, AccordionSummary, Alert, Button, Grid, Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DashboardFilter } from '../../../../containers/Dashboards/BudgetDashboardAssets';
import { stopPropagation } from '../../../../helpers/Utils';
import { getValidFilters } from '../../../../helpers/BudgetDashboardFilter';
import FormObserver from '../../../Common/Form/FormObserver';
import { PeriodPicker } from '../../../Common/Form/PeriodPicker';
import { budgetDashboardInitialFilter } from '../../../../constants/dashboard';
import BudgetOrderFourFieldsFilter from '../../../Common/BudgetOrderFourFields/BudgetOrderFourFieldsFilter';
import { IFilterFieldsValues } from '../../../../interfaces/BudgetOrderFourFields';

interface BudgetDashboardFiltersProps {
	filterFieldsValues: IFilterFieldsValues | null;
	dashboardFilter: DashboardFilter;
	handleDashboardFilter(filter: DashboardFilter): void;
}

const FilterAlert = ({
	isCurrentFilterApplied,
}: { isCurrentFilterApplied: boolean }): JSX.Element | null => {
	if (isCurrentFilterApplied) return null;

	return (
		<Alert severity="warning" sx={{ width: 'fit-content' }}>Os valores apresentados não correpondem aos filtros selecionados</Alert>
	);
};

export const BudgetDashboardFilters = ({
	filterFieldsValues,
	dashboardFilter,
	handleDashboardFilter,
}: BudgetDashboardFiltersProps): JSX.Element | null => {
	const onReset = useCallback((e, handleReset) => {
		stopPropagation(e);
		handleDashboardFilter(budgetDashboardInitialFilter);
		handleReset();
	}, [handleDashboardFilter]);

	const onSubmit = useCallback((values: DashboardFilter) => {
		const periodFilter = {
			year: values.year,
			initialMonth: values.initialMonth,
			finalMonth: values.finalMonth,
		};
		const budgetFilter = {
			accountingAccounts: values.accountingAccounts,
			accountingItems: values.accountingItems,
			classValues: values.classValues,
			costCenters: values.costCenters,
		};
		const validBudgetFilters = getValidFilters(budgetFilter, filterFieldsValues);
		handleDashboardFilter({ ...periodFilter, ...validBudgetFilters });
	}, [filterFieldsValues, handleDashboardFilter]);

	if (!filterFieldsValues) {
		return null;
	}

	return (
		<Formik
			initialValues={budgetDashboardInitialFilter}
			onSubmit={onSubmit}
		>
			{({ values, handleReset }) => {
				const isCurrentFilterApplied = _isEqual(
					dashboardFilter,
					getValidFilters(values, filterFieldsValues),
				);

				return (
					<Form>
						<Accordion defaultExpanded>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="filter-content"
								id="filter-header"
							>
								<FormObserver />
								<Stack spacing={2} direction="row" justifyContent="flex-start" alignItems="center" sx={{ width: '100%' }}>
									<PeriodPicker />
									<FilterAlert isCurrentFilterApplied={isCurrentFilterApplied} />
									<Button onClick={(e) => onReset(e, handleReset)} variant="text" sx={{ ml: 'auto!important', height: 'fit-content' }}>Restaurar</Button>
									<Button onClick={stopPropagation} disabled={isCurrentFilterApplied} type="submit" variant="contained" sx={{ mr: '1rem!important', height: 'fit-content' }}>Filtrar</Button>
								</Stack>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={3}>
									{filterFieldsValues && (
										<BudgetOrderFourFieldsFilter
											accountingAccounts={filterFieldsValues.accountingAccounts}
											accountingItems={filterFieldsValues.accountingItems}
											classValues={filterFieldsValues.classValues}
											costCenters={filterFieldsValues.costCenters}
											breakpoints={{ xs: 6, xl: 3 }}
											limitTags={2}
										/>
									)}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Form>
				);
			}}
		</Formik>
	);
};
